<div>
  <div>
    <h4>Regional Managers</h4>
  </div>


  <div class="p-grid" *ngIf="rms?.length > 0">
    <div class="p-col-12" *ngFor="let rm of rms">
      <hr/>
      <h5>{{rm.firstName}} {{rm.lastName}}</h5>
      <div>
        <button pButton label="Go To Employee's RM Schedule" (click)="navigateToRMSchedulingPage(rm)" class="btn-margin"></button>
        <button pButton label="Go To Employee's Zip Code to Day Assignments" (click)="navigateToRMTimeSlotPage(rm)"></button>
      </div>
    </div>
  </div>
  <hr>
  <div *ngIf="!isLoading && rms.length <= 0" class="fine-print">There aren't any Regional Managers in system.</div>
  <div>
    <span *ngIf="isLoading" style="margin-left:8px">
      <i class="pi pi-spin pi-spinner" style="font-size: 3.5em"></i>
    </span>
  </div>
</div>