import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QuoteRequest } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-additional-info-modal',
  templateUrl: './additional-info-modal.component.html',
  styleUrls: ['./additional-info-modal.component.css']
})
export class AdditionalInfoModalComponent implements OnInit {
  @Input() display: boolean;
  @Input() quoteRequest: QuoteRequest;
  @Input() event: any;

  @Output() emitClose = new EventEmitter();

  constructor() { }

  ngOnInit() {
    // console.log(this.event)
  }

  close() {
    this.emitClose.emit();
  }
}
