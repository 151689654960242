import { Injectable } from '@angular/core';
import { Crew, Equipment, Employee, CrewEquipment, CrewEmployee, CrewGenSvc } from '../services_autogenerated/generated_services';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrewDragAndDropService {
  private crewUpdater = new BehaviorSubject<Crew>(null);
  crews: Crew[];

  constructor(
  private crewService: CrewGenSvc) {
    this.addCrewCreationLists();
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Crew Creation drag and drop methods
  //////////////////////////////////////////////////////////////////////////////////////////
  allCrewEmployeeLists: string[] = [];
  allCrewEquipmentLists: string[] = [];
  dragStartCrew: Crew;

  setCrews(crews: Crew[]) {
    this.crews = crews;
  }

  getCrewUpdater(): Observable<Crew> {
    return this.crewUpdater.asObservable();
  }

  emitCrewUpdate(crew: Crew) {
    this.crewUpdater.next(crew);
  }

  setDragStartCrew(previousCrew: Crew) {
    this.dragStartCrew = previousCrew;
  }

  addCrewCreationLists() {
    this.allCrewEmployeeLists.push('employeeList');
    this.allCrewEquipmentLists.push('equipmentList');
  }

  addCrewLists(crew: Crew) {
    this.allCrewEmployeeLists.push('crewEmployeeList-' + crew.id);
    this.allCrewEquipmentLists.push('crewEquipmentList-' + crew.id);
  }

  dropOntoCrewCard(event: CdkDragDrop<Equipment[] | Employee[] | CrewEquipment[] | CrewEmployee[]>, crew: Crew) {
    console.log('DROP ONTO CREW');
    const droppedItem = event.previousContainer.data[event.previousIndex];

    if (event.previousContainer === event.container) {
      // Do not allow movement in the array
    } else if (droppedItem instanceof Employee && crew.crewEmployees.find(e => e.employee.id === droppedItem.id)) {
      // bucket already has that employee, do not duplicate it.
    } else if (droppedItem instanceof Equipment && crew.crewEquipment.find(e => e.equipment.id === droppedItem.id)) {
      // bucket already has the equipmnet, do not duplicate it.
    } else if (droppedItem instanceof CrewEquipment || droppedItem instanceof CrewEmployee) {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    } else if (droppedItem instanceof Employee) {
      const newCrewEmployee = new CrewEmployee();
      newCrewEmployee.employeeId = droppedItem.id;
      newCrewEmployee.employee = droppedItem;
      newCrewEmployee.crewId = crew.id;

      crew.crewEmployees.push(newCrewEmployee);
    } else if (droppedItem instanceof Equipment) {
      const newCrewEquipment = new CrewEquipment();
      newCrewEquipment.equipmentId = droppedItem.id;
      newCrewEquipment.equipment = droppedItem;
      newCrewEquipment.crewId = crew.id;

      crew.crewEquipment.push(newCrewEquipment);
    }

    this.updateCrewInDatabase(crew);

    if (crew && this.dragStartCrew && this.dragStartCrew.id !== crew.id) {
      this.updateCrewInDatabase(this.dragStartCrew);
    }
  }

  dropOntoEmployeeList(event: CdkDragDrop<Employee | CrewEmployee>) {
    console.log('DROP ONTO EMPLOYEE LIST');
    const droppedItem = event.previousContainer.data[event.previousIndex];

    if (droppedItem instanceof CrewEmployee) {
      if (this.dragStartCrew) {
        this.dragStartCrew.crewEmployees.splice(this.dragStartCrew.crewEmployees.findIndex(ce => ce.employeeId === droppedItem.id));
        this.updateCrewInDatabase(this.dragStartCrew);
      }
    }
  }

  dropOntoEquipmentList(event: CdkDragDrop<Equipment | CrewEquipment>) {
    console.log('DROP ONTO EQUIPMENT LIST');
    const droppedItem = event.previousContainer.data[event.previousIndex];

    if (this.dragStartCrew) {
      this.dragStartCrew.crewEquipment.splice(this.dragStartCrew.crewEquipment.findIndex(ce => ce.equipmentId === droppedItem.id));
      this.updateCrewInDatabase(this.dragStartCrew);
    }
  }

  updateCrewInDatabase(crew: Crew) {
    this.crewService.update(crew, crew.id.toString()).subscribe();
    this.emitCrewUpdate(crew);
  }
}
