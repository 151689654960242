import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PriorityTypeShortcut, PriorityTypeShortcutGenSvc } from 'src/app/services_autogenerated/generated_services';

function rangeValidator(field: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const group = control.parent;
    const fieldToCompare = group.get(field);
    const range = Number(fieldToCompare.value) > Number(control.value);
    return range ? {'range': {value: control.value}} : null;
  };
}

@Component({
  selector: 'app-backlog-row',
  templateUrl: './backlog-row.component.html',
  styleUrls: ['./backlog-row.component.css']
})
export class BacklogRowComponent implements OnInit {
  backlogForm: FormGroup;
  isLoading: boolean;

  @Input() backlog: PriorityTypeShortcut;

  constructor(private priorityTypeShortcutService: PriorityTypeShortcutGenSvc,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
    this.backlogForm = this.formBuilder.group({
      start: [0, Validators.required],
      end: [0, Validators.required]
    } );
   }

  ngOnInit() {
    this.backlogForm.get('end').setValidators([Validators.required, rangeValidator('start')]);
    this.backlogForm.controls['start'].setValue(this.backlog.weeksOutStart);
    this.backlogForm.controls['end'].setValue(this.backlog.weeksOutEnd);
  }

  public get start() { return this.backlogForm.get('start'); }

  public get endC() { return this.backlogForm.get('end'); }

  save(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.controls[key].markAsDirty();
      formGroup.controls[key].markAsTouched();
    });

    if (formGroup.valid) {
      this.priorityTypeShortcutService.updateBackLog(this.backlog.id, this.backlogForm.get('start').value, this.backlogForm.get('end').value).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Saved Successfully',
          detail: `The values for ${this.backlog.name} have been updated.`
        });
      }, error => {
        if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Updating Values',
            detail: JSON.parse(error.response).ExceptionMessage,
            sticky: true
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Save Failed',
            detail: 'The backlog values were not updated, refresh the page and try again.'
          });
        }
      });
    }
  }
}
