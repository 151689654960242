import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuoteRequest, QuoteWorkOrder, Customer } from '../../../services_autogenerated/generated_services';

@Component({
  selector: 'app-quote-billing-modal',
  templateUrl: './quote-billing-modal.component.html',
  styleUrls: ['./quote-billing-modal.component.css']
})
export class QuoteBillingModalComponent implements OnInit {

  @Input() quoteId: number;
  @Input() display: boolean = false;
  @Input() isQuoteRequest: boolean = false;
  @Input() paymentAmount = 0;
  @Input() showManualPaymentChargeMessage = false;

  @Output() emitClose = new EventEmitter();
  @Output() emitPaymentSuccess = new EventEmitter();
  @Output() emitConvertQuote = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  afterSave() {
    this.emitPaymentSuccess.emit();
    // this.close();
  }

  close() {
    console.log('billing saved emit 3');
    this.emitClose.emit();
  }

  convertQuote() {
    this.emitConvertQuote.emit();
  }
}
