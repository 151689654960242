import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import {  EmployeeGenSvc, EmployeeMFAPreferenceViewModel, EmployeeMFAPreferenceViewModelPreferenceType } from 'src/app/services_autogenerated/generated_services';
import { catchError, timeout } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import emailMask from 'text-mask-addons/dist/emailMask';

@Component({
  selector: 'app-multi-factor-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  public preferences = Object.keys(EmployeeMFAPreferenceViewModelPreferenceType).map(key => ({key, name: EmployeeMFAPreferenceViewModelPreferenceType[key]}));
  public loading: boolean = true;
  public employeePreference: EmployeeMFAPreferenceViewModel;
  public selectedPreference;

  public disableSave: boolean = false;

  public responseMessage: string;
  public success: boolean;

  @ViewChild('mfaSetupForm') form: NgForm;

  emailMask = emailMask;

  constructor(
    private authHelper: AuthHelperService,
    private employeeService: EmployeeGenSvc,
    private router: Router
  ) { }

  ngOnInit() {
    this.employeeService.getEmployeePreference(this.authHelper.getDecodedAccessToken().employeeId)
      .pipe(catchError((error) => {
        this.success = false;
        this.responseMessage = 'Unable to retrieve your preference. Please refresh and retry. Contact an admin if the issue persists.'
        return of(null);
      }))
      .subscribe(res => {
        this.employeePreference = res;
        this.selectedPreference = res.preferenceType;
      }
    );
  }

  save() {
    this.disableSave = true;
    // call to update preference, then set and send mfa code
    
    this.employeePreference.preferenceType = this.selectedPreference;
    this.employeeService.saveEmployeePreference(this.employeePreference)
      .pipe(catchError((error) => {
        this.success = false;
        this.disableSave = false;
        this.responseMessage = 'Unable to save your preference. Please refresh and retry. Contact an admin if the issue persists.'
        return of(null);
      }))
      .subscribe(token => {
        this.success = true;
        this.disableSave = true;
        this.responseMessage = 'Preference saved successfully. Redirecting to verification'
        if (token != null) {
          localStorage.setItem(this.authHelper.authToken, token);
        }
        setTimeout(() => {
          this.router.navigate(['multiFactor', 'process']);
        }, 2000);
      });
  }
}
