import { OnInit, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { QuoteRequestGenSvc, EmployeeGenSvc, Employee, QuoteRequest, QuoteRequestScheduleUpdate, RegionalManagerScheduleEventGenSvc, RegionalManagerScheduleEvent, RegionalManagerTimeSlot, } from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthHelperService } from '../../services/auth-helper.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocationColorService } from '../../services/location-color.service';
import { CustomerService } from 'src/app/services/customer.service';
import { forkJoin } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
var CustomerFirmtimeSchedulingComponent = /** @class */ (function () {
    function CustomerFirmtimeSchedulingComponent(quoteRequestService, route, router, authHelperService, employeeService, rmScheduleEventService, confirmationService, locationService, messageService, customerService, configService) {
        var _this = this;
        this.quoteRequestService = quoteRequestService;
        this.route = route;
        this.router = router;
        this.authHelperService = authHelperService;
        this.employeeService = employeeService;
        this.rmScheduleEventService = rmScheduleEventService;
        this.confirmationService = confirmationService;
        this.locationService = locationService;
        this.messageService = messageService;
        this.customerService = customerService;
        this.configService = configService;
        this.events = [];
        this.options = {
            defaultEventMinutes: 60
        };
        // Count is used for double click functionality, since dblClick cannot be used on mobile devices
        this.count = 0;
        this.rmScheduleEvents = [];
        this.anytimeQuoteRequests = [];
        this.confirmPageDisplay = false;
        this.displayStartDate = moment().add(2, 'day').toDate();
        this.displayEndDate = moment().add(8, 'days').toDate();
        this.displayAvailable = [];
        this.isEventInDateRange = function (event) {
            return moment(event.eventStart).isBetween(moment(_this.displayStartDate), moment(_this.displayEndDate), null, '()')
                || moment(event.eventEnd).isBetween(moment(_this.displayStartDate), moment(_this.displayEndDate), null, '()')
                || (moment(event.eventStart).isSameOrBefore(moment(_this.displayStartDate)) && moment(event.eventEnd).isSameOrAfter(moment(_this.displayEndDate)));
        };
        this.isTimeSlotInDateRange = function (timeSlot) {
            return moment(timeSlot.timeBegin).isBetween(moment(_this.displayStartDate), moment(_this.displayEndDate), null, '()')
                || moment(timeSlot.timeEnd).isBetween(moment(_this.displayStartDate), moment(_this.displayEndDate), null, '()')
                || (moment(timeSlot.timeBegin).isSameOrBefore(moment(_this.displayStartDate)) && moment(timeSlot.timeEnd).isSameOrAfter(moment(_this.displayEndDate)));
        };
    }
    CustomerFirmtimeSchedulingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.authHelperService.getDecodedAccessToken();
        this.isCustomer = this.token ? this.token.userType === 'customer' : true;
        var quoteId = localStorage.getItem('quoteRequestId');
        this.quoteId = +quoteId;
        this.quoteRequestService.get(+quoteId).subscribe(function (quoteRequest) {
            _this.quoteRequest = quoteRequest;
        });
        var id;
        if (this.inputId) {
            id = this.inputId;
        }
        else {
            id = +this.route.snapshot.paramMap.get('id');
        }
        this.employeeService.get(id).subscribe(function (emp) {
            _this.employee = emp;
            _this.getAvailableTimeSlots();
            _this.dateRangeChange();
        });
    };
    CustomerFirmtimeSchedulingComponent.prototype.eventSelect = function (time) {
        this.selectedTime = time;
        this.quoteRequest.eventStart = this.selectedTime;
        if (this.isCustomer) {
            // Customer scheduling a firmtime
            // Ignore the end time, the event will only be an hour for the customer appointment
            this.confirmScheduleDate(moment(time));
        }
        else if (+localStorage.getItem('quoteRequestId')) {
            // this is if an employee is scheduling a firmtime for a customer
            this.confirmScheduleDate(moment(time));
        }
    };
    CustomerFirmtimeSchedulingComponent.prototype.confirmScheduleDate = function (date) {
        this.confirmPageDisplay = true;
    };
    CustomerFirmtimeSchedulingComponent.prototype.onPaymentSuccess = function () {
        var _this = this;
        var eventEnd = moment(this.selectedTime).clone();
        eventEnd.add(1, 'h');
        if (+localStorage.getItem('quoteRequestId')) {
            var requestUpdate = new QuoteRequestScheduleUpdate({
                regionalManagerId: this.employee.id,
                eventStart: this.selectedTime,
                eventEnd: eventEnd.toDate(),
                quoteRequestId: +localStorage.getItem('quoteRequestId')
            });
            this.quoteRequestService.updateStartOrEnd(requestUpdate, requestUpdate.quoteRequestId.toString()).subscribe(function (qr) {
                localStorage.removeItem('zipCode');
                localStorage.removeItem('quoteRequestId');
                var confirmLink = _this.configService.appConfig.quoteRequestConfirmationLink;
                if (_this.isCustomer) {
                    _this.route.queryParams
                        .subscribe(function (params) {
                        params.embedded === 'true' ?
                            window.top.location.href = confirmLink :
                            window.location.assign(confirmLink);
                    });
                }
                else {
                    _this.authHelperService.redirectToHome();
                }
            });
        }
    };
    CustomerFirmtimeSchedulingComponent.prototype.navigateToQuoteRequest = function (id) {
        if (confirm('Do you want to navigate to the Request for Quote maintenance page?')) {
            this.router.navigateByUrl('requestedQuoteMaintenance/' + id);
        }
    };
    CustomerFirmtimeSchedulingComponent.prototype.getAvailableTimeSlots = function () {
        var zip = localStorage.getItem('zipCode');
        this.timeSlotsForZipCode = this.employee.regionalManagerTimeSlots.
            filter(function (timeSlot) { return timeSlot.regionalManagerTimeSlotZipCodes.some(function (zc) { return zc.zipCode.code === zip; }); });
    };
    CustomerFirmtimeSchedulingComponent.prototype.getScheduledEvents = function (startDate, endDate) {
        var _this = this;
        this.events = [];
        // Id as a parameter means someone is navigating to an RM's schedule
        // Otherwise get the id from the token of the logged in user.
        var id;
        if (this.inputId) {
            id = this.inputId;
        }
        else {
            id = +this.route.snapshot.paramMap.get('id') ? +this.route.snapshot.paramMap.get('id') : this.token.id;
        }
        if (id) {
            this.rmId = id;
            // Get the quote requests that have been scheduled for the employee
            var getEvents1 = this.quoteRequestService.getAllRequestsInDateRangeForRM(id, startDate, endDate);
            // Get the non-quote request events that have been scheduled for the employee, ie the "blocks"
            var getEvents2 = this.rmScheduleEventService.getAllEventsInDateRangeForRM(id, startDate, endDate);
            forkJoin(getEvents1, getEvents2).subscribe(function (results) {
                _this.scheduledQuoteRequests = results[0];
                _this.rmScheduleEvents = results[1];
                _this.calculateDisplayAvailable();
            });
        }
    };
    CustomerFirmtimeSchedulingComponent.prototype.clickForMore = function () {
        var _this = this;
        this.displayEndDate = moment(this.displayEndDate).add(7, 'd').toDate();
        // Get the quote requests that have been scheduled for the employee
        var getEvents1 = this.quoteRequestService.getAllRequestsInDateRangeForRM(this.rmId, this.displayStartDate, this.displayEndDate);
        // Get the non-quote request events that have been scheduled for the employee, ie the "blocks"
        var getEvents2 = this.rmScheduleEventService.getAllEventsInDateRangeForRM(this.rmId, this.displayStartDate, this.displayEndDate);
        forkJoin(getEvents1, getEvents2).subscribe(function (results) {
            _this.scheduledQuoteRequests = results[0];
            _this.rmScheduleEvents = results[1];
            _this.calculateDisplayAvailable();
        });
    };
    CustomerFirmtimeSchedulingComponent.prototype.calculateDisplayAvailable = function () {
        var _this = this;
        this.displayAvailable = [];
        var days = moment(this.displayEndDate).diff(this.displayStartDate, 'days') + 1;
        // No filter cause time slots span all days
        var timeSlots = this.timeSlotsForZipCode; // .filter(this.isTimeSlotInDateRange);
        var _loop_1 = function (x) {
            var date = moment(this_1.displayStartDate).clone();
            date.add(x, 'days');
            // Loop through adding hour time slots based on RM schedule
            var times = [];
            // Filter time slot by date's day of week
            var timeSlotsForDate = this_1.timeSlotsForZipCode.filter(function (timeSlot) { return timeSlot.dayOfTheWeek === date.day(); });
            timeSlotsForDate.forEach(function (slot) {
                // Only use RM schedule slots matching date
                // Set timeIndex equal to date at slot start time
                var timeIndex = moment(date.format('YYYY-MM-DD ' + moment(slot.timeBegin).format('HH:mm'))).toDate();
                // Set timeEnd equal to date at slot end time
                var timeEnd = moment(date.format('YYYY-MM-DD ' + moment(slot.timeEnd).format('HH:mm')));
                var hour = timeIndex.getHours();
                // Start time must be on the hour
                if (timeIndex.getMinutes() !== 0) {
                    timeIndex.setHours(hour + 1);
                }
                // Add time for each hour in time slot
                while (moment(timeIndex).isBefore(timeEnd) && moment(timeIndex).isBefore(moment(_this.displayEndDate))) {
                    // Only 24hrs from current time that aren't already
                    var dayAfterTomorrow = moment().add(2, 'day');
                    if ((moment(timeIndex).isAfter(dayAfterTomorrow, 'hour'))
                        && _this.findDateIndex(times, timeIndex) < 0) {
                        times.push(timeIndex);
                    }
                    var time = moment(timeIndex);
                    time.add(1, 'hours');
                    timeIndex = time.toDate();
                }
            });
            if (times.length !== 0) {
                this_1.displayAvailable.push({
                    day: date.toDate(),
                    times: times.sort(function (a, b) { return a - b; })
                });
            }
        };
        var this_1 = this;
        // Loop through days of week
        for (var x = 0; x < days; x++) {
            _loop_1(x);
        }
        // Remove already taken slots
        var quoteRequestInRange = this.scheduledQuoteRequests.filter(this.isEventInDateRange);
        var rmEventsInRange = this.rmScheduleEvents.filter(this.isEventInDateRange);
        var eventsInRange = quoteRequestInRange.concat(rmEventsInRange);
        eventsInRange.forEach(function (event) {
            // Find days with conflicting times
            var conflictingDays = _this.displayAvailable.filter(function (available) { return moment(available.day).isBetween(moment(event.eventStart), moment(event.eventEnd), 'day', '[]'); });
            conflictingDays.forEach(function (conflictingDay) {
                if (conflictingDay.times) {
                    // Remove conflicting times from days
                    conflictingDay.times = conflictingDay.times.filter(function (time) { return !moment(time).isBetween(moment(event.eventStart), moment(event.eventEnd), null, '[)'); });
                }
            });
        });
    };
    CustomerFirmtimeSchedulingComponent.prototype.findDateIndex = function (dates, dateToFind) {
        return dates.findIndex(function (date) {
            return date.valueOf() === dateToFind.valueOf();
        });
    };
    // openPaymentModal(quoteId: number) {
    //   this.confirmPageDisplay = true;
    // }
    // closeQuoteBillingModal() {
    //   this.confirmPageDisplay = false;
    // }
    CustomerFirmtimeSchedulingComponent.prototype.setStartDate = function (newStartDate) {
        this.displayStartDate = newStartDate;
        var newEndDate = moment(newStartDate).clone();
        newEndDate.add(6, 'days');
        this.displayEndDate = newEndDate.toDate();
        this.displayEndDate.setHours(23);
        this.dateRangeChange();
    };
    CustomerFirmtimeSchedulingComponent.prototype.pageDateRange = function (pageIncrement) {
        var daysToAdd = pageIncrement * 7;
        var newStartDate = moment(this.displayStartDate);
        var newEndDate = moment(this.displayEndDate);
        newStartDate.add(daysToAdd, 'days');
        newEndDate.add(daysToAdd, 'days');
        this.displayStartDate = newStartDate.toDate();
        this.displayEndDate = newEndDate.toDate();
        this.displayEndDate.setHours(23);
        this.dateRangeChange();
    };
    CustomerFirmtimeSchedulingComponent.prototype.dateRangeChange = function () {
        var tomorrow = moment().add(1, 'day');
        if (moment(this.displayStartDate).isBefore(tomorrow)) {
            this.displayStartDate = moment().add(1, 'day').toDate();
            this.displayEndDate = moment().add(7, 'days').toDate();
            this.displayEndDate.setHours(23);
        }
        this.getScheduledEvents(this.displayStartDate, this.displayEndDate);
    };
    CustomerFirmtimeSchedulingComponent.prototype.openCalendar = function () {
        this.calendarNonMobile.showOverlay(this.calendarNonMobile.inputfieldViewChild.nativeElement);
        this.calendarMobile.showOverlay(this.calendarMobile.inputfieldViewChild.nativeElement);
    };
    return CustomerFirmtimeSchedulingComponent;
}());
export { CustomerFirmtimeSchedulingComponent };
var ScheduleDay = /** @class */ (function () {
    function ScheduleDay() {
    }
    return ScheduleDay;
}());
