<div class="export-container">
  <span style="min-width: 290px; max-width: 290px; margin-top: auto; margin-bottom: auto;">{{export.name}}: </span>
  <span *ngFor="let option of export.options">
    <button
      pButton 
      type="submit" 
      class="ui-button-success ui-button-raised ui-button-success buttonStyle" 
      (click)="doExport(option.id)"
      [disabled]="isLoading"
      [label]="option.name">
    </button>
  </span>
  <span *ngIf="isLoading" style="vertical-align: middle; margin-left: 5px;">
    <p-progressSpinner [style]="{width: '26px', height: '26px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"></p-progressSpinner>
  </span>
  <br/>
</div>
<hr/>