<!--If you do not know how WorkTaskTemplate corresponds to WorkTask, then please get clarification
  prior to changing this page. -->

  <br>
  <form id="taskForm" #taskForm="ngForm">
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6 ui-lg-6 columnPadding">
        <div class="ui-g">
          <div class="ui-g-12 ui-md-6 ui-lg-6">
            <h4>Work Task Information</h4>
          </div>
        </div>
        <br>
        <div class="ui-g">
          <div class="ui-g-12">
            <h6>Task Name</h6>
            <input pInputText name="name" class="inputTextBox" [(ngModel)]="task.name" *ngIf="task" required/>
          </div>
        </div>
        <div class="ui-g">
          <!--
          <div class="ui-g-12 ui-lg-6">
            <h6>Cost</h6>
            <input pInputText class="inputTextBox" name="cost" numeric [textMask]="{mask: currencyMask}" [ngModel]="task.cost" *ngIf="task" (ngModelChange)="task.cost=$event"/>
          </div>
          -->
          <div class="ui-g-12 ui-lg-6">
            <h6>Price</h6>
            <input pInputText class="inputTextBox" name="price" numeric [textMask]="{mask: currencyMask}" [ngModel]="task.price" *ngIf="task" (ngModelChange)="task.price=$event"/>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-2 ui-lg-2" style="font-weight: 500"> 
            <label for="active"> <p-checkbox binary="true" name="active" [(ngModel)]="task.active" *ngIf="task"></p-checkbox>&nbsp; Active </label>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-6 columnPadding topMargin">
        <br>
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g">
              <div class="ui-g-12">
                <h6>Description</h6>
                <textarea pInputTextarea name="description" required class="form-control" [(ngModel)]="task.description" *ngIf="task" [rows]="8" style="width: 100%;"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12 columnPadding">
        <div *ngIf="taskForm.invalid && taskForm.submitted" class="alert alert-danger" role="alert">
          There are errors on this page. Please correct the errors and resubmit.
        </div>
      </div>
      <div class="ui-g-12 columnPadding">
        <button 
          pButton
          type="submit"
          class="ui-button-raised ui-button-success buttonStyle"
          (click)="save(taskForm)"
          [disabled]="saving"
          label="Save">
        </button>
        <button 
          pButton
          type="button"
          class="ui-button-raised ui-button-warning buttonStyle"
          (click)="cancel()"
          label="Cancel">
        </button>
        <button 
          pButton
          type="button"
          class="ui-button-raised ui-button-danger buttonStyle"
          (click)="delete()"
          [disabled]="disableDelete"
          label="Delete">
        </button>
      </div>
    </div>
  </form>