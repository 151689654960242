import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DisplayBlob } from '../../models/displayBlob';
import { of } from 'rxjs';
import { BlobGenSvc, SasResponse } from '../../services_autogenerated/generated_services';
import { BlobManagerWrapperService } from 'src/app/services/blob-manager-wrapper.service';
import { FileHelper } from 'src/app/models/fileWithOverwrittenName';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
import { Base64 } from 'src/app/models/base64';
import { Guid } from '../../models/guid';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
    selector: 'app-blob-manager',
    templateUrl: './blob-manager.component.html',
    styleUrls: ['./blob-manager.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
})
export class BlobManagerComponent implements OnInit, AfterViewInit {
    // Container name on the blob storage (should not have spaces)
    @Input() containerName: string;
    // Id of the parent object, will create a folder in the container with this id
    @Input() parentRecordId: number;
    // Optional, disables upload functionality but can download blobs
    @Input() viewOnly: boolean;
    // Optional, will display bolded
    @Input() titleLabel: string;
    // Optional, will display as Add {uploadTitle}
    @Input() uploadLabel: string;
    // Optional, will trigger quote only, anonymous blob retrieval
    @Input() anonymousPossible: boolean;
    // Optional, will hide if there are no blobs
    @Input() hideIfNoBlobs: boolean;
    // Optional, limit types acceptable
    @Input() accept: string = '';
    // Optional, sets upload input to required
    @Input() required: boolean = false;
    // Optional, overrides button text so it's not a +
    @Input() buttonText = '';
    // Optional, do the upload through Angular instead of .NET
    @Input() angularUpload: boolean = false;

    @Output() doneUploadingEvent = new EventEmitter();

    blobs: any[];
    displayBlobs: DisplayBlob[];
    errorMessage: string;
    fileInUploader: File;
    filesToUpload: FileHelper[] = [];
    isRequired: boolean; // a copy of required Input so we can preserve original value
    successfulUploads: number = 0;

    sasToken: SasResponse;

    constructor(private blobService: BlobGenSvc,
        private authService: AuthHelperService,
        private blobServiceWrapper: BlobManagerWrapperService,
        private cdRef: ChangeDetectorRef,
        private appConfigService: AppConfigService,
        private messageService: MessageWrapperService,
        private http: HttpClient) { }

    ngOnInit() {
        this.isRequired = this.required;
        if (this.containerName == null) {
            throw new Error('BlobManager requires \'containerName\' attribute.');
        }
    }

    ngAfterViewInit() {
        // go get, and list, the blobs if it is not a new record
        if (this.parentRecordId > 0) {
            if (this.anonymousPossible) {
                this.blobService.getAnonymousBlobs(this.containerName, this.parentRecordId)
                    .subscribe((blobs) => {
                        this.blobs = blobs;
                        this.displayBlobs = blobs.map((blob, index) => {
                            // Kenny doesn't want customers to see the actual names, so just use 'Photo x'
                            const newBlob = new DisplayBlob(blob);
                            newBlob.DisplayName = 'Photo ' + (index + 1);
                            return newBlob;
                        });
                    }, (error) => {
                        // Some error occurred, let the user know
                        console.log('Error loading uploads: ', error);
                        this.errorMessage = 'Error loading uploads, please refresh the page.';
                    });
            } else {
            this.blobService.getBlobs(this.containerName, this.parentRecordId)
                .subscribe((blobs) => {
                    this.blobs = blobs;
                    this.displayBlobs = blobs.map((blob) => new DisplayBlob(blob));
                }, (error) => {
                    // Some error occurred, let the user know
                    console.log('Error loading uploads: ', error);
                    this.errorMessage = 'Error loading uploads, please refresh the page.';
                });
            }
        } else {
            this.blobs = [];
            this.displayBlobs = [];
            this.cdRef.detectChanges(); 
            // so sorry... weird bug where it didn't always detect changes when logged in, quick fix was important
            // and I didn't get back to fixing it later
        }
    }

    save() {
        if (this.filesToUpload && this.filesToUpload.length > 0) {
            return this.blobServiceWrapper.uploadFilesToBlobStorage(this.containerName, this.parentRecordId, this.filesToUpload);
        } else {
            return of(null);
        }
    }

    async uploadFile(file: FileHelper, sas: SasResponse) {
        file.progress = 0;
        try {
            let formattedContainerName = `${this.appConfigService.appConfig.environment.toLowerCase()}-${this.containerName}`
            const accountName = "russelltreeappstorage";
            var fileName = `${this.parentRecordId}/${file.overwrittenFileName}`;
        
            var blockIDArrayList: string[] = [];
            var blockSize = 4 * 1024 * 1024 - 48
            var bytesLeft = file.size;

            for (let offset = 0; offset < file.size; offset += blockSize) {
                let chunk;
                if (bytesLeft >= blockSize) {
                    chunk = file.slice(offset, offset + blockSize);
                } else {
                    chunk = file.slice(offset, offset + bytesLeft);
                }
                var blockID = Base64.encode(Guid.newGuid());
    
                blockIDArrayList.push(blockID);
                
                var httpOptions = {
                    headers: new HttpHeaders({
                        'x-ms-blob-type': 'BlockBlob',
                        'x-ms-date': new Date().toUTCString(),
                        'x-ms-version': '2015-02-21',
                        'x-ms-client-request-id': blockID,
                        'Content-Type': file.type
                    })
                };
                
                await this.http.put(`https://${accountName}.blob.core.windows.net/${formattedContainerName}/${fileName}?${sas.token}&comp=block&blockid=${blockID}`, chunk, httpOptions).toPromise()
                    .then(data => {
                            file.progress = Math.round((offset / file.size) * 100);
                        }
                    );
            }

            var httpOptions = {
                headers: new HttpHeaders({ 
                    'x-ms-date': new Date().toUTCString(),
                    'x-ms-version': '2015-02-21',
                    'Content-Type': 'text/plain; charset=UTF-8'
                })
            };
            
            var xml = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><BlockList>'
            blockIDArrayList.forEach(id => xml += `<Latest>${id}</Latest>`);
            xml += '</BlockList>';

            await this.http.put(`https://${accountName}.blob.core.windows.net/${formattedContainerName}/${fileName}?${sas.token}&comp=blocklist&timeout=120`, xml, httpOptions).toPromise()
                .then(data => {
                    file.progress = 100;
                    this.successfulUploads++;
                }, (err) => {
                    if (file.canRetry) {
                        file.canRetry = false;
                        this.messageService.clear('upload-in-progress');
                        this.messageService.addErrorMessage(
                            `Your upload was not successful but don\'t worry, we still got your quote request. You can contact us at ${this.appConfigService.appConfig.contactPhoneNumber} to arrange a way to send your video.`, 
                            undefined,
                            true);
                    } else {
                        file.canRetry = true;
                    }
                });
        } catch (err) {
            console.log(err);
            if (file.canRetry) {
                file.canRetry = false;
                this.messageService.clear('upload-in-progress');
                this.messageService.addErrorMessage(
                    `Your upload was not successful but don\'t worry, we still got your quote request. You can contact us at ${this.appConfigService.appConfig.contactPhoneNumber} to arrange a way to send your video.`, 
                    undefined,
                    true);
            } else {
                file.canRetry = true;
            }
        }
    }

    retryFile(file: FileHelper) {
        if (!this.sasToken) {
            this.blobService.getSAS().subscribe(async (sas) => {
                this.sasToken = sas;
                this.uploadFile(file, sas);
            });
        } else {
            this.uploadFile(file, this.sasToken);
        }
    }

    addToList(file: FileHelper) {
        if (file) {
            const index = this.filesToUpload
                .findIndex(x => (x.name === file.name || x.name === this.spacesToUnderscores(file.name))
                    && x.size === file.size && x.type === file.type);

            if (index === -1) {
                file.overwrittenFileName = this.spacesToUnderscores(file.name);
                this.fileInUploader = null;

                if (this.parentRecordId && this.angularUpload) {
                    if (!this.sasToken) {
                        this.blobService.getSAS().subscribe(async (sas) => {
                            this.sasToken = sas;
                            this.uploadFile(file, sas);
                            file.overwrittenFileName = this.spacesToUnderscores(file.name);
                            this.filesToUpload.push(file);
                        });
                    } else {
                        this.uploadFile(file, this.sasToken);
                        file.overwrittenFileName = this.spacesToUnderscores(file.name);
                        this.filesToUpload.push(file);
                    }
                } else if (this.parentRecordId) {
                    this.blobServiceWrapper.uploadFilesToBlobStorage(this.containerName, this.parentRecordId, [file]).subscribe((response) => {
                        if (response) {
                            this.blobs = this.blobs.concat(response);
                            this.displayBlobs = this.displayBlobs.concat(response.map(r => {
                                    return new DisplayBlob({
                                    name: r.Name,
                                    SASUri: r.SASUri,
                                });
                            }));
                        } else {
                            alert('Failed to upload document/photo. Please try again later.');
                        }
                    });
               } else {
                    this.fileInUploader = null;
                    file.overwrittenFileName = this.spacesToUnderscores(file.name);
                    this.filesToUpload.push(file);
                    this.isRequired = false;
               }
            } else {
                alert('That file is already in your list.');
            }
        }
    }

    removeFromList(index: number) {
        if (confirm('Are you sure you want to remove this item from the upload list?')) {
            const removedFile = this.filesToUpload.splice(index, 1);
            // if it was originally required and we have 0 files, change required back to lock form
            this.isRequired = this.required && this.filesToUpload.length == 0;

            // Don't call delete API when there is not a parent record ID, it'll fail
            if (this.parentRecordId) {
                if (this.authService.isLoggedIn()) {
                    this.blobService.deleteBlob(this.containerName, removedFile[0].overwrittenFileName, this.parentRecordId)
                    .subscribe((response) => {
                        if (!response) {
                            alert('Failed to delete document/photo. Please try again later.');
                        }
                    });
                } else {
                    this.blobService.deleteBlobAnonymous(this.containerName, removedFile[0].overwrittenFileName, this.parentRecordId)
                    .subscribe((response) => {
                        if (!response) {
                            alert('Failed to delete document/photo. Please try again later.');
                        }
                    });
                }
            }
        }
    }

    saveAll() {
        if (this.filesToUpload && this.filesToUpload.length > 0) {
            return this.blobServiceWrapper.uploadFilesToBlobStorage(this.containerName, this.parentRecordId, this.filesToUpload);
        } else {
            return of(null);
        }
    }

    delete(blob: DisplayBlob, index: number) {
        if (confirm('Are you sure you want to remove ' + blob.DisplayName + ' from this record?')) {
              this.blobService.deleteBlob(this.containerName, blob.DisplayName, this.parentRecordId)
              .subscribe((response) => {
                  if (response) {
                      this.displayBlobs.splice(index, 1);
                      this.blobs.splice(index, 1);
                  } else {
                      alert('Failed to delete document/photo. Please try again later.');
                  }
              });
        }
    }

    scrubSpacesFromInput(file: FileHelper) {
        file.overwrittenFileName = this.spacesToUnderscores(file.overwrittenFileName);
    }

    spacesToUnderscores(toReplace: string) {
        return toReplace.replace(/ /g, '_');
    }
}
