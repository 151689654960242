import { Component, Input, OnChanges, SimpleChanges, AfterContentInit, EventEmitter, Output } from '@angular/core';
import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
    Employee,
    WellnessWorkOrder,
    Equipment,
    ScheduledBucketEmployee,
    ScheduledBucketEquipment,
    ScheduledBucketWellnessWorkOrder,
    ScheduledBucketGenSvc,
    WorkOrderWellnessTask,
    WellnessTask,
    WellnessWorkOrderGenSvc,
    WorkWorkOrder,
    WorkWorkOrderGenSvc,
    ScheduledBucketWorkWorkOrder,
    WorkOrderWorkTask,
    WellnessTaskCompletionStatus,
    WorkTaskCompletionStatus,
    WellnessWorkOrderCompletionStatus,
    WorkWorkOrderCompletionStatus,
    ScheduleTaskDTO,
    ScheduleTaskDTOType,
    ScheduledBucketDTO
} from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { SpecificationService } from 'src/app/services/specification.service';
import { CardTitleHelperService } from 'src/app/services/card-title-helper.service';
import { BucketValidationHelperService } from 'src/app/services/bucket-validation-helper.service';
import { IDroppable } from '../../models/IDroppable';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';

@Component({
    selector: 'app-scheduled-item-bucket-card',
    templateUrl: './scheduled-item-bucket-card.component.html',
    styleUrls: ['./scheduled-item-bucket-card.component.css']
})
export class ScheduledItemBucketCardComponent implements AfterContentInit, OnChanges, IDroppable {
    @Input() bucket: ScheduledBucketDTO;
    @Input() isWork: boolean;
    @Input() isWellness: boolean;
    hours: number;
    totalHours = 0;
    taskHours = 0;
    type: string;
    isCollapsed = true;
    bucketTitle: string;
    employeeList: string;
    bucketErrors: string[] = [];
    grayOut = false;

    @Output() dragStartEvent = new EventEmitter<WellnessWorkOrder | WorkWorkOrder>();
    @Output() dragEndEvent = new EventEmitter();

    @Output() taskDragStartEvent = new EventEmitter<WellnessTask>();
    @Output() taskDragEndEvent = new EventEmitter();

    @Output() needsValidated = new EventEmitter<ScheduledBucketDTO>();
    @Output() isInitialized = new EventEmitter<ScheduledBucketDTO>();

    constructor(
        public dragAndDropService: DragAndDropService,
        public scheduledItemBucketService: ScheduledBucketGenSvc,
        public titleHelperService: CardTitleHelperService,
        public validatorHelper: BucketValidationHelperService,
        private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
        private workWorkOrderService: WorkWorkOrderGenSvc,
        private workOrderHelperService: WorkOrderHelperService,
        private messageService: MessageWrapperService
    ) { }

    ngAfterContentInit(): void {
        this.setCrewForemanAndEquipment();
        this.setUpProgressBar();
        setTimeout(() => {
            this.validateItem();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onBucketUpdate();
        this.isInitialized.emit(this.bucket);
    }

    onBucketUpdate() {
        this.setUpProgressBar();
        this.validateItem();
        this.setCrewForemanAndEquipment();
    }

    dragStart(event: CdkDragStart) {
        // event may not be needed since we're just setting the bucket on the service
        this.dragAndDropService.setDragStartBucket(this.bucket);
        if (event instanceof WellnessWorkOrder) {
            // This gets pushed up parent components via event emitters until it reaches
            // base-wellness-view.component, where it grays out the buckets that do not
            // meet the requirements of the given WO
            this.dragStartEvent.emit(event);
        } else if (event instanceof WorkOrderWellnessTask) {
            this.taskDragStartEvent.emit(event.wellnessTask);
        } else if (event instanceof WorkWorkOrder) {
            this.dragStartEvent.emit(event);
        }
    }

    taskDragEnd() {
        this.taskDragEndEvent.emit();
    }

    // This gets pushed up parent components via event emitters until it reaches
    // base-wellness-view.component, where it un-grays out all the buckets
    dragWOEnd() {
        this.dragEndEvent.emit();
    }

    validateItem() {
        this.needsValidated.emit(this.bucket);
    }

    setCrewForemanAndEquipment() {
        this.employeeList = this.titleHelperService.generateEmployeeTitle(this.bucket.scheduledBucketEmployees.map(sbe => sbe.employee));

        this.bucketTitle = this.titleHelperService.generateTitle(this.bucket.scheduledBucketEquipment.map(sbe => sbe.equipment),
            this.bucket.scheduledBucketEmployees.map(sbe => sbe.employee));
    }

    disableDrag(bucketWO: ScheduledBucketWellnessWorkOrder | ScheduledBucketWorkWorkOrder) {
        // Checks the tasks on the WO to see if any are complete.
        // They cannot drag the whole WO if a task is complete but they can drag tasks that aren't complete
        if (bucketWO instanceof ScheduledBucketWellnessWorkOrder) {
            return bucketWO.wellnessWorkOrder.workOrderWellnessTasks.some(wowt =>
                (wowt.wellnessTask.currentBucketId === this.bucket.id || wowt.wellnessTask.goBackBucketId === this.bucket.id) &&
                (wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed
                    || wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed));
        } else if (bucketWO instanceof ScheduledBucketWorkWorkOrder) {
            return bucketWO.workWorkOrder.workOrderWorkTasks.some(wowt =>
                (wowt.workTask.currentBucketId === this.bucket.id || wowt.workTask.goBackBucketId === this.bucket.id) &&
                (wowt.workTask.completionStatus === WorkTaskCompletionStatus.Completed || wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed));
        }
    }

    setUpProgressBar() {
        this.type = 'secondary';
        this.totalHours = 0;

        this.taskHours = 0;
        if (this.bucket.scheduledBucketWellnessWorkOrders && this.bucket.scheduledBucketWellnessWorkOrders.length > 0) {
            this.bucket.scheduledBucketWellnessWorkOrders.forEach(sbwwo => {
                if (sbwwo.wellnessWorkOrder.workOrderWellnessTasks) {
                    this.taskHours += sbwwo.wellnessWorkOrder.workOrderWellnessTasks
                        .map(wowt => wowt.wellnessTask).filter(wt => wt.currentBucketId === this.bucket.id)
                            .map(t => t.hours).reduce((a, b) => a + b, 0);

                    this.taskHours += sbwwo.wellnessWorkOrder.workOrderWellnessTasks
                        .map(wowt => wowt.wellnessTask).filter(wt => wt.goBackBucketId === this.bucket.id)
                            .map(t => t.goBackHoursEstimate).reduce((acc, curr) => acc + curr, 0);
                }
            });
        } else if (this.bucket.scheduledBucketWorkWorkOrders && this.bucket.scheduledBucketWorkWorkOrders.length > 0) {
            this.bucket.scheduledBucketWorkWorkOrders.forEach(sbwwo => {
                if (sbwwo.workWorkOrder.workOrderWorkTasks) {
                    this.taskHours += sbwwo.workWorkOrder.workOrderWorkTasks
                        .map(wowt => wowt.workTask).filter(wt => wt.currentBucketId === this.bucket.id)
                            .map(t => t.hours).reduce((a, b) => a + b, 0);

                this.taskHours += sbwwo.workWorkOrder.workOrderWorkTasks
                    .map(wowt => wowt.workTask).filter(wt => wt.goBackBucketId === this.bucket.id)
                        .map(t => t.goBackHoursEstimate).reduce((acc, curr) => acc + curr, 0);
                }
            });
        }

        if (this.bucket.scheduledBucketEmployees.length > 0) {
            this.totalHours = this.bucket.scheduledBucketEmployees.length * 10;
        }

        // If there are no employees, total hours is 0 and you cannot divide by zero... So check for NaN
        this.hours = Number.isNaN(this.taskHours / this.totalHours * 100) ? 0 : this.taskHours / this.totalHours * 100;

        if (this.hours > 100) {
            this.type = 'danger';
        }
    }

    deleteEmployeeFromBucket(employee: Employee) {
        const index = this.bucket.scheduledBucketEmployees.findIndex(ce => ce.employeeId === employee.id);
        const removed = this.bucket.scheduledBucketEmployees.splice(index, 1);
        this.scheduledItemBucketService.removeEmployeeFromBucket(employee.id, this.bucket.id).subscribe(() => {
            this.onBucketUpdate();
        }, error => {
            this.messageService.addErrorMessage('Unable to delete the employee.', error);
            this.bucket.scheduledBucketEmployees.splice(index, 0, removed[0]);
            this.onBucketUpdate();
        });
    }

    deleteEquipmentFromBucket(equipment: Equipment) {
        const index = this.bucket.scheduledBucketEquipment.findIndex(ce => ce.equipmentId === equipment.id);
        const removed = this.bucket.scheduledBucketEquipment.splice(index, 1);
        this.scheduledItemBucketService.removeEquipmentFromBucket(equipment.id, this.bucket.id).subscribe(() => {
            this.onBucketUpdate();
        }, error => {
            this.messageService.addErrorMessage('Unable to delete the equipment.', error);
            this.bucket.scheduledBucketEquipment.splice(index, 0, removed[0]);
            this.onBucketUpdate();
        });
    }

    orderWorkOrders() {
        for (let i = 0; i < this.bucket.scheduledBucketWorkWorkOrders.length; i++) {
            this.bucket.scheduledBucketWorkWorkOrders[i].order = i;
        }

        for (let i = 0; i < this.bucket.scheduledBucketWellnessWorkOrders.length; i++) {
            this.bucket.scheduledBucketWellnessWorkOrders[i].order = i;
        }
    }

    // Handle your own drop events bucket 515
    drop(event: CdkDragDrop<Equipment[] | Employee[] | WellnessWorkOrder[] | ScheduledBucketEquipment[] |
        ScheduledBucketEmployee[] | ScheduledBucketWellnessWorkOrder[] | WorkOrderWellnessTask[] |
        WorkWorkOrder[] | ScheduledBucketWorkWorkOrder[] | WorkOrderWorkTask[]>) {
        console.log('DROP ONTO BUCKET');
        const droppedItem = event.previousContainer.data[event.previousIndex];
        if (event.previousContainer === event.container) {
            // This code runs when a work order is reordered within a bucket
            if (droppedItem instanceof ScheduledBucketWorkWorkOrder) {
                moveItemInArray(this.bucket.scheduledBucketWorkWorkOrders, event.previousIndex, event.currentIndex);
                this.orderWorkOrders();
                this.bucket.scheduledBucketWorkWorkOrders.forEach((sbwwo, index) => {
                    sbwwo.order = index;
                    this.scheduledItemBucketService.updateWorkWorkOrderOrdering(this.bucket.id, sbwwo.workWorkOrderId, index)
                        .subscribe(() => {}
                        , error => {
                            moveItemInArray(this.bucket.scheduledBucketWorkWorkOrders, event.currentIndex, event.previousIndex);
                            this.messageService.addErrorMessage('Could not save the WO order, please refresh and retry.', error);
                        }
                    );
                });
                this.dragAndDropService.emitBucketUpdate(this.bucket);
            } else if (droppedItem instanceof ScheduledBucketWellnessWorkOrder) {
                moveItemInArray(this.bucket.scheduledBucketWellnessWorkOrders, event.previousIndex, event.currentIndex);
                this.bucket.scheduledBucketWellnessWorkOrders.forEach((sbwwo, index) => {
                    sbwwo.order = index;
                    this.scheduledItemBucketService.updateWellnessWorkOrderOrdering(this.bucket.id, sbwwo.wellnessWorkOrderId, index)
                        .subscribe(() => { }
                        , error => {
                            moveItemInArray(this.bucket.scheduledBucketWellnessWorkOrders, event.currentIndex, event.previousIndex);
                            this.messageService.addErrorMessage('Could not save the WO order, please refresh and retry.', error);
                        }
                    );
                });
                this.dragAndDropService.emitBucketUpdate(this.bucket);
            }
            // Can't reorder anything else - including tasks, if they wanna reorder when a task is done they drag the WO
        } else if (
            droppedItem instanceof Employee && this.bucket.scheduledBucketEmployees.find(e => e.employee.id === droppedItem.id) ||
            droppedItem instanceof Equipment && this.bucket.scheduledBucketEquipment.find(e => e.equipment.id === droppedItem.id)) {
            // Do not duplicate employees, do not duplicate equipment
        } else if (droppedItem instanceof Employee) {
            const scheduledBucketEmployee = new ScheduledBucketEmployee();
            scheduledBucketEmployee.employeeId = droppedItem.id;
            scheduledBucketEmployee.scheduledBucketId = this.bucket.id;
            scheduledBucketEmployee.employee = droppedItem;

            this.bucket.scheduledBucketEmployees.push(scheduledBucketEmployee);

            this.scheduledItemBucketService.addEmployeeToBucket(droppedItem.id, this.bucket.id).subscribe(
                resId => {
                    scheduledBucketEmployee.id = resId;
                },
                error => {
                    const employeeIndex = this.bucket.scheduledBucketEmployees.findIndex(sbe => sbe.employeeId === droppedItem.id);
                    if (employeeIndex >= 0) {
                        this.bucket.scheduledBucketEmployees.splice(employeeIndex, 1);
                        this.onBucketUpdate();
                    }
                    this.messageService.addErrorMessage('Unable to add employee, refresh the page and try again.', error);
                }
            );
        } else if (droppedItem instanceof Equipment) {
            const scheduledBucketEquipment = new ScheduledBucketEquipment();
            scheduledBucketEquipment.equipmentId = droppedItem.id;
            scheduledBucketEquipment.scheduledBucketId = this.bucket.id;
            scheduledBucketEquipment.equipment = droppedItem;

            this.bucket.scheduledBucketEquipment.push(scheduledBucketEquipment);

            this.scheduledItemBucketService.addEquipmentToBucket(droppedItem.id, this.bucket.id).subscribe(
                resId => {
                    scheduledBucketEquipment.id = resId;
                },
                error => {
                    const equipmentIndex = this.bucket.scheduledBucketEquipment.findIndex(sbe => sbe.equipmentId === droppedItem.id);
                    if (equipmentIndex >= 0) {
                        this.bucket.scheduledBucketEquipment.splice(equipmentIndex, 1);
                        this.onBucketUpdate();
                    }
                    this.messageService.addErrorMessage('Unable to add equipment, refresh the page and try again.', error);
                }
            );
        } else {
            //////////////////////////////////////////////////////////////////////////////////////////
            // This code runs when Equipment / an Employee / a Work Order is dragged:
            // 1. From the left panel to a Scheduling Bucket.
            //    In this case, only the top part runs.
            // 2. From one Bucket to another.
            //    In this case, both the top and bottom sections run.
            //////////////////////////////////////////////////////////////////////////////////////////

            // Top section
            // If the item is already a scheduled bucket item, then transfer it, otherwise transform it into a scheduled
            // bucket item so it can be added
            if (droppedItem instanceof ScheduledBucketEquipment) {
                transferArrayItem(event.previousContainer.data as any,
                    event.container.data as any,
                    event.previousIndex,
                    event.currentIndex);

                this.scheduledItemBucketService.moveEquipmentToAnotherBucket(droppedItem.id, this.bucket.id).subscribe(
                    () => { }
                    , error => {
                        transferArrayItem(event.container.data as any,
                            event.previousContainer.data as any,
                            event.currentIndex,
                            event.previousIndex);
                        this.messageService.addErrorMessage('Unable to move equipment, refresh the page and try again.', error);
                        this.onBucketUpdate();
                        this.dragAndDropService.emitBucketUpdate(this.dragAndDropService.dragStartBucket);
                    }
                );
            } else if (droppedItem instanceof ScheduledBucketEmployee) {
                transferArrayItem(event.previousContainer.data as any,
                    event.container.data as any,
                    event.previousIndex,
                    event.currentIndex);

                this.scheduledItemBucketService.moveEmployeeToAnotherBucket(droppedItem.id, this.bucket.id).subscribe(
                    () => { }
                    , error => {
                        transferArrayItem(event.container.data as any,
                            event.previousContainer.data as any,
                            event.currentIndex,
                            event.previousIndex);
                        this.messageService.addErrorMessage('Unable to move employee, refresh the page and try again.', error);
                        this.onBucketUpdate();
                        this.dragAndDropService.emitBucketUpdate(this.dragAndDropService.dragStartBucket);
                    }
                );
            } else if (droppedItem instanceof ScheduledBucketWellnessWorkOrder) {
                const matchingWOinBucket = this.bucket.scheduledBucketWellnessWorkOrders
                    .find(sbwwo => sbwwo.wellnessWorkOrderId === droppedItem.wellnessWorkOrderId);

                droppedItem.wellnessWorkOrder.workOrderWellnessTasks.forEach(wowt => {
                    // Only update tasks that were on the old WO
                    if ((!wowt.wellnessTask.isGoBack && wowt.wellnessTask.currentBucketId === this.dragAndDropService.dragStartBucket.id)
                        || (wowt.wellnessTask.isGoBack && wowt.wellnessTask.goBackBucketId === this.dragAndDropService.dragStartBucket.id)) {
                        if (wowt.wellnessTask.isGoBack) {
                            wowt.wellnessTask.goBackBucketId = this.bucket.id;
                        } else {
                            wowt.wellnessTask.currentBucketId = this.bucket.id;
                            wowt.wellnessTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                            wowt.wellnessTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                        }
                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                        wowt.wellnessTask.reschedule = true;

                        if (matchingWOinBucket) {
                            matchingWOinBucket.wellnessWorkOrder.workOrderWellnessTasks
                                .filter(task => task.id === wowt.id)
                                .forEach(task => {
                                    if (task.wellnessTask.isGoBack) {
                                        task.wellnessTask.goBackBucketId = this.bucket.id;
                                    } else {
                                        task.wellnessTask.currentBucketId = this.bucket.id;
                                    }
                                    task.wellnessTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                                    task.wellnessTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                                    task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                    task.wellnessTask.reschedule = true;
                            });
                        }
                    }
                });

                droppedItem.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                this.scheduledItemBucketService.rescheduleWellnessWorkOrder(droppedItem.wellnessWorkOrderId,
                                                                            this.dragAndDropService.dragStartBucket.id,
                                                                            this.bucket.id,
                                                                            event.currentIndex,
                                                                            moment(this.bucket.date).utc().toDate())
                                                                        .subscribe();
                // Remove WO from previous bucket
                this.dragAndDropService.dragStartBucket.scheduledBucketWellnessWorkOrders
                    .splice(this.dragAndDropService.dragStartBucket.scheduledBucketWellnessWorkOrders
                        .findIndex(sbwwo => sbwwo.wellnessWorkOrderId === droppedItem.wellnessWorkOrderId), 1);

                this.dragAndDropService.emitWorkOrderToUpdate(droppedItem.wellnessWorkOrder);

                if (!matchingWOinBucket) {
                    const scheduledBucketWellnessWorkOrder = new ScheduledBucketWellnessWorkOrder();
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrderId = droppedItem.wellnessWorkOrder.id;
                    scheduledBucketWellnessWorkOrder.scheduledBucketId = this.bucket.id;
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrder = droppedItem.wellnessWorkOrder;
                    scheduledBucketWellnessWorkOrder.order = event.currentIndex;
                    this.bucket.scheduledBucketWellnessWorkOrders.splice(event.currentIndex, 0, scheduledBucketWellnessWorkOrder);
                }
            } else if (droppedItem instanceof ScheduledBucketWorkWorkOrder) {
                const matchingWOinBucket = this.bucket.scheduledBucketWorkWorkOrders
                    .find(sbwwo => sbwwo.workWorkOrderId === droppedItem.workWorkOrderId);

                droppedItem.workWorkOrder.workOrderWorkTasks.forEach(wowt => {
                    // Only update tasks that were on the old WO
                    if ((!wowt.workTask.isGoBack && wowt.workTask.currentBucketId === this.dragAndDropService.dragStartBucket.id)
                        || (wowt.workTask.isGoBack && wowt.workTask.goBackBucketId === this.dragAndDropService.dragStartBucket.id)) {
                        if (wowt.workTask.isGoBack) {
                            wowt.workTask.goBackBucketId = this.bucket.id;
                        } else {
                            wowt.workTask.currentBucketId = this.bucket.id;
                            wowt.workTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                            wowt.workTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                        }
                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                        wowt.workTask.reschedule = true;

                        if (matchingWOinBucket) {
                            matchingWOinBucket.workWorkOrder.workOrderWorkTasks
                                .filter(task => task.id === wowt.id)
                                .forEach(task => {
                                    if (task.workTask.isGoBack) {
                                        task.workTask.goBackBucketId = this.bucket.id;
                                    } else {
                                        task.workTask.currentBucketId = this.bucket.id;
                                    }
                                    task.workTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                                    task.workTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                                    task.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                    task.workTask.reschedule = true;
                            });
                        }
                    }
                });

                droppedItem.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                this.scheduledItemBucketService.rescheduleWorkWorkOrder(droppedItem.workWorkOrderId,
                                                                            this.dragAndDropService.dragStartBucket.id,
                                                                            this.bucket.id,
                                                                            event.currentIndex,
                                                                            moment(this.bucket.date).utc().toDate())
                                                                        .subscribe();
                // Remove WO from previous bucket
                this.dragAndDropService.dragStartBucket.scheduledBucketWorkWorkOrders
                    .splice(this.dragAndDropService.dragStartBucket.scheduledBucketWorkWorkOrders
                        .findIndex(sbwwo => sbwwo.workWorkOrderId === droppedItem.workWorkOrderId), 1);

                this.dragAndDropService.emitWorkOrderToUpdate(droppedItem.workWorkOrder);

                if (!matchingWOinBucket) {
                    const scheduledBucketWorkWorkOrder = new ScheduledBucketWorkWorkOrder();
                    scheduledBucketWorkWorkOrder.workWorkOrderId = droppedItem.workWorkOrder.id;
                    scheduledBucketWorkWorkOrder.scheduledBucketId = this.bucket.id;
                    scheduledBucketWorkWorkOrder.workWorkOrder = droppedItem.workWorkOrder;
                    scheduledBucketWorkWorkOrder.order = event.currentIndex;
                    this.bucket.scheduledBucketWorkWorkOrders.splice(event.currentIndex, 0, scheduledBucketWorkWorkOrder);
                }
            } else if (droppedItem instanceof WellnessWorkOrder) {
                // Schedule a whole WO
                const workOrder = droppedItem as WellnessWorkOrder;

                const oldStatus = workOrder.completionStatus;
                workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;

                // Get unscheduled tasks and schedule only those ones
                const tasks = workOrder.workOrderWellnessTasks.filter(wowt => (wowt.wellnessTask.currentBucketId === null
                                                            || (wowt.wellnessTask.goBackBucketId === null && wowt.wellnessTask.isGoBack)
                                                        ) &&
                                                        this.workOrderHelperService.isTaskQualifiedToBeScheduled(wowt.wellnessTask));
                tasks.forEach(workOrderWellnessTask => {
                    workOrderWellnessTask.wellnessTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                    workOrderWellnessTask.wellnessTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                    if (workOrderWellnessTask.wellnessTask.isGoBack) {
                        workOrderWellnessTask.wellnessTask.goBackBucketId = this.bucket.id;
                    } else {
                        workOrderWellnessTask.wellnessTask.currentBucketId = this.bucket.id;
                    }
                    workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                });

                // Take the WO out of the unfiltered work order list in the work order panel component
                // Only if all tasks are scheduled
                if (this.dragAndDropService.workOrderPanel &&
                    workOrder.workOrderWellnessTasks.every(wowt => wowt.wellnessTask.currentBucketId !== null &&
                                    (!wowt.wellnessTask.isGoBack || wowt.wellnessTask.goBackBucketId !== null))) {
                    const index: number = this.dragAndDropService.workOrderPanel.wellnessWorkOrders.findIndex(wo => wo.id === workOrder.id);
                    if (index >= 0) {
                        this.dragAndDropService.workOrderPanel.wellnessWorkOrders.splice(index, 1);
                        this.dragAndDropService.workOrderPanel.filterBarComponent.filterWorkOrders();
                    }
                }

                this.dragAndDropService.emitWorkOrderToUpdate(workOrder);
                this.dragAndDropService.emitBucketUpdate(this.bucket);

                const matchingWOinBucket = this.bucket.scheduledBucketWellnessWorkOrders
                    .find(sbwwo => sbwwo.wellnessWorkOrderId === workOrder.id);

                // Only add new scheduled bucket wo if it isn't already on the bucket
                if (!matchingWOinBucket) {
                    const scheduledBucketWellnessWorkOrder = new ScheduledBucketWellnessWorkOrder();
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrderId = workOrder.id;
                    scheduledBucketWellnessWorkOrder.scheduledBucketId = this.bucket.id;
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrder = workOrder;
                    scheduledBucketWellnessWorkOrder.order = event.currentIndex;
                    this.bucket.scheduledBucketWellnessWorkOrders.splice(event.currentIndex, 0, scheduledBucketWellnessWorkOrder);
                } else {
                    matchingWOinBucket.wellnessWorkOrder.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.currentBucketId === null
                                                            || (wowt.wellnessTask.isGoBack && wowt.wellnessTask.goBackBucketId === null))
                        .forEach(workOrderWellnessTask => {
                            workOrderWellnessTask.wellnessTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                            workOrderWellnessTask.wellnessTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                            if (workOrderWellnessTask.wellnessTask.isGoBack) {
                                workOrderWellnessTask.wellnessTask.goBackBucketId = this.bucket.id;
                            } else {
                                workOrderWellnessTask.wellnessTask.currentBucketId = this.bucket.id;
                            }
                            workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                        });
                    this.dragAndDropService.emitWorkOrderToUpdate(matchingWOinBucket);
                }

                // update DB WO
                this.wellnessWorkOrderService.scheduleWorkOrder(workOrder.id,
                    tasks.map(task => task.wellnessTask.id),
                    this.bucket.id,
                    moment(this.bucket.date).utc().toDate(),
                    event.currentIndex)
                .subscribe(() => {},
                    error => {
                        this.messageService.addErrorMessage('Unable to schedule WO, refresh the page and try again.', error);
                        // undo what we just did in the UI, reset status, clear bucket info
                        workOrder.completionStatus = oldStatus;
                        tasks.forEach(workOrderWellnessTask => {
                            workOrderWellnessTask.wellnessTask.scheduleDateFrom = undefined;
                            workOrderWellnessTask.wellnessTask.scheduleDateTo = undefined;
                            if (workOrderWellnessTask.wellnessTask.isGoBack) {
                                workOrderWellnessTask.wellnessTask.goBackBucketId = undefined;
                            } else {
                                workOrderWellnessTask.wellnessTask.currentBucketId = undefined;
                            }
                            workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                        });

                        const bucketWO = this.bucket.scheduledBucketWellnessWorkOrders
                            .find(sbwwo => sbwwo.wellnessWorkOrderId === workOrder.id);

                        // if there aren't any more tasks in the bucket for that WO, then remove it
                        if (bucketWO.wellnessWorkOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTask.currentBucketId === this.bucket.id
                                                                                            || wowt.wellnessTask.goBackBucketId === this.bucket.id) === undefined) {
                            this.bucket.scheduledBucketWellnessWorkOrders.splice(event.currentIndex, 1);
                        }

                        if (this.dragAndDropService.workOrderPanel &&
                            this.dragAndDropService.workOrderPanel.wellnessWorkOrders.find(wo => wo.id === workOrder.id) === undefined) {
                                this.dragAndDropService.workOrderPanel.wellnessWorkOrders.push(workOrder);
                                this.dragAndDropService.workOrderPanel.filterBarComponent.filterWorkOrders();
                        }

                        this.dragAndDropService.emitWorkOrderToUpdate(workOrder);
                        this.dragAndDropService.emitBucketUpdate(this.bucket);
                    });
            } else if (droppedItem instanceof WorkOrderWellnessTask) {
                // Scheduling one task
                let workOrder: WellnessWorkOrder = this.dragAndDropService.dragStartWO as WellnessWorkOrder;
                workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                if (droppedItem.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Customer_Notified) {
                    // If customer has been notified already, mark the task as rescheduled so the reschedule email is sent on notification
                    droppedItem.wellnessTask.reschedule = true;
                }

                this.scheduledItemBucketService.scheduleTask(new ScheduleTaskDTO({
                    taskId: droppedItem.wellnessTask.id,
                    workOrderId: workOrder.id,
                    bucketId: this.bucket.id,
                    order: event.currentIndex,
                    type: ScheduleTaskDTOType._0
                })).subscribe(() => {

                }, error => {
                    this.messageService.addErrorMessage('Could not save the task, please refresh and retry.', error);
                });
                workOrder = this.dragAndDropService.dragStartWO as WellnessWorkOrder;

                droppedItem.wellnessTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                droppedItem.wellnessTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();

                if (droppedItem.wellnessTask.isGoBack) {
                    droppedItem.wellnessTask.goBackBucketId = this.bucket.id;
                } else {
                    droppedItem.wellnessTask.currentBucketId = this.bucket.id;
                }
                droppedItem.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;

                // this.wellnessWorkOrderService.update(workOrder, workOrder.id.toString()).subscribe();
                this.dragAndDropService.emitWorkOrderToUpdate(workOrder);

                // Take the WO out of the unfiltered work order list in the work order panel component
                // Only if all tasks are scheduled
                if (this.dragAndDropService.workOrderPanel &&
                    workOrder.workOrderWellnessTasks.every(wowt => wowt.wellnessTask.currentBucketId !== null
                                                                && (wowt.wellnessTask.goBackBucketId !== null || !wowt.wellnessTask.isGoBack))) {
                    const index: number = this.dragAndDropService.workOrderPanel.wellnessWorkOrders.findIndex(wo => wo.id === workOrder.id);
                    if (index >= 0) {
                        this.dragAndDropService.workOrderPanel.wellnessWorkOrders.splice(index, 1);
                        this.dragAndDropService.workOrderPanel.filterBarComponent.filterWorkOrders();
                    }
                }

                const existingBucketWO = this.bucket.scheduledBucketWellnessWorkOrders
                    .find(sbwwo => sbwwo.wellnessWorkOrderId === workOrder.id);

                if (existingBucketWO) {
                    // Add to existing WO card
                    const matchingTask = existingBucketWO.wellnessWorkOrder.workOrderWellnessTasks
                        .find(wowt => wowt.wellnessTaskId === droppedItem.wellnessTaskId);
                    if (matchingTask.wellnessTask.isGoBack) {
                        matchingTask.wellnessTask.goBackBucketId = this.bucket.id;
                    } else {
                        matchingTask.wellnessTask.currentBucketId = this.bucket.id;
                    }
                    matchingTask.wellnessTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                    matchingTask.wellnessTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                    // this.wellnessWorkOrderService.update(workOrder, workOrder.id.toString()).subscribe();
                    this.dragAndDropService.emitWorkOrderToUpdate(workOrder);
                } else {
                    // Create new bucket WO to contain the scheduled wellness task
                    const scheduledBucketWellnessWorkOrder = new ScheduledBucketWellnessWorkOrder();
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrderId = workOrder.id;
                    scheduledBucketWellnessWorkOrder.scheduledBucketId = this.bucket.id;
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrder = workOrder;
                    scheduledBucketWellnessWorkOrder.wellnessWorkOrderId = workOrder.id;
                    scheduledBucketWellnessWorkOrder.order = event.currentIndex;
                    this.bucket.scheduledBucketWellnessWorkOrders.splice(event.currentIndex, 0, scheduledBucketWellnessWorkOrder);
                }
            } else if (droppedItem instanceof WorkWorkOrder) {
                // Schedule a whole WO
                const workOrder = droppedItem as WorkWorkOrder;

                const oldStatus = workOrder.completionStatus;
                workOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;

                // Get unscheduled tasks and schedule only those ones
                const tasks = workOrder.workOrderWorkTasks.filter(wowt => (wowt.workTask.currentBucketId === null
                                                            || (wowt.workTask.goBackBucketId === null && wowt.workTask.isGoBack)
                                                        ) &&
                                                        this.workOrderHelperService.isTaskQualifiedToBeScheduled(wowt.workTask));
                tasks.forEach(workOrderWorkTask => {
                    workOrderWorkTask.workTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                    workOrderWorkTask.workTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                    if (workOrderWorkTask.workTask.isGoBack) {
                        workOrderWorkTask.workTask.goBackBucketId = this.bucket.id;
                    } else {
                        workOrderWorkTask.workTask.currentBucketId = this.bucket.id;
                    }
                    workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                });

                // Take the WO out of the unfiltered work order list in the work order panel component
                // Only if all tasks are scheduled
                if (this.dragAndDropService.workOrderPanel &&
                    workOrder.workOrderWorkTasks.every(wowt => wowt.workTask.currentBucketId !== null &&
                                    (!wowt.workTask.isGoBack || wowt.workTask.goBackBucketId !== null))) {
                    const index: number = this.dragAndDropService.workOrderPanel.workWorkOrders.findIndex(wo => wo.id === workOrder.id);
                    if (index >= 0) {
                        this.dragAndDropService.workOrderPanel.workWorkOrders.splice(index, 1);
                        this.dragAndDropService.workOrderPanel.filterBarComponent.filterWorkOrders();
                    }
                }

                this.dragAndDropService.emitWorkOrderToUpdate(workOrder);
                this.dragAndDropService.emitBucketUpdate(this.bucket);

                const matchingWOinBucket = this.bucket.scheduledBucketWorkWorkOrders
                    .find(sbwwo => sbwwo.workWorkOrderId === workOrder.id);

                // Only add new scheduled bucket wo if it isn't already on the bucket
                if (!matchingWOinBucket) {
                    const scheduledBucketWorkWorkOrder = new ScheduledBucketWorkWorkOrder();
                    scheduledBucketWorkWorkOrder.workWorkOrderId = workOrder.id;
                    scheduledBucketWorkWorkOrder.scheduledBucketId = this.bucket.id;
                    scheduledBucketWorkWorkOrder.workWorkOrder = workOrder;
                    scheduledBucketWorkWorkOrder.order = event.currentIndex;
                    this.bucket.scheduledBucketWorkWorkOrders.splice(event.currentIndex, 0, scheduledBucketWorkWorkOrder);
                } else {
                    matchingWOinBucket.workWorkOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.currentBucketId === null
                                                            || (wowt.workTask.isGoBack && wowt.workTask.goBackBucketId === null))
                        .forEach(workOrderWorkTask => {
                            workOrderWorkTask.workTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                            workOrderWorkTask.workTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                            if (workOrderWorkTask.workTask.isGoBack) {
                                workOrderWorkTask.workTask.goBackBucketId = this.bucket.id;
                            } else {
                                workOrderWorkTask.workTask.currentBucketId = this.bucket.id;
                            }
                            workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                        });
                    this.dragAndDropService.emitWorkOrderToUpdate(matchingWOinBucket);
                }

                // update DB WO
                this.workWorkOrderService.scheduleWorkOrder(workOrder.id,
                    tasks.map(task => task.workTask.id),
                    this.bucket.id,
                    moment(this.bucket.date).utc().toDate(),
                    event.currentIndex)
                .subscribe(() => {},
                    error => {
                        this.messageService.addErrorMessage('Unable to schedule WO, refresh the page and try again.', error);
                        // undo what we just did in the UI, reset status, clear bucket info
                        workOrder.completionStatus = oldStatus;
                        tasks.forEach(workOrderWorkTask => {
                            workOrderWorkTask.workTask.scheduleDateFrom = undefined;
                            workOrderWorkTask.workTask.scheduleDateTo = undefined;
                            if (workOrderWorkTask.workTask.isGoBack) {
                                workOrderWorkTask.workTask.goBackBucketId = undefined;
                            } else {
                                workOrderWorkTask.workTask.currentBucketId = undefined;
                            }
                            workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                        });

                        const bucketWO = this.bucket.scheduledBucketWorkWorkOrders
                            .find(sbwwo => sbwwo.workWorkOrderId === workOrder.id);

                        // if there aren't any more tasks in the bucket for that WO, then remove it
                        if (bucketWO.workWorkOrder.workOrderWorkTasks.find(wowt => wowt.workTask.currentBucketId === this.bucket.id
                                                                                            || wowt.workTask.goBackBucketId === this.bucket.id) === undefined) {
                            this.bucket.scheduledBucketWorkWorkOrders.splice(event.currentIndex, 1);
                        }

                        if (this.dragAndDropService.workOrderPanel &&
                            this.dragAndDropService.workOrderPanel.workWorkOrders.find(wo => wo.id === workOrder.id) === undefined) {
                                this.dragAndDropService.workOrderPanel.workWorkOrders.push(workOrder);
                                this.dragAndDropService.workOrderPanel.filterBarComponent.filterWorkOrders();
                        }

                        this.dragAndDropService.emitWorkOrderToUpdate(workOrder);
                        this.dragAndDropService.emitBucketUpdate(this.bucket);
                    });
            } else if (droppedItem instanceof WorkOrderWorkTask) {
                // Scheduling one task
                let workOrder: WorkWorkOrder = this.dragAndDropService.dragStartWO as WorkWorkOrder;
                workOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                if (droppedItem.workTask.completionStatus === WorkTaskCompletionStatus.Customer_Notified) {
                    // If customer has been notified already, mark the task as rescheduled so the reschedule email is sent on notification
                    droppedItem.workTask.reschedule = true;
                }

                workOrder = this.dragAndDropService.dragStartWO as WorkWorkOrder;

                this.scheduledItemBucketService.scheduleTask(new ScheduleTaskDTO({
                    taskId: droppedItem.workTask.id,
                    workOrderId: workOrder.id,
                    bucketId: this.bucket.id,
                    order: event.currentIndex,
                    type: ScheduleTaskDTOType._1
                })).subscribe(() => {

                }, error => {
                    this.messageService.addErrorMessage('Could not save the task, please refresh and retry.', error);
                });
                droppedItem.workTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                droppedItem.workTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();

                if (droppedItem.workTask.isGoBack) {
                    droppedItem.workTask.goBackBucketId = this.bucket.id;
                } else {
                    droppedItem.workTask.currentBucketId = this.bucket.id;
                }

                droppedItem.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;

                // this.workWorkOrderService.update(workOrder, workOrder.id.toString()).subscribe();
                this.dragAndDropService.emitWorkOrderToUpdate(workOrder);

                // Take the WO out of the unfiltered work order list in the work order panel component
                // Only if all tasks are scheduled
                if (this.dragAndDropService.workOrderPanel &&
                    workOrder.workOrderWorkTasks.every(wowt => wowt.workTask.currentBucketId !== null
                        && (wowt.workTask.goBackBucketId !== null || !wowt.workTask.isGoBack))) {
                    const index: number = this.dragAndDropService.workOrderPanel.workWorkOrders.findIndex(wo => wo.id === workOrder.id);
                    if (index >= 0) {
                        this.dragAndDropService.workOrderPanel.workWorkOrders.splice(index, 1);
                        this.dragAndDropService.workOrderPanel.filterBarComponent.filterWorkOrders();
                    }
                }

                const existingBucketWO = this.bucket.scheduledBucketWorkWorkOrders
                    .find(sbwwo => sbwwo.workWorkOrderId === workOrder.id);

                if (existingBucketWO) {
                    // Add to existing WO card
                    const matchingTask = existingBucketWO.workWorkOrder.workOrderWorkTasks
                        .find(wowt => wowt.workTaskId === droppedItem.workTaskId);
                    if (matchingTask.workTask.isGoBack) {
                        matchingTask.workTask.goBackBucketId = this.bucket.id;
                    } else {
                        matchingTask.workTask.currentBucketId = this.bucket.id;
                    }
                    matchingTask.workTask.scheduleDateFrom = moment(this.bucket.date).utc().toDate();
                    matchingTask.workTask.scheduleDateTo = moment(this.bucket.date).utc().toDate();
                    // this.workWorkOrderService.update(workOrder, workOrder.id.toString()).subscribe();
                    this.dragAndDropService.emitWorkOrderToUpdate(workOrder);
                } else {
                    // Create new bucket WO to contain the scheduled wellness task
                    const scheduledBucketWorkWorkOrder = new ScheduledBucketWorkWorkOrder();
                    scheduledBucketWorkWorkOrder.workWorkOrderId = workOrder.id;
                    scheduledBucketWorkWorkOrder.scheduledBucketId = this.bucket.id;
                    scheduledBucketWorkWorkOrder.workWorkOrder = workOrder;
                    scheduledBucketWorkWorkOrder.workWorkOrderId = workOrder.id;
                    scheduledBucketWorkWorkOrder.order = event.currentIndex;
                    this.bucket.scheduledBucketWorkWorkOrders.splice(event.currentIndex, 0, scheduledBucketWorkWorkOrder);
                }
            }

            this.orderWorkOrders();

            this.dragAndDropService.emitBucketUpdate(this.bucket);

            //////////////////////////////////////////////////////////////////////////////////////////
            // Bottom section
            // Handle when items are dragged from one bucket to another
            //////////////////////////////////////////////////////////////////////////////////////////
            if (this.bucket && this.dragAndDropService.dragStartBucket && this.dragAndDropService.dragStartBucket.id !== this.bucket.id) {
                var sbWellnessWOToRemove = this.dragAndDropService.dragStartBucket.scheduledBucketWellnessWorkOrders.find(sbwwo =>
                    sbwwo.wellnessWorkOrder.workOrderWellnessTasks.every(wowt => {
                        return wowt.wellnessTask.currentBucketId !== this.dragAndDropService.dragStartBucket.id
                                && wowt.wellnessTask.goBackBucketId !== this.dragAndDropService.dragStartBucket.id;
                    }));

                if (sbWellnessWOToRemove) {
                    var deleted = this.dragAndDropService.dragStartBucket.scheduledBucketWellnessWorkOrders
                        .splice(this.dragAndDropService.dragStartBucket.scheduledBucketWellnessWorkOrders
                            .findIndex(sbwwo => sbwwo.wellnessWorkOrderId === sbWellnessWOToRemove.wellnessWorkOrderId), 1);
                }
                
                var sbWorkWOToRemove = this.dragAndDropService.dragStartBucket.scheduledBucketWorkWorkOrders.find(sbwwo =>
                    sbwwo.workWorkOrder.workOrderWorkTasks.every(wowt => {
                        return wowt.workTask.currentBucketId !== this.dragAndDropService.dragStartBucket.id
                                && wowt.workTask.goBackBucketId !== this.dragAndDropService.dragStartBucket.id;
                    }));

                if (sbWorkWOToRemove) {
                    var deleted2 = this.dragAndDropService.dragStartBucket.scheduledBucketWorkWorkOrders
                        .splice(this.dragAndDropService.dragStartBucket.scheduledBucketWorkWorkOrders
                            .findIndex(sbwwo => sbwwo.workWorkOrderId === sbWorkWOToRemove.workWorkOrderId), 1);
                }
                this.dragAndDropService.emitBucketUpdate(this.dragAndDropService.dragStartBucket);
            }
        }

        this.validateItem();
        this.setCrewForemanAndEquipment();
        this.setUpProgressBar();
    }
}
