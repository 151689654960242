<br>
<div class="p-grid">
  <div class="p-col-12">
    <h4>Equipment Type Information</h4>
  </div>
</div>
<br>
<form id="equipmentTypeForm" #equipmentTypeForm="ngForm">
  <div class="p-grid">
    <div class="p-col-12 p-lg-8">
      <h6>Equipment Type Name</h6>
      <input pInputText #typeNameInput required name="type" class="inputTextBox"  *ngIf="equipmentType" [(ngModel)]="equipmentType.type"/>
    </div>
    <div class="p-col-12 p-lg-2">
      <h6>Abbreviation</h6>
      <input pInputText #abbreviationInput maxlength="50" name="abbreviation" class="inputTextBox"  *ngIf="equipmentType" [(ngModel)]="equipmentType.abbreviation"/>
    </div>
    <div class="p-col-12 p-lg-2 pushContentsToBottom">
      <button 
        pButton
        type="submit"
        (click)="save(equipmentTypeForm)"
        class="ui-button-raised ui-button-success buttonStyle"
        [disabled]="saving"
        label="Save">
      </button>
      <button style="margin-left: 8px;"
        pButton 
        type="button" 
        class="ui-button-raised ui-button-success buttonStyle" 
        (click)="clear(equipmentTypeForm);" 
        label="Clear">
      </button>
    </div>
  </div>
  <div class="p-col-12 columnPadding">
    <div *ngIf="equipmentTypeForm.invalid && equipmentTypeForm.submitted" class="alert alert-danger" role="alert">
      There are errors on this page. Please correct the errors and resubmit.
    </div>
  </div>
</form>
<br>
<br>
<p-table [columns]="cols" [value]="equipmentTypes" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-equipmentType
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{equipmentType.type}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{equipmentType.abbreviation}}</td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="edit(equipmentType)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="delete(equipmentType)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
<br>
