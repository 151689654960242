import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Customer } from 'src/app/services_autogenerated/generated_services';
import { PaymentType } from 'src/app/models/paymentType';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { TokenResult } from 'ngx-stripe';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { StripeGenSvc, CustomerGenSvc } from 'src/app/services_autogenerated/generated_services';
import { StripeBillingComponent } from 'src/app/components/stripe-billing/stripe-billing.component';
import { Router } from '@angular/router';
import { StripeBilling, PlaidLinkSuccessDTO } from '../../../services_autogenerated/generated_services';
import { BillingProfileEntryComponent } from '../billing-profile-entry/billing-profile-entry.component';

declare var Plaid: any;

@Component({
  selector: 'app-billing-profile-entry-modal',
  templateUrl: './billing-profile-entry-modal.component.html',
  styleUrls: ['./billing-profile-entry-modal.component.css']
})
export class BillingProfileEntryModalComponent {

  @ViewChild(BillingProfileEntryComponent) billingProfileEntry: BillingProfileEntryComponent;
  @Input() customer: Customer;
  @Input() stripeBilling: StripeBilling = null;
  @Input() display: boolean = false;
  @Output() emitClose = new EventEmitter();
  @Output() emitUpdatedStripeCustomerId = new EventEmitter<string>();

  isCustomer: boolean;

  constructor() { }

  save() {
    this.billingProfileEntry.save();
  }

  afterSave(stripeCustomerId: string) {
    //if (this.customer.stripeCustomerId) {
      this.emitUpdatedStripeCustomerId.emit(stripeCustomerId);
    //}
  }

  cancel() {
    this.emitClose.emit();
  }
}
