import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-multi-factor',
  templateUrl: './multi-factor.component.html',
  styleUrls: ['./multi-factor.component.css']
})
export class MultiFactorComponent implements OnInit {
  public action: string;
  public userPreference: string;

  constructor(private route: ActivatedRoute,
    private authHelper: AuthHelperService
  ) { }

  ngOnInit() {
    this.userPreference = this.authHelper.getDecodedAccessToken().multiFactorAuthPreference;
    this.route.params
      .subscribe(params => {
        this.action = params.action;
      });
  }

}
