import { Injectable } from '@angular/core';
import { WorkOrderWorkTask, QuoteWorkOrder, WorkWorkOrder, WorkTask } from '../services_autogenerated/generated_services';

@Injectable({
  providedIn: 'root'
})
export class WorkWorkOrderMaintenanceService {

  constructor() { }

  removeWorkTaskDependentReferences(workOrder: WorkWorkOrder | QuoteWorkOrder, deleted: WorkOrderWorkTask) {
    workOrder.workOrderWorkTasks.map(task => {
        if (this.isWorkTaskIsDependent(task.workTask, deleted)) {
            task.workTask.schedulableAfterCompletionOfId = null;
            task.workTask.schedulableAfterCompletionOf = null;
        }
    });
}

isWorkTaskIsDependent(task: WorkTask, wowt: WorkOrderWorkTask): boolean {
    if (task) {
        const hasMatchingId = wowt.workTaskId && task.schedulableAfterCompletionOfId == wowt.workTaskId;
        const hasMatchingReference = task.schedulableAfterCompletionOf == wowt.workTask;
        if (hasMatchingId || hasMatchingReference) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
}
