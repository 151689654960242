<span class="regular-link" (click)="openInvoices()" style="margin-bottom: 3px">
  [Invoices]
</span>
<span class="regular-link" *ngIf="canCollectCash()" (click)="addCash()" style="margin-bottom: 3px">
  <br/>
  [Collect Cash/Check]
</span>
<span [ngClass]="workOrder.hasHourlyRateTask ? 'disabled-link' : 'regular-link'" *ngIf="canChargeCard()" (click)="chargeInvoice()">
  <br/>
  [Charge Credit Card]
</span>
<span [ngClass]="workOrder.hasHourlyRateTask ? 'disabled-link' : 'regular-link'" *ngIf="canCloseOut()" (click)="finalize()">
  <br/>
  [Finalize and Mark Paid]
</span>
<span *ngIf="canCollectCash()" (click)="resendInvoice()" style="margin-bottom: 3px" [ngClass]="isSending ? 'disabled-link' : 'regular-link'">
  <br/>
  [Resend Invoice]
</span>

<span class="regular-link" *ngIf="nonPdfReceiptCount > 0" (click)="openNonPDFReceipts()" style="margin-bottom: 3px">
  <br/>
  {{nonPdfReceiptCount > 1 ? '[View Card Receipts]' : '[View Card Receipt]'}}
</span>
<span class="regular-link" *ngIf="pdfReceiptCount > 0" (click)="openPDFReceipts()" style="margin-bottom: 3px">
  <br/>
  {{pdfReceiptCount > 1 ? '[Download Cash Payment PDF Receipts]' : '[Download Cash Payment PDF Receipt]'}}
</span>
<span [ngClass]="isSending ? 'disabled-link' : 'regular-link'" *ngIf="nonPdfReceiptCount > 0 || pdfReceiptCount > 0" (click)="sendReceiptsToCustomer()" style="margin-bottom: 3px;">
  <br/>
  [Email Receipt]
</span>

<span class="regular-link" *ngIf="nonPdfReceiptCount > 0" (click)="openRefund()">
  <br/>
  [Refund]
</span>