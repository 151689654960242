import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { PriorityLevel,
  WellnessWorkOrder,
  PriorityLevelGenSvc,
  WorkWorkOrder,
  QuoteWorkOrder,
  Employee,
} from '../../services_autogenerated/generated_services';
import { WorkOrderFilterPipe } from 'src/app/pipes/work-order-filter.pipe';
import { FilteredWellnessWorkOrder } from 'src/app/models/filteredWellnessWorkOrder';
import { FilteredWorkWorkOrder } from 'src/app/models/filteredWorkWorkOrder';
import { FilteredQuoteWorkOrder } from 'src/app/models/filteredQuoteWorkOrder';
import { CalculatedPriorityType } from 'src/app/models/calculatedPriorityType';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-work-order-filter-bar',
  templateUrl: './work-order-filter-bar.component.html',
  styleUrls: ['./work-order-filter-bar.component.css']
})
export class WorkOrderFilterBarComponent implements OnInit, OnChanges {
  @Input() isDayView: boolean;
  @Input() typeOfWorkOrders: ('quote' | 'work' | 'wellness') = null;
  @Input() allWorkOrders: (WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder)[];
  @Input() rms: Employee[];
  @Input() useSearchDelay = true;

  searchInput = new Subject<string>();

  priorityLevels: PriorityLevel[];
  searchTerm = '';

  filteredWorkOrders: (FilteredWellnessWorkOrder | FilteredWorkWorkOrder | FilteredQuoteWorkOrder)[];

  shouldFilterByGDD = false;
  shouldFilterByDueDate = false;
  shouldFilterByType = false;
  shouldFilterByGeoRegion = false;
  shouldFilterByPriority = false;
  shouldFilterByRM = false;
  dueDateFilterFromDate: Date;
  dueDateFilterToDate: Date;
  geoRegionSearchRadius: number;
  geoRegionWorkOrderNumber: string;
  geoRegionWorkOrderFindError: string;
  geoRegionWorkOrderAddressError: string;
  selectedPriorities: PriorityLevel[] = [];
  selectedRMs: Employee[] = [];
  gddStart: number;
  gddEnd: number;

  @Output() OnWoFilteringFinished = new EventEmitter<(FilteredWellnessWorkOrder | FilteredWorkWorkOrder | FilteredQuoteWorkOrder)[]>();

  constructor(
    private priorityService: PriorityLevelGenSvc,
    private dragAndDropService: DragAndDropService,
    private workOrderFilter: WorkOrderFilterPipe,
  ) {
   }

  ngOnInit() {
    this.setUpSearchDelay();
    this.priorityService.getAll().subscribe(p => {

      const keys = Object.keys(CalculatedPriorityType);
      const values = keys.map(el => Object(CalculatedPriorityType)[el]);

      const calculatedPriorityTypes = values.map(x => new PriorityLevel({
        id: -1,
        active: true,
        status: x.toString(),
      })).filter(x => x.status !== 'Error');
      this.priorityLevels = calculatedPriorityTypes.concat(p);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allWorkOrders && this.allWorkOrders) {
      this.filterWorkOrders();
    }
  }

  setUpSearchDelay() {
    if (this.useSearchDelay) {
      this.searchInput.pipe(
        debounceTime(600),
        distinctUntilChanged())
        .subscribe(value => {
          this.filterWorkOrders();
        });
    } else {
      this.searchInput.pipe(
        distinctUntilChanged())
        .subscribe(value => {
          this.filterWorkOrders();
        });
    }
  }

  filterWorkOrders(): void {
    if (this.allWorkOrders) {
      let temp;
      const isWellnessOnly = this.typeOfWorkOrders === 'wellness';
      const isTreeWorkOnly = this.typeOfWorkOrders === 'work';
      const isQuote = this.typeOfWorkOrders === 'quote';

      if (isWellnessOnly) {
        if (this.searchTerm) {
          temp = this.workOrderFilter.transform(this.allWorkOrders as WellnessWorkOrder[], this.searchTerm) as FilteredWellnessWorkOrder[];
        } else {
          temp = this.allWorkOrders as FilteredWellnessWorkOrder[];
        }
      } else if (isTreeWorkOnly) {
        if (this.searchTerm) {
          temp = this.workOrderFilter.transformWork(this.allWorkOrders as WorkWorkOrder[], this.searchTerm) as FilteredWorkWorkOrder[];
        } else {
          temp = this.allWorkOrders as FilteredWorkWorkOrder[];
        }
      } else if (isQuote) {
        if (this.searchTerm) {
          temp = this.workOrderFilter.transformQuote(this.allWorkOrders as QuoteWorkOrder[], this.searchTerm) as FilteredQuoteWorkOrder[];
        } else {
          temp = this.allWorkOrders as FilteredQuoteWorkOrder[];
        }
      }

      if (this.shouldFilterByGDD && isWellnessOnly) {
        temp = this.workOrderFilter.filterByGDD(temp, this.gddStart, this.gddEnd);
      }
      if (this.shouldFilterByDueDate && moment(this.dueDateFilterFromDate).isSameOrBefore(moment(this.dueDateFilterToDate))) {
        temp = this.workOrderFilter.filterByDueDate(temp, this.dueDateFilterFromDate, this.dueDateFilterToDate);
      }
      if (this.shouldFilterByGeoRegion) {
        let searchWorkOrder = this.allWorkOrders.find(x =>
          this.geoRegionWorkOrderNumber ? x.quoteNumber.toLowerCase().includes(this.geoRegionWorkOrderNumber.toLowerCase()) : null);

        // WO isn't in the work order panel, so check the schedule buckets
        if (!searchWorkOrder && this.dragAndDropService.dayBucketIndexRecords) {
          searchWorkOrder = this.dragAndDropService.dayBucketIndexRecords
            .map(sb => sb.scheduledBucketWellnessWorkOrders)
            .reduce((acc, curr) => acc.concat(curr), [])
            .map(sbwwo => sbwwo.wellnessWorkOrder)
            .find(x => x.quoteNumber === this.geoRegionWorkOrderNumber);
        }

        if (!searchWorkOrder && this.dragAndDropService.dayBucketIndexRecords) {
          searchWorkOrder = this.dragAndDropService.dayBucketIndexRecords
            .map(sb => sb.scheduledBucketWorkWorkOrders)
            .reduce((acc, curr) => acc.concat(curr), [])
            .map(sbwwo => sbwwo.workWorkOrder)
            .find(x => x.quoteNumber === this.geoRegionWorkOrderNumber);
        }

        if (searchWorkOrder && searchWorkOrder.address.latitude && searchWorkOrder.address.longitude) {
          this.geoRegionWorkOrderFindError = '';
          this.geoRegionWorkOrderAddressError = '';
          temp = this.workOrderFilter.filterByGeoRegion(temp, searchWorkOrder, this.geoRegionSearchRadius);
          temp.sort((a, b) => a.distance - b.distance);
        } else {
          if (!searchWorkOrder) {
            this.geoRegionWorkOrderFindError = 'Work Order not found.';
          } else if (!searchWorkOrder.address.latitude || !searchWorkOrder.address.longitude) {
            this.geoRegionWorkOrderFindError = 'Work Order does not have correct GPS coordinates.';
          }
        }
      }
      if (this.shouldFilterByPriority && this.selectedPriorities.length > 0) {
        temp = this.workOrderFilter.filterByPriority(temp, this.selectedPriorities.map(sp => sp.status));
      }
      if (this.shouldFilterByRM && this.selectedRMs.length > 0) {
        temp = this.workOrderFilter.filterByRM(temp, this.selectedRMs);
      }

      if (isWellnessOnly) {
        this.filteredWorkOrders = temp as FilteredWellnessWorkOrder[];
      } else if (isTreeWorkOnly) {
        this.filteredWorkOrders = temp as FilteredWorkWorkOrder[];
      } else {
        this.filteredWorkOrders = temp as FilteredQuoteWorkOrder[];
      }
    }

    this.OnWoFilteringFinished.emit(this.filteredWorkOrders);
  }

  private filterWorkWorkOrders() {
    if (this.allWorkOrders.length > 0) {
      let temp = this.allWorkOrders as FilteredWorkWorkOrder[];

      if (this.searchTerm) {
        temp = this.workOrderFilter.transformWork(this.allWorkOrders as WorkWorkOrder[], this.searchTerm);
      }

      if (this.shouldFilterByDueDate) {
        temp = this.workOrderFilter.filterByDueDate(temp, this.dueDateFilterFromDate, this.dueDateFilterToDate) as FilteredWorkWorkOrder[];
      }
      if (this.shouldFilterByGeoRegion) {
        const searchWorkOrder = this.allWorkOrders.find(x => x.quoteNumber === this.geoRegionWorkOrderNumber);

        if (searchWorkOrder && searchWorkOrder.address.latitude && searchWorkOrder.address.longitude) {
          this.geoRegionWorkOrderFindError = '';
          this.geoRegionWorkOrderAddressError = '';
          temp = this.workOrderFilter.filterByGeoRegion(temp, searchWorkOrder, this.geoRegionSearchRadius) as FilteredWorkWorkOrder[];
          temp.sort((a, b) => a.distance - b.distance);
        } else {
          if (!searchWorkOrder) {
            this.geoRegionWorkOrderFindError = 'Work Order not found.';
          } else if (!searchWorkOrder.address.latitude || !searchWorkOrder.address.longitude) {
            this.geoRegionWorkOrderFindError = 'Work Order does not have correct GPS coordinates.';
          }
        }
      }
      if (this.shouldFilterByPriority) {
        temp = this.workOrderFilter.filterByPriority(temp, this.selectedPriorities.map(sp => sp.status)) as FilteredWorkWorkOrder[];
      }

      this.filteredWorkOrders = temp;
    }
  }

  private filterWellnessWorkOrders() {
    if (this.allWorkOrders.length > 0) {
      let temp = this.allWorkOrders as FilteredWellnessWorkOrder[];

      if (this.searchTerm) {
        temp = this.workOrderFilter.transform(this.allWorkOrders as FilteredWellnessWorkOrder[], this.searchTerm);
      }

      // Only wellness has GDD
      if (this.shouldFilterByGDD) {
        temp = this.workOrderFilter.filterByGDD(temp, this.gddStart, this.gddEnd) as FilteredWellnessWorkOrder[];
      }
      if (this.shouldFilterByDueDate) {
        temp = this.workOrderFilter.filterByDueDate(
          temp, this.dueDateFilterFromDate, this.dueDateFilterToDate) as FilteredWellnessWorkOrder[];
      }

      if (this.shouldFilterByGeoRegion) {
        const searchWorkOrder = this.allWorkOrders.find(x => x.quoteNumber === this.geoRegionWorkOrderNumber);

        if (searchWorkOrder && searchWorkOrder.address.latitude && searchWorkOrder.address.longitude) {
          this.geoRegionWorkOrderFindError = '';
          this.geoRegionWorkOrderAddressError = '';
          temp = this.workOrderFilter.filterByGeoRegion(temp, searchWorkOrder, this.geoRegionSearchRadius) as FilteredWellnessWorkOrder[];
          temp.sort((a, b) => a.distance - b.distance);
        } else {
          if (!searchWorkOrder) {
            this.geoRegionWorkOrderFindError = 'Work Order not found.';
          } else if (!searchWorkOrder.address.latitude || !searchWorkOrder.address.longitude) {
            this.geoRegionWorkOrderFindError = 'Work Order does not have correct GPS coordinates.';
          }
        }
      }
      if (this.shouldFilterByPriority) {
        temp = this.workOrderFilter.filterByPriority(temp, this.selectedPriorities.map(sp => sp.status)) as FilteredWellnessWorkOrder[];
      }

      this.filteredWorkOrders = temp;
    }
  }

}
