<div class="p-grid">
    <div class="p-col-12 p-md-1">
        <p-scrollPanel [style]="{width: '100%', height: '90vh'}">
            <app-work-orders-panel *ngIf="calendarComponent"
                [selectedView]='selectedView'
                [isWellnessOnly]="true"
                (taskDraggedStartEvent)="taskDragStart($event)"
                (taskDraggedEndEvent)="taskDragEnd()"
                (dragStartEvent)="woDragStart($event)"
                (dragEndEvent)="clearGrayOverlayOnBuckets()"></app-work-orders-panel>
        </p-scrollPanel>
    </div>
    <div class="p-col-12 p-md-10">
        <p-scrollPanel [style]="{width: '100%', height: '90vh'}">
            <div style="height: 100%">
                <app-week-calendar-view (woDragged)="onWoDragged($event)" (woDraggedEnd)="onWoDraggedEnd()" 
                    (taskDragged)="taskDragStart($event)" (taskDraggedEnd)="taskDragEnd()" [isWellness]="true" [isWork]="false"></app-week-calendar-view>
            </div>
        </p-scrollPanel>
    </div>
    <div class="p-col-12 p-md-1">
        <p-scrollPanel [style]="{width: '100%', height: '90vh'}">
            <app-crews-and-equipment-panel [selectedView]='selectedView'></app-crews-and-equipment-panel>
        </p-scrollPanel>
    </div>
</div>