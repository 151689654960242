<form #PasswordResetForm="ngForm" autocomplete="off" (submit)="submit()">
  <div class="m-auto">
      <div class="p-grid">
          <div class="p-col-12">
              <h3 class="title">Need/Reset Password</h3>
          </div>
          <div class="p-col-12">
              <label for="username">Email Address</label>
              <input class="form-control" name="username" type="text" [(ngModel)]="username" pInputText email
                  required>
          </div>
          <div class="p-col-12">
              <button type="button" pButton class="ui-button-success" label="Submit" style="width: 100%;" (click)="submit()" [disabled]="loading"></button>
          </div>
          <div class="p-col-12">
            <p-message *ngIf="responseMessage" [severity]="success ? 'success' : 'error'" [text]="responseMessage" style="width:100%">
            </p-message>
          </div>
      </div>
  </div>
</form>