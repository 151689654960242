import { FormBuilder } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { BigDayBucketSubrangeGenSvc, NotificationsGenSvc, WorkWorkOrderGenSvc, WellnessWorkOrderGenSvc } from '../../services_autogenerated/generated_services';
import { BaseCalendarComponent } from '../base-calendar/base-calendar.component';
import { ScheduledBucketGenSvc, ScheduleCommentGenSvc } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CrewsDateRangeService } from 'src/app/services/crews-date-range.service';
import { BucketValidationHelperService } from 'src/app/services/bucket-validation-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { inject } from '@angular/core/testing';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';

@Component({
    selector: 'app-two-day-calendar-view',
    templateUrl: './two-day-calendar-view.component.html',
    styleUrls: ['./two-day-calendar-view.component.css']
})
export class TwoDayCalendarViewComponent extends BaseCalendarComponent {
    howManyDays = 2;

    constructor(
        @Inject(ScheduledBucketGenSvc) scheduledItemBucketService: ScheduledBucketGenSvc,
        private formB: FormBuilder,
        @Inject(ScheduleCommentGenSvc) scheduleCommentService: ScheduleCommentGenSvc,
        @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
        messageService: MessageService,
        @Inject(CrewsDateRangeService) crewsDateRangeService: CrewsDateRangeService,
        @Inject(BigDayBucketSubrangeGenSvc) bigDayBucketSubrangeService: BigDayBucketSubrangeGenSvc,
        @Inject(BucketValidationHelperService) bucketValidationHelperService: BucketValidationHelperService,
        @Inject(ScheduledBucketGenSvc) scheduledBucketGenSvc: ScheduledBucketGenSvc,
        @Inject(AuthHelperService) authHelperService: AuthHelperService,
        @Inject(NotificationsGenSvc) emailService: NotificationsGenSvc,
        @Inject(WellnessWorkOrderGenSvc) wellnessWorkOrderService: WellnessWorkOrderGenSvc,
        @Inject(WorkWorkOrderGenSvc) workWorkOrderService: WorkWorkOrderGenSvc,
        confirmationService: ConfirmationService,
        @Inject(MessageWrapperService) messageWrapperService: MessageWrapperService
    ) {
        super(scheduledItemBucketService,
            formB,
            scheduleCommentService,
            dragAndDropService,
            messageService,
            crewsDateRangeService,
            bigDayBucketSubrangeService,
            bucketValidationHelperService,
            scheduledBucketGenSvc,
            authHelperService,
            emailService,
            wellnessWorkOrderService,
            workWorkOrderService,
            confirmationService,
            messageWrapperService);
    }
}
