
<p-card class="sameSizeCards">
  <div class="ui-g">
    <div *ngIf="showStatusBar" class="status-header ui-g-12 ui-g" [ngClass]="{
                                                        'completed': workOrderWorkTask.workTask.completionStatus == 'Completed',
                                                        'go-back': workOrderWorkTask.workTask.completionStatus == 'Go Back' || isIncompleteGoBack,
                                                        'rm-follow-up': workOrderWorkTask.workTask.completionStatus == 'Customer Unsatisfied - RM Follow Up'
                                                      }">
      <div class="status-bar ui-g-12">
        <i style="vertical-align: middle;" class="fa" [ngClass]="{
                                              'fa-check': workOrderWorkTask.workTask.completionStatus == 'Completed',
                                              'fa-exclamation': workOrderWorkTask.workTask.completionStatus == 'Customer Unsatisfied - RM Follow Up',
                                              'fa-arrow-left': workOrderWorkTask.workTask.completionStatus == 'Go Back'
                                            }"></i>
        <span style="vertical-align: middle;">&nbsp;{{workOrderWorkTask.workTask.completionStatus}}</span>
        <span style="vertical-align: middle;" *ngIf="workOrderWorkTask.workTask.paymentStatus">&nbsp;|&nbsp;{{workOrderWorkTask.workTask.paymentStatus}}</span>
        <span style="vertical-align: middle;" *ngIf="workOrderWorkTask.workTask.paidDate">&nbsp;|&nbsp;{{workOrderWorkTask.workTask.paidDate | date: 'shortDate'}}</span>
        <span style="vertical-align: middle;" *ngIf="!workOrderWorkTask.workTask.paymentStatus">&nbsp;|&nbsp;Not Paid</span>
        <span style="vertical-align: middle;" *ngIf="!workOrderWorkTask.workTask.paidDate">&nbsp;|&nbsp;No paid date</span>
      </div>
      <br/>
      <div class="ui-g-12 showLineBreaks" *ngIf="workOrderWorkTask.workTask.customerIssue && workOrderWorkTask.workTask.completionStatus != 'Completed'">
        {{workOrderWorkTask.workTask.customerIssue}}
      </div>
      <div class="ui-g-4" *ngIf="workOrderWorkTask.workTask.workTaskGoBackEquipment && workOrderWorkTask.workTask.workTaskGoBackEquipment.length > 0 && workOrderWorkTask.workTask.completionStatus != 'Completed'">
        Required Equipment Type(s):
      </div>
      <div class="ui-g-8" *ngIf="workOrderWorkTask.workTask.workTaskGoBackEquipment && workOrderWorkTask.workTask.workTaskGoBackEquipment.length > 0 && workOrderWorkTask.workTask.completionStatus != 'Completed'">
        <span class="taskStyle" *ngFor="let wtEquipment of workOrderWorkTask.workTask.workTaskGoBackEquipment">
          {{wtEquipment.equipmentType.type}}
        </span>
      </div>

      <div class="ui-g-4" *ngIf="isIncompleteGoBack">
        <label>Go Back Uploads:</label>
      </div>
      <div class="ui-g-8" *ngIf="isIncompleteGoBack">
        <app-blob-manager 
          [containerName]="'work-task-go-back'" 
          [parentRecordId]="uploadId" 
          [uploadLabel]="'Document/Photo'" 
          [viewOnly]="true"></app-blob-manager>
      </div>

      <div class="ui-g-12">
        <button 
          pButton 
          type="button" 
          label="Complete" 
          (click)="showTaskHoursDialog(true)"
          *ngIf="canComplete 
                  && (workOrderWorkTask.workTask.goBackBucketId != undefined) 
                  && workOrderWorkTask.workTask.completionStatus != 'Completed' 
                  && workOrderWorkTask.workTask.completionStatus !='Unable to be Completed'"
          class="ui-button ui-button-success" 
          style="font-size: 90%;"
        ></button>
        <button pButton type="button" label="Undo Complete" icon="fa fa-undo" (click)="undoComplete()" *ngIf="canUndo" class="ui-button-primary"></button>
        <button 
          pButton 
          type="button" 
          label="Multiday - Add Hours for Day"
          (click)="showTaskHoursDialogAddHours()" 
          *ngIf="canComplete
                  && workOrderWorkTask.workTask.scheduleDateFrom
                  && workOrderWorkTask.workTask.currentBigDaySubrangeId
                  && workOrderWorkTask.workTask.completionStatus != 'Completed' 
                  && workOrderWorkTask.workTask.completionStatus !='Unable to be Completed'"
          class="ui-button editButtonMargin" 
          style="margin-left: 5px; font-size: 90%;"
        ></button>
        <button 
          pButton 
          type="button" 
          label="Unable to Complete" 
          (click)="showTaskHoursDialogUnableToComplete(true)"
          *ngIf="canComplete 
                  && (workOrderWorkTask.workTask.goBackBucketId != undefined) 
                  && workOrderWorkTask.workTask.completionStatus != 'Completed' 
                  && workOrderWorkTask.workTask.completionStatus !='Unable to be Completed'"
          class="ui-button ui-button-danger"
          style="margin-left: 5px; font-size: 90%;"
        ></button>
      </div>
    </div>
    <div class="ui-g-9" style="text-align: left">
      <h6>{{workOrderWorkTask.workTask?.name}}</h6>
      <h6>
        <span *ngIf="workOrderWorkTask.workTask.fixedPrice && (canSeeHiddenPrice || !hidePrice)">
          {{workOrderWorkTask.workTask.price | currency}}
        </span>
        <span *ngIf="!workOrderWorkTask.workTask.fixedPrice && (canSeeHiddenPrice || !hidePrice)">
          <span *ngIf="workOrderWorkTask.workTask.hourlyRate">{{workOrderWorkTask.workTask.hourlyRate | currency}} per hour</span>
          <span *ngIf="workOrderWorkTask.workTask.hourlyRate && (workOrderWorkTask.workTask.minPrice || workOrderWorkTask.workTask.maxPrice)"> - </span>
          <span *ngIf="workOrderWorkTask.workTask.minPrice">{{workOrderWorkTask.workTask.minPrice | currency}}</span>
          <span *ngIf="workOrderWorkTask.workTask.minPrice && workOrderWorkTask.workTask.maxPrice"> to </span>
          <span *ngIf="workOrderWorkTask.workTask.maxPrice">{{workOrderWorkTask.workTask.maxPrice | currency}}</span>
        </span>
        <span *ngIf="(canSeeHiddenPrice || !hidePrice) && !isCustomer && workOrderWorkTask.workTask.fixedPrice"> - </span>
        <span *ngIf="!isCustomer && workOrderWorkTask.workTask.fixedPrice"> {{workOrderWorkTask.workTask.hours}} hr(s)</span>
      </h6>
    </div>
    <div class="ui-g-3" style="text-align: right" *ngIf="fromQuote && workOrderWorkTask.id">
      <p-checkbox name="agree" [disabled]="true" [(ngModel)]="isSelected" (onChange)="workCheckChanged($event)" binary="true"></p-checkbox>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-9" style="text-align: left">
      <h6 class="showLineBreaks">{{workOrderWorkTask.workTask?.description}}</h6>
    </div>
    <div class="ui-g-3" style="text-align: right">
      <button 
        pButton
        type="button"
        icon="pi pi-copy"
        *ngIf="isEditable"
        (click)="copyTask()"
        class="ui-button-secondary editMargin">
      </button>
      <button 
        pButton
        type="button"
        icon="pi pi-pencil"
        *ngIf="isEditable || canSetGoBack"
        (click)="editWorkTask()"
        class="ui-button-secondary editMargin">
      </button>
      <button 
        pButton
        type="button"
        icon="pi pi-trash"
        (click)="deleteWorkTask()"
        *ngIf="isDeletable()"
        class="ui-button-danger">
      </button>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12" style="text-align: left" *ngIf="!workOrderWorkTask.workTask.isGoBack">
      <button 
        pButton 
        type="button" 
        label="Complete" 
        (click)="showTaskHoursDialog(false)"
        *ngIf="canComplete
        && (workOrderWorkTask.workTask.currentBucketId != undefined || workOrderWorkTask.workTask.scheduleDateFrom) 
        && workOrderWorkTask.workTask.completionStatus != 'Completed' 
        && workOrderWorkTask.workTask.completionStatus !='Unable to be Completed'"
        class="ui-button ui-button-success" 
        style="font-size: 90%;"
      ></button>
      <button pButton type="button" label="Undo Complete" icon="fa fa-undo" (click)="undoComplete()" *ngIf="canUndo" class="ui-button-primary"></button>
      <button 
        pButton 
        type="button" 
        label="Multiday - Add Hours for Day"
        (click)="showTaskHoursDialogAddHours()" 
        *ngIf="canComplete 
        && workOrderWorkTask.workTask.scheduleDateFrom
        && workOrderWorkTask.workTask.currentBigDaySubrangeId
        && workOrderWorkTask.workTask.completionStatus != 'Completed' 
        && workOrderWorkTask.workTask.completionStatus !='Unable to be Completed'"
        class="ui-button editButtonMargin" 
        style="margin-left: 5px; font-size: 90%;"
      ></button>
      <button 
        pButton 
        type="button" 
        label="Unable to Complete" 
        (click)="showTaskHoursDialogUnableToComplete(false)"
        *ngIf="canComplete 
        && (workOrderWorkTask.workTask.currentBucketId != undefined || workOrderWorkTask.workTask.scheduleDateFrom) 
        && workOrderWorkTask.workTask.completionStatus != 'Completed' 
        && workOrderWorkTask.workTask.completionStatus !='Unable to be Completed'"
        class="ui-button ui-button-danger" 
        style="margin-left: 5px; font-size: 90%;"
      ></button>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.workTaskTreeTypes && workOrderWorkTask.workTask.workTaskTreeTypes.length > 0" style="text-align: left">
    <div class="ui-g-4">
      Tree Type(s):
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let wtTreeType of workOrderWorkTask.workTask.workTaskTreeTypes">
        {{wtTreeType.treeType.commonName}}
      </span>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.location" style="text-align: left">
    <div class="ui-g-4">
      Location of Tree(s):
    </div>
    <div class="ui-g-8">
      {{workOrderWorkTask.workTask.location}}
    </div>
  </div>
  <div class="ui-g" style="text-align: left" *ngIf="uploadId > 0 && !isCustomer">
    <div class="ui-g-4">
      Uploads:
    </div>
    <div class="ui-g-8">
      <app-blob-manager
        [containerName]="'work-task'" 
        [parentRecordId]="uploadId" 
        [uploadLabel]="'Document/Photo'" 
        [viewOnly]="true"></app-blob-manager>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.workTaskSkills && workOrderWorkTask.workTask.workTaskSkills.length > 0 && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Required Skill(s):
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let wtSkill of workOrderWorkTask.workTask.workTaskSkills">
        {{wtSkill.skill.name}}
      </span>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.workTaskEquipment && workOrderWorkTask.workTask.workTaskEquipment.length > 0 && !isCustomer" style="text-align: left">
    <div class="ui-g-4">
      Required Equipment Type(s):
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let wtEquipment of workOrderWorkTask.workTask.workTaskEquipment">
        {{wtEquipment.equipmentType.type}}
      </span>
    </div>
  </div>

  <hr *ngIf="scheduledBucket && canSeePreviousCrew && !isCustomer"/>
  <div class="ui-g" *ngIf="scheduledBucket && canSeePreviousCrew && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Work Date(s):
    </div>
    <div class="ui-g-8">
      {{scheduledBucket.date | date: 'shortDate'}}<span *ngIf="goBackScheduledBucket">, {{ goBackScheduledBucket.date | date: 'shortDate' }}</span>
    </div>
    <div class="ui-g-4">
      Crew:
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let sbEmployee of scheduledBucket.scheduledBucketEmployees">
        {{sbEmployee.employee.fullName}}
      </span>
      <span class="taskStyle" *ngFor="let sbEmployee of goBackScheduledBucket?.scheduledBucketEmployees">
        {{sbEmployee.employee.fullName}}
      </span>
    </div>
    <div class="ui-g-4">
      Equipment:
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let sbEquipment of scheduledBucket.scheduledBucketEquipment">
        {{sbEquipment.equipment.number}}
      </span>
      <span class="taskStyle" *ngFor="let sbEquipment of goBackScheduledBucket?.scheduledBucketEquipment">
        {{sbEquipment.equipment.number}}
      </span>
    </div>
  </div>

  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.taskHourEntries && workOrderWorkTask.workTask.taskHourEntries.length > 0 && canSeePreviousCrew && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Task Hours Total:
    </div>
    <div class="ui-g-8">
      {{taskHourTotal | number: '1.0-2'}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.isGoBack && workOrderWorkTask.workTask.taskHourEntries && workOrderWorkTask.workTask.taskHourEntries.length > 0 && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Go Back Hours Total:
    </div>
    <div class="ui-g-8">
      {{taskHourGoBackTotal | number: '1.0-2'}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.jobFeedback && canSeePreviousCrew && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Crew Feedback:
    </div>
    <div class="ui-g-8">
      {{workOrderWorkTask.workTask.jobFeedback}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.goBackFeedback && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Go Back Feedback:
    </div>
    <div class="ui-g-8">
      {{workOrderWorkTask.workTask.goBackFeedback}}
    </div>
  </div>

  <hr *ngIf="workOrderWorkTask.workTask.customerIssue && !isCustomer"/>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.customerIssue && !isCustomer" style="text-align:left">
    <div class="ui-g-4">
      Customer Issue:
    </div>
    <div class="ui-g-8 showLineBreaks">
      {{workOrderWorkTask.workTask.customerIssue}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWorkTask.workTask.goBackCompletionDate && !isCustomer" style="text-align: left;">
    <div class="ui-g-4"></div>
    <div class="ui-g-8">
      Resolved {{workOrderWorkTask.workTask.goBackCompletionDate | date: 'shortDate'}}
    </div>    
  </div>
</p-card>

<p-dialog [(visible)]="showTaskHour" (onHide)="closedTaskHoursDialog()" [modal]="true" [style]="{width: '25em'}" [responsive]="true">
  <app-task-hours-entry 
    *ngIf="showTaskHour"
    [asGoBack]="taskHoursForGoBack"
    [complete]="doCompleteTask"
    [customerId]="customerId" 
    [quoteNumber]="quoteNumber" 
    [task]="workOrderWorkTask.workTask"
    [unableToComplete]="unableToComplete" 
    (addHours)="addedHours()"
    (commentId)="addCustomerCommentId($event)"
    (completed)="taskHoursCompleted()"
    (unableToCompleteSubmitted)="taskHoursSubmitted($event)"
  >
  </app-task-hours-entry>
</p-dialog>
