import { Injectable } from '@angular/core';
import { SharedColorService } from './shared-color.service';
import { WellnessTask, WorkTask, PriorityLevel } from '../services_autogenerated/generated_services';
import { CalculatedPriorityType } from '../models/calculatedPriorityType';
import * as moment from 'moment';

const redThreshold = 7;
const yellowThreshold = 21;

const black = { r: 0, g: 0, b: 0 };
const red = { r: 255, g: 0, b: 0 };
const yellow = { r: 255, g: 255, b: 0 };
const green = { r: 0, g: 255, b: 0 };

@Injectable({
  providedIn: 'root'
})
export class CalculatedPriorityAgingColorService {

  constructor(
    private sharedColorService: SharedColorService,
  ) { }

  getPriority(task: WellnessTask | WorkTask): PriorityLevel {
    const type = this.getCalculatedPriorityType(task);

    // Have to use .startOf so that moment isn't taking the time into consideration.
    const startDateDaysOut = moment(task.dueDateStart).diff(moment().startOf('day'), 'day');
    const endDateDaysOut = moment(task.dueDateEnd).diff(moment().startOf('day'), 'day');

    const rgb = this.getRgbColorByDaysAndType(startDateDaysOut, endDateDaysOut, type);

    if (task.priorityShortcutId === 6) {
      // storm priority is first
      return new PriorityLevel({
        id: -1,
        active: true,
        status: type.toString(),
        backgroundColor: this.sharedColorService.rgbObjectToRgbString(rgb),
        textColor: this.sharedColorService.getFontColor(rgb),
        order: -Infinity
      });
    }

    return new PriorityLevel({
      id: -1,
      active: true,
      status: type.toString(),
      backgroundColor: this.sharedColorService.rgbObjectToRgbString(rgb),
      textColor: this.sharedColorService.getFontColor(rgb),
      order: endDateDaysOut
    });
  }

  private getCalculatedPriorityType(task: WellnessTask | WorkTask): CalculatedPriorityType {
    if (task.priorityTypeShortcut) {
      return task.priorityTypeShortcut.name as CalculatedPriorityType;
    }

    if (task.hardStartDate && task.hardEndDate) {
      if (moment(task.dueDateStart).isSame(moment(task.dueDateEnd), 'day')) {
        return CalculatedPriorityType.DueOn;
      } else {
        return CalculatedPriorityType.DueBetween;
      }
    } else if (task.hardStartDate) {
      return CalculatedPriorityType.DueAfter;
    } else if (task.hardEndDate) {
      return CalculatedPriorityType.DueBefore;
    } else {
      return CalculatedPriorityType.SoftDeadline;
    }
  }

  private getRgbColorByDaysAndType(startDateDaysOut: number, endDateDaysOut: number, type: CalculatedPriorityType) {
    if (type === CalculatedPriorityType.DueOn) {
      // Due on is red when you pass the date, yellow if the date is within 7 to 21 days away and green otherwise
      if (endDateDaysOut <= redThreshold) {
        return red;
      } else if (endDateDaysOut <= yellowThreshold && endDateDaysOut >= redThreshold) {
        return yellow;
      } else {
        return green;
      }
    } else if (type === CalculatedPriorityType.DueAfter) {
      // Due After looks at start date - black before, green for 1 week after, yellow at 2-3 weeks, red after that
      // Dealing with negatives here because this works kinda backwards from the other types
      if (startDateDaysOut > 0) {
        return black;
      } else if (startDateDaysOut >= -7) {
        return green;
      } else if (startDateDaysOut >= -21) {
        return yellow;
      } else {
        return red;
      }
    } else {
      // Black before, yellow 8-21 days before the end date, red 7 days before the end date (and after end date), green otherwise
      if (startDateDaysOut > 0) {
        return black;
      } else if (endDateDaysOut <= redThreshold) {
        return red;
      } else if (endDateDaysOut <= yellowThreshold && endDateDaysOut > redThreshold) {
        return yellow;
      } else {
        return green;
      }
    }
  }

  private roundToNearestHalf(num: number) {
    return Math.round(num * 2) / 2;
  }
}
