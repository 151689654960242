import { Component, OnInit } from '@angular/core';
import { SurveyHeader, SurveyResponseGenSvc } from 'src/app/services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { SurveyHeaderFilterPipe } from 'src/app/pipes/survey-header-filter.pipe';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.css'],
  providers: [SurveyHeaderFilterPipe]
})
export class SurveyListComponent implements OnInit {

  cols: any[];
  surveyHeaders: SurveyHeader[] = [];
  searchTerm: string;

  constructor(
    private router: Router,
    private surveyResponseService: SurveyResponseGenSvc,
    private authHelperService: AuthHelperService,
    private surveyHeaderFilter: SurveyHeaderFilterPipe,
    public customCustomerService: CustomerService
  ) {
    this.cols = [
      { field: 'tempCustomerName', header: 'Customer Name' },
      { field: 'tempPhoneNumber', header: 'Phone Number', width: '14%' },
      { field: 'customer.address.street', subfield: 'street', header: 'Address' },
      { field: 'customer.address.zip', subfield: 'zip', header: 'Zip', width: '110px' },
      { field: 'dateSent', header: 'Date Sent', width: '14%' },
      { field: 'dateCompleted', header: 'Date Completed', width: '14%' },
      { field: 'tempSatisfied', header: 'Satisfied', width: '12%' },
      { header: '', width: '110px' }
    ];
  }

  ngOnInit() {
    this.getSurveyHeaders();
  }

  getSurveyHeaders() {
    this.surveyResponseService.getAll().subscribe(headers => {
      headers.forEach(surveyHeader => {
        (surveyHeader as any).tempCustomerName = this.customCustomerService.getCustomerFullName(surveyHeader.customer);
        (surveyHeader as any).tempPhoneNumber = this.customCustomerService.getPrimaryContact(surveyHeader.customer).phoneNumber;
        (surveyHeader as any).tempSatisfied = surveyHeader.surveyAnswers[0].yesNoResponse ? 'Yes'
          : (surveyHeader.surveyAnswers[0].yesNoResponse === false ? 'No' : null);
      });
      this.surveyHeaders = headers;
    });
  }

  viewSurvey(surveyHeader: SurveyHeader) {
    this.router.navigateByUrl('/survey/' + surveyHeader.id);
  }

  deleteSurveyHeader(surveyHeader: SurveyHeader) {
    if (confirm('Delete this survey response?')) {
      this.surveyResponseService.delete(surveyHeader.id).subscribe(() => {
        this.surveyHeaders = this.surveyHeaders.filter(sh => sh.id !== surveyHeader.id);
      });
    }
  }
}
