import { Directive, Input, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appDateGreaterThanValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateGreaterThanValidatorDirective, multi: true}]
})
export class DateGreaterThanValidatorDirective implements Validator {
    @Input() appDateGreaterThanValidator: Date;

    validate(control: AbstractControl): {[key: string]: any} | null {
      return this.appDateGreaterThanValidator ? dateCompareValidator(control.value, this.appDateGreaterThanValidator)(control)
                                : null;
    }
}

/** A  */
export function dateCompareValidator(thisDate: Date, minDate: Date): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return thisDate < minDate ? {'Start Date must be before End Date': {value: control.value}} : null;
    };
  }
