<br>
<form id="pesticideForm" #pesticideForm="ngForm">
  <div class="ui-g">
    <div class="ui-g-12 columnPadding">
      <h4>Pesticide Application Record</h4>
    </div>
  </div>
  <div class="ui-g" *ngIf="record">
    <div class="ui-g-12 columnPadding">
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Applicator</h6>
        <ng-select class="custom" required [items]="employees" placeholder="Select an Applicator" [selectOnTab]="true"
          (ngModelChange)="onApplicatorSelect($event)" name="applicator" bindLabel="fullName"
          [(ngModel)]="record.applicator">
        </ng-select>
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Certified Applicator</h6>
        <ng-select class="custom" required [items]="employees" placeholder="Select a Certified Applicator" [selectOnTab]="true"
          (ngModelChange)="onCertifiedApplicatorSelect($event)" name="certifiedApplicator" bindLabel="fullName"
          [(ngModel)]="record.certifiedApplicator">
        </ng-select>
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Date</h6>
        <p-calendar name="date" [(ngModel)]="record.dateApplied" class="secondary" [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%', 'border-radius': '5px'}"></p-calendar>
      </div>
    </div>
    <div class="ui-g-12 columnPadding">
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Chemical</h6>
        <ng-select class="custom" required [items]="chemicals" placeholder="Select a Chemical" [selectOnTab]="true"
          (ngModelChange)="onChemicalSelect($event)" name="chemical" bindLabel="dropdown" [(ngModel)]="record.chemical">
        </ng-select>
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Application Method</h6>
        <ng-select class="custom" required [items]="chemicalAppMethods" placeholder="Select an Application Method" [selectOnTab]="true"
          (ngModelChange)="onChemicalAppMethodSelect($event)" name="chemicalAppMethod" bindLabel="method"
          [(ngModel)]="record.chemicalApplicationMethod">
        </ng-select>
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Application Location / Treated Area</h6>
        <ng-select class="custom" required [items]="chemicalAppLocations" placeholder="Select an Application Location" [selectOnTab]="true"
          (ngModelChange)="onChemicalAppLocationSelect($event)" name="chemicalAppLocation" bindLabel="location"
          [(ngModel)]="record.chemicalApplicationLocation">
        </ng-select>
      </div>
    </div>
    <div class="ui-g-12 columnPadding">
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Total Amount Used (Concentrate)</h6>
        <input pInputText class="inputTextBox" name="amountConcentrate" [(ngModel)]="record.amountConcentrateUsed"
          required />
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Total Volume (If Diluted)</h6>
        <input pInputText class="inputTextBox" name="totalVolume" [(ngModel)]="record.totalVolume" />
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Wind Direction and Speed (MPH)</h6>
        <input pInputText class="inputTextBox" name="windDirectionAndSpeed" [(ngModel)]="record.windDirectionAndSpeed"
          required />
      </div>
    </div>
    <div class="ui-g-12 columnPadding">
      <div class="ui-g-12 ui-md-6 ui-lg-4">
        <h6>Temperature (F)</h6>
        <input pInputText type="number" pattern="^-?[1-9]\d{0,2}(\.\d)?$" class="inputTextBox" name="temperature"
          [(ngModel)]="record.temperature" required />
      </div>
      <div class="ui-g-12 ui-md-8 ui-g-nopad">
        <div class="ui-g-6">
          <h6>Start</h6>
          <input pInputText type="time" name="startTime" [(ngModel)]="startTimeString" required style="border-radius: 5px;">
        </div>
        <div class="ui-g-6">
          <h6>End</h6>
          <input pInputText type="time" name="endTime" [(ngModel)]="endTimeString" required style="border-radius: 5px;">
        </div>
      </div>
    </div>
    <div class="ui-g-12 columnPadding">
      <div class="ui-g-12">
        <h6>Work Order Customer and Address</h6>
        <app-basic-work-order-dropdown
          [disabled]="disableWorkOrderSelect"
          [existingSelectionQuoteNumber]="record.quoteNumber"
          [required]="true"
          (workOrderSelected)="setWorkOrder($event)"
        >
        </app-basic-work-order-dropdown>
      </div>
    </div>
  </div>
  <app-loading-swirly [isLoading]="!record"></app-loading-swirly>
  <div class="ui-g-12">
    <div class="ui-g-12 columnPadding">
      <div *ngIf="pesticideForm.invalid && pesticideForm.submitted" class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 columnPadding" *ngIf="!wellnessWorkOrderId && !recordId">
      <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle" label="Save"
        (click)="saveNewRecord(pesticideForm)">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" label="Cancel"
        (click)="goToList()">
      </button>
    </div>
    <div class="ui-g-12 columnPadding" *ngIf="recordId">
      <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle" label="Save"
        (click)="saveEditedRecord(pesticideForm)">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" label="Cancel"
        (click)="goToList()">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-danger buttonStyle" label="Delete"
        (click)="delete()">
      </button>
    </div>
    <div class="ui-g-12 columnPadding" *ngIf="wellnessWorkOrderId">
      <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle"
        [disabled]="disableSave"
        label="Save and Return to Work Order" (click)="saveRecordAndReturnToWorkOrder(pesticideForm)">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" label="Cancel"
        (click)="goToWorkOrder()">
      </button>
    </div>
  </div>
</form>
