import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PesticideApplicationRecord, PesticideApplicationRecordGenSvc } from 'src/app/services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-pesticide-record-list',
  templateUrl: './pesticide-record-list.component.html',
  styleUrls: ['./pesticide-record-list.component.css']
})
export class PesticideRecordListComponent implements OnInit {
  @ViewChild(Table) primeTable: Table;

  cols: any[];
  records: PesticideApplicationRecord[] = [];
  allRecords: PesticideApplicationRecord[] = [];

  canEditAndDelete = false;
  filterDates: Date[];

  constructor(
    private router: Router,
    private pesticideApplicationRecordService: PesticideApplicationRecordGenSvc,
    private authHelperService: AuthHelperService,
    public customCustomerService: CustomerService
  ) {
    this.cols = [
      { uiPriority: 1, field: 'dateApplied', header: 'Date ' },
      { uiPriority: 1, field: 'applicator.fullName', header: 'Applicator' },
      { uiPriority: 3, field: 'certifiedApplicator.fullName', header: 'Cert. App.' },
      { uiPriority: 1, field: 'chemical.displayName', header: 'Chemical' },
      { uiPriority: 3, field: 'chemical.registrationNumber', header: 'Reg. #' },
      { uiPriority: 3, field: 'chemical.mixRate', header: 'Mix Rate' },
      { uiPriority: 1, field: 'amountConcentrateUsed', header: 'Concentrate Volume' },
      { uiPriority: 1, field: 'totalVolume', header: 'Mixture Volume' },
      { uiPriority: 2, field: 'chemicalApplicationMethod.method', header: 'Chemical Application Method' },
      { uiPriority: 3, field: 'startTime', header: 'Start' },
      { uiPriority: 4, field: 'endTime', header: 'End' },
      { uiPriority: 4, field: 'windDirectionAndSpeed', header: 'Wind (MPH)' },
      { uiPriority: 4, field: 'temperature', header: 'Temp (F)' },
      { uiPriority: 2, field: 'chemicalApplicationLocation.location', header: 'Treated Area' },
      { uiPriority: 3, field: 'customerName', header: 'Customer' },
      { uiPriority: 2, field: 'address', header: 'Address' },
      { uiPriority: 2, field: 'quoteNumber', header: 'Quote #' },
      { uiPriority: 1, header: '', width: '100px' }
    ];
  }

  ngOnInit() {
    this.pesticideApplicationRecordService.getAll().subscribe((pars) => {
      this.allRecords = pars;
      this.records = this.allRecords.slice();
    });

    const claimNames = this.authHelperService.getDecodedAccessToken().claimNames;
    this.canEditAndDelete = claimNames.includes('View/Edit Pesticide Application Record');
  }

  onAddNew() {
    this.router.navigate(['pesticideApplicationRecordMaintenance']);
  }

  editRecord(record) {
    this.router.navigateByUrl('/pesticideApplicationRecordMaintenance/' + record.id);
  }

  deleteRecord(record) {
    if (confirm('Delete this record?')) {
      this.pesticideApplicationRecordService.delete(record.id).subscribe(() => {
        this.records = this.records.filter(x => x.id !== record.id);
        this.allRecords = this.allRecords.filter(x => x.id !== record.id);
      });
    }
  }

  onFilterDateChange() {
    if (this.filterDates[0] && this.filterDates[1]) {
      const startDate = moment(this.filterDates[0]);
      const endDate = moment(this.filterDates[1]);
      this.records = this.allRecords.filter(x => moment(x.dateApplied).isBetween(startDate, endDate, 'date', '[]')).slice();
    }
  }

  refreshPage() {
    this.filterDates = null;
    this.primeTable.reset();
    this.records = this.allRecords.slice();
  }

}
