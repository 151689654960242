
<ng-select [items]="workOrders$ | async"
  class="custom"
  placeholder="Search All Work Orders"
  name="workOrders"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [loading]="loading"
  typeToSearchText="Please enter 1 or more characters to search"
  [typeahead]="workOrderInput$"
  [(ngModel)]="selectedWorkOrder"
  (ngModelChange)="workOrderSelectedModelChange()"
  [virtualScroll]="true"
  [disabled]="disabled"
  [required]="required"
>
  <ng-template ng-label-tmp let-item="item">
    {{item.quoteNumber}} - {{item.customerName}} - {{item.address}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    {{item.quoteNumber}} - {{item.customerName}} - {{item.address}}
  </ng-template>
</ng-select>