/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./system-settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./setting-row/setting-row.component.ngfactory";
import * as i3 from "./setting-row/setting-row.component";
import * as i4 from "../../services_autogenerated/generated_services";
import * as i5 from "@angular/forms";
import * as i6 from "primeng/components/common/messageservice";
import * as i7 from "@angular/common";
import * as i8 from "./system-settings.component";
import * as i9 from "../../services/auth-helper.service";
var styles_SystemSettingsComponent = [i0.styles];
var RenderType_SystemSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SystemSettingsComponent, data: {} });
export { RenderType_SystemSettingsComponent as RenderType_SystemSettingsComponent };
function View_SystemSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "margin-left:8px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "pi pi-spin pi-spinner"], ["style", "font-size: 3.5em"]], null, null, null, null, null))], null, null); }
function View_SystemSettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-setting-row", [], null, null, null, i2.View_SettingRowComponent_0, i2.RenderType_SettingRowComponent)), i1.ɵdid(1, 114688, null, 0, i3.SettingRowComponent, [i4.SystemSettingGenSvc, i5.FormBuilder, i6.MessageService], { setting: [0, "setting"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SystemSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_3)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_v.context.$implicit.claimName || _co.claims.includes(_v.context.$implicit.claimName)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SystemSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["System Settings Maintenance"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_2)), i1.ɵdid(8, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.settings; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_SystemSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-system-settings", [], null, null, null, View_SystemSettingsComponent_0, RenderType_SystemSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i8.SystemSettingsComponent, [i4.SystemSettingGenSvc, i6.MessageService, i9.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SystemSettingsComponentNgFactory = i1.ɵccf("app-system-settings", i8.SystemSettingsComponent, View_SystemSettingsComponent_Host_0, {}, {}, []);
export { SystemSettingsComponentNgFactory as SystemSettingsComponentNgFactory };
