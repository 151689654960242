<div>
  <div>
    <h4>Crew Log</h4>
  </div>
  <div id="datePicker">
    <label for="startDate">Date: &nbsp;</label>
    <p-calendar id="startDate" [(ngModel)]="date" [showIcon]="true" (ngModelChange)="onDateChange()" ></p-calendar>
  </div>

<hr>

  <div class="p-grid" *ngIf="scheduledBuckets?.length > 0">
    <div class="p-col-12" *ngFor="let scheduledBucket of scheduledBuckets; let i = index">
      <div>
        <h5>Crew {{i + 1}}: {{scheduledBucket.employeeString}} ({{scheduledBucket.equipmentString}})</h5>
        <button pButton type="button" label="Map Route" (click)="exportToGoogle(scheduledBucket)" class="ui-button-primary"></button>
        <div *ngFor="let workOrder of scheduledBucket.workOrders" [ngClass]="workOrder.allScheduledTasksComplete ? 'grey-out' : ''">
          <span *ngIf="workOrder.allScheduledTasksComplete"><i class="pi pi-check"></i></span>
          <a target="_blank" class="regular-link" [routerLink]="workOrder.workOrderLink">{{workOrder.quoteNumber}}</a> - 
          <a target="_blank" class="regular-link" [routerLink]="workOrder.customerLink">{{workOrder.customerName}}</a> - 
          {{workOrder.customerPhone | phoneNumber}} - 
          {{workOrder.workOrderAddress}}
          <span *ngIf="!workOrder.hidePrice"> - {{workOrder.priceSummary | currency }} - {{workOrder.hoursSummary}} hour<span *ngIf="workOrder.hoursSummary != 1">s</span></span>
          <span *ngIf="workOrder.hasGoBack"> - GB</span>
        </div>
        <div class="bold">
          {{scheduledBucket.total | currency}} | {{scheduledBucket.hours}} hour<span *ngIf="scheduledBucket.hours != 1">s</span>
        </div>
      </div>
    <hr/>
    </div>
  </div>
  <div *ngIf="!isLoading && !isLoadingMultiday  && scheduledBuckets.length <= 0" class="fine-print">There are no crews scheduled for this day.</div>
  <div>
    <span *ngIf="isLoading || isLoadingMultiday" style="margin-left:8px">
      <i class="pi pi-spin pi-spinner" style="font-size: 3.5em"></i>
    </span>
  </div>
</div>