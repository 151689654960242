import { Component, OnInit, Input } from '@angular/core';
import { WellnessWorkOrder,
  WorkWorkOrder, WellnessTask,
  WorkTask, WellnessTaskCompletionStatus,
  WorkTaskCompletionStatus } from 'src/app/services_autogenerated/generated_services';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { Router } from '@angular/router';
import { StripeGenSvc, WorkOrderBilling } from '../../../services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-customer-work-order-row',
  templateUrl: './customer-work-order-row.component.html',
  styleUrls: ['./customer-work-order-row.component.css']
})
export class CustomerWorkOrderRowComponent implements OnInit {
  @Input() workOrder: WellnessWorkOrder | WorkWorkOrder;
  @Input() workOrderBilling: WorkOrderBilling = null;
  @Input() current: boolean;
  buttonText: string;
  canSeeHiddenPrice: boolean;
  pdfReceiptCount = 0;
  nonPdfReceiptCount = 0;
  tasks: any;
  type: string;

  CompletionStatus = WellnessTaskCompletionStatus;

  showDueDates: boolean = true;

  constructor(
    private router: Router,
    private woHelper: WorkOrderHelperService,
    private customStripeService: StripeGenSvc,
    private authHelper: AuthHelperService
    ) { }

  ngOnInit() {
    if (this.workOrder instanceof WellnessWorkOrder) {
      this.type = 'Wellness';
      this.showDueDates = false; // Specifically asked not to show wellness due dates
    } else {
      this.type = 'Work';
    }
    if (this.current) {
      this.buttonText = 'View Work Details';
    } else {
      this.buttonText = 'View Receipt';
      if (this.workOrderBilling && this.workOrderBilling.receiptURLs.length > 0) {
        this.workOrderBilling.receiptURLs.forEach((receiptLink) => {
          if (this.isPDFReceipt(receiptLink)) {
            // receipt link
            // this.buttonText = 'View Card/ACH Payment Receipt';
            this.pdfReceiptCount++;
          } else {
            // invoice PDF
            // this.buttonText = 'Download Cash Payment Receipt PDF';
            this.nonPdfReceiptCount++;
          }
        });
      }
    }
    this.tasks = this.woHelper.getTasks(this.workOrder);
    this.canSeeHiddenPrice = this.authHelper.getDecodedAccessToken().claimNames.includes('View Hidden Prices');
  }

  navigate() {
    if (this.workOrder instanceof WellnessWorkOrder) {
      this.router.navigateByUrl('wellnessWorkOrderMaintenance/' + this.workOrder.id);
    } else {
      this.router.navigateByUrl('workWorkOrderMaintenance/' + this.workOrder.id);
    }
    if (this.current) {
    } else {
      // console.log('meep')
      // console.log(this.workOrder);
      // console.log(this.workOrderBilling);
      // // Receipt
      // this.customStripeService.getWorkOrderReceipt(this.workOrder.id, this.type)
      //   .subscribe(receiptPDFLinks => {
      //     if (receiptPDFLinks.length > 0) {
      //       receiptPDFLinks.forEach((receiptPDFLink) => {
      //         window.open(receiptPDFLink, "_blank");
      //       })
      //     }
      //   });
      // console.log(this.workOrder);
    }
  }

  openNonPDFReceipts() {
    if (this.workOrderBilling.receiptURLs.length > 0) {
      this.workOrderBilling.receiptURLs.forEach((receiptLink) => {
        if (!this.isPDFReceipt(receiptLink)) {
          window.open(receiptLink, '_blank');
        }
      });
    }
  }

  openPDFReceipts() {
    if (this.workOrderBilling.receiptURLs.length > 0) {
      this.workOrderBilling.receiptURLs.forEach((receiptLink) => {
        if (this.isPDFReceipt(receiptLink)) {
          window.open(receiptLink, '_blank');
        }
      });
    }
  }

  isPDFReceipt(link: string): boolean {
    if (link.indexOf('receipts') >= 0) {
      return false;
    } else {
      return true;
    }
  }

  getTreeList(task) {
    if (task instanceof WellnessTask) {
      return task.wellnessTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(',');
    } else if (task instanceof WorkTask) {
      return task.workTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(',');
    }
  }

  showScheduled(task): boolean {
    if (task instanceof WellnessTask) {
      return task.scheduleDateFrom
        && task.completionStatus !== WellnessTaskCompletionStatus.Customer_Not_Notified
        && task.completionStatus !== WellnessTaskCompletionStatus.Ready_to_Schedule;
    } else if (task instanceof WorkTask) {
      return task.scheduleDateFrom
      && task.completionStatus !== WorkTaskCompletionStatus.Customer_Not_Notified
      && task.completionStatus !== WorkTaskCompletionStatus.Ready_to_Schedule;
    }

    return false;
  }

  showGoBack(task): boolean {
    return task.isGoBack && task.goBackBucketId !== undefined;
  }
}
