<p-dialog header="Billing Information" [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '640px'}" [minY]="70" appendTo="body"
  [baseZIndex]="10000" [closable]="false" [maximizable]="false" [blockScroll]="true" class="billing-dialog"
>
  <app-billing-profile-entry [stripeBilling]="stripeBilling" [customer]="customer" (emitUpdatedStripeCustomerId)="afterSave($event)" (emitClose)="cancel()"></app-billing-profile-entry>
  <div class="button-group" style="float: right">
    <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" label="Cancel" style="margin-right: 5px;"
      (click)="cancel()" *ngIf="!isCustomer">
    </button>
    <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle" label="Save"
      (click)="save()">
    </button>
  </div>
</p-dialog>