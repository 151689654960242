import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedColorService {

  constructor() { }

  public findContrastingTextColor(h: number, s: number, l: number): string {
    const rgb = this.convertHSLtoRGB(h, s, l);

    return this.getFontColor(rgb);
  }

  public getFontColor(rgb): string {
      return (((rgb.r * 0.299) + (rgb.g * 0.587) + (rgb.b * 0.114)) > 150) ? 'black' : 'white';
  }

  public convertHSLtoRGB(h: number, s: number, l: number) {
      s /= 100;
      l /= 100;

      const c = (1 - Math.abs(2 * l - 1)) * s,
          x = c * (1 - Math.abs((h / 60) % 2 - 1)),
          m = l - c / 2;
      let r = 0,
          g = 0,
          b = 0;

      if (0 <= h && h < 60) {
          r = c; g = x; b = 0;
      } else if (60 <= h && h < 120) {
          r = x; g = c; b = 0;
      } else if (120 <= h && h < 180) {
          r = 0; g = c; b = x;
      } else if (180 <= h && h < 240) {
          r = 0; g = x; b = c;
      } else if (240 <= h && h < 300) {
          r = x; g = 0; b = c;
      } else if (300 <= h && h < 360) {
          r = c; g = 0; b = x;
      }
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);
      return {
          r,
          g,
          b
      };
  }

  hextoRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  hexToRgbStr(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const resultStr = 'rgb(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ')';
    return resultStr;
  }

  rgbStrToRgbObject(rgbString: string) {
    return rgbString.substring(4, rgbString.length - 1).replace(/ /g, '').split(',');
  }

  public rgbObjectToRgbString(rgb) {
    return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  }
}
