<div>
  <form id="hoursEntry" #hoursEntry="ngForm">
    <h5>
      {{title}}
    </h5>

    <textarea name="feedback" [rows]="5" style="width: 100%" pInputTextarea [(ngModel)]="task.jobFeedback" required *ngIf="!task.isGoBack"></textarea>
    <textarea name="feedback" [rows]="5" style="width: 100%" pInputTextarea [(ngModel)]="task.goBackFeedback" required *ngIf="task.isGoBack"></textarea>

    <h5>
      HOURS ON THE JOB
    </h5>
    <div>
      Please submit to the quarter hour. Example: if employee worked 6 hrs & 15 minutes, input 6.25.
    </div>
 
    <div *ngIf="scheduledBucket || bigDayBucketSubrange" style="max-height: 15em; overflow-y: scroll; overflow-x: hidden;">
      <div *ngFor="let taskHourEntry of task.taskHourEntries">
        <div class="p-grid" *ngIf="asGoBack ? taskHourEntry.isGoBack : !taskHourEntry.isGoBack">
          <div class="p-col-6">
            <app-employee-card style="margin: auto; vertical-align: middle" [employee]="taskHourEntry.employee" [deleteable]="false"></app-employee-card>
          </div>
          <div class="p-col-6 ui-fluid">
            <input required type="text" pInputText [(ngModel)]="taskHourEntry.hours" (ngModelChange)="getHourTotal()" [name]="taskHourEntry.id + '-' + taskHourEntry.employeeId + '-hours'" />
          </div>
        </div>
      </div>
      <div *ngIf="addingEmployee">
        <ng-select class="custom" required [items]="employees" placeholder="Select an Employee" [selectOnTab]="true"
          (ngModelChange)="addNewTaskHourEntry($event)" name="certifiedApplicator" bindLabel="fullName"
          [(ngModel)]="selectedEmployee">
        </ng-select>
      </div>
    </div>

    
    
    <div class="p-grid">
      <div class="p-col-6 ui-fluid">
        <button pButton type="button" label="Add Employee" icon="pi pi-plus" class="ui-button-raised ui-button-secondary" (click)="addEmployee()"></button>
      </div>
      <div class="p-col-6" style="text-align: right;">
        Total: {{totalHours}} hrs
      </div>
    </div>
    <div style="margin-bottom: 5px;" *ngIf="unableToComplete">
      <p-checkbox name="unableToCompletePermanently" [(ngModel)]="unableToCompletePermanently" binary="true" label="Permanently Unable to Complete?"></p-checkbox>
      <!-- Focus doesn't seem to work even with hacks, so I'm just using it to turn this off then using p-overlay for mobile.  -->
      <i #TheMostIrritatingIconInTheWorld
        style="vertical-align: middle; margin: auto;" 
        class="pi pi-info-circle" 
        (click)="onInfoClick($event)"
        [pTooltip]="iconMessage"
      >
      <p-overlayPanel #op [appendTo]="'body'" *ngIf="isTouchscreen">
        {{iconMessage}}
      </p-overlayPanel>
    </i>
    </div>

    <div *ngIf="verifyPrice">
      <h5>
        CONFIRM PRICE
      </h5>
      <div>
        Please input the final price of this task (not including tax). If needed, please consult with the Regional Manager on the work order to ensure you are submitting the correct price. The price you input below is what the customer will receive in their invoice.
      </div>
      <div class="fine-print">
        {{priceInfo}}
      </div>
      <div>
        <input pInputText
          class="inputTextBox"
          name="price"
          required
          numeric
          [textMask]="{mask: currencyMask}"
          [(ngModel)]="task.price"
          min="{{task.minPrice}}"
        />
      </div>
      <br/>
    </div>

    <button pButton type="submit" label="Submit" (click)="submit(hoursEntry)" class="ui-button-success" [disabled]="disableSubmit || (!scheduledBucket && !bigDayBucketSubrange)"></button>
  </form>
</div>