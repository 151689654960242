import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { IWorkOrder } from 'src/app/services_autogenerated/generated_services';
import * as moment from 'moment';

@Component({
  selector: 'app-work-order-item',
  templateUrl: './work-order-item.component.html',
  styleUrls: ['./work-order-item.component.css']
})
export class WorkOrderItemComponent implements OnInit {
  @Input() workOrder: IWorkOrder;

  constructor(public customCustomerService: CustomerService) { }

  ngOnInit() {
  }

}
