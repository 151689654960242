/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporting.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./reporting.component";
import * as i3 from "../../services/app-config.service";
import * as i4 from "@angular/platform-browser";
var styles_ReportingComponent = [i0.styles];
var RenderType_ReportingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportingComponent, data: {} });
export { RenderType_ReportingComponent as RenderType_ReportingComponent };
export function View_ReportingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reporting"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [["allowFullScreen", "true"], ["frameborder", "0"], ["height", "700"], ["width", "1000"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportLink; _ck(_v, 3, 0, currVal_0); }); }
export function View_ReportingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reporting", [], null, null, null, View_ReportingComponent_0, RenderType_ReportingComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReportingComponent, [i3.AppConfigService, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportingComponentNgFactory = i1.ɵccf("app-reporting", i2.ReportingComponent, View_ReportingComponent_Host_0, {}, {}, []);
export { ReportingComponentNgFactory as ReportingComponentNgFactory };
