import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CustomerNoteGenSvc,
  CustomerComment,
  CustomerCommentStage,
  CustomerCommentStageGenSvc,
  RequestChangesEmailRequest,
  QuoteWorkOrder} from '../../../services_autogenerated/generated_services';
import { Base64 } from 'src/app/models/base64';

@Component({
  selector: 'app-request-changes-dialog',
  templateUrl: './request-changes-dialog.component.html',
  styleUrls: ['./request-changes-dialog.component.css']
})
export class RequestChangesDialogComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() quote: QuoteWorkOrder;
  @Output() close  = new EventEmitter<boolean>();

  changeRequest: string;
  disableSubmit = true;
  stages: CustomerCommentStage[];

  constructor(private customerCommentService: CustomerNoteGenSvc,
    private messageService: MessageService,
    private customerCommentStageService: CustomerCommentStageGenSvc) { }

  ngOnInit() {
    this.customerCommentStageService.get().subscribe(stages => {
      this.stages = stages;
    });
  }

  closeDialog(navigate: boolean) {
    this.close.emit(navigate);
  }

  changeRequestUpdated() {
    this.disableSubmit = this.changeRequest.length < 3;
  }

  cancel() {
    this.closeDialog(false);
  }

  sendRequest() {
    const comment: CustomerComment = new CustomerComment({
      active: true,
      comment: this.changeRequest,
      customerId: this.quote.customer.id,
      quoteNumber: this.quote.quoteNumber,
      customerCommentStage: this.stages.find(stage => stage.commentStage === 'Quote'),
      companyWide: false
    });

    const encodedCustId = Base64.encode(this.quote.customerId.toString());
    const encodedQuoteId = Base64.encode(this.quote.id.toString());
    
    const requestChange: RequestChangesEmailRequest = new RequestChangesEmailRequest({
      customerComment: comment,
      customerEmails: this.quote.customer.customerContacts.filter(cc => cc.active).map(cc => cc.emailAddress.email),
      customerName: this.quote.customer.customerContacts.find(cc => cc.primary).fullName,
      recipientEmail: this.quote.representative.emailAddress.email,
      requestedChangesText: this.changeRequest,
      quoteNumber: this.quote.quoteNumber,
      encodedCustId: encodedCustId,
      encodedQuoteId: encodedQuoteId
    });

    this.customerCommentService.addQuoteChangeRequest(requestChange).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Message Sent',
        detail: 'Your request has been emailed to your Regional Manager! We will be in touch soon with an update!',
        life: 10000 // 10 seconds
      });
      this.closeDialog(true);
    });
  }
}
