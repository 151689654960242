import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import {
  EquipmentGenSvc, EmployeeGenSvc, RoleGenSvc, Crew, EquipmentTypeGenSvc,
  CrewGenSvc
} from '../../services_autogenerated/generated_services';
import { Employee, Equipment, Role, EquipmentType } from '../../services_autogenerated/generated_services';
import { EquipmentFilterPipe } from '../../pipes/equipment-filter.pipe';
import { PeopleFilterPipe } from '../../pipes/people-filter.pipe';
import * as moment from 'moment';
import { CrewCreationCardComponent } from '../crew-creation-card/crew-creation-card.component';
import { CrewDragAndDropService } from 'src/app/services/crew-drag-and-drop.service';

@Component({
  selector: 'app-crew-creation',
  templateUrl: './crew-creation.component.html',
  styleUrls: ['./crew-creation.component.css'],
  providers: [EquipmentFilterPipe, PeopleFilterPipe]
})
export class CrewCreationComponent implements OnInit {
  @ViewChildren(CrewCreationCardComponent) crewCreationCardComponents: QueryList<CrewCreationCardComponent>;

  allEquipment: Equipment[] = [];
  allEmployees: Employee[] = [];
  allCrews: Crew[] = [];
  allCrewCardListIds: string[];
  equipmentTypes: EquipmentType[] = [];
  selectedType: EquipmentType;
  quarterStart: moment.Moment;
  quarterEnd: moment.Moment;
  quarter: number;
  searchTermEquip = '';
  searchTermPeople = '';
  selectedRole: Role;
  allRoles: Role[];
  filteredEquipmentList: Equipment[];
  filteredPeopleList: Employee[];

  constructor(
    private equipmentService: EquipmentGenSvc,
    private equipmentFilter: EquipmentFilterPipe,
    private peopleFilter: PeopleFilterPipe,
    private employeeService: EmployeeGenSvc,
    private roleService: RoleGenSvc,
    private equipmentTypeService: EquipmentTypeGenSvc,
    private crewService: CrewGenSvc,
    public dragAndDropService: CrewDragAndDropService
  ) { }

  ngOnInit() {
    this.quarter = moment().quarter();
    this.quarterStart = moment().quarter(this.quarter).startOf('quarter');
    this.quarterEnd = moment().quarter(this.quarter).endOf('quarter');
    this.getCrewsByQuater();

    this.equipmentService.getAll().subscribe(equipment => {
      this.allEquipment = equipment;
      this.filteredEquipmentList = equipment;
    });

    this.employeeService.getCurrentlyEmployed().subscribe(employees => {

      this.allEmployees = employees;
      this.filteredPeopleList = employees;
    });

    this.roleService.getAll().subscribe(roles => {
      this.allRoles = roles;
    });

    this.equipmentTypeService.getAll().subscribe(types => {
      this.equipmentTypes = types;
    });

    this.dragAndDropService.getCrewUpdater().subscribe(crew => this.onCrewUpdate(crew));
  }

  onCrewUpdate(crew: Crew) {
    if (crew && this.crewCreationCardComponents) {
      const match = this.crewCreationCardComponents.find(x => x.crew.id === crew.id);
      match.onCrewUpdate();
    }
  }

  getCrewsByQuater() {
    this.crewService.getCrewsInDateRange(this.quarterStart.toDate(), this.quarterEnd.toDate()).subscribe(crews => {
      this.allCrews = crews;
      this.dragAndDropService.crews = crews;

      this.dragAndDropService.allCrewEmployeeLists = crews.map(crew => 'crewEmployeeList-' + crew.id);
      this.dragAndDropService.allCrewEquipmentLists = crews.map(crew => 'crewEquipmentList-' + crew.id);

      this.dragAndDropService.addCrewCreationLists();
    });
  }

  changeQuarter(number: number) {
    // To go back a quarter, the number is passed in as -1. To go forward a quarter, the number is passed in as 1
    // Using moment's add method automagically figures out the quarters even moving bewtween years
    this.quarterStart.add(number, 'Q').startOf('quarter');
    this.quarterEnd.add(number, 'Q').endOf('quarter');
    this.quarter = this.quarterStart.quarter();

    this.getCrewsByQuater();
  }

  addCrew() {
    const crew: Crew = new Crew();
    crew.active = true;
    crew.crewEmployees = [];
    crew.crewEquipment = [];
    crew.startDate = this.quarterStart.toDate();
    crew.endDate = this.quarterEnd.startOf('day').toDate();

    this.crewService.add(crew).subscribe(addedCrew => {
      this.allCrews = [addedCrew, ...this.allCrews];
      this.dragAndDropService.addCrewLists(addedCrew);
    });
  }

  deleteCrew(crew: Crew) {
    if (confirm('Do you want to delete this crew?')) {
      this.allCrews.splice(this.allCrews.findIndex(c => c.id === crew.id), 1);
      this.crewService.delete(crew.id).subscribe();
    }
  }

  equipTypeChanged() {
    if (this.selectedType) {
      const temp = this.allEquipment.filter(equipment => equipment.equipmentType.type === this.selectedType.type);
      this.filteredEquipmentList = temp;
    } else {
      this.filteredEquipmentList = this.allEquipment;
    }
  }

  roleChanged() {
    if (this.selectedRole) {
      const temp = this.allEmployees.filter(person => person.employeeRoles.some(er => er.role.name === this.selectedRole.name));
      this.filteredPeopleList = temp;
    } else {
      this.filteredPeopleList = this.allEmployees;
    }
  }

}
