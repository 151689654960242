import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { ExportGenSvc, ExportModel } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-export-row',
  templateUrl: './export-row.component.html',
  styleUrls: ['./export-row.component.css']
})
export class ExportRowComponent implements OnInit {
  @Input() export: ExportModel
  isLoading: boolean;
  constructor(
    private datePipe: DatePipe,
    private exportService: ExportGenSvc
    ) { }

  ngOnInit() {
  }

  doExport(id: number) {
    this.isLoading = true;
    this.exportService.export(id).subscribe(res => {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        headers: res.headers
      };
      const file = new ngxCsv(res.jSONData, `${this.export.name} ${this.datePipe.transform(new Date(), 'medium')}`, options);
      this.isLoading = false;
    }, err => {
      console.log(err);
      this.isLoading = false;
    })
  }
}
