import { OnInit, Input } from '@angular/core';
import { WorkTask, WellnessTask, EquipmentType } from 'src/app/services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { PriorityService } from 'src/app/services/priority.service';
import { WellnessWorkOrder, WorkWorkOrder } from 'src/app/services_autogenerated/generated_services';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import * as moment from 'moment';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

export abstract class BaseWorkOrderCardComponent {
  isCollapsed = false;
  unit: string;
  canSeeHiddenPrice: boolean;

  @Input() showScheduled: boolean;

  @Input() shouldShowLeftColor = true;
  @Input() shouldShowRightColor = true;

  @Input() showMap = false;

  @Input() abstract workOrder: WellnessWorkOrder | WorkWorkOrder;

  hasCompanyWideNotes = false;

  constructor(
    public dragAndDropService: DragAndDropService,
    public router: Router,
    public priorityService: PriorityService,
    public workOrderHelperService: WorkOrderHelperService,
    public authHelper: AuthHelperService
  ) {
    this.dragAndDropService = dragAndDropService;
  }

  // will be overridden
  shouldDisableWODrag() {
    return this.workOrder.completionStatus === 'Completed';
  }

  shouldDisableTaskDrag(task: WorkTask | WellnessTask): boolean {
    return !this.workOrderHelperService.isTaskQualifiedToBeScheduled(task);
  }

  getDueDateDisplay(task: WorkTask | WellnessTask): string {
    const dueDateStart = moment(task.dueDateStart).format('M/DD/YY');
    const dueDateEnd = moment(task.dueDateEnd).format('M/DD/YY');

    if (task.hardStartDate && task.hardEndDate) {
      if (moment(task.dueDateStart).isSame(moment(task.dueDateEnd), 'day')) {
        return dueDateStart;
      } else {
        return `${dueDateStart} - ${dueDateEnd}`;
      }
    } else if (task.hardStartDate) {
      return dueDateStart;
    } else if (task.hardEndDate) {
      return dueDateEnd;
    } else {
      return `${dueDateStart} - ${dueDateEnd}`;
    }
  }

  abstract getEquipmentTypeDisplay(): string;

  openMap() {
    this.workOrderHelperService.openMap(this.workOrder);
  }

  public abstract updateWorkOrderDisplay(): void;

  protected getZipColor() {
    return this.shouldShowLeftColor ?
      ((this.workOrder.address && this.workOrder.address.textColor) ?
        this.workOrder.address.textColor : '')
      : 'black';
  }

  protected getZipBackgroundColor() {
    return this.shouldShowLeftColor ?
    ((this.workOrder.address && this.workOrder.address.geoColor) ?
      this.workOrder.address.geoColor : '')
    : '#ebebeb';
  }

  protected getPriorityColor() {
    return this.shouldShowRightColor ?
      ((this.workOrder.mostUrgentPriorityLevel && this.workOrder.mostUrgentPriorityLevel.textColor) ?
        this.workOrder.mostUrgentPriorityLevel.textColor : '')
      : 'black';
  }

  protected getPriorityBackgroundColor() {
    return this.shouldShowRightColor ?
    ((this.workOrder.mostUrgentPriorityLevel && this.workOrder.mostUrgentPriorityLevel.backgroundColor) ?
      this.workOrder.mostUrgentPriorityLevel.backgroundColor : '')
    : '#ebebeb';
  }
}
