<p-dialog header="Additional Information" [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '450px', minWidth: '200px'}" [minY]="70" 
  [baseZIndex]="10000" [closable]="true" [maximizable]="false" (onHide)="close()"
>
  <div class="bold">
    {{quoteRequest.customer.fullName}}
  </div>
  <div class="bold">
    {{quoteRequest.address.street}} {{quoteRequest.address.city}}, {{quoteRequest.address.state.abbreviation}} {{quoteRequest.address.zip}}
  </div>
  <div class="bold">
    {{quoteRequest.customer.primaryPhone | phoneNumber}}
  </div>
  <div>
    {{quoteRequest.description}}
  </div>
  <hr/>
  <div>
    {{event.start | date: 'shortTime'}} - {{event.end | date: 'shortTime'}}
    <br/>
    {{event.start | date: 'EEEE, MMMM d'}}
  </div>
</p-dialog>