<div class="p-grid">
  <div class="p-col-2">
    <h6>{{rm.fullName}}</h6>
    <!-- <button pButton label="Go to Time Slots"></button> -->
  </div>
  <div class="p-col-10 multiSelect">
    <h6>Zip Code(s)</h6>
    <p-multiSelect 
      [options]="zipCodeOptions" 
      [(ngModel)]="rm.zipCodeAssignments" 
      [panelStyle]="{minWidth:'12em'}" 
      name="zipCodeAssignments" 
      [disabled]="!canEdit"
      [showToggleAll]="false"
      *ngIf="rm"
    >
      <ng-template let-value pTemplate="selectedItems">
        <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
          <span>{{val.zipCode.code}} - {{val.zipCode.city}}</span>
        </div>
        <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Choose</span>
      </ng-template>
      <ng-template let-item pTemplate="item">
        {{item.label}}
      </ng-template>
    </p-multiSelect>
  </div>
</div>