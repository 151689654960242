import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseWorkWorkOrderCardComponent } from '../base-work-work-order-card/base-work-work-order-card.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { EquipmentType } from 'src/app/services_autogenerated/generated_services';
var WorkWorkOrderCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkWorkOrderCardComponent, _super);
    function WorkWorkOrderCardComponent(dragAndDropService, router, priorityService, workOrderHelperService, customCustomerService, authHelper) {
        var _this = _super.call(this, dragAndDropService, router, priorityService, workOrderHelperService, authHelper) || this;
        _this.customCustomerService = customCustomerService;
        return _this;
    }
    WorkWorkOrderCardComponent.prototype.getEquipmentTypeDisplay = function () {
        var equipmentTypes = [];
        this.unscheduledTasks.forEach(function (wowt) {
            if (wowt.workTask.isGoBack) {
                equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskGoBackEquipment.map(function (wte) { return wte.equipmentType; }));
            }
            else {
                equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskEquipment.map(function (wte) { return wte.equipmentType; }));
            }
        });
        return this.workOrderHelperService.getUnique(equipmentTypes).map(function (et) { return et.abbreviation ? et.abbreviation : et.type; }).join(' / ');
    };
    // Override
    WorkWorkOrderCardComponent.prototype.setTasks = function () {
        // Total amount of task scheduled, even ones not scheduled on given bucket day
        this.scheduledTasksTotal = this.workOrder.workOrderWorkTasks.filter(function (task) {
            return !((!task.workTask.isGoBack && !task.workTask.currentBucketId) || (task.workTask.isGoBack && !task.workTask.goBackBucketId));
        }).length;
        // Tasks still unscheduled
        this.unscheduledTasks = this.workOrder.workOrderWorkTasks.filter(function (task) {
            return (!task.workTask.isGoBack && !task.workTask.currentBucketId) || (task.workTask.isGoBack && !task.workTask.goBackBucketId);
        });
    };
    // Override
    WorkWorkOrderCardComponent.prototype.setBottomSection = function () {
        this.hoursSummary = this.unscheduledTasks.reduce(function (acc, curr) { return acc + curr.workTask.hours; }, 0);
        this.hoursSummary += this.unscheduledTasks.reduce(function (acc, curr) { return acc + curr.workTask.goBackHoursEstimate; }, 0);
        this.priceSummary = this.unscheduledTasks.reduce(function (acc, curr) { return acc + curr.workTask.price; }, 0);
        this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
    };
    return WorkWorkOrderCardComponent;
}(BaseWorkWorkOrderCardComponent));
export { WorkWorkOrderCardComponent };
