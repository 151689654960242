import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WellnessWorkOrderCashCollected, WorkOrderGenSvc, WorkOrderInvoicingViewModel, WorkWorkOrderCashCollected } from 'src/app/services_autogenerated/generated_services';
import { MaskService } from 'src/app/services/mask.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-add-cash',
  templateUrl: './add-cash.component.html',
  styleUrls: ['./add-cash.component.css']
})
export class AddCashComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() workOrder: WorkOrderInvoicingViewModel;
  @Output() close  = new EventEmitter<boolean>();
  @Output() charge = new EventEmitter<WorkOrderInvoicingViewModel>();

  disableSave: boolean;

  isLoading: boolean;

  cashCollected: (WellnessWorkOrderCashCollected | WorkWorkOrderCashCollected)[] = [];
  currencyMask: any;
  saving: boolean;

  constructor(
    private maskService: MaskService,
    private messageService: MessageService,
    private workOrderService: WorkOrderGenSvc) { }

  ngOnInit() {
    this.currencyMask = this.maskService.currencyMask;
    this.isLoading = true;
    if (this.workOrder.serviceType === 'Tree Wellness') {
      this.workOrderService.getWellnessCashCollected(this.workOrder.workOrderId).subscribe(res => {
        this.cashCollected = res;
        this.isLoading = false;
      });
    } else {
      this.workOrderService.getWorkCashCollected(this.workOrder.workOrderId).subscribe(res => {
        this.cashCollected = res;
        this.isLoading = false;
      });
    }
  }

  isCashCollectedEditable() {
    return !this.workOrder.paymentStatus || this.workOrder.paymentStatus === 'Not Paid';
  }

  pushNewCashCollectedEntry() {
    if (!this.cashCollected) {
      this.cashCollected = [];
    }

    if (this.workOrder.serviceType === 'Tree Wellness') {
      const newEntry = new WellnessWorkOrderCashCollected();
      newEntry.invoiced = false;
      newEntry.wellnessWorkOrderId = this.workOrder.workOrderId;
      newEntry.amount = null;
      this.cashCollected.push(newEntry);
    } else if (this.workOrder.serviceType === 'Tree Work') {
      const newEntry = new WorkWorkOrderCashCollected();
      newEntry.invoiced = false;
      newEntry.workWorkOrderId = this.workOrder.workOrderId;
      newEntry.amount = null;
      this.cashCollected.push(newEntry);
    }
  }

  cashCollectedChange(newValue: string, index: number) {
    if (newValue && newValue.length > 0 && newValue[0] === '$') {
      if (newValue[0] === '$') {
        newValue = newValue.replace('$', '');
      }
      newValue = newValue.split(',').join('');
    }
    if (newValue && newValue.length > 0) {
      this.cashCollected[index].amount = parseFloat(newValue);
    } else {
      this.cashCollected[index].amount = 0;
    }
  }

  removeCashCollectedEntry(index) {
    this.cashCollected.splice(index, 1);
  }

  save(form: NgForm) {
    Object.keys(form.controls).forEach( key => {
      form.controls[key].markAsTouched();
      form.controls[key].markAsDirty();
    });

    if (form.valid) {
      this.saving = true;
      if (this.workOrder.serviceType === 'Tree Wellness') {
        this.workOrderService.saveWellnessCashCollectedRange(this.cashCollected, this.workOrder.workOrderId).subscribe(() => {
          this.checkIfPaid();
        }, error => {
          console.log(error);
          this.saving = false;
          this.addErrorMessage('Could not save cash collected. Ensure all fields are valid and retry.');
        });
      } else {
        this.workOrderService.saveWorkCashCollectedRange(this.cashCollected, this.workOrder.workOrderId).subscribe(() => {
          this.checkIfPaid();
        }, error => {
          console.log(error);
          this.saving = false;
          this.addErrorMessage('Could not save cash collected. Ensure all fields are valid and retry.');
        });
      }
    }
  }

  checkIfPaid() {
    const amount = this.cashCollected.filter(cc => !cc.invoiced).reduce((curr, acc) => curr + acc.amount, 0);
    if (amount === this.workOrder.amountDue) {
      if (confirm('Amount of cash collected is equal to the amount due, apply it to invoice and mark it as paid?')) {
        this.charge.emit(this.workOrder);
      } else {
        this.cancel(true);
      }
    } else {
      this.cancel(true);
    }
  }

  cancel(refresh: boolean) {
    this.saving = false;
    this.close.emit(refresh);
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }
}
