import { Component, OnInit } from '@angular/core';
import { WorkTaskTemplate, WorkTaskTemplateGenSvc } from 'src/app/services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-work-task-template-list',
  templateUrl: './work-task-template-list.component.html',
  styleUrls: ['./work-task-template-list.component.css']
})

/**
 * NOTE:
 * If you do not know how WorkTaskTemplate corresponds to WorkTask, then please get clarification
 * prior to changing this component.
 */

export class WorkTaskTemplateListComponent implements OnInit {

  cols: any[];
  tasks: WorkTaskTemplate[] = [];
  getInactives: boolean;

  constructor(private workTaskTemplateService: WorkTaskTemplateGenSvc,
    private router: Router,
    private authHelper: AuthHelperService) {
      this.cols = [
      { field: 'name', header: 'Task Name', width: '18%'  },
      { field: 'description', header: 'Description', width: '60%'  },
      // { field: 'cost', header: 'Cost', width: '8%'  },
      { field: 'price', header: 'Price', width: '8%'  },
      { field: 'active', header: 'Status', width: '7%' },
      { header: '', width: '7%'  }
    ];
  }

  ngOnInit() {
    this.getTasks();
  }

  showDeleteForTask(task): boolean {
    let showDelete = false;
    const token = this.authHelper.getDecodedAccessToken();

    // only show the "delete" button if user has permissions and the task is inactive
    showDelete = token.claimNames.includes('View/Edit Task Template Page');

    return showDelete;
  }

  getTasks() {
    if (this.getInactives) {
      this.workTaskTemplateService.getAll().subscribe(tasks => {
        this.tasks = tasks;
      });
    } else {
      this.workTaskTemplateService.getAllActive().subscribe(tasks => {
        this.tasks = tasks;
      });
    }
  }

  addNew() {
    this.router.navigateByUrl('/workTaskTemplateMaintenance');
  }

  editTask(task) {
    this.router.navigateByUrl('/workTaskTemplateMaintenance/' + task.id);
  }

  deleteTask(task) {
    if (confirm('Do you want to delete this work task template?')) {
      this.workTaskTemplateService.delete(task.id).subscribe(() => {
        this.tasks = this.tasks.filter(obj => obj !== task);
      });
    }
  }
}
