<div class="ui-g">
  <div class="ui-g-12"
    style="margin-top: 11px;" *ngIf="isEditable && !task.id">
    <ng-select 
        class="custom"
        required
        [items]="templateOptions" 
        placeholder="Select a Task Template" 
        [selectOnTab]="true"
        name="task" 
        bindLabel="name"
        (change)="onTemplateSelect($event)"
      >
    </ng-select>
  </div>
</div>
<form class="ui-g" id="workTaskForm" #workTaskForm="ngForm">  
  <div class="ui-g-12" *ngIf="canChangeStatus">
    <label>Status</label>
    <ng-select
      class="custom"
      [items]="statuses"
      name="status"
      [clearable]="false"
      [(ngModel)]="task.completionStatus"
    ></ng-select>
  </div>
  <div class="ui-g-12" *ngIf="canChangeStatus && task.completionStatus != 'Completed'">
    <label>Customer's Issue</label>
    <textarea
      pInputTextarea
      [(ngModel)]="task.customerIssue"
      [rows]="3"
      name="customerIssue"
      style="width: 100%;"
      required
      placeholder=""
    ></textarea>
  </div>
  <div class="ui-g-12" *ngIf="canChangeStatus && (task.completionStatus == 'Go Back' || isIncompleteGoBack)">
    <label>Hours (For Go Back)</label>
    <input pInputText
      class="inputTextBox"
      name="goBackHours"
      required
      [(ngModel)]="task.goBackHoursEstimate"
      *ngIf="task"
    />
  </div>
  <div class="ui-g-12" *ngIf="canChangeStatus && (task.completionStatus == 'Go Back' || isIncompleteGoBack)">
    <label>Required Equipment (For Go Back)</label>
    <p-multiSelect [options]="goBackEquipmentOptions"
      [(ngModel)]="task.workTaskGoBackEquipment"
      [panelStyle]="{minWidth:'12em'}"
      name="goBackEquipment"
      optionLabel="equipmentType.type"
      required
      *ngIf="task">
      <ng-template let-value
        pTemplate="selectedItems">
        <div *ngFor="let val of value"
          class="ui-multiselected-item-token ui-corner-all">
          <span>{{val.equipmentType.type}}</span>
        </div>
        <span *ngIf="!value || value.length === 0"
          class="ui-multiselected-empty-token ui-corner-all">Choose</span>
      </ng-template>
      <ng-template let-equipment
        pTemplate="item">
        {{equipment.label}}
      </ng-template>
    </p-multiSelect>
  </div>
  <div class="ui-g-12" *ngIf="canChangeStatus && (task.completionStatus == 'Go Back' || isIncompleteGoBack)">
    <label>Uploads (For Go Back)</label>
    <app-blob-manager
      [containerName]="'work-task-go-back'" 
      [parentRecordId]="uploadId" 
      [uploadLabel]="'Document/Photo'" 
      [viewOnly]="!canEditUploads">
    </app-blob-manager>
  </div>
  <div class="ui-g-12">
    <p-card>
      <div class="ui-g">
        <div class="ui-g-12 ui-lg-6 ui-md-9">
          <h6>Task Name</h6>
          <input pInputText
            name="name"
            class="inputTextBox"
            [(ngModel)]="task.name"
            *ngIf="task"
            [disabled]="!isEditable"
            required />
        </div>
        <div class="ui-g-12 ui-lg-6 ui-md-3" style="text-align: left; margin-top: auto">
          <p-radioButton
          name="permissionRequired"
          [value]="true"
          [label]="'Fixed Price'"
          [(ngModel)]="task.fixedPrice"
          [disabled]="!isEditable"
          required
          *ngIf="task">
        </p-radioButton><br/>
        <p-radioButton
          name="permissionNotRequired"
          [value]="false"
          [label]="'Crew Hourly Rate'"
          [(ngModel)]="task.fixedPrice"
          [disabled]="!isEditable"
          required
          *ngIf="task">
        </p-radioButton>
        </div>
        <!-- Begin text boxes for fixed price option -->
        <div class="ui-g-12 ui-lg-6 ui-md-3" *ngIf="task.fixedPrice">
          <h6>Price</h6>
          <input pInputText
            class="inputTextBox"
            name="price"
            required
            numeric
            [textMask]="{mask: currencyMask}"
            [ngModel]="task.price"
            *ngIf="task"
            [disabled]="!isEditable || isInvoiced"
            (ngModelChange)="task.price=$event" />
        </div>
        <div class="ui-g-12 ui-lg-6 ui-md-3" *ngIf="task.fixedPrice">
          <h6>Hours Estimate</h6>
          <input pInputText
            class="inputTextBox"
            name="hours"
            required
            [(ngModel)]="task.hours"
            *ngIf="task"
            [disabled]="!isEditable" />
        </div>
        <!-- Begin text boxes for hourly price option -->
        <div class="ui-g-12 ui-lg-6 ui-md-3" *ngIf="!task.fixedPrice">
          <h6>Crew Hourly Rate</h6>
          <input pInputText
            class="inputTextBox"
            name="hourlyRate"
            numeric
            [textMask]="{mask: currencyMask}"
            [ngModel]="task.hourlyRate"
            *ngIf="task"
            [disabled]="isInvoiced"
            required
            (ngModelChange)="task.hourlyRate=$event" />
        </div>
        <div class="ui-g-12 ui-lg-6 ui-md-3" *ngIf="!task.fixedPrice"><!-- Spacer --></div>
        <div class="ui-g-12 ui-lg-6 ui-md-3" *ngIf="!task.fixedPrice">
          <h6>Minimum Price</h6>
          <input pInputText
            class="inputTextBox"
            name="minPrice"
            numeric
            [textMask]="{mask: currencyMask}"
            [ngModel]="task.minPrice"
            *ngIf="task"
            [disabled]="isInvoiced"
            required
            (ngModelChange)="task.minPrice=$event" />
        </div>
        <div class="ui-g-12 ui-lg-6 ui-md-3" *ngIf="!task.fixedPrice">
          <h6>Maximum Price</h6>
          <input pInputText
            class="inputTextBox"
            name="maxPrice"
            numeric
            [textMask]="{mask: currencyMask}"
            [ngModel]="task.maxPrice"
            *ngIf="task"
            [disabled]="isInvoiced"
            (ngModelChange)="task.maxPrice=$event" />
        </div>
        <!-- End sections related to fixed/hourly costs -->
        <div class="ui-g-12 columnPadding topMargin">
          <h6>Description</h6>
          <textarea pInputTextarea
            name="description"
            required
            class="form-control"
            [(ngModel)]="task.description"
            *ngIf="task"
            [rows]="8"
            style="width: 100%;"
            [disabled]="!isEditable">
          </textarea>
        </div>
      </div>
    </p-card>
  </div>
  <div class="ui-g-12 multiSelect">
    <h6>Tree Type(s)</h6>
    <p-multiSelect [options]="treeTypeOptions"
      [(ngModel)]="task.workTaskTreeTypes"
      [panelStyle]="{minWidth:'12em'}"
      [disabled]="!isEditable"
      [showToggleAll]="false"
      name="treeTypes"
      required
      *ngIf="task">
      <ng-template let-value
        pTemplate="selectedItems">
        <div *ngFor="let val of value"
          class="ui-multiselected-item-token ui-corner-all">
          <span>{{val.treeType.commonName}}</span>
        </div>
        <span *ngIf="!value || value.length === 0"
          class="ui-multiselected-empty-token ui-corner-all">Choose</span>
      </ng-template>
      <ng-template let-wttt pTemplate="item">
        {{wttt.label}}
      </ng-template>
    </p-multiSelect>
  </div>
  <div class="ui-g-12">
    <h6>Location of Tree(s)</h6>
    <textarea pInputTextarea
      [(ngModel)]="task.location"
      [disabled]="!isEditable"
      class="form-control"
      [rows]="2"
      name="location"
      style="width: 100%;"
      required
      *ngIf="task">
    </textarea>
  </div>
  <div class="ui-g-12 multiSelect">
    <h6>Required Skill(s)/Certification(s)</h6>
    <p-multiSelect [options]="skillOptions"
      [(ngModel)]="task.workTaskSkills"
      [disabled]="!isEditable"
      name="skills"
      [panelStyle]="{minWidth:'12em'}"
      optionLabel="skill.name"
      *ngIf="task">
      <ng-template let-value
        pTemplate="selectedItems">
        <div *ngFor="let val of value"
          class="ui-multiselected-item-token ui-corner-all">
          <span>{{val.skill.name}}</span>
        </div>
        <span *ngIf="!value || value.length === 0"
          class="ui-multiselected-empty-token ui-corner-all">Choose</span>
      </ng-template>
      <ng-template let-skill
        pTemplate="item">
        {{skill.label}}
      </ng-template>
    </p-multiSelect>
  </div>
  <div class="ui-g-12 multiSelect">
    <h6>Required Equipment Type(s)</h6>
    <p-multiSelect [options]="equipmentOptions"
      [(ngModel)]="task.workTaskEquipment"
      [panelStyle]="{minWidth:'12em'}"
      [disabled]="!isEditable"
      name="equipment"
      optionLabel="equipmentType.type"
      *ngIf="task">
      <ng-template let-value
        pTemplate="selectedItems">
        <div *ngFor="let val of value"
          class="ui-multiselected-item-token ui-corner-all">
          <span>{{val.equipmentType.type}}</span>
        </div>
        <span *ngIf="!value || value.length === 0"
          class="ui-multiselected-empty-token ui-corner-all">Choose</span>
      </ng-template>
      <ng-template let-equipment
        pTemplate="item">
        {{equipment.label}}
      </ng-template>
    </p-multiSelect>
  </div>
  <div class="ui-g-12">
      <h6>Schedulable After The Completion Of:</h6>
      <ng-select 
          class="custom"
          [items]="dependencyOptions"
          [disabled]="!isEditable"
          placeholder="Does this task require another task to be completed first?" 
          name="SchedulableAfterCompletionOf" 
          bindLabel="name"
          [selectOnTab]="true"
          bindValue="id"
          (change)="onSchedulableAfterCompletionOfIdChange()"
          [(ngModel)]="task.schedulableAfterCompletionOfId"
          *ngIf="task">
      </ng-select>
  </div>
  <div class="ui-g-12">
    <h6>Priority</h6>
    <app-priority-and-date-selector
      #prioritySelector
      [(taskOrQuote)]="task" 
      [isEditable]="isEditable">
    </app-priority-and-date-selector>
  </div>
  <div class="ui-g-12">
    <h6>Uploads:</h6>
    <app-blob-manager
      [containerName]="'work-task'" 
      [parentRecordId]="uploadId" 
      [uploadLabel]="'Document/Photo'" 
      [viewOnly]="!canEditUploads">
    </app-blob-manager>
  </div>

  <span *ngIf="!isQuoteTask && !task.id || task.customerSignature">
    <app-legal-acceptance-verbiage [paymentDueOption]="workOrder.paymentDueOption"></app-legal-acceptance-verbiage>
    <br/>
    <app-signature-area
      [signature]="task.customerSignature"
      [isAlwaysDisabled]="task.id"
      [name]="'Customer'"
      (currentSignature)="onUpdatedSignature($event)"
    >
    </app-signature-area>
  </span>
  <div class="ui-g-12 columnPadding">
    <div *ngIf="workTaskForm.invalid && workTaskForm.submitted" class="alert alert-danger" role="alert">
      There are errors on this page. Please correct the errors and resubmit.
    </div>
    <div *ngIf="showSignError" class="alert alert-danger" role="alert">
      Please have the customer sign above.
    </div>
  </div>
  <div class="ui-g-12">
    <div class="ui-g">
      <div class="ui-g-12 columnPadding">
        <button pButton
          type="submit"
          class="ui-button-raised ui-button-success buttonStyleRight"
          (click)="saveTask(workTaskForm)"
          [disabled]="(!isEditable || isSaving) && !canChangeStatus"
          label=" Save">
        </button>
        <button pButton
          type="button"
          class="ui-button-raised ui-button-warning buttonStyleRight"
          (click)="cancelButtonPressed()"
          label="Cancel">
        </button>
        <button pButton
          type="button"
          class="ui-button-raised ui-button-danger buttonStyleRight"
          (click)="deleteButtonPressed()"
          label="Delete"
          [disabled]="isInvoiced"
          *ngIf="isEditable && task.id">
        </button>
<!--         
        <button pButton
          type="button"
          class="ui-button-raised buttonStyle"
          (click)="completeTask()"
          label="Complete"
          *ngIf="canComplete">
        </button> -->
      </div>
    </div>
  </div>
</form>