<div class="ui-g">
  <div class="ui-g-12 ui-md-4" style="padding-left: 0;">
      <ng-select
        class="custom"
        required
        [searchable]="false"
        [items]="priorityTypeOptions"
        placeholder="Select an Option"
        [selectOnTab]="true"
        name="priorityTypeOption"
        bindLabel="name"
        [(ngModel)]="selectedPriorityTypeOption"
        (change)="onPriorityTypeSelection()"
        [disabled]="!isEditable"
        *ngIf="taskOrQuote">
      </ng-select>
  </div>
  <ng-container *ngIf="selectedPriorityTypeOption">
    <ng-container *ngIf="selectedPriorityTypeOption.hasCalculatedPriority">
      <div class="ui-g-8 ui-md-4" *ngIf="shouldShowStartDateForCalculated()">
        <p-calendar
          [style]="{'width': '100%'}"
          name="dueDateStart"
          class="priority-calendar-picker"
          required
          [placeholder]="'Start Date'"
          [(ngModel)]="taskOrQuote.dueDateStart"
          [inputStyle]="{'width':'100%'}"
          [disabled]="!isEditable"
          [maxDate]="taskOrQuote.dueDateEnd"
          oninput="validity.valid||(value='');"
          [showButtonBar]="true"
          (ngModelChange)="this.calculatedDatesChanged()"
          (onChange)="this.calculatedDatesChanged()"
          (change)="this.calculatedDatesChanged()">
        </p-calendar>
      </div>
      <div class="ui-g-8 ui-md-4" *ngIf="shouldShowEndDateForCalculated()">
        <p-calendar
          [style]="{'width': '100%'}"
          appendTo="body"
          [baseZIndex]="20000"
          name="dueDateEnd"
          required
          [placeholder]="'End Date'"
          class="priority-calendar-picker"
          [(ngModel)]="taskOrQuote.dueDateEnd"
          [inputStyle]="{'width':'100%'}"
          [disabled]="!isEditable"
          [minDate]="taskOrQuote.dueDateStart"
          oninput="validity.valid||(value='');"
          [showButtonBar]="true"
          (ngModelChange)="this.calculatedDatesChanged()"
          (onChange)="this.calculatedDatesChanged()"
          (change)="this.calculatedDatesChanged()">
        </p-calendar>
      </div>
      <div class="ui-g-8 ui-md-3" style="margin: auto 0 auto 0" *ngIf="shouldShowWeeksOut()">
        {{selectedPriorityTypeOption.weeksOutStart}} to {{selectedPriorityTypeOption.weeksOutEnd}} Weeks
      </div>
      <div class="ui-g-8 ui-md-3" style="margin: auto 0 auto 0" *ngIf="shouldShowCalculatedWeeksOut()">
        {{signedQuoteWeekRangeCalculation.start}} to {{signedQuoteWeekRangeCalculation.end}} Weeks
      </div>
    </ng-container>
    <ng-container *ngIf="!selectedPriorityTypeOption.hasCalculatedPriority">
      <div class="ui-g-12 ui-md-4">
        <ng-select
          class="custom"
          [required]="true"
          [searchable]="!taskOrQuote.manualPriorityLevel"
          [selectOnTab]="true"
          [items]="allManualPriorityLevels"
          placeholder="Select a Priority Level"
          name="priorityLevelOption"
          bindLabel="status"
          [(ngModel)]="taskOrQuote.manualPriorityLevel"
          (change)="onPriorityLevelSelection($event)"
          [disabled]="!isEditable">
          <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="littleColorBox" [style.background-color]="item.backgroundColor"></div>
            {{item.status}}
          </ng-template>
        </ng-select>
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-calendar
          class=" priority-calendar-picker"
          [style]="{'width': '100%'}"
          appendTo="body"
          [baseZIndex]="20000"
          name="dueDateStart"
          required
          [placeholder]="'Start Date'"
          [(ngModel)]="taskOrQuote.dueDateStart"
          [inputStyle]="{'width':'100%'}"
          [disabled]="!isEditable"
          [maxDate]="taskOrQuote.dueDateEnd"
          oninput="validity.valid||(value='');"
          [showButtonBar]="true"
          (ngModelChange)="this.emitChangedEvent()"
          (onChange)="this.emitChangedEvent()"
          (change)="this.emitChangedEvent()"
          *ngIf="taskOrQuote">
        </p-calendar>
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-calendar
          [style]="{'width': '100%'}"
          appendTo="body"
          [baseZIndex]="20000"
          name="dueDateEnd"
          class="left-this priority-calendar-picker"
          required
          [placeholder]="'End Date'"
          [(ngModel)]="taskOrQuote.dueDateEnd"
          [inputStyle]="{'width':'100%'}"
          [disabled]="!isEditable"
          [minDate]="taskOrQuote.dueDateStart"
          oninput="validity.valid||(value='');"
          [showButtonBar]="true"
          (ngModelChange)="this.emitChangedEvent()"
          (onChange)="this.emitChangedEvent()"
          (change)="this.emitChangedEvent()"
          *ngIf="taskOrQuote">
        </p-calendar>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedPriorityTypeOption.name == calculatedPriorityType.WeekRange || selectedPriorityTypeOption.name == calculatedPriorityType.DayRange">
      <div class="ui-g-8 ui-md-8">
        <input pInputText #rangeStartInput required name="rangeStart" class="inputTextBox" [(ngModel)]="taskOrQuote.manualRangeStart"/>
        &mdash;
        <input pInputText #rangEndInput required name="rangEnd" class="inputTextBox" [(ngModel)]="taskOrQuote.manualRangeEnd"/>
      </div>
    </ng-container>
  </ng-container>
</div>
