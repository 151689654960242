/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/card/card.ngfactory";
import * as i3 from "primeng/components/card/card";
import * as i4 from "@angular/common";
import * as i5 from "./equipment-card.component";
var styles_EquipmentCardComponent = [i0.styles];
var RenderType_EquipmentCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentCardComponent, data: {} });
export { RenderType_EquipmentCardComponent as RenderType_EquipmentCardComponent };
function View_EquipmentCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-times"], ["style", "float:right; color:red;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFromContainer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_EquipmentCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-card", [["class", "equipment-card"]], null, null, null, i2.View_Card_0, i2.RenderType_Card)), i1.ɵdid(1, 49152, null, 2, i3.Card, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { headerFacet: 0 }), i1.ɵqud(335544320, 2, { footerFacet: 0 }), (_l()(), i1.ɵted(4, 1, [" ", " \u00A0"])), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_EquipmentCardComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 1, 0, "span", [["class", "clear"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.deleteable; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.equipment.fullTitle; _ck(_v, 4, 0, currVal_0); }); }
export function View_EquipmentCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-equipment-card", [], null, null, null, View_EquipmentCardComponent_0, RenderType_EquipmentCardComponent)), i1.ɵdid(1, 114688, null, 0, i5.EquipmentCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentCardComponentNgFactory = i1.ɵccf("app-equipment-card", i5.EquipmentCardComponent, View_EquipmentCardComponent_Host_0, { equipment: "equipment", deleteable: "deleteable" }, { deleted: "deleted" }, []);
export { EquipmentCardComponentNgFactory as EquipmentCardComponentNgFactory };
