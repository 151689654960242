/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multi-factor.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./setup/setup.component.ngfactory";
import * as i3 from "./setup/setup.component";
import * as i4 from "../../services/auth-helper.service";
import * as i5 from "../../services_autogenerated/generated_services";
import * as i6 from "@angular/router";
import * as i7 from "./process/process.component.ngfactory";
import * as i8 from "./process/process.component";
import * as i9 from "@angular/common";
import * as i10 from "./multi-factor.component";
var styles_MultiFactorComponent = [i0.styles];
var RenderType_MultiFactorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultiFactorComponent, data: {} });
export { RenderType_MultiFactorComponent as RenderType_MultiFactorComponent };
function View_MultiFactorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-multi-factor-setup", [], null, null, null, i2.View_SetupComponent_0, i2.RenderType_SetupComponent)), i1.ɵdid(2, 114688, null, 0, i3.SetupComponent, [i4.AuthHelperService, i5.EmployeeGenSvc, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_MultiFactorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-multi-factor-process", [], null, null, null, i7.View_ProcessComponent_0, i7.RenderType_ProcessComponent)), i1.ɵdid(2, 114688, null, 0, i8.ProcessComponent, [i6.Router, i5.EmployeeGenSvc, i4.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_MultiFactorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i9.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiFactorComponent_1)), i1.ɵdid(3, 278528, null, 0, i9.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiFactorComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i9.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.action; _ck(_v, 1, 0, currVal_0); var currVal_1 = "setup"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_MultiFactorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multi-factor", [], null, null, null, View_MultiFactorComponent_0, RenderType_MultiFactorComponent)), i1.ɵdid(1, 114688, null, 0, i10.MultiFactorComponent, [i6.ActivatedRoute, i4.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiFactorComponentNgFactory = i1.ɵccf("app-multi-factor", i10.MultiFactorComponent, View_MultiFactorComponent_Host_0, {}, {}, []);
export { MultiFactorComponentNgFactory as MultiFactorComponentNgFactory };
