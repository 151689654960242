import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DisplayBlob } from '../models/displayBlob';
import { API_BASE_URL } from '../services_autogenerated/generated_services';
import { FileHelper } from '../models/fileWithOverwrittenName';

@Injectable({
  providedIn: 'root'
})
export class BlobManagerWrapperService {
  /* Angular only service
     Acts as a wrapper around the BlobManagerGenSvc
  */

 urlBase;

  constructor(private http: HttpClient,
    @Inject(API_BASE_URL) baseUrl) {
    this.urlBase = `${baseUrl}/api/v1/blob`;
   }

  uploadFilesToBlobStorage(containerName: string, recordId: number, files: FileHelper[]): Observable<DisplayBlob[]> {
    const formData: FormData = new FormData();
    files.forEach(file => {
      // tslint:disable-next-line: no-bitwise
      const extension = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
      // tslint:disable-next-line: no-bitwise
      const overwrittenFileNameExtension = file.overwrittenFileName.slice((file.overwrittenFileName.lastIndexOf('.') - 1 >>> 0) + 2);
      if (file.overwrittenFileName && !overwrittenFileNameExtension && extension) {
        file.overwrittenFileName += '.' + extension;
      }

      formData.append(file.name, file, file.overwrittenFileName || file.name);
    });

    // Skip over the BlobGenSvc because it was being dumb about adding the formData right.
    return this.http.post<DisplayBlob[]>(`${this.urlBase}/UploadBlob/${containerName}/${recordId}`, formData);
  }
}
