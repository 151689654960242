<p-tabView *ngIf="customer" [activeIndex]="tabIndex">
  <p-tabPanel *ngIf="customer?.id && hasCustomerDashboardAccess" leftIcon="pi pi-home">
    <app-customer-main-tab [customer]="customer"></app-customer-main-tab>
  </p-tabPanel>
  <p-tabPanel header="Customer Info">
    <app-customer-maintenance [customer]="customer"></app-customer-maintenance>
  </p-tabPanel>
  <p-tabPanel header="Internal Notes" *ngIf="!isCustomer && customer && customer.id">
    <app-customer-comments [customerIdInput]="customer.id" [claimNames]="claimNames"></app-customer-comments>
  </p-tabPanel>
  <!-- <p-tabPanel header="Current" *ngIf="customer && customer.id">
    <app-customer-current-tab [customer]="customer"></app-customer-current-tab>
  </p-tabPanel>
  <p-tabPanel header="History" *ngIf="customer && customer.id">
    <app-customer-history-tab [customer]="customer"></app-customer-history-tab>
  </p-tabPanel> -->
</p-tabView>
