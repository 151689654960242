import { Pipe, PipeTransform } from '@angular/core';
import { Equipment } from '../services_autogenerated/generated_services';

@Pipe({
  name: 'equipmentFilter'
})
export class EquipmentFilterPipe implements PipeTransform {

  transform(equipment: Equipment[], searchTerm: string): Equipment[] {
    if (!equipment) { return []; }
    if (!searchTerm) { return equipment; }

    searchTerm = searchTerm.toLowerCase();

    return equipment.filter(eq => {
      const nameMatches = eq.fullTitle.toLowerCase().includes(searchTerm);

      const typeMatches = eq.equipmentType.type.toLowerCase().includes(searchTerm);

      const feeMatches = eq.baseFee.toString().includes(searchTerm);

      const abbreviationMatches = eq.equipmentType.abbreviation ? eq.equipmentType.abbreviation.toLowerCase().includes(searchTerm) : false;

      return nameMatches || typeMatches || feeMatches || abbreviationMatches;
    });
  }
}
