<div id="datePicker">
  <label for="startDate">Start Date: &nbsp;</label>
  <p-calendar id="startDate" [(ngModel)]="startDate" [showIcon]="true" (ngModelChange)="onDateChange()"></p-calendar>

  <span style="margin-left: 15px;">
    <i class="fa fa-play"  *ngIf="timerPaused" (click)="startTimer()"></i>
    <i class="fa fa-pause" *ngIf="!timerPaused" (click)="pauseTimer()"></i>
    {{timeLeft | time}} until refresh
  </span> 

  <button pButton type="button" label="Notify All Customers" class="ui-button-secondary ui-button-rounded"
    (click)="showDialog(bucketListDayList)" style="float: right;"></button>
</div>

<p-dialog header="Schedule" *ngIf="displayScheduleDialog" [(visible)]="displayScheduleDialog" [modal]="true"
  [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <div class="form-group">
    {{message}}
  </div>
  <div class="form-group">
    <button type="button" type="submit" pButton (click)="scheduleDays()" label="Yes"></button>&nbsp;
    <button type="button" pButton (click)="displayScheduleDialog=false" label="No" class="ui-button-secondary"></button>
  </div>
</p-dialog>

<p-dialog header="Schedule" *ngIf="displayErrorScheduleDialog" [(visible)]="displayErrorScheduleDialog" [modal]="true"
  [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <form [formGroup]="commentForm">
    <div class="form-group">
      {{message}}
      <textarea pInputTextarea formControlName="errorComment" class="form-control" [required]="true"
        style="width: 100%;" autoResize="autoResize"></textarea>
    </div>
    <div class="form-group">
      <button type="button" type="submit" [disabled]="commentForm.invalid" pButton (click)="scheduleDays()"
        label="Yes"></button>&nbsp;
      <button type="button" pButton (click)="displayErrorScheduleDialog=false" label="No"
        class="ui-button-secondary"></button>
    </div>
  </form>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="displayConfirmationDialog" [modal]="true" [responsive]="true"
  [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <div>
    {{confirmMessage}}
  </div>
  <p-footer>
    <button type="button" pButton (click)="displayConfirmationDialog=false" label="Ok"
      class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<div id="fiveDayCalendar" class="p-grid" style="z-index: 900000;">
  <p-card id="dayOne" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[0].bucketList"
    [cdkDropListConnectedTo]="['dayTwo', 'dayThree', 'dayFour', 'dayFive', 'daySix', 'daySeven']"
    (cdkDropListDropped)="drop($event)">
    <p-header class="date-header">
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[0])"></p-calendar>
      </span>
      {{bucketListDayList[0].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[0])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[0]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees 
      *ngIf="indexHasUnavailableEquipmentOrEmployees(0)"
      [bigEquipment]="unavailableEquipment[0]" 
      [bigEmployees]="unavailableEmployees[0]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[0].bucketList"
      cdkDrag 
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[0].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
  <p-card id="dayTwo" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[1].bucketList"
    [cdkDropListConnectedTo]="['dayOne', 'dayThree', 'dayFour', 'dayFive', 'daySix', 'daySeven']"
    (cdkDropListDropped)="drop($event)">
    <p-header>
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[1])"></p-calendar>
      </span>
      {{bucketListDayList[1].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[1])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[1]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees
      *ngIf="indexHasUnavailableEquipmentOrEmployees(1)"
      [bigEquipment]="unavailableEquipment[1]" 
      [bigEmployees]="unavailableEmployees[1]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[1].bucketList" cdkDrag 
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[1].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
  <p-card id="dayThree" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[2].bucketList"
    [cdkDropListConnectedTo]="['dayOne', 'dayTwo', 'dayFour', 'dayFive', 'daySix', 'daySeven']"
    (cdkDropListDropped)="drop($event)">
    <p-header>
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[2])"></p-calendar>
      </span>
      {{bucketListDayList[2].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[2])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[2]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees 
      *ngIf="indexHasUnavailableEquipmentOrEmployees(2)"
      [bigEquipment]="unavailableEquipment[2]" 
      [bigEmployees]="unavailableEmployees[2]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[2].bucketList" cdkDrag 
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[2].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
  <p-card id="dayFour" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[3].bucketList"
    [cdkDropListConnectedTo]="['dayOne', 'dayTwo', 'dayThree', 'dayFive', 'daySix', 'daySeven']"
    (cdkDropListDropped)="drop($event)">
    <p-header>
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[3])"></p-calendar>
      </span>
      {{bucketListDayList[3].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[3])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[3]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees 
      *ngIf="indexHasUnavailableEquipmentOrEmployees(3)"
      [bigEquipment]="unavailableEquipment[3]" 
      [bigEmployees]="unavailableEmployees[3]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[3].bucketList" cdkDrag 
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[3].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
  <p-card id="dayFive" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[4].bucketList"
    [cdkDropListConnectedTo]="['dayOne', 'dayTwo', 'dayThree', 'dayFour', 'daySix', 'daySeven']"
    (cdkDropListDropped)="drop($event)">
    <p-header>
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[4])"></p-calendar>
      </span>
      {{bucketListDayList[4].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[4])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[4]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees 
      *ngIf="indexHasUnavailableEquipmentOrEmployees(4)"
      [bigEquipment]="unavailableEquipment[4]" 
      [bigEmployees]="unavailableEmployees[4]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[4].bucketList" cdkDrag
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[4].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
  <p-card id="daySix" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[5].bucketList"
    [cdkDropListConnectedTo]="['dayOne', 'dayTwo', 'dayThree', 'dayFour', 'dayFive', 'daySeven']"
    (cdkDropListDropped)="drop($event)">
    <p-header>
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[5])"></p-calendar>
      </span>
      {{bucketListDayList[5].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[5])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[5]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees 
      *ngIf="indexHasUnavailableEquipmentOrEmployees(5)"
      [bigEquipment]="unavailableEquipment[5]" 
      [bigEmployees]="unavailableEmployees[5]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[5].bucketList" cdkDrag
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[5].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
  <p-card id="daySeven" [style]="{height: '98%'}" class="p-col" cdkDropList
    [cdkDropListData]="bucketListDayList[6].bucketList"
    [cdkDropListConnectedTo]="['dayOne', 'dayTwo', 'dayThree', 'dayFour', 'dayFive', 'daySix']"
    (cdkDropListDropped)="drop($event)">
    <p-header>
      <span style="float: left;">
        <p-calendar [minDate]="today" class="only-icon rescheule-calendar special" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[6])"></p-calendar>
      </span>
      {{bucketListDayList[6].day | date: 'EEE M/d' | uppercase}}
      <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
        (click)="addNewCrew(bucketListDayList[6])"></i>
      <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
        (click)="showDialog([bucketListDayList[6]])"></button>
    </p-header>
    <app-unavailable-equipment-and-employees 
      *ngIf="indexHasUnavailableEquipmentOrEmployees(6)"
      [bigEquipment]="unavailableEquipment[6]" 
      [bigEmployees]="unavailableEmployees[6]"
      [type]="isWellness ? 'wellness' : 'work'">
    </app-unavailable-equipment-and-employees>
    <div *ngFor="let item of bucketListDayList[6].bucketList" cdkDrag
      [cdkDragDisabled]="disableDrag(item)">
      <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
        (needsValidated)="validateDay(bucketListDayList[6].day)" (dragStartEvent)="woDragStart($event, item.id)"
        (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
        (taskDragEndEvent)="taskDraggedEnded()"
        [isWellness]="isWellness"
        [isWork]="isWork"></app-scheduled-item-bucket-card>
    </div>
  </p-card>
</div>

<!-- <div id="datePicker">
  <label for="startDate">Start Date: &nbsp;</label>
  <p-calendar id="startDate" [(ngModel)]="startDate" [showIcon]="true" (ngModelChange)="onDateChange()"></p-calendar>

  <button pButton type="button" label="Schedule View" class="ui-button-secondary ui-button-rounded"
    (click)="showDialog(bucketListDayList)" style="float: right;"></button>
</div>

<p-dialog header="Schedule Days" *ngIf="displayScheduleDialog" [(visible)]="displayScheduleDialog" [modal]="true"
  [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <div>
    {{message}}
  </div>
  <div class="form-group">
    <button type="button" type="submit" pButton (click)="scheduleDays()" label="Yes"></button>
    <button type="button" pButton (click)="displayScheduleDialog=false" label="No" class="ui-button-secondary"></button>
  </div>
</p-dialog>

<p-dialog header="Schedule Days" *ngIf="displayErrorScheduleDialog" [(visible)]="displayErrorScheduleDialog"
  [modal]="true" [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <form [formGroup]="commentForm">
    <div class="form-group">
      {{message}}
      <textarea pInputTextarea formControlName="errorComment" class="form-control" [required]="true"
        style="width: 100%;" autoResize="autoResize"></textarea>
    </div>
    <div class="form-group">
      <button type="button" type="submit" [disabled]="commentForm.invalid" pButton (click)="scheduleDays()"
        label="Yes"></button>
      <button type="button" pButton (click)="displayErrorScheduleDialog=false" label="No"
        class="ui-button-secondary"></button>
    </div>
  </form>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="displayConfirmationDialog" [modal]="true" [responsive]="true"
  [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <div>
    {{confirmMessage}}
  </div>
  <p-footer>
    <button type="button" pButton (click)="displayConfirmationDialog=false" label="Ok"
      class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<div id="fiveDayCalendar" class="p-grid">
  <div *ngFor="let bucketListDay of bucketListDayList; let i=index">
    <p-card id="{{ writtenIds[i] }}" [style]="{height: '98%'}" class="p-col" cdkDropList
      [cdkDropListData]="bucketListDay.bucketList" [cdkDropListConnectedTo]="getOneThroughSevenExcept(i)"
      (cdkDropListDropped)="drop($event)">
      <p-header class="date-header">
        {{bucketListDay.day | date: 'EEE M/d' | uppercase}}
        <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
          (click)="addNewCrew(bucketListDay)"></i>
        <button class="scheduleButton ui-button-info" type="button" pButton label="Schedule Day"
          (click)="showDialog([bucketListDay])"></button>
      </p-header>
      <app-unavailable-equipment-and-employees 
        *ngIf="indexHasUnavailableEquipmentOrEmployees(i)"
        [bigEquipment]="unavailableEquipment[i]" 
        [bigEmployees]="unavailableEmployees[i]">
            [type]="isWellness ? 'wellness' : 'work'"
      </app-unavailable-equipment-and-employees>
      <div *ngFor="let item of bucketListDay.bucketList" cdkDrag [cdkDragDisabled]="item.disabled">
        <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
          (dragStartEvent)="woDragStart($event, item.id)" (dragEndEvent)="woDragEnd()"></app-scheduled-item-bucket-card>
      </div>
    </p-card>
  </div>
</div> -->