<form id="skillForm" #skillForm="ngForm">
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-12">
      <h4>Skills/Certification Information</h4>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-7 ui-lg-6">
      <h6>Skill/Certification Name</h6>
      <input pInputText maxlength="255" class="inputTextBox" name="name" [(ngModel)]="skill.name" *ngIf="skill"
        required />
    </div>
    <div class="ui-g-2 ui-lg-2">
      <h6>Short Name</h6>
      <input pInputText maxlength="25" class="inputTextBox" name="shortName" [(ngModel)]="skill.shortName" *ngIf="skill"
        required />
    </div>
    <div class="ui-g-2 ui-lg-2">
      <h6>Years Valid</h6>
      <input pInputText class="inputTextBox" pKeyFilter="pint" name="length" [(ngModel)]="skill.length" *ngIf="skill"
        required />
    </div>
    <!-- <div class="ui-g-1" style="margin-left: 40px;">
      <h6>Active</h6>
      <p-checkbox binary="true" name="active" [(ngModel)]="skill.active" *ngIf="skill"></p-checkbox>
    </div> -->
  </div>
  <div class="ui-g">
    <div class="ui-g-12 columnPadding">
      <div *ngIf="skillForm.invalid && skillForm.submitted" class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 buttonStyle">
      <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle" [disabled]="saving" (click)="save(skillForm)"
        label="Save">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" (click)="cancel()"
        label="Cancel">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-danger buttonStyle" (click)="delete()"
        [disabled]="disableDelete" label="Delete">
      </button>
    </div>
  </div>
</form>