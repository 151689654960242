import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PriorityTypeShortcut, PriorityTypeShortcutGenSvc } from 'src/app/services_autogenerated/generated_services';
function rangeValidator(field) {
    return function (control) {
        var group = control.parent;
        var fieldToCompare = group.get(field);
        var range = Number(fieldToCompare.value) > Number(control.value);
        return range ? { 'range': { value: control.value } } : null;
    };
}
var BacklogRowComponent = /** @class */ (function () {
    function BacklogRowComponent(priorityTypeShortcutService, formBuilder, messageService) {
        this.priorityTypeShortcutService = priorityTypeShortcutService;
        this.formBuilder = formBuilder;
        this.messageService = messageService;
        this.backlogForm = this.formBuilder.group({
            start: [0, Validators.required],
            end: [0, Validators.required]
        });
    }
    BacklogRowComponent.prototype.ngOnInit = function () {
        this.backlogForm.get('end').setValidators([Validators.required, rangeValidator('start')]);
        this.backlogForm.controls['start'].setValue(this.backlog.weeksOutStart);
        this.backlogForm.controls['end'].setValue(this.backlog.weeksOutEnd);
    };
    Object.defineProperty(BacklogRowComponent.prototype, "start", {
        get: function () { return this.backlogForm.get('start'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BacklogRowComponent.prototype, "endC", {
        get: function () { return this.backlogForm.get('end'); },
        enumerable: true,
        configurable: true
    });
    BacklogRowComponent.prototype.save = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (key) {
            formGroup.controls[key].markAsDirty();
            formGroup.controls[key].markAsTouched();
        });
        if (formGroup.valid) {
            this.priorityTypeShortcutService.updateBackLog(this.backlog.id, this.backlogForm.get('start').value, this.backlogForm.get('end').value).subscribe(function () {
                _this.messageService.add({
                    severity: 'success',
                    summary: 'Saved Successfully',
                    detail: "The values for " + _this.backlog.name + " have been updated."
                });
            }, function (error) {
                if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error Updating Values',
                        detail: JSON.parse(error.response).ExceptionMessage,
                        sticky: true
                    });
                }
                else {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Save Failed',
                        detail: 'The backlog values were not updated, refresh the page and try again.'
                    });
                }
            });
        }
    };
    return BacklogRowComponent;
}());
export { BacklogRowComponent };
