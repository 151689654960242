/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multiday-jobs-panel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../multiday-job-card/multiday-job-card.component.ngfactory";
import * as i3 from "../multiday-job-card/multiday-job-card.component";
import * as i4 from "../../services_autogenerated/generated_services";
import * as i5 from "primeng/components/common/messageservice";
import * as i6 from "../../services/work-order-helper.service";
import * as i7 from "@angular/router";
import * as i8 from "../../services/customer.service";
import * as i9 from "../../services/priority.service";
import * as i10 from "../work-order-filter-bar/work-order-filter-bar.component.ngfactory";
import * as i11 from "../work-order-filter-bar/work-order-filter-bar.component";
import * as i12 from "../../services/drag-and-drop.service";
import * as i13 from "../../pipes/work-order-filter.pipe";
import * as i14 from "../../../../node_modules/primeng/components/panel/panel.ngfactory";
import * as i15 from "primeng/components/panel/panel";
import * as i16 from "@angular/common";
import * as i17 from "../../services/location-color.service";
import * as i18 from "./multiday-jobs-panel.component";
var styles_MultidayJobsPanelComponent = [i0.styles];
var RenderType_MultidayJobsPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultidayJobsPanelComponent, data: {} });
export { RenderType_MultidayJobsPanelComponent as RenderType_MultidayJobsPanelComponent };
function View_MultidayJobsPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multiday-job-card", [["class", "cdk-drag p-lg-4 p-md-12"]], null, null, null, i2.View_MultidayJobCardComponent_0, i2.RenderType_MultidayJobCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.MultidayJobCardComponent, [i4.BigDayBucketGenSvc, i4.BigDayBucketSubrangeGenSvc, i4.BigDayEmployeeGenSvc, i4.BigDayEquipmentGenSvc, i5.MessageService, i6.WorkOrderHelperService, i7.Router, i8.CustomerService, i9.PriorityService], { bigDayBucket: [0, "bigDayBucket"], type: [1, "type"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "wellness"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MultidayJobsPanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { filterBarComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-work-order-filter-bar", [], null, [[null, "OnWoFilteringFinished"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("OnWoFilteringFinished" === en)) {
        var pd_0 = (_co.OnWoFilteringFinished($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_WorkOrderFilterBarComponent_0, i10.RenderType_WorkOrderFilterBarComponent)), i1.ɵdid(2, 638976, [[1, 4]], 0, i11.WorkOrderFilterBarComponent, [i4.PriorityLevelGenSvc, i12.DragAndDropService, i13.WorkOrderFilterPipe], { isDayView: [0, "isDayView"], typeOfWorkOrders: [1, "typeOfWorkOrders"], allWorkOrders: [2, "allWorkOrders"], rms: [3, "rms"], useSearchDelay: [4, "useSearchDelay"] }, { OnWoFilteringFinished: "OnWoFilteringFinished" }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "p-panel", [["class", "innerHeader"], ["header", ""]], null, null, null, i14.View_Panel_0, i14.RenderType_Panel)), i1.ɵdid(4, 49152, null, 1, i15.Panel, [i1.ElementRef], { header: [0, "header"] }, null), i1.ɵqud(335544320, 2, { footerFacet: 0 }), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultidayJobsPanelComponent_1)), i1.ɵdid(8, 278528, null, 0, i16.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedView == "day"); var currVal_1 = "wellness"; var currVal_2 = _co.allWorkOrders; var currVal_3 = _co.rms; var currVal_4 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = ""; _ck(_v, 4, 0, currVal_5); var currVal_6 = _co.filteredBigDayBuckets; _ck(_v, 8, 0, currVal_6); }, null); }
export function View_MultidayJobsPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-multiday-jobs-panel", [], null, null, null, View_MultidayJobsPanelComponent_0, RenderType_MultidayJobsPanelComponent)), i1.ɵprd(4608, null, i13.WorkOrderFilterPipe, i13.WorkOrderFilterPipe, [i17.LocationColorService, i8.CustomerService, i16.DatePipe]), i1.ɵdid(2, 114688, null, 0, i18.MultidayJobsPanelComponent, [i4.BigDayBucketGenSvc, i6.WorkOrderHelperService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var MultidayJobsPanelComponentNgFactory = i1.ɵccf("app-multiday-jobs-panel", i18.MultidayJobsPanelComponent, View_MultidayJobsPanelComponent_Host_0, {}, {}, []);
export { MultidayJobsPanelComponentNgFactory as MultidayJobsPanelComponentNgFactory };
