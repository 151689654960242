import { Component, OnInit, ViewChild } from '@angular/core';
import {
  EmployeeGenSvc, Employee, PesticideApplicationRecord, ChemicalGenSvc, ChemicalApplicationMethodGenSvc,
  ChemicalApplicationLocationGenSvc,
  Chemical,
  ChemicalApplicationMethod,
  ChemicalApplicationLocation,
  PesticideApplicationRecordGenSvc,
  WellnessTask,
  WellnessTaskGenSvc,
  WellnessWorkOrder,
  WellnessWorkOrderGenSvc,
  WellnessTaskCompletionStatus,
  BasicWorkOrderViewModel
} from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
import { BasicWorkOrderDropdownComponent } from '../basic-work-order-dropdown/basic-work-order-dropdown.component';

@Component({
  selector: 'app-pesticide-record-maintenance',
  templateUrl: './pesticide-record-maintenance.component.html',
  styleUrls: ['./pesticide-record-maintenance.component.css']
})
export class PesticideRecordMaintenanceComponent implements OnInit {
  record: PesticideApplicationRecord;
  employees: Employee[];
  chemicals: Chemical[];
  chemicalAppMethods: ChemicalApplicationMethod[];
  chemicalAppLocations: ChemicalApplicationLocation[];
  wellnessWorkOrders: WellnessWorkOrder[];

  startTimeString: string;
  endTimeString: string;

  @ViewChild(BasicWorkOrderDropdownComponent) workOrderDropdown: BasicWorkOrderDropdownComponent;

  // After ngOnInit if recordId exists then a record is being edited
  recordId: number;
  // After ngOnInit if wellnessWorkOrderId exists then the page was redirected from a work order
  wellnessWorkOrderId: number;
  wellnessTaskId: number;

  disableWorkOrderSelect = false;

  disableSave: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeGenSvc,
    private chemicalService: ChemicalGenSvc,
    private chemicalApplicationMethodService: ChemicalApplicationMethodGenSvc,
    private chemicalApplicationLocationService: ChemicalApplicationLocationGenSvc,
    private wellnessTaskService: WellnessTaskGenSvc,
    private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
    private pesticideApplicationRecordService: PesticideApplicationRecordGenSvc,
    public customCustomerService: CustomerService,
    private messageService: MessageWrapperService
  ) { }

  ngOnInit() {
    let idParam = this.route.snapshot.paramMap.get('id');
    if (!idParam) {
      this.record = new PesticideApplicationRecord();
      // New record
      this.record.dateApplied = new Date();
    } else if (idParam.slice(0, 1) === 'w') {
      // New record from work order
      this.record = new PesticideApplicationRecord();
      
      this.disableWorkOrderSelect = true;
      this.record.dateApplied = new Date();

      idParam = idParam.slice(2); // remove wo
      const index = idParam.indexOf('w');
      this.wellnessWorkOrderId = +idParam.slice(0, index);
      this.wellnessTaskId = +idParam.slice(index + 2);
      this.wellnessWorkOrderService.get(this.wellnessWorkOrderId).subscribe((wo) => {
        this.record.quoteNumber = wo.quoteNumber;
        this.record.wellnessWorkOrderId = wo.id;
        this.workOrderDropdown.setWorkOrderFromNew(this.record.quoteNumber);
      });
    } else {
      // Edit Record
      this.recordId = +idParam;
      this.pesticideApplicationRecordService.get(this.recordId).subscribe((record) => {
        this.record = record;
        const chemical = this.record.chemical;
        (chemical as any).dropdown = chemical.displayName + '  ' + chemical.mixRate;
        this.setTimeStringsFromDate();
        this.disableWorkOrderSelect = true;
      });
    }

    this.employeeService.getAll().subscribe((emps) => {
      this.employees = emps;
    });

    this.chemicalService.getAll().subscribe((chemicals) => {
      this.chemicals = chemicals;
      this.chemicals.forEach((chemical) => {
        (chemical as any).dropdown = chemical.displayName + '  ' + chemical.mixRate;
      });
    });

    this.chemicalApplicationMethodService.getAll().subscribe((chemicalAppMethods) => {
      this.chemicalAppMethods = chemicalAppMethods;
    });

    this.chemicalApplicationLocationService.getAll().subscribe((chemicalAppLocations) => {
      this.chemicalAppLocations = chemicalAppLocations;
    });
  }

  onApplicatorSelect(employee: Employee) {
    this.record.applicatorId = employee.id;
  }

  onCertifiedApplicatorSelect(employee: Employee) {
    this.record.certifiedApplicatorId = employee.id;
  }

  onChemicalSelect(chemical: Chemical) {
    this.record.chemicalId = chemical.id;
  }

  onChemicalAppMethodSelect(chemicalApplicationMethod: ChemicalApplicationMethod) {
    this.record.chemicalApplicationMethodId = chemicalApplicationMethod.id;
  }

  onChemicalAppLocationSelect(chemicalApplicationLocation: ChemicalApplicationLocation) {
    this.record.chemicalApplicationLocationId = chemicalApplicationLocation.id;
  }

  onWorkOrderSelect(wo: WellnessWorkOrder) {
    this.record.wellnessWorkOrderId = wo.id;
  }

  saveEditedRecord(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    this.compareStartAndEndTime(ngForm);

    if (ngForm.valid) {
      this.pesticideApplicationRecordService.update(this.record, this.record.id.toString())
        .subscribe((record) => {
          this.goToList();
        }, error => {
          this.messageService.addErrorMessage('Could not save the record, please check that all fields are valid and retry.', error);
        });
    }
  }

  saveNewRecord(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    this.compareStartAndEndTime(ngForm);

    if (ngForm.valid) {
      this.pesticideApplicationRecordService.add(this.record)
        .subscribe(() => {
          this.goToList();
        }, error => {
          this.messageService.addErrorMessage('Could not save the record, please check that all fields are valid and retry.', error);
        });
    }
  }

  saveRecordAndReturnToWorkOrder(ngForm: NgForm) {
    this.disableSave = true;
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    this.compareStartAndEndTime(ngForm);

    if (ngForm.valid) {
      this.disableSave = true;
      this.pesticideApplicationRecordService.add(this.record)
        .subscribe(record => {
          this.messageService.addSuccessMessage('The pesticide application record was added successfully!');
          this.completeWellnessTask();
        }, error => {
          this.disableSave = false;
          this.messageService.addErrorMessage('Could not save the record, please check that all fields are valid and retry.', error);
        });
    } else {
      this.disableSave = false;
    }
  }

  goToList() {
    this.router.navigate(['pesticideApplicationRecordList']);
  }

  goToWorkOrder() {
    this.disableSave = false;
    this.router.navigateByUrl('wellnessWorkOrderMaintenance/' + this.wellnessWorkOrderId);
  }

  delete() {
    if (confirm('Delete this record?')) {
      this.pesticideApplicationRecordService.delete(this.recordId).subscribe(() => {
        this.goToList();
      });
    }
  }

  completeWellnessTask() {
    const wellnessTask = new WellnessTask();
    wellnessTask.id = this.wellnessTaskId;
    wellnessTask.completionStatus = WellnessTaskCompletionStatus.Completed;
    if (!wellnessTask.completedDate) {
      wellnessTask.completedDate = new Date();
    }

    this.wellnessTaskService.updateCompletionStatus(wellnessTask).subscribe(() => {
      this.goToWorkOrder();
    }, error => {
      this.messageService.addErrorMessage('Could not mark the task complete but the pesticide record saved successfully, please try to complete the task without the pesticide record.', error, true);
      this.goToWorkOrder();
    });
  }

  compareStartAndEndTime(ngForm: NgForm) {
    if (ngForm.valid) {
      this.setDateFromTimeStrings();
      if (this.record.startTime.getTime() > this.record.endTime.getTime()) {
        ngForm.controls['endTime'].setErrors({ 'Start time later than end time.': true });
      }
    }
  }

  setTimeStringsFromDate() {
    const startHour = +this.record.startTime.getHours();
    if (startHour < 10) {
      this.startTimeString = '0' + startHour + ':';
    } else {
      this.startTimeString = startHour + ':';
    }
    const startMinute = +this.record.startTime.getMinutes();
    if (startMinute < 10) {
      this.startTimeString = this.startTimeString + '0' + startMinute;
    } else {
      this.startTimeString = this.startTimeString + startMinute;
    }

    const endHour = +this.record.endTime.getHours();
    if (endHour < 10) {
      this.endTimeString = '0' + endHour + ':';
    } else {
      this.endTimeString = endHour + ':';
    }
    const endMinute = +this.record.endTime.getMinutes();
    if (endMinute < 10) {
      this.endTimeString = this.endTimeString + '0' + endMinute;
    } else {
      this.endTimeString = this.endTimeString + endMinute;
    }
  }

  setDateFromTimeStrings() {
    this.record.startTime = new Date(this.record.dateApplied);
    this.record.startTime.setHours(+this.startTimeString.substring(0, 2), +this.startTimeString.substring(3));

    this.record.endTime = new Date(this.record.dateApplied);
    this.record.endTime.setHours(+this.endTimeString.substring(0, 2), +this.endTimeString.substring(3));
  }

  setWorkOrder(workOrder: BasicWorkOrderViewModel) {
    console.log(workOrder)
    if (workOrder) {
      switch (workOrder.type) {
        case ('Wellness'): {
          this.record.wellnessWorkOrderId = workOrder.id;
          break;
        }
        case ('Work'): {
          this.record.workWorkOrderId = workOrder.id;
          break;
        }
        default: {
          // um
          break;
        }
      }
    } else {
      // also um
    }
  }
}
