import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../services_autogenerated/generated_services';

@Pipe({
  name: 'peopleFilter'
})
export class PeopleFilterPipe implements PipeTransform {

  transform(people: Employee[], searchTerm: string): Employee[] {
    if (!people) { return []; }
    if (!searchTerm) { return people; }

    searchTerm = searchTerm.toLowerCase();
    return people.filter(person => {
      const nameMatches = person.fullName.toLowerCase().includes(searchTerm);

      const roleMatches = person.employeeRoles.some(er => er.role.name.toLowerCase().includes(searchTerm));

      const skillMatches = person.employeeSkills.some(es => es.skill.name.toLowerCase().includes(searchTerm) || es.skill.shortName.toLowerCase().includes(searchTerm));

      return nameMatches || roleMatches || skillMatches;
    });
  }

}
