import { OnInit, ViewChildren, QueryList, Output, EventEmitter, Input, Inject, OnDestroy } from '@angular/core';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
    Crew,
    WellnessWorkOrder,
    BigDayBucketSubrangeGenSvc,
    BigDayEquipment,
    BigDayEmployee,
    BigDayBucketSubrange,
    WellnessTask,
    ScheduledBucketEmployee,
    ScheduledBucketEquipment,
    WorkTask,
    WellnessTaskCompletionStatus,
    WorkTaskCompletionStatus,
    NotificationsGenSvc,
    WellnessWorkOrderGenSvc,
    WorkWorkOrderGenSvc,
    WellnessWorkOrderCompletionStatus,
    WorkWorkOrderCompletionStatus,
    WorkWorkOrder,
    ScheduledBucketDTO} from '../../services_autogenerated/generated_services';
import * as moment from 'moment';
import { ScheduledBucketGenSvc } from '../../services_autogenerated/generated_services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ScheduledItemBucketCardComponent } from '../scheduled-item-bucket-card/scheduled-item-bucket-card.component';
import { ScheduleComment } from '../../services_autogenerated/generated_services';
import { ScheduleCommentGenSvc } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { BucketListDay } from '../../models/bucketListDay';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CrewsDateRangeService } from 'src/app/services/crews-date-range.service';
import { BucketValidationHelperService } from 'src/app/services/bucket-validation-helper.service';
// tslint:disable-next-line: max-line-length
import { UnavailableEquipmentAndEmployeesComponent } from '../unavailable-equipment-and-employees/unavailable-equipment-and-employees.component';
import { Guid } from '../../models/guid';
import { IDroppable } from '../../models/IDroppable';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';

export abstract class BaseCalendarComponent implements OnInit, IDroppable, OnDestroy {
    abstract howManyDays: number;

    @Input() isWellness: boolean;
    @Input() isWork: boolean;

    @Output() woDragged = new EventEmitter<{workOrder: WellnessWorkOrder, bucketId: number}>();
    @Output() woDraggedEnd = new EventEmitter();

    @Output() taskDragged = new EventEmitter<WellnessTask | WorkTask>();
    @Output() taskDraggedEnd = new EventEmitter<WellnessTask | WorkTask>();

    @ViewChildren(ScheduledItemBucketCardComponent) bucketCardComponents: QueryList<ScheduledItemBucketCardComponent>;
    @ViewChildren(UnavailableEquipmentAndEmployeesComponent) unavailableItems: QueryList<UnavailableEquipmentAndEmployeesComponent>;

    startDate: Date;
    displayScheduleDialog = false;
    displayConfirmationDialog = false;
    displayErrorScheduleDialog = false;
    message = 'Schedule and send notifications to the customers?';
    confirmMessage = 'Notifications were sent to the customers.';
    dayScheduledField: moment.Moment;
    bucketListDayList: BucketListDay[];
    // At the moment there are two instances of each scheduled bucket that is used.
    // The instance inside of this.bucketListDayList is used to get the actual scheduled bucket values
    // and this.dayBucketIndexRecords is used exclusively to determine the ordering and date of the scheduled buckets
    // TODO refactor
    dayBucketIndexRecords: ScheduledBucketDTO[] = [];
    otherViewScheduledBuckets: ScheduledBucketDTO[] = [];
    commentForm: FormGroup;
    initializingNewCrew: boolean;

    bucketListDaysToBeScheduled: BucketListDay[];
    multidayCrewsToBeScheduled: BigDayBucketSubrange[];

    unavailableEquipment: BigDayEquipment[][];
    unavailableEmployees: BigDayEmployee[][];

    subrangesForView: BigDayBucketSubrange[] = [];

    rescheduleDate: Date;
    today: Date = new Date();

    timerPaused: boolean = true;
    defaultTime: number = 300;
    timeLeft: number = this.defaultTime;
    intervals: any[] = [];

    constructor(
        protected scheduledBucketService: ScheduledBucketGenSvc,
        private fb: FormBuilder,
        private scheduleCommentService: ScheduleCommentGenSvc,
        private dragAndDropService: DragAndDropService,
        private messageMainService: MessageService,
        private crewDateRangeService: CrewsDateRangeService,
        private bigDayBucketSubrangeService: BigDayBucketSubrangeGenSvc,
        private bucketValidationHelper: BucketValidationHelperService,
        private scheduledItemBucketService: ScheduledBucketGenSvc,
        private authService: AuthHelperService,
        private emailService: NotificationsGenSvc,
        private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
        private workWorkOrderService: WorkWorkOrderGenSvc,
        private confirmationService: ConfirmationService,
        private messageService: MessageWrapperService
    ) { }

    ngOnDestroy(): void {
        this.intervals.forEach(interval => {
            clearInterval(interval);
        });
    }

    ngOnInit() {
        this.startDate = new Date();
        this.initializeDates(this.howManyDays);
        this.getAllScheduledItems();
        this.startTimer();

        this.commentForm = this.fb.group({
            errorComment: ['']
        });

        this.dragAndDropService.getBucketUpdater().subscribe(bucket => this.onBucketUpdate(bucket));
    }

    onBucketUpdate(bucket: ScheduledBucketDTO) {
        if (bucket && this.bucketCardComponents) {
            const match = this.bucketCardComponents.find(x => x.bucket.id === bucket.id);
            if (match) {
                match.onBucketUpdate();
            }
        }
    }

    // Set the dates
    initializeDates(numberOfDays: number) {
        this.bucketListDayList = [];
        for (let index = 0; index < numberOfDays; index++) {
            const start = moment(this.startDate);
            this.bucketListDayList.push({ bucketList: [], day: start.add(index, 'days') });
        }
        this.dragAndDropService.setBucketListDayList(this.bucketListDayList);
        this.crewDateRangeService.setCrewsInDateRange(this.bucketListDayList[0].day,
            this.bucketListDayList[0].day.clone().add(numberOfDays, 'd'));
    }

    onDateChange() {
        this.initializeDates(this.howManyDays);
        this.getAllScheduledItems();
    }

    validateDay(day: string) {
        const bucketsOnThatDay: ScheduledItemBucketCardComponent[] =
            this.bucketCardComponents
                .filter(bcc => {
                    return moment(bcc.bucket.date).isSame(moment(day), 'day');
                });

        const rangesOnThatDay = this.subrangesForView.filter(sfv => {
            return moment(day).isBetween(sfv.fromDate, sfv.toDate, 'd', '[]');
        });

        const otherViewScheduledBucketsOnThatDay: ScheduledBucketDTO[] = this.otherViewScheduledBuckets.filter(sbForView => {
            return moment(day).isSame(sbForView.date, 'day');
        });

        bucketsOnThatDay.forEach(bucketComponent => {
            bucketComponent.bucketErrors = [];
            // Basic error checking, for foreman, having equipment, WO skills/equipment needed, equipment OOS, over total hours
            bucketComponent.bucketErrors = this.bucketValidationHelper.validateBucket(bucketComponent.bucket);

            const otherBucketComponents = bucketsOnThatDay.filter(bucket => bucket.bucket.id !== bucketComponent.bucket.id);

            // Add an error if an employee is on another bucket that day
            bucketComponent.bucket.scheduledBucketEmployees.forEach(sbe => {
                const bucketsWithSameEmployees = otherBucketComponents.filter(bucket =>
                    bucket.bucket.scheduledBucketEmployees.some(innerSbe =>
                        innerSbe.employeeId === sbe.employeeId));

                const rangeWithSameEmployees = rangesOnThatDay.filter(range =>
                    range.bigDayEmployees.some(bde =>
                        bde.employeeId === sbe.employeeId));

                const otherViewBucketWithSameEmployees = otherViewScheduledBucketsOnThatDay.filter(bucket =>
                    bucket.scheduledBucketEmployees.some(innerSbe =>
                        innerSbe.employeeId === sbe.employeeId));

                if (bucketsWithSameEmployees.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.employee.fullName + ' is on another crew on this day');
                }

                if (rangeWithSameEmployees.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.employee.fullName + ' is on a multi-day crew on this day');
                }

                if (otherViewBucketWithSameEmployees.length > 0) {
                    bucketComponent.bucketErrors.push(
                        `${sbe.employee.fullName} is already on a ${this.isWellness ? 'Tree Work' : 'Tree Wellness'} crew on this day`
                    );
                }
            });

            // Add an error if a piece of equipment is on another bucket that day
            bucketComponent.bucket.scheduledBucketEquipment.forEach(sbe => {
                const bucketsWithSameEquipment = otherBucketComponents.filter(bucket =>
                    bucket.bucket.scheduledBucketEquipment.some(innerSbe =>
                        innerSbe.equipmentId === sbe.equipmentId));

                const rangeWithSameEquipment = rangesOnThatDay.filter(range =>
                    range.bigDayEquipment.some(bde =>
                        bde.equipmentId === sbe.equipmentId));

                const otherViewBucketWithSameEquipment = otherViewScheduledBucketsOnThatDay.filter(bucket =>
                    bucket.scheduledBucketEquipment.some(innerSbe =>
                        innerSbe.equipmentId === sbe.equipmentId));

                if (bucketsWithSameEquipment.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.equipment.number + ' is on another crew on this day');
                }

                if (rangeWithSameEquipment.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.equipment.number + ' is on a mulit-day crew on this day');
                }

                if (otherViewBucketWithSameEquipment.length > 0) {
                    bucketComponent.bucketErrors.push(
                        `${sbe.equipment.fullTitle} is already on a ${this.isWellness ? 'Tree Work' : 'Tree Wellness'} crew on this day`
                    );
                }
            });
        });
    }

    showDialog(bucketListDays: BucketListDay[]) {
        let errors = 0;
        // This gets the bucket card(s) for the day(s) passed in.
        // Either 1 day or all the days on that view are passed in.
        this.bucketCardComponents.filter(bucketCard => {
            return bucketListDays.map(bld => bld.day).some(day => day.isSame(bucketCard.bucket.date, 'days'));
        }).forEach(bucket => {
            errors += bucket.bucketErrors.length;
        });

        if (errors > 0) {
            this.message = errors + ' error(s) were detected, would you like to schedule and send notifications to the customer anyway?';
            // This is for the scheduled comment ScheduledDate field.
            // If 1 day is passed it, that is the day it uses, if multiple days are passed in it just uses the first
            // NOTE: If they want to change the scheduled day when scheduling multiple days, do that here
            this.dayScheduledField = bucketListDays[0].day;

            this.displayErrorScheduleDialog = true;
        } else {
            this.message = 'Schedule and send notifications to the customers?';
            this.displayScheduleDialog = true;
        }

        this.bucketListDaysToBeScheduled = bucketListDays;
        // subrangesForView contains both work and wellness because the validation needs to check if the employee/equipment is on ANY multi-day subrange for that day
        // but for the notifications, we only want to send it out based on the type
        const subrangesForType = this.subrangesForView.filter(subrange => this.isWellness ? subrange.bigDayBucket.wellnessWorkOrder : subrange.bigDayBucket.workWorkOrder);
        this.multidayCrewsToBeScheduled = subrangesForType.filter(subrange => bucketListDays.map(bld => bld.day).some(day => day.isBetween(subrange.fromDate, subrange.toDate, 'days', '[]')));
    }

    scheduleDays() {
        this.displayScheduleDialog = false;
        this.displayErrorScheduleDialog = false;
        this.displayConfirmationDialog = true;
        const comment: string = this.commentForm.get('errorComment').value;
        const scheduleComment: ScheduleComment = new ScheduleComment();
        if (comment) {
            scheduleComment.dateEntered = new Date();
            // If the scheduled date was set, use it, otherwise just put today.
            scheduleComment.dateScheduled = this.dayScheduledField.isValid() ? this.dayScheduledField.toDate() : new Date();
            scheduleComment.comment = comment;

            const token = this.authService.getDecodedAccessToken();

            scheduleComment.employeeId = +token.employeeId;

            this.scheduleCommentService.add(scheduleComment).subscribe(sc => {

            }, error => {
                this.messageService.addErrorMessage('Could not save the scheduled comment, please refresh and retry.', error);
            });
        }
        // This flattens the matrix this.bucketListDaysToBeScheduled[0, 1, ...].bucketList
        const bucketsToSchedule: ScheduledBucketDTO[] = [].concat(...this.bucketListDaysToBeScheduled.map(x => x.bucketList));

        // This notifies the customers - only notify the customers who have not been notified already.
        this.emailService.sendWorkOrdersScheduledByScheduledBucket(bucketsToSchedule.map(b => b.id)).subscribe(response => {
            if (response) {
                this.messageMainService.add({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: response,
                    life: 10000 // 10 seconds
                });
            }

            // This updates the front end task status
            bucketsToSchedule.forEach(bucket => {
                bucket.scheduledBucketWellnessWorkOrders.forEach(sbWO => {
                    if (sbWO.wellnessWorkOrder.completionStatus === WellnessWorkOrderCompletionStatus.Customer_Not_Notified) {
                        sbWO.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Notified;
                        sbWO.wellnessWorkOrder.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.currentBucketId === bucket.id)
                            .forEach(task => {
                                if (task.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Customer_Not_Notified) {
                                    task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Notified;
                                }
                            });
                    }
                });

                bucket.scheduledBucketWorkWorkOrders.forEach(sbWO => {
                    if (sbWO.workWorkOrder.completionStatus === WorkWorkOrderCompletionStatus.Customer_Not_Notified) {
                        sbWO.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Notified;
                        sbWO.workWorkOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.currentBucketId === bucket.id)
                            .forEach(task => {
                                if (task.workTask.completionStatus === WorkTaskCompletionStatus.Customer_Not_Notified) {
                                    task.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Notified;
                                }
                            });
                    }
                });
            });
        });
        this.emailService.sendWorkOrdersScheduledBySubrange(this.multidayCrewsToBeScheduled.map(m => m.id)).subscribe(res => {
            this.multidayCrewsToBeScheduled.forEach(subrange => {
                // theoretically, multiday crews are scheduled for ALL tasks on the WO - but let's do some checking beforehand in case the workflow changes
                if (subrange.bigDayBucket.wellnessWorkOrder && subrange.bigDayBucket.wellnessWorkOrder.completionStatus === WellnessWorkOrderCompletionStatus.Customer_Not_Notified) { 
                    subrange.bigDayBucket.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Notified;
                    subrange.bigDayBucket.wellnessWorkOrder.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.currentBigDaySubrangeId === subrange.id)
                        .forEach(task => {
                            if (task.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Customer_Not_Notified) {
                                task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Notified;
                            }
                        })
                }

                if (subrange.bigDayBucket.workWorkOrder && subrange.bigDayBucket.workWorkOrder.completionStatus === WorkWorkOrderCompletionStatus.Customer_Not_Notified) { 
                    subrange.bigDayBucket.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Notified;
                    subrange.bigDayBucket.workWorkOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.currentBigDaySubrangeId === subrange.id)
                        .forEach(task => {
                            if (task.workTask.completionStatus === WorkTaskCompletionStatus.Customer_Not_Notified) {
                                task.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Notified;
                            }
                        });
                }
            })
        });

        // After scheduling, remove the comment for next time.
        this.commentForm.reset();
    }

    startTimer() {
        if (this.timerPaused) {
            this.timerPaused = false;
            this.intervals.push(setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.getAllScheduledItems();
                this.timeLeft = this.defaultTime;
            }
            }, 1000));
        }
    }

    pauseTimer() {
        this.timerPaused = true;
        this.intervals.forEach(interval => {
            clearInterval(interval);
        });
    }

    getAllScheduledItems() {
        this.scheduledBucketService.getAllScheduledBucketsInDateRange(
            this.bucketListDayList[0].day.startOf('d').toDate(),
                this.bucketListDayList[this.bucketListDayList.length - 1].day.endOf('d').toDate())
            .subscribe(
                records => {
                    this.dayBucketIndexRecords = records.filter(record => record.isWork === this.isWork); // Current view records
                    this.otherViewScheduledBuckets = records.filter(record => !(record.isWork === this.isWork)); // Other view records

                    this.dragAndDropService.setDayBucketIndexRecords(this.dayBucketIndexRecords);
                    this.bucketListDayList.forEach(bucketListDay => {
                        const dayMatches = this.dayBucketIndexRecords.filter(x => {
                            return bucketListDay.day.isSame(moment(x.date), 'day');
                        });
                        bucketListDay.bucketList = dayMatches.sort((a, b) => a.bucketIndex - b.bucketIndex);
                    });
                    // this.dragAndDropService.setBucketListDayList(this.bucketListDayList);
                }
            );

        this.getUnavailableBigDayEquipmentAndEmployees();
    }

    getUnavailableBigDayEquipmentAndEmployees() {
        this.unavailableEquipment = [];
        this.unavailableEmployees = [];
        this.bigDayBucketSubrangeService.getAllBigDayBucketSubrangeInDateRange(
            this.bucketListDayList[0].day.toDate(), this.bucketListDayList[this.bucketListDayList.length - 1].day.toDate()).
            subscribe(subranges => {
                this.subrangesForView = subranges;
                for (let i = 0; i < this.howManyDays; i++) {
                    // const subrangesOnThisDay = subranges.filter(x =>
                    //     x.fromDate <= this.bucketListDayList[i].day.toDate() && x.toDate >= this.bucketListDayList[i].day.toDate());
                    const subrangesOnThisDay = subranges.filter(x =>
                        moment(this.bucketListDayList[i].day.toDate()).isBetween(x.fromDate, x.toDate, 'day', '[]'));

                    const eqpMatrix = subrangesOnThisDay.map(subs => subs.bigDayEquipment).slice();
                    const eqpArray = [].concat.apply([], eqpMatrix);
                    this.unavailableEquipment.push(eqpArray);

                    const empMatrix = subrangesOnThisDay.map(subs => subs.bigDayEmployees).slice();
                    const empArray = [].concat.apply([], empMatrix);
                    this.unavailableEmployees.push(empArray);
                }
            });
    }

    indexHasUnavailableEquipmentOrEmployees(index: number) {
        const hasUnavailableEquipment = this.unavailableEquipment &&
            this.unavailableEquipment.length > 0 && this.unavailableEquipment[index].length > 0;
        const hasUnavailableEmployees = this.unavailableEmployees &&
            this.unavailableEmployees.length > 0 && this.unavailableEmployees[index].length > 0;
        return hasUnavailableEquipment || hasUnavailableEmployees;
    }

    rescheduleDay(bucketDayList: BucketListDay) {
        this.confirmationService.confirm({
            header: 'Reschedule',
            message: `Are you sure that you want reschedule all draggable crews from ${bucketDayList.day.format('M/DD/YY')} to ${moment(this.rescheduleDate).format('M/DD/YY')}?
                        Any crews that remain on ${bucketDayList.day.format('M/DD/YY')} have work that has been completed and need to be manually reviewed to reschedule.`,
            accept: () => {
                const oldBucketList = bucketDayList.bucketList;
                const targetDayList = this.bucketListDayList.find(x => x.day.isSame(moment(this.rescheduleDate), 'd'));

                if (targetDayList) {
                    oldBucketList.forEach((bucket, newIndex) => {
                        if (!this.disableDrag(bucket)) { // only update buckets that are draggable
                            copyArrayItem(oldBucketList, targetDayList.bucketList, newIndex, newIndex);
                            const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
                            this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                            const record = this.dayBucketIndexRecords[recordIndex];
                            record.date = record.date;
                            this.scheduledItemBucketService.rescheduleBucket(bucket.id, newIndex, moment(record.date).startOf('d').utc().toDate())
                                .subscribe(x => {
                                    const index = oldBucketList.findIndex(sb => sb.id === x.id);
                                    oldBucketList.splice(index, 1);
                                    this.dayBucketIndexRecords[recordIndex] = x;
                                    this.validateDay(x.date);
                                },
                                error => {
                                    this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                                });
                        }
                    });

                    // bucketDayList.bucketList = [];

                    targetDayList.bucketList.forEach((bucket, newIndex) => {
                        const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
                        this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                        this.dayBucketIndexRecords[recordIndex].active = true;
                        // if (newIndex === 0) {
                            this.dayBucketIndexRecords[recordIndex].date = targetDayList.day.format('MM/DD/YYYY');
                            bucket.date = targetDayList.day.format('MM/DD/YYYY');
                            this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, moment(bucket.date).startOf('d').utc().toDate()).subscribe();
                        // }

                        bucket.scheduledBucketWellnessWorkOrders.forEach(sbwwo => {
                            sbwwo.wellnessWorkOrder.workOrderWellnessTasks.forEach(wowt => {
                                if (wowt.wellnessTask.currentBucketId === bucket.id) {
                                    wowt.wellnessTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                    wowt.wellnessTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                    wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                    wowt.wellnessTask.reschedule = true;

                                    sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                }
                            });

                            // tslint:disable-next-line: max-line-length
                            this.wellnessWorkOrderService.update(sbwwo.wellnessWorkOrder, sbwwo.wellnessWorkOrder.id.toString()).subscribe();
                        });

                        bucket.scheduledBucketWorkWorkOrders.forEach(sbwwo => {
                            sbwwo.workWorkOrder.workOrderWorkTasks.forEach(wowt => {
                                if (wowt.workTask.currentBucketId === bucket.id) {
                                    wowt.workTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                    wowt.workTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                    wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                    wowt.workTask.reschedule = true;

                                    sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                }
                            });

                            this.workWorkOrderService.update(sbwwo.workWorkOrder, sbwwo.workWorkOrder.id.toString()).subscribe();
                        });

                        const record = this.dayBucketIndexRecords[recordIndex];
                        record.date = moment(record.date).startOf('d').format('MM/DD/YYYY');
                        this.scheduledItemBucketService.updateBucketOrdering(record.id, newIndex, moment(record.date).startOf('d').utc().toDate())
                            .subscribe(x => {
                                this.dayBucketIndexRecords[recordIndex] = x;
                            },
                                error => {
                                    this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                                });
                    });
                    this.rescheduleDate = null;
                } else {
                    oldBucketList.forEach((bucket, newIndex) => {
                        if (!this.disableDrag(bucket)) { // only update buckets that are draggable
                            bucket.date = moment(this.rescheduleDate).startOf('d').format('MM/DD/YYYY');
                            this.scheduledItemBucketService.rescheduleBucket(bucket.id, newIndex, moment(bucket.date).startOf('d').utc().toDate()).subscribe(res => {
                                const index = oldBucketList.findIndex(sb => sb.id === res.id);
                                oldBucketList.splice(index, 1);
                            });

                            // Update work orders - this also sends out rescheduled emails if needed
                            bucket.scheduledBucketWellnessWorkOrders.forEach(sbwwo => {
                                sbwwo.wellnessWorkOrder.workOrderWellnessTasks.forEach(wowt => {
                                    if (wowt.wellnessTask.currentBucketId === bucket.id) {
                                        wowt.wellnessTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.wellnessTask.reschedule = true;

                                        sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });

                                // tslint:disable-next-line: max-line-length
                                this.wellnessWorkOrderService.update(sbwwo.wellnessWorkOrder, sbwwo.wellnessWorkOrder.id.toString()).subscribe();
                            });

                            bucket.scheduledBucketWorkWorkOrders.forEach(sbwwo => {
                                sbwwo.workWorkOrder.workOrderWorkTasks.forEach(wowt => {
                                    if (wowt.workTask.currentBucketId === bucket.id) {
                                        wowt.workTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.workTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.workTask.reschedule = true;

                                        sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });

                                this.workWorkOrderService.update(sbwwo.workWorkOrder, sbwwo.workWorkOrder.id.toString()).subscribe();
                            });
                        }
                    });

                    this.rescheduleDate = null;
                }
            }, reject: () => {
                this.rescheduleDate = null;
                this.messageMainService.add({
                    severity: 'info',
                    summary: 'No Action Taken',
                    detail: 'No work orders have been rescheduled'
                });
            }
        });
    }

    addNewCrew(bucketDayList: BucketListDay) {
        const newBucket = new ScheduledBucketDTO();
        newBucket.isBucket = true;
        newBucket.date = bucketDayList.day.format('MM/DD/YYYY');
        newBucket.active = true;
        newBucket.scheduledBucketEmployees = [];
        newBucket.scheduledBucketEquipment = [];
        newBucket.scheduledBucketWellnessWorkOrders = [];
        newBucket.scheduledBucketWorkWorkOrders = [];
        newBucket.bucketIndex = 0;
        newBucket.isWork = this.isWork;

        // Make a temporary GUID to match so when the real IDs come back we can update the objects
        const tempGuid = Guid.newGuid();
        (newBucket as any).temp = tempGuid;
        bucketDayList.bucketList.splice(0, 0, newBucket);
        newBucket.date = moment(newBucket.date).startOf('d').format('MM/DD/YYYY');
        this.scheduledBucketService.add(newBucket).subscribe(addedBucket => {
            const match = bucketDayList.bucketList.find(x => {
                return (x as any).temp === tempGuid;
            });
            match.id = addedBucket.id;

            this.dragAndDropService.addBucketToCDKLists(addedBucket);
            this.dayBucketIndexRecords.push(addedBucket);
            this.dragAndDropService.dayBucketIndexRecords.push(addedBucket);
        });
    }

    woCanMove(workOrder: WellnessWorkOrder | WorkWorkOrder, bucket: ScheduledBucketDTO) {
        if (workOrder instanceof WellnessWorkOrder) {
            return workOrder.workOrderWellnessTasks
            .some(wowt => wowt.wellnessTask.currentBucketId === bucket.id &&
                moment(wowt.wellnessTask.scheduleDateFrom).isSame(bucket.date, 'd') &&
                (wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed ||
                wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed));
        } else if (workOrder instanceof WorkWorkOrder) {
            return workOrder.workOrderWorkTasks
            .some(wowt => wowt.workTask.currentBucketId === bucket.id &&
                moment(wowt.workTask.scheduleDateFrom).isSame(bucket.date, 'd') &&
                (wowt.workTask.completionStatus === WorkTaskCompletionStatus.Completed ||
                    wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed));
        }
    }

    disableDrag(bucket: ScheduledBucketDTO) {
        let hasCompletedItem = false;

        // Checks each work order to see if any of the tasks have been completed (or can not be completed).
        // Will disable drag if there is a task complete, so they cannot unschedule complete tasks.
        // They can still drag tasks that are not complete but cannot drag the whole bucket
        // This ONLY applies when the completed task is scheduled for the date of the schedule bucket
        hasCompletedItem = bucket.scheduledBucketWellnessWorkOrders.some(sbwwo => sbwwo.wellnessWorkOrder.workOrderWellnessTasks
                .some(wowt => (wowt.wellnessTask.currentBucketId === bucket.id ||  wowt.wellnessTask.goBackBucketId === bucket.id) &&
                    (wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed ||
                    wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed))) ||
            bucket.scheduledBucketWorkWorkOrders.some(sbwwo => sbwwo.workWorkOrder.workOrderWorkTasks
                .some(wowt => (wowt.workTask.currentBucketId === bucket.id || wowt.workTask.goBackBucketId === bucket.id) &&
                    (wowt.workTask.completionStatus === WorkTaskCompletionStatus.Completed ||
                        wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed)));
        return hasCompletedItem;
    }

    onNewBucket(bucket: ScheduledBucketDTO) {
        this.dragAndDropService.addBucketToCDKLists(bucket);
    }

    woDragStart(workOrder: WellnessWorkOrder, bucketId: number) {
        this.pauseTimer();
        this.woDragged.emit({workOrder, bucketId});
    }

    woDragEnd() {
        this.startTimer();
        this.woDraggedEnd.emit();
    }

    taskDraggedStart(task: WellnessTask | WorkTask) {
        this.pauseTimer();
        this.taskDragged.emit(task);
    }

    taskDraggedEnded() {
        this.startTimer();
        this.taskDraggedEnd.emit();
    }

    // Handle your own drop events calendar
    drop(event: CdkDragDrop<Crew[] | ScheduledBucketDTO[]>) {
        console.log('DROP ONTO CALENDAR');
        if (event.isPointerOverContainer) {
            const droppedItem = event.previousContainer.data[event.previousIndex];

            //////////////////////////////////////////////////////////////////////////////////////////
            // This code runs when you are reordering an already-created bucket around ON THE SAME DAY.
            //////////////////////////////////////////////////////////////////////////////////////////
            if (event.previousContainer === event.container) {
                const bucketList = (event.container.data as ScheduledBucketDTO[]);

                // This just updates all the indices in javascript
                moveItemInArray(bucketList, event.previousIndex, event.currentIndex);

                // Data call to update the DB
                bucketList.forEach((bucket, newIndex) => {
                    const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
                    const record = this.dayBucketIndexRecords[recordIndex];
                    this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;

                    const date = moment(record.date).startOf('d').utc().toDate();
                    this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, date)
                        .subscribe(x => {
                            this.dayBucketIndexRecords[recordIndex] = x;
                        },
                        error => {
                            this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                        });
                });
            } else {
                //////////////////////////////////////////////////////////////////////////////////////////
                // Move a bucket from one day to another
                //////////////////////////////////////////////////////////////////////////////////////////
                if (droppedItem instanceof ScheduledBucketDTO) {
                    droppedItem.active = true;
                    const oldBucketList = (event.previousContainer.data as ScheduledBucketDTO[]);
                    const newBucketList = (event.container.data as ScheduledBucketDTO[]);
                    const targetDayList = this.bucketListDayList.find(x => x.bucketList === event.container.data);

                    // This just updates all the indices in javascript
                    transferArrayItem(oldBucketList, newBucketList, event.previousIndex, event.currentIndex);

                    // Data call to update the DB - updating index for buckets on the day we're moving off of
                    oldBucketList.forEach((bucket, newIndex) => {
                        const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
                        const record = this.dayBucketIndexRecords[recordIndex];
                        this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                        const date = moment(record.date).startOf('d').utc().toDate();
                        record.date = date.toDateString();
                        this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, date)
                            .subscribe(x => {
                                this.dayBucketIndexRecords[recordIndex] = x;
                                this.validateDay(x.date);
                            },
                            error => {
                                this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                            });
                    });

                    // updating indexes for buckets on the way we're moving to
                    newBucketList.forEach((bucket, newIndex) => {
                        const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
                        this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;

                        // only update the tasks for the bucket that is being moved
                        if (bucket.id === droppedItem.id) {
                            bucket.date = targetDayList.day.format('MM/DD/YYYY');
                            bucket.scheduledBucketWellnessWorkOrders.forEach(sbwwo => {
                                sbwwo.wellnessWorkOrder.workOrderWellnessTasks.forEach(wowt => {
                                    if (!wowt.wellnessTask.isGoBack && wowt.wellnessTask.currentBucketId === bucket.id) {
                                        wowt.wellnessTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.wellnessTask.reschedule = true;

                                        sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                    } else if (wowt.wellnessTask.isGoBack && wowt.wellnessTask.goBackBucketId === bucket.id) {
                                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.wellnessTask.reschedule = true;

                                        sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });
                            });

                            bucket.scheduledBucketWorkWorkOrders.forEach(sbwwo => {
                                sbwwo.workWorkOrder.workOrderWorkTasks.forEach(wowt => {
                                    if (!wowt.workTask.isGoBack && wowt.workTask.currentBucketId === bucket.id) {
                                        wowt.workTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.workTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.workTask.reschedule = true;

                                        sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                    } else if (wowt.workTask.isGoBack && wowt.workTask.goBackBucketId === bucket.id) {
                                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.workTask.reschedule = true;

                                        sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });
                            });

                            this.scheduledItemBucketService.rescheduleBucket(bucket.id, newIndex, new Date(bucket.date)).subscribe(x => {
                                    this.dayBucketIndexRecords[recordIndex] = x;
                                    this.validateDay(x.date);
                                },
                                error => {
                                    this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                                });
                        } else {
                            // reorder other buckets
                            this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, new Date(bucket.date))
                                .subscribe(x => {
                                    this.dayBucketIndexRecords[recordIndex] = x;
                                    this.validateDay(x.date);
                                },
                                error => {
                                    this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                                });
                        }
                    });
                } else {
                    //////////////////////////////////////////////////////////////////////////////////////////
                    // Create a new bucket
                    //////////////////////////////////////////////////////////////////////////////////////////
                    const bucketList = (event.container.data as ScheduledBucketDTO[]);
                    const newBucket = this.prepareItemForScheduleBucket(droppedItem);

                    const targetDayList = this.bucketListDayList.find(x => x.bucketList === event.container.data);
                    // Make a temporary GUID to match so when the real IDs come back we can update the objects
                    const tempGuid = Guid.newGuid();
                    (newBucket as any).temp = tempGuid;
                    bucketList.splice(event.currentIndex, 0, newBucket);
                    newBucket.active = true;

                    // Only delete item from previous container if it is coming off a bucket
                    if (event.previousContainer.id.includes('bucket')) {
                        event.previousContainer.data.splice(event.previousIndex, 1);
                    }

                    newBucket.date = targetDayList.day.format('MM/DD/YYYY');
                    newBucket.bucketIndex = event.currentIndex;

                    newBucket.date = moment(newBucket.date).startOf('d').format('MM/DD/YYYY');
                    this.scheduledItemBucketService.add(newBucket).subscribe(addedBucket => {
                        // const record = new ScheduledBucketIndex();

                        const match = bucketList.find(x => {
                            return (x as any).temp === tempGuid;
                        });
                        match.id = addedBucket.id;

                        this.dragAndDropService.addBucketToCDKLists(addedBucket);
                        this.dayBucketIndexRecords.push(addedBucket);
                    },
                        error => {
                            this.messageService.addErrorMessage('Could not save the scheduled bucket, please refresh and retry.', error);
                        });
                }
            }
        } else {
            //////////////////////////////////////////////////////////////////////////////////////////
            // If the event is not over a permitted container, delete it.
            //////////////////////////////////////////////////////////////////////////////////////////
            const bucketList = (event.previousContainer.data as ScheduledBucketDTO[]);
            const bucket = bucketList[event.previousIndex];

            bucket.scheduledBucketWellnessWorkOrders.forEach(wo => {
                this.dragAndDropService.removeWorkOrderFromScheduling(wo.wellnessWorkOrder, bucket.id);
            });

            bucket.scheduledBucketWorkWorkOrders.forEach(wo => {
                this.dragAndDropService.removeWorkOrderFromScheduling(wo.workWorkOrder, bucket.id);
            });

            // rxjs uses cold observables meaning that you have to subscribe to make it work.
            const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
            this.scheduledItemBucketService.delete(bucket.id).subscribe(() => {
                this.validateDay(bucket.date);
            });

            // This just updates all the indices in javascript
            bucketList.splice(event.previousIndex, 1);
            this.dayBucketIndexRecords.splice(recordIndex, 1);
        }
    }

    prepareItemForScheduleBucket(item: Crew): ScheduledBucketDTO {
        const bucket = new ScheduledBucketDTO();
        bucket.isBucket = true;
        bucket.isWork = this.isWork;
        bucket.scheduledBucketWellnessWorkOrders = [];
        bucket.scheduledBucketWorkWorkOrders = [];
        bucket.scheduledBucketEmployees = [];
        bucket.scheduledBucketEquipment = [];

        item.crewEmployees.forEach(ce => {
            const scheduledBucketEmployee: ScheduledBucketEmployee = new ScheduledBucketEmployee();
            scheduledBucketEmployee.employeeId = ce.employeeId;
            scheduledBucketEmployee.scheduledBucketId = bucket.id;
            scheduledBucketEmployee.employee = ce.employee;
            bucket.scheduledBucketEmployees.push(scheduledBucketEmployee);
        });


        item.crewEquipment.forEach(ce => {
            const scheduledBucketEquipment: ScheduledBucketEquipment = new ScheduledBucketEquipment();
            scheduledBucketEquipment.equipmentId = ce.equipmentId;
            scheduledBucketEquipment.scheduledBucketId = bucket.id;
            scheduledBucketEquipment.equipment = ce.equipment;
            bucket.scheduledBucketEquipment.push(scheduledBucketEquipment);
        });

        return bucket;
    }
}
