import { EventEmitter, OnInit } from '@angular/core';
import { QuoteRequest, QuoteRequestType } from 'src/app/services_autogenerated/generated_services';
var QuoteOptionSectionComponent = /** @class */ (function () {
    function QuoteOptionSectionComponent() {
        this.quoteOptionChanged = new EventEmitter();
    }
    Object.defineProperty(QuoteOptionSectionComponent.prototype, "Type", {
        // use type on template
        get: function () {
            return QuoteRequestType;
        },
        enumerable: true,
        configurable: true
    });
    QuoteOptionSectionComponent.prototype.ngOnInit = function () {
    };
    QuoteOptionSectionComponent.prototype.optionChanged = function () {
        this.quoteOptionChanged.emit();
    };
    return QuoteOptionSectionComponent;
}());
export { QuoteOptionSectionComponent };
