<form id="cashCollectedForm" #cashCollectedForm="ngForm">
  <p-dialog header="Charge Card" [(visible)]="showDialog" [modal]="true" [blockScroll]="false" (onHide)="cancel()" [style]="{width: '480px'}">
    <app-customer-billing-profile *ngIf="!isLoading" [customer]="customer" style="z-index: 11111" (billingProfileEntryModalClosed)="refreshBilling()"></app-customer-billing-profile>
    <div>
      Work Order Total: {{workOrder.workOrderTotal | currency: 'USD' : 'symbol'}}
      <br/>
      Cash/Check Collected: {{workOrder.cashCollected | currency: 'USD' : 'symbol'}}
      <br/>
      Amount Due: {{workOrder.amountDue | currency: 'USD' : 'symbol'}}      
    </div>
    <p-footer>
      <app-loading-swirly [isLoading]="isLoading"></app-loading-swirly>
      <span *ngIf="showNoCC" class="fine-print">There is no credit card on file, edit the billing info above to add a card.</span>
      <button type="button" pButton (click)="chargeCard()" label="Charge Card" [disabled]="disabled"></button>
      <button type="button" pButton (click)="cancel()" label="Cancel" class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
</form>
