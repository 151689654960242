<div *ngIf="customer">
  <br>
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-12">
      <h4>CREATE A NEW NOTE</h4>
    </div>
  </div>
  <br>
  <form id="customerCommentForm" #customerCommentForm="ngForm" *ngIf="comment">
    <div class="ui-g">
      <div class="ui-g-12 ui-lg-6">
        <h6>Note</h6>
        <textarea pInputTextarea name="comment" [(ngModel)]="comment.comment" required></textarea>
      </div>
      <div class="ui-g-12 ui-lg-2">
        <h6>Category</h6>
        <ng-select class="custom" [items]="stages" placeholder="Select a Category" name="stage" bindLabel="commentStage"  [selectOnTab]="true"
          [(ngModel)]="comment.customerCommentStage" required>
        </ng-select>
      </div>
      <div class="ui-g-12 ui-lg-2">
        <h6>Quote/Work Order #</h6>
        <input pInputText class="inputTextBox" name="quoteNumber" [(ngModel)]="comment.quoteNumber" />
      </div>
      <div class="ui-g-12 ui-lg-2">
        <h6>Company Wide Note?</h6>
        <p-checkbox binary="true" name="companyWide" [(ngModel)]="comment.companyWide">
        </p-checkbox>
        <div style="font-style: italic;">By checking the box, this note will be displayed to internal employees when viewing/creating quotes and work orders. Notes will never be visible to customers.</div>
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12 columnPadding">
        <div *ngIf="customerCommentForm.invalid && customerCommentForm.submitted" class="alert alert-danger"
          role="alert">
          There are errors on this page. Please correct the errors and resubmit.
        </div>
      </div>
      <div class="ui-g-12 buttonStyle">
        <button *ngIf="!comment.id" pButton type="submit" style="padding: 2px" (click)="saveComment(customerCommentForm)"
          class="ui-button-raised ui-button-success buttonStyle" label="Submit">
        </button>
        <button *ngIf="comment.id" pButton type="submit" style="padding: 2px" (click)="saveComment(customerCommentForm)"
          class="ui-button-raised ui-button-success buttonStyle" label="Update">
        </button>
      </div>
    </div>
  </form>

  <div class="ui-g-12 ui-g-nopad">
    <p-table [columns]="cols" [value]="allComments" [paginator]="true" [rows]="10" [responsive]="true" #notesTable sortField="companyWide" [sortOrder]="-1" [lazy]="false">
      <ng-template pTemplate="caption">
          <div style="text-align: right">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="50" placeholder="Search..." (input)="notesTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
            {{col.header}}
            <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
            <th [style.width]="'7%'"><!-- for the buttons --></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-eachComment let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <td><span class="ui-column-title">{{columns[0].header}}</span>{{eachComment.lastEditedDate | date: 'short'}}</td>
          <td><span class="ui-column-title">{{columns[1].header}}</span>{{eachComment.companyWide ? 'Yes': 'No'}}</td>
          <td><span class="ui-column-title">{{columns[2].header}}</span>{{eachComment.customerCommentStage.commentStage}}</td>
          <td><span class="ui-column-title">{{columns[3].header}}</span>{{eachComment.comment}}</td>
          <td><span class="ui-column-title">{{columns[4].header}}</span>{{eachComment.quoteNumber ? eachComment.quoteNumber : '--'}}</td>
          <td><span class="ui-column-title">{{columns[5].header}}</span>{{eachComment.employee ? eachComment.employee.fullName : 'Customer'}}</td>
          <td>
            <button pButton type="button" icon="pi pi-pencil" (click)="editComment(eachComment)" [disabled]="!canEditOrDelete"
              class="ui-button-secondary blackBorder editButtonMargin">
            </button>
            <button pButton [disabled]="!canEditOrDelete" type="button"
              icon="pi pi-trash" (click)="deleteComment(eachComment)" class="ui-button-danger">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g-12"></div>
</div>
