import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkOrderWorkTask,
  WorkTaskGenSvc,
  WorkTask,
  PriorityLevel,
  PriorityLevelGenSvc,
  WorkTaskCompletionStatus,
  CustomerNoteGenSvc,
  WorkTaskPaymentStatus,
  ScheduledBucketGenSvc,
  UnableToCompleteTaskDTO,
  ScheduledBucketDTO
} from '../../services_autogenerated/generated_services';
import { AuthHelperService } from '../../services/auth-helper.service';
import { MessageService } from 'primeng/api';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';

@Component({
  selector: 'app-work-order-maintenance-work-task-card',
  templateUrl: './work-order-maintenance-work-task-card.component.html',
  styleUrls: ['./work-order-maintenance-work-task-card.component.css']
})
export class WorkOrderMaintenanceWorkTaskCardComponent implements OnInit {
  @Input() customerId: number;
  @Input() fromQuote: boolean;
  @Input() hidePrice: boolean;
  @Input() isCustomer: boolean;
  @Input() isEditable: boolean;
  @Input() quoteNumber: string;
  @Input() workOrderWorkTask: WorkOrderWorkTask;

  @Output() completeWorkTaskEvent = new EventEmitter<WorkOrderWorkTask>();
  @Output() copyWorkTaskEvent = new EventEmitter<WorkOrderWorkTask>();
  @Output() deleteWorkTaskEvent = new EventEmitter<WorkOrderWorkTask>();
  @Output() editWorkTaskEvent = new EventEmitter<WorkOrderWorkTask>();
  @Output() taskSelectedChangeEvent = new EventEmitter<null>();
  @Output() unableToCompleteWorkTaskEvent = new EventEmitter<WorkTask>();
  @Output() undoCompleteTask = new EventEmitter<WorkTask>();

  canSeeHiddenPrice: boolean;
  canSetGoBack: boolean;
  doCompleteTask: boolean;
  priorities: PriorityLevel[];
  taskHourGoBackTotal: number;
  taskHourTotal: number;
  returnedCustomerCommentId: number;
  isIncompleteGoBack: boolean;
  showStatusBar = false;
  showTaskHour = false;
  taskHoursForGoBack = false;
  unableToComplete = false;

  canComplete: boolean;
  canSeePreviousCrew: boolean;
  isSelected = false;

  previousStage: WorkTaskCompletionStatus;
  canUndo: boolean;
  uploadId: number;

  scheduledBucket: ScheduledBucketDTO;
  goBackScheduledBucket: ScheduledBucketDTO;

  constructor(private authHelper: AuthHelperService,
    private workTaskService: WorkTaskGenSvc,
    private priorityLevelService: PriorityLevelGenSvc,
    private messageService: MessageService,
    private customerCommentService: CustomerNoteGenSvc,
    public helper: WorkOrderHelperService,
    private scheduledBucketService: ScheduledBucketGenSvc
  ) { }

  ngOnInit() {
    const token = this.authHelper.getDecodedAccessToken();
    // When you are looking at a task on a quote, use the task id
    // when looking at a task on a WO, use the primordial task id
    // (So both tasks look at the same set of uploads, since WO task's have a different task id from the Quote's task)
    this.uploadId = this.fromQuote ? this.workOrderWorkTask.workTask.id : this.workOrderWorkTask.workTask.primordialTaskId;
    this.canComplete = token.claimNames.includes('Completing a Task');
    this.canSeeHiddenPrice = token.claimNames.includes('View Hidden Prices');
    this.canSetGoBack = token.claimNames.includes('Can Initiate Go Back') || token.claimNames.includes('Can Set Go Back');
    this.isSelected = this.workOrderWorkTask.workTask.completionStatus === WorkTaskCompletionStatus.Work_Orders_Created;
    // If the task is a go back, don't let schedulable employees see the previous crew - otherwise just show the schedule bucket crew
    this.canSeePreviousCrew = this.workOrderWorkTask.workTask.isGoBack
          ? !token.claimNames.includes('Schedulable')
            || token.claimNames.includes('Full Control')
            || token.roles.includes('Tree Wellness Production Manager')
            || token.roles.includes('Tree Work Production Manager')
          : true;

    this.priorityLevelService.getAll().subscribe(priorities => this.priorities = priorities);
    this.setUpStatusBar();
    this.setTaskHours();

    if (this.workOrderWorkTask.workTask.currentBucketId) {
      this.scheduledBucketService.get(this.workOrderWorkTask.workTask.currentBucketId).subscribe(sb => {
        this.scheduledBucket = sb;
      });
    }

    if (this.workOrderWorkTask.workTask.goBackBucketId) {
      this.scheduledBucketService.get(this.workOrderWorkTask.workTask.goBackBucketId).subscribe(sb => {
        this.goBackScheduledBucket = sb;
      });
    }
  }

  showTaskHoursDialog(asGoBack: boolean) {
    this.taskHoursForGoBack = asGoBack;
    this.showTaskHour = true;
    this.doCompleteTask = true;
  }

  showTaskHoursDialogUnableToComplete(asGoBack: boolean) {
    this.taskHoursForGoBack = asGoBack;
    this.doCompleteTask = false;
    this.unableToComplete = true;
    this.showTaskHour = true;
  }

  showTaskHoursDialogAddHours() {
    this.doCompleteTask = false;
    this.showTaskHour = true;
  }

  setTaskHours() {
    this.taskHourTotal = this.workOrderWorkTask.workTask.taskHourEntries.reduce((acc, curr) => acc + +curr.hours, 0);
    this.taskHourGoBackTotal = this.workOrderWorkTask.workTask.taskHourEntries.filter(entry => entry.isGoBack).reduce((acc, curr) => acc + +curr.hours, 0);
  }

  setUpStatusBar() {
    this.isIncompleteGoBack = this.workOrderWorkTask.workTask.isGoBack && this.workOrderWorkTask.workTask.completionStatus !== WorkTaskCompletionStatus.Completed;

    this.showStatusBar = this.workOrderWorkTask.workTask.completionStatus === WorkTaskCompletionStatus.Completed
                      || this.workOrderWorkTask.workTask.completionStatus === WorkTaskCompletionStatus.Go_Back
                      || this.workOrderWorkTask.workTask.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                      || this.isIncompleteGoBack;
  }

  taskHoursCompleted() {
    this.showTaskHour = false;
    this.setTaskHours();
    this.setUpStatusBar();

    this.completeTask();

    this.unableToComplete = false;
  }

  taskHoursSubmitted(permanent: boolean) {
    this.showTaskHour = false;
    this.setTaskHours();
    this.setUpStatusBar();

    if (permanent) {
      this.workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Unable_to_be_Completed;

      // Update task
      this.workTaskService.update(this.workOrderWorkTask.workTask)
        .subscribe(() => {
          this.unableToCompleteWorkTaskEvent.emit(this.workOrderWorkTask);
          this.messageService.add({ severity: 'success', summary: 'Task has been set to unable to be completed.' });
          this.setUpStatusBar();
        });
      this.unableToComplete = false;
    } else {
      // Unschedule task and set it as a Go Back
      this.workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Go_Back;
      this.workOrderWorkTask.workTask.priorityLevelId = 1; // Go Back priority is seeded into the database and cannot be deleted
      this.workOrderWorkTask.workTask.hasCalculatedPriority = false;

      // Update task
      this.workTaskService.unableToCompleteToday(new UnableToCompleteTaskDTO({
        id: this.workOrderWorkTask.workTask.id,
        feedback: this.workOrderWorkTask.workTask.isGoBack ?
          this.workOrderWorkTask.workTask.goBackFeedback : this.workOrderWorkTask.workTask.jobFeedback
      }))
      .subscribe(res => {
        this.unableToCompleteWorkTaskEvent.emit(res);
        this.messageService.add({ severity: 'success', summary: 'Task has been set to Go Back status.' });
      });
    this.unableToComplete = false;
    }
  }

  addedHours() {
    this.showTaskHour = false;
    this.setTaskHours();
    this.setUpStatusBar();
    this.workTaskService.update(this.workOrderWorkTask.workTask)
        .subscribe(() => this.messageService.add({ severity: 'success', summary: 'Task hours have been saved.' }) );
  }

  closedTaskHoursDialog() {
    this.unableToComplete = false;
    this.showTaskHour = false;
    this.setUpStatusBar();
  }

  completeTask() {
    this.previousStage = this.workOrderWorkTask.workTask.completionStatus;

    if (this.workOrderWorkTask.workTask.isGoBack && !this.workOrderWorkTask.workTask.goBackCompletionDate) {
      this.workOrderWorkTask.workTask.goBackCompletionDate = new Date();
    } else if (!this.workOrderWorkTask.workTask.completedDate) {
      this.workOrderWorkTask.workTask.completedDate = new Date();
    }

    this.canUndo = true;
    this.closedTaskHoursDialog();
    this.completeWorkTaskEvent.emit(this.workOrderWorkTask);
    this.setUpStatusBar();
  }

  undoComplete() {
    this.workOrderWorkTask.workTask.completionStatus = this.previousStage;

    if (this.workOrderWorkTask.workTask.isGoBack && !this.workOrderWorkTask.workTask.goBackCompletionDate) {
      this.workOrderWorkTask.workTask.goBackCompletionDate = null;
    } else if (!this.workOrderWorkTask.workTask.completedDate) {
      this.workOrderWorkTask.workTask.completedDate = null;
    }
    this.canUndo = false;
    if (this.returnedCustomerCommentId) {
      // Delete the customer comment that was entered when completing the task
      this.customerCommentService.delete(this.returnedCustomerCommentId).subscribe();
    }
    this.workTaskService.updateCompletionStatus(this.workOrderWorkTask.workTask).subscribe((wt) => {
      this.undoCompleteTask.emit(wt);
      this.setUpStatusBar();
    });
  }

  addCustomerCommentId(id: number) {
    this.returnedCustomerCommentId = id;
  }

  workCheckChanged(checked) {
    if (checked) {
      this.workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Work_Orders_Created;
    } else {
      this.workOrderWorkTask.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
    }

    this.taskSelectedChangeEvent.emit();
  }

  copyTask() {
    this.copyWorkTaskEvent.emit(this.workOrderWorkTask);
  }

  deleteWorkTask() {
    this.deleteWorkTaskEvent.emit(this.workOrderWorkTask);
  }

  editWorkTask() {
    this.editWorkTaskEvent.emit(this.workOrderWorkTask);
  }

  isDeletable() {
    return this.isEditable
    && this.helper.isTaskCapableOfProgress(this.workOrderWorkTask.workTask)
    && this.workOrderWorkTask.workTask.paymentStatus !== WorkTaskPaymentStatus.Invoiced
    && this.workOrderWorkTask.workTask.paymentStatus !== WorkTaskPaymentStatus.Paid;
  }
}
