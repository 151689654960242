export class DisplayBlob {
    constructor(fromCloudBlob: any) {
        const n = fromCloudBlob.name.lastIndexOf('/');
        this.DisplayName = fromCloudBlob.name.substring(n + 1);

        this.SASUri = fromCloudBlob.sASUri ? fromCloudBlob.sASUri : fromCloudBlob.SASUri;
        this.Name = fromCloudBlob.name;
    }

    DisplayName: string;
    Name: string;
    SASUri: string;
    Uri: string;
}
