import { Component, Inject, ViewChildren, QueryList, ViewChild } from '@angular/core';
import {
  Skill,
  WellnessWorkOrder,
  Address,
  WorkOrderWellnessTask,
  WellnessTask,
  Customer,
  WellnessWorkOrderGenSvc,
  WellnessTaskGenSvc,
  WorkOrderWorkTaskGenSvc,
  WorkOrderWellnessTaskGenSvc,
  WorkOrderPriceAdjustmentGenSvc,
  EquipmentType,
  EmailAddress,
  CustomerContact,
  WellnessTaskCompletionStatus,
  WellnessWorkOrderCompletionStatus,
  NotificationsGenSvc,
  WellnessTaskPaymentStatus,
  WorkOrderWorkTask,
  WellnessTaskTreeType,
  SaveWorkOrderAfterCompletionRequest,
  WellnessWorkOrderPaymentStatus,
  QuoteGenSvc
} from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BaseWorkOrderMaintenanceComponent } from '../base-work-order-maintenance.ts/base-work-order-maintenance.component';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';
import { WellnessTaskMaintenanceComponent } from '../wellness-task-maintenance/wellness-task-maintenance.component';
import { MaskService } from 'src/app/services/mask.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
import { WorkOrderMaintenanceWellnessTaskCardComponent } from '../work-order-maintenance-wellness-task-card/work-order-maintenance-wellness-task-card.component';

@Component({
  selector: 'app-wellness-work-order-maintenance',
  templateUrl: './wellness-work-order-maintenance.component.html',
  styleUrls: ['./wellness-work-order-maintenance.component.css']
})
export class WellnessWorkOrderMaintenanceComponent extends BaseWorkOrderMaintenanceComponent {
  workOrder: WellnessWorkOrder;
  selectedWellnessTask: WellnessTask;
  canCompleteWO = false;
  canUndoWOCompletion = false;
  previousWOStatus: WellnessWorkOrderCompletionStatus;
  amountToInvoice = 0;

  showUndoCompleteDialog = false;
  undoCompleteReason: string;
  commentForm: FormGroup;

  @ViewChildren(BlobManagerComponent) blobManagerComponents: QueryList<BlobManagerComponent>;
  @ViewChild(WellnessTaskMaintenanceComponent) wellnessTaskMaintenance: WellnessTaskMaintenanceComponent;
  @ViewChildren(WorkOrderMaintenanceWellnessTaskCardComponent) woTaskCards: QueryList<WorkOrderMaintenanceWellnessTaskCardComponent>;

  currencyMask: any;

  canSaveAfterCompletion: boolean;

  constructor(
    private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
    authHelper: AuthHelperService,
    private wellnessTaskService: WellnessTaskGenSvc,
    @Inject(ActivatedRoute) route: ActivatedRoute,
    @Inject(Router) router: Router,
    @Inject(MessageService) messageService: MessageService,
    @Inject(LocationColorService) locationColorService: LocationColorService,
    @Inject(NotificationsGenSvc) notificationService: NotificationsGenSvc,
    @Inject(WorkOrderHelperService) helper: WorkOrderHelperService,
    @Inject(WorkOrderWorkTaskGenSvc) workOrderWorkTaskGenSvc: WorkOrderWorkTaskGenSvc,
    @Inject(WorkOrderWellnessTaskGenSvc) workOrderWellnessTaskGenSvc: WorkOrderWellnessTaskGenSvc,
    @Inject(WorkOrderPriceAdjustmentGenSvc) wopaService: WorkOrderPriceAdjustmentGenSvc,
    @Inject(WorkWorkOrderMaintenanceService) workWorkOrderMaintenanceService: WorkWorkOrderMaintenanceService,
    @Inject(WellnessWorkOrderMaintenanceService) wellnessWorkOrderMaintenanceService: WellnessWorkOrderMaintenanceService,
    @Inject(MaskService) maskService: MaskService,
    @Inject(FormBuilder) fb: FormBuilder,
    @Inject(QuoteGenSvc) public quoteService: QuoteGenSvc,
    private messageWrapperService: MessageWrapperService
  ) {
    super(route, router, messageService, locationColorService, notificationService, helper, workOrderWorkTaskGenSvc,
      workOrderWellnessTaskGenSvc, wopaService, authHelper, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService);

    this.currencyMask = maskService.currencyMaskNoDecimal;

    this.commentForm = fb.group({
      description: ['']
  });
  }

  preSave(workOrderForm: NgForm) {
    if (this.canCompleteWO) {
      if (this.invoiceAmtGreaterThanCashCollected()) {
      this.previousWOStatus = this.workOrder.completionStatus;
      // this.wellnessWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
      //   .subscribe(() => {
          this.canCompleteWO = false;
        // }, error => {
        //   this.saving = false;
        //   this.messageService.add({
        //     severity: 'error',
        //     summary: 'Error Saving',
        //     detail: 'Could not save the work order, please check that all fields are valid and retry.'
        //   });
        // });
        this.save(workOrderForm);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Cash Collected Discrepancy',
          detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2)  + ') to complete this work order.',
          sticky: true
        });
      }
    } else {
      this.save(workOrderForm);
    }
  }

  saveAfterCompletion() {
    this.wellnessWorkOrderService.saveAfterCompletion(new SaveWorkOrderAfterCompletionRequest({
        id: this.workOrder.id,
        hasRentalCosts: this.workOrder.hasRentalExpense,
        rentalCosts: this.workOrder.rentalCost,
        rentalDescription: this.workOrder.rentalDescription
    })
    ).subscribe(() => this.doneSaving('work')
    , error =>
      this.messageService.add({
        severity: 'error',
        summary: 'Save Error',
        detail: 'Work order not saved. Please refresh and try again.',
        sticky: true
      }));
  }

  getWO(id: number) {
    if (id) {
      this.wellnessWorkOrderService.get(id).subscribe(wo => {
        this.workOrder = wo;
        this.canSaveAfterCompletion = this.canSave && this.workOrder.completionStatus === WellnessWorkOrderCompletionStatus.Completed
                                      && this.token.claimNames.includes('Edit Outside Costs');
        this.onTaskChange();
        this.isEditable = this.isEditable
                          && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed
                          && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Unable_to_be_Completed;
        this.canSave = (this.canSave
                        && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed
                        && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Unable_to_be_Completed)
                      || this.token.claimNames.includes('Add Task to Completed Work Order');

        this.setCanCompleteWO();
        this.canUndoWOCompletion = this.workOrder.completionStatus === WellnessWorkOrderCompletionStatus.Completed
                                   && this.token.claimNames.includes('Undo Work Order Completion')
                                   && this.workOrder.paymentStatus !== WellnessWorkOrderPaymentStatus.Paid;

        this.isLoading = false;
      }, error => {
        this.errorMessage = 'Could not load the work order. Please refresh and try again.';
        this.isLoading = false;
      });
    } else {
      const wo = new WellnessWorkOrder();
      wo.customer = new Customer();
      wo.customer.customerContacts = [
        new CustomerContact({
          receiveInvoicingEmails: true,
          receiveNonInvoicingEmails: true,
        })
      ];
      wo.customer.customerContacts[0].emailAddress = new EmailAddress();
      wo.customer.address = new Address();
      wo.address = new Address();
      wo.createdDate = new Date();
      wo.completionStatus = WellnessWorkOrderCompletionStatus.Ready_to_Schedule;
      wo.salesTaxPercentage = 0;
      wo.workOrderWellnessTasks = [];
      wo.workOrderPriceAdjustments = [];
      wo.salesTaxPercentage = 7.5; // default

      this.workOrder = wo;
      this.isEditable = this.isEditable && true;
      this.onTaskChange();

      this.isLoading = false;
    }
  }

  completeWO() {
    if (confirm('This will complete the work order. Do you want to continue?')) {
      if (this.invoiceAmtGreaterThanCashCollected()) {
        if (this.workOrder.workOrderWellnessTasks.every(wowt => wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed)) {
          this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Unable_to_be_Completed;
        } else {
          this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Completed;
          if (!this.workOrder.completedDate) {
            this.workOrder.completedDate = new Date();
          }
        }

        this.saving = true;
        this.isEditable = false;
        this.saveWO();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Cash Collected Discrepancy',
          detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2)  + ') to complete this work order.',
          sticky: true
        });
      }
    }
  }

  undoWOCompletion() {
    this.saving = true;
    const comment: string = this.commentForm.get('description').value;
    this.wellnessWorkOrderService.undoWorkOrderCompletion(this.workOrder.id, comment ? comment : 'Work order adjusted using uncomplete button', this.token.employeeId).subscribe(() => {
      this.closeUndoWOCompletion();
      this.getWO(this.workOrder.id);
      this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Notified;
      this.isLoading = true;
      this.workOrder = undefined;
      this.saving = false;
    }, error => {
      if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Undoing Completion',
          detail: JSON.parse(error.response).ExceptionMessage,
          sticky: true
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Undoing Completion',
          detail: 'An unexpected error occurred. Contact an admin for support.',
          sticky: true
        });
      }
    });
  }

  openUndoWOCompletion() {
    this.showUndoCompleteDialog = true;
  }

  closeUndoWOCompletion() {
    this.showUndoCompleteDialog = false;
  }

  invoiceAmtGreaterThanCashCollected(): boolean {
    const selectedTasks = this.workOrder.workOrderWellnessTasks.filter(x =>
      x.wellnessTask.active && x.wellnessTask.paymentStatus === WellnessTaskPaymentStatus.Not_Paid);
    const cashToInvoice = this.workOrder.workOrderCashCollected.filter(x => x.invoiced === false).reduce((a, b) => a + b.amount, 0);
    this.amountToInvoice = this.helper.totalAmountByTasks(this.workOrder, selectedTasks);
    if (this.amountToInvoice >= cashToInvoice) {
      return true;
    } else {
      return false;
    }
  }

  protected saveWO() {
    // Updating existing wellness WO
    this.wellnessWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
      .subscribe(wo => {
        this.saveBlobs(wo);
      }, error => {
        this.saving = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error Saving',
          detail: 'Could not save the work order, please check that all fields are valid and retry.'
        });
      });
  }

  private saveBlobs(wo: WellnessWorkOrder) {
    // Currently only 1 blob manager should be not view only. If that changes, you'll need to update this and loop through all the
    // ones that are not view only and save them.
    const blobManagerComponent: BlobManagerComponent = this.blobManagerComponents.find(blobComponent => blobComponent.viewOnly === false);

    if (blobManagerComponent) {
      blobManagerComponent.parentRecordId = wo.id;
      blobManagerComponent.save().subscribe(() => this.doneSaving('wellness'));
    } else {
      this.doneSaving('wellness');
    }
  }

  addNewWelnessTask() {
    this.displayWellnessTaskDialog = true;
  }

  copyWorkTask(wowt: WorkOrderWorkTask) {
    throw new Error('Cannot add a Work Task to a Wellness Work Order');
  }

  copyWellnessTask(wowt: WorkOrderWellnessTask) {
    this.selectedWellnessTask = new WellnessTask({
      active: true,
      renewable: false,
      renewalAccepted: wowt.wellnessTask.renewable,
      renewed: false,
      location: wowt.wellnessTask.location,
      wellnessTaskTreeTypes: wowt.wellnessTask.wellnessTaskTreeTypes.map(wttt => new WellnessTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType}) ),
      primordialTaskId: wowt.wellnessTask.id,
      priorityLevelId: wowt.wellnessTask.priorityLevelId,
      manualPriorityLevel: wowt.wellnessTask.manualPriorityLevel,
      priorityShortcutId: wowt.wellnessTask.priorityShortcutId,
      priorityTypeShortcut: wowt.wellnessTask.priorityTypeShortcut,
      hasCalculatedPriority: wowt.wellnessTask.hasCalculatedPriority,
      dueDateEnd: wowt.wellnessTask.dueDateEnd,
      dueDateStart: wowt.wellnessTask.dueDateStart,
      hardEndDate: wowt.wellnessTask.hardEndDate,
      hardStartDate: wowt.wellnessTask.hardStartDate,
      // schedulableAfterCompletionOf: wowt.wellnessTask.schedulableAfterCompletionOf,
      // schedulableAfterCompletionOfId: wowt.wellnessTask.schedulableAfterCompletionOfId,
      completionStatus: wowt.wellnessTask.completionStatus,
      wellnessTaskSkills: [],
      wellnessTaskEquipment: [],
      taskHourEntries: [],
      fixedPrice: true
    });
    this.displayWellnessTaskDialog = true;
  }

  editWellnessTask(wowt: WorkOrderWellnessTask) {
    this.selectedWellnessTask = wowt.wellnessTask;
    this.displayWellnessTaskDialog = true;
  }

  completeWowtWithoutPestRecord(wowt: WorkOrderWellnessTask) {
    const previousStatus = wowt.wellnessTask.completionStatus;
    const previousCompletedDate = wowt.wellnessTask.completedDate;
    const previousGoBackCompletedDate = wowt.wellnessTask.goBackCompletionDate;
    if (confirm('Are you sure this work order does not required a pesticide application record?')) {
      wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Completed;

      if (wowt.wellnessTask.isGoBack && !wowt.wellnessTask.goBackCompletionDate) {
        wowt.wellnessTask.goBackCompletionDate = new Date();
      } else if (!wowt.wellnessTask.completedDate) {
        wowt.wellnessTask.completedDate = new Date();
      }

      this.wellnessTaskService.updateCompletionStatus(wowt.wellnessTask).subscribe((wt) => {
        const index = this.workOrder.workOrderWellnessTasks.findIndex(x => x.wellnessTaskId === wt.id);
        this.workOrder.workOrderWellnessTasks[index].wellnessTask = wt;
        this.setCanCompleteWO();
        if (wt.paymentStatus === WellnessTaskPaymentStatus.Paid) {
          this.woTaskCards.filter(card => card.workOrderWellnessTask.id === wowt.id).forEach(card => {
            card.setUpStatusBar();
            card.canUndo = false;
          });
        }
      }, error => {
        if (wowt.wellnessTask.isGoBack && !wowt.wellnessTask.goBackCompletionDate) {
          wowt.wellnessTask.goBackCompletionDate = previousGoBackCompletedDate;
        } else if (!wowt.wellnessTask.completedDate) {
          wowt.wellnessTask.completedDate = previousCompletedDate;
        }
        wowt.wellnessTask.completionStatus = previousStatus;
        this.woTaskCards.filter(card => card.workOrderWellnessTask.id === wowt.id).forEach(card => {
          card.setUpStatusBar();
          card.canUndo = false;
        });
        this.messageWrapperService.addErrorMessage('Could not mark the task complete, please contact support.', error, true);
      });
    }
  }

  undoCompleteTask(wellnessTask: WellnessTask) {
    const index = this.workOrder.workOrderWellnessTasks.findIndex(x => x.wellnessTaskId === wellnessTask.id);

    this.workOrder.workOrderWellnessTasks[index].wellnessTask = wellnessTask;

    this.setCanCompleteWO();
  }

  fromWowtToPesticideApplicationRecord(wowt: WorkOrderWellnessTask) {
    const urlExt = 'wo' + this.workOrder.id + 'wt' + wowt.wellnessTaskId;
    this.router.navigateByUrl('pesticideApplicationRecordMaintenance/' + urlExt);
  }

  protected onTaskChange() {
    this.requiredSkills = this.getSkillsFromTasks();
    this.requiredEquipment = this.getEquipmentTypesFromTasks();
    this.setCanCompleteWO();
  }

  protected cancelledTask(task: WellnessTask) {
    if (task instanceof WellnessTask && task.id) {
      this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTaskId === task.id).wellnessTask = task;
    } else if (!task) {
      if (this.selectedWellnessTask && this.selectedWellnessTask.id) {
        this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTaskId === this.selectedWellnessTask.id).wellnessTask = (this.wellnessTaskMaintenance.taskUnmodified as WellnessTask);
      }
    }

    this.displayWellnessTaskDialog = false;
    this.displayWorkTaskDialog = false;
    this.selectedWellnessTask = undefined;

    this.onTaskChange();
  }

  protected closedDialog(task: WellnessTask) {
    if (task && task.id) {
      this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTask.id === task.id).wellnessTask = task;
    }

    this.displayWellnessTaskDialog = false;
    this.selectedWellnessTask = undefined;
    this.onTaskChange();
  }

  getEquipmentTypesFromTasks() {
    let wellnessEquipment: EquipmentType[] = [];
    this.workOrder.workOrderWellnessTasks.forEach(wowt => {
      wellnessEquipment = wellnessEquipment.concat(wowt.wellnessTask.wellnessTaskEquipment.map(wte => wte.equipmentType));
    });

    return this.helper.getUnique(wellnessEquipment);
  }

  getSkillsFromTasks() {
    let wellnessSkills: Skill[] = [];

    this.workOrder.workOrderWellnessTasks.forEach(wowt => {
      wellnessSkills = wellnessSkills.concat(wowt.wellnessTask.wellnessTaskSkills.map(wts => wts.skill));
    });

    return this.helper.getUnique(wellnessSkills);
  }

  onMyWay() {
    if (confirm('This will send the customer an email or SMS' +
    ' message letting them know you are on your way to do this job. Click “OK” to proceed with this notification to the customer.')) {
      this.notificationService.sendWellnessWorkOrderOnWayNotification(this.workOrder.id).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Message Sent',
          detail: 'Customer was notified Russell Tree is en route.'
        });
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Sending Message',
          detail: 'Could not enqueue message to be sent. Please try again.'
        });
      });
    }
  }

  unableToCompleteWorkTask(wellnessTask: WellnessTask) {
    const index = this.workOrder.workOrderWellnessTasks.findIndex(x => x.wellnessTaskId === wellnessTask.id);
    this.workOrder.workOrderWellnessTasks[index].wellnessTask = wellnessTask;

    this.setCanCompleteWO();
  }

  setCanCompleteWO() {
    // canCompleteWO is permissions based, WO's stage cannot already be complete or unable to complete, and ALL tasks must be complete or unable to complete
    this.canCompleteWO = this.canComplete && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed
      && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Unable_to_be_Completed
      && this.workOrder.workOrderWellnessTasks.every(woTask =>
        woTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed ||
        woTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed);
  }

  duplicateToQuote() {
    if (confirm('This will create a new Quote based on this WO. Please select "Ok" to continue.')) {
      // this.saving = true;
      this.quoteService.duplicateWellnessToQuote(this.workOrder.id, false).subscribe(id => this.router.navigateByUrl('quoteWorkOrderMaintenance/' + id));
    }
  }
}
