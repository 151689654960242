import { Component, OnInit } from '@angular/core';
import { Employee,
  EmployeeGenSvc,
  RegionalManagerTimeSlot,
  RegionalManagerTimeSlotGenSvc } from '../../../services_autogenerated/generated_services';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-day-zip-code-assignment',
  templateUrl: './day-zip-code-assignment.component.html',
  styleUrls: ['./day-zip-code-assignment.component.css']
})
export class DayZipCodeAssignmentComponent implements OnInit {
  employee: Employee;
  daysOfTheWeek = [ { number: 0, string: 'Sunday' },
    { number: 1, string: 'Monday' },
    { number: 2, string: 'Tuesday' },
    { number: 3, string: 'Wednesday' },
    { number: 4, string: 'Thursday' },
    { number: 5, string: 'Friday' },
    { number: 6, string: 'Saturday' }
  ];

  sundayTimeSlots: RegionalManagerTimeSlot[];
  mondayTimeSlots: RegionalManagerTimeSlot[];
  tuesdayTimeSlots: RegionalManagerTimeSlot[];
  wednesdayTimeSlots: RegionalManagerTimeSlot[];
  thursdayTimeSlots: RegionalManagerTimeSlot[];
  fridayTimeSlots: RegionalManagerTimeSlot[];
  saturdayTimeSlots: RegionalManagerTimeSlot[];

  timeSlotsByDay: RegionalManagerTimeSlot[][] = [[]];

  employeeIsRM: boolean;

  constructor(private route: ActivatedRoute,
    private employeeService: EmployeeGenSvc,
    private timeSlotService: RegionalManagerTimeSlotGenSvc,
    private messageService: MessageService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.employeeService.get(id).subscribe(emp => {
      this.employeeIsRM = emp.employeeRoles.map(er => er.role).some(r => r.name === 'Regional Manager');
      this.employee = emp;

      // I dont' really like having to have the page work this way
      // but I couldn't get it to display right when I tried to use an array of arrays for the week
      // So I just broke it up like this to get it working at least, we can make it better later
      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 0)) {
        this.sundayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 0);
      } else {
        this.sundayTimeSlots = [];
      }

      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 1)) {
        this.mondayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 1);
      } else {
        this.mondayTimeSlots = [];
      }

      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 2)) {
        this.tuesdayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 2);
      } else {
        this.tuesdayTimeSlots = [];
      }

      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 3)) {
        this.wednesdayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 3);
      } else {
        this.wednesdayTimeSlots = [];
      }

      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 4)) {
        this.thursdayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 4);
      } else {
        this.thursdayTimeSlots = [];
      }

      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 5)) {
        this.fridayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 5);
      } else {
        this.fridayTimeSlots = [];
      }

      if (emp.regionalManagerTimeSlots.some(timeSlot => timeSlot.dayOfTheWeek === 6)) {
        this.saturdayTimeSlots = emp.regionalManagerTimeSlots.filter(timeSlot => timeSlot.dayOfTheWeek === 6);
      } else {
        this.saturdayTimeSlots = [];
      }
    });
  }

  private createNewTimeSlotArray(day: number): RegionalManagerTimeSlot[] {
    const newTimeSlotArray: RegionalManagerTimeSlot[] = [];

    newTimeSlotArray.push(this.createNewTimeSlot(day));

    return newTimeSlotArray;
  }

  private createNewTimeSlot(day: number): RegionalManagerTimeSlot {
    // Default times to 8AM for beginning and 4PM for ending
    const begin: Date = new Date();
    begin.setHours(8);
    begin.setMinutes(0);
    const end: Date = new Date();
    end.setHours(16);
    end.setMinutes(0);

    return new RegionalManagerTimeSlot({
      employeeId: this.employee.id,
      dayOfTheWeek: day,
      timeBegin: begin,
      timeEnd: end,
      regionalManagerTimeSlotZipCodes: []
    });
  }

  addNewTimeSlot(day: number) {
    switch (day) {
      case 0: {
        this.sundayTimeSlots ? this.sundayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlot(day);
        break;
      }
      case 1: {
        this.mondayTimeSlots ? this.mondayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlotArray(day);
        break;
      }
      case 2: {
        this.tuesdayTimeSlots ? this.tuesdayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlotArray(day);
        break;
      }
      case 3: {
        this.wednesdayTimeSlots ? this.wednesdayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlotArray(day);
        break;
      }
      case 4: {
        this.thursdayTimeSlots ? this.thursdayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlotArray(day);
        break;
      }
      case 5: {
        this.fridayTimeSlots ? this.fridayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlotArray(day);
        break;
      }
      case 6: {
        this.saturdayTimeSlots ? this.saturdayTimeSlots.push(this.createNewTimeSlot(day)) : this.createNewTimeSlotArray(day);
        break;
      }
    }
  }

  handleDeletedItem(timeSlot: RegionalManagerTimeSlot, timeSlotArray: RegionalManagerTimeSlot[]) {
    const index: number = timeSlotArray.findIndex(item => item === timeSlot);
    // Remove from JS
    timeSlotArray = timeSlotArray.splice(index, 1);

    // Remove from DB
    this.timeSlotService.delete(timeSlot, timeSlot.id.toString()).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Delete Successful',
        detail: 'The time slot was deleted successfully.'
      });
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Could not delete the time slot, please refresh the page and try again.'
      });
    });
  }
}
