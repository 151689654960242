import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BigDayBucketSubrange, BigDayEmployee, BigDayEquipment, BigDayBucketSubrangeGenSvc, Equipment, Employee, BigDayBucket } from 'src/app/services_autogenerated/generated_services';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MultidayDragAndDropService } from 'src/app/services/multiday-drag-and-drop.service';

@Component({
  selector: 'app-multiday-subrange-card',
  templateUrl: './multiday-subrange-card.component.html',
  styleUrls: ['./multiday-subrange-card.component.css']
})
export class MultidaySubrangeCardComponent implements OnInit {
  @Output() deleted = new EventEmitter<BigDayBucketSubrange>();
  @Input() bigDayBucketSubrange: BigDayBucketSubrange;
  @Input() bigDayBucket: BigDayBucket;

  bigDayEmployees: BigDayEmployee[];
  bigDayEquipment: BigDayEquipment[];

  constructor(private bigDayBucketSubrangeService: BigDayBucketSubrangeGenSvc,
    public multidayDragAndDropService: MultidayDragAndDropService
  ) { }

  ngOnInit() {
    this.bigDayEmployees = this.bigDayBucketSubrange.bigDayEmployees;
    this.bigDayEquipment = this.bigDayBucketSubrange.bigDayEquipment;
    this.multidayDragAndDropService.addSubrangeToCDKLists(this.bigDayBucketSubrange);
  }

  drop(event) {
    this.multidayDragAndDropService.dropOntoSubrange(event, this.bigDayBucketSubrange);
  }

  onDelete() {
    this.deleted.next(this.bigDayBucketSubrange);
  }

  deleteEmployeeFromBigDayBucketSubrange(employee: Employee) {
    this.bigDayBucketSubrange.bigDayEmployees.splice(this.bigDayBucketSubrange.bigDayEmployees
      .findIndex(x => x.employeeId === employee.id), 1);
    this.bigDayEmployees = this.bigDayBucketSubrange.bigDayEmployees;
  }

  deleteEquipmentFromBigDayBucketSubrange(equipment: Equipment) {
    this.bigDayBucketSubrange.bigDayEquipment.splice(this.bigDayBucketSubrange.bigDayEquipment
      .findIndex(x => x.equipmentId === equipment.id), 1);
    this.bigDayEquipment = this.bigDayBucketSubrange.bigDayEquipment;
  }

}
