import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Crew, CrewEmployee, Equipment, Employee, CrewEquipment, CrewGenSvc } from 'src/app/services_autogenerated/generated_services';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CardTitleHelperService } from 'src/app/services/card-title-helper.service';
import { CrewDragAndDropService } from 'src/app/services/crew-drag-and-drop.service';

@Component({
  selector: 'app-crew-creation-card',
  templateUrl: './crew-creation-card.component.html',
  styleUrls: ['./crew-creation-card.component.css']
})
export class CrewCreationCardComponent implements OnInit {
  /**
   * This crew card is for the crew creation page, it has deliable employees/equipment, a date range,
   * and has drag and drop cabilities for employees/equipment.
   * Basically, this card is used to create/edit crews.
   * WARNING: If you make a change to this card, check the other crew card to see if it needs the change as well.
   */
  @Input() crew: Crew;

  @Output() deleted = new EventEmitter<Crew>();

  isCollapsed = false;
  crewTitle: string;
  employeeList: string;
  crewErrors: string[] = [];

  constructor(public dragAndDropService: CrewDragAndDropService,
    public titleHelperService: CardTitleHelperService,
    private crewService: CrewGenSvc) { }

  ngOnInit() {
    this.setCrewForemanAndEquipment();
    this.validateCrew();
  }

  onCrewUpdate() {
    this.validateCrew();
    this.setCrewForemanAndEquipment();
  }

  dragStart() {
    this.dragAndDropService.setDragStartCrew(this.crew);
  }

  drop(event: CdkDragDrop<Equipment[] | Employee[] | CrewEquipment[] | CrewEmployee[]>) {
    this.dragAndDropService.dropOntoCrewCard(event, this.crew);
  }

  save() {
    this.crewService.update(this.crew, this.crew.id.toString()).subscribe(() => {
      this.validateCrew();
      this.setCrewForemanAndEquipment();
    });
  }

  delete() {
    this.deleted.emit(this.crew);
  }

  deleteEmployeeFromCrew(employee: Employee) {
    this.crew.crewEmployees.splice(this.crew.crewEmployees.findIndex(ce => ce.employeeId === employee.id), 1);
    this.save();
  }

  deleteEquipmentFromCrew(equipment: Equipment) {
    this.crew.crewEquipment.splice(this.crew.crewEquipment.findIndex(ce => ce.equipmentId === equipment.id), 1);
    this.save();
  }

  validateCrew() {
    this.crewErrors = [];

    if (!this.crew.crewEquipment || this.crew.crewEquipment.length < 1) {
      this.crewErrors.push('No equipment');
    }

    if (!this.crew.crewEmployees) {
      this.crewErrors.push('No employees');
    }

    if (this.crew.crewEmployees && !this.crew.crewEmployees.find((e) => e.employee.employeeRoles
        .some((r) => r.role.name.toLowerCase().includes('foreman')))) {
      this.crewErrors.push('No foreman');
    }

    if (this.crew.startDate > this.crew.endDate) {
      this.crewErrors.push('Crew cannot end before it starts');
    }
  }

  setCrewForemanAndEquipment() {
    this.employeeList = this.titleHelperService.generateEmployeeTitle(this.crew.crewEmployees.map(sbe => sbe.employee));

    this.crewTitle = this.titleHelperService.generateTitle(this.crew.crewEquipment.map(sbe => sbe.equipment),
      this.crew.crewEmployees.map(sbe => sbe.employee));
  }
}
