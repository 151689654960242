<div class="ui-g-12 columnPadding">
  <div class="ui-g">
    <div class="ui-g-12">
      <h4>Billing Info</h4>
    </div>
  </div>
  <div class="ui-g-12">
    <!-- TODO: different wording if they wanna pay with cash/credit? -->
    <!-- <i>
      Your 
      <span *ngIf="!stripeBilling || stripeBilling?.cCLast4">credit card</span>
      <span *ngIf="stripeBilling?.aCHLast4">bank account</span> 
      on file will be charged the next business day after the completion of each service
    </i> -->
    <div>
      <span *ngIf="stripeBilling?.cCLast4" style="margin-right:4px;">{{stripeBilling.cCBrand}} Ending in {{getLast4Display(stripeBilling.cCLast4)}}</span>
      <span *ngIf="stripeBilling?.aCHLast4" style="margin-right:4px;">{{stripeBilling.bankName}} Ending in {{getLast4Display(stripeBilling.aCHLast4)}}</span>
      <span [class]="hasBillingAddEditPermission() ? 'link' : 'link disabledLink'" (click)="openBillingProfileEntryModal()">{{ stripeBilling ? '[EDIT]' : '[ADD]'}}</span>
    </div>
  </div>
</div>
<app-billing-profile-entry-modal 
  *ngIf="displayBillingProfileEntryModal" 
  [customer]="customer" 
  [stripeBilling]="stripeBilling"
  [display]="displayBillingProfileEntryModal" 
  (emitUpdatedStripeCustomerId)="updatedStripeCustomer($event)" 
  (emitClose)="closeBillingProfileEntryModal()">
</app-billing-profile-entry-modal>