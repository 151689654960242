import { Component, OnInit, Input } from '@angular/core';
import { Equipment, Employee, BigDayEmployee, BigDayEquipment } from 'src/app/services_autogenerated/generated_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unavailable-equipment-and-employees',
  templateUrl: './unavailable-equipment-and-employees.component.html',
  styleUrls: ['./unavailable-equipment-and-employees.component.css']
})
export class UnavailableEquipmentAndEmployeesComponent implements OnInit {
  @Input() bigEmployees: BigDayEmployee[];
  @Input() bigEquipment: BigDayEquipment[];
  @Input() type: 'wellness' | 'work';

  clickTime = 0;
  employees: Employee[];
  eqpNumbers: string[];
  equipment: Equipment[];

  constructor(private router: Router) { }

  ngOnInit() {
    this.equipment = this.bigEquipment.map((eqp) => eqp.equipment);
    this.employees = this.bigEmployees.map((emp) => emp.employee);
    this.employees.sort((a, b) => (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0));
    this.eqpNumbers = this.equipment.map(x => x.number);
    this.eqpNumbers.sort();
  }

  // Double click doesn't work on mobile
  // https://stackoverflow.com/questions/50752562/angular-4-dblclick-event-not-working-in-mobile-view
  dblclickHandler() {
    if (!this.clickTime) {
      // set first click
      this.clickTime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (((new Date().getTime()) - this.clickTime) < 600) {
        // double click occurred
        this.onDoubleClick();
        this.clickTime = 0;
      } else {
        // not a double click so set as a new first click
        this.clickTime = new Date().getTime();
      }
    }
  }

  onDoubleClick() {
    if (confirm('Would you like to redirect to the multiday scheduling page?')) {
      this.router.navigateByUrl(`scheduling/multiday/${this.type}`);
    }
  }

}
