<div>
  <form #checkout="ngForm"> 
    <div class="ui-g ui-g-12" style="padding-top:0px;">
      <div *ngIf="isFirmtime" class="ui-g-12">
        <div>
          To book your firm-time quote with an ISA Certified Arborist, please provide your credit/debit card information below to be <b>charged immediately</b>. 
          By clicking the button below you authorize Russell Tree Experts to store your card through Stripe, a secure merchant service provider.
        </div>
      </div>
      <div *ngIf="!isPayInAdvance && !isCustomerProfile && !isFirmtime" class="ui-g-12">
        <!-- <div style="margin-top:12px;">
          <p-checkbox *ngIf="showChargePermissionMessage" binary="true" [(ngModel)]="paymentAuthorization" name="paymentAuthorization" required></p-checkbox> -->
          <div style="text-align: center;">
            To reserve your services on our production schedule a major credit card is required.
            <br/>
            <span class="bold">Your card will not be charged at this time.</span>
            <!-- Your credit card will only be charged once services are complete
             and to your satisfaction. If you wish to pay by check or cash, please select the box below and provide payment within 5 days of service completion. -->
          </div>  
        <!-- </div> --> 
      </div>
      <div *ngIf="isPayInAdvance && !isCustomerProfile" class="ui-g-12" style="text-align: center;">
        <span>To reserve your space on our production schedule please provide your credit/debit card information below to be 
          <b>charged immediately</b> for the full price of the quote. By clicking the “Pay” below I authorize Russell Tree Experts to store my card through Stripe, 
          a secure merchant service provider.</span>
      </div>
      
      <div class="card-header">
        Credit/Debit Card Info
      </div>
      <div class="card-body grayBG">
        <!-- Credit Card Fields --> 
        <div class="full-width" [style.display]="paymentType != PaymentType.ACH ? 'block': 'none'">
          <div class="ui-g-12 ui-lg-8">
            <h6>Card Number</h6>
            <div id="cardNumber1" pInputText class="inputTextBox" #cardNumber [style.display]="renderSavedCard == false ? 'block' : 'none'"></div>
            <input pInputText class="form-control inputTextBox" name="last4" [value]="'xxxx-xxxx-xxxx-' + stripeBilling.cCLast4" *ngIf="renderSavedCard" disabled/>
            <div *ngIf="(renderSavedCard || updateWithNewCard)"> 
                <a href="#" (click)="addNewCard($event); clearErrors();" style="cursor:pointer" *ngIf="renderSavedCard">Enter a different card to use</a>
                <a href="#" (click)="cancelAddNewCard($event); clearErrors();" style="cursor:pointer" *ngIf="!renderSavedCard">Cancel</a>
            </div>
          </div>
          <div class="ui-g-6 ui-lg-2">
            <h6>Expiration</h6>
            <div id="cardExpiry1" pInputText class="inputTextBox" #cardExpiry [style.display]="renderSavedCard == false ? 'block' : 'none'"></div>
            <input id="expiration" [textMask]="{mask: ccExpirationMask}" pInputText class="form-control inputTextBox" name="exp" [(ngModel)]="stripeBilling.expiration" placeholder="MM/YY" (ngModelChange)="clearErrors();;removeExpirationError();" *ngIf="renderSavedCard" disabled/>
          <!---->
          </div>
          <div class="ui-g-6 ui-lg-2">
            <h6>CVC</h6>
            <div id="cardCvc1" pInputText class="inputTextBox" #cardCvc [style.display]="renderSavedCard == false ? 'block' : 'none'"></div>
            <input pInputText class="form-control inputTextBox" name="cvc" value="xxx" *ngIf="renderSavedCard" disabled/>
          </div>
        </div>
        <!-- Billing Address Fields -->
        <div class="ui-g-12" *ngIf="isCustomerProfile">
          <p-checkbox binary="true" name="sameaddress" [(ngModel)]="sameAddressAsContact" (click)="onToggleSameAddressCheckbox()"></p-checkbox>
          <span style="margin-left:8px;">Same as Billing Address</span>
        </div>
        <div class="ui-g-12 ui-lg-10">
          <h6>Billing Address</h6>
          <input id="address" pInputText class="inputTextBox" [(ngModel)]="stripeBilling.address" name="address" (ngModelChange)="clearErrors(); onAddresChange()" [required]="true" placeholder="Address"/>
        </div>
        <div class="ui-g-3 ui-lg-2">
          <h6>Zip</h6>
          <input id="zip" pInputText class="inputTextBox" [(ngModel)]="stripeBilling.zip" name="address_zip" (ngModelChange)="clearErrors();" (change)="removeZipError()" [required]="true" type="text" pattern="[0-9]*" placeholder="ZIP"/>
        </div> 
        <div class="ui-g-12" style="text-align: right;">
          <img src="../../../assets/Stripe.png" height="25px">
        </div>
      </div>
      
      <!-- Manual cash/check Option-->
      <div *ngIf="!isPayInAdvance && paymentTypeEnabled(PaymentType.Manual) && !isQuote" class="ui-g-12">
        <p-checkbox *ngIf="customer" binary="true" [(ngModel)]="customer.manualPayment" name="manualPay"></p-checkbox>
        <span style="margin-left:8px;">Pay by Cash or Check<span *ngIf="isCustomerProfile">*</span></span>
        <div *ngIf="isCustomerProfile"> 
          <span style="font-size:12px">*(Please note that a credit/debit card is required to reserve your space on our production schedule even if you intend to pay by cash or check.)</span>
        </div>
      </div>
      <div *ngIf="!isPayInAdvance && !isCustomerProfile && !isFirmtime" class="ui-g-12">
        By clicking the “Save” button below you authorize Russell Tree Experts to store your card through Stripe, a secure merchant service provider. 
        <span *ngIf="!isQuote">Customer acknowledges the payment(s) will be processed the next business day following the completion of each service if “Pay by Cash or Check” is not selected.</span>
      </div>
    </div>
  </form>
</div>