import { Equipment, Employee, CrewEquipment, CrewEmployee, CrewGenSvc } from '../services_autogenerated/generated_services';
import { transferArrayItem } from '@angular/cdk/drag-drop';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../services_autogenerated/generated_services";
var CrewDragAndDropService = /** @class */ (function () {
    function CrewDragAndDropService(crewService) {
        this.crewService = crewService;
        this.crewUpdater = new BehaviorSubject(null);
        //////////////////////////////////////////////////////////////////////////////////////////
        // Crew Creation drag and drop methods
        //////////////////////////////////////////////////////////////////////////////////////////
        this.allCrewEmployeeLists = [];
        this.allCrewEquipmentLists = [];
        this.addCrewCreationLists();
    }
    CrewDragAndDropService.prototype.setCrews = function (crews) {
        this.crews = crews;
    };
    CrewDragAndDropService.prototype.getCrewUpdater = function () {
        return this.crewUpdater.asObservable();
    };
    CrewDragAndDropService.prototype.emitCrewUpdate = function (crew) {
        this.crewUpdater.next(crew);
    };
    CrewDragAndDropService.prototype.setDragStartCrew = function (previousCrew) {
        this.dragStartCrew = previousCrew;
    };
    CrewDragAndDropService.prototype.addCrewCreationLists = function () {
        this.allCrewEmployeeLists.push('employeeList');
        this.allCrewEquipmentLists.push('equipmentList');
    };
    CrewDragAndDropService.prototype.addCrewLists = function (crew) {
        this.allCrewEmployeeLists.push('crewEmployeeList-' + crew.id);
        this.allCrewEquipmentLists.push('crewEquipmentList-' + crew.id);
    };
    CrewDragAndDropService.prototype.dropOntoCrewCard = function (event, crew) {
        console.log('DROP ONTO CREW');
        var droppedItem = event.previousContainer.data[event.previousIndex];
        if (event.previousContainer === event.container) {
            // Do not allow movement in the array
        }
        else if (droppedItem instanceof Employee && crew.crewEmployees.find(function (e) { return e.employee.id === droppedItem.id; })) {
            // bucket already has that employee, do not duplicate it.
        }
        else if (droppedItem instanceof Equipment && crew.crewEquipment.find(function (e) { return e.equipment.id === droppedItem.id; })) {
            // bucket already has the equipmnet, do not duplicate it.
        }
        else if (droppedItem instanceof CrewEquipment || droppedItem instanceof CrewEmployee) {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        else if (droppedItem instanceof Employee) {
            var newCrewEmployee = new CrewEmployee();
            newCrewEmployee.employeeId = droppedItem.id;
            newCrewEmployee.employee = droppedItem;
            newCrewEmployee.crewId = crew.id;
            crew.crewEmployees.push(newCrewEmployee);
        }
        else if (droppedItem instanceof Equipment) {
            var newCrewEquipment = new CrewEquipment();
            newCrewEquipment.equipmentId = droppedItem.id;
            newCrewEquipment.equipment = droppedItem;
            newCrewEquipment.crewId = crew.id;
            crew.crewEquipment.push(newCrewEquipment);
        }
        this.updateCrewInDatabase(crew);
        if (crew && this.dragStartCrew && this.dragStartCrew.id !== crew.id) {
            this.updateCrewInDatabase(this.dragStartCrew);
        }
    };
    CrewDragAndDropService.prototype.dropOntoEmployeeList = function (event) {
        console.log('DROP ONTO EMPLOYEE LIST');
        var droppedItem = event.previousContainer.data[event.previousIndex];
        if (droppedItem instanceof CrewEmployee) {
            if (this.dragStartCrew) {
                this.dragStartCrew.crewEmployees.splice(this.dragStartCrew.crewEmployees.findIndex(function (ce) { return ce.employeeId === droppedItem.id; }));
                this.updateCrewInDatabase(this.dragStartCrew);
            }
        }
    };
    CrewDragAndDropService.prototype.dropOntoEquipmentList = function (event) {
        console.log('DROP ONTO EQUIPMENT LIST');
        var droppedItem = event.previousContainer.data[event.previousIndex];
        if (this.dragStartCrew) {
            this.dragStartCrew.crewEquipment.splice(this.dragStartCrew.crewEquipment.findIndex(function (ce) { return ce.equipmentId === droppedItem.id; }));
            this.updateCrewInDatabase(this.dragStartCrew);
        }
    };
    CrewDragAndDropService.prototype.updateCrewInDatabase = function (crew) {
        this.crewService.update(crew, crew.id.toString()).subscribe();
        this.emitCrewUpdate(crew);
    };
    CrewDragAndDropService.ngInjectableDef = i0.defineInjectable({ factory: function CrewDragAndDropService_Factory() { return new CrewDragAndDropService(i0.inject(i1.CrewGenSvc)); }, token: CrewDragAndDropService, providedIn: "root" });
    return CrewDragAndDropService;
}());
export { CrewDragAndDropService };
