import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { PaymentType } from 'src/app/models/paymentType';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { CustomerGenSvc, SystemSettingGenSvc } from 'src/app/services_autogenerated/generated_services';
import { StripeBillingComponent } from '../../stripe-billing/stripe-billing.component';
import { BasePaymentProcessingComponent } from '../../base-payment-processing/base-payment-processing.component';
import { QuoteRequest, QuoteWorkOrder, QuoteRequestType, QuoteRequestGenSvc, StripeGenSvc, ChargeFirmtimeRequest } from '../../../services_autogenerated/generated_services';
import { ActivatedRoute } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { AppConfigService } from 'src/app/services/app-config.service';
var QuotePaymentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QuotePaymentComponent, _super);
    function QuotePaymentComponent(behaviorSubjectService, scriptLoaderService, customStripeService, customerService, quoteRequestService, route, authService, configService, systemSettingsService) {
        var _this = _super.call(this, behaviorSubjectService, scriptLoaderService, customStripeService) || this;
        _this.customerService = customerService;
        _this.quoteRequestService = quoteRequestService;
        _this.route = route;
        _this.authService = authService;
        _this.configService = configService;
        _this.systemSettingsService = systemSettingsService;
        _this.QuoteRequestType = QuoteRequestType;
        _this.maxWidth = '800px';
        _this.showManualPaymentChargeMessage = false;
        _this.billingSaved = new EventEmitter();
        _this.closed = new EventEmitter();
        _this.convertQuote = new EventEmitter();
        _this.saving = false;
        _this.submitLabel = '';
        _this.paymentTypes = [PaymentType.Credit, PaymentType.ACH, PaymentType.Manual];
        _this.isQuoteRequest = false;
        _this.showLeaveConfirm = true;
        _this.quoteStep = 1;
        _this.hasToggledPayment = false;
        return _this;
    }
    QuotePaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.systemSettingsService.getManualPaymentDaysDue().subscribe(function (res) { return _this.numberOfDaysForCash = parseFloat(res.value); });
        this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
        this.amountString = this.amount.toFixed(2);
        this.getQuote();
        this.getCustomer();
        this.setupPlaid();
        this.isQuoteRequest = this.quoteIsRequest(this.quote);
        // console.log('ISQuoteRequest: ');
        // console.log(this.isQuoteRequest);
        if (this.isQuoteRequest) {
            this.forcePaymentType(PaymentType.Credit);
        }
        // var showAlert = true;
        // document.addEventListener("mouseleave", function(event){
        //   if((event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) && showAlert)
        //   {
        //     showAlert = false;
        //     alert('Are you sure you want to leave this page? If you don’t provide your credit/debit card information your services will not be in our queue to be completed.');
        //   }
        // });
    };
    QuotePaymentComponent.prototype.getCustomer = function () {
        var _this = this;
        var token = this.authService.getDecodedAccessToken();
        if (token) {
            this.customerService.get(+this.customerId).subscribe(function (customer) {
                _this.customer = customer;
            });
        }
        else {
            this.customerService.getAnonymous(+this.customerId).subscribe(function (customer) {
                _this.customer = customer;
            });
        }
    };
    QuotePaymentComponent.prototype.getQuote = function () {
        var _this = this;
        // console.log('the quote: ');
        // console.log(this.quote);
        if (!this.quote) {
            var quoteId = +this.route.snapshot.paramMap.get('id');
            this.quoteRequestService.get(quoteId)
                .subscribe(function (quote) {
                _this.quote = quote;
                // if (quote.quoteRequestType == QuoteRequestType.Firmtime) {
                //   this.amount = 100;
                // } else {
                // }
            });
        }
    };
    QuotePaymentComponent.prototype.processBillingRequest = function (token) {
        if (this.isQuoteRequest) {
            var quote = (this.quote);
            if (quote.quoteRequestType === QuoteRequestType.Firmtime) {
                this.processFirmtimeQuoteRequestBilling(token);
            }
            else {
                // this.processAnytimeQuoteRequestBilling(token);
            }
        }
        else {
            this.processQuoteBilling(token);
        }
    };
    QuotePaymentComponent.prototype.quoteIsRequest = function (quote) {
        return this.quote.constructor === QuoteRequest;
    };
    QuotePaymentComponent.prototype.forcePaymentType = function (paymentTypeToForce) {
        this.paymentTypes = [PaymentType.Credit];
    };
    // processAnytimeQuoteRequestBilling(token: string) {
    //   this.customStripeService.addOrUpdateAndAcceptAnytimeRequest(this.customer.id, token,
    //        this.stripeBillingComponent.stripeBilling, this.quote.id, true)
    //   .subscribe((stripeCustomerId) => {
    //     this.afterBillingSave();
    //   },(error) => {
    //     this.onServerError(error);
    //   });
    // }
    QuotePaymentComponent.prototype.processFirmtimeQuoteRequestBilling = function (token) {
        var _this = this;
        var request = new ChargeFirmtimeRequest();
        request.customerId = this.customer.id;
        request.billingToken = token;
        request.stripeBilling = this.stripeBillingComponent.stripeBilling;
        request.quoteId = this.quote.id;
        request.eventStart = (this.quote).eventStart;
        this.customStripeService.addOrUpdateAndChargeFirmtimeRequest(request)
            .subscribe(function (stripeCustomerId) {
            _this.afterBillingSave();
        }, function (error) {
            _this.onServerError(error);
        });
    };
    // ,(error) => {
    //   this.onError(error);
    // });
    QuotePaymentComponent.prototype.onError = function (event) {
        this.saving = false;
        if (event) {
            if (event.response) {
                var eventResponse = JSON.parse(event.response);
                this.error = eventResponse.ExceptionMessage ? eventResponse.ExceptionMessage : eventResponse.Message;
            }
            else {
                this.error = event.message;
            }
        }
        else {
            this.error = null;
        }
    };
    QuotePaymentComponent.prototype.onServerError = function (event) {
        this.saving = false;
        if (event) {
            if (event.response) {
                var eventResponse = JSON.parse(event.response);
                this.serverError = eventResponse.ExceptionMessage ? eventResponse.ExceptionMessage : eventResponse.Message;
            }
            else {
                this.serverError = event.message;
            }
        }
        else {
            this.serverError = null;
        }
    };
    QuotePaymentComponent.prototype.afterBillingSave = function () {
        console.log('billing saved emit 1');
        if (this.quote instanceof QuoteWorkOrder && this.quoteStep === 1) {
            this.quoteStep = 2;
            this.saving = false;
        }
        else if (this.quote instanceof QuoteWorkOrder && this.quoteStep === 3) {
            this.quoteStep = 4;
            this.billingSaved.emit();
        }
        else if (this.quote instanceof QuoteRequest) {
            this.billingSaved.emit();
        }
        else {
            this.closed.emit();
        }
    };
    QuotePaymentComponent.prototype.processQuoteBilling = function (token) {
        var _this = this;
        if (this.quote instanceof QuoteWorkOrder && this.quoteStep === 1) {
            this.quoteStep = 2;
            this.saving = false;
            this.convertQuote.emit();
        }
        else {
            this.customStripeService.addOrUpdateAndApproveQuote(this.quote.customerId, token, this.stripeBillingComponent.stripeBilling, this.quote.id, this.stripeBillingComponent.customer.manualPayment, true)
                .subscribe(function (stripeCustomerId) {
                _this.afterBillingSave();
            }, function (error) {
                _this.onServerError(error);
            });
        }
    };
    QuotePaymentComponent.prototype.toggleManualPayment = function (isManual) {
        this.customer.manualPayment = isManual;
        this.hasToggledPayment = true;
        this.quoteStep = 3;
    };
    QuotePaymentComponent.prototype.onSubmit = function () {
        this.serverError = null;
        if (!this.error) {
            if (!this.stripeBillingComponent.checkFormError()) {
                if (this.stripeBillingComponent.paymentType === PaymentType.Credit) {
                    this.submitCard();
                }
                else if (this.stripeBillingComponent.paymentType === PaymentType.ACH) {
                    this.submitACH();
                }
            }
        }
    };
    // Again, I don't really like this work around
    // But Quotes do not have a property that is needed for QuoteRequests
    // So pass the variable back as a QR to get at that property
    QuotePaymentComponent.prototype.asQuoteRequestNotQuote = function () {
        return this.quote;
    };
    return QuotePaymentComponent;
}(BasePaymentProcessingComponent));
export { QuotePaymentComponent };
