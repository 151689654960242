<div id="datePicker">
  <label for="startDate">Start Date: &nbsp;</label>
  <p-calendar id="startDate" [(ngModel)]="startDate" [showIcon]="true" (ngModelChange)="onDateChange()"></p-calendar>
</div>

<hr>

<div *ngIf="colSize === 0" id="twoDayCalendar" class="p-grid">
  <div class="p-col" *ngFor="let day of week; let i=index">
    <p-card [style]="{height: '100%', minHeight: '50em'}">
      <p-header>
        {{day | date: 'EEEE M/d' | uppercase}}
      </p-header>
      <div *ngIf="!scheduledBucketWeek || !bigDayBucketSubrangeWeek"><app-loading-swirly [isLoading]="!scheduledBucketWeek || !bigDayBucketSubrangeWeek"></app-loading-swirly></div>
      <div *ngIf="scheduledBucketWeek">
        <app-my-schedule-crew-card class="scheduled-item" *ngFor="let bucket of scheduledBucketWeek[i]"
          [inputBucket]="bucket">
        </app-my-schedule-crew-card>
      </div>
      <div *ngIf="bigDayBucketSubrangeWeek">
        <app-my-schedule-crew-card class="scheduled-item" *ngFor="let bdbSub of bigDayBucketSubrangeWeek[i]"
          [inputBucket]="bdbSub">
        </app-my-schedule-crew-card>
      </div>
    </p-card>
  </div>
</div>

<div *ngIf="colSize !== 0" id="twoDayCalendar" class="p-grid" style="margin-left: 1em; margin-right: 1em;">
  <div class="p-col-{{colSize}}" *ngFor="let day of week; let i=index" style=" margin-bottom: 1em;">
    <p-card [style]="{height: '100%'}">
      <p-header>
        {{day | date: 'EEEE M/d' | uppercase}}
      </p-header>
      <div *ngIf="!scheduledBucketWeek || !bigDayBucketSubrangeWeek"><app-loading-swirly [isLoading]="!scheduledBucketWeek || !bigDayBucketSubrangeWeek"></app-loading-swirly></div>
      <div *ngIf="scheduledBucketWeek">
        <app-my-schedule-crew-card class="scheduled-item" *ngFor="let bucket of scheduledBucketWeek[i]"
          [inputBucket]="bucket">
        </app-my-schedule-crew-card>
      </div>
      <div *ngIf="bigDayBucketSubrangeWeek">
        <app-my-schedule-crew-card class="scheduled-item" *ngFor="let bdbSub of bigDayBucketSubrangeWeek[i]"
          [inputBucket]="bdbSub">
        </app-my-schedule-crew-card>
      </div>
    </p-card>
  </div>
</div>