<div *ngIf="quote">
    <app-quote-payment 
    [customerId]="quote.customerId" 
    [paymentAmount]="paymentAmount" 
    [quote]="quote"
    [maxWidth]="maxWidth"
    (billingSaved)="onBillingSaved()"
    (convertQuote)="emitConvertQuote()"
    (closed)="emitClose()"
    [showManualPaymentChargeMessage]="showManualPaymentChargeMessage"></app-quote-payment>
</div>