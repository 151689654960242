import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  WellnessWorkOrder, BigDayEmployeeGenSvc, BigDayEquipmentGenSvc, BigDayEmployee, BigDayEquipment, BigDayBucket,
  BigDayBucketSubrangeGenSvc, BigDayBucketSubrange, BigDayBucketGenSvc, WorkWorkOrder, BigDayBucketUpdateRequest, WorkTask, WellnessTask
} from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable } from 'rxjs';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import * as moment from 'moment';

@Component({
  selector: 'app-multiday-job-card',
  templateUrl: './multiday-job-card.component.html',
  styleUrls: ['./multiday-job-card.component.css']
})
export class MultidayJobCardComponent implements OnInit {
  @Input() bigDayBucket: BigDayBucket;
  @Input() type: string;

  bigDayBucketSubranges: BigDayBucketSubrange[];
  bigDayEmployees: BigDayEmployee[];
  bigDayEquipment: BigDayEquipment[];

  workOrder: WellnessWorkOrder | WorkWorkOrder;

  // See dblclickHandler()
  clickTime = 0;

  isCollapsed = true;
  crewTitle: string;
  foremanName: string;
  crewErrors: string[] = [];
  hasCompanyWideNotes = false;
  tasks: (WellnessTask | WorkTask)[];

  isSaving: boolean = false;

  constructor(
    private bigDayBucketService: BigDayBucketGenSvc,
    private bigDayBucketSubrangeService: BigDayBucketSubrangeGenSvc,
    private bigDayEmployeeService: BigDayEmployeeGenSvc,
    private bigDayEquipmentService: BigDayEquipmentGenSvc,
    private messageService: MessageService,
    private workOrderHelper: WorkOrderHelperService,
    private router: Router,
    public customCustomerService: CustomerService,
    private priorityService: PriorityService) { }

  ngOnInit() {
    if (this.type === 'wellness') {
      this.workOrder = this.bigDayBucket.wellnessWorkOrder;
    } else if (this.type === 'work') {
      this.workOrder = this.bigDayBucket.workWorkOrder;
    }

    this.workOrder.mostUrgentPriorityLevel = this.priorityService.GetMostUrgentPriorityLevel(this.workOrder);
    this.hasCompanyWideNotes = this.workOrder.customer.customerComments.some(comment => comment.companyWide && comment.active);

    this.getAllFieldsFromServer();

    this.tasks = this.workOrderHelper.getTasks(this.workOrder);
  }

  navigateToWorkOrder() {
    if (confirm('Would you like to redirect to the work order page?')) {
      if (this.type === 'wellness') {
        // this.router.navigateByUrl('wellnessWorkOrderMaintenance/' + this.workOrder.id);
        window.open('wellnessWorkOrderMaintenance/' + this.workOrder.id, '_blank');
      } else if (this.type === 'work') {
        // this.router.navigateByUrl('workWorkOrderMaintenance/' + this.workOrder.id);
        window.open('workWorkOrderMaintenance/' + this.workOrder.id, '_blank');
      }
    }
  }

  getAllFieldsFromServer() {
    this.bigDayEmployeeService.getAllWithBigDayBucketId(this.bigDayBucket.id).subscribe(emp => {
      this.bigDayEmployees = emp;
    });

    this.bigDayEquipmentService.getAllWithBigDayBucketId(this.bigDayBucket.id).subscribe(eqp => {
      this.bigDayEquipment = eqp;
    });

    this.bigDayBucketSubrangeService.getAllWithBigDayBucketId(this.bigDayBucket.id).subscribe(subs => {
      this.bigDayBucketSubranges = subs;
      this.sortSubranges();
    });
  }

  deleteSubrange(bigDayBucketSubrange: BigDayBucketSubrange) {
    this.bigDayBucketSubrangeService.delete(bigDayBucketSubrange.id).subscribe(() => {
      this.bigDayBucketSubranges = this.bigDayBucketSubranges.filter(x => x.id !== bigDayBucketSubrange.id);
    });

  }

  onAddSubrange() {
    const newBigDayBucketSubrange = new BigDayBucketSubrange();
    newBigDayBucketSubrange.active = true;
    newBigDayBucketSubrange.fromDate = new Date(this.bigDayBucket.fromDate);
    newBigDayBucketSubrange.toDate = new Date(this.bigDayBucket.fromDate);
    newBigDayBucketSubrange.bigDayBucketId = this.bigDayBucket.id;

    this.bigDayBucketSubrangeService.add(newBigDayBucketSubrange).subscribe((s) => {
      const subrange = s;
      this.bigDayBucketSubranges.push(subrange);
      this.sortSubranges();
    });
  }

  saveAll() {
    this.isSaving = true;
    let isDatesValid = this.bigDayBucket.fromDate <= this.bigDayBucket.toDate;
    this.bigDayBucketSubranges.forEach((subrange) => {
      isDatesValid = isDatesValid && subrange.fromDate <= subrange.toDate;
    });
    if (isDatesValid) {
      this.bigDayBucketSubranges.forEach((subrange) => {
        isDatesValid = isDatesValid && this.bigDayBucket.fromDate <= subrange.fromDate
          && this.bigDayBucket.toDate >= subrange.toDate;
      });
    }

    if (isDatesValid) {
      const observableBatch = [];

      const bigDayBucketUpdateRequest: BigDayBucketUpdateRequest = new BigDayBucketUpdateRequest({
        ...this.bigDayBucket
      });

      observableBatch.push(this.bigDayBucketService.update(bigDayBucketUpdateRequest));

      this.bigDayBucketSubranges.forEach((subrange) => {
        observableBatch.push(this.bigDayBucketSubrangeService.update(subrange, subrange.id.toString()));
      });

      Observable.forkJoin(observableBatch).subscribe(() => {
        this.getAllFieldsFromServer();
        this.isSaving = false;
        this.messageService.add({ severity: 'success', summary: 'Data saved' });
      });
    } else {
      this.isSaving = false;
      this.messageService.add({ severity: 'error', summary: 'Invalid dates' });
    }
  }

  sortSubranges() {
    this.bigDayBucketSubranges.sort((a, b) => {
      return a.fromDate.getTime() - b.fromDate.getTime();
    });
    const subranges = this.bigDayBucketSubranges.filter(x => x.bigDayEmployees.length === 0 && x.bigDayEquipment.length === 0).slice();
    this.bigDayBucketSubranges = subranges
      .concat(this.bigDayBucketSubranges.filter(x => x.bigDayEmployees.length !== 0 || x.bigDayEquipment.length !== 0).slice());
  }

  // Double click doesn't work on mobile
  // https://stackoverflow.com/questions/50752562/angular-4-dblclick-event-not-working-in-mobile-view
  dblclickHandler() {
    if (!this.clickTime) {
      // set first click
      this.clickTime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (((new Date().getTime()) - this.clickTime) < 600) {
        // double click occurred
        this.navigateToWorkOrder();
        this.clickTime = 0;
      } else {
        // not a double click so set as a new first click
        this.clickTime = new Date().getTime();
      }
    }
  }

  getDueDateDisplay(task: WorkTask | WellnessTask): string {
    const dueDateStart = moment(task.dueDateStart).format('M/DD/YY');
    const dueDateEnd = moment(task.dueDateEnd).format('M/DD/YY');

    if (task.hardStartDate && task.hardEndDate) {
      if (moment(task.dueDateStart).isSame(moment(task.dueDateEnd), 'day')) {
        return dueDateStart;
      } else {
        return `${dueDateStart} - ${dueDateEnd}`;
      }
    } else if (task.hardStartDate) {
      return dueDateStart;
    } else if (task.hardEndDate) {
      return dueDateEnd;
    } else {
      return `${dueDateStart} - ${dueDateEnd}`;
    }
  }

  getEquipmentTypeDisplay(): string {
    const equipmentTypes = this.workOrderHelper.getUniqueEquipment(this.workOrder);

    return equipmentTypes.map(et => et.abbreviation ? et.abbreviation : et.type).join(' / ');
  }

  public getPriorityColor() {
    return this.workOrder.mostUrgentPriorityLevel && this.workOrder.mostUrgentPriorityLevel.textColor ?
        this.workOrder.mostUrgentPriorityLevel.textColor : '';
  }

  public getPriorityBackgroundColor() {
    return this.workOrder.mostUrgentPriorityLevel && this.workOrder.mostUrgentPriorityLevel.backgroundColor ?
      this.workOrder.mostUrgentPriorityLevel.backgroundColor : '';
  }
}
