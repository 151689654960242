import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
import { BigDayBucketSubrangeGenSvc, NotificationsGenSvc, WorkWorkOrderGenSvc, WellnessWorkOrderGenSvc } from '../../services_autogenerated/generated_services';
import { BaseCalendarComponent } from '../base-calendar/base-calendar.component';
import { ScheduledBucketGenSvc, ScheduleCommentGenSvc } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CrewsDateRangeService } from 'src/app/services/crews-date-range.service';
import { BucketValidationHelperService } from 'src/app/services/bucket-validation-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
var TwoDayCalendarViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TwoDayCalendarViewComponent, _super);
    function TwoDayCalendarViewComponent(scheduledItemBucketService, formB, scheduleCommentService, dragAndDropService, messageService, crewsDateRangeService, bigDayBucketSubrangeService, bucketValidationHelperService, scheduledBucketGenSvc, authHelperService, emailService, wellnessWorkOrderService, workWorkOrderService, confirmationService, messageWrapperService) {
        var _this = _super.call(this, scheduledItemBucketService, formB, scheduleCommentService, dragAndDropService, messageService, crewsDateRangeService, bigDayBucketSubrangeService, bucketValidationHelperService, scheduledBucketGenSvc, authHelperService, emailService, wellnessWorkOrderService, workWorkOrderService, confirmationService, messageWrapperService) || this;
        _this.formB = formB;
        _this.howManyDays = 2;
        return _this;
    }
    return TwoDayCalendarViewComponent;
}(BaseCalendarComponent));
export { TwoDayCalendarViewComponent };
