import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BehaviorSubjectService {
  apiUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  plaidPublicKeySubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  plaidEnvironmentSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  stripePublicKeySubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  reportLinkSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  contactPhoneNumberSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  quoteRequestConfirmationLinkSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor() {}
}
