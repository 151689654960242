/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-swirly.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-swirly.component";
var styles_LoadingSwirlyComponent = [i0.styles];
var RenderType_LoadingSwirlyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingSwirlyComponent, data: {} });
export { RenderType_LoadingSwirlyComponent as RenderType_LoadingSwirlyComponent };
function View_LoadingSwirlyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "margin-left:8px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "pi pi-spin pi-spinner"], ["style", "font-size: 3em"]], null, null, null, null, null))], null, null); }
export function View_LoadingSwirlyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingSwirlyComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingSwirlyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-swirly", [], null, null, null, View_LoadingSwirlyComponent_0, RenderType_LoadingSwirlyComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingSwirlyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingSwirlyComponentNgFactory = i1.ɵccf("app-loading-swirly", i3.LoadingSwirlyComponent, View_LoadingSwirlyComponent_Host_0, { isLoading: "isLoading" }, {}, []);
export { LoadingSwirlyComponentNgFactory as LoadingSwirlyComponentNgFactory };
