import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PriorityTypeShortcut, PriorityTypeShortcutGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-priority-backlog-maintenance',
  templateUrl: './priority-backlog-maintenance.component.html',
  styleUrls: ['./priority-backlog-maintenance.component.css']
})
export class PriorityBacklogMaintenanceComponent implements OnInit {
  isLoading: boolean;
  backlogs: PriorityTypeShortcut[];

  constructor(private priorityTypeShortcutService: PriorityTypeShortcutGenSvc,
    private messageService: MessageService) {
   }

  ngOnInit() {
    this.isLoading = true;
    this.priorityTypeShortcutService.getCurrentBacklogs().subscribe(backlogs => {
      this.isLoading = false;
      this.backlogs = backlogs;
    });
  }
}
