import * as tslib_1 from "tslib";
import { QueryList } from '@angular/core';
import { WellnessWorkOrder, Address, WellnessTask, Customer, WellnessWorkOrderGenSvc, WellnessTaskGenSvc, WorkOrderWorkTaskGenSvc, WorkOrderWellnessTaskGenSvc, WorkOrderPriceAdjustmentGenSvc, EmailAddress, CustomerContact, WellnessTaskCompletionStatus, WellnessWorkOrderCompletionStatus, NotificationsGenSvc, WellnessTaskPaymentStatus, WellnessTaskTreeType, SaveWorkOrderAfterCompletionRequest, WellnessWorkOrderPaymentStatus, QuoteGenSvc } from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BaseWorkOrderMaintenanceComponent } from '../base-work-order-maintenance.ts/base-work-order-maintenance.component';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { FormBuilder } from '@angular/forms';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';
import { WellnessTaskMaintenanceComponent } from '../wellness-task-maintenance/wellness-task-maintenance.component';
import { MaskService } from 'src/app/services/mask.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
import { WorkOrderMaintenanceWellnessTaskCardComponent } from '../work-order-maintenance-wellness-task-card/work-order-maintenance-wellness-task-card.component';
var WellnessWorkOrderMaintenanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WellnessWorkOrderMaintenanceComponent, _super);
    function WellnessWorkOrderMaintenanceComponent(wellnessWorkOrderService, authHelper, wellnessTaskService, route, router, messageService, locationColorService, notificationService, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc, wopaService, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService, maskService, fb, quoteService, messageWrapperService) {
        var _this = _super.call(this, route, router, messageService, locationColorService, notificationService, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc, wopaService, authHelper, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService) || this;
        _this.wellnessWorkOrderService = wellnessWorkOrderService;
        _this.wellnessTaskService = wellnessTaskService;
        _this.quoteService = quoteService;
        _this.messageWrapperService = messageWrapperService;
        _this.canCompleteWO = false;
        _this.canUndoWOCompletion = false;
        _this.amountToInvoice = 0;
        _this.showUndoCompleteDialog = false;
        _this.currencyMask = maskService.currencyMaskNoDecimal;
        _this.commentForm = fb.group({
            description: ['']
        });
        return _this;
    }
    WellnessWorkOrderMaintenanceComponent.prototype.preSave = function (workOrderForm) {
        if (this.canCompleteWO) {
            if (this.invoiceAmtGreaterThanCashCollected()) {
                this.previousWOStatus = this.workOrder.completionStatus;
                // this.wellnessWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
                //   .subscribe(() => {
                this.canCompleteWO = false;
                // }, error => {
                //   this.saving = false;
                //   this.messageService.add({
                //     severity: 'error',
                //     summary: 'Error Saving',
                //     detail: 'Could not save the work order, please check that all fields are valid and retry.'
                //   });
                // });
                this.save(workOrderForm);
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Cash Collected Discrepancy',
                    detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2) + ') to complete this work order.',
                    sticky: true
                });
            }
        }
        else {
            this.save(workOrderForm);
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.saveAfterCompletion = function () {
        var _this = this;
        this.wellnessWorkOrderService.saveAfterCompletion(new SaveWorkOrderAfterCompletionRequest({
            id: this.workOrder.id,
            hasRentalCosts: this.workOrder.hasRentalExpense,
            rentalCosts: this.workOrder.rentalCost,
            rentalDescription: this.workOrder.rentalDescription
        })).subscribe(function () { return _this.doneSaving('work'); }, function (error) {
            return _this.messageService.add({
                severity: 'error',
                summary: 'Save Error',
                detail: 'Work order not saved. Please refresh and try again.',
                sticky: true
            });
        });
    };
    WellnessWorkOrderMaintenanceComponent.prototype.getWO = function (id) {
        var _this = this;
        if (id) {
            this.wellnessWorkOrderService.get(id).subscribe(function (wo) {
                _this.workOrder = wo;
                _this.canSaveAfterCompletion = _this.canSave && _this.workOrder.completionStatus === WellnessWorkOrderCompletionStatus.Completed
                    && _this.token.claimNames.includes('Edit Outside Costs');
                _this.onTaskChange();
                _this.isEditable = _this.isEditable
                    && _this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed
                    && _this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Unable_to_be_Completed;
                _this.canSave = (_this.canSave
                    && _this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed
                    && _this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Unable_to_be_Completed)
                    || _this.token.claimNames.includes('Add Task to Completed Work Order');
                _this.setCanCompleteWO();
                _this.canUndoWOCompletion = _this.workOrder.completionStatus === WellnessWorkOrderCompletionStatus.Completed
                    && _this.token.claimNames.includes('Undo Work Order Completion')
                    && _this.workOrder.paymentStatus !== WellnessWorkOrderPaymentStatus.Paid;
                _this.isLoading = false;
            }, function (error) {
                _this.errorMessage = 'Could not load the work order. Please refresh and try again.';
                _this.isLoading = false;
            });
        }
        else {
            var wo = new WellnessWorkOrder();
            wo.customer = new Customer();
            wo.customer.customerContacts = [
                new CustomerContact({
                    receiveInvoicingEmails: true,
                    receiveNonInvoicingEmails: true,
                })
            ];
            wo.customer.customerContacts[0].emailAddress = new EmailAddress();
            wo.customer.address = new Address();
            wo.address = new Address();
            wo.createdDate = new Date();
            wo.completionStatus = WellnessWorkOrderCompletionStatus.Ready_to_Schedule;
            wo.salesTaxPercentage = 0;
            wo.workOrderWellnessTasks = [];
            wo.workOrderPriceAdjustments = [];
            wo.salesTaxPercentage = 7.5; // default
            this.workOrder = wo;
            this.isEditable = this.isEditable && true;
            this.onTaskChange();
            this.isLoading = false;
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.completeWO = function () {
        if (confirm('This will complete the work order. Do you want to continue?')) {
            if (this.invoiceAmtGreaterThanCashCollected()) {
                if (this.workOrder.workOrderWellnessTasks.every(function (wowt) { return wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed; })) {
                    this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Unable_to_be_Completed;
                }
                else {
                    this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Completed;
                    if (!this.workOrder.completedDate) {
                        this.workOrder.completedDate = new Date();
                    }
                }
                this.saving = true;
                this.isEditable = false;
                this.saveWO();
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Cash Collected Discrepancy',
                    detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2) + ') to complete this work order.',
                    sticky: true
                });
            }
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.undoWOCompletion = function () {
        var _this = this;
        this.saving = true;
        var comment = this.commentForm.get('description').value;
        this.wellnessWorkOrderService.undoWorkOrderCompletion(this.workOrder.id, comment ? comment : 'Work order adjusted using uncomplete button', this.token.employeeId).subscribe(function () {
            _this.closeUndoWOCompletion();
            _this.getWO(_this.workOrder.id);
            _this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Notified;
            _this.isLoading = true;
            _this.workOrder = undefined;
            _this.saving = false;
        }, function (error) {
            if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Undoing Completion',
                    detail: JSON.parse(error.response).ExceptionMessage,
                    sticky: true
                });
            }
            else {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Undoing Completion',
                    detail: 'An unexpected error occurred. Contact an admin for support.',
                    sticky: true
                });
            }
        });
    };
    WellnessWorkOrderMaintenanceComponent.prototype.openUndoWOCompletion = function () {
        this.showUndoCompleteDialog = true;
    };
    WellnessWorkOrderMaintenanceComponent.prototype.closeUndoWOCompletion = function () {
        this.showUndoCompleteDialog = false;
    };
    WellnessWorkOrderMaintenanceComponent.prototype.invoiceAmtGreaterThanCashCollected = function () {
        var selectedTasks = this.workOrder.workOrderWellnessTasks.filter(function (x) {
            return x.wellnessTask.active && x.wellnessTask.paymentStatus === WellnessTaskPaymentStatus.Not_Paid;
        });
        var cashToInvoice = this.workOrder.workOrderCashCollected.filter(function (x) { return x.invoiced === false; }).reduce(function (a, b) { return a + b.amount; }, 0);
        this.amountToInvoice = this.helper.totalAmountByTasks(this.workOrder, selectedTasks);
        if (this.amountToInvoice >= cashToInvoice) {
            return true;
        }
        else {
            return false;
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.saveWO = function () {
        var _this = this;
        // Updating existing wellness WO
        this.wellnessWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
            .subscribe(function (wo) {
            _this.saveBlobs(wo);
        }, function (error) {
            _this.saving = false;
            _this.messageService.add({
                severity: 'error',
                summary: 'Error Saving',
                detail: 'Could not save the work order, please check that all fields are valid and retry.'
            });
        });
    };
    WellnessWorkOrderMaintenanceComponent.prototype.saveBlobs = function (wo) {
        var _this = this;
        // Currently only 1 blob manager should be not view only. If that changes, you'll need to update this and loop through all the
        // ones that are not view only and save them.
        var blobManagerComponent = this.blobManagerComponents.find(function (blobComponent) { return blobComponent.viewOnly === false; });
        if (blobManagerComponent) {
            blobManagerComponent.parentRecordId = wo.id;
            blobManagerComponent.save().subscribe(function () { return _this.doneSaving('wellness'); });
        }
        else {
            this.doneSaving('wellness');
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.addNewWelnessTask = function () {
        this.displayWellnessTaskDialog = true;
    };
    WellnessWorkOrderMaintenanceComponent.prototype.copyWorkTask = function (wowt) {
        throw new Error('Cannot add a Work Task to a Wellness Work Order');
    };
    WellnessWorkOrderMaintenanceComponent.prototype.copyWellnessTask = function (wowt) {
        this.selectedWellnessTask = new WellnessTask({
            active: true,
            renewable: false,
            renewalAccepted: wowt.wellnessTask.renewable,
            renewed: false,
            location: wowt.wellnessTask.location,
            wellnessTaskTreeTypes: wowt.wellnessTask.wellnessTaskTreeTypes.map(function (wttt) { return new WellnessTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType }); }),
            primordialTaskId: wowt.wellnessTask.id,
            priorityLevelId: wowt.wellnessTask.priorityLevelId,
            manualPriorityLevel: wowt.wellnessTask.manualPriorityLevel,
            priorityShortcutId: wowt.wellnessTask.priorityShortcutId,
            priorityTypeShortcut: wowt.wellnessTask.priorityTypeShortcut,
            hasCalculatedPriority: wowt.wellnessTask.hasCalculatedPriority,
            dueDateEnd: wowt.wellnessTask.dueDateEnd,
            dueDateStart: wowt.wellnessTask.dueDateStart,
            hardEndDate: wowt.wellnessTask.hardEndDate,
            hardStartDate: wowt.wellnessTask.hardStartDate,
            // schedulableAfterCompletionOf: wowt.wellnessTask.schedulableAfterCompletionOf,
            // schedulableAfterCompletionOfId: wowt.wellnessTask.schedulableAfterCompletionOfId,
            completionStatus: wowt.wellnessTask.completionStatus,
            wellnessTaskSkills: [],
            wellnessTaskEquipment: [],
            taskHourEntries: [],
            fixedPrice: true
        });
        this.displayWellnessTaskDialog = true;
    };
    WellnessWorkOrderMaintenanceComponent.prototype.editWellnessTask = function (wowt) {
        this.selectedWellnessTask = wowt.wellnessTask;
        this.displayWellnessTaskDialog = true;
    };
    WellnessWorkOrderMaintenanceComponent.prototype.completeWowtWithoutPestRecord = function (wowt) {
        var _this = this;
        var previousStatus = wowt.wellnessTask.completionStatus;
        var previousCompletedDate = wowt.wellnessTask.completedDate;
        var previousGoBackCompletedDate = wowt.wellnessTask.goBackCompletionDate;
        if (confirm('Are you sure this work order does not required a pesticide application record?')) {
            wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Completed;
            if (wowt.wellnessTask.isGoBack && !wowt.wellnessTask.goBackCompletionDate) {
                wowt.wellnessTask.goBackCompletionDate = new Date();
            }
            else if (!wowt.wellnessTask.completedDate) {
                wowt.wellnessTask.completedDate = new Date();
            }
            this.wellnessTaskService.updateCompletionStatus(wowt.wellnessTask).subscribe(function (wt) {
                var index = _this.workOrder.workOrderWellnessTasks.findIndex(function (x) { return x.wellnessTaskId === wt.id; });
                _this.workOrder.workOrderWellnessTasks[index].wellnessTask = wt;
                _this.setCanCompleteWO();
                if (wt.paymentStatus === WellnessTaskPaymentStatus.Paid) {
                    _this.woTaskCards.filter(function (card) { return card.workOrderWellnessTask.id === wowt.id; }).forEach(function (card) {
                        card.setUpStatusBar();
                        card.canUndo = false;
                    });
                }
            }, function (error) {
                if (wowt.wellnessTask.isGoBack && !wowt.wellnessTask.goBackCompletionDate) {
                    wowt.wellnessTask.goBackCompletionDate = previousGoBackCompletedDate;
                }
                else if (!wowt.wellnessTask.completedDate) {
                    wowt.wellnessTask.completedDate = previousCompletedDate;
                }
                wowt.wellnessTask.completionStatus = previousStatus;
                _this.woTaskCards.filter(function (card) { return card.workOrderWellnessTask.id === wowt.id; }).forEach(function (card) {
                    card.setUpStatusBar();
                    card.canUndo = false;
                });
                _this.messageWrapperService.addErrorMessage('Could not mark the task complete, please contact support.', error, true);
            });
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.undoCompleteTask = function (wellnessTask) {
        var index = this.workOrder.workOrderWellnessTasks.findIndex(function (x) { return x.wellnessTaskId === wellnessTask.id; });
        this.workOrder.workOrderWellnessTasks[index].wellnessTask = wellnessTask;
        this.setCanCompleteWO();
    };
    WellnessWorkOrderMaintenanceComponent.prototype.fromWowtToPesticideApplicationRecord = function (wowt) {
        var urlExt = 'wo' + this.workOrder.id + 'wt' + wowt.wellnessTaskId;
        this.router.navigateByUrl('pesticideApplicationRecordMaintenance/' + urlExt);
    };
    WellnessWorkOrderMaintenanceComponent.prototype.onTaskChange = function () {
        this.requiredSkills = this.getSkillsFromTasks();
        this.requiredEquipment = this.getEquipmentTypesFromTasks();
        this.setCanCompleteWO();
    };
    WellnessWorkOrderMaintenanceComponent.prototype.cancelledTask = function (task) {
        var _this = this;
        if (task instanceof WellnessTask && task.id) {
            this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTaskId === task.id; }).wellnessTask = task;
        }
        else if (!task) {
            if (this.selectedWellnessTask && this.selectedWellnessTask.id) {
                this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTaskId === _this.selectedWellnessTask.id; }).wellnessTask = this.wellnessTaskMaintenance.taskUnmodified;
            }
        }
        this.displayWellnessTaskDialog = false;
        this.displayWorkTaskDialog = false;
        this.selectedWellnessTask = undefined;
        this.onTaskChange();
    };
    WellnessWorkOrderMaintenanceComponent.prototype.closedDialog = function (task) {
        if (task && task.id) {
            this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTask.id === task.id; }).wellnessTask = task;
        }
        this.displayWellnessTaskDialog = false;
        this.selectedWellnessTask = undefined;
        this.onTaskChange();
    };
    WellnessWorkOrderMaintenanceComponent.prototype.getEquipmentTypesFromTasks = function () {
        var wellnessEquipment = [];
        this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
            wellnessEquipment = wellnessEquipment.concat(wowt.wellnessTask.wellnessTaskEquipment.map(function (wte) { return wte.equipmentType; }));
        });
        return this.helper.getUnique(wellnessEquipment);
    };
    WellnessWorkOrderMaintenanceComponent.prototype.getSkillsFromTasks = function () {
        var wellnessSkills = [];
        this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
            wellnessSkills = wellnessSkills.concat(wowt.wellnessTask.wellnessTaskSkills.map(function (wts) { return wts.skill; }));
        });
        return this.helper.getUnique(wellnessSkills);
    };
    WellnessWorkOrderMaintenanceComponent.prototype.onMyWay = function () {
        var _this = this;
        if (confirm('This will send the customer an email or SMS' +
            ' message letting them know you are on your way to do this job. Click “OK” to proceed with this notification to the customer.')) {
            this.notificationService.sendWellnessWorkOrderOnWayNotification(this.workOrder.id).subscribe(function () {
                _this.messageService.add({
                    severity: 'success',
                    summary: 'Message Sent',
                    detail: 'Customer was notified Russell Tree is en route.'
                });
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Sending Message',
                    detail: 'Could not enqueue message to be sent. Please try again.'
                });
            });
        }
    };
    WellnessWorkOrderMaintenanceComponent.prototype.unableToCompleteWorkTask = function (wellnessTask) {
        var index = this.workOrder.workOrderWellnessTasks.findIndex(function (x) { return x.wellnessTaskId === wellnessTask.id; });
        this.workOrder.workOrderWellnessTasks[index].wellnessTask = wellnessTask;
        this.setCanCompleteWO();
    };
    WellnessWorkOrderMaintenanceComponent.prototype.setCanCompleteWO = function () {
        // canCompleteWO is permissions based, WO's stage cannot already be complete or unable to complete, and ALL tasks must be complete or unable to complete
        this.canCompleteWO = this.canComplete && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed
            && this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Unable_to_be_Completed
            && this.workOrder.workOrderWellnessTasks.every(function (woTask) {
                return woTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed ||
                    woTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed;
            });
    };
    WellnessWorkOrderMaintenanceComponent.prototype.duplicateToQuote = function () {
        var _this = this;
        if (confirm('This will create a new Quote based on this WO. Please select "Ok" to continue.')) {
            // this.saving = true;
            this.quoteService.duplicateWellnessToQuote(this.workOrder.id, false).subscribe(function (id) { return _this.router.navigateByUrl('quoteWorkOrderMaintenance/' + id); });
        }
    };
    return WellnessWorkOrderMaintenanceComponent;
}(BaseWorkOrderMaintenanceComponent));
export { WellnessWorkOrderMaintenanceComponent };
