import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthHelperService } from './services/auth-helper.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthHelperService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['login']);
            return false;
        }

        if (route.data.roles) {
            const currentUser = this.authService.getDecodedAccessToken();

            if (currentUser.roles.includes('Admin')) {
                return true; // Admin can do whatever they want, so don't check other roles
            }

            if (route.data.roles.includes('Office Manager')) {
                if (currentUser.roles.includes('Office Manager') || currentUser.roles.includes('Office Manager Admin')) {
                    return true;
                } else {
                    // User is logged in but does not have permission to go to route, navigate 'em home
                    this.authService.redirectToHome();
                }
            }
        }

        return true;
    }
}
