import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services_autogenerated/generated_services";
var BlobManagerWrapperService = /** @class */ (function () {
    function BlobManagerWrapperService(http, baseUrl) {
        this.http = http;
        this.urlBase = baseUrl + "/api/v1/blob";
    }
    BlobManagerWrapperService.prototype.uploadFilesToBlobStorage = function (containerName, recordId, files) {
        var formData = new FormData();
        files.forEach(function (file) {
            // tslint:disable-next-line: no-bitwise
            var extension = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
            // tslint:disable-next-line: no-bitwise
            var overwrittenFileNameExtension = file.overwrittenFileName.slice((file.overwrittenFileName.lastIndexOf('.') - 1 >>> 0) + 2);
            if (file.overwrittenFileName && !overwrittenFileNameExtension && extension) {
                file.overwrittenFileName += '.' + extension;
            }
            formData.append(file.name, file, file.overwrittenFileName || file.name);
        });
        // Skip over the BlobGenSvc because it was being dumb about adding the formData right.
        return this.http.post(this.urlBase + "/UploadBlob/" + containerName + "/" + recordId, formData);
    };
    BlobManagerWrapperService.ngInjectableDef = i0.defineInjectable({ factory: function BlobManagerWrapperService_Factory() { return new BlobManagerWrapperService(i0.inject(i1.HttpClient), i0.inject(i2.API_BASE_URL)); }, token: BlobManagerWrapperService, providedIn: "root" });
    return BlobManagerWrapperService;
}());
export { BlobManagerWrapperService };
