<div class="p-grid">
  <div class="p-col-12 p-lg-4 p-md-6">
    <p-scrollPanel [style]="{height: '90vh'}">
      <h6 class="bold" style="text-align: center; padding:.25em">Equipment</h6>
      <div class="p-grid search-style">
        <div class="p-col-6">
            <span class="ui-float-label">
              <input id="float-input-crew-equipment" class="serach-input float-input" type="text" pInputText [(ngModel)]="searchTermEquip"> 
              <label for="float-input-crew-equipment">
                  <i class="pi pi-search"></i>
                  Search
              </label>
          </span>
        </div>
        <div class="p-col-6" style="margin-top: 10px;">
          <ng-select 
            class="custom"
            [items]="equipmentTypes" 
            placeholder="Type" 
            [selectOnTab]="true"
            name="type" 
            bindLabel="type"
            [(ngModel)]="selectedType"
            (ngModelChange)="equipTypeChanged()">
          </ng-select>
        </div>
      </div>
      <div class="p-col-12">
        <div class="p-grid"
          [id]="'equipmentList'"
          cdkDropList
          [cdkDropListData]="filteredEquipmentList | equipmentFilter: searchTermEquip"
          [cdkDropListConnectedTo]="dragAndDropService.allCrewEquipmentLists"
          (cdkDropListDropped)="dragAndDropService.dropOntoEquipmentList($event)">
          <app-equipment-card class="p-col-4 match-height-card"
            *ngFor="let equip of filteredEquipmentList | equipmentFilter: searchTermEquip"
            [equipment]="equip"
            cdkDrag></app-equipment-card>
        </div>
      </div>
    </p-scrollPanel>
  </div>
    
  <div class="p-col-12 p-lg-4 p-md-6">
    <p-scrollPanel [style]="{height: '90vh'}">
      <div class="p-grid p-nogutter">
        <div class="p-col-1 p-nogutter"></div>
        <div class="p-col-10 p-nogutter"><h6 class="bold centered">Crew Assignments</h6></div>
        <div class="p-col-1 p-nogutter"><i class="pi pi-plus right" pTooltip="Add New Crew" tooltipPosition="bottom" (click)="addCrew()"></i></div>
      </div>
      <div>
        <div class="quaters" style="padding-bottom: 3.4em;">
          <i class="pi pi-caret-left middle" (click)="changeQuarter(-1)"></i>
          <span class="bold">Q{{quarter}}</span> ({{quarterStart.format("L")}} to {{quarterEnd.format("L")}})
          <i class="pi pi-caret-right middle" (click)="changeQuarter(1)"></i>
        </div>
      </div>
      <div class="p-col-12">
        <div class="p-grid"
          id="crewList">
        <app-crew-creation-card 
          class="p-col-6"
          *ngFor="let crew of allCrews"
          [crew]="crew"
          (deleted)="deleteCrew($event)"></app-crew-creation-card>
        </div>
      </div>
    </p-scrollPanel>
  </div>
    
  <div class="p-col-12 p-lg-4 p-md-7">
    <p-scrollPanel [style]="{height: '90vh'}">
      <h6 class="bold" style="text-align: center; padding:.25em">People</h6>
      <div class="p-grid search-style">
        <div class="p-col-6">
          <span class="ui-float-label">
            <input id="float-input-crew-people" type="text" class="float-input" pInputText [(ngModel)]="searchTermPeople"> 
            <label for="float-input-crew-people">
                <i class="pi pi-search"></i>
                Search
            </label>
          </span>
        </div>
        <div class="p-col-6" style="margin-top: 10px;">
          <ng-select 
            class="custom"
            [items]="allRoles" 
            placeholder="Role" 
            [selectOnTab]="true"
            name="role" 
            bindLabel="name"
            [(ngModel)]="selectedRole"
            (ngModelChange)="roleChanged()">
          </ng-select>
        </div>
      </div>
      <div class="p-col-12">
        <div class="p-grid"
          [id]="'employeeList'"
          cdkDropList
          [cdkDropListData]="filteredPeopleList | peopleFilter: searchTermPeople"
          [cdkDropListConnectedTo]="dragAndDropService.allCrewEmployeeLists"
          (cdkDropListDropped)="dragAndDropService.dropOntoEmployeeList($event)">
          <app-employee-card class="match-height-card p-col-4"
            *ngFor="let employee of filteredPeopleList | peopleFilter: searchTermPeople"
            [employee]="employee"
            cdkDrag></app-employee-card>
        </div>
      </div>
    </p-scrollPanel>
  </div>
</div>