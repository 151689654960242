/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./charge-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../customer-billing-profile/customer-billing-profile.component.ngfactory";
import * as i3 from "../../customer-billing-profile/customer-billing-profile.component";
import * as i4 from "../../../services_autogenerated/generated_services";
import * as i5 from "../../../services/auth-helper.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i9 from "primeng/components/dialog/dialog";
import * as i10 from "../../../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i11 from "primeng/components/common/shared";
import * as i12 from "../../loading-swirly/loading-swirly.component.ngfactory";
import * as i13 from "../../loading-swirly/loading-swirly.component";
import * as i14 from "primeng/components/button/button";
import * as i15 from "./charge-card.component";
var styles_ChargeCardComponent = [i0.styles];
var RenderType_ChargeCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChargeCardComponent, data: {} });
export { RenderType_ChargeCardComponent as RenderType_ChargeCardComponent };
function View_ChargeCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-billing-profile", [["style", "z-index: 11111"]], null, [[null, "billingProfileEntryModalClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("billingProfileEntryModalClosed" === en)) {
        var pd_0 = (_co.refreshBilling() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerBillingProfileComponent_0, i2.RenderType_CustomerBillingProfileComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomerBillingProfileComponent, [i4.StripeGenSvc, i5.AuthHelperService], { customer: [0, "customer"] }, { billingProfileEntryModalClosed: "billingProfileEntryModalClosed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customer; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChargeCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "fine-print"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There is no credit card on file, edit the billing info above to add a card."]))], null, null); }
export function View_ChargeCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 30, "form", [["id", "cashCollectedForm"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i7.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(3, 4210688, [["cashCollectedForm", 4]], 0, i7.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i1.ɵdid(5, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 25, "p-dialog", [["header", "Charge Card"]], null, [[null, "visibleChange"], [null, "onHide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showDialog = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onHide" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_Dialog_0, i8.RenderType_Dialog)), i1.ɵdid(7, 180224, null, 2, i9.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], style: [3, "style"], blockScroll: [4, "blockScroll"] }, { onHide: "onHide", visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpod(10, { width: 0 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_ChargeCardComponent_1)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, 1, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" Work Order Total: ", " "])), i1.ɵppd(15, 3), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" Cash/Check Collected: ", " "])), i1.ɵppd(18, 3), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, [" Amount Due: ", " "])), i1.ɵppd(21, 3), (_l()(), i1.ɵeld(22, 0, null, 2, 9, "p-footer", [], null, null, null, i10.View_Footer_0, i10.RenderType_Footer)), i1.ɵdid(23, 49152, [[2, 4]], 0, i11.Footer, [], null, null), (_l()(), i1.ɵeld(24, 0, null, 0, 1, "app-loading-swirly", [], null, null, null, i12.View_LoadingSwirlyComponent_0, i12.RenderType_LoadingSwirlyComponent)), i1.ɵdid(25, 114688, null, 0, i13.LoadingSwirlyComponent, [], { isLoading: [0, "isLoading"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ChargeCardComponent_2)), i1.ɵdid(27, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(28, 0, null, 0, 1, "button", [["label", "Charge Card"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chargeCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 4341760, null, 0, i14.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(30, 0, null, 0, 1, "button", [["class", "ui-button-secondary"], ["label", "Cancel"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(31, 4341760, null, 0, i14.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.showDialog; var currVal_8 = "Charge Card"; var currVal_9 = true; var currVal_10 = _ck(_v, 10, 0, "480px"); var currVal_11 = false; _ck(_v, 7, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = !_co.isLoading; _ck(_v, 12, 0, currVal_12); var currVal_16 = _co.isLoading; _ck(_v, 25, 0, currVal_16); var currVal_17 = _co.showNoCC; _ck(_v, 27, 0, currVal_17); var currVal_19 = "Charge Card"; _ck(_v, 29, 0, currVal_19); var currVal_20 = "Cancel"; _ck(_v, 31, 0, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_13 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), _co.workOrder.workOrderTotal, "USD", "symbol")); _ck(_v, 14, 0, currVal_13); var currVal_14 = i1.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i1.ɵnov(_v, 0), _co.workOrder.cashCollected, "USD", "symbol")); _ck(_v, 17, 0, currVal_14); var currVal_15 = i1.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i1.ɵnov(_v, 0), _co.workOrder.amountDue, "USD", "symbol")); _ck(_v, 20, 0, currVal_15); var currVal_18 = _co.disabled; _ck(_v, 28, 0, currVal_18); }); }
export function View_ChargeCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charge-card", [], null, null, null, View_ChargeCardComponent_0, RenderType_ChargeCardComponent)), i1.ɵdid(1, 114688, null, 0, i15.ChargeCardComponent, [i4.CustomerGenSvc, i4.StripeGenSvc], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChargeCardComponentNgFactory = i1.ɵccf("app-charge-card", i15.ChargeCardComponent, View_ChargeCardComponent_Host_0, { showDialog: "showDialog", workOrder: "workOrder", amountDue: "amountDue" }, { close: "close", charge: "charge" }, []);
export { ChargeCardComponentNgFactory as ChargeCardComponentNgFactory };
