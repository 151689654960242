import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { CustomerGenSvc, CustomerListView, Customer } from 'src/app/services_autogenerated/generated_services';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-customer-dropdown',
  templateUrl: './customer-dropdown.component.html',
  styleUrls: ['./customer-dropdown.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomerDropdownComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() required: boolean = true;
  @Input() existingSelectionId;

  // Emit the id of the selected customer
  @Output() customerSelected = new EventEmitter<number>();

  customers$: Observable<CustomerListView[]>;
  customerInput$ = new Subject<string>();

  loading: boolean;
  selectedCustomer: CustomerListView;

  constructor(private customerService: CustomerGenSvc) { }

  ngOnInit() {
    this.loadCustomers();

    if (this.existingSelectionId) {
      this.customerService.getListViewCustomer(this.existingSelectionId).subscribe(c => {
        this.selectedCustomer = c;
      });
    }
  }

  setCustomerFromNew(customer: Customer) {
    this.customerService.getListViewCustomer(customer.id).subscribe(c => {
      this.selectedCustomer = c;
    });
  }
  
  trackByFn(customer: CustomerListView) {
    return customer.id;
  }

  customerSelectedModelChange() {
    if (this.selectedCustomer) {
      this.customerSelected.emit(this.selectedCustomer.id);
    } else {
      this.customerSelected.emit(null);
    }
  }

  private loadCustomers() {
    this.customers$ = concat(
      of([]), // default items
      this.customerInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => {
          if (term) {
            return this.customerService.getByFilter(term).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.loading = false)
            );
          } else {
            this.loading = false;
            return of([]); // empty list on invalid search
          }
        })
      )
    );
  }
}
