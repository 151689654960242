import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EquipmentType } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { BaseWellnessWorkOrderCardComponent } from '../base-wellness-work-order-card/base-wellness-work-order-card.component';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
var WellnessWorkOrderCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WellnessWorkOrderCardComponent, _super);
    function WellnessWorkOrderCardComponent(dragAndDropService, router, priorityService, workOrderHelperService, customCustomerService, authHelper) {
        var _this = _super.call(this, dragAndDropService, router, priorityService, workOrderHelperService, authHelper) || this;
        _this.customCustomerService = customCustomerService;
        return _this;
    }
    WellnessWorkOrderCardComponent.prototype.getEquipmentTypeDisplay = function () {
        var equipmentTypes = [];
        this.unscheduledTasks.forEach(function (wowt) {
            if (wowt.wellnessTask.isGoBack) {
                equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskGoBackEquipment.map(function (wte) { return wte.equipmentType; }));
            }
            else {
                equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskEquipment.map(function (wte) { return wte.equipmentType; }));
            }
        });
        return this.workOrderHelperService.getUnique(equipmentTypes).map(function (et) { return et.abbreviation ? et.abbreviation : et.type; }).join(' / ');
    };
    // Override
    WellnessWorkOrderCardComponent.prototype.setTasks = function () {
        // Total amount of task scheduled, even ones not scheduled on given bucket day
        this.scheduledTasksTotal = this.workOrder.workOrderWellnessTasks.filter(function (task) {
            return !((!task.wellnessTask.isGoBack && !task.wellnessTask.currentBucketId) || (task.wellnessTask.isGoBack && !task.wellnessTask.goBackBucketId));
        }).length;
        // Tasks still unscheduled
        this.unscheduledTasks = this.workOrder.workOrderWellnessTasks.filter(function (task) {
            return (!task.wellnessTask.isGoBack && !task.wellnessTask.currentBucketId) || (task.wellnessTask.isGoBack && !task.wellnessTask.goBackBucketId);
        });
    };
    // Override
    WellnessWorkOrderCardComponent.prototype.setBottomSection = function () {
        this.hoursSummary = this.roundToTwoDecimalPlacesIfDecimalPlacesExist(this.unscheduledTasks.reduce(function (acc, curr) { return acc + curr.wellnessTask.hours; }, 0));
        this.hoursSummary += this.roundToTwoDecimalPlacesIfDecimalPlacesExist(this.unscheduledTasks.reduce(function (acc, curr) { return acc + curr.wellnessTask.goBackHoursEstimate; }, 0));
        this.priceSummary = this.roundToTwoDecimalPlacesIfDecimalPlacesExist(this.unscheduledTasks.reduce(function (acc, curr) { return acc + curr.wellnessTask.price; }, 0));
        this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
    };
    WellnessWorkOrderCardComponent.prototype.roundToTwoDecimalPlacesIfDecimalPlacesExist = function (numberToRound) {
        if (Math.round(numberToRound) !== numberToRound) {
            return numberToRound = numberToRound.toFixed(2);
        }
        else {
            return numberToRound;
        }
    };
    return WellnessWorkOrderCardComponent;
}(BaseWellnessWorkOrderCardComponent));
export { WellnessWorkOrderCardComponent };
