import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  WellnessWorkOrderGenSvc,
  WorkWorkOrderGenSvc,
  WellnessWorkOrder,
  WorkWorkOrder
} from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-invoice-partial-work',
  templateUrl: './invoice-partial-work.component.html',
  styleUrls: ['./invoice-partial-work.component.css']
})
export class InvoicePartialWorkComponent implements OnInit {

  index: number;

  wellnessWOs: WellnessWorkOrder[];
  workWOs: WorkWorkOrder[];
  startDate: Date;
  endDate: Date;
  isLoading;
  loadCount = 0;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    private wellnessWoService: WellnessWorkOrderGenSvc,
    private workWoService: WorkWorkOrderGenSvc
  ) { }

  ngOnInit() {
    this.changeType();

    this.startDate = this.getLastSunday(new Date());
    this.endDate = new Date(new Date(this.startDate).setDate(this.startDate.getDate() + 6));

    this.getData();
  }

  getLastSunday(d: Date): Date {
    d = new Date(d);
    const diff = d.getDate() - d.getDay();
    return new Date(d.setDate(diff));
  }

  changeType() {
    const type = this.route.snapshot.paramMap.get('type');

    // If there is a type in the route, pick the tab based on that type (uses tab's index to switch)
    if (type && type === 'work') {
      this.index = 1;
    } else if (type && type === 'wellness') {
      this.index = 0;
    } else {
      // Default to first tab
      this.index = 0;
    }
  }

  handleChange(event) {
    this.index = event.index;
    this.getData();
  }

  getData() {
    this.loadCount++;
    this.isLoading = true;
    if (this.index === 1) {
      this.workWoService.getPartiallyCompletedWorkOrders(this.startDate, this.endDate).subscribe(wos => {
        this.workWOs = wos;
        this.trackLoadFinished();
      }, error => {
        this.trackLoadFinished();
      });
      // this.router.navigateByUrl('/scheduling/wellness');
    } else {
      this.wellnessWoService.getPartiallyCompletedWorkOrders(this.startDate, this.endDate).subscribe(wos => {
        this.wellnessWOs = wos;
        this.trackLoadFinished();
      }, error => {
        this.trackLoadFinished();
      });
      // this.router.navigateByUrl('/scheduling/wellness');
    }
  }

  trackLoadFinished() {
    this.loadCount--;
    if (this.loadCount == 0) {
      this.isLoading = false;
    }
  }

  onDateChange() {
    // if (this.startDate && this.endDate && this.startDate <= this.endDate) {
    //   // Update the listed work orders
    // }
    if (this.startDate && this.endDate) {
      this.getData();
    }
  }
}
