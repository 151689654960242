import { EmployeeSkill, WorkOrderWellnessTask, WorkOrderWorkTask, ScheduledBucketDTO } from '../services_autogenerated/generated_services';
import { SpecificationService } from './specification.service';
import * as i0 from "@angular/core";
import * as i1 from "./specification.service";
var BucketValidationHelperService = /** @class */ (function () {
    function BucketValidationHelperService(specificationService) {
        this.specificationService = specificationService;
    }
    BucketValidationHelperService.prototype.calculateTaskHours = function (bucket) {
        var hours = 0;
        var totalHours = this.calculateTotalHours(bucket);
        var taskHours = 0;
        if (bucket.scheduledBucketWellnessWorkOrders) {
            bucket.scheduledBucketWellnessWorkOrders.forEach(function (sbwo) {
                if (sbwo.wellnessWorkOrder.workOrderWellnessTasks) {
                    taskHours += sbwo.wellnessWorkOrder.workOrderWellnessTasks.filter(function (wowt) { return wowt.wellnessTask.currentBucketId === bucket.id; })
                        .map(function (wowt) { return wowt.wellnessTask; }).map(function (t) { return t.hours; }).reduce(function (a, b) { return a + b; }, 0);
                    taskHours += sbwo.wellnessWorkOrder.workOrderWellnessTasks.filter(function (wowt) { return wowt.wellnessTask.goBackBucketId === bucket.id; })
                        .map(function (wowt) { return wowt.wellnessTask; }).map(function (t) { return t.goBackHoursEstimate; }).reduce(function (a, b) { return a + b; }, 0);
                }
            });
        }
        if (bucket.scheduledBucketWorkWorkOrders) {
            bucket.scheduledBucketWorkWorkOrders.forEach(function (sbwo) {
                if (sbwo.workWorkOrder.workOrderWorkTasks) {
                    taskHours += sbwo.workWorkOrder.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.currentBucketId === bucket.id; })
                        .map(function (wowt) { return wowt.workTask; }).map(function (t) { return t.hours; }).reduce(function (a, b) { return a + b; }, 0);
                    taskHours += sbwo.workWorkOrder.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.goBackBucketId === bucket.id; })
                        .map(function (wowt) { return wowt.workTask; }).map(function (t) { return t.goBackHoursEstimate; }).reduce(function (a, b) { return a + b; }, 0);
                }
            });
        }
        hours = Number.isNaN(taskHours / totalHours * 100) ? 0 : taskHours / totalHours * 100;
        return hours;
    };
    BucketValidationHelperService.prototype.calculateTotalHours = function (bucket) {
        var totalHours = 0;
        if (bucket.scheduledBucketEmployees.length > 0) {
            totalHours = bucket.scheduledBucketEmployees.length * 10;
        }
        return totalHours;
    };
    BucketValidationHelperService.prototype.validateBucket = function (bucket) {
        var _this = this;
        var bucketErrors = [];
        if (!bucket.scheduledBucketEquipment || bucket.scheduledBucketEquipment.length < 1) {
            bucketErrors.push('No equipment');
        }
        if (!bucket.scheduledBucketEmployees) {
            bucketErrors.push('No employees');
        }
        if (bucket.scheduledBucketEmployees && !bucket.scheduledBucketEmployees.find(function (sbe) { return sbe.employee.employeeRoles
            .some(function (er) { return er.role.name.toLowerCase().includes('foreman'); }); })) {
            bucketErrors.push('No foreman');
        }
        if (this.calculateTaskHours(bucket) > 100) {
            bucketErrors.push('Over ' + this.calculateTotalHours(bucket) + ' Hours of Tasks Scheduled ');
        }
        var errors = [];
        bucket.scheduledBucketEquipment.forEach(function (sbe) {
            if (!_this.specificationService.EquipmentInService(sbe.equipment)) {
                errors.push(sbe.equipment.name + ' is out of service on this date.');
            }
        });
        var workOrderWellnessTasks = bucket.scheduledBucketWellnessWorkOrders.map(function (wo) { return wo.wellnessWorkOrder.workOrderWellnessTasks
            .filter(function (task) { return task.wellnessTask.currentBucketId === bucket.id || task.wellnessTask.goBackBucketId === bucket.id; }); })
            .reduce(function (acc, curr) { return acc.concat(curr); }, []);
        workOrderWellnessTasks.forEach(function (workOrderWellnessTask) {
            // If the equipment type isn't found in the bucket's equipment - check go back equipment if priority is go back
            if (workOrderWellnessTask.wellnessTask.isGoBack && bucket.id === workOrderWellnessTask.wellnessTask.goBackBucketId) {
                workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment.forEach(function (wtgbe) {
                    if (!bucket.scheduledBucketEquipment.find(function (equip) { return equip.equipment.equipmentType.type === wtgbe.equipmentType.type; })) {
                        if (wtgbe.equipmentType.type !== 'No Equipment') {
                            errors.push(wtgbe.equipmentType.type + ' not in crew');
                        }
                        else {
                            if (bucketErrors.some(function (be) { return be === 'No equipment'; })) {
                                bucketErrors = bucketErrors.filter(function (e) { return e !== 'No equipment'; });
                            }
                        }
                    }
                });
            }
            else {
                workOrderWellnessTask.wellnessTask.wellnessTaskEquipment.forEach(function (wte) {
                    if (!bucket.scheduledBucketEquipment
                        .find(function (equip) { return equip.equipment.equipmentType.type === wte.equipmentType.type; })) {
                        if (wte.equipmentType.type !== 'No Equipment') {
                            errors.push(wte.equipmentType.type + ' not in crew');
                        }
                        else {
                            if (bucketErrors.some(function (be) { return be === 'No equipment'; })) {
                                bucketErrors = bucketErrors.filter(function (e) { return e !== 'No equipment'; });
                            }
                        }
                    }
                });
            }
            // Skills of all the employees.
            var skills = bucket.scheduledBucketEmployees
                .map(function (sbe) { return sbe.employee.employeeSkills; }).reduce(function (acc, curr) { return acc.concat(curr); }, []);
            // If the skill name isn't found in the bucket's skills
            workOrderWellnessTask.wellnessTask.wellnessTaskSkills.forEach(function (wts) {
                if (!skills.find(function (bucketSkill) { return bucketSkill.skill.name === wts.skill.name; })) {
                    errors.push(wts.skill.name + ' not in crew');
                }
            });
        });
        var workOrderWorkTasks = bucket.scheduledBucketWorkWorkOrders.map(function (wo) { return wo.workWorkOrder.workOrderWorkTasks
            .filter(function (task) { return task.workTask.currentBucketId === bucket.id || task.workTask.goBackBucketId === bucket.id; }); })
            .reduce(function (acc, curr) { return acc.concat(curr); }, []);
        workOrderWorkTasks.forEach(function (workOrderWorkTask) {
            if (workOrderWorkTask.workTask.isGoBack && bucket.id === workOrderWorkTask.workTask.goBackBucketId) {
                workOrderWorkTask.workTask.workTaskGoBackEquipment.forEach(function (wtgbe) {
                    if (!bucket.scheduledBucketEquipment
                        .find(function (equip) { return equip.equipment.equipmentType.type === wtgbe.equipmentType.type; })) {
                        if (wtgbe.equipmentType.type !== 'No Equipment') {
                            errors.push(wtgbe.equipmentType.type + ' not in crew');
                        }
                        else {
                            if (bucketErrors.some(function (be) { return be === 'No equipment'; })) {
                                bucketErrors = bucketErrors.filter(function (e) { return e !== 'No equipment'; });
                            }
                        }
                    }
                });
            }
            else {
                workOrderWorkTask.workTask.workTaskEquipment.forEach(function (wte) {
                    if (!bucket.scheduledBucketEquipment
                        .find(function (equip) { return equip.equipment.equipmentType.type === wte.equipmentType.type; })) {
                        if (wte.equipmentType.type !== 'No Equipment') {
                            errors.push(wte.equipmentType.type + ' not in crew');
                        }
                        else {
                            if (bucketErrors.some(function (be) { return be === 'No equipment'; })) {
                                bucketErrors = bucketErrors.filter(function (e) { return e !== 'No equipment'; });
                            }
                        }
                    }
                });
            }
            // Skills of all the employees.
            var skills = bucket.scheduledBucketEmployees
                .map(function (sbe) { return sbe.employee.employeeSkills; }).reduce(function (acc, curr) { return acc.concat(curr); }, []);
            // If the skill name isn't found in the bucket's skills
            workOrderWorkTask.workTask.workTaskSkills.forEach(function (wts) {
                if (!skills.find(function (bucketSkill) { return bucketSkill.skill.name === wts.skill.name; })) {
                    errors.push(wts.skill.name + ' not in crew');
                }
            });
        });
        // Concat wos errors with bucket errors so all errors will be shown.
        errors = bucketErrors.concat(errors);
        // Filter out repeating errors
        bucketErrors = errors.filter(function (item, pos) { return errors.indexOf(item) === pos; });
        return bucketErrors;
    };
    BucketValidationHelperService.ngInjectableDef = i0.defineInjectable({ factory: function BucketValidationHelperService_Factory() { return new BucketValidationHelperService(i0.inject(i1.SpecificationService)); }, token: BucketValidationHelperService, providedIn: "root" });
    return BucketValidationHelperService;
}());
export { BucketValidationHelperService };
