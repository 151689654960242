import { Injectable } from '@angular/core';
import { WellnessTask, WorkTask, WorkWorkOrder, WellnessWorkOrder, QuoteWorkOrder,
  PriorityLevel,
  WellnessTaskCompletionStatus,
  WorkTaskCompletionStatus} from '../services_autogenerated/generated_services';
import { CalculatedPriorityAgingColorService } from './calculated-priority-aging-color.service';

@Injectable({
  providedIn: 'root'
})
/// <summary>
/// This thing's job is to eat a work order or a task and get the Priority Rank, based on whether it's manual or calculated.
/// Do we need to make it on the backend?
/// </summary>
export class PriorityService {

  constructor(
    private calculatedPriorityAgingService: CalculatedPriorityAgingColorService
  ) { }

  GetTaskPriority(task: WellnessTask | WorkTask): PriorityLevel {
    if (!task.hasCalculatedPriority) {

      if (task.manualPriorityLevel.id === 1) {
        // go backs are second
        task.manualPriorityLevel.order = -9999999999;
      }

      return task.manualPriorityLevel;
    } else {
      return this.calculatedPriorityAgingService.getPriority(task);
    }
  }

  GetMostUrgentPriorityLevel(wo: QuoteWorkOrder | WorkWorkOrder | WellnessWorkOrder): PriorityLevel {
    if (wo instanceof QuoteWorkOrder) {
      const wellness = this.GetMostUrgentWellnessPriorityLevel(wo);
      const work = this.GetMostUrgentWorkPriorityLevel(wo);
      if (wellness && work) {
        return wellness.order < work.order ? wellness : work;
      } else if (wellness) {
        return wellness;
      } else {
        return work; // OK if undefined
      }
    } else if (wo instanceof WorkWorkOrder) {
      return this.GetMostUrgentWorkPriorityLevel(wo);
    } else { // (wo instanceof WellnessWorkOrder)
      return this.GetMostUrgentWellnessPriorityLevel(wo);
    }
  }

  // TODO include or exclude those that are scheduled?
  private GetMostUrgentWellnessPriorityLevel(wo: WellnessWorkOrder | QuoteWorkOrder): PriorityLevel {
    if (wo.workOrderWellnessTasks.length > 0) {
      const tasksNotComplete = wo.workOrderWellnessTasks
        .filter(x => x.wellnessTask.active
          && x.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Completed
          && x.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed);
      const taskPriorities = tasksNotComplete.map(x => this.GetTaskPriority(x.wellnessTask));
      if (tasksNotComplete.length) {
        return taskPriorities.reduce((prev, curr) => {
          if (!curr) {
            return prev;
          } else {
            return (prev.order < curr.order) ? prev : curr;
          }
        }, { order: Infinity } as PriorityLevel);
      } else {
        const completedPriority = new PriorityLevel({
          order: Infinity, backgroundColor: 'black', textColor: 'white', status: 'Completed'
        });
        return completedPriority;
      }
    } else {
      return null;
    }
  }

  private GetMostUrgentWorkPriorityLevel(wo: WorkWorkOrder | QuoteWorkOrder): PriorityLevel {
    if (wo.workOrderWorkTasks.length > 0) {
      const tasksNotComplete = wo.workOrderWorkTasks
        .filter(x => x.workTask.active
          && x.workTask.completionStatus !== WorkTaskCompletionStatus.Completed
          && x.workTask.completionStatus !== WorkTaskCompletionStatus.Unable_to_be_Completed);
      const taskPriorities = tasksNotComplete.map(x => this.GetTaskPriority(x.workTask));
      if (tasksNotComplete.length) {
        return taskPriorities.reduce((prev, curr) => {
          if (!curr) {
            return prev;
          } else {
            return (prev.order < curr.order) ? prev : curr;
          }
        }, { order: Infinity } as PriorityLevel);
      } else {
        const completedPriority = new PriorityLevel({
          order: Infinity, backgroundColor: 'black', textColor: 'white', status: 'Completed'
        });
        return completedPriority;
      }
    } else {
      return null;
    }
  }
}
