import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseWellnessViewComponent } from '../base-wellness-view/base-wellness-view.component';
import { TwoDayCalendarViewComponent } from '../two-day-calendar-view/two-day-calendar-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
var WellnessDayViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WellnessDayViewComponent, _super);
    function WellnessDayViewComponent(dragAndDropService) {
        var _this = _super.call(this, dragAndDropService) || this;
        _this.selectedView = 'day';
        return _this;
    }
    WellnessDayViewComponent.prototype.ngOnInit = function () {
    };
    return WellnessDayViewComponent;
}(BaseWellnessViewComponent));
export { WellnessDayViewComponent };
