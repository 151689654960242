import { Component, OnInit, ViewChild } from '@angular/core';
import { AdvancedInvoiceFilters,
          StripeGenSvc,
          WellnessTaskCompletionStatus,
          WellnessTaskPaymentStatus,
          WellnessWorkOrderGenSvc,
          WorkOrderGenSvc,
          WorkOrderInvoicingViewModel,
          WorkTaskCompletionStatus,
          WorkWorkOrderGenSvc } from 'src/app/services_autogenerated/generated_services';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';

@Component({
  selector: 'app-invoice-full-page',
  templateUrl: './invoice-full-page.component.html',
  styleUrls: ['./invoice-full-page.component.css']
})
export class InvoiceFullPageComponent implements OnInit {
  @ViewChild(Table) table: Table;

  canSeeHiddenPrice: boolean;
  cols: any[];
  isLoading: boolean = false;
  totalRecords: number;

  rows: number = 10;

  searchTerm: string = '';
  searchInput = new Subject<string>();

  filterBarInput = new Subject<string[]>();

  allWorkOrders: WorkOrderInvoicingViewModel[] = [];

  advancedFilters: AdvancedInvoiceFilters = new AdvancedInvoiceFilters({
    shouldFilterByWOStatus: true,
    shouldFilterByPaymentStatus: true,
    shouldFilterByRM: false,
    shouldFilterByType: true,
    services: [],
    completionStatuses: [ WellnessTaskCompletionStatus.Completed, WellnessTaskCompletionStatus.Partially_Complete ],
    rMs: [],
    paymentStatuses: [ WellnessTaskPaymentStatus.Not_Paid ],
    skip: 0,
    take: this.rows,
    globalFilter: '',
    orderBy: 'Number ASC'
  });

  rms: SelectItem[];

  woStatuses: SelectItem[] = [
    { label: WellnessTaskCompletionStatus.Completed, value: WellnessTaskCompletionStatus.Completed},
    { label: WellnessTaskCompletionStatus.Partially_Complete, value: WellnessTaskCompletionStatus.Partially_Complete },
    { label: WellnessTaskCompletionStatus.Created, value: WellnessTaskCompletionStatus.Created},
    { label: WellnessTaskCompletionStatus.Customer_Not_Notified, value: WellnessTaskCompletionStatus.Customer_Not_Notified },
    { label: WellnessTaskCompletionStatus.Customer_Notified, value: WellnessTaskCompletionStatus.Customer_Notified },
    { label: WellnessTaskCompletionStatus.Ready_to_Schedule, value: WellnessTaskCompletionStatus.Ready_to_Schedule },
    { label: WellnessTaskCompletionStatus.Unable_to_be_Completed, value: WellnessTaskCompletionStatus.Unable_to_be_Completed }
  ];

  paymentStatuses: SelectItem[] = [
    { label: 'Not Paid', value: 'Not Paid'},
    { label: 'Invoiced', value: 'Invoiced'},
    { label: 'Paid', value: 'Paid'}
  ];

  serviceTypes: SelectItem[] = [
    { label: 'Tree Wellness', value: 'Tree Wellness'},
    { label: 'Tree Work', value: 'Tree Work'}
  ];

  lastLazyEvent: LazyLoadEvent;

  showAddNoteDialog: boolean;
  addNoteWorkOrderRow: WorkOrderInvoicingViewModel;

  showAddCashDialog: boolean;
  addCashWorkOrderRow: WorkOrderInvoicingViewModel;

  showInvoicesDialog: boolean;
  showInvoicesRow: WorkOrderInvoicingViewModel;

  showChargeCardDialog: boolean;
  chargeCardWorkOrderRow: WorkOrderInvoicingViewModel;

  showRefundDialog: boolean;
  refundWorkOrderRow: WorkOrderInvoicingViewModel;

  showFinalizeLoading: boolean;

  claimNames: string[];

  constructor(
    private workOrderService: WorkOrderGenSvc,
    private messageService: MessageService,
    private authHelper: AuthHelperService,
    private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
    private workWorkOrderService: WorkWorkOrderGenSvc,
    private workOrderHelper: WorkOrderHelperService,
    private stripeService: StripeGenSvc
  ) { }

  ngOnInit() {
    this.cols = [
      { header: 'Number', width: '7%' },
      { field: 'FullName', header: 'Customer Name', width: '10%' },
      { field: 'PhoneNumber', header: 'Phone Number', width: '8%' },
      { field: 'ServiceType', header: 'Service Type', width: '8%' },
      { field: 'WOStatus', header: 'WO Status', width: '9%' },
      { field: 'DateCompleted', header: 'Date Completed', width: '9%' },
      { field: 'PaymentStatus', header: 'Payment Status', width: '8%' },
      { field: 'PreferredPayment', header: 'Preferred Payment', width: '9.5%' },
      { field: 'CardOnFile', header: 'Card On File?', width: '7%' },
      { header: 'Amount Due', width: '7%' },
      { header: 'Payment Options', width: '9%' },
      { field: 'LatestNote', header: 'Latest Note', width: '8.5%' }
    ];

    const token = this.authHelper.getDecodedAccessToken();
    this.claimNames = token.claimNames;

    this.isLoading = true;

    this.setUpSearchDelay();
    this.setUpFilterBarDelay();
  }

  setUpFilterBarDelay() {
    this.filterBarInput.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(() => {
        this.filterFromBar();
      });
  }

  setUpSearchDelay() {
    this.searchInput.pipe(
      debounceTime(600),
      distinctUntilChanged())
      .subscribe(() => {
        this.filterFromBar();
      });
  }

  loadRequestLazy(event: LazyLoadEvent) {
    this.lastLazyEvent = event;
    const sortOption = this.getSortOption(event.sortField, event.sortOrder);

    this.isLoading = true;

    this.advancedFilters.skip = event.first;
    this.advancedFilters.take = event.rows;
    this.advancedFilters.orderBy = sortOption;
    this.advancedFilters.globalFilter = this.searchTerm ? this.searchTerm : '';

    this.workOrderService.getPaginated(this.advancedFilters)
      .subscribe(res => {
        this.allWorkOrders = res.results;
        this.totalRecords = res.totalResults;
        this.isLoading = false;

        // Only set these if they haven't been set yet.
        if (!this.rms) {
          this.rms = this.getUniqueSelectItems(res.rMs);
        }
        if (!this.woStatuses) {
          this.woStatuses = this.getUniqueSelectItems(res.completionStatuses);
        }

        this.allWorkOrders.forEach(wo => this.getAmountDue(wo));
      },
      error => {
        this.isLoading = false;
        this.addErrorMessage('Could not load the work orders, please refresh and try again.');
      });
  }

  getAmountDue(workOrder: WorkOrderInvoicingViewModel) {
    if (workOrder.paymentStatus === 'Paid') {
      workOrder.amountDue = 0;
      return;
    }
    if (workOrder.serviceType === 'Tree Wellness') {
      this.wellnessWorkOrderService.get(workOrder.workOrderId).subscribe(wo => {
        workOrder.cashCollected = wo.workOrderCashCollected.reduce((acc, curr) => curr.invoiced ? acc : acc + curr.amount, 0);
        workOrder.hasHourlyRateTask = wo.workOrderWellnessTasks.some(wowt => !wowt.wellnessTask.fixedPrice);
        if (workOrder.workOrderBilling && workOrder.workOrderBilling.amountDueFromInvoices !== undefined) {
          workOrder.workOrderTotal = workOrder.workOrderBilling.amountDueFromInvoices;
          workOrder.amountDue = workOrder.workOrderBilling.amountDueFromInvoices - workOrder.cashCollected;
        } else {
          workOrder.workOrderTotal = this.workOrderHelper.totalAmount(wo, false);
          workOrder.amountDue = workOrder.workOrderTotal - workOrder.cashCollected;
        }
      });
    } else {
      this.workWorkOrderService.get(workOrder.workOrderId).subscribe(wo => {
        workOrder.cashCollected = wo.workOrderCashCollected.reduce((acc, curr) => curr.invoiced ? acc : acc + curr.amount, 0);
        workOrder.hasHourlyRateTask = wo.workOrderWorkTasks.some(wowt => !wowt.workTask.fixedPrice
                                                                          && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Completed
                                                                          && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Customer_Satisfied
                                                                          && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                                                                          && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Go_Back
                                                                          && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Unable_to_be_Completed);
        if (workOrder.workOrderBilling && workOrder.workOrderBilling.amountDueFromInvoices !== undefined) {
          workOrder.workOrderTotal = workOrder.workOrderBilling.amountDueFromInvoices;
          workOrder.amountDue = workOrder.workOrderBilling.amountDueFromInvoices - workOrder.cashCollected;
        } else {
          workOrder.workOrderTotal = this.workOrderHelper.totalAmount(wo, false);
          workOrder.amountDue = workOrder.workOrderTotal - workOrder.cashCollected;
        }
      });
    }
  }

  filterFromBar() {
    this.isLoading = true;

    this.advancedFilters.shouldFilterByType = this.advancedFilters.services.length > 0;
    this.advancedFilters.shouldFilterByWOStatus = this.advancedFilters.completionStatuses.length > 0;
    this.advancedFilters.shouldFilterByPaymentStatus = this.advancedFilters.paymentStatuses.length > 0;
    this.advancedFilters.shouldFilterByRM = this.advancedFilters.rMs.length > 0;

    const lazyRequest = {
      ...this.lastLazyEvent,
      first: 0,
      rows: this.rows
    };
    this.table.first = 0;
    this.loadRequestLazy(lazyRequest);
  }

  getSortOption(field: string, order: number) {
    if (order === 1) {
      return field + ' ASC';
    } else {
      return field + ' DESC';
    }
  }

  navigateToCustomer(workOrder: WorkOrderInvoicingViewModel) {
    window.open('customerLandingPage/' + workOrder.customerId, '_blank');
  }

  navigateToWorkOrder(workOrder: WorkOrderInvoicingViewModel) {
    if (workOrder.serviceType === 'Tree Wellness') {
      window.open('wellnessWorkOrderMaintenance/' + workOrder.workOrderId, '_blank');
    } else {
      window.open('workWorkOrderMaintenance/' + workOrder.workOrderId, '_blank');
    }
  }

  addNoteForRow(workOrder: WorkOrderInvoicingViewModel) {
    this.addNoteWorkOrderRow = workOrder;
    this.showAddNoteDialog = true;
  }

  closedDialog(refresh: boolean) {
    this.showAddNoteDialog = false;
    this.addNoteWorkOrderRow = null;
    this.showAddCashDialog = false;
    this.addCashWorkOrderRow = null;
    this.showChargeCardDialog = false;
    this.chargeCardWorkOrderRow = null;
    this.showRefundDialog = false;
    this.refundWorkOrderRow = null;
    this.showFinalizeLoading = false;
    this.showInvoicesRow = null;
    this.showInvoicesDialog = false;

    if (refresh) {
      this.loadRequestLazy(this.lastLazyEvent);
    }
  }

  openInvoiceForRow(workOrder: WorkOrderInvoicingViewModel) {
    this.showInvoicesRow = workOrder;
    this.showInvoicesDialog = true;
  }

  addCashForRow(workOrder: WorkOrderInvoicingViewModel) {
    this.addCashWorkOrderRow = workOrder;
    this.showAddCashDialog = true;
  }

  chargeInvoiceForRow(workOrder: WorkOrderInvoicingViewModel) {
    this.chargeCardWorkOrderRow = workOrder;
    this.showChargeCardDialog = true;
  }

  refundInvoiceForRow(workOrder: WorkOrderInvoicingViewModel) {
    this.refundWorkOrderRow = workOrder;
    this.showRefundDialog = true;
  }

  finalize(workOrder: WorkOrderInvoicingViewModel) {
    this.showFinalizeLoading = true;
    this.chargeInvoice(workOrder, true);
  }

  chargeInvoice(workOrder: WorkOrderInvoicingViewModel, skipConfirm: boolean = false) {
    const type = workOrder.serviceType === 'Tree Wellness' ? 'Wellness' : 'Work';
    const chargeMessage = workOrder.wOStatus === 'Partially Complete' || workOrder.showAsPartiallyComplete ? 'This will charge the open invoices for this work order.' : 'This will charge the customer for all the tasks on this WO.';
    if (skipConfirm || confirm(chargeMessage)) {
      this.stripeService.generateInvoiceAndCharge(workOrder.workOrderId, type).subscribe(res => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Invoice successfully processed through Stripe.'
        });
        this.closedDialog(true);
      }, error => {
        if (error.status === 400) {
          if (skipConfirm || confirm('This invoice has already been paid, mark it as paid now?')) {
            this.stripeService.markAsPaid(workOrder.workOrderId, type).subscribe(() => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Invoice has been marked as paid.'
              });
              this.closedDialog(true);
            });
          }
        } else {
          if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Processing Payment',
              detail: JSON.parse(error.response).ExceptionMessage,
              sticky: true
            });
          } else {
            this.addErrorMessage('An unexpected error occurred. Contact an admin or support.');
          }
        }
      });
    }
  }

  private getUniqueSelectItems(arr: string[]): SelectItem[] {
    const unique = arr.map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]).sort();

    return unique.map(u => ({label: u, value: u}));
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }
}
