import { Customer, CustomerContact, EmailAddress, Address } from '../services_autogenerated/generated_services';
import { CustomerTypes } from '../models/enums/customerTypes';
import * as i0 from "@angular/core";
var CustomerService = /** @class */ (function () {
    function CustomerService() {
        var _this = this;
        this.customerSearch = function (searchTerm, customer) {
            searchTerm = searchTerm ? searchTerm.toLowerCase() : searchTerm;
            var activeStatus = customer.active ? 'Active' : 'Not Active';
            var taxStatus = customer.taxExempt ? 'Tax Exempt' : 'Taxable';
            var customerName = _this.getCustomerFullName(customer);
            var customerType = customer.customerType.name;
            var customerNameMatches = customerName.toLowerCase().includes(searchTerm);
            var addressMatches = customer.address.city.toLowerCase().includes(searchTerm) ||
                customer.address.zip.toLowerCase().includes(searchTerm) ||
                customer.address.street.toLowerCase().includes(searchTerm) ||
                customer.address.state.name.toLowerCase().includes(searchTerm);
            var activeStatusMatches = activeStatus.toLowerCase().includes(searchTerm);
            var taxStatusMatches = taxStatus.toLowerCase().includes(searchTerm);
            var customerTypeMatches = customerType.toLowerCase().includes(searchTerm);
            return customerNameMatches || addressMatches || activeStatusMatches || taxStatusMatches || customerTypeMatches;
        };
    }
    CustomerService.prototype.getPrimaryContact = function (customer) {
        var contact = new CustomerContact();
        if (customer && customer.customerContacts) {
            var existingContact = customer.customerContacts.find(function (x) { return x.active === true && x.primary; });
            if (existingContact) {
                contact = existingContact;
            }
        }
        return contact;
    };
    CustomerService.prototype.getCustomerFullName = function (customer) {
        if (customer.customerTypeId === CustomerTypes.Residential) {
            return this.getPrimaryContact(customer).fullName;
        }
        else {
            return customer.companyName;
        }
    };
    CustomerService.prototype.getCustomerFullNameWithAddress = function (customer) {
        // tslint:disable-next-line: max-line-length
        return this.getCustomerFullName(customer) + " - " + customer.address.street + ", " + customer.address.city + " " + (customer.address.state != null ? customer.address.state.abbreviation : '') + ", " + customer.address.zip;
    };
    CustomerService.prototype.newCustomer = function () {
        var customer = new Customer();
        customer.address = new Address();
        customer.customerContacts = [new CustomerContact()];
        customer.customerContacts[0].emailAddress = new EmailAddress();
        customer.active = true;
        customer.paymentInfoRequired = true;
        return customer;
    };
    CustomerService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
