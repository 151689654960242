import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MaskService } from 'src/app/services/mask.service';
import {
  WellnessWorkOrder,
  WorkWorkOrder,
  QuoteWorkOrder,
  WorkOrderPriceAdjustment,
  WellnessWorkOrderCashCollected,
  WorkWorkOrderCashCollected,
  PriceAdjustment} from '../../services_autogenerated/generated_services';
import { ControlContainer, NgForm } from '@angular/forms';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-work-order-maintenance-common-fields-bottom',
  templateUrl: './work-order-maintenance-common-fields-bottom.component.html',
  styleUrls: ['./work-order-maintenance-common-fields-bottom.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
    // Sourced from https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
})
export class WorkOrderMaintenanceCommonFieldsBottomComponent implements OnInit {
  @Input() workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder;
  @Input() isEditable: boolean;
  @Input() isCustomer: boolean;
  @Input() canCompleteWO: boolean;
  @Input() hidePrice: boolean;
  @Input() isQuote = false;
  thing = true;
  cashIndex = 0;

  canCollectCash: boolean;
  canSeePrice: boolean;
  stage: string;
  cashCollected: WellnessWorkOrderCashCollected[] | WorkWorkOrderCashCollected[] = [];
  paymentInfoRequired = true;

  @Output() priceAdjustmentDeleted = new EventEmitter<WorkOrderPriceAdjustment>();

  currencyMask: any;
  claimNames: any;

  constructor(
    private maskService: MaskService,
    public helper: WorkOrderHelperService,
    private authHelpder: AuthHelperService
  ) {
   }

  ngOnInit() {
    this.paymentInfoRequired = this.workOrder.customer.paymentInfoRequired;
    if (!this.paymentInfoRequired) {
      this.workOrder.autoChargeEnabled = false;
    }
    // this.workOrder['workOrderCashCollected'] = [];
    // this.pushNewCashCollectedEntry();
    // if (this.workOrder['workOrderCashCollected'] && this.workOrder['workOrderCashCollected'].length > 0) {

    // } else {
    //   this.workOrder['workOrderCashCollected'] = [];
    //   this.pushNewCashCollectedEntry();
    // }
    this.currencyMask = this.maskService.currencyMask;
    if (this.workOrder instanceof QuoteWorkOrder) {
      this.stage = 'quote';
    } else {
      this.stage = 'work order';
    }
    this.claimNames = this.authHelpder.getDecodedAccessToken().claimNames;
    this.canCollectCash = this.claimNames.includes('Completing a Task');
    this.canSeePrice = !this.hidePrice ||  this.claimNames.includes('View Hidden Prices');
  }

  addPriceAdjustment() {
    const wopa = new WorkOrderPriceAdjustment();
    wopa.priceAdjustment = new PriceAdjustment();
    wopa.priceAdjustment.active = true;
    this.workOrder.workOrderPriceAdjustments.push(wopa);
  }

  hasCustomerMaintenancePermissions() {
    return this.claimNames.includes('View/Edit Customer Maint.');
  }

  deletePriceAdjustment(wopa: WorkOrderPriceAdjustment) {
    this.priceAdjustmentDeleted.emit(wopa);
  }

  // hasCashCollectedField(): boolean {
  //   if (this.workOrder) {
  //     return Object.keys(this.workOrder).indexOf('cashCollected') >= 0
  //   } else {
  //     return false;
  //   }
  // }

  cashCollectedChange(newValue: string, index: number) {
    if (newValue && newValue.length > 0 && newValue[0] === '$') {
      if (newValue[0] === '$') {
        newValue = newValue.replace('$', '');
      }
      newValue = newValue.split(',').join('');
    }
    if (newValue && newValue.length > 0) {
      this.workOrder['workOrderCashCollected'][index].amount = parseFloat(newValue);
    } else {
      this.workOrder['workOrderCashCollected'][index].amount = 0;
    }
  }

  removeCashCollectedEntry(index) {
    this.workOrder['workOrderCashCollected'].splice(index, 1);
  }

  // I don't really like this work around
  // But Quotes do not have a property that is needed for Work Orders
  // So pass the variable back as a WO to get at that property
  asWorkOrderNotQuote() {
    return this.workOrder as WellnessWorkOrder | WorkWorkOrder;
  }

  isCashCollectedEditable() {
    return this.canCollectCash && this.isEditable;
  }
}
