import { Injectable, ViewChild, Inject } from '@angular/core';
import { BaseSchedulingViewComponent } from '../base-scheduling-view/base-scheduling-view.component';
import { BaseCalendarComponent } from '../base-calendar/base-calendar.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

import { WellnessWorkOrder, EmployeeSkill, WellnessTask } from '../../services_autogenerated/generated_services';

@Injectable()
export abstract class BaseWellnessViewComponent extends BaseSchedulingViewComponent {
    @ViewChild(BaseCalendarComponent) calendarComponent: BaseCalendarComponent;

    constructor(
        @Inject(DragAndDropService) dragAndDropService: DragAndDropService
    ) {
        super(dragAndDropService);
    }

    // This captures the event emitter object thing from
    // scheduled-item-bucket-card.component so it can gray out the
    // buckets that don't meet the WO's requirements
    onWoDragged(event: {workOrder: WellnessWorkOrder, bucketId: number}) {
      this.woDragStart(event.workOrder, event.bucketId);
    }

    // This captures the event emitter object thing from
    // scheduled-item-bucket-card.component so it can ungray out
    // all the buckets
    onWoDraggedEnd() {
      this.clearGrayOverlayOnBuckets();
    }

    // these are on the wellness base because we are only checking for wellness task errors
    woDragStart(workOrder: WellnessWorkOrder, bucketId?: number) {
      // Get all the bucket components and check the bucket to see if the component needs to be grayed out
      const bucketComponets = this.calendarComponent.bucketCardComponents;

      bucketComponets.forEach(bucketComponet => {
        // if we have a bucket id then we only want to look at the tasks that are schedueld on that bucket
        // if there is not a bucket id, we only want the tasks that are not scheduled
        // This prevents graying out of buckets because a previously scheduled task needed equipment
        // that the unschedule task does not need.
        workOrder.workOrderWellnessTasks.filter(wowt => bucketId ? wowt.wellnessTask.currentBucketId === bucketId : !wowt.wellnessTask.currentBucketId).forEach(wowt => {
          // If the equipment type isn't found in the bucket's equipment, gray it out
          wowt.wellnessTask.wellnessTaskEquipment.forEach(wte => {
            if (!bucketComponet.bucket.scheduledBucketEquipment
                .find(sbe => sbe.equipment.equipmentType.type === wte.equipmentType.type)) {
              bucketComponet.grayOut = true;
            }
          });

          // Skills of all the employees.
          const skillsInBucket: EmployeeSkill[] = bucketComponet.bucket.scheduledBucketEmployees
            .map(sbe => sbe.employee.employeeSkills).reduce((acc, curr) => acc.concat(curr), []);

          // If the skill name isn't found in the bucket's skills, gray it out
          wowt.wellnessTask.wellnessTaskSkills.forEach(wts => {
            if (!skillsInBucket.find(sib => sib.skill.name.toLowerCase().includes(wts.skill.name.toLowerCase()))) {
              bucketComponet.grayOut = true;
            }
          });
        });
      });
    }

    clearGrayOverlayOnBuckets() {
      // Clear the gray overlay when WO is done being dragged
      const bucketComponets = this.calendarComponent.bucketCardComponents;

      bucketComponets.forEach(bucketComponet => {
        bucketComponet.grayOut = false;
      });
    }

    taskDragStart(wellnessTask: WellnessTask) {
      // Get all the bucket components and check the bucket to see if the component needs to be grayed out
      const bucketComponets = this.calendarComponent.bucketCardComponents;

      bucketComponets.forEach(bucketComponet => {
        wellnessTask.wellnessTaskEquipment.forEach(wte => {
          if (!bucketComponet.bucket.scheduledBucketEquipment
              .find(sbe => sbe.equipment.equipmentType.type === wte.equipmentType.type)) {
            bucketComponet.grayOut = true;
          }
        });

        // Skills of all the employees.
        const skillsInBucket: EmployeeSkill[] = bucketComponet.bucket.scheduledBucketEmployees
          .map(sbe => sbe.employee.employeeSkills).reduce((acc, curr) => acc.concat(curr), []);

        // If the skill name isn't found in the bucket's skills, gray it out
        wellnessTask.wellnessTaskSkills.forEach(wts => {
          if (!skillsInBucket.find(sib => sib.skill.name.toLowerCase().includes(wts.skill.name.toLowerCase()))) {
            bucketComponet.grayOut = true;
          }
        });
      });
    }

    taskDragEnd() {
      this.clearGrayOverlayOnBuckets();
    }
}
