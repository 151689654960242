import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthHelperService } from './services/auth-helper.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private authHelperService: AuthHelperService;

    constructor(private injector: Injector) {
       }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authHelperService = this.injector.get(AuthHelperService); // get it here within intercept

        const token = localStorage.getItem(this.authHelperService.authToken);
        // specifically do not want to add auth token to requests going from UI directly to Azure blob service
        const isAngularBlobRequest = request.url.startsWith('https://russelltreeappstorage.blob.core.windows.net');
        if (token && !isAngularBlobRequest) {
            const authRequest = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`)
            });
            return next.handle(authRequest);
        } else {
            return next.handle(request);
        }
    }
}
