import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Employee,
  WellnessWorkOrder,
  WorkWorkOrder,
  Customer,
  EquipmentType,
  QuoteWorkOrder,
  CustomerContact,
  WorkTask,
  WellnessTask} from '../services_autogenerated/generated_services';
import { LocationColorService } from '../services/location-color.service';
import { CustomerService } from '../services/customer.service';
import { FilteredWellnessWorkOrder } from '../models/filteredWellnessWorkOrder';
import { FilteredWorkWorkOrder } from '../models/filteredWorkWorkOrder';
import { FilteredQuoteWorkOrder } from '../models/filteredQuoteWorkOrder';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'workOrderFilter'
})
export class WorkOrderFilterPipe implements PipeTransform {
  constructor(private locationColorService: LocationColorService,
    private customCustomerService: CustomerService,
    private datePipe: DatePipe
    ) {}

  // Search methods
  transform(workOrders: WellnessWorkOrder[], searchTerm: string): WellnessWorkOrder[] {
    if (!workOrders) { return []; }
    if (!searchTerm) { return workOrders; }

    searchTerm = searchTerm.toLowerCase();

    return workOrders.filter(workOrder => {
      const basicWorkOrderMatch = this.checkForBasicMatch(workOrder, searchTerm);

      const customerMatches: boolean = this.checkForCustomerMatch(workOrder.customer, searchTerm);
      const workOrderAddressMatches = workOrder.address != null && (workOrder.address.city.toLowerCase().includes(searchTerm) ||
        workOrder.address.zip.toLowerCase().includes(searchTerm) || workOrder.address.street.toLowerCase().includes(searchTerm) ||
        workOrder.address.state.name.toLowerCase().includes(searchTerm));

      // const searchTermIsDate: boolean = moment(searchTerm, 'MM/DD/YYYY', true).isValid() ||
      //   moment(searchTerm, 'M/D/YYYY', true).isValid() || moment(searchTerm, 'MM-DD-YYYY', true).isValid() ||
      //   moment(searchTerm, 'M-D-YYYY', true).isValid();

        let createdDateMatches = this.dateMatchesSearch(workOrder.createdDate, searchTerm);

      // if (searchTermIsDate) {
      //   createdDateMatches = moment(searchTerm, 'MM/DD/YYYY', false).isSame(workOrder.createdDate, 'day');
      // }

      let wellnessTaskMatches = false;
      if (workOrder.workOrderWellnessTasks) {
        wellnessTaskMatches = this.checkForWellnessTaskMatch(workOrder, searchTerm);
      }

      return basicWorkOrderMatch || customerMatches || workOrderAddressMatches || createdDateMatches || wellnessTaskMatches;
    });
  }

  transformWork(workOrders: WorkWorkOrder[], searchTerm: string): WorkWorkOrder[] {
    if (!workOrders) { return []; }
    if (!searchTerm) { return workOrders; }

    searchTerm = searchTerm.toLowerCase();

    return workOrders.filter(workOrder => {
      const basicWorkOrderMatch = this.checkForBasicMatch(workOrder, searchTerm);

      const customerMatches: boolean = this.checkForCustomerMatch(workOrder.customer, searchTerm);
      const workOrderAddressMatches = workOrder.address != null && (workOrder.address.city.toLowerCase().includes(searchTerm) ||
        workOrder.address.zip.toLowerCase().includes(searchTerm) || workOrder.address.street.toLowerCase().includes(searchTerm) ||
        workOrder.address.state.name.toLowerCase().includes(searchTerm));

      // const searchTermIsDate: boolean = moment(searchTerm, 'MM/DD/YYYY', true).isValid() ||
      //   moment(searchTerm, 'M/D/YYYY', true).isValid() || moment(searchTerm, 'MM-DD-YYYY', true).isValid() ||
      //   moment(searchTerm, 'M-D-YYYY', true).isValid();

      let createdDateMatches = this.dateMatchesSearch(workOrder.createdDate, searchTerm);
      // if (searchTermIsDate) {
      //   createdDateMatches = moment(searchTerm, 'MM/DD/YYYY', false).isSame(workOrder.createdDate, 'day');
      // }

      let workTaskMatches = false;
      if (workOrder.workOrderWorkTasks) {
        workTaskMatches = this.checkForWorkTaskMatch(workOrder, searchTerm);
      }

      return basicWorkOrderMatch || customerMatches || workOrderAddressMatches || createdDateMatches || workTaskMatches;
    });
  }

  transformQuote(workOrders: QuoteWorkOrder[], searchTerm: string): QuoteWorkOrder[] {
    if (!workOrders) { return []; }
    if (!searchTerm) { return workOrders; }

    searchTerm = searchTerm.toLowerCase();

    return workOrders.filter(workOrder => {
      const basicWorkOrderMatch = this.checkForBasicMatch(workOrder, searchTerm);

      const customerMatches: boolean = this.checkForCustomerMatch(workOrder.customer, searchTerm);
      const workOrderAddressMatches = workOrder.address != null && (workOrder.address.city.toLowerCase().includes(searchTerm) ||
        workOrder.address.zip.toLowerCase().includes(searchTerm) || workOrder.address.street.toLowerCase().includes(searchTerm) ||
        workOrder.address.state.name.toLowerCase().includes(searchTerm));

      // const searchTermIsDate: boolean = moment(searchTerm, 'MM/DD/YYYY', true).isValid() ||
      //   moment(searchTerm, 'M/D/YYYY', true).isValid() || moment(searchTerm, 'MM-DD-YYYY', true).isValid() ||
      //   moment(searchTerm, 'M-D-YYYY', true).isValid();
      let createdDateMatches = this.dateMatchesSearch(workOrder.createdDate, searchTerm);
      let startDateMatches = this.dateMatchesSearch(workOrder.dueDateStart, searchTerm);
      let endDateMatches = this.dateMatchesSearch(workOrder.dueDateEnd, searchTerm);
    
      // this.datePipe.transform(workOrder.createdDate, 'MM/DD/YYYY');
      // createdDateMatches = moment(searchTerm, 'MM/DD/YYYY', false).isSame(workOrder.createdDate, 'day');
      // startDateMatches = moment(searchTerm, 'MM/DD/YYYY', false).isSame(workOrder.dueDateStart, 'day');
      // endDateMatches = moment(searchTerm, 'MM/DD/YYYY', false).isSame(workOrder.dueDateEnd, 'day');

      let workTaskMatches = false;
      if (workOrder.workOrderWorkTasks) {
        workTaskMatches = this.checkForWorkTaskMatch(workOrder, searchTerm);
      }

      let wellnessTaskMatches = false;
      if (workOrder.workOrderWellnessTasks) {
        wellnessTaskMatches = this.checkForWellnessTaskMatch(workOrder, searchTerm);
      }

      // Check for total hours match
      let wellnessHours = 0;
      let workHours = 0;
      let totalHoursMatch = false;
      
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      if (workOrder.workOrderWellnessTasks) {
        const wellnessTaskHoursArray = workOrder.workOrderWellnessTasks.map(x => x.wellnessTask.hours);
        if (wellnessTaskHoursArray.length > 0) {
          wellnessHours = wellnessTaskHoursArray.reduce(reducer)
        }
      }

      if (workOrder.workOrderWorkTasks) {
        const workTaskHoursArray = workOrder.workOrderWorkTasks.map(x => x.workTask.hours);
        if (workTaskHoursArray.length > 0) {
          workHours = workTaskHoursArray.reduce(reducer)
        }
      }

      if ((wellnessHours + workHours).toString().includes(searchTerm)) {
        totalHoursMatch = true;
      }
      //End check for total hours match

      return basicWorkOrderMatch || customerMatches || workOrderAddressMatches || createdDateMatches || startDateMatches
        || endDateMatches || workTaskMatches || wellnessTaskMatches || totalHoursMatch;
    });
  }

  private checkForBasicMatch(workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder, searchTerm: string): boolean {
    let quoteIdMatches = false;
    if (workOrder.quoteNumber) {
      quoteIdMatches = workOrder.quoteNumber.toLowerCase().includes(searchTerm);
    }

    let stageMatches = false;
    if (workOrder.completionStatus) {
      stageMatches = workOrder.completionStatus.toLowerCase().includes(searchTerm);
    }

    let noteMatches = false;
    if (workOrder.note) {
      noteMatches = workOrder.note.toLowerCase().includes(searchTerm);
    }

    let priorityMatches = false;
    if (workOrder.mostUrgentPriorityLevel && workOrder.mostUrgentPriorityLevel.status) {
      priorityMatches = workOrder.mostUrgentPriorityLevel.status.toLowerCase().includes(searchTerm);
    }

    const representativeMatches = workOrder.representative.fullName.toLowerCase().includes(searchTerm);

    return quoteIdMatches || stageMatches || noteMatches || priorityMatches || representativeMatches;
  }

  private checkForCustomerMatch(customer: Customer, searchTerm: string): boolean {
    const customerNameMatches: boolean = customer.customerContacts.map(cc => cc.fullName.toLowerCase()).some(name => name.includes(searchTerm))
                                        || (customer.companyName && customer.companyName.toLowerCase().includes(searchTerm));
    const addressMatches: boolean = customer.address != null && (customer.address.city.toLowerCase().includes(searchTerm) ||
      customer.address.zip.toLowerCase().includes(searchTerm) ||
      customer.address.street.toLowerCase().includes(searchTerm) ||
      customer.address.state.name.toLowerCase().includes(searchTerm));

    return customerNameMatches || addressMatches;
  }

  private checkForWellnessTaskMatch(workOrder: WellnessWorkOrder | QuoteWorkOrder, searchTerm: string): boolean {
    let equipmentMatch = false;
    let skillMatch = false;
    let gddMatch = false;
    let nameMatch = false;
    let timeMatch = false;
    const priorityMatch = false;
    let descriptionMatch = false;
    let startDateMatch = false;
    let endDateMatch = false;
    let totalHoursMatch = false;

    workOrder.workOrderWellnessTasks.forEach(wowt => {
      wowt.wellnessTask.wellnessTaskEquipment.forEach(wte => {
        if (this.checkForEquipmentMatch(wte.equipmentType, searchTerm)) {
          equipmentMatch = true;
        }
      });

      wowt.wellnessTask.wellnessTaskSkills.forEach(wts => {
        if (wts.skill.name.toLowerCase().includes(searchTerm) || wts.skill.shortName.toLowerCase().includes(searchTerm)) {
          skillMatch = true;
        }
      });

      if (wowt.wellnessTask.growingDegreeDay.toString().includes(searchTerm)) {
        gddMatch = true;
      }

      if (wowt.wellnessTask.name.toLowerCase().includes(searchTerm)) {
        nameMatch = true;
      }

      // hr is included in the string hrs so no need to check for hr separately
      const taskHours: string = wowt.wellnessTask.hours.toString() + ' hrs';
      if (taskHours.toLowerCase().includes(searchTerm)) {
        timeMatch = true;
      }

      // const searchTermIsDate: boolean = moment(searchTerm, 'MM/DD/YYYY', true).isValid() ||
      // moment(searchTerm, 'M/D/YYYY', true).isValid() || moment(searchTerm, 'MM-DD-YYYY', true).isValid() ||
      // moment(searchTerm, 'M-D-YYYY', true).isValid();

      // if (searchTermIsDate) {
      //   startDateMatch = moment(searchTerm, 'MM/DD/YYYY', false).isSame(wowt.wellnessTask.dueDateStart, 'day');
      //   endDateMatch = moment(searchTerm, 'MM/DD/YYYY', false).isSame(wowt.wellnessTask.dueDateEnd, 'day');
      // }
      if (this.dateMatchesSearch(wowt.wellnessTask.dueDateStart, searchTerm)) {
        startDateMatch = true;
      }
      if (this.dateMatchesSearch(wowt.wellnessTask.dueDateEnd, searchTerm)) {
        endDateMatch = true;
      }

      if (wowt.wellnessTask.description.toLowerCase().includes(searchTerm)) {
        descriptionMatch = true;
      }
    });

    return equipmentMatch || skillMatch || gddMatch || nameMatch || timeMatch || priorityMatch ||
      startDateMatch || endDateMatch || descriptionMatch;
  }

  private checkForWorkTaskMatch(workOrder: WorkWorkOrder | QuoteWorkOrder, searchTerm: string): boolean {
    let equipmentMatch = false;
    let skillMatch = false;
    let nameMatch = false;
    let timeMatch = false;
    const priorityMatch = false;
    let descriptionMatch = false;
    let startDateMatch = false;
    let endDateMatch = false;
    workOrder.workOrderWorkTasks.forEach(wowt => {
      wowt.workTask.workTaskEquipment.forEach(wte => {
        if (this.checkForEquipmentMatch(wte.equipmentType, searchTerm)) {
          equipmentMatch = true;
        }
      });

      wowt.workTask.workTaskSkills.forEach(wts => {
        if (wts.skill.name.toLowerCase().includes(searchTerm) || wts.skill.shortName.toLowerCase().includes(searchTerm)) {
          skillMatch = true;
        }
      });

      if (wowt.workTask.name.toLowerCase().includes(searchTerm)) {
        nameMatch = true;
      }

      // hr is included in the string hrs so no need to check for hr separately
      const taskHours: string = wowt.workTask.hours.toString() + ' hrs';
      if (taskHours.toLowerCase().includes(searchTerm)) {
        timeMatch = true;
      }

      // const searchTermIsDate: boolean = moment(searchTerm, 'MM/DD/YYYY', true).isValid() ||
      //   moment(searchTerm, 'M/D/YYYY', true).isValid() || moment(searchTerm, 'MM-DD-YYYY', true).isValid() ||
      //   moment(searchTerm, 'M-D-YYYY', true).isValid();

      // if (searchTermIsDate) {
      //   startDateMatch = moment(searchTerm, 'MM/DD/YYYY', false).isSame(wowt.workTask.dueDateStart, 'day');
      //   endDateMatch = moment(searchTerm, 'MM/DD/YYYY', false).isSame(wowt.workTask.dueDateEnd, 'day');
      // }
      if (this.dateMatchesSearch(wowt.workTask.dueDateStart, searchTerm)) {
        startDateMatch = true;
      }
      if (this.dateMatchesSearch(wowt.workTask.dueDateEnd, searchTerm)) {
        endDateMatch = true;
      }

      if (wowt.workTask.description.toLowerCase().includes(searchTerm)) {
        descriptionMatch = true;
      }
    });

    return equipmentMatch || skillMatch || nameMatch || timeMatch || priorityMatch ||
      startDateMatch || endDateMatch || descriptionMatch;
  }

  private checkForEquipmentMatch(equipmentType: EquipmentType, searchTerm: string): boolean {
    let typeMatches = false;

    const equipmentNameMatches = equipmentType.type.toLowerCase().includes(searchTerm);

    if (equipmentType.type.toLowerCase().includes(searchTerm)) {
      typeMatches = true;
    }

    return equipmentNameMatches || typeMatches;
  }

  public filterByGDD(workOrders: (WellnessWorkOrder | QuoteWorkOrder)[],
    gddStart: number, gddEnd: number): (WellnessWorkOrder | QuoteWorkOrder)[] {
    return workOrders.filter(wo => {
      return wo.workOrderWellnessTasks.some(wowt => {
        if (gddStart && gddEnd) {
          return wowt.wellnessTask.growingDegreeDay >= gddStart && wowt.wellnessTask.growingDegreeDay <= gddEnd;
        } else if (gddStart && !gddEnd) {
          return wowt.wellnessTask.growingDegreeDay >= gddStart;
        } else if (!gddStart && gddEnd) {
          return wowt.wellnessTask.growingDegreeDay <= gddEnd;
        } else {
          return true;
        }
      });
    });
  }

  public filterByDueDate(workOrders: (WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder)[], fromDate: Date, toDate: Date) {
    return workOrders.filter(wo => {
      let relevantTasks: (WorkTask | WellnessTask)[] = [];
      if (wo instanceof WellnessWorkOrder) {
        relevantTasks = wo.workOrderWellnessTasks.map(wowt => wowt.wellnessTask);
      } else if (wo instanceof WorkWorkOrder) {
        relevantTasks = wo.workOrderWorkTasks.map(wowt => wowt.workTask);
      } else if (wo instanceof QuoteWorkOrder) {
        relevantTasks = wo.workOrderWellnessTasks
          .map(wowt => wowt.wellnessTask)
          .concat(wo.workOrderWorkTasks
            .map(wowt => wowt.workTask as any));
      }

      return relevantTasks.some(task => {
        if (fromDate && toDate) {
          if (moment(fromDate).isAfter(moment(toDate))) {
            const temp = toDate;
            toDate = fromDate;
            fromDate = temp;
          }
          // https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap/5601502
          // (StartA <= EndB) and (EndA >= StartB), with A being filter and B being the task
          return moment(fromDate).isSameOrBefore(moment(task.dueDateEnd)) && moment(toDate).isSameOrAfter(moment(task.dueDateStart));
        } else if (fromDate && !toDate) {
          return moment(task.dueDateEnd).isSameOrAfter(moment(fromDate));
        } else if (!fromDate && toDate) {
          return moment(task.dueDateStart).isSameOrBefore(moment(toDate));
        } else {
          return true;
        }
      });
    });
  }

  public filterByGeoRegion(workOrders: (FilteredWellnessWorkOrder | FilteredWorkWorkOrder | FilteredQuoteWorkOrder)[],
      searchWorkOrder: (WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder), radius: number) {
    return workOrders.filter(wo => {
      if (!wo.address.latitude || !wo.address.longitude) {
        return false;
      }

      wo.distance = this.locationColorService.distance(
        wo.address.latitude, wo.address.longitude,
        searchWorkOrder.address.latitude, searchWorkOrder.address.longitude);

      return (wo.distance <= radius);
    });
  }

  public filterByPriority(workOrders: (WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder)[], selectedPriorityStatuses: string[]) {
    return workOrders.filter(wo => {
      return wo.mostUrgentPriorityLevel ? selectedPriorityStatuses.includes(wo.mostUrgentPriorityLevel.status) : false;
    });
  }

  public filterByRM(workOrders: (WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder)[], selectedRMs: Employee[]) {
    return workOrders.filter(wo => {
      return selectedRMs.some(rm => rm.id === wo.representative.id);
    });
  }

  private getDateTransforms(date: Date): string[] {
    const dateTransforms = [];
    const supportedDateSearchFormats = ['MM/dd/yyyy', 'MM/dd/yy', 'M/d/yyyy', 'M/d/yy', 'MM-dd-yyyy', 'MM-dd-yy', 'M-d-yyyy', 'M-d-yy'];
    supportedDateSearchFormats.forEach(format => dateTransforms.push(this.datePipe.transform(date, format)));
    return dateTransforms;
  }

  private dateMatchesSearch(date: Date, searchTerm: string): boolean {
    if (date) {
      const dateTransforms = this.getDateTransforms(date);
      return dateTransforms.some(transform => transform.includes(searchTerm));
    } else {
      return false;
    }
  }
}
