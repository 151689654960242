import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { QuoteRequest } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
  // Sourced from https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
})
export class ServicesSectionComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() quoteRequest: QuoteRequest;

  constructor() { }

  ngOnInit() { }

}
