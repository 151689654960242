import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ZipCodeAssignment,
  RegionalManagerTimeSlot,
  RegionalManagerTimeSlotZipCode,
  RegionalManagerTimeSlotGenSvc} from '../../../services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-day-zip-code-item',
  templateUrl: './day-zip-code-item.component.html',
  styleUrls: ['./day-zip-code-item.component.css']
})
export class DayZipCodeItemComponent implements OnInit {
  @Input() zipCodeAssignments: ZipCodeAssignment[];
  @Input() timeSlot: RegionalManagerTimeSlot;

  @Output() deletedItem = new EventEmitter<RegionalManagerTimeSlot>();

  zipCodeOptions: any[];

  saving = false;

  constructor(private timeSlotService: RegionalManagerTimeSlotGenSvc,
    private messageService: MessageService) { }

  ngOnInit() {
    if (this.timeSlot.regionalManagerTimeSlotZipCodes) {
      this.zipCodeOptions = this.timeSlot.regionalManagerTimeSlotZipCodes;
    } else {
      this.zipCodeOptions = [];
      this.timeSlot.regionalManagerTimeSlotZipCodes = [];
    }

    const otherZips = this.zipCodeAssignments.filter(zip =>
      !this.timeSlot.regionalManagerTimeSlotZipCodes.some(rmTimeSlotZip => rmTimeSlotZip.zipCodeId === zip.zipCodeId));

    this.zipCodeOptions = this.zipCodeOptions.concat(otherZips.map(zip => {
      return new RegionalManagerTimeSlotZipCode({
        regionalManagerTimeSlotId: this.timeSlot.id,
        zipCode: zip.zipCode,
        zipCodeId: zip.zipCode.id
      });
    }));

    this.zipCodeOptions = this.zipCodeOptions.map(assignedZip => {
      return {
        label: `${assignedZip.zipCode.code} - ${assignedZip.zipCode.city}`,
        value: assignedZip
      };
    });
  }

  save() {
    this.saving = true;

    if (this.timeSlot.id) {
      // Update time slot
      this.timeSlotService.update(this.timeSlot, this.timeSlot.id.toString()).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Update Successful',
          detail: 'The time slot was updated successfully.'
        });
        this.saving = false;
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Could not update the time slot, please refresh the page and try again.'
        });
        this.saving = false;
      });
    } else {
      // Add new time slot
      this.timeSlotService.add(this.timeSlot).subscribe((timeSlotResponse) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Add Successful',
          detail: 'The time slot was added successfully.'
        });
        this.timeSlot.id = timeSlotResponse.id;
        this.saving = false;
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Could not add the time slot, please refresh the page and try again.'
        });
        this.saving = false;
      });
    }
  }

  delete() {
    if (confirm('Are you sure you want to remove this record?')) {
      this.deletedItem.emit(this.timeSlot);
    }
  }
}
