<span class="ui-float-label">
  <input id="float-input-multiday-search" type="text" class="float-input" pInputText [(ngModel)]="searchTerm">
  <label for="float-input-multiday-search">
    <i class="pi pi-search"></i>
    Search
  </label>
</span>

<p-panel header="Equipment" [toggleable]="true" class="innerHeader">
  <div class="p-grid" id="equipmentPanelList" cdkDropList [cdkDropListData]="allEquipment | equipmentFilter: searchTerm"
    [cdkDropListConnectedTo]="multidayDragAndDropService.allCDKEquipmentListIds" (cdkDropListDropped)="drop($event)">
    <app-equipment-card class="p-md-4 p-sm-6 lessPad match-height-card"
      *ngFor="let equip of allEquipment | equipmentFilter: searchTerm" [equipment]="equip" cdkDrag
      (cdkDragStarted)="startDrag($event)">
    </app-equipment-card>
  </div>
</p-panel>
<p-panel header="Employees" [toggleable]="true" class="innerHeader">
  <div class="p-grid" id="employeePanelList" cdkDropList [cdkDropListData]="allEmployees | peopleFilter: searchTerm"
    [cdkDropListConnectedTo]="multidayDragAndDropService.allCDKEmployeeListIds" (cdkDropListDropped)="drop($event)">
    <app-employee-card class="p-md-4 p-sm-6 lessPad match-height-card"
      *ngFor="let employee of allEmployees | peopleFilter: searchTerm" [employee]="employee" cdkDrag>
    </app-employee-card>
  </div>
</p-panel>