import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, ViewChildren, QueryList, ViewChild, OnDestroy } from '@angular/core';
import {
    WellnessWorkOrderGenSvc,
    PriorityLevelGenSvc,
    ScheduledBucketWellnessWorkOrder,
    WorkOrderWellnessTask,
    WellnessTask,
    WorkWorkOrderGenSvc,
    WellnessTaskGenSvc,
    ScheduledBucketGenSvc,
    WorkWorkOrder,
    ScheduledBucketWorkWorkOrder,
    WorkOrderWorkTask,
    WorkTask,
    WellnessWorkOrder,
    WorkTaskGenSvc,
    WorkTaskCompletionStatus,
    WellnessTaskCompletionStatus,
    Employee,
} from '../../services_autogenerated/generated_services';
import { LocationColorService } from '../../services/location-color.service';
import { WorkOrderFilterPipe } from '../../pipes/work-order-filter.pipe';
import { CdkDragDrop, CdkDrag } from '@angular/cdk/drag-drop';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { WellnessWorkOrderCardComponent } from '../wellness-work-order-card/wellness-work-order-card.component';
import { IDroppable } from '../../models/IDroppable';
import { WorkWorkOrderCardComponent } from '../work-work-order-card/work-work-order-card.component';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderFilterBarComponent } from '../work-order-filter-bar/work-order-filter-bar.component';
import { FilteredWellnessWorkOrder } from 'src/app/models/filteredWellnessWorkOrder';
import { FilteredWorkWorkOrder } from 'src/app/models/filteredWorkWorkOrder';
import { FilteredQuoteWorkOrder } from 'src/app/models/filteredQuoteWorkOrder';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { BaseWorkOrderCardComponent } from '../base-work-order-card/base-work-order-card.component';

@Component({
    selector: 'app-work-orders-panel',
    templateUrl: './work-orders-panel.component.html',
    styleUrls: ['./work-orders-panel.component.css'],
    providers: [WorkOrderFilterPipe]
})
export class WorkOrdersPanelComponent implements OnInit, AfterViewInit, IDroppable, OnDestroy {
    @Input() selectedView: string;
    @Input() isWellnessOnly: boolean;
    @Input() isTreeWorkOnly: boolean;

    @Output() dragStartEvent = new EventEmitter<WellnessWorkOrder | WorkWorkOrder>();
    @Output() dragEndEvent = new EventEmitter<WellnessWorkOrder>();

    @Output() taskDraggedStartEvent = new EventEmitter<WellnessTask | WorkTask>();
    @Output() taskDraggedEndEvent = new EventEmitter();

    @ViewChild(WorkOrderFilterBarComponent) filterBarComponent: WorkOrderFilterBarComponent;

    @ViewChildren(WellnessWorkOrderCardComponent) wellnessWoCardComponents: QueryList<WellnessWorkOrderCardComponent>;
    @ViewChildren(WorkWorkOrderCardComponent) workWoCardComponents: QueryList<WorkWorkOrderCardComponent>;

    wellnessWorkOrders: WellnessWorkOrder[];
    filteredWellnessWorkOrders: WellnessWorkOrder[];

    workWorkOrders: WorkWorkOrder[];
    filteredWorkWorkOrders: WorkWorkOrder[];

    rms: Employee[];

    shouldShowLeftColor = true;
    shouldShowRightColor = true;

    isLoadingWOs = true;

    timerPaused: boolean = false;
    defaultTime: number = 300;
    timeLeft: number = this.defaultTime;
    interval;

    constructor(
        private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
        private workWorkOrderService: WorkWorkOrderGenSvc,
        private locationColorService: LocationColorService,
        private priorityService: PriorityLevelGenSvc,
        private workOrderFilter: WorkOrderFilterPipe,
        public dragAndDropService: DragAndDropService,
        private router: Router,
        private wellnessTaskService: WellnessTaskGenSvc,
        private scheduledItemBucketService: ScheduledBucketGenSvc,
        private workTaskService: WorkTaskGenSvc,
        private priorityLevelService: PriorityService,
        private workOrderHelper: WorkOrderHelperService
    ) { }

    ngOnInit() {
        this.dragAndDropService.setWorkOrderPanel(this);
        console.time('Get WOs');
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    public getWorkOrders() {
        if (this.isWellnessOnly) {
            this.wellnessWorkOrderService.getWorkOrdersWithOpenWellnessTasks().subscribe(workOrders => {
                this.onWellnessWorkOrdersSubscribe(workOrders);
                this.isLoadingWOs = false;
                console.timeEnd('Get WOs');
            });
        } else if (this.isTreeWorkOnly) {
            this.workWorkOrderService.getWorkOrdersWithOpenWorkTasks().subscribe(workOrders => {
                this.onWorkWorkOrdersSubscribe(workOrders);
                this.isLoadingWOs = false;
                console.timeEnd('Get WOs');
            });
        }
    }

    ngAfterViewInit(): void {
        this.doStuff();
        this.startTimer();
    }

    startTimer() {
        this.timerPaused = false;
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.doStuff();
            this.timeLeft = this.defaultTime;
          }
        }, 1000);
    }

    pauseTimer() {
        this.timerPaused = true;
        clearInterval(this.interval);
    }

    doStuff() {
        this.isLoadingWOs = true;
        this.wellnessWorkOrders = [];
        this.workWorkOrders = [];
        this.filteredWellnessWorkOrders = [];
        this.filteredWorkWorkOrders = [];
        this.getWorkOrders();

        this.dragAndDropService.getWorkOrderPanelUpdater().subscribe(wo => {
            if (wo && this.wellnessWorkOrders && wo instanceof WellnessWorkOrder) {
                const existingWO: WellnessWorkOrderCardComponent = this.wellnessWoCardComponents.find(card => card.workOrder.id === wo.id);

                if (existingWO) {
                    // Update wo for display
                    existingWO.workOrder = wo;
                    existingWO.updateWorkOrderDisplay();
                } else {
                    // Push only if WO is not currently in the panel
                    this.wellnessWorkOrders.push(wo);
                }
            }

            if (wo && this.workWorkOrders && wo instanceof WorkWorkOrder) {
                const existingWO: WorkWorkOrderCardComponent = this.workWoCardComponents.find(card => card.workOrder.id === wo.id);

                if (existingWO) {
                    // Update wo for display
                    existingWO.workOrder = wo;
                    existingWO.updateWorkOrderDisplay();
                } else {
                    // Push only if WO is not currently in the panel
                    this.workWorkOrders.push(wo);
                }
            }
            this.sortWorkOrders();
            this.filterBarComponent.filterWorkOrders();
        });
    }

    OnWoFilteringFinished(filteredWorkOrders: (FilteredWellnessWorkOrder | FilteredWorkWorkOrder | FilteredQuoteWorkOrder)[]) {
        if (this.isWellnessOnly) {
            this.filteredWellnessWorkOrders = filteredWorkOrders as WellnessWorkOrder[];
        } else {
            this.filteredWorkWorkOrders = filteredWorkOrders as WorkWorkOrder[];
        }
    }

    async onWellnessWorkOrdersSubscribe(workOrders: WellnessWorkOrder[]) {
        this.wellnessWorkOrders = workOrders.map(wo => {
            wo.mostUrgentPriorityLevel = this.priorityLevelService.GetMostUrgentPriorityLevel(wo);
            return wo;
        });
        this.rms = this.workOrderHelper.getUnique(workOrders.map(wo => wo.representative));
        this.sortWorkOrders();
        this.filteredWellnessWorkOrders = this.wellnessWorkOrders;
    }

    async onWorkWorkOrdersSubscribe(workOrders: WorkWorkOrder[]) {
        this.workWorkOrders = workOrders.map(wo => {
            wo.mostUrgentPriorityLevel = this.priorityLevelService.GetMostUrgentPriorityLevel(wo);
            return wo;
        });
        this.rms = this.workOrderHelper.getUnique(workOrders.map(wo => wo.representative));
        this.sortWorkOrders();
        this.filteredWorkWorkOrders = this.workWorkOrders;
    }

    sortWorkOrders() {
        // This method was needed because when dragging from schedule to work order panel,
        // the dragged work order would go to the end of the list
        if (this.wellnessWorkOrders) {
            this.wellnessWorkOrders.sort((a, b) => a.mostUrgentPriorityLevel.order - b.mostUrgentPriorityLevel.order);
        }

        if (this.workWorkOrders) {
            this.workWorkOrders.sort((a, b) => a.mostUrgentPriorityLevel.order - b.mostUrgentPriorityLevel.order);
        }
    }

    dragStart(wo: WellnessWorkOrder | WorkWorkOrder, event) {
        this.pauseTimer();
        if (wo.isMultiday) {
            const evt = document.createEvent('MouseEvents');
            evt.initEvent('mouseup', true, true);
            (event.source.element.nativeElement).dispatchEvent(evt);
            if (confirm('This is a multiday order. Would you like to redirect to the multiday scheduling page?')) {
                if (wo instanceof WellnessWorkOrder) {
                    this.router.navigateByUrl('scheduling/multiday/wellness');
                } else {
                    this.router.navigateByUrl('scheduling/multiday/work');
                }
            }
        }
        this.dragAndDropService.workOrderPanel = this;
        this.dragStartEvent.emit(wo);
    }

    dragEnd(wo: WellnessWorkOrder) {
        this.startTimer();
        this.dragEndEvent.emit(wo);
    }

    taskDragged(woTask: WorkOrderWellnessTask | WorkOrderWorkTask) {
        this.pauseTimer();
        this.dragAndDropService.workOrderPanel = this;
        // emit the task to be picked up by the base wellness view for graying out buckets that do not meet
        // the requirements for the task
        if (woTask instanceof WorkOrderWellnessTask) {
            this.taskDraggedStartEvent.emit(woTask.wellnessTask);
        } else if (woTask instanceof WorkOrderWorkTask) {
            this.taskDraggedStartEvent.emit(woTask.workTask);
        }
    }

    taskDragEnd() {
        this.startTimer();
        this.taskDraggedEndEvent.emit();
    }

    // Handle your own drop events WO panel
    drop(event: CdkDragDrop<WellnessWorkOrder | ScheduledBucketWellnessWorkOrder | WorkWorkOrder | ScheduledBucketWorkWorkOrder |
        WorkOrderWellnessTask | WorkOrderWorkTask>) {
        console.log('DROP ONTO WORK ORDERS PANEL');
        const droppedItem = event.previousContainer.data[event.previousIndex];
        //////////////////////////////////////////////////////////////////////////////////////////
        // Un-schedule the work order
        //////////////////////////////////////////////////////////////////////////////////////////
        if (event.isPointerOverContainer && event.previousContainer !== event.container &&
            droppedItem instanceof ScheduledBucketWellnessWorkOrder) {

            const oldWorkOrderList = (event.previousContainer.data as ScheduledBucketWellnessWorkOrder);
            const workOrderToUnschedule = oldWorkOrderList[event.previousIndex].wellnessWorkOrder;

            const buckets = this.dragAndDropService.bucketListDayList.map(x => x.bucketList).reduce((acc, curr) => acc.concat(curr));
            const matchingBucket = buckets.find(bucket => bucket.id === droppedItem.scheduledBucketId);

            // This just updates all the indices in javascript - updates front-end
            const index = matchingBucket.scheduledBucketWellnessWorkOrders.findIndex(sbwo =>
                sbwo.wellnessWorkOrder.id === workOrderToUnschedule.id);
            matchingBucket.scheduledBucketWellnessWorkOrders[index].wellnessWorkOrder.workOrderWellnessTasks.filter(wowt =>
                wowt.wellnessTask.currentBucketId === matchingBucket.id).forEach(wowt => {
                    wowt.wellnessTask.currentBucketId = null;
                    wowt.wellnessTask.scheduleDateFrom = null;
                    wowt.wellnessTask.scheduleDateTo = null;
                });
            matchingBucket.scheduledBucketWellnessWorkOrders[index].wellnessWorkOrder.workOrderWellnessTasks.filter(wowt =>
                wowt.wellnessTask.goBackBucketId === matchingBucket.id).forEach(wowt => {
                    wowt.wellnessTask.goBackBucketId = null;
                });

            const woPanelMatchingWO = this.wellnessWorkOrders.find(wo => wo.id === droppedItem.wellnessWorkOrderId);
            if (woPanelMatchingWO) {
                // Update task on frontend
                woPanelMatchingWO.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.currentBucketId === matchingBucket.id || wowt.wellnessTask.goBackBucketId === matchingBucket.id)
                                                    .forEach(wowt => {
                                                        if (wowt.wellnessTask.isGoBack) {
                                                            wowt.wellnessTask.goBackBucketId = null;
                                                            wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                                                        } else {
                                                            wowt.wellnessTask.scheduleDateFrom = null;
                                                            wowt.wellnessTask.scheduleDateTo = null;
                                                            wowt.wellnessTask.currentBucketId = null;
                                                            wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                                                        }
                                                    });
            }
            matchingBucket.scheduledBucketWellnessWorkOrders.splice(index, 1);

            // Update front-end
            this.dragAndDropService.emitBucketUpdate(matchingBucket);
            // Update back-end
            this.dragAndDropService.removeWorkOrderFromScheduling(workOrderToUnschedule, matchingBucket.id);
        } else if (droppedItem instanceof WorkOrderWellnessTask) {
            const buckets = this.dragAndDropService.bucketListDayList.map(x => x.bucketList).reduce((acc, curr) => acc.concat(curr));
            const matchingBucket = buckets.find(bucket => bucket.id === this.dragAndDropService.dragStartBucket.id);
            // This is the matching WO from the scheduled bucket
            const matchingWorkOrder = matchingBucket.scheduledBucketWellnessWorkOrders.find(sbwwo =>
                sbwwo.wellnessWorkOrder.id === droppedItem.wellnessWorkOrderId).wellnessWorkOrder;

            const woPanelMatchingWO = this.wellnessWorkOrders.find(wo => wo.id === droppedItem.wellnessWorkOrderId);

            if (droppedItem.wellnessTask.isGoBack) {
                droppedItem.wellnessTask.goBackBucketId = null;
                droppedItem.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
            } else {
                droppedItem.wellnessTask.currentBucketId = null;
                droppedItem.wellnessTask.scheduleDateFrom = null;
                droppedItem.wellnessTask.scheduleDateTo = null;
                droppedItem.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
            }

            if (matchingWorkOrder &&
                !matchingWorkOrder.workOrderWellnessTasks.some(wowt => wowt.wellnessTask.currentBucketId === matchingBucket.id
                                                                        || wowt.wellnessTask.goBackBucketId === matchingBucket.id)) {
                const woIndex: number =
                    matchingBucket.scheduledBucketWellnessWorkOrders.findIndex(wo => wo.wellnessWorkOrder.id === matchingWorkOrder.id);

                if (woIndex >= 0) {
                    // Remove the WO from the bucket if there are no tasks with that bucket's ID, updates front-end
                    matchingBucket.scheduledBucketWellnessWorkOrders.splice(woIndex, 1);
                    // Remove the scheduled bucket wellness WO from back-end
                    this.scheduledItemBucketService.deleteScheduledBucketWellnessWorkOrder(matchingBucket.id,
                                                                                            matchingWorkOrder.id).subscribe();
                }
            }

            if (woPanelMatchingWO) {
                // Update task on frontend
                const woPanelWOTask =  woPanelMatchingWO.workOrderWellnessTasks.find(wowt => wowt.id === droppedItem.id);
                if (woPanelWOTask.wellnessTask.isGoBack) {
                    woPanelWOTask.wellnessTask.goBackBucketId = null;
                    woPanelWOTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                } else {
                    woPanelWOTask.wellnessTask.scheduleDateFrom = null;
                    woPanelWOTask.wellnessTask.scheduleDateTo = null;
                    woPanelWOTask.wellnessTask.currentBucketId = null;
                    woPanelWOTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                }

                this.wellnessTaskService.unschedule(woPanelWOTask.wellnessTask.id).subscribe();
                this.dragAndDropService.emitWorkOrderToUpdate(woPanelMatchingWO);
            }

            if (!this.wellnessWorkOrders.find(wo => wo.id === matchingWorkOrder.id)) {
                // Only push WO into front-end WO list if it is currently not there
                this.wellnessWorkOrders.push(matchingWorkOrder);
                this.filterBarComponent.filterWorkOrders();
            }

            // Update bucket and WO on the front end
            this.dragAndDropService.emitBucketUpdate(matchingBucket);
            this.dragAndDropService.emitWorkOrderToUpdate(matchingWorkOrder);

            // Unschedule task in the back end
            this.wellnessTaskService.unschedule(droppedItem.wellnessTask.id).subscribe();
        } else if (event.isPointerOverContainer && event.previousContainer !== event.container &&
            droppedItem instanceof ScheduledBucketWorkWorkOrder) {
            const oldWorkOrderList = event.previousContainer.data as ScheduledBucketWorkWorkOrder;
            const workOrderToUnschedule = oldWorkOrderList[event.previousIndex].workWorkOrder;

            const buckets = this.dragAndDropService.bucketListDayList.map(x => x.bucketList).reduce((acc, curr) => acc.concat(curr));
            const matchingBucket = buckets.find(bucket => bucket.id === droppedItem.scheduledBucketId);

            // This just updates all the indices in javascript - updates front-end
            const index = matchingBucket.scheduledBucketWorkWorkOrders.findIndex(sbwo =>
                sbwo.workWorkOrder.id === workOrderToUnschedule.id);
            matchingBucket.scheduledBucketWorkWorkOrders[index].workWorkOrder.workOrderWorkTasks.filter(wowt =>
                wowt.workTask.currentBucketId === matchingBucket.id).forEach(wowt => {
                    wowt.workTask.currentBucketId = null;
                    wowt.workTask.scheduleDateFrom = null;
                    wowt.workTask.scheduleDateTo = null;
                });
            matchingBucket.scheduledBucketWorkWorkOrders[index].workWorkOrder.workOrderWorkTasks.filter(wowt =>
                wowt.workTask.goBackBucketId === matchingBucket.id).forEach(wowt => {
                    wowt.workTask.goBackBucketId = null;
                });

            const woPanelMatchingWO = this.workWorkOrders.find(wo => wo.id === droppedItem.workWorkOrderId);
            if (woPanelMatchingWO) {
                // Update task on frontend
                woPanelMatchingWO.workOrderWorkTasks.filter(wowt => wowt.workTask.currentBucketId === matchingBucket.id || wowt.workTask.goBackBucketId === matchingBucket.id)
                                                    .forEach(wowt => {
                                                        if (wowt.workTask.isGoBack) {
                                                            wowt.workTask.goBackBucketId = null;
                                                            wowt.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                                                        } else {
                                                            wowt.workTask.scheduleDateFrom = null;
                                                            wowt.workTask.scheduleDateTo = null;
                                                            wowt.workTask.currentBucketId = null;
                                                            wowt.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                                                        }
                                                    });
            }

            matchingBucket.scheduledBucketWorkWorkOrders.splice(index, 1);

            // Update bucket on front-end
            this.dragAndDropService.emitBucketUpdate(matchingBucket);
            // Update WO and bucket on the back-end
            this.dragAndDropService.removeWorkOrderFromScheduling(workOrderToUnschedule, matchingBucket.id);
        } else if (droppedItem instanceof WorkOrderWorkTask) {
            const buckets = this.dragAndDropService.bucketListDayList.map(x => x.bucketList).reduce((acc, curr) => acc.concat(curr));
            const matchingBucket = buckets.find(bucket => bucket.id === this.dragAndDropService.dragStartBucket.id);
            // This is the matching WO from the scheduled bucket
            const matchingWorkOrder = matchingBucket.scheduledBucketWorkWorkOrders.find(sbwwo =>
                sbwwo.workWorkOrder.id === droppedItem.workWorkOrderId).workWorkOrder;

            const woPanelMatchingWO = this.workWorkOrders.find(wo => wo.id === droppedItem.workWorkOrderId);
            // Update task on front end
            if (droppedItem.workTask.isGoBack) {
                droppedItem.workTask.goBackBucketId = null;
                droppedItem.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
            } else {
                droppedItem.workTask.currentBucketId = null;
                droppedItem.workTask.scheduleDateFrom = null;
                droppedItem.workTask.scheduleDateTo = null;
                droppedItem.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
            }

            if (matchingWorkOrder && !matchingWorkOrder.workOrderWorkTasks.some(wowt =>
                wowt.workTask.currentBucketId === matchingBucket.id || wowt.workTask.goBackBucketId === matchingBucket.id)) {
                const woIndex: number = matchingBucket.scheduledBucketWorkWorkOrders
                    .findIndex(wo => wo.workWorkOrder.id === matchingWorkOrder.id);

                if (woIndex >= 0) {
                    // Remove the WO from the bucket if there are no tasks with that bucket's ID, front-end update
                    matchingBucket.scheduledBucketWorkWorkOrders.splice(woIndex, 1);
                    // Remove the scheduled bucket WO from the back-end
                    this.scheduledItemBucketService.deleteScheduledBucketWorkWorkOrder(matchingBucket.id, matchingWorkOrder.id).subscribe();
                }
            }

            if (woPanelMatchingWO) {
                const woPanelWOTask =  woPanelMatchingWO.workOrderWorkTasks.find(wowt => wowt.id === droppedItem.id);
                // Update task on front end
                if (woPanelWOTask.workTask.isGoBack) {
                    woPanelWOTask.workTask.goBackBucketId = null;
                    woPanelWOTask.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                } else {
                    woPanelWOTask.workTask.scheduleDateFrom = null;
                    woPanelWOTask.workTask.scheduleDateTo = null;
                    woPanelWOTask.workTask.currentBucketId = null;
                    woPanelWOTask.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                }

                this.dragAndDropService.emitWorkOrderToUpdate(woPanelMatchingWO);
            }

            if (!this.workWorkOrders.find(wo => wo.id === matchingWorkOrder.id)) {
                // Only push WO into front-end WO list if it is currently not there
                this.workWorkOrders.push(matchingWorkOrder);
                this.filterBarComponent.filterWorkOrders();
            }

            // Update bucket and WO on the front end
            this.dragAndDropService.emitBucketUpdate(matchingBucket);
            this.dragAndDropService.emitWorkOrderToUpdate(matchingWorkOrder);

            // Unschedule task in the back end
            this.workTaskService.unschedule(droppedItem.workTask.id).subscribe();
        }
    }

    shouldDisableWODrag(workOrder: WellnessWorkOrder | WorkWorkOrder) {
        let cardComponent: BaseWorkOrderCardComponent;
        if (workOrder instanceof WellnessWorkOrder) {
            cardComponent = this.wellnessWoCardComponents.find(card => card.workOrder.id === workOrder.id);
        } else if (workOrder instanceof WorkWorkOrder) {
            cardComponent = this.workWoCardComponents.find(card => card.workOrder.id === workOrder.id);
        }

        if (workOrder && cardComponent) {
            return cardComponent.shouldDisableWODrag();
        } else {
            return false;
        }
    }
}
