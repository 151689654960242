<!-- <form #LoginForm="ngForm" autocomplete="off" (submit)="login()">
    <div class="m-auto p-top">
        <h1 class="title">Login</h1>
        <label for="username">Username</label>
        <input class="form-control" name="username" type="text" [(ngModel)]="loginRequest.username" pInputText
            class="m-bottom" required>
        <label for="password" class="m-top">Password</label>
        <input class="form-control" name="password" type="password" [(ngModel)]="loginRequest.password" pInputText
            required>
        <button type="submit" pButton label="Login" class="m-top m-bottom"
            [disabled]="!LoginForm.valid || isRequesting">
        </button>
        <p-message *ngIf="submissionError" severity="error" text="Invalid login.">
        </p-message>
    </div>
</form> -->

<form #LoginForm="ngForm" autocomplete="off" (submit)="login()">
    <div class="m-auto">
        <div class="p-grid">
            <div class="p-col-12">
                <h1 class="title">{{title}}</h1>
                <p-message severity="info" *ngIf="redirectMessage" [text]="redirectMessage"></p-message>
                <div *ngIf="firmtimeRedirectMessage" [innerHTML]="firmtimeRedirectMessage"></div>
                <div *ngIf="firmtimeRedirectMessage2" [innerHTML]="firmtimeRedirectMessage2" style="margin-top:20px;"></div>
            </div>
            <div class="p-col-12">
                <label for="username">Email</label>
                <input class="form-control" name="username" type="text" [(ngModel)]="loginRequest.username" pInputText
                    required>
            </div>
            <div class="p-col-12">
                <label for="password" class="m-top">Password</label>
                <input class="form-control" name="password" type="password" [(ngModel)]="loginRequest.password"
                    pInputText required>
                <span class="forgotPassword">
                    <a routerLink="/passwordReset" style="display: inline-block;">Need/Reset Password</a>
                </span>
            </div>
            <div class="p-col-6">
                <p-inputSwitch name="rememberMe" [(ngModel)]="rememberMe">
                </p-inputSwitch>
                <span class="remeberMeText">Remember Me</span>
            </div>
            <div class="p-col-6">
                <button type="submit" pButton class="ui-button-success" label="Login"
                    [disabled]="!LoginForm.valid || isRequesting" style="width: 100%;">
                </button>
            </div>
            <!-- <div class="p-col-12">
                <hr style="margin: 0;">
            </div> -->
            <!-- <div class="p-col-12">
                <button type="button" pButton class="ui-button-success" label="Create New Account" style="width: 100%;"
                    routerLink="/todo" (click)="createNewAccount()"></button>
            </div> -->
            <!-- <div class="p-col-12">
                <button type="button" pButton class="ui-button-success" label="Request a Quote" style="width: 100%;"
                [routerLink]="['/requestedQuoteMaintenance']"></button>
            </div> -->

            <p-message *ngIf="submissionError" severity="error" text="Invalid login.">
            </p-message>
        </div>
    </div>
</form>
