import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { QuoteRequest, QuoteRequestType } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-quote-option-section',
  templateUrl: './quote-option-section.component.html',
  styleUrls: ['./quote-option-section.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
  // Sourced from https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
})
export class QuoteOptionSectionComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() quoteRequest: QuoteRequest;

  @Output() quoteOptionChanged = new EventEmitter<null>();

  // use type on template
  public get Type() {
    return QuoteRequestType;
  }

  constructor() { }

  ngOnInit() {
  }

  optionChanged() {
    this.quoteOptionChanged.emit();
  }
}
