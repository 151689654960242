<p-card class="crew-card">

  <div class="p-grid">
    <div class="p-col">
      <p-calendar [(ngModel)]="bigDayBucketSubrange.fromDate" class="secondary" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" [maxDate]="bigDayBucketSubrange.toDate" [minDate]="bigDayBucket.fromDate"
        appendTo="body">
      </p-calendar>
    </div>
    <div class="p-col-1 to">
      to
    </div>
    <div class="p-col">
      <p-calendar [(ngModel)]="bigDayBucketSubrange.toDate" class="secondary" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" [minDate]="bigDayBucketSubrange.fromDate" [maxDate]="bigDayBucket.toDate"
        appendTo="body">
      </p-calendar>
    </div>
  </div>

  <div class="toggle-crew-information">
    <div class="crew-information">
      <div *ngIf="bigDayEquipment" [id]="'subrangeEquipmentList-' + bigDayBucketSubrange.id"
        [cdkDropListData]="bigDayEquipment" [cdkDropListConnectedTo]="multidayDragAndDropService.allCDKEquipmentListIds"
        cdkDropList (cdkDropListDropped)="drop($event)">
        <div style="padding: 6px 0;">
          <span>Equipment</span>
        </div>
        <div *ngFor="let bigDayEqp of bigDayEquipment" class="inner-card">
          <app-equipment-card [equipment]="bigDayEqp.equipment" [deleteable]="true"
            (deleted)="deleteEquipmentFromBigDayBucketSubrange($event)"></app-equipment-card>
        </div>
        <div *ngIf="bigDayEquipment.length == 0" class="inner-card drag-placeholder">
          <p-card>Drag Equipment Here</p-card>
        </div>
      </div>
      <div *ngIf="bigDayEmployees" [id]="'subrangeEmployeeList-' + bigDayBucketSubrange.id"
        [cdkDropListData]="bigDayEmployees" [cdkDropListConnectedTo]="multidayDragAndDropService.allCDKEmployeeListIds"
        cdkDropList (cdkDropListDropped)="drop($event)">
        <div style="padding: 6px 0;">
          <span>Employees</span>
        </div>
        <div *ngFor="let bigDayEmployee of bigDayEmployees" class="inner-card">
          <app-employee-card [employee]="bigDayEmployee.employee" [deleteable]="true"
            (deleted)="deleteEmployeeFromBigDayBucketSubrange($event)"></app-employee-card>
        </div>
        <div *ngIf="bigDayEmployees && bigDayEmployees.length == 0" class="inner-card drag-placeholder">
          <p-card>Drag Employee Here</p-card>
        </div>
      </div>
    </div>
  </div>
  <p-footer class="clearfix">
    <button pButton type="button" icon="pi pi-trash"
      class="ui-button-rounded ui-button-raised ui-button-danger deleteButton btn-sm float-right"
      (click)="onDelete()"></button>
  </p-footer>
</p-card>