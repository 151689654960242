<div *ngIf="isLoading">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>Loading...
</div>

<div *ngIf="errorMessage" ><p-message severity="error" [text]="errorMessage"></p-message></div>

<form id="workOrderForm" #workOrderForm="ngForm" *ngIf="workOrder">
  <div class="ui-g iPhoneFix ui-g-nopad">
    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
      <div class="ui-g ui-g-nopad">
        <div class="ui-g-12 ui-md-6 ui-lg-6 ui-g-nopad">
          <h4>Tree Work Order Maintenance</h4>
        </div>
      </div>
      <br>
      <!-- Common top section includes basic WO information such as customer, address, representative -->
      <app-work-order-maintenance-common-fields-top 
        [(workOrder)]="workOrder" 
        [isEditable]="isEditable"
        [isCustomer]="isCustomer">
      </app-work-order-maintenance-common-fields-top>
      <br/>
      <div *ngIf="workOrder && workOrder.customer && workOrder.customer.customerComments && !isCustomer">  
        <button
          pButton
          type="button"
          class="ui-button-raised smallButton"
          (click)="onMyWay()"
          [disabled]="false"
          *ngIf="workOrder && workOrder.id && workOrder.completionStatus != 'Completed' && workOrder.completionStatus !='Unable to be Completed' && !isCustomer && isAdminOrForeman"
          label="On My Way">
        </button>
      </div>
      <div *ngIf="workOrder && !isCustomer && workOrder.completionStatus == 'Completed'">
        <button
          pButton
          type="button"
          class="ui-button-raised smallButton"
          (click)="duplicateToQuote()"
          [disabled]="saving"
          label="Duplicate Work Order to New Quote">
        </button>
      </div>
      <div class="ui-g" *ngIf="!isCustomer">
        <div class="ui-g-12 ui-md-6 multiSelect">
          <h6>Required Skill(s)/Certification(s)</h6>
          <p-multiSelect
            [options]=""
            name="requiredSkills"
            [ngModel]="requiredSkills"
            [panelStyle]="{minWidth:'12em'}"
            optionLabel="name" 
            disabled
            defaultLabel=""
            *ngIf="workOrder">
            <ng-template let-value pTemplate="selectedItems">
              <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                <span>{{val.name}}</span>
              </div>
              <span *ngIf="!value || value.length === 0" style="width: 150px;" class="ui-multiselected-empty-token ui-corner-all">No Skills Required</span>
            </ng-template>
            <ng-template let-skill pTemplate="item">
              {{skill.label}}
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="ui-g-12 ui-md-6 multiSelect">
          <h6>Required Equipment</h6>
          <p-multiSelect 
            [options]=""
            name="requiredEquipment"
            [ngModel]="requiredEquipment"
            [panelStyle]="{minWidth:'12em'}"
            optionLabel="type"
            disabled
            defaultLabel=""
            *ngIf="workOrder">
            <ng-template let-value pTemplate="selectedItems">
              <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                <span>{{val.type}}</span>
              </div>
              <span *ngIf="!value || value.length === 0" style="width: 180px;" class="ui-multiselected-empty-token ui-corner-all">No Equipment Required</span>
            </ng-template>
            <ng-template let-role pTemplate="item">
              {{role.label}}
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="ui-g" *ngIf="!isCustomer">
        <div class="ui-g-12 ui-md-12 ui-lg-12" style="text-align: left;">
          <button 
            type="button" 
            (click)="addNewWorkTask()" 
            class="smallButton green-button" 
            pButton 
            [disabled]="!isEditable && !canAddTaskToCompletedWO"
            label="Add Work Task"></button>
        </div>
      </div>
      <br>
      <div class="ui-g" *ngIf="workOrder">
        <div class="ui-g-12 ui-md-6 ui-lg-4" [ngClass]="{'taskComplete': wowt.workTask.completionStatus == WorkWorkOrderCompletionStatus.Completed || wowt.workTask.completionStatus == WorkWorkOrderCompletionStatus.Unable_to_be_Completed }" *ngFor="let wowt of workOrder.workOrderWorkTasks">
          <app-work-order-maintenance-work-task-card
            [workOrderWorkTask]="wowt"
            [isEditable]="isEditable"
            [isCustomer]="isCustomer"
            [customerId]="workOrder.customer.id"
            [quoteNumber]="workOrder.quoteNumber"
            [hidePrice]="workOrder.hidePrice"
            (completeWorkTaskEvent)="completeTask($event)"
            (copyWorkTaskEvent)="copyWorkTask($event)"
            (deleteWorkTaskEvent)="deleteWorkTask($event)"
            (editWorkTaskEvent)="editWorkTask($event)"
            (unableToCompleteWorkTaskEvent)="unableToCompleteWorkTask($event)"
            (undoCompleteTask)="undoCompleteTask($event)">
          </app-work-order-maintenance-work-task-card>
        </div>
      </div>
      
      <div class="ui-g">
        <div class="ui-g-12">
          <div class="bold">Multi-Day Job: </div>
          <p-radioButton
            name="isMultiday"
            [value]="true"
            [label]="'Yes'"
            [(ngModel)]="workOrder.isMultiday"
            [disabled]="!isEditable"
            required
            *ngIf="workOrder">
          </p-radioButton>&nbsp;&nbsp;
          <p-radioButton
            name="isNotMultiday"
            [value]="false"
            [label]="'No'"
            [(ngModel)]="workOrder.isMultiday"
            [disabled]="!isEditable"
            required
            *ngIf="workOrder">
          </p-radioButton>
        </div>
      </div>

      <div class="ui-g">
        <div class="ui-g-12">
          <h6>Outside Expenses: </h6>
          <p-radioButton
            name="hasRentalExpense"
            [value]="true"
            [label]="'Yes'"
            [(ngModel)]="workOrder.hasRentalExpense"
            [disabled]="!isEditable && !canSaveAfterCompletion"
            required
            *ngIf="workOrder">
          </p-radioButton>&nbsp;&nbsp;
          <p-radioButton
            name="noRentalExpense"
            [value]="false"
            [label]="'No'"
            [(ngModel)]="workOrder.hasRentalExpense"
            [disabled]="!isEditable && !canSaveAfterCompletion"
            required
            *ngIf="workOrder">
          </p-radioButton>
        </div>
        <div class="ui-g-12" *ngIf="workOrder?.hasRentalExpense">
          <h6>Outside Cost(s)</h6>
          <p>
            <input 
              pInputText 
              name="rentalCosts" 
              numeric 
              [textMask]="{mask: currencyMask}"
              [(ngModel)]="workOrder.rentalCost" 
              [required]="workOrder.hasRentalExpense" 
              [disabled]="!isEditable && !canSaveAfterCompletion" 
              (ngModelChange)="workOrder.rentalCost=$event"
            />
          </p>
          <h6>Describe Outside Cost(s)</h6>
          <div class="ui-fluid">
            <input pInputText name="rentalDescription" [(ngModel)]="workOrder.rentalDescription" [required]="workOrder.hasRentalExpense" [disabled]="!isEditable && !canSaveAfterCompletion" />
          </div>
        </div>
      </div>

      <div class="ui-g" *ngIf="workOrder && workOrder.quoteWorkOrder && !isCustomer">
        <div class="ui-g-12">
          <h6>Quote Uploads</h6>
          <app-blob-manager [containerName]="'quote'" [parentRecordId]="workOrder.quoteWorkOrder.id" [viewOnly]="true"></app-blob-manager>
        </div>
      </div>
      <div class="ui-g" *ngIf="workOrder && !isCustomer">
        <div class="ui-g-12">
          <h6>Upload Photos of Work</h6>
          <app-blob-manager 
            [containerName]="'work-work-order'" 
            [parentRecordId]="workOrder.id" 
            [uploadLabel]="'Document/Photo'" 
            [viewOnly]="!canUpload || !canSave">
          </app-blob-manager>
        </div>
      </div>
      <div *ngIf="canCompleteATask && completeableTaskCount > 1">
        <button type="button" class="smallButton" pButton label="Complete All Tasks" (click)="op1.toggle($event)"></button>
        <p-overlayPanel #op1 [appendTo]="'body'" [dismissable]="false" [showCloseIcon]="true">
          <div style="max-width: 500px">
            <form id="hoursEntry" #hoursEntry="ngForm">
              <p-message severity="info" text="**This completes all scheduled tasks**
                <br/><br/>To complete all scheduled tasks enter the amount of time worked by each employee for the day and feedback. 
                That time will be split between the tasks and the feedback will be set on each task. 
                Example: If one employee works for 6 hours on that day on two tasks and another works for 4 hours on that day, enter 6 for the first employee and 4 for the second. 
                The first employee's time will be 3 hours on each task and the second employee's time will be 2 hours on each task.">
              </p-message>
              <h5>
                FEEDBACK ON JOB
              </h5>
              <textarea name="feedback" [rows]="5" [cols]="40" pInputTextarea [(ngModel)]="overallFeedback" required></textarea>
              <h5>
                HOURS ON THE JOB
              </h5>
              <div>
                Please submit to the quarter hour. Example: if employee worked 6 hrs & 15 minutes, input 6.25.
              </div>
              <div>
                <div class="p-grid" *ngFor="let taskHourEntry of hourEntries">
                  <div class="p-col-6">
                    <app-employee-card sytle="margin: auto; veritcal-align: middle" [employee]="taskHourEntry.employee" [deleteable]="false"></app-employee-card>
                  </div>
                  <div class="p-col-6 ui-fluid">
                    <input required type="text" pInputText [(ngModel)]="taskHourEntry.hours" (ngModelChange)="getHourTotal()" [name]="taskHourEntry.id + '-' + taskHourEntry.employeeId + '-hours'" />
                  </div>
                </div>
                <div *ngIf="addingEmployee">
                  <ng-select class="custom" required [items]="employees" placeholder="Select an Employee" [selectOnTab]="true"
                    (ngModelChange)="addNewTaskHourEntry($event)" name="certifiedApplicator" bindLabel="fullName"
                    [(ngModel)]="selectedEmployee">
                  </ng-select>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col-6 ui-fluid">
                  <button pButton type="button" label="Add Employee" icon="pi pi-plus" class="ui-button-raised ui-button-secondary" (click)="addEmployee()"></button>
                </div>
                <div class="p-col-6" style="text-align: right;">
                  Total: {{totalHours}} hrs
                </div>
              </div>
              <div *ngIf="verifyPrice">
                <h5>
                  CONFIRM PRICE
                </h5>
                <div>
                  Please input the final price of this task (not including tax). If needed, please consult with the Regional Manager on the work order to ensure you are submitting the correct price. The price you input below is what the customer will receive in their invoice.
                </div>
                <div class="p-grid" *ngFor="let task of tasksToVerifyPrice">
                  <div class="p-col-6" style="margin: auto">
                    {{task.name}}
                  </div>
                  <div class="p-col-6">
                    <input pInputText
                      class="inputTextBox"
                      [name]="'price' + task.id"
                      required
                      numeric
                      [textMask]="{mask: currencyMask}"
                      [(ngModel)]="task.price"
                      *ngIf="task" 
                    />
                  </div>
                </div>
              </div>
              <button pButton type="submit" label="Submit" (click)="completeAllIncompleteTasks(hoursEntry, op1);" class="ui-button-success" [disabled]="!canCompleteATask"></button>
            </form>
          </div>
        </p-overlayPanel>
      </div>
      <!-- <div style="padding: 5px 0 5px 0;">
        <button pButton
          *ngIf="canCollectCash"
          type="button"
          class="ui-button-raised ui-button-success smallButton"
          (click)="pushNewCashCollectedEntry()"
          [disabled]="!isEditable"
          label="Cash or Check Collected">
        </button>
      </div> -->
      <div>
        <button
          pButton
          type="button"
          class="ui-button-raised smallButton"
          (click)="completeWO()"
          [disabled]="!canCompleteWO"
          *ngIf="workOrder && workOrder.id && workOrder.completionStatus != WorkWorkOrderCompletionStatus.Completed && workOrder.completionStatus != WorkWorkOrderCompletionStatus.Unable_to_be_Completed && !isCustomer"
          label="Complete Work Order">
        </button>    
        <button
          pButton 
          type="button"
          class="ui-button-raised smallButton"
          (click)="openUndoWOCompletion()"
          *ngIf="workOrder && workOrder.id && canUndoWOCompletion"
          label="Undo Work Order Completion"
        ></button>    
      </div>
      <!-- Common bottom section includes total information such as price adjustments, subtotal, sales tax, total -->
      <app-work-order-maintenance-common-fields-bottom 
        *ngIf="workOrder"
        [(workOrder)]="workOrder" 
        [(isEditable)]="isEditable"
        [isCustomer]="isCustomer"
        [canCompleteWO]="canCompleteWO"
        [hidePrice]="workOrder.hidePrice"
        (priceAdjustmentDeleted)="deletePriceAdjustment($event)">
      </app-work-order-maintenance-common-fields-bottom>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12 columnPadding">
      <div *ngIf="workOrderForm.invalid && workOrderForm.submitted" class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 columnPadding">
      <button 
        pButton
        type="button"
        class="ui-button-raised ui-button-success buttonStyle"
        (click)="saveAfterCompletion()"
        *ngIf="canSaveAfterCompletion"
        [disabled]="saving"
        label="Save">
      </button>
      <button 
        pButton
        type="submit"
        class="ui-button-raised ui-button-success buttonStyle"
        (click)="preSave(workOrderForm)"
        *ngIf="canSave && !isCustomer"
        [disabled]="saving"
        label="Save">
      </button>
      <button 
        pButton
        type="button"
        class="ui-button-raised ui-button-warning buttonStyle"
        (click)="cancel('work')"
        *ngIf="canSave"
        [label]="isEditable ? 'Cancel ': 'Back'">
      </button>
      <button 
        pButton
        type="button"
        class="ui-button-raised ui-button-warning buttonStyle"
        (click)="cancel('work')"
        *ngIf="!canSave"
        label="Back">
      </button>
    </div>
  </div>
</form>

<!-- Add New Work Task dialog -->
<!-- Only dialogs inside class scrollableDialog get the CSS for scrolling. Only use scrollableDialog if the dialog actually has enough content that
    it needs to scroll because it looks weird otherwise. -->
<div class="scrollableDialog" *ngIf="displayWorkTaskDialog">
  <p-dialog header="Work Task Info" [(visible)]="displayWorkTaskDialog" [modal]="true" [responsive]="true" [resizable]="false" [baseZIndex]="10000"
    [maximizable]="true" [style]="{'width': '760px'}" [focusOnShow]="false" (onHide)="cancelledTask()" [closable]="false">
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" tabindex="0" role="button" (click)="cancelledTask()" (keydown.enter)="cancelledTask()" (mousedown)="cancelledTask()">
          <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-work-task-maintenance (timeToClose)="closedDialog($event)" (cancelled)="cancelledTask($event)" (delete)="deleteWorkTaskByTask($event)" [workOrder]="workOrder" [task]="selectedWorkTask"></app-work-task-maintenance>
  </p-dialog>
</div>


<div *ngIf="showUndoCompleteDialog">
  <p-dialog header="Undo Work Order Completion" *ngIf="showUndoCompleteDialog" [(visible)]="showUndoCompleteDialog" [modal]="true"
  [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body" [focusOnShow]="false">
  <form [formGroup]="commentForm">
      <div class="form-group">
          Please describe the changes you are about to make:
          <textarea pInputTextarea formControlName="description" class="form-control" [required]="true"
              style="width: 100%;" autoResize="autoResize"
              placeholder="Describe..."></textarea>
      </div>
      <div class="form-group" style="width: 100%; text-align: right">
        <button type="button" pButton (click)="closeUndoWOCompletion()" label="Cancel"
              class="ui-button-secondary"></button>
        &nbsp;
        <button type="button" type="submit" [disabled]="commentForm.invalid || saving" pButton (click)="undoWOCompletion()"
            label="Submit"></button>
      </div>
  </form>
</p-dialog>
</div>