import { Component, Input, OnInit } from '@angular/core';
import { PaymentDueOption } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-legal-acceptance-verbiage',
  templateUrl: './legal-acceptance-verbiage.component.html',
  styleUrls: ['./legal-acceptance-verbiage.component.css']
})
export class LegalAcceptanceVerbiageComponent implements OnInit {
  @Input() paymentDueOption: PaymentDueOption;

  constructor() { }

  ngOnInit() {
  }

}
