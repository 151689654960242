import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { ScheduledBucketDTO } from 'src/app/services_autogenerated/generated_services';
import { BaseWorkWorkOrderCardComponent } from '../base-work-work-order-card/base-work-work-order-card.component';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
var ScheduledBucketWorkWorkOrderCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduledBucketWorkWorkOrderCardComponent, _super);
    function ScheduledBucketWorkWorkOrderCardComponent(dragAndDropService, router, priorityService, workOrderHelperService, customCustomerService, authHelper) {
        var _this = _super.call(this, dragAndDropService, router, priorityService, workOrderHelperService, authHelper) || this;
        _this.customCustomerService = customCustomerService;
        return _this;
    }
    ScheduledBucketWorkWorkOrderCardComponent.prototype.getEquipmentTypeDisplay = function () {
        var _this = this;
        var equipmentTypes = [];
        this.scheduledTasks.forEach(function (wowt) {
            if (wowt.workTask.isGoBack && _this.bucket.id === wowt.workTask.goBackBucketId) {
                equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskGoBackEquipment.map(function (wte) { return wte.equipmentType; }));
            }
            else if (_this.bucket.id === wowt.workTask.currentBucketId) {
                equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskEquipment.map(function (wte) { return wte.equipmentType; }));
            }
        });
        return this.workOrderHelperService.getUnique(equipmentTypes).map(function (et) { return et.abbreviation ? et.abbreviation : et.type; }).join(' / ');
    };
    ScheduledBucketWorkWorkOrderCardComponent.prototype.setBottomSection = function () {
        this.hoursSummary = this.scheduledTasks.reduce(function (acc, curr) { return acc + curr.workTask.hours; }, 0);
        this.hoursSummary += this.scheduledTasks.reduce(function (acc, curr) { return acc + curr.workTask.goBackHoursEstimate; }, 0);
        this.priceSummary = this.scheduledTasks.reduce(function (acc, curr) { return acc + curr.workTask.price; }, 0);
        this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
    };
    ScheduledBucketWorkWorkOrderCardComponent.prototype.setTasks = function () {
        var _this = this;
        // Total amount of task scheduled, even ones not scheduled on given bucket day
        this.scheduledTasksTotal = this.workOrder.workOrderWorkTasks.filter(function (task) {
            return !((!task.workTask.isGoBack && !task.workTask.currentBucketId) || (task.workTask.isGoBack && !task.workTask.goBackBucketId));
        }).length;
        // Tasks that are scheduled on the given bucket day
        this.scheduledTasks = this.workOrder.workOrderWorkTasks
            .filter(function (task) { return task.workTask.currentBucketId === _this.bucket.id || task.workTask.goBackBucketId === _this.bucket.id; });
    };
    return ScheduledBucketWorkWorkOrderCardComponent;
}(BaseWorkWorkOrderCardComponent));
export { ScheduledBucketWorkWorkOrderCardComponent };
