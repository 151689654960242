import { Router } from '@angular/router';
import { AuthHelperService } from './services/auth-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "./services/auth-helper.service";
import * as i2 from "@angular/router";
var PasswordResetGuard = /** @class */ (function () {
    function PasswordResetGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    PasswordResetGuard.prototype.canActivate = function (route, state) {
        // If there is a token and that token is marked as needing a password reset, force them to go to the password change screen
        // If there isn't a token then or there's a token and it doesn't need a password reset, then allow them (this lets non auth-guarded pages load appropriately)
        if (this.authService.getDecodedAccessToken() && this.authService.requiresPasswordReset(this.authService.getDecodedAccessToken())) {
            this.router.navigate(['passwordChange']);
            return false;
        }
        return true;
    };
    PasswordResetGuard.ngInjectableDef = i0.defineInjectable({ factory: function PasswordResetGuard_Factory() { return new PasswordResetGuard(i0.inject(i1.AuthHelperService), i0.inject(i2.Router)); }, token: PasswordResetGuard, providedIn: "root" });
    return PasswordResetGuard;
}());
export { PasswordResetGuard };
