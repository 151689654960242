import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MessageService } from 'primeng/api';
import { MaskService } from 'src/app/services/mask.service';
import { AAInvoice,
  WellnessWorkOrderCashCollected,
  WorkOrderGenSvc,
  WorkOrderInvoicingViewModel,
  WorkWorkOrderCashCollected } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() workOrder: WorkOrderInvoicingViewModel;

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Output() close  = new EventEmitter<boolean>();

  currencyMask: any;
  cashCollected: (WellnessWorkOrderCashCollected | WorkWorkOrderCashCollected)[] = [];
  nonInvoicedCashCollected: (WellnessWorkOrderCashCollected | WorkWorkOrderCashCollected)[];
  isLoading: boolean;
  disableApply: boolean;

  constructor(private messageService: MessageService,
    private maskService: MaskService,
    private workOrderService: WorkOrderGenSvc) { }

  ngOnInit() {
    this.currencyMask = this.maskService.currencyMask;
    this.getCashCollected();
  }

  getCashCollected() {
    if (this.workOrder.serviceType === 'Tree Wellness') {
      this.workOrderService.getWellnessCashCollected(this.workOrder.workOrderId).subscribe(res => {
        this.cashCollected = res;
        this.nonInvoicedCashCollected = res.filter(cc => !cc.invoiced);
        this.isLoading = false;
        this.disableApply = false;
      });
    } else {
      this.workOrderService.getWorkCashCollected(this.workOrder.workOrderId).subscribe(res => {
        this.cashCollected = res;
        this.nonInvoicedCashCollected = res.filter(cc => !cc.invoiced);
        this.isLoading = false;
        this.disableApply = false;
      });
    }
  }

  pushNewCashCollectedEntry() {
    if (!this.cashCollected) {
      this.cashCollected = [];
    }

    if (this.workOrder.serviceType === 'Tree Wellness') {
      const newEntry = new WellnessWorkOrderCashCollected();
      newEntry.invoiced = false;
      newEntry.wellnessWorkOrderId = this.workOrder.workOrderId;
      newEntry.amount = null;
      this.cashCollected.push(newEntry);
    } else if (this.workOrder.serviceType === 'Tree Work') {
      const newEntry = new WorkWorkOrderCashCollected();
      newEntry.invoiced = false;
      newEntry.workWorkOrderId = this.workOrder.workOrderId;
      newEntry.amount = null;
      this.cashCollected.push(newEntry);
    }
  }

  cashCollectedChange(newValue: string, index: number) {
    if (newValue && newValue.length > 0 && newValue[0] === '$') {
      if (newValue[0] === '$') {
        newValue = newValue.replace('$', '');
      }
      newValue = newValue.split(',').join('');
    }
    if (newValue && newValue.length > 0) {
      this.cashCollected[index].amount = parseFloat(newValue);
    } else {
      this.cashCollected[index].amount = 0;
    }
  }

  saveCashCollectedEntry() {
    this.disableApply = true;
      if (this.workOrder.serviceType === 'Tree Wellness') {
        this.workOrderService.saveWellnessCashCollectedRange(this.cashCollected, this.workOrder.workOrderId).subscribe(() => {
          this.getCashCollected();
        }, error => {
          console.log(error);
          this.disableApply = false;
          this.addErrorMessage('Could not save cash collected. Ensure all fields are valid and retry.');
        });
      } else {
        this.workOrderService.saveWorkCashCollectedRange(this.cashCollected, this.workOrder.workOrderId).subscribe(() => {
          this.getCashCollected();
        }, error => {
          console.log(error);
          this.disableApply = false;
          this.addErrorMessage('Could not save cash collected. Ensure all fields are valid and retry.');
        });
      }
  }

  removeCashCollectedEntry(index) {
    this.cashCollected.splice(index, 1);
  }

  setCashIdToApply(invoice: AAInvoice, event) {
    invoice.cashIdToApply = event.id;
  }

  applyCashToInvoice(invoice: AAInvoice) {
    this.disableApply = true;
    this.workOrderService.applyCashCollectedToInvoice(this.workOrder.serviceType, invoice.invoiceId, invoice.cashIdToApply).subscribe(res => {
      invoice.cashIdToApply = null;
      const index: number = this.workOrder.invoices.findIndex(i => i.invoiceId === invoice.invoiceId);
      this.workOrder.invoices[index] = res;
      this.isLoading = false;
      this.ngSelectComponent.handleClearClick();
      this.getCashCollected();
    }, error => {
      this.disableApply = false;
      if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
        this.isLoading = false;
        this.addErrorMessage(JSON.parse(error.response).ExceptionMessage);
      } else {
        this.isLoading = false;
        this.addErrorMessage('Unable to apply the cash, please try again or contact support.');
      }
    });
  }

  chargeCardForInvoice(invoice: AAInvoice) {
    if (confirm('This will not apply any outstanding cash/check to the invoice. To apply cash before continuing press Cancel to return to the invoicing dialog.')) {
      this.disableApply = true;
      this.workOrderService.chargeInvoice(invoice.invoiceId).subscribe(res => {
        if (res) {
          const index: number = this.workOrder.invoices.findIndex(i => i.invoiceId === invoice.invoiceId);
          this.workOrder.invoices[index] = res;
          this.isLoading = false;
          this.disableApply = false;
          this.ngSelectComponent.handleClearClick();
        } else {
          this.isLoading = false;
          this.disableApply = false;
        }
      }, error => {
        this.disableApply = false;
        if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
          this.isLoading = false;
          this.disableApply = false;
          this.addErrorMessage(JSON.parse(error.response).ExceptionMessage);
        } else {
          this.isLoading = false;
          this.disableApply = false;
          this.addErrorMessage('Unable to charge the card, please try again or contact support.');
        }
      });
    }
  }

  closeAndRefresh() {
    this.close.emit(true);
  }

  navigate(url: string) {
    window.open(url, '_blank');
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }
}
