<div class="p-grid">
  <div class="p-col-8">
    <p-scrollPanel [style]="{width: '100%', height: '92vh'}">
      <p-panel [header]="header" class="topHeader multiDayJobsPanel">
        <app-multiday-jobs-panel *ngIf="type == 'wellness'"></app-multiday-jobs-panel>
        <app-multiday-jobs-work-panel *ngIf="type == 'work'"></app-multiday-jobs-work-panel>
      </p-panel>
    </p-scrollPanel>
  </div>
  <div class="p-col-4">
    <p-scrollPanel [style]="{width: '100%', height: '92vh'}">
      <app-multiday-equipment-and-employees></app-multiday-equipment-and-employees>
    </p-scrollPanel>
  </div>
</div>