/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-billing-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./billing-profile-entry-modal/billing-profile-entry-modal.component.ngfactory";
import * as i3 from "./billing-profile-entry-modal/billing-profile-entry-modal.component";
import * as i4 from "@angular/common";
import * as i5 from "./customer-billing-profile.component";
import * as i6 from "../../services_autogenerated/generated_services";
import * as i7 from "../../services/auth-helper.service";
var styles_CustomerBillingProfileComponent = [i0.styles];
var RenderType_CustomerBillingProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerBillingProfileComponent, data: {} });
export { RenderType_CustomerBillingProfileComponent as RenderType_CustomerBillingProfileComponent };
function View_CustomerBillingProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "margin-right:4px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " Ending in ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stripeBilling.cCBrand; var currVal_1 = _co.getLast4Display(_co.stripeBilling.cCLast4); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CustomerBillingProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "margin-right:4px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " Ending in ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stripeBilling.bankName; var currVal_1 = _co.getLast4Display(_co.stripeBilling.aCHLast4); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CustomerBillingProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-billing-profile-entry-modal", [], null, [[null, "emitUpdatedStripeCustomerId"], [null, "emitClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emitUpdatedStripeCustomerId" === en)) {
        var pd_0 = (_co.updatedStripeCustomer($event) !== false);
        ad = (pd_0 && ad);
    } if (("emitClose" === en)) {
        var pd_1 = (_co.closeBillingProfileEntryModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_BillingProfileEntryModalComponent_0, i2.RenderType_BillingProfileEntryModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.BillingProfileEntryModalComponent, [], { customer: [0, "customer"], stripeBilling: [1, "stripeBilling"], display: [2, "display"] }, { emitClose: "emitClose", emitUpdatedStripeCustomerId: "emitUpdatedStripeCustomerId" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customer; var currVal_1 = _co.stripeBilling; var currVal_2 = _co.displayBillingProfileEntryModal; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CustomerBillingProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "ui-g-12 columnPadding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Billing Info"])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerBillingProfileComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerBillingProfileComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openBillingProfileEntryModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerBillingProfileComponent_3)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.stripeBilling == null) ? null : _co.stripeBilling.cCLast4); _ck(_v, 8, 0, currVal_0); var currVal_1 = ((_co.stripeBilling == null) ? null : _co.stripeBilling.aCHLast4); _ck(_v, 10, 0, currVal_1); var currVal_4 = _co.displayBillingProfileEntryModal; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.hasBillingAddEditPermission() ? "link" : "link disabledLink"); _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.stripeBilling ? "[EDIT]" : "[ADD]"); _ck(_v, 12, 0, currVal_3); }); }
export function View_CustomerBillingProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-billing-profile", [], null, null, null, View_CustomerBillingProfileComponent_0, RenderType_CustomerBillingProfileComponent)), i1.ɵdid(1, 114688, null, 0, i5.CustomerBillingProfileComponent, [i6.StripeGenSvc, i7.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerBillingProfileComponentNgFactory = i1.ɵccf("app-customer-billing-profile", i5.CustomerBillingProfileComponent, View_CustomerBillingProfileComponent_Host_0, { customer: "customer" }, { billingProfileEntryModalClosed: "billingProfileEntryModalClosed" }, []);
export { CustomerBillingProfileComponentNgFactory as CustomerBillingProfileComponentNgFactory };
