<div class="p-grid">
    <div class="p-col-12 p-md-7">
        <p-scrollPanel [style]="{width: '100%', height: '90vh'}">
            <p-panel header="Crew & Equipment"
                [toggleable]="true"
                class="topHeader" [collapsed]="true">
                <app-crews-and-equipment-panel [selectedView]='selectedView'></app-crews-and-equipment-panel>
            </p-panel>
            <p-panel header="Work Orders"
                [toggleable]="true"
                class="topHeader">
                <app-work-orders-panel *ngIf="calendarComponent"
                    [selectedView]='selectedView'
                    [isWellnessOnly]="true"
                    (taskDraggedStartEvent)="taskDragStart($event)"
                    (taskDraggedEndEvent)="taskDragEnd()"
                    (dragStartEvent)="woDragStart($event)"
                    (dragEndEvent)="clearGrayOverlayOnBuckets()"></app-work-orders-panel>
            </p-panel>
        </p-scrollPanel>
    </div>
    <div class="p-col-12 p-md-5" style="padding: 1rem !important;">
        <p-scrollPanel [style]="{width: '100%', height: '90vh'}">
            <div style="height: 100%">
                <app-two-day-calendar-view (woDragged)="onWoDragged($event)" (woDraggedEnd)="onWoDraggedEnd()"
                (taskDragged)="taskDragStart($event)" (taskDraggedEnd)="taskDragEnd()" [isWellness]="true" [isWork]="false"></app-two-day-calendar-view>
            </div>
        </p-scrollPanel>
    </div>
</div>