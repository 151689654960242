<br>
<div>
    <span class="headerText">Skills/Certifications</span>
    <div style="float:right">
        <div>
            <button type="button" class="btn btn-success addNewButton" (click)="addNew();">Add New</button>
        </div>
    </div>
</div>
<br>
<p-table [columns]="cols" [value]="skills" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-skill
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <ng-container *ngFor="let col of columns">
        <td *ngIf="col.type !== 'actions'">
          <span class="ui-column-title">{{col.header}}</span>{{skill[col.field]}}
        </td>
        <td *ngIf="col.type === 'actions'">
          <button
            pButton
            type="button"
            icon="pi pi-pencil"
            (click)="editSkill(skill)"
            class="ui-button-secondary blackBorder editButtonMargin">
          </button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            (click)="deleteSkill(skill)"
            class="ui-button-danger">
          </button>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
