import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { StripeBilling, StripeBillingPaymentType, StripeGenSvc, Customer, CustomerGenSvc, PlaidLinkSuccessDTO, StateGenSvc, State } from 'src/app/services_autogenerated/generated_services';
import { StripeService, Elements, Element, TokenResult, Token} from 'ngx-stripe';
import { NgForm } from '@angular/forms';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { PaymentType } from 'src/app/models/paymentType';
import { Observable, of } from 'rxjs';
import { MaskService } from 'src/app/services/mask.service';
import { CustomerService } from '../../services/customer.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

declare var Plaid: any;

@Component({
  selector: 'app-stripe-billing',
  templateUrl: './stripe-billing.component.html',
  styleUrls: ['./stripe-billing.component.css']
})
export class StripeBillingComponent implements OnInit {

  @Input() stripeBillingIn: StripeBilling = null;
  @Input() customer: Customer;
  @Input() paymentTypes: PaymentType[];
  @Output() errorEmitter = new EventEmitter<string>();
  @Input('showManualPaymentChargeMessage') showManualPaymentChargeMessage = false;
  @Input('isPayInAdvance') isPayInAdvance = false;
  @Input('isPayOnCompletion') isPayOnCompletion = false;
  @Input('isCustomerProfile') isCustomerProfile = false;
  @Input('isFirmtime') isFirmtime = false;
  @Input() isQuote = false;
  stripeBilling = new StripeBilling();
  error: any;
  renderSavedCard = false;
  updateWithNewCard = false;
  cardHandler = this.onChange.bind(this);
  elements: Elements;
  shouldFetchImmediately = false;
  sameAddressAsContact = false;
  paymentAuthorization = false;

  cNumber: any;
  expiry: any;
  cvc: any;

  saving = false;
  stripeCustomerId = null;
  paymentType: PaymentType = PaymentType.Credit;
  public PaymentType = PaymentType;
  states: State[];
  ccExpirationMask: (string | RegExp)[];

  @ViewChild('cardNumber') cardNumber: ElementRef;
  @ViewChild('cardCvc') cardCvc: ElementRef;
  @ViewChild('cardExpiry') cardExpiry: ElementRef;

  constructor(
    private cd: ChangeDetectorRef,
    private changeDetectorRef: ChangeDetectorRef,
    private customStripeService: StripeGenSvc,
    private customerService: CustomerGenSvc,
    private stateService: StateGenSvc,
    private behaviorSubjectService: BehaviorSubjectService,
    private stripeService: StripeService,
    private maskService: MaskService,
    private customCustomerService: CustomerService,
    private authService: AuthHelperService
  ) { }

  ngOnInit() {
    if (this.customer.stripeCustomerId && !this.stripeBillingIn) {
      this.getStripeBilling();
    } else if (this.stripeBillingIn) {
      this.stripeBilling = this.stripeBillingIn;
      this.afterGetStripeBilling(this.stripeBilling);
    } else {
      this.stripeBilling.name = this.customCustomerService.getCustomerFullName(this.customer);
    }
    this.getStates();
    this.setStripeKey();
    this.ccExpirationMask = this.maskService.ccExpirationMask();
  }

  ngAfterViewInit() {
    this.setupStripeElements();
  }

  setStripeKey() {
    this.behaviorSubjectService.stripePublicKeySubject.subscribe(key => {
      this.stripeService.setKey(key);
    });
  }

  ngOnDestroy() {
    if (this.elements) {
        this.cNumber.removeEventListener('change', this.cardHandler);
        this.expiry.removeEventListener('change', this.cardHandler);
        this.cvc.removeEventListener('change', this.cardHandler);
        this.cNumber.destroy();
        this.expiry.destroy();
        this.cvc.destroy();
    }
    this.cardHandler = null;
    // this.plaidHandler = null;
  }

  getStates() {
    this.stateService.getAll()
      .subscribe(states => {
        this.states = states;
      });
  }

  getStripeBilling() {
    const token = this.authService.getDecodedAccessToken();
    if (token) {
      this.customStripeService.getStripeBillingByCustomerId(this.customer.id)
        .subscribe(stripeBilling => {
          // console.log(stripeBilling);
          this.afterGetStripeBilling(stripeBilling);
        });
    } else {
      this.stripeBilling = new StripeBilling();
    }
  }

  afterGetStripeBilling(stripeBilling) {
    if ((stripeBilling.paymentType as any) === PaymentType.Credit && stripeBilling.cCLast4) {
      this.renderSavedCard = true;
    }
    if (stripeBilling.address === this.customer.address.street) {
      this.sameAddressAsContact = true;
    }
    this.stripeBilling = stripeBilling;
  }

  setupStripeElements() {
    this.stripeService.elements()
      .subscribe(elements => {
          this.elements = elements;
          this.cNumber = this.elements.create('cardNumber', {});
          this.expiry = this.elements.create('cardExpiry', {});
          this.cvc = this.elements.create('cardCvc', {});
          this.cNumber.mount(this.cardNumber.nativeElement);
          this.expiry.mount(this.cardExpiry.nativeElement);
          this.cvc.mount(this.cardCvc.nativeElement);

          this.cNumber.addEventListener('change', this.cardHandler);
          this.expiry.addEventListener('change', this.cardHandler);
          this.cvc.addEventListener('change', this.cardHandler);
      });
  }

  addNewCard(event) {
    event.preventDefault();
    this.renderSavedCard = false;
    this.updateWithNewCard = true;
    this.changeDetectorRef.detectChanges();
    this.setupStripeElements();
  }

  cancelAddNewCard(event) {
    event.preventDefault();
    this.renderSavedCard = true;
    this.updateWithNewCard = false;
  }

  onChange({ error }) {
    if (error) {
      this.errorEmitter.emit(error);
    } else {
      this.errorEmitter.emit(null);
    }
    this.cd.detectChanges();
  }

  removeExpirationError() {
    document.getElementById('expiration').classList.remove('ng-invalid');
  }

  removeZipError() {
    document.getElementById('zip').classList.remove('ng-invalid');
  }

  generateCCToken(): Observable<TokenResult> {
     return this.stripeService.createToken(this.cNumber,
      {
          address_line1: this.stripeBilling.address,
          // address_city: this.stripeBilling.city,
          // address_state: this.stripeBilling.state.name,
          address_zip: this.stripeBilling.zip.toString(),
          name: this.stripeBilling.name
      });
  }

  // performStripeUpdate() {
  //   if (this.checkFormError()) {
  //       this.saving = false;
  //   } else {
  //       this.saving = true;
  //       this.customStripeService.performTestCharge();
  //   }
  // }

  // returns true if in error state.
  checkFormError(): boolean {
    if (this.renderSavedCard && !this.isValidccExpiration()) {
        document.getElementById('expiration').classList.add('ng-invalid');
    }
    if (this.stripeBilling.address == null || this.stripeBilling.address == undefined || this.stripeBilling.address == '') {
      this.onChange({ error: { message: 'Please enter a billing address' } });
    } 
    // else if (this.stripeBilling.city == null || this.stripeBilling.city == undefined || this.stripeBilling.city == '') {
    //     this.onChange({ error: { message: 'Please enter a billing city' } });
    // } else if (this.stripeBilling.state_Id == null || this.stripeBilling.state_Id == undefined) { //|| this.bStates.find(x => x.Id == this.stripeBilling.State_Id) == undefined
    //     this.onChange({ error: { message: 'Please enter a billing state' } });
    // } 
    else if (this.stripeBilling.zip == null || this.stripeBilling.zip == undefined) {
        this.onChange({ error: { message: 'Please enter a billing zip code' } });
    } else if (this.stripeBilling.zip.toString().length !== 5 || this.stripeBilling.zip.toString().indexOf('e') >= 0) {
        this.onChange({ error: { message: 'Please enter a valid 5 digit zip code' } });
        document.getElementById('zip').classList.add('ng-invalid');
    } else if (this.renderSavedCard && !this.isValidccExpiration()) {
        this.onChange({ error: { message: 'Please enter a valid expiration in the future' } });
    } 
    // else if (this.showChargePermissionMessage && !this.paymentAuthorization) {
    //     this.onChange({ error: { message: 'Please accept payment authorization' } });
    // } 
    else {
      return false
    }
    return true;
  }

  isValidccExpiration(): boolean {
    if (this.renderSavedCard && this.stripeBilling.expiration !== null && this.stripeBilling.expiration !== '' &&
        this.stripeBilling.expiration.indexOf('_') < 0 && this.stripeBilling.expiration.length == 5) {
        if (this.stripeBilling.expiration.indexOf('/') > 0) {
            const expirationSplit = this.stripeBilling.expiration.split('/');
            const expirationMonth = expirationSplit[0];
            const expirationYear = expirationSplit[1];
            const currentDate = new Date();
            const currentYear = Number.parseInt(currentDate.getFullYear().toString().substring(2, 4));

            if (Number.parseInt(expirationMonth) >= currentDate.getMonth() + 1) {
                if (Number.parseInt(expirationYear) >= currentYear) {
                    return true;
                }
            } else if (Number.parseInt(expirationYear) > currentYear) {
                return true;
            }
        }
    }
    return false;
  }

  onStateSelect(selectedState: State) {
    this.stripeBilling.state_Id = selectedState.id;
  }

  // private onCaughtErrorSave = (error: any): Observable<StripeBilling> => {
  //   try {
  //       alert('Error performing save.\n' + Object.values(error.error.ModelState.errors).join('\n'));
  //   } catch (e) {
  //       alert('Error performing save');
  //   }
  //   this.saving = false;
  //   return of(null);
  // }

  clearErrors() {
    this.error = null;
    this.errorEmitter.emit(null);
  }

  onToggleSameAddressCheckbox() {
    // console.log(this.customer);
    if (this.sameAddressAsContact) {
      this.stripeBilling.address = this.customer.address.street;
      this.stripeBilling.zip = this.customer.address.zip;
    }
  }

  onAddresChange() {
    if (this.sameAddressAsContact && this.customer.address && this.stripeBilling.address !== this.customer.address.street) {
      this.sameAddressAsContact = false;
    } else if (!this.sameAddressAsContact && this.customer.address && this.stripeBilling.address === this.customer.address.street) {
      this.sameAddressAsContact = true;
    }
  }

  paymentTypeEnabled(paymentType: PaymentType) {
    if (this.paymentTypes) {
      return this.paymentTypes.indexOf(paymentType) >= 0 && this.paymentTypes.length > 1;
    } else {
      return true;
    }
  }
}
