<app-work-order-filter-bar
  [isDayView]="selectedView == 'day'"
  [typeOfWorkOrders]="'work'"
  [allWorkOrders]="allWorkOrders"
  [rms]="rms"
  (OnWoFilteringFinished)="OnWoFilteringFinished($event)"
  [useSearchDelay]="false"
  >
</app-work-order-filter-bar>

<p-panel header="" class="innerHeader">
  <div class="p-grid">
    <app-multiday-job-card class="cdk-drag p-lg-4 p-md-12" *ngFor="let bigDayBucket of filteredBigDayBuckets"
      [bigDayBucket]="bigDayBucket" [type]="'work'">
    </app-multiday-job-card>
  </div>
</p-panel>