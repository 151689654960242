import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { AdvancedCommissionFilter,
  ReportGenSvc,
  SystemSettingGenSvc,
  WorkOrderCommissionViewModel,
  WorkOrderGenSvc} from 'src/app/services_autogenerated/generated_services';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-commission-report',
  templateUrl: './commission-report.component.html',
  styleUrls: ['./commission-report.component.css']
})
export class CommissionReportComponent implements OnInit {
  @ViewChild(Table) table: Table;

  searchInput = new Subject<string>();

  commissionLaborHourRequirement: number;

  canOverrideCommissionEarned: boolean;
  canSeeHiddenPrice: boolean;
  cols: any[];
  isLoading: boolean = false;
  totalRecords: number;

  rows: number = 25;

  allWorkOrders: WorkOrderCommissionViewModel[] = [];

  yearRange: string = '2021:' + new Date().getFullYear();

  advancedFilters: AdvancedCommissionFilter = new AdvancedCommissionFilter({
    shouldFilterByRM: false,
    shouldFilterByType: false,
    shouldFilterByCommissionEarned: false,
    workHoursSearch: '',
    wONumberSearch: '',
    services: [],
    rMs: [],
    dateSearch: [],
    paidStatuses: [],
    commissionEarnedSearch: '',
    skip: 0,
    take: this.rows,
    globalFilter: '',
    orderBy: 'Number ASC'
  });

  lastLazyEvent: LazyLoadEvent;

  showAddNoteDialog: boolean;
  addNoteWorkOrderRow: WorkOrderCommissionViewModel;

  claimNames: string[];

  serviceTypes: SelectItem[] = [
    { label: 'Tree Wellness', value: 'Tree Wellness'},
    { label: 'Tree Work', value: 'Tree Work'}
  ];

  commissionEarnedItems: SelectItem[] = [
    { label: 'Yes', value: '1'},
    { label: 'No', value: '0'}
  ];

  paidStatusItems: SelectItem[] = [
    { label: 'Yes', value: 'Yes'},
    { label: 'No', value: 'No'},
    { label: 'Partial', value: 'Partial'}
  ];

  rangeDates: Date[];
  signedRangeDates: Date[];
  rms: SelectItem[];

  constructor(private reportService: ReportGenSvc,
    private messageService: MessageService,
    private authHelper: AuthHelperService,
    private systemSettingService: SystemSettingGenSvc,
    private workOrderService: WorkOrderGenSvc,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe) { }


    /**
      Number = reader.GetString(0),
      DateCompleted = reader.GetString(1),
      ServiceType = reader.GetString(2),
      Representative = reader.GetString(3),
      Total = reader.GetDecimal(4),
      StumpGrindingTotal = reader.GetDecimal(5),
      RentalCost = reader.GetDecimal(6),
      NetTotal = reader.GetDecimal(7),
      HoursTotal = reader.GetDecimal(8),
      WorkHourAverage = GetNullableDecimal(reader, 9),
      CommissionEarned = reader.GetBoolean(10),
      LatestNote = reader.GetString(11),
      WorkOrderId = reader.GetInt32(12),
      CustomerId = reader.GetInt32(13)
     */

  ngOnInit() {
    this.cols = [
      { field: 'Number', header: 'Work Order', width: '7.5%' },
      { field: 'SignedDate', header: 'Date Signed', width: '5%' },
      { field: 'DateCompleted', header: 'Date Completed', width: '6%' },
      { field: 'ServiceType', header: 'Service Type', width: '6.5%' },
      { field: 'Representative', header: 'Regional Manager', width: '7%' },
      { field: 'Total', header: 'Total Price', width: '6%', info: 'Total Price including stump grinding and price adjustments' },
      { field: 'StumpGrindingTotal', header: 'Stump Grinding Price', width: '6.5%', info: 'Total price of stump grinding tasks and price adjustments' },
      { field: 'RentalCost', header: 'Outside Costs', width: '5.5%' },
      { field: 'NetPrice', header: 'Net Price', width: '5.5%', info: 'Total Price minus stump grinding and Outside Costs' },
      { field: 'HoursTotal', header: 'Work Hours', width: '5%', info: 'Sum of hours entered, includes stump grinding hours' },
      { field: 'WorkHourAverage', header: 'Work Hours Average', width: '6%', info: 'Total Price minus Outside Costs, divided by Work Hours' },
      { field: 'commissionMinimum', header: 'Commission Minimum', width: '6%', info: 'Desired minimum Work Hours to get commission, set when quote is created' },
      { field: 'CommissionEarned', header: 'Commission Earned', width: '6.5%' },
      { header: 'Commission', width: '6%', info: 'Total Price minus Outside Costs multiplied by Commission Bonus Percentage' },
      { field: 'PaidStatus', header: 'Paid', width: '5%' },
      { field: 'LatestNote', header: 'Latest Note' } // no width means it fills out the rest of the space
    ];

    const token = this.authHelper.getDecodedAccessToken();
    this.claimNames = token.claimNames;
    this.canOverrideCommissionEarned = this.claimNames.includes('Edit Commission Override');

    this.isLoading = true;

    this.systemSettingService.getCommissionLaborHourRequirement().subscribe(setting =>
      this.commissionLaborHourRequirement = parseFloat(setting.value)
    );

    this.setUpSearchDelay();
  }

  setUpSearchDelay() {
    this.searchInput.pipe(
      debounceTime(600),
      distinctUntilChanged())
      .subscribe(() => {
        this.filterFromBar();
      });
  }

  loadRequestLazy(event: LazyLoadEvent) {
    this.lastLazyEvent = event;
    const sortOption = this.getSortOption(event.sortField, event.sortOrder);

    this.isLoading = true;

    this.advancedFilters.skip = event.first;
    this.advancedFilters.take = event.rows;
    this.advancedFilters.orderBy = sortOption;
    this.advancedFilters.globalFilter = '';

    this.reportService.getCommissionReportPaginated(this.advancedFilters)
      .subscribe(res => {
        this.allWorkOrders = res.results;
        this.totalRecords = res.totalResults;
        this.isLoading = false;

         // Only set these if they haven't been set yet.
         if (!this.rms) {
          this.rms = this.getUniqueSelectItems(res.rMs);
        }
      },
      error => {
        this.isLoading = false;
        this.addErrorMessage('Could not load the work orders, please refresh and try again.');
      });
  }

  filterFromBar() {
    this.isLoading = true;

    this.advancedFilters.shouldFilterByWONumber = this.advancedFilters.wONumberSearch.length > 0;

    if (this.rangeDates && this.rangeDates[0] && this.rangeDates[1]) {
      this.advancedFilters.shouldFilterByCompletionDate = true;
      this.advancedFilters.dateSearch = this.rangeDates.map(d => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`);
    } else {
      this.advancedFilters.shouldFilterByCompletionDate = false;
    }

    if (this.signedRangeDates && this.signedRangeDates[0] && this.signedRangeDates[1]) {
      this.advancedFilters.shouldFilterBySignedDate = true;
      this.advancedFilters.signedDateSearch = this.signedRangeDates.map(d => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`);
    } else {
      this.advancedFilters.shouldFilterBySignedDate = false;
    }

    this.advancedFilters.shouldFilterByPaidStatus = this.advancedFilters.paidStatuses.length > 0;

    this.advancedFilters.shouldFilterByType = this.advancedFilters.services.length > 0;

    this.advancedFilters.shouldFilterByRM = this.advancedFilters.rMs.length > 0;

    this.advancedFilters.shouldFilterByCommissionEarned = this.advancedFilters.commissionEarnedSearch !== null
                                                          && this.advancedFilters.commissionEarnedSearch !== undefined
                                                          && this.advancedFilters.commissionEarnedSearch.length > 0;

    const lazyRequest = {
      ...this.lastLazyEvent,
      first: 0,
      rows: this.rows
    };
    this.table.first = 0;
    this.loadRequestLazy(lazyRequest);
  }

  getSortOption(field: string, order: number) {
    if (order === 1) {
      return field + ' ASC';
    } else {
      return field + ' DESC';
    }
  }

  navigateToWorkOrder(workOrder: WorkOrderCommissionViewModel) {
    if (workOrder.serviceType === 'Tree Wellness') {
      window.open('wellnessWorkOrderMaintenance/' + workOrder.workOrderId, '_blank');
    } else {
      window.open('workWorkOrderMaintenance/' + workOrder.workOrderId, '_blank');
    }
  }

  addNoteForRow(workOrder: WorkOrderCommissionViewModel) {
    this.addNoteWorkOrderRow = workOrder;
    this.showAddNoteDialog = true;
  }

  closedDialog(refresh: boolean) {
    this.showAddNoteDialog = false;
    this.addNoteWorkOrderRow = null;

    if (refresh) {
      this.loadRequestLazy(this.lastLazyEvent);
    }
  }

  getCommissionAmount(workOrder: WorkOrderCommissionViewModel): number {
    return (workOrder.total - workOrder.rentalCost) * (workOrder.commissionBonusPercentage / 100);
  }

  editMode(workOrder: WorkOrderCommissionViewModel): void {
    workOrder.editing = !workOrder.editing;
  }

  saveCommissionEarned(workOrder: WorkOrderCommissionViewModel): void {
    if (this.canOverrideCommissionEarned) {
      this.workOrderService.saveCommissionEarned(workOrder).subscribe(res => {
        this.editMode(workOrder);
      }, error => {
        if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
          this.isLoading = false;
          this.addErrorMessage(JSON.parse(error.response).ExceptionMessage);
        } else {
          this.isLoading = false;
          this.addErrorMessage('The commission earned value was not updated, refresh the page and try again.');
        }
      });
    } else {
      this.addErrorMessage('You don\'t have permission to override that.');
    }
  }

  exportToCSV() {
    this.isLoading = true;
    document.body.classList.add('busy-cursor');

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      headers: ['Number', 'Date Signed', 'Date Completed', 'Service Type', 'Regional Manager', 'Total Price', 'Stump Grinding Price',
        'Outside Costs', 'Net Price', 'Work Hours', 'Work Hours Average', 'Commission Minimum', 'Commission Earned', 'Commission', 'Paid', 'Latest Note']
    };

    this.reportService.getCommissionReportForExport(this.advancedFilters).subscribe(res => {
      const dataToExport = res.map(wo => {
        return {
          number: wo.number,
          dateSigned: this.datePipe.transform(wo.dateSigned, 'shortDate'),
          dateCompleted: this.datePipe.transform(wo.dateCompleted, 'shortDate'),
          serviceType: wo.serviceType,
          regionalManager: wo.representative,
          totalPrice: this.currencyPipe.transform(wo.total, 'USD'),
          stumpGrindingPrice: this.currencyPipe.transform(wo.stumpGrindingTotal, 'USD'),
          outsideCodes: this.currencyPipe.transform(wo.rentalCost, 'USD'),
          netPrice: this.currencyPipe.transform(wo.netTotal, 'USD'),
          workHours: wo.hoursTotal,
          workHoursAverage: wo.workHourAverage ? this.currencyPipe.transform(wo.workHourAverage, 'USD') : '',
          commissionMinimum: this.currencyPipe.transform(wo.commissionMinimum, 'USD'),
          commissionEarned: wo.commissionEarned ? 'Yes' : 'No',
          commission: this.currencyPipe.transform(this.getCommissionAmount(wo), 'USD'),
          paid: wo.paidStatus,
          latestNote: wo.latestNote
        };
      });

      // total row - may be added later
      // dataToExport.push({
      //   number: 'Total as of ' + this.datePipe.transform(this.advancedFilters.asOfDate, 'shortDate'),
      //   name: '',
      //   type: '',
      //   completedOn: '',
      //   paidOn: '',
      //   status: '',
      //   amount: this.total
      // });

      const file = new ngxCsv(dataToExport, `Commission Report ${this.datePipe.transform(new Date(), 'medium')}`, options);

      document.body.classList.remove('busy-cursor');
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.addErrorMessage('Could not export results.');
    });
  }


  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }

  private getUniqueSelectItems(arr: string[]): SelectItem[] {
    const unique = arr.map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]).sort();

    return unique.map(u => ({label: u, value: u}));
  }
}
