import { Component, OnInit } from '@angular/core';
import { Employee, ZipCode, EmployeeGenSvc, ZipCodeGenSvc } from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-zip-code-assignment',
  templateUrl: './zip-code-assignment.component.html',
  styleUrls: ['./zip-code-assignment.component.css']
})
export class ZipCodeAssignmentComponent implements OnInit {
  rms: Employee[];
  zipCodes: ZipCode[];
  saving: boolean;
  canSave: boolean;

  constructor(private employeeService: EmployeeGenSvc,
    private zipCodeService: ZipCodeGenSvc,
    private messageService: MessageService,
    private authHelper: AuthHelperService) { }

  ngOnInit() {
    this.setPermissions();
    this.employeeService.getCurrentlyEmployed().subscribe(employees => {
      this.rms = employees.filter(e => e.employeeRoles.map(er => er.role).some(r => r.name === 'Regional Manager'));
    });

    this.zipCodeService.getAll().subscribe(zipCodes => {
      this.zipCodes = zipCodes;
    });
  }

  setPermissions() {
    const token = this.authHelper.getDecodedAccessToken();
    this.canSave = token.claimNames.includes('Can Edit Zip Code Maintenance');
  }

  saveAll() {
    this.saving = true;
    this.employeeService.updateZipCodeAssignments(this.rms).subscribe(() => {
      this.saving = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Saved Successfully',
        detail: 'The zip code assignments were saved successfully.'
      });
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Could not save the zip code assignments, please refresh the page and try again.'
      });
    });
  }
}
