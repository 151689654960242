import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DecodedJwt } from '../models/decodedJwt';
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MessageService } from 'primeng/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-pwa/local-storage";
import * as i4 from "primeng/components/common/messageservice";
var AuthHelperService = /** @class */ (function () {
    function AuthHelperService(http, router, localStorage, messageService, route) {
        this.http = http;
        this.router = router;
        this.localStorage = localStorage;
        this.messageService = messageService;
        this.route = route;
        this.isLoggedInSubject = new Subject();
        this.authToken = 'russell_tree_auth_token';
        this.urlSuffix = 'api/v1/authentication'; // URL to web api
    }
    AuthHelperService.prototype.logOut = function () {
        localStorage.removeItem(this.authToken);
        this.router.navigate(['/login']);
        this.isLoggedInSubject.next(false);
    };
    AuthHelperService.prototype.getDecodedAccessToken = function (token) {
        try {
            if (!token) {
                token = localStorage.getItem(this.authToken);
            }
            var decodedToken = jwt_decode(token);
            return new DecodedJwt(decodedToken);
        }
        catch (Error) {
            return null;
        }
    };
    AuthHelperService.prototype.isLoggedIn = function () {
        var token = this.getDecodedAccessToken();
        var notExpired = token && new Date(token.exp * 1000) > new Date(); // change to ms then date
        if (!notExpired) { // makes notExpired non null
            notExpired = false;
        }
        this.isLoggedInSubject.next(notExpired);
        return notExpired;
    };
    AuthHelperService.prototype.redirectToHome = function () {
        var token = this.getDecodedAccessToken();
        if (this.requiresPasswordReset(token)) {
            if (this.route.snapshot.queryParams.asAnonymous) {
                var extras = {
                    queryParams: { 'asAnonymous': this.route.snapshot.queryParams.asAnonymous }
                };
                this.router.navigate(['/passwordChange'], extras);
            }
            else {
                this.router.navigate(['/passwordChange']);
            }
        }
        else if (token.userType === 'customer') {
            this.router.navigate(['/customerLandingPage/' + token.id]);
        }
        else if (token.roles.includes('Arborist') || token.roles.includes('Foreman')) {
            this.router.navigate(['/scheduling/myschedule']);
        }
        else {
            this.router.navigate(['/welcome']);
        }
        this.isLoggedInSubject.next(true);
    };
    AuthHelperService.prototype.requiresPasswordReset = function (token) {
        if (token.requirePasswordReset === 'True') {
            return true;
        }
        else if (token.passwordIsExpired === 'True') {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.prototype.requiresMultiFactorAuthSetup = function (token) {
        if (token.requireMultiFactorAuthSetup === 'True') {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.prototype.requiresMultiFactorAuth = function (token) {
        if (token.requireMultiFactorAuth === 'True') {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.prototype.hasClaim = function (claim) {
        var token = this.getDecodedAccessToken();
        if (Object.keys(token).indexOf(claim) >= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.ngInjectableDef = i0.defineInjectable({ factory: function AuthHelperService_Factory() { return new AuthHelperService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.LocalStorage), i0.inject(i4.MessageService), i0.inject(i2.ActivatedRoute)); }, token: AuthHelperService, providedIn: "root" });
    return AuthHelperService;
}());
export { AuthHelperService };
