<br>
<form id="surveyForm" #surveyForm="ngForm">
  <div class="ui-g" *ngIf="surveyHeader">
    <div class="ui-g-12" *ngFor="let answer of surveyHeader.surveyAnswers; let i = index; trackBy: trackByIndex;">
      <ng-container *ngIf="answer.surveyQuestion.visible">
        <ng-container *ngIf="answer.surveyQuestion.isYesNo">
          <h6>{{answer.surveyQuestion.question}}</h6>
          <span style="margin-left: .5em">
            <p-radioButton [name]="'yesNoGroup' + i" [value]="true" label="Yes"
              [(ngModel)]="surveyHeader.surveyAnswers[i].yesNoResponse" inputId="opt1" [disabled]="completed">
            </p-radioButton>
          </span>
          <span style="margin-left: .5em">
            <p-radioButton [name]="'yesNoGroup' + i" [value]="false" label="No"
              [(ngModel)]="surveyHeader.surveyAnswers[i].yesNoResponse" inputId="opt2" [disabled]="completed">
            </p-radioButton>
          </span>
        </ng-container>
        <ng-container *ngIf="!answer.surveyQuestion.isYesNo">
          <h6>{{answer.surveyQuestion.question}}</h6>
          <textarea pInputTextarea [name]="'comment' + i" [(ngModel)]="surveyHeader.surveyAnswers[i].comment"
            [disabled]="completed"></textarea>
        </ng-container>
      </ng-container>
    </div>
    <div class="ui-g-12 columnPadding">
      <div *ngIf="errorsInForm" class="alert alert-danger" role="alert">
        Please complete all yes/no questions.
      </div>
    </div>
    <div class="ui-g-12 buttonStyle">
      <button *ngIf="!completed" pButton type="submit" class="ui-button-raised ui-button-success buttonStyle"
        (click)="submit()" label="Submit">
      </button>
      <button *ngIf="!completed" pButton type="button" class="ui-button-raised ui-button-secondary buttonStyle"
        (click)="cancel()" label="Cancel">
      </button>
      <button *ngIf="completed && !isCustomer" pButton type="button" class="ui-button-raised ui-button-warning buttonStyle"
        (click)="goToSurveyList()" label="Return to List">
      </button>
    </div>
  </div>
</form>
