import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
function bootstrapFailed(val) {
    document.getElementById('bootstrap-fail').style.display = 'block';
    document.getElementById('bootstrap-fail').innerHTML = val;
    console.error('bootstrap-fail', val);
}
fetch('/assets/data/appConfig.json')
    .then(function (response) { return response.json(); })
    .then(function (config) {
    if (!config || !config['apiUrl']) {
        bootstrapFailed(config);
        return;
    }
    // Store the response somewhere that your ConfigService can read it.
    window['tempConfigStorage'] = config;
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .then(function () {
        if ('serviceWorker' in navigator && environment.deployed) {
            // && environment.production) {
            navigator.serviceWorker
                .register('./ngsw-worker.js')
                .then(function (registration) {
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            })
                .catch(function (err) {
                console.log('ServiceWorker registration failed: ', err);
            });
        }
    })
        .catch(bootstrapFailed);
})
    .catch(bootstrapFailed);
