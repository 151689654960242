import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/appConfig';
import { BehaviorSubjectService } from '../services/behavior-subject.service';

@Injectable()
export class AppConfigService {
    appConfig: AppConfig;

    constructor(
        private http: HttpClient,
        private behaviorSubjectManager: BehaviorSubjectService
    ) { }

    init(config) {
        this.appConfig = config;
    }

    loadAppConfig() {
        return this.http.get<AppConfig>('/assets/data/appConfig.json')
            .toPromise()
            .then(data => {
                if (data.plaidPublicKey) {
                    this.behaviorSubjectManager.plaidPublicKeySubject.next(data.plaidPublicKey);
                }
                if (data.plaidEnvironment) {
                    this.behaviorSubjectManager.plaidEnvironmentSubject.next(data.plaidEnvironment);
                }
                if (data.stripePublicKey) {
                    this.behaviorSubjectManager.stripePublicKeySubject.next(data.stripePublicKey);
                }
                if (data.apiUrl) {
                    this.behaviorSubjectManager.apiUrl.next(data.apiUrl);
                }
                if (data.reportLink) {
                    this.behaviorSubjectManager.reportLinkSubject.next(data.reportLink);
                }
                if (data.contactPhoneNumber) {
                    this.behaviorSubjectManager.contactPhoneNumberSubject.next(data.contactPhoneNumber);
                }
                if (data.quoteRequestConfirmationLink) {
                    this.behaviorSubjectManager.quoteRequestConfirmationLinkSubject.next(data.quoteRequestConfirmationLink)
                }
                this.appConfig = data;
            });
    }

    getConfig() {
        return this.appConfig;
    }
}
