<br>
<form id="employeeForm" #employeeForm="ngForm">
    <div class="ui-g">
        <div class="ui-g-12 ui-lg-4 ui-xl-6 columnPadding">
            <div class="ui-g">
                <div class="ui-g-12">
                <h4>Employee Information</h4>
                </div>
            </div>
        <div class="ui-g">
            <div class="ui-g-12 ui-lg-6">
                <h6>First Name</h6>
                <input pInputText class="inputTextBox" name="firstName" [(ngModel)]="employee.firstName" *ngIf="employee" [disabled]="!canEdit" required/>
            </div>
            <div class="ui-g-12 ui-lg-6">
                <h6>Last Name</h6>
                <input pInputText class="inputTextBox" name="lastName" [(ngModel)]="employee.lastName" *ngIf="employee" [disabled]="!canEdit" required/>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12 multiSelect">
                <h6>Role(s)</h6>
                <p-multiSelect 
                    [options]="employeeRoleOptions" 
                    name="employeeRoles" 
                    [(ngModel)]="employee.employeeRoles" 
                    (ngModelChange)="updateEmployeIsRM()"
                    [panelStyle]="{minWidth:'12em'}" 
                    optionLabel="role.name"
                    [disabled]="!canEdit"
                    *ngIf="employee" required>
                    <ng-template let-value pTemplate="selectedItems">
                        <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                            <span>{{val.role.name}}</span>
                        </div>
                        <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Choose</span>
                    </ng-template>
                    <ng-template let-er pTemplate="item">
                        {{er.value.role.name}}
                    </ng-template>
                </p-multiSelect>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-6 ui-fluid">
                <h6>Phone Number</h6>
                <input 
                    pInputText 
                    mask="(000) 000-0000" 
                    placeholder="(     )      -" 
                    name="phoneNumber" 
                    [(ngModel)]="employee.phoneNumber" 
                    [disabled]="!canEdit" 
                    *ngIf="employee"
                />
            </div>
            <div class="ui-g-6">
                <h6>Email</h6>
                <input pInputText class="inputTextBox" name="emailAddress" [(ngModel)]="employee.emailAddress.email" *ngIf="employee" required [disabled]="!canEdit" email />
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12">
                <h6>Address</h6>
                <input pInputText class="inputTextBox" name="street" [(ngModel)]="employee.address.street" *ngIf="employee" [disabled]="!canEdit" required/>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-4">
                <h6>City</h6>
                <input pInputText class="inputTextBox" name="city" [(ngModel)]="employee.address.city" *ngIf="employee" [disabled]="!canEdit" required/>
            </div>
            <div class="ui-g-4">
                <h6>State</h6>
                <ng-select 
                    class="custom"
                    required
                    [items]="states" 
                    placeholder="Select a State" 
                    (ngModelChange)="onStateSelect($event)" 
                    name="state" 
                    [selectOnTab]="true"
                    bindLabel="name"
                    [(ngModel)]="selectedState" 
                    [disabled]="!canEdit"
                    *ngIf="employee">
                </ng-select>
            </div>
            <div class="ui-g-4">
                <h6>Zip</h6>
                <input pInputText class="inputTextBox" name="zip" [(ngModel)]="employee.address.zip" *ngIf="employee" [disabled]="!canEdit" required/>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12">
                <h6>Signature</h6>
                <textarea
                    pInputTextarea
                    class="form-control" 
                    rows="5"
                    name="signature"
                    style="width: 100%;"
                    [(ngModel)]="employee.employeeSignature"
                    placeholder="Add Your Signature Here"
                    [disabled]="!canEdit && !employeeIsRM"
                    *ngIf="employee"
                ></textarea>
            </div>
        </div>
        <div class="ui-g" *ngIf="employee">
            <div class="ui-g-6">
                <h6>Currently Employed</h6>
                <p-checkbox binary="true" name="currentlyEmployed" [(ngModel)]="employee.currentlyEmployed" [disabled]="!canEdit"></p-checkbox>
            </div>
            <div class="ui-g-6" style="text-align: right;" *ngIf="employeeIsRM">
                <button pButton label="Go To Employee's RM Schedule" (click)="navigateToRMSchedulingPage()"></button>
            </div>
        </div>
        </div>
        <div class="ui-g-12 ui-lg-8 ui-xl-6 columnPadding">
            <div class="ui-g">
                <div class="ui-g-12 ui-md-6 ui-lg-6">
                    <h4>Skills/Certifications</h4>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <button style="float:right" type="button" class="btn btn-success" (click)="addNewSkill();" [disabled]="!canEdit">Add Skill</button>
                </div>
                <div class="ui-g-12">
                    <p-table [columns]="cols" [value]="employee.employeeSkills" [responsive]="true" *ngIf="employee" class="innerMaintenanceTable">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.filterable" [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" 
                            let-employeeSkill
                            let-columns="columns"
                            let-rowIndex="rowIndex"
                        >
                            <tr>
                                <td pEditableColumn><span class="ui-column-title">{{columns[0].header}}</span>
                                    <ng-select 
                                        class="custom"
                                        required
                                        [items]="skills" 
                                        placeholder="Select a Skill" 
                                        [selectOnTab]="true"
                                        (ngModelChange)="onSkillChange(employeeSkill, $event)" 
                                        [name]="'skill'+rowIndex" 
                                        bindLabel="shortName"
                                        [disabled]="!canEdit"
                                        [(ngModel)]="employeeSkill.skill">
                                    </ng-select>
                                </td>
                                <td pEditableColumn><span class="ui-column-title">{{columns[1].header}}</span>
                                    <p-calendar 
                                        [(ngModel)]="employeeSkill.fromDate" 
                                        [name]="'fromDate'+rowIndex"
                                        (ngModelChange)="onSkillFromDateChange(employeeForm, rowIndex, employeeSkill)"
                                        [appendTo]="'body'"
                                        class="dateInTable"
                                        dateFormat="mm/dd/yy" 
                                        placeholder="MM/DD/YYYY" 
                                        [disabled]="!canEdit"
                                        required>
                                    </p-calendar>
                                </td>
                                <td pEditableColumn><span class="ui-column-title">{{columns[2].header}}</span>
                                    <p-calendar 
                                        [(ngModel)]="employeeSkill.toDate" 
                                        [minDate]="employeeSkill.fromDate"
                                        [appDateGreaterThanValidator]="employeeSkill.fromDate"
                                        [name]="'toDate'+rowIndex" 
                                        [appendTo]="'body'" 
                                        class="dateInTable"
                                        dateFormat="mm/dd/yy" 
                                        placeholder="MM/DD/YYYY" 
                                        [disabled]="!canEdit"
                                        required>
                                    </p-calendar>
                                </td>
                                <td style="word-wrap: break-word;"><span class="ui-column-title">{{columns[3].header}}</span>
                                    <app-employee-skill-upload [employeeSkill]="employeeSkill" [viewOnly]="!canEdit"></app-employee-skill-upload>
                                </td>
                                <td><span class="ui-column-title">{{columns[4].header}}</span>
                                    <button
                                        type="button"
                                        class="btn btn-light buttonOutline"
                                        style="margin-left:5px;"
                                        [disabled]="!canEdit"
                                        (click)="removeSkill(rowIndex);">
                                        <i class="pi pi-trash" style="color:red" ></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="ui-g" *ngIf="employee">
        <div class="ui-g-12 columnPadding">
            <div *ngIf="employeeForm.invalid && employeeForm.submitted" class="alert alert-danger" role="alert">
                There are errors on this page. Please correct the errors and resubmit.
            </div>
        </div>
        <div class="ui-g-12 columnPadding">
            <button 
                pButton
                type="submit"
                class="ui-button-raised ui-button-success buttonStyle"
                label="Save"
                [disabled]="saving || (!canEdit && !employeeIsRM)"
                (click)="save(employeeForm)">
            </button>
            <button 
                pButton
                type="button"
                class="ui-button-raised ui-button-warning buttonStyle"
                label="Cancel"
                (click)="cancel()">
            </button>
            <button 
                pButton
                type="button"
                class="ui-button-raised ui-button-danger buttonStyle"
                label="Delete"
                [disabled]="disableDelete"
                (click)="delete()">
            </button>
        </div>
    </div>
</form>
