/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quote-billing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./quote-payment/quote-payment.component.ngfactory";
import * as i3 from "./quote-payment/quote-payment.component";
import * as i4 from "../../services/behavior-subject.service";
import * as i5 from "../../services/script-loader.service";
import * as i6 from "../../services_autogenerated/generated_services";
import * as i7 from "@angular/router";
import * as i8 from "../../services/auth-helper.service";
import * as i9 from "../../services/app-config.service";
import * as i10 from "@angular/common";
import * as i11 from "./quote-billing.component";
var styles_QuoteBillingComponent = [i0.styles];
var RenderType_QuoteBillingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuoteBillingComponent, data: {} });
export { RenderType_QuoteBillingComponent as RenderType_QuoteBillingComponent };
function View_QuoteBillingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-quote-payment", [], null, [[null, "billingSaved"], [null, "convertQuote"], [null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("billingSaved" === en)) {
        var pd_0 = (_co.onBillingSaved() !== false);
        ad = (pd_0 && ad);
    } if (("convertQuote" === en)) {
        var pd_1 = (_co.emitConvertQuote() !== false);
        ad = (pd_1 && ad);
    } if (("closed" === en)) {
        var pd_2 = (_co.emitClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_QuotePaymentComponent_0, i2.RenderType_QuotePaymentComponent)), i1.ɵdid(2, 114688, null, 0, i3.QuotePaymentComponent, [i4.BehaviorSubjectService, i5.ScriptLoaderService, i6.StripeGenSvc, i6.CustomerGenSvc, i6.QuoteRequestGenSvc, i7.ActivatedRoute, i8.AuthHelperService, i9.AppConfigService, i6.SystemSettingGenSvc], { amount: [0, "amount"], customerId: [1, "customerId"], quote: [2, "quote"], maxWidth: [3, "maxWidth"], showManualPaymentChargeMessage: [4, "showManualPaymentChargeMessage"] }, { billingSaved: "billingSaved", closed: "closed", convertQuote: "convertQuote" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentAmount; var currVal_1 = _co.quote.customerId; var currVal_2 = _co.quote; var currVal_3 = _co.maxWidth; var currVal_4 = _co.showManualPaymentChargeMessage; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_QuoteBillingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuoteBillingComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quote; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_QuoteBillingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quote-billing", [], null, null, null, View_QuoteBillingComponent_0, RenderType_QuoteBillingComponent)), i1.ɵdid(1, 114688, null, 0, i11.QuoteBillingComponent, [i7.ActivatedRoute, i6.QuoteRequestGenSvc, i6.StripeGenSvc, i6.CustomerGenSvc, i6.QuoteGenSvc, i8.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuoteBillingComponentNgFactory = i1.ɵccf("app-quote-billing", i11.QuoteBillingComponent, View_QuoteBillingComponent_Host_0, { quoteId: "quoteId", maxWidth: "maxWidth", isQuoteRequest: "isQuoteRequest", paymentAmount: "paymentAmount", eventStart: "eventStart", showManualPaymentChargeMessage: "showManualPaymentChargeMessage" }, { billingSaved: "billingSaved", convertQuote: "convertQuote", closed: "closed" }, []);
export { QuoteBillingComponentNgFactory as QuoteBillingComponentNgFactory };
