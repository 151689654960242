import { Directive, Input, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { BigDayBucketSubrange } from '../services_autogenerated/generated_services';

@Directive({
  selector: '[appDateLessThanAllSubrangeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateLessThanAllSubrangeValidatorDirective, multi: true }]
})
export class DateLessThanAllSubrangeValidatorDirective implements Validator {
  @Input() appDateLessThanAllSubrangeValidator: BigDayBucketSubrange[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    const subranges = this.appDateLessThanAllSubrangeValidator;
    return (subranges && subranges.length > 0) ? dateCompareValidator(control.value, subranges)(control)
      : null;
  }
}

/** A  */
export function dateCompareValidator(thisDate: Date, subranges: BigDayBucketSubrange[]): ValidatorFn {
  let maxDate = subranges[0].fromDate;
  subranges.forEach((subrange) => {
    if (subrange.fromDate < maxDate) {
      maxDate = subrange.fromDate;
    }
  });
  return (control: AbstractControl): { [key: string]: any } | null => {
    return thisDate > maxDate ? { 'Start Date must be before End Date': { value: control.value } } : null;
  };
}
