import { Injectable, OnInit } from '@angular/core';
import {
    Skill,
    Equipment,
    WorkWorkOrder,
    QuoteWorkOrder,
    WellnessWorkOrder,
    WorkOrderWorkTaskGenSvc,
    WorkOrderWellnessTaskGenSvc,
    WorkOrderWellnessTask,
    WorkOrderWorkTask,
    WorkOrderPriceAdjustment,
    WorkOrderPriceAdjustmentGenSvc,
    EquipmentType,
    WellnessTask,
    WorkTask,
    NotificationsGenSvc,
    WellnessWorkOrderCashCollected
} from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService, AddressSetResult } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { NgForm, FormGroup } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { DecodedJwt } from 'src/app/models/decodedJwt';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';

@Injectable()
export abstract class BaseWorkOrderMaintenanceComponent implements OnInit {
    abstract workOrder: WorkWorkOrder | QuoteWorkOrder | WellnessWorkOrder;

    requiredSkills: Skill[] = [];
    requiredEquipment: Equipment[] = [];

    acceptText = 'Accept Quote';
    rejectText = 'Decline';

    displayWellnessTaskDialog: boolean;
    displayWorkTaskDialog: boolean;
    isEditable: boolean;
    canEditAssignedTasks: boolean;
    canAddTaskToCompletedWO: boolean;
    isLoading: boolean;
    canComplete: boolean;
    canCollectCash: boolean;
    canSign: boolean;
    canSave: boolean;
    canUpload: boolean;
    saving: boolean;
    isCustomer: boolean; // Hides some things from the customer
    isAdminOrForeman: boolean;
    token: DecodedJwt;
    errorMessage: string;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected messageService: MessageService,
        protected locationColorService: LocationColorService,
        protected notificationService: NotificationsGenSvc,
        protected helper: WorkOrderHelperService,
        protected workOrderWorkTaskService: WorkOrderWorkTaskGenSvc,
        protected workOrderWellnesTaskService: WorkOrderWellnessTaskGenSvc,
        protected wopaService: WorkOrderPriceAdjustmentGenSvc,
        protected authHelper: AuthHelperService,
        protected workWorkOrderMaintenanceService: WorkWorkOrderMaintenanceService,
        protected wellnessWorkOrderMaintenanceService: WellnessWorkOrderMaintenanceService
    ) { }

    ngOnInit() {
        this.isLoading = true;
        const id = +this.route.snapshot.paramMap.get('id');
        this.setPermissions();
        this.getWO(id);
    }

    setAcceptText() {
        this.acceptText = this.isCustomer ? 'Accept Quote' : 'Pretend Customer Accepted Quote';
    }

    setRejectText() {
        this.rejectText = this.isCustomer ? 'Decline' : 'Pretend Customer Rejected Quote';
    }

    setPermissions() {
        this.token = this.authHelper.getDecodedAccessToken();
        this.isAdminOrForeman = this.token.roles.includes('Admin') || this.token.roles.includes('Foreman') || this.token.roles.includes('Tree Wellness Tech');
        const claims = this.token.claimNames || [];
        this.isCustomer = this.token.userType === 'customer';
        this.setAcceptText();
        this.setRejectText();

        this.canCollectCash = claims.includes('Completing a Task');
        this.canAddTaskToCompletedWO = claims.includes('Add Task to Completed Work Order');

        if (claims.includes('View/Edit Work Order') && claims.includes('View/Complete Work Order')) {
            console.log('::View/Edit Work Order AND ::View/Complete Work Order');
            this.isEditable = true;
            this.canComplete = true;
            this.canUpload = true;
            this.canSign = true;
            this.canSave = true;
        } else if (claims.includes('View/Edit Work Order')) {
            console.log('::View/Edit Work Order');
            this.isEditable = true;
            this.canComplete = false;
            this.canUpload = true;
            this.canSign = true;
            this.canSave = true;
        } else if (claims.includes('View/Complete Work Order')) {
            console.log('::View/Complete Work Order');
            this.isEditable = false;
            this.canComplete = true;
            this.canUpload = true;
            this.canSign = true;
            this.canSave = true;
        } else if (claims.includes('View/Edit Their Own Work Order')) {
            console.log('::View/Edit Their Own Work Order');
            this.isEditable = false;
            this.canComplete = false;
            this.canUpload = false;
            this.canSign = true;
            this.canSave = true;
        } else {
            console.log('::View Work Order');
            this.isEditable = false;
            this.canComplete = false;
            this.canUpload = false;
            this.canSave = false;
            this.canSign = false;
        }

        if (claims.includes('Edit Assigned Tasks')) {
            this.canEditAssignedTasks = true;
        }
    }

    abstract getWO(id: number);

    abstract copyWorkTask(wowt: WorkOrderWorkTask);
    abstract copyWellnessTask(wowt: WorkOrderWorkTask);

    save(ngForm: NgForm): void {
        Object.keys(ngForm.controls).forEach(key => {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();

            // If control is a form group, loop through that group to mark each control
            // This marks the child components' form controls dirty/touched
            if (ngForm.controls[key] instanceof FormGroup) {
                const formGroup: FormGroup = ngForm.controls[key] as FormGroup;
                Object.keys(formGroup.controls).forEach(innerKey => {
                    formGroup.controls[innerKey].markAsDirty();
                    formGroup.controls[innerKey].markAsTouched();
                });
            }
        });

        // So a disabled form cannot be valid (source: https://github.com/angular/angular/issues/18678#issuecomment-322260163)
        // So check to make sure it's not invalid so the form can save when disabled (i.e., when they are completing the WO)
        if (!ngForm.invalid) {
            this.saving = true;

            this.locationColorService.geocodeAddress(this.workOrder.address).subscribe(res => {
                const result = this.locationColorService.setAddressWithGeocodeResponse(this.workOrder.address,
                                                                                    res,
                                                                                    true,
                                                                                    true);

                if (result === AddressSetResult.Successful) {
                    this.saveWO();
                } else if (result === AddressSetResult.Failed) {
                    this.locationColorService.geocodeAddressLeastSpecific(this.workOrder.address).subscribe(res2 => {
                        const secondResult = this.locationColorService.setAddressWithGeocodeResponse(this.workOrder.address,
                                                                                                    res2,
                                                                                                    true);

                        if (secondResult !== AddressSetResult.Cancelled) {
                            this.saveWO();
                        } else {
                            this.saving = false;
                        }
                    });
                } else if (result === AddressSetResult.Cancelled) {
                    this.saving = false;
                }
            }, error => {
                // Save WO even if geocode failed
                this.saveWO();

                if (!this.isCustomer) {
                    // customers don't need to see this message
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Geocode Response Failed',
                        detail: 'Could not get the Geocode Reponse from the MapQuest provider. The color will be set to black.'
                    });
                }
            });
        }
    }

    protected abstract saveWO();

    protected doneSaving = (type: string) => {
        this.saving = false;
        this.cancel(type);
    }

    public cancel(type: string) {
        const token = this.authHelper.getDecodedAccessToken();
        if (this.route.snapshot.queryParamMap.get('fromCustomer') || token.userType === 'customer') {
            this.router.navigateByUrl('/customerLandingPage/' + this.workOrder.customerId);
        } else if (token.roles.includes('Foreman') || token.roles.includes('Arborist') || token.roles.includes('Tree Wellness Tech')) {
            this.router.navigateByUrl('/scheduling/myschedule');
        } else {
            if (type === 'quote') {
                this.router.navigateByUrl('/quoteList/' + type);
            } else {
                this.router.navigateByUrl('/workOrderList/' + type);
            }
        }
    }

    getSkillsFromTasks() {
        let workSkills: Skill[] = [];

        this.helper.getTasks(this.workOrder).forEach(wowt => {
            if (wowt instanceof WorkOrderWorkTask) {
                const workTasks = wowt as WorkOrderWorkTask;
                workSkills = workSkills.concat(workTasks.workTask.workTaskSkills.map(wts => wts.skill));
            }
            if (wowt instanceof WorkOrderWellnessTask) {
                const wellnessTasks = wowt as WorkOrderWellnessTask;
                workSkills = workSkills.concat(wellnessTasks.wellnessTask.wellnessTaskSkills.map(wts => wts.skill));
            }
        });

        return this.helper.getUnique(workSkills);
    }

    getEquipmentFromTasks() {
        this.helper.getUniqueEquipment(this.workOrder);
    }

    protected abstract onTaskChange();
    protected abstract closedDialog(task);

    deleteWellnessTask(wowt: WorkOrderWellnessTask) {
        if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
            if (wowt.id) {
                this.workOrderWellnesTaskService.deleteWellnessTaskByWowtId(wowt.id).subscribe(() => {
                    this.afterWellnessTaskDeleted(wowt);
                });
            } else {
                this.afterWellnessTaskDeleted(wowt);
            }
        }
    }

    afterWellnessTaskDeleted(wowt: WorkOrderWorkTask) {
        this.spliceWellnessTask(wowt);
        this.wellnessWorkOrderMaintenanceService.removeWellnessTaskDependentReferences(this.workOrder as WellnessWorkOrder | QuoteWorkOrder, wowt);
        this.onTaskChange();
    }

    deleteWorkTask(wowt: WorkOrderWorkTask) {
        if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
            if (wowt.id) {
                this.workOrderWorkTaskService.deleteWorkTaskByWowtId(wowt.id).subscribe(() => {
                    this.afterWorkTaskDeleted(wowt);
                });
            } else {
                this.afterWorkTaskDeleted(wowt);
            }
        }
    }

    deleteWorkTaskByTask(task: WorkTask) {
        if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
            if (task.id) {
                const wowt = (this.workOrder as WorkWorkOrder | QuoteWorkOrder).workOrderWorkTasks.find(x => x.workTaskId === task.id);
                this.workOrderWorkTaskService.deleteWorkTaskByWowtId(wowt.id).subscribe(() => {
                    this.afterWorkTaskDeleted(wowt);
                });
            }

            this.closedDialog(null);
        }
    }

    deleteWellnessTaskByTask(task: WellnessTask) {
        if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
            if (task.id) {
                const wowt = (this.workOrder as WellnessWorkOrder | QuoteWorkOrder).workOrderWellnessTasks.find(x => x.wellnessTaskId === task.id);
                this.workOrderWellnesTaskService.deleteWellnessTaskByWowtId(wowt.id).subscribe(() => {
                    this.afterWellnessTaskDeleted(wowt);
                });
            }

            this.closedDialog(null);
        }
    }

    afterWorkTaskDeleted(wowt: WorkOrderWorkTask) {
        this.spliceWorkTask(wowt);
        this.workWorkOrderMaintenanceService.removeWorkTaskDependentReferences(this.workOrder as WorkWorkOrder | QuoteWorkOrder, wowt);
        this.onTaskChange();
    }

    spliceWellnessTask(wowt: WorkOrderWellnessTask) {
        const wo = (this.workOrder as WellnessWorkOrder | QuoteWorkOrder);

        if (wowt.id) {
            wo.workOrderWellnessTasks.splice(wo.workOrderWellnessTasks.findIndex(wt => wt.id === wowt.id), 1);
        } else {
            wo.workOrderWellnessTasks.splice(wo.workOrderWellnessTasks.findIndex(wt => wt.wellnessTaskId === wowt.wellnessTaskId), 1);
        }
    }

    spliceWorkTask(wowt: WorkOrderWorkTask) {
        const wo = (this.workOrder as WorkWorkOrder | QuoteWorkOrder);

        if (wowt.id) {
            wo.workOrderWorkTasks.splice(wo.workOrderWorkTasks.findIndex(wt => wt.id === wowt.id), 1);
        } else {
            wo.workOrderWorkTasks.splice(wo.workOrderWorkTasks.findIndex(wt => wt.workTaskId === wowt.workTaskId), 1);
        }
    }

    deletePriceAdjustment(wopa: WorkOrderPriceAdjustment) {
        if (confirm('Are you really sure you want to delete this adjustment? This operation cannot be undone.')) {
            if (wopa.id) {
                this.wopaService.deletePriceAdjustmentByWopaId(wopa.id).subscribe(() => {
                    this.splicePriceAdjustment(wopa);
                });
            } else {
                this.splicePriceAdjustment(wopa);
            }
        }
    }

    splicePriceAdjustment(wopa: WorkOrderPriceAdjustment) {
        this.workOrder.workOrderPriceAdjustments.splice(this.workOrder.workOrderPriceAdjustments.findIndex(wt => wt.id === wopa.id), 1);
    }

  pushNewCashCollectedEntry() {
    if (!this.workOrder['workOrderCashCollected']) {
      this.workOrder['workOrderCashCollected'] = [];
    }
    if (this.workOrder.constructor === WellnessWorkOrder) {
      const newEntry = new WellnessWorkOrderCashCollected();
      newEntry.invoiced = false;
      newEntry.wellnessWorkOrderId = this.workOrder.id;
      newEntry.amount = null;
      this.workOrder['workOrderCashCollected'].push(newEntry);
    } else if (this.workOrder.constructor === WorkWorkOrder) {
      const newEntry = new WellnessWorkOrderCashCollected();
      newEntry.invoiced = false;
      newEntry.wellnessWorkOrderId = this.workOrder.id;
      newEntry.amount = null;
      this.workOrder['workOrderCashCollected'].push(newEntry);
    }
  }

}
