/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./welcome-page.component";
var styles_WelcomePageComponent = [i0.styles];
var RenderType_WelcomePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomePageComponent, data: {} });
export { RenderType_WelcomePageComponent as RenderType_WelcomePageComponent };
export function View_WelcomePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "russellTreeBackground"], ["class", "centerPicture"], ["src", "assets/russellTreeBackground.png"]], null, null, null, null, null))], null, null); }
export function View_WelcomePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-welcome-page", [], null, null, null, View_WelcomePageComponent_0, RenderType_WelcomePageComponent)), i1.ɵdid(1, 114688, null, 0, i2.WelcomePageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WelcomePageComponentNgFactory = i1.ɵccf("app-welcome-page", i2.WelcomePageComponent, View_WelcomePageComponent_Host_0, {}, {}, []);
export { WelcomePageComponentNgFactory as WelcomePageComponentNgFactory };
