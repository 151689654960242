import { MessageService } from 'primeng/api';
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/common/messageservice";
var MessageWrapperService = /** @class */ (function () {
    function MessageWrapperService(messageService) {
        this.messageService = messageService;
    }
    /**
     * Used to display a standard success message using PrimeNG's messages.
     * @param {string=} message Message to show to the user
     * @param {string=} title Title, defaults to success but can be overridden
     */
    MessageWrapperService.prototype.addSuccessMessage = function (message, title) {
        if (title === void 0) { title = 'Success'; }
        this.messageService.add({
            severity: 'success',
            summary: title,
            detail: message
        });
    };
    /**
     * Used to display an error message using PrimeNG's messages. Will use message if there is no
     * error response from the error param.
     * @param {string=} message Default message
     * @param {any=} error Error from the API, will unwrap and get the error response if available
     * @param {boolean=} sticky If message should remain until closed by user or not,
     * defaults to false so it auto closes
     */
    MessageWrapperService.prototype.addErrorMessage = function (message, error, sticky) {
        if (sticky === void 0) { sticky = false; }
        if (error && error.response && JSON.parse(error.response).Message) {
            message = JSON.parse(error.response).Message;
        }
        if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
            message = JSON.parse(error.response).ExceptionMessage;
        }
        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: message,
            sticky: sticky
        });
    };
    MessageWrapperService.prototype.clear = function (id) {
        this.messageService.clear(id);
    };
    MessageWrapperService.ngInjectableDef = i0.defineInjectable({ factory: function MessageWrapperService_Factory() { return new MessageWrapperService(i0.inject(i1.MessageService)); }, token: MessageWrapperService, providedIn: "root" });
    return MessageWrapperService;
}());
export { MessageWrapperService };
