export enum CalculatedPriorityType {
    Error = 'Error',
    SoftDeadline = 'Approx.',
    DueOn = 'Due On',
    DueAfter = 'Due After',
    DueBefore = 'Due Before',
    DueBetween = 'Due Between',
    DayRange = 'Day Range',
    WeekRange = 'Week Range',

    Standard = 'Standard',
    Merlo = 'Merlo',
    Crane = 'Crane',
    Storm = 'Storm',
    TwoPersonManual = '2-Person Manual'
}
