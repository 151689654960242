import { Component, OnInit } from '@angular/core';
import { Skill } from '../../services_autogenerated/generated_services';
import { SkillGenSvc } from '../../services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.css']
})
export class SkillListComponent implements OnInit {

  cols: any[];
  skills: Skill[] = [];

  constructor(
    private skillService: SkillGenSvc,
    private router: Router,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'name', header: 'Name', width: '' },
      { field: 'shortName', header: 'Short Name', width: '19%' },
      { field: 'length', header: 'Years Valid', width: '13%' },
      { header: '', width: '110px', type: 'actions' }
    ];
  }

  ngOnInit() {
    this.skillService.getAll().subscribe(skills => {
      this.skills = skills;
    });
  }

  addNew() {
    this.router.navigateByUrl('/skillMaintenance');
  }

  editSkill(skill) {
    this.router.navigateByUrl('/skillMaintenance/' + skill.id);
  }

  deleteSkill(skill) {
    if (confirm('Delete this skill?')) {
      this.skillService.delete(skill.id).subscribe(() => {
        this.skills = this.skills.filter(obj => obj !== skill);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the skill, please check that the skill exists and retry.'
        });
      });
    }
  }
}
