import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SurveyQuestion, SurveyQuestionGenSvc } from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/components/common/messageservice';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-survey-maintenance',
  templateUrl: './survey-maintenance.component.html',
  styleUrls: ['./survey-maintenance.component.css']
})
export class SurveyMaintenanceComponent implements OnInit {
  @ViewChild('questionInput') questionInput: ElementRef;
  @ViewChild('indelibleQuestionInput') indelibleQuestionInput: ElementRef;

  constructor(
    private surveyQuestionService: SurveyQuestionGenSvc,
    private messageService: MessageService
  ) { }

  surveyQuestions: SurveyQuestion[];
  hideableSurveyQuestions: SurveyQuestion[];
  deletableSurveyQuestions: SurveyQuestion[];
  currentSurveyQuestion: SurveyQuestion;
  currentNonDeletableQuestion: SurveyQuestion;

  displayTopQuestions = true;

  ngOnInit() {
    this.getSurveyQuestions();
  }

  getSurveyQuestions() {
    this.surveyQuestionService.get().subscribe((sqlist) => {
      this.formatSurveyQuestions(sqlist);
    }, error => alert('Error getting survey questions. Please refresh.'));
  }

  formatSurveyQuestions(surveyQuestions: SurveyQuestion[]) {
    this.surveyQuestions = surveyQuestions;
    if (this.surveyQuestions.length > 2) {
      this.hideableSurveyQuestions = this.surveyQuestions.slice(0, 2);
      this.deletableSurveyQuestions = this.surveyQuestions.slice(2);
    } else {
      this.hideableSurveyQuestions = this.surveyQuestions;
      this.deletableSurveyQuestions = null;
    }
    if (this.surveyQuestions.length > 0) {
      this.displayTopQuestions = this.surveyQuestions[0].visible;
    }
  }

  toggleDisplaySatisfied() {
    const observableBatch = [];
    this.hideableSurveyQuestions.forEach(question => {
      question.visible = this.displayTopQuestions;
      observableBatch.push(this.surveyQuestionService.update(question, question.id.toString()));
    });
    Observable.forkJoin(observableBatch).subscribe(() => { }, error => alert('Error toggling mandatory questions. Please refresh.'));
  }

  addNew() {
    this.currentSurveyQuestion = new SurveyQuestion();
    this.currentSurveyQuestion.active = true;
    this.currentSurveyQuestion.visible = true;
    // Sets priority 1 higher than current max
    this.currentSurveyQuestion.priority = this.surveyQuestions.map(x => x.priority).sort()[this.surveyQuestions.length - 1] + 1;
  }

  save(ngForm: NgForm, surveyQuestion: SurveyQuestion) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (ngForm.valid) {
      if (surveyQuestion.id) {
        this.surveyQuestionService.update(surveyQuestion, surveyQuestion.id.toString()).subscribe(() => {
          this.refresh();
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Please check fields to ensure they are valid.'
          });
        });
      } else {
        this.surveyQuestionService.add(surveyQuestion).subscribe(() => {
          this.refresh();
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Please check fields to ensure they are valid.'
          });
        });
      }
    }
  }

  refresh() {
    this.currentSurveyQuestion = null;
    this.currentNonDeletableQuestion = null;
    this.getSurveyQuestions();
  }

  cancel() {
    this.currentSurveyQuestion = null;
  }

  cancelNonDelete() {
    this.currentNonDeletableQuestion = null;
  }

  deleteCurrent() {
    if (confirm('Delete this question?')) {
      this.surveyQuestionService.delete(this.currentSurveyQuestion.id).subscribe(() => {
        this.refresh();
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the question, please check that the question exists and retry.'
        });
      });
    }
  }

  edit(question: SurveyQuestion) {
    this.currentSurveyQuestion = new SurveyQuestion();
    Object.assign(this.currentSurveyQuestion, question);

    // set timeout because otherwise it tries to focus before object.assign is done
    // and the element isn't in the DOM yet
    setTimeout(() => {
      this.questionInput.nativeElement.focus();
    }, 50);
  }

  editNonDelete(question: SurveyQuestion) {
    this.currentNonDeletableQuestion = new SurveyQuestion();
    Object.assign(this.currentNonDeletableQuestion, question);

    // set timeout because otherwise it tries to focus before object.assign is done
    // and the element isn't in the DOM yet
    setTimeout(() => {
      this.indelibleQuestionInput.nativeElement.focus();
    }, 50);
  }

  deleteFromList(question: SurveyQuestion) {
    if (confirm('Delete this question?')) {
      this.surveyQuestionService.delete(question.id).subscribe(() => {
        if (this.currentSurveyQuestion && this.currentSurveyQuestion.id === question.id) {
          this.refresh();
        } else {
          this.formatSurveyQuestions(this.surveyQuestions.filter(x => x.id !== question.id));
        }
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the question, please check that the question exists and retry.'
        });
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.deletableSurveyQuestions, event.previousIndex, event.currentIndex);
      this.surveyQuestionService.updateOrder(this.hideableSurveyQuestions.concat(this.deletableSurveyQuestions)).subscribe((newSqs) => {
        this.formatSurveyQuestions(newSqs);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Updating Order',
          detail: 'An error occurred while updating. Please refresh.'
        });
      });
    }
  }

}
