import { Component, OnInit } from '@angular/core';
import { Employee } from '../../services_autogenerated/generated_services';
import { EmployeeGenSvc } from '../../services_autogenerated/generated_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  cols: any[];
  employees: Employee[] = [];
  getInactives = false;

  constructor(
    private router: Router,
    private employeeService: EmployeeGenSvc
    ) {
    this.cols = [
      { field: 'lastName', header: 'Last Name', width: '15%' },
      { field: 'firstName', header: 'First Name', width: '15%' },
      { field: 'phoneNumber', header: 'Phone Number', width: '15%' },
      { field: 'address.street', subfield: 'street', header: 'Address', width: '15%' },
      { field: 'address.state.name', subfield: 'state', header: 'State', width: '15%' },
      { field: 'address.zip', subfield: 'zip', header: 'Zip', width: '9%' },
      { field: 'currentlyEmployed', header: 'Employed', width: '9%' },
      { header: '', width: '7%' }
    ];
  }

  ngOnInit() {
    this.getEmployees();
  }

  // I know this isn't the most efficient if they flip flop a lot, but they're probably not ever going to flip flop
  getEmployees() {
    if (this.getInactives) {
      this.employeeService.getAll().subscribe(employees => {
        this.employees = employees;
      });
    } else {
      this.employeeService.getCurrentlyEmployed().subscribe(employees => {
        this.employees = employees;
      });
    }
  }

  addNew() {
    this.router.navigateByUrl('/employeeMaintenance');
  }

  editEmployee(employee) {
    this.router.navigateByUrl('/employeeMaintenance/' + employee.id);
  }

  deleteEmployee(employee) {
    if (confirm('Are you sure you want to permanently delete this user? This cannot be undone.')) {
      this.employeeService.delete(employee.id).subscribe(() => {
          this.employees = this.employees.filter(obj => obj !== employee);
      });
    }
  }
}
