import { Injectable } from '@angular/core';
import { WellnessWorkOrder,
  WorkOrderPriceAdjustment,
  WorkWorkOrder,
  QuoteWorkOrder,
  WellnessTask,
  WorkTask,
  WorkTaskCompletionStatus,
  QuoteRequestType,
  QuoteWorkOrderCompletionStatus,
  EquipmentType,
  WellnessTaskPaymentStatus,
  WellnessTaskCompletionStatus,
} from '../services_autogenerated/generated_services';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderHelperService {
  // tslint:disable: max-line-length

  constructor() { }

  // TODO the union type is used here because, atm, the service generator doesn't generate the IWorkOrder class from the C#
  getTasks(workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder, tasks?: any[]): (WellnessTask | WorkTask)[] {
    if (workOrder instanceof WellnessWorkOrder) {
      // return wellness tasks
      return workOrder.workOrderWellnessTasks.map(wowt => tasks && tasks.length > 0 ? (tasks.find(x => x.id === wowt.wellnessTask.id) ?  wowt.wellnessTask : null) : wowt.wellnessTask).filter(x => x != null);
    } else if (workOrder instanceof WorkWorkOrder) {
      return workOrder.workOrderWorkTasks.map(wowt => tasks && tasks.length > 0 ? (tasks.find(x => x.id === wowt.workTask.id) ?  wowt.workTask : null) : wowt.workTask).filter(x => x != null);
    } else if (workOrder instanceof QuoteWorkOrder) {
      // return both wellness and work tasks
      return workOrder.workOrderWellnessTasks.map(wowt => tasks && tasks.length > 0 ? (tasks.find(x => x.id === wowt.wellnessTask.id) ?  wowt.wellnessTask : null) : wowt.wellnessTask)
        .concat(workOrder.workOrderWorkTasks.map(wowt => tasks && tasks.length > 0 ? (tasks.find(x => x.id === wowt.workTask.id) ?  wowt.workTask : null) : wowt.workTask) as any).filter(x => x != null); // dumb generated service
    }

    return [];
  }

  // TODO the union type is used here because, atm, the service generator doesn't generate the IWorkOrder class from the C#
  // getWorkOrderTasks(workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder): (WorkOrderWellnessTask | WorkOrderWorkTask)[] {
  //   if (workOrder instanceof WellnessWorkOrder) {
  //     // return wellness tasks
  //     return workOrder.workOrderWellnessTasks.map(wowt => wowt);
  //   } else if (workOrder instanceof WorkWorkOrder) {
  //     // return work tasks
  //     return workOrder.workOrderWorkTasks.map(wowt => wowt);
  //   } else if (workOrder instanceof QuoteWorkOrder) {
  //     // return both wellness and work tasks
  //     return workOrder.workOrderWellnessTasks.map(wowt => wowt)
  //       .concat(workOrder.workOrderWorkTasks.map(wowt => wowt));
  //   }

  //   return [];
  // }

  subTotal(workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder, tasks?: any[], includePaidTasks: boolean = true): number {
    // if it is a quote, then we only want to calculate the total for items that were agreed upon by the customer (signed)
    if (workOrder instanceof QuoteWorkOrder) {
      return this.getTasks(workOrder, tasks)
        .filter(task => ((task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Work_Orders_Created || (task.completionStatus as any) ===  QuoteWorkOrderCompletionStatus.Credit_Card_Needed))
        .reduce((acc, curr) => acc + (curr.price ? curr.price : 0), 0);
    }

    if (includePaidTasks) {
      return this.getTasks(workOrder, tasks).reduce((acc, curr) => acc + (curr.price ? curr.price : 0), 0);
    } else {
      return this.getTasks(workOrder, tasks)
        .filter(task => ((task.paymentStatus as any) !== WellnessTaskPaymentStatus.Paid
                          && (task.completionStatus as any) !== WellnessTaskCompletionStatus.Unable_to_be_Completed))
        .reduce((acc, curr) => acc + (curr.price ? curr.price : 0), 0);
    }
  }

  totalPriceAfterAdjustment(workOrder: WellnessWorkOrder, tasks?: any[], includePaidTasks: boolean = true): number {
    const basePrice = this.subTotal(workOrder, tasks, includePaidTasks);

    if (workOrder.workOrderPriceAdjustments.length > 0) {
      return workOrder.workOrderPriceAdjustments.reduce((acc, curr) => acc + this.adjustmentAmount(curr, workOrder, tasks), basePrice);
    } else {
      return basePrice;
    }
  }

  adjustmentAmount(wopa: WorkOrderPriceAdjustment, workOrder: WellnessWorkOrder | WorkWorkOrder, tasks?: any[]): number {
    if (wopa.priceAdjustment.active) {
      if (workOrder instanceof QuoteWorkOrder) {
        return this.getTasks(workOrder, tasks)
          .filter(task => task.active && ((task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Work_Orders_Created || (task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Credit_Card_Needed))
          .reduce((acc, curr) => acc + Math.floor((curr.price ? curr.price : 0) * (wopa.priceAdjustment.percentage || 0) / 100), 0);
      }

      return this.getTasks(workOrder, tasks)
        .filter(task => (task.active))
        .reduce((acc, curr) => acc + Math.floor((curr.price ? curr.price : 0) * (wopa.priceAdjustment.percentage || 0) / 100), 0);
    } else {
      return 0;
    }
  }

  adjustmentAmountCompleted(wopa: WorkOrderPriceAdjustment, workOrder: WellnessWorkOrder | WorkWorkOrder): number {
    if (wopa.priceAdjustment.active) {
        return this.getTasks(workOrder)
        .filter(task => (task.active) && (task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Completed)
        .reduce((acc, curr) => acc + Math.floor((curr.price ? curr.price : 0) * (wopa.priceAdjustment.percentage || 0) / 100), 0);
    } else {
      return 0;
    }
  }

  // TODO: Make these functions get called from back end so there is only one source for Stripe/UI ?
  // Make sure this matches the back end equivalent if changed
  subTotalCompleted(workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder): number {
    // if it is a quote, then we only want to calculate the total for items that were already completed
    if (workOrder instanceof QuoteWorkOrder) {
      return this.getTasks(workOrder)
        .filter(task => (task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Completed)
        .reduce((acc, curr) => acc + (curr.price ? curr.price : 0), 0);
    }
    return this.getTasks(workOrder)
    .filter(task => task.completionStatus === WorkTaskCompletionStatus.Completed)
    .reduce((acc, curr) => acc + (curr.price ? curr.price : 0), 0);
  }

  salesTaxAmount(workOrder: WellnessWorkOrder, tasks?: any[], includePaidTasks: boolean = true): number {
    let allSalesTax = 0;
    if (workOrder.salesTaxPercentage && workOrder.salesTaxPercentage > 0 && !workOrder.customer.taxExempt) {
      let taskSalesTax = 0;
      if (workOrder instanceof QuoteWorkOrder) {
        taskSalesTax = this.getTasks(workOrder, tasks)
          .filter(task => ((task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Work_Orders_Created || (task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Credit_Card_Needed))
          .reduce((acc, curr) => acc + this.currencyRound(((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100))), 0);
      } else {
        // This is the only difference between 'totalPriceAfterAdjustment()' and 'subTotal()' code in here but needs to happen because Stripe tax is rounded per line item
        if (includePaidTasks) {
          taskSalesTax =  this.getTasks(workOrder, tasks).reduce((acc, curr) => acc + this.currencyRound((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100)), 0);
        } else {
          taskSalesTax =  this.getTasks(workOrder, tasks)
                              .filter(task =>
                                    (task.completionStatus as any) !== WellnessTaskCompletionStatus.Unable_to_be_Completed
                                    && (task.paymentStatus as any) !== WellnessTaskPaymentStatus.Paid)
                              .reduce((acc, curr) => acc + this.currencyRound((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100)), 0);
        }
      }

      // Add adjustment sales tax
      if (workOrder.workOrderPriceAdjustments.length > 0) {

        allSalesTax = workOrder.workOrderPriceAdjustments.reduce((acc, curr) => acc + this.currencyRound(this.adjustmentAmount(curr, workOrder, tasks) * (workOrder.salesTaxPercentage / 100)), taskSalesTax);
      } else {
        allSalesTax = taskSalesTax;
      }

      // Apply firmtime sales tax discount
      if (this.qualifiesForFirmtimeRefund(workOrder as any, tasks)) {
        allSalesTax = allSalesTax - this.currencyRound(100 * (workOrder.salesTaxPercentage / 100));
      }
      return allSalesTax;

    } else {
      // // No sales tax
      return allSalesTax;
    }
  }

  salesTaxAmountCompleted(workOrder: WellnessWorkOrder): number {
    let allSalesTax = 0;
    if (workOrder.salesTaxPercentage && workOrder.salesTaxPercentage > 0 && !workOrder.customer.taxExempt) {
      let taskSalesTax = 0;

      taskSalesTax = this.getTasks(workOrder)
        .filter(task => (task.completionStatus as any) === QuoteWorkOrderCompletionStatus.Completed)
        .reduce((acc, curr) => acc + this.currencyRound(((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100))), 0);

      // Add adjustment sales tax
      if (workOrder.workOrderPriceAdjustments.length > 0) {

        allSalesTax = workOrder.workOrderPriceAdjustments.reduce((acc, curr) => acc + this.currencyRound(this.adjustmentAmountCompleted(curr, workOrder) * (workOrder.salesTaxPercentage / 100)), taskSalesTax);
      } else {
        allSalesTax = taskSalesTax;
      }

      // Apply firmtime sales tax discount
      if (this.qualifiesForFirmtimeRefund(workOrder as any)) {
        allSalesTax = allSalesTax - this.currencyRound(100 * (workOrder.salesTaxPercentage / 100));
      }
      return allSalesTax;

    } else {
      // No sales tax
      return allSalesTax;
    }
  }

    // TODO: Make these functions get called from back end so there is only one source for Stripe/UI ?
  // Make sure this matches the back end equivalent if changed
  totalAmount(workOrder: any, includePaidTasks: boolean = true): number {
    let total = this.totalPriceAfterAdjustment(workOrder, undefined, includePaidTasks);
    if (this.qualifiesForFirmtimeRefund(workOrder)) {
        total = total - 100;
      }
    return this.currencyRound(total + this.salesTaxAmount(workOrder, null, includePaidTasks));
  }

  totalAmountByTasks(workOrder: any, tasks?: any[]): number {
    let total = this.totalPriceAfterAdjustment(workOrder, tasks);
    if (this.qualifiesForFirmtimeRefund(workOrder, tasks)) {
        total = total - 100;
      }
    return this.currencyRound(total + this.salesTaxAmount(workOrder, tasks));
  }

  qualifiesForFirmtimeRefund(workOrder: QuoteWorkOrder | WellnessWorkOrder | WorkWorkOrder, tasks?: any[]): boolean {
    if (workOrder instanceof QuoteWorkOrder && workOrder.quoteRequest && workOrder.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
      if (this.totalPriceAfterAdjustment(workOrder as any, tasks) > 500 && !workOrder) {
        return true;
      }
    } else if (workOrder instanceof WellnessWorkOrder && workOrder.quoteWorkOrder.quoteRequest && workOrder.quoteWorkOrder.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !workOrder.ignoreFirmtime) {
      if (this.totalPriceAfterAdjustment(workOrder as any, tasks) > 500) {
        return true;
      }
    } else if (workOrder instanceof WorkWorkOrder && workOrder.quoteWorkOrder.quoteRequest && workOrder.quoteWorkOrder.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !workOrder.ignoreFirmtime) {
      if (this.totalPriceAfterAdjustment(workOrder as any, tasks) > 500) {
        return true;
      }
    }
    return false;
  }

  // evenRound(num, decimalPlaces) {
  //   const d = decimalPlaces || 0;
  //   const m = Math.pow(10, d);
  //   const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  //   const i = Math.floor(n), f = n - i;
  //   const e = 1e-8; // Allow for rounding errors in f
  //   const r = (f > 0.5 - e && f < 0.5 + e) ?
  //               ((i % 2 === 0) ? i : i + 1) : Math.round(n);
  //   return d ? r / m : r;
  // }

  // Matches Stripe logic
  currencyRound(num) {
    // Javascript does weird things and this handles them (Ex: rounding 1.005)
    return Math.round((num + 0.00001) * 100) / 100;
  }

  getUnique(arr: any[]) {
    const unique = arr
      .map(e => e['id'])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  getUniqueEquipment(workOrder: QuoteWorkOrder | WellnessWorkOrder | WorkWorkOrder): EquipmentType[] {
    let equipment: EquipmentType[] = [];

    this.getTasks(workOrder).forEach(wowt => {
        if (wowt instanceof WorkTask) {
            const workTasks = wowt as WorkTask;
            equipment = equipment.concat(workTasks.workTaskEquipment.map(wte => wte.equipmentType));
        }
        if (wowt instanceof WellnessTask) {
            const wellnessTasks = wowt as WellnessTask;
            equipment = equipment.concat(wellnessTasks.wellnessTaskEquipment.map(wte => wte.equipmentType));
        }
    });

    return this.getUnique(equipment);
  }

  // This tells you whether it is qualified to be scheduled. It could be scheduled, or it could not be.
  isTaskQualifiedToBeScheduled(task: WellnessTask | WorkTask) {
    return this.isTaskCapableOfProgress(task) &&
      !(task.schedulableAfterCompletionOf && this.isTaskCapableOfProgress(task.schedulableAfterCompletionOf));
  }

  isTaskCapableOfProgress(task: WellnessTask | WorkTask) {
    return task.active && !(task.completionStatus === WorkTaskCompletionStatus.Completed || task.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed || task.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up);
  }

  // Maybe include tax here?
  totalAmountCompleted(workOrder: any): number {
    let total = this.totalCompletedPriceAfterAdjustment(workOrder);
    if (this.qualifiesForFirmtimeRefund(workOrder)) {
      total = total - 100;
    } else {
      return total;
    }
  }

  // TODO: Make these functions get called from back end so there is only one source for Stripe/UI ?
  // Make sure this matches the back end equivalent if changed
  totalCompletedPriceAfterAdjustment(workOrder: WellnessWorkOrder | WorkWorkOrder): number {
    const basePrice = this.subTotalCompleted(workOrder);
    if (workOrder.workOrderPriceAdjustments.length > 0) {
      return workOrder.workOrderPriceAdjustments.reduce((acc, curr) => acc + this.adjustmentAmountCompleted(curr, workOrder), basePrice) + this.salesTaxAmountCompleted(workOrder as any);
    } else {
      return basePrice + this.salesTaxAmountCompleted(workOrder as any);
    }
  }

  isTaskScheduledButNotComplete(task: WellnessTask | WorkTask) {
    return (!task.isGoBack && (task.currentBucketId || task.currentBigDaySubrangeId)
          || (task.isGoBack && task.goBackBucketId))
          && this.isTaskCapableOfProgress(task);
  }

  isTaskScheduledForGoBackButNotComplete(task: WellnessTask | WorkTask) {
    return this.isTaskCapableOfProgress(task) && task.goBackBucketId;
  }

  isTaskMultiDayScheduledButNotComplete(task: WellnessTask | WorkTask) {
    return task.scheduleDateFrom &&
    task.currentBigDaySubrangeId &&
    this.isTaskCapableOfProgress(task);
  }


  public openMap(workOrder: QuoteWorkOrder | WellnessWorkOrder | WorkWorkOrder) {
    /* if we're on iOS, open in Apple Maps */
    if ((navigator.platform.indexOf('iPhone') !== -1)
      || (navigator.platform.indexOf('iPad') !== -1)
      || (navigator.platform.indexOf('iPod') !== -1)) {
      window.open(`maps://maps.apple.com/maps?q=${workOrder.address.street}, ${workOrder.address.zip}`, '_blank');
    } else { /* else use Google  */
      window.open(`https://maps.google.com/maps?q=${workOrder.address.street}, ${workOrder.address.zip}`, '_blank');
    }
  }
}
