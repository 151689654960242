import { Component, OnInit } from '@angular/core';
import { WorkTaskTemplate, WorkTaskTemplateGenSvc } from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { MaskService } from 'src/app/services/mask.service';

@Component({
  selector: 'app-work-task-template-maintenance',
  templateUrl: './work-task-template-maintenance.component.html',
  styleUrls: ['./work-task-template-maintenance.component.css']
})
export class WorkTaskTemplateMaintenanceComponent implements OnInit {
  task: WorkTaskTemplate;
  taxCodes: any[];
  disableDelete = false;
  saving = false;
  currencyMask: any;

  constructor(private workTaskTemplateService: WorkTaskTemplateGenSvc,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private maskService: MaskService
  ) {
    this.taxCodes = [
      { label: '', value: null },
      { label: 'Taxable', value: 'tax' },
      { label: 'Tax Exempt', value: 'untaxed' }
    ];
  }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.workTaskTemplateService.get(id).subscribe(task => {
        this.task = task;
      });
    } else {
      this.disableDelete = true;
      this.task = new WorkTaskTemplate();
      this.task.active = true;
    }

    this.currencyMask = this.maskService.currencyMaskNoDecimal;
  }

  save(ngForm: NgForm): void {
    if ('activeElement' in document) {
      (document.activeElement as HTMLElement).blur();
    }
    // There was an error where cost would be zeroed if the user was editing it when the hit enter
    // This is a janky solution
    setTimeout(() => this.actualSave(ngForm), 0);
  }

  actualSave(ngForm: NgForm): void {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    if (ngForm.valid) {
      this.saving = true;
      if (this.task.id) {
        this.workTaskTemplateService.update(this.task, this.task.id.toString())
          .subscribe(() => {
            this.doneSaving();
          }, error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: 'Could not save the task template, please check that all fields are valid and retry.'
            });
            this.doneSaving();
          });
      } else {
        this.workTaskTemplateService.add(this.task)
          .subscribe(() => {
            this.doneSaving();
          }, error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: 'Could not save the task template, please check that all fields are valid and retry.'
            });
            this.doneSaving();
          });
      }
    }
  }

  private doneSaving() {
    this.saving = false;
    this.router.navigateByUrl('/workTaskTemplateList');
  }

  cancel() {
    this.router.navigateByUrl('/workTaskTemplateList');
  }

  delete() {
    if (confirm('Would you like to delete this work task template?')) {
      this.workTaskTemplateService.delete(this.task.id).subscribe(() => {
        this.router.navigateByUrl('/workTaskTemplateList');
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the task, please check that the task exists and retry.'
        });
      });
    }
  }
}
