import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseWellnessWorkOrderCardComponent } from '../base-wellness-work-order-card/base-wellness-work-order-card.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { ScheduledBucketDTO } from 'src/app/services_autogenerated/generated_services';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
var ScheduledBucketWellnessWorkOrderCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduledBucketWellnessWorkOrderCardComponent, _super);
    function ScheduledBucketWellnessWorkOrderCardComponent(dragAndDropService, router, priorityService, workOrderHelperService, customCustomerService, authHelper) {
        var _this = _super.call(this, dragAndDropService, router, priorityService, workOrderHelperService, authHelper) || this;
        _this.customCustomerService = customCustomerService;
        return _this;
    }
    ScheduledBucketWellnessWorkOrderCardComponent.prototype.getEquipmentTypeDisplay = function () {
        var _this = this;
        var equipmentTypes = [];
        this.scheduledTasks.forEach(function (wowt) {
            if (wowt.wellnessTask.isGoBack && _this.bucket.id === wowt.wellnessTask.goBackBucketId) {
                equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskGoBackEquipment.map(function (wte) { return wte.equipmentType; }));
            }
            else if (_this.bucketId === wowt.wellnessTask.currentBucketId) {
                equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskEquipment.map(function (wte) { return wte.equipmentType; }));
            }
        });
        return this.workOrderHelperService.getUnique(equipmentTypes).map(function (et) { return et.abbreviation ? et.abbreviation : et.type; }).join(' / ');
    };
    ScheduledBucketWellnessWorkOrderCardComponent.prototype.setBottomSection = function () {
        this.hoursSummary = this.scheduledTasks.reduce(function (acc, curr) { return acc + curr.wellnessTask.hours; }, 0);
        this.hoursSummary += this.scheduledTasks.reduce(function (acc, curr) { return acc + curr.wellnessTask.goBackHoursEstimate; }, 0);
        this.priceSummary = this.scheduledTasks.reduce(function (acc, curr) { return acc + curr.wellnessTask.price; }, 0);
        this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
    };
    ScheduledBucketWellnessWorkOrderCardComponent.prototype.setTasks = function () {
        var _this = this;
        // Total amount of task scheduled, even ones not scheduled on given bucket day
        this.scheduledTasksTotal = this.workOrder.workOrderWellnessTasks.filter(function (task) {
            return !((!task.wellnessTask.isGoBack && !task.wellnessTask.currentBucketId) || (task.wellnessTask.isGoBack && !task.wellnessTask.goBackBucketId));
        }).length;
        // Tasks that are scheduled on the given bucket day
        this.scheduledTasks = this.workOrder.workOrderWellnessTasks
            .filter(function (task) { return task.wellnessTask.currentBucketId === _this.bucket.id || task.wellnessTask.goBackBucketId === _this.bucket.id; });
    };
    return ScheduledBucketWellnessWorkOrderCardComponent;
}(BaseWellnessWorkOrderCardComponent));
export { ScheduledBucketWellnessWorkOrderCardComponent };
