import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ReportGenSvc } from '../services_autogenerated/generated_services';
import { ngxCsv } from 'ngx-csv';
import { MessageWrapperService } from './message-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ReportHelperService {

  constructor(private reportService: ReportGenSvc,
              private datePipe: DatePipe,
              private currencyPipe: CurrencyPipe,
              private messageService: MessageWrapperService) { }
    options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      headers: ['Type', 'Number', 'Customer Name', 'Date Signed', 'Date Completed', 'Regional Manager', 'Total Price', 'Task Names', 'Task Statuses',
                'Task Priorities', 'Task Hours Estimate Total','Address', 'Tax Exempt', 'Is Multiday', 'Has Outside Expenses', 'Expenses Description',
                'Expenses Cost', 'Quote Created','Sales Tax Percentage', 'Customer Note', 'Crew Note', 'Auto Renewal Accepted', 'Paid (Overall)', 
                'Paid (Tasks)']
    };
}
