import { Component, OnInit, Input, Output, ViewChild, Inject } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Customer } from 'src/app/services_autogenerated/generated_services';
import { PaymentType } from 'src/app/models/paymentType';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { TokenResult } from 'ngx-stripe';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { StripeGenSvc, CustomerGenSvc } from 'src/app/services_autogenerated/generated_services';
import { StripeBillingComponent } from 'src/app/components/stripe-billing/stripe-billing.component';
import { Router } from '@angular/router';
import { StripeBilling, PlaidLinkSuccessDTO } from '../../../services_autogenerated/generated_services';
import { BasePaymentProcessingComponent } from '../../base-payment-processing/base-payment-processing.component';
import { SwaggerExceptionResponse } from '../../../models/SwaggerExceptionResponse';

@Component({
  selector: 'app-billing-profile-entry',
  templateUrl: './billing-profile-entry.component.html',
  styleUrls: ['./billing-profile-entry.component.css']
})
export class BillingProfileEntryComponent extends BasePaymentProcessingComponent implements OnInit {

  @ViewChild(StripeBillingComponent) stripeBillingComponent: StripeBillingComponent;

  @Input() customer: Customer;
  @Input() stripeBilling: StripeBilling = null;
  @Output() emitClose = new EventEmitter();
  @Output() emitUpdatedStripeCustomerId = new EventEmitter<string>();

  error: string;
  saving = false;

  constructor(
    behaviorSubjectService: BehaviorSubjectService,
    scriptLoaderService: ScriptLoaderService,
    customStripeService: StripeGenSvc
  ) {
    super(
      behaviorSubjectService,
      scriptLoaderService,
      customStripeService,
      );
   }

  ngOnInit() {
    this.setupPlaid();
  }

  save() {
    if (!this.error) {
      if (!this.stripeBillingComponent.checkFormError()) {
        // if (!this.customer.paymentInfoRequired) {

        // }
        if (this.stripeBillingComponent.paymentType === PaymentType.Credit) {
          this.submitCard();
        } else if (this.stripeBillingComponent.paymentType === PaymentType.ACH) {
          this.submitACH();
        }
      }
    }
  }

  afterBillingSave(stripeCustomerId: string) {
    // console.log('profile entry: ' + stripeCustomerId)
    this.saving = false;
    // if (this.customer.stripeCustomerId) {
      this.emitUpdatedStripeCustomerId.emit(stripeCustomerId);
    // }
    this.emitClose.emit();
  }

  cancel() {
    this.emitClose.emit();
  }

  onError(event) {
    if (event) {
      if (event.response) {
        const eventResponse = (JSON.parse(event.response) as SwaggerExceptionResponse);
        this.error = eventResponse.ExceptionMessage ? eventResponse.ExceptionMessage : eventResponse.Message;
      } else {
        this.error = event.message;
      }
    } else {
      this.error = null;
    }
  }

  processBillingRequest(token: string) {
    this.customStripeService.addOrUpdate(this.customer.id, token, this.stripeBillingComponent.stripeBilling, this.customer.manualPayment)
    .subscribe((stripeCustomerId) => {
      this.afterBillingSave(stripeCustomerId);
    }), (error) => {
      this.onError(error);
    };
  }
}
