/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./billing-profile-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../stripe-billing/stripe-billing.component.ngfactory";
import * as i3 from "../../stripe-billing/stripe-billing.component";
import * as i4 from "../../../services_autogenerated/generated_services";
import * as i5 from "../../../services/behavior-subject.service";
import * as i6 from "ngx-stripe";
import * as i7 from "../../../services/mask.service";
import * as i8 from "../../../services/customer.service";
import * as i9 from "../../../services/auth-helper.service";
import * as i10 from "@angular/common";
import * as i11 from "./billing-profile-entry.component";
import * as i12 from "../../../services/script-loader.service";
var styles_BillingProfileEntryComponent = [i0.styles];
var RenderType_BillingProfileEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BillingProfileEntryComponent, data: {} });
export { RenderType_BillingProfileEntryComponent as RenderType_BillingProfileEntryComponent };
function View_BillingProfileEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["id", "errors"], ["role", "alert"], ["style", "text-align:left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 2, 0, currVal_0); }); }
export function View_BillingProfileEntryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { stripeBillingComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-stripe-billing", [], null, [[null, "errorEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("errorEmitter" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StripeBillingComponent_0, i2.RenderType_StripeBillingComponent)), i1.ɵdid(2, 4440064, [[1, 4]], 0, i3.StripeBillingComponent, [i1.ChangeDetectorRef, i1.ChangeDetectorRef, i4.StripeGenSvc, i4.CustomerGenSvc, i4.StateGenSvc, i5.BehaviorSubjectService, i6.StripeService, i7.MaskService, i8.CustomerService, i9.AuthHelperService], { stripeBillingIn: [0, "stripeBillingIn"], customer: [1, "customer"], isCustomerProfile: [2, "isCustomerProfile"] }, { errorEmitter: "errorEmitter" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ui-g ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingProfileEntryComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stripeBilling; var currVal_1 = _co.customer; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.error; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_BillingProfileEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-billing-profile-entry", [], null, null, null, View_BillingProfileEntryComponent_0, RenderType_BillingProfileEntryComponent)), i1.ɵdid(1, 114688, null, 0, i11.BillingProfileEntryComponent, [i5.BehaviorSubjectService, i12.ScriptLoaderService, i4.StripeGenSvc], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BillingProfileEntryComponentNgFactory = i1.ɵccf("app-billing-profile-entry", i11.BillingProfileEntryComponent, View_BillingProfileEntryComponent_Host_0, { stripeBillingComponent: "stripeBillingComponent", saving: "saving", customer: "customer", stripeBilling: "stripeBilling" }, { emitClose: "emitClose", emitUpdatedStripeCustomerId: "emitUpdatedStripeCustomerId" }, []);
export { BillingProfileEntryComponentNgFactory as BillingProfileEntryComponentNgFactory };
