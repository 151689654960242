import * as i0 from "@angular/core";
var CardTitleHelperService = /** @class */ (function () {
    function CardTitleHelperService() {
    }
    // Given a list of equipment and employees, create a title that is either a CSV of the 
    // equipment numbers, a CSV of employees, or the string New Crew
    CardTitleHelperService.prototype.generateTitle = function (equipment, employees) {
        var title = '';
        var employeeList = this.generateEmployeeTitle(employees);
        if (equipment && equipment.length > 0) {
            return equipment.map(function (e) { return ' ' + e.number; }).toString();
        }
        else if (employees && employees.length > 0) {
            if (employeeList !== '') {
                // No equipment, just list employees
                title = employeeList;
            }
        }
        else {
            title = 'New Crew';
        }
        return title;
    };
    // Given a list of employees, create a CSV of their last names with the first foreman found being
    // the first employee in the list.
    CardTitleHelperService.prototype.generateEmployeeTitle = function (employees) {
        var employeeTitle = '';
        if (employees && employees.length > 0) {
            // Find first foreman
            var foreman = employees.find(function (e) { return e.employeeRoles.some(function (er) { return er.role.name.toLowerCase().includes('foreman'); }); });
            if (foreman) {
                // First foreman is first entry
                employeeTitle = foreman.lastName;
                // Go through array and add rest of employees, skipping the first foreman
                for (var i = 0; i < employees.length; i++) {
                    if (employees[i] !== foreman) {
                        employeeTitle += ', ' + employees[i].lastName;
                    }
                }
            }
            else {
                return employees.map(function (e) { return ' ' + e.lastName; }).toString();
            }
        }
        else {
            employeeTitle = '';
        }
        return employeeTitle;
    };
    CardTitleHelperService.ngInjectableDef = i0.defineInjectable({ factory: function CardTitleHelperService_Factory() { return new CardTitleHelperService(); }, token: CardTitleHelperService, providedIn: "root" });
    return CardTitleHelperService;
}());
export { CardTitleHelperService };
