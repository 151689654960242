<div *ngIf="showMenu">
  <img *ngIf="!logoLink" src="../assets/russellTreeBackground.png" alt="Arbor Answers Logo" id="logo" style="padding: .4em;">
  <a *ngIf="logoLink" [href]="logoLink"><img src="../assets/russellTreeBackground.png"  alt="Arbor Answers Logo" id="logo" style="padding: .4em;"></a>
  <div *ngIf="isLoggedIn" class="menubarLg">
    <p-menubar styleClass="my-menu" [model]="items" [autoDisplay]="false">
      <span *ngIf="token.userType !== 'customer'">
        Hello, <a [routerLink]="'employeeMaintenance/' + token.employeeId">{{token.firstName}}</a>!
      </span>
      <span *ngIf="token.userType === 'customer'">Hello, {{token.firstName}}!</span>
      <span *ngIf="token && token.userType == 'customer'">
        <button pButton class="ui-button-secondary ui-button-rounded pad-left" style="padding-right: 40px;"
          icon="pi pi-home" (click)="customerPage()"></button>
        <button pButton class="ui-button-secondary ui-button-rounded pad-left" label="Request a Quote"
          (click)="requestAQuote()"></button>
      </span>
      <button pButton class="ui-button-secondary ui-button-rounded pad-left" label="Logout"
        (click)="onLogout()"></button>
    </p-menubar>
  </div>
  <div *ngIf="isLoggedIn" class="slide-menu-option">
    <button (click)="showSlideMenu=true" class="ui-button-secondary slide-menu-button" pButton
      icon=" fa fa-bars"></button>
    <div class="slide-menu">
      <p-sidebar class="" [(visible)]="showSlideMenu" position="right" *ngIf="token && token.userType !== 'customer'">
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">
          <div style="margin-bottom: .5em">
            Hello, {{token.firstName}}!<button pButton class="ui-button-secondary ui-button-rounded pad-left"
              label="Logout" (click)="onLogout()"></button>
          </div>
          <p-panelMenu class="panel-menu" [model]="items" [style]="{'width':'100%'}" [multiple]="false"></p-panelMenu>
        </p-scrollPanel>
      </p-sidebar>
      <p-sidebar [(visible)]="showSlideMenu" [showCloseIcon]="false" position="right"
        *ngIf="token && token.userType === 'customer'">
        <div class="burger-title">
          <div><i class="pi pi-user"></i> Hello, {{token.firstName}}</div>
          <div (click)="showSlideMenu=false"><i class="pi pi-times"></i></div>
        </div>
        <div class="mobile-burger-container">
          <div (click)="customerPage()">Home</div>
          <div (click)="requestAQuote()">Request a Quote</div>
          <div (click)="onLogout()">Logout</div>
        </div>
      </p-sidebar>
    </div>
  </div>
</div>
<div *ngIf="environment" class="env-banner">
  <p-message severity="warn" text="This is a non-production environment: {{environment}}"></p-message>  
</div>
<div>
  <br />
  <br />
  <br />
</div>

<div class="main-content">
  <p-toast position="top-center"></p-toast>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  <router-outlet></router-outlet>
</div>
