import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthHelperService } from './services/auth-helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PasswordResetGuard implements CanActivate {
  constructor(
    private authService: AuthHelperService,
    private router: Router
  ) { }
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // If there is a token and that token is marked as needing a password reset, force them to go to the password change screen
    // If there isn't a token then or there's a token and it doesn't need a password reset, then allow them (this lets non auth-guarded pages load appropriately)
    if (this.authService.getDecodedAccessToken() && this.authService.requiresPasswordReset(this.authService.getDecodedAccessToken())) {
      this.router.navigate(['passwordChange']);
      return false;
    }

    return true;
  }
}
