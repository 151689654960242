import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Address, AddressGenSvc } from '../services_autogenerated/generated_services';
import { environment } from '../../environments/environment';
import * as haversine from 'haversine';
import { MessageService } from 'primeng/api';
import { SharedColorService } from './shared-color.service';
import { GeocodingResponse, ColorResponse, Locations } from '../models/colorModels';
import { OptimizedRouteResult } from '../models/OptimizedRouteResult';
import { Observable } from 'rxjs';

export enum AddressSetResult {
    Successful,
    Failed,
    Cancelled
}

// tslint:disable: max-line-length
const cityName = 'Columbus';
const country = 'USA';
const ColumbusCenter = {
    latitude: 40,
    longitude: -82.985
};
// They may want to change this if they do business farther away.
const colorWheelRadius = 30; // miles, arbitrarily picked

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class LocationColorService {

    /** Angular Only Service **/

    urlBase = `https://open.mapquestapi.com/geocoding/v1/address?key=${environment.MAPQUEST_API_KEY}`;
    optimizeUrlBase = `https://www.mapquestapi.com/directions/v2/optimizedroute?key=${environment.MAPQUEST_API_KEY}`;

    constructor(private http: HttpClient,
        private messageService: MessageService,
        private sharedColorService: SharedColorService,
        private addressService: AddressGenSvc
    ) { }

    public geocodeAddress(address: Address) {
        return this.addressService.geocode(this.formatAddressGeneral(address));
        return this.http.get<GeocodingResponse>(`${this.urlBase}&location=${this.formatAddressGeneral(address)}`);
    }

    public geocodeAddressLeastSpecific(address: Address) {
        return this.addressService.geocode(this.formatAddressLeastSpecific(address));
        return this.http.get<GeocodingResponse>(`${this.urlBase}&location=${this.formatAddressLeastSpecific(address)}`);
    }

    // Sometimes MapQuest needs a simiplified version of the address format or it is too nitpicky
    private formatAddressGeneral(address: Address): string {
        return `${this.spaceToPlus(address.street)},${address.zip},${country}`; // works better
    }

    public optimizeRoute(addresses: Address[]): Observable<OptimizedRouteResult> {
        // default address is RTE office, used as the start and end location for route optimization
        const defaultAddress = '3427 E Dublin Granville Rd,43081';
        return this.addressService.routeOptimize(`{"locations": ["${defaultAddress}",${addresses.map(address => `"${this.formatAddressMoreSpecific(address)}"`)},"${defaultAddress}"]}`);
        return this.http.get<OptimizedRouteResult>(`${this.optimizeUrlBase}&json={
            "locations": [
                ${defaultAddress},
                ${addresses.map(address => `"${this.formatAddressMoreSpecific(address)}"`)},
                ${defaultAddress}
            ]
        }`, httpOptions);
    }

    // Sometimes MapQuest needs a more detailed version of the address or it does weird stuff when it goes to MapQuest
    private formatAddressMoreSpecific (address: Address): string {
        return `${address.street},${this.spaceToPlus(address.city)},${address.state.abbreviation},${address.zip}`;
    }

    // Sometimes MapQuest needs a less detailed version of the address because they can't find it for some reason
    // Use country with zip because apparently MapQuest thinks zip code 43230 is in Somalia so bound address searches by country
    private formatAddressLeastSpecific (address: Address): string {
        return `${address.zip},${country}`;
    }

    public spaceToPlus(str) {
        return str;
        return this.replaceAll(str, ' ', '+');
    }

    private replaceAll(str: string, find: string, replace: string) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    private escapeRegExp(str: string) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    }

    // Assumes valid response
    // willReattempt means if this call returns false, an immediate reattempt will be made by the calling code
    public setAddressWithGeocodeResponse(address: Address, res: GeocodingResponse, skipConfirm: boolean, willReattempt: boolean = false): AddressSetResult {

        // If the first location has geocode quality of country, then it's just returning the middle of the US as the result.
        // Arguably, it should return with a different status code, but it doesn't. Because reasons. That are known only to MapQuest.
        if (res.results[0].locations[0].geocodeQuality === 'COUNTRY') {
            if (!willReattempt) {
                if (skipConfirm || confirm('The provided address could not be found, if you continue the color for this address will be black. \nContinue?')) {
                    address.latitude = res.results[0].locations[0].latLng.lat;
                    address.longitude = res.results[0].locations[0].latLng.lng;

                    const colors = this.getColorsForAddress(address);
                    address.geoColor = colors.geoColor;
                    address.textColor = colors.textColor;
                    return AddressSetResult.Successful;
                } else {
                    return AddressSetResult.Cancelled;
                }
            } else {
                return AddressSetResult.Failed;
            }
        } else if (res.results.some(r => r.locations.some(l => l.geocodeQuality === 'STREET'
                                                            || l.geocodeQuality === 'ADDRESS'
                                                            || l.geocodeQuality === 'INTERSECTION'
                                                            || l.geocodeQuality === 'POINT'
                                                            || l.geocodeQuality === 'CITY'
                                                            || l.geocodeQuality === 'ZIP'
                                                            || l.geocodeQuality === 'NEIGHBORHOOD'
                                                            || l.geocodeQuality === 'ZIP_EXTENDED'))) {
            const bestLocation = this.getBestLocationResult(res.results[0].locations);
            address.latitude = bestLocation.latLng.lat;
            address.longitude = bestLocation.latLng.lng;

            const distance = this.distance(ColumbusCenter.latitude, ColumbusCenter.longitude, address.latitude, address.longitude);

            // if too far away and the user doesn't OK that, return false;
            if (!skipConfirm && distance > colorWheelRadius * 2
                    && !confirm('This address is outside of the ' + cityName + ' metropolitan area; if you continue, the color for this address will be black. \nContinue?')) {
                return AddressSetResult.Cancelled;
            }

            const colors = this.getColorsForAddress(address);

            address.geoColor = colors.geoColor;
            address.textColor = colors.textColor;
            return AddressSetResult.Successful;
        } else if (!res) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error Message',
                detail: `Error: No geocode response`
            });

            return AddressSetResult.Failed;
        }

        return AddressSetResult.Failed;
    }

    private getBestLocationResult(locations: Locations[]): Locations {
        return locations.reduce((a, b) => {
            // https://stackoverflow.com/questions/41032573/typescript-computed-getter-not-working/41035685
            // Using getter for order requires you to new up the object instead of just using the returned JSON
            const aLocation = Object.assign(new Locations(), a);
            const bLocation = Object.assign(new Locations(), b);
            return (aLocation.order < bLocation.order ? a : b);
        });
    }

    private getColorsForAddress(address: Address): ColorResponse {
        return this.getColorsForLatLong(address.latitude, address.longitude);
    }

    private getColorsForLatLong(lat: number, lng: number): ColorResponse {
        const distance = this.distance(ColumbusCenter.latitude, ColumbusCenter.longitude, lat, lng);
        // distance of 0 = 100% luminosity; distance of colorWheel = 50%, infinite distance = 0% luminosity.
        const maxDistance = 2 * colorWheelRadius;
        const luminosity = (distance > maxDistance) ? 0 : ((1 - (distance / (2 * colorWheelRadius))) * 100);

        const bearing = this.bearing(ColumbusCenter.latitude, ColumbusCenter.longitude, lat, lng);

        return new ColorResponse(
            `hsl(${bearing},100%,${luminosity.toFixed(0)}%)`,
            this.sharedColorService.findContrastingTextColor(bearing, 100, luminosity)
        );
    }

    public distance(lat1: number, lng1: number, lat2: number, lng2: number): number {
        return haversine({ latitude: lat1, longitude: lng1 }, { latitude: lat2, longitude: lng2 }, {unit: 'mile'});
    }

    public bearing(lat1: number, lng1: number, lat2: number, lng2: number) {
        const dLon = (lng2 - lng1);
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        const brng = this.toDeg(Math.atan2(y, x));
        return 360 - ((brng + 360) % 360);
    }

    private toRad(deg: number) {
         return deg * Math.PI / 180;
    }

    private toDeg(rad: number) {
        return rad * 180 / Math.PI;
    }
}
