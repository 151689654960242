import * as i0 from "@angular/core";
var WellnessWorkOrderMaintenanceService = /** @class */ (function () {
    function WellnessWorkOrderMaintenanceService() {
    }
    WellnessWorkOrderMaintenanceService.prototype.removeWellnessTaskDependentReferences = function (workOrder, deleted) {
        var _this = this;
        workOrder.workOrderWellnessTasks.map(function (task) {
            if (_this.isWellnessTaskDependent(task.wellnessTask, deleted)) {
                task.wellnessTask.schedulableAfterCompletionOfId = null;
                task.wellnessTask.schedulableAfterCompletionOf = null;
            }
        });
    };
    WellnessWorkOrderMaintenanceService.prototype.isWellnessTaskDependent = function (task, wowt) {
        if (task) {
            var hasMatchingId = wowt.wellnessTaskId && task.schedulableAfterCompletionOfId == wowt.wellnessTaskId;
            var hasMatchingReference = task.schedulableAfterCompletionOf == wowt.wellnessTask;
            if (hasMatchingId || hasMatchingReference) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    WellnessWorkOrderMaintenanceService.ngInjectableDef = i0.defineInjectable({ factory: function WellnessWorkOrderMaintenanceService_Factory() { return new WellnessWorkOrderMaintenanceService(); }, token: WellnessWorkOrderMaintenanceService, providedIn: "root" });
    return WellnessWorkOrderMaintenanceService;
}());
export { WellnessWorkOrderMaintenanceService };
