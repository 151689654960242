<app-loading-swirly [isLoading]="!(currentInfo || historyInfo)"></app-loading-swirly>
<p-accordion [multiple]="true" *ngIf="currentInfo || historyInfo">
    <p-accordionTab *ngIf="unfinalizedQuoteRequests && unfinalizedQuoteRequests.length > 0" header="FINALIZE FIRMTIME QUOTE REQUEST(S)" [selected]="unfinalizedQuoteRequests.length">
        <div *ngFor="let quote of unfinalizedQuoteRequests">
            <app-customer-firmtime-quote-row [quote]="quote"></app-customer-firmtime-quote-row>
        </div>
    </p-accordionTab>
    <hr *ngIf="unfinalizedQuoteRequests && unfinalizedQuoteRequests.length > 0" class="smallDivider"/>
    <p-accordionTab header="NEW QUOTES" [selected]="currentInfo && currentInfo.quoteWorkOrders.length">
        <app-loading-swirly [isLoading]="!currentInfo"></app-loading-swirly>
        <span *ngIf="currentInfo">
            <div *ngIf="!currentInfo.quoteWorkOrders.length">
                You currently have no quotes to review.
            </div>
            <div *ngFor="let quote of currentInfo.quoteWorkOrders">
                <app-customer-quote-row [quote]="quote" [current]="true"></app-customer-quote-row>
            </div>            
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
    <p-accordionTab header="SCHEDULING" [selected]="currentInfo && (currentInfo.wellnessWorkOrders.length || currentInfo.workWorkOrders.length)">
        <div class="fine-print">
            Tree Work, Tree Wellness, and Stump Grinding are performed by separate crews so we can most efficiently and effectively complete your work. Each service is auto charged to the credit card on file separately as the work is completed.
        </div>
        <br/>
        <app-loading-swirly [isLoading]="!currentInfo"></app-loading-swirly>
        <span *ngIf="currentInfo">
            <div *ngIf="!currentInfo.wellnessWorkOrders.length && !currentInfo.workWorkOrders.length">
                You currently have no outstanding work.
            </div>
            <div *ngFor="let wellnessWO of currentInfo.wellnessWorkOrders">
                <app-customer-work-order-row [workOrder]="wellnessWO" [current]="true"></app-customer-work-order-row>
            </div>
            <div *ngFor="let workWO of currentInfo.workWorkOrders">
                <app-customer-work-order-row [workOrder]="workWO" [current]="true"></app-customer-work-order-row>
            </div>
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
    <p-accordionTab header="COMPLETED WORK">
        <app-loading-swirly [isLoading]="!historyInfo"></app-loading-swirly>
        <span *ngIf="historyInfo ">
            <div *ngIf="!historyInfo.wellnessWorkOrders.length && !historyInfo.workWorkOrders.length">
                You currently have no completed work.
            </div>
            <div *ngFor="let wellnessWO of historyInfo.wellnessWorkOrders">
                <app-customer-work-order-row [workOrder]="wellnessWO" [workOrderBilling]="getWorkOrderBillingByWorkOrder(wellnessWO)" [current]="false"></app-customer-work-order-row>
            </div>
            <div *ngFor="let workWO of historyInfo.workWorkOrders">
                <app-customer-work-order-row [workOrder]="workWO" [workOrderBilling]="getWorkOrderBillingByWorkOrder(workWO)" [current]="false"></app-customer-work-order-row>
            </div>            
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
    <p-accordionTab header="QUOTE HISTORY">
        <app-loading-swirly [isLoading]="!historyInfo"></app-loading-swirly>
        <span *ngIf="historyInfo">
            <div *ngIf="!historyInfo.quoteWorkOrders.length">
                You currently have no completed quotes.
            </div>
            <div *ngFor="let quote of historyInfo.quoteWorkOrders">
                <app-customer-quote-row [quote]="quote" [current]="false"></app-customer-quote-row>
            </div>            
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
</p-accordion>
