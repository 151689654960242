<div class="p-grid">
  <div class="p-col-8 multiSelect">
    <h6>Zip Code(s)</h6>
    <p-multiSelect
      [options]="zipCodeOptions"
      [panelStyle]="{minWidth:'12em'}"
      name="dayZipCodeAssignments"
      [(ngModel)]="timeSlot.regionalManagerTimeSlotZipCodes"
    >
      <ng-template let-value pTemplate="selectedItems">
        <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
          <span>{{val.zipCode.code}} - {{val.zipCode.city}}</span>
        </div>
        <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Choose</span>
      </ng-template>
      <ng-template let-item pTemplate="item">
        {{item.label}}
      </ng-template>
    </p-multiSelect>
  </div>
  <div class="p-col-2">
    <h6>Time Slot</h6>
    <div class="p-grid">
      <div class="p-col">
        <p-calendar
          [(ngModel)]="timeSlot.timeBegin"
          [timeOnly]="true"
          hourFormat="12"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}">
        </p-calendar>
      </div>
      <div class="p-col-1 center">
        to
      </div>
      <div class="p-col">
        <p-calendar
        [(ngModel)]="timeSlot.timeEnd"
        [timeOnly]="true"
        hourFormat="12"
        [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}"></p-calendar>
      </div>
    </div>
  </div>
  <div class="p-col-2">
    <h6>Action</h6>
    <button pButton class="ui-button-success" pTooltip="Save" label="Save" (click)="save()" [disabled]="saving"></button>
    <button pButton class="ui-button-danger pad-left" pTooltip="Delete" icon="pi pi-trash" (click)="delete()"></button>
  </div>
</div>
