<br>
<div class="ui-g">
  <div class="ui-g-12 ui-lg-12">
    <h4>Zip Codes</h4>
  </div>
</div>
<br>
<form id="zipCodeForm" #zipCodeForm="ngForm">
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-6">
      <h6>Zip Code</h6>
      <input pInputText #zipCodeInput class="inputTextBox" name="code" [(ngModel)]="zipCode.code" required/>
    </div>
    <div class="ui-g-12 ui-lg-6">
      <h6>City</h6>
      <input pInputText class="inputTextBox" name="city" [(ngModel)]="zipCode.city" required />
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12 columnPadding">
      <div *ngIf="zipCodeForm.invalid && zipCodeForm.submitted" class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 buttonStyle" style="text-align: right">
      <button 
        pButton
        type="submit"
        (click)="saveZipCode(zipCodeForm)"
        class="ui-button-raised ui-button-success buttonStyle"
        [disabled]="saving"
        label="Save">
      </button>
      <button style="margin: 8px;"
        pButton 
        type="button" 
        class="ui-button-raised ui-button-success buttonStyle" 
        (click)="clear(zipCodeForm);" 
        label="Clear">
      </button>
    </div>
  </div>
</form>
<br>
<br>
<p-table [columns]="cols" [value]="zipCodes" [paginator]="true" [rows]="30" [responsive]="true" #zipTable>
  <ng-template pTemplate="caption">
    <div style="text-align: right">        
      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
      <input type="text" pInputText size="50" placeholder="Search..." (input)="zipTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
      <th [style.width]="'7%'"><!-- for the buttons --></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-zipCode
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td *ngFor="let col of cols">
        <span class="ui-column-title">{{col.header}}</span>{{zipCode[col.field]}}
      </td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          *ngIf="zipCode.code != 00000"
          (click)="editZipCode(zipCode)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          *ngIf="zipCode.code != 00000"
          (click)="deleteZipCode(zipCode)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
<br>
