import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { AccountsReceivableViewModel, AdvancedAccountsReceivableFilter, ReportGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-ar-report',
  templateUrl: './ar-report.component.html',
  styleUrls: ['./ar-report.component.css']
})
export class ArReportComponent implements OnInit {
  @ViewChild(Table) table: Table;

  interval;

  cols: any[];
  isLoading: boolean = false;
  totalRecords: number;

  expandField: string = 'number';

  total: number;

  rows: number = 15;
  allWorkOrders: AccountsReceivableViewModel[] = [];
  lastLazyEvent: LazyLoadEvent;
  advancedFilters: AdvancedAccountsReceivableFilter = new AdvancedAccountsReceivableFilter({
    asOfDate: new Date(new Date().getFullYear() - 1, 11, 31)
  });
  rowGroupMetadata: {index: number, size: number, amount: number}[];

  constructor(
    private reportService: ReportGenSvc,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) {
    this.cols = [
      { header: 'Number', width: '7%' },
      { field: 'FullName', header: 'Customer Name', width: '10%' },
      { field: 'ServiceType', header: 'Service Type', width: '8%' },
      { field: 'DateCompleted', header: 'Date Completed', width: '9%' },
      { field: 'PaidDate', header: 'Date Paid', width: '8%' },
      { field: 'PaymentStatus', header: 'Payment Status', width: '8%' },
      { field: 'AmountDue', header: 'Amount Due', width: '8%' }
    ];

    this.isLoading = true;
   }

  ngOnInit() {
    this.loadRequestLazy({
      sortField: 'Number',
      sortOrder: 1
    });
  }

  dateChanged() {
    const lazyRequest = {
      ...this.lastLazyEvent,
      first: 0,
      rows: this.rows
    };
    this.table.first = 0;
    this.loadRequestLazy(lazyRequest);
  }

  loadRequestLazy(event: LazyLoadEvent) {
    this.lastLazyEvent = event;
    this.isLoading = true;

    const sortOption = this.getSortOption(event.sortField, event.sortOrder);
    this.advancedFilters.orderBy = sortOption;
    this.messageService.add({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait',
      sticky: true
    });
    this.reportService.startARReport(this.advancedFilters)
      .subscribe(res => {
        // this.total = res.results.reduce((acc, curr) => acc + curr.amountDue, 0);
        // this.allWorkOrders = res.results;
        // this.updateRowGroupMetaData();
        // this.totalRecords = res.totalResults;
        // this.isLoading = false;

        this.interval = setInterval(() => {
          this.reportService.checkARReportStatus(res).subscribe(state => {
            if (state === 'Succeeded') {
              this.messageService.clear();
              clearInterval(this.interval);
              this.reportService.getARResultsFromJob(res).subscribe(results => {
                this.total = results.results.reduce((acc, curr) => acc + curr.amountDue, 0);
                this.allWorkOrders = results.results;
                this.updateRowGroupMetaData();
                this.totalRecords = results.totalResults;
                this.isLoading = false;
              });
            } else if (state === 'Failed') {
              this.messageService.clear();
              clearInterval(this.interval);
              this.addErrorMessage('Could not get AR report data.');
            }
          });
        }, 30000);

        // this.allWorkOrders.forEach(wo => this.getAmountDue(wo));
      },
      error => {
        this.isLoading = false;
        this.addErrorMessage('Could not load the report, please refresh and try again.');
      });
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = [];
    if (this.allWorkOrders) {
        for (let i = 0; i < this.allWorkOrders.length; i++) {
            const rowData = this.allWorkOrders[i];
            const woNum = rowData[this.expandField];
            if (i === 0) {
                this.rowGroupMetadata[woNum] = { index: 0, size: 1, amount: rowData.amountDue };
            } else {
                const previousRowData = this.allWorkOrders[i - 1];
                const previousRowGroup = previousRowData[this.expandField];
                if (woNum === previousRowGroup) {
                  this.rowGroupMetadata[woNum].size++;
                  this.rowGroupMetadata[woNum].amount += rowData.amountDue;
                } else {
                  this.rowGroupMetadata[woNum] = { index: i, size: 1, amount: rowData.amountDue };
                }
            }
        }
    }
}
  navigateToCustomer(workOrder: AccountsReceivableViewModel) {
    window.open('customerLandingPage/' + workOrder.customerId, '_blank');
  }

  navigateToWorkOrder(workOrder: AccountsReceivableViewModel) {
    if (workOrder.serviceType === 'Tree Wellness') {
      window.open('wellnessWorkOrderMaintenance/' + workOrder.workOrderId, '_blank');
    } else {
      window.open('workWorkOrderMaintenance/' + workOrder.workOrderId, '_blank');
    }
  }

  getSortOption(field: string, order: number) {
    if (order === 1) {
      return field + ' ASC';
    } else {
      return field + ' DESC';
    }
  }

  exportToCSV() {
    this.isLoading = true;
    document.body.classList.add('busy-cursor');

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      headers: ['Number', 'Customer Name', 'Service Type', 'Date Completed', 'Date Paid', 'Payment Status', 'Amount Due']
    };

    const dataToExport = this.allWorkOrders.map(wo => {
      return {
        number: wo.number,
        name: wo.fullName,
        type: wo.serviceType,
        completedOn: this.datePipe.transform(wo.dateCompleted, 'shortDate'),
        paidOn: wo.paidDate ? this.datePipe.transform(wo.paidDate, 'shortDate') : '',
        status: wo.paymentStatus,
        amount: this.currencyPipe.transform(wo.amountDue, 'USD')
      };
    });

    dataToExport.push({
      number: 'Total as of ' + this.datePipe.transform(this.advancedFilters.asOfDate, 'shortDate'),
      name: '',
      type: '',
      completedOn: '',
      paidOn: '',
      status: '',
      amount: this.currencyPipe.transform(this.total, 'USD')
    });

    const file = new ngxCsv(dataToExport, 'AR', options);

    document.body.classList.remove('busy-cursor');
    this.isLoading = false;
  }


  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }
}
