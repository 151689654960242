import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DisplayBlob } from '../../models/displayBlob';
import { of } from 'rxjs';
import { BlobGenSvc } from '../../services_autogenerated/generated_services';
import { BlobManagerWrapperService } from 'src/app/services/blob-manager-wrapper.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
import { Base64 } from 'src/app/models/base64';
import { Guid } from '../../models/guid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
var BlobManagerComponent = /** @class */ (function () {
    function BlobManagerComponent(blobService, authService, blobServiceWrapper, cdRef, appConfigService, messageService, http) {
        this.blobService = blobService;
        this.authService = authService;
        this.blobServiceWrapper = blobServiceWrapper;
        this.cdRef = cdRef;
        this.appConfigService = appConfigService;
        this.messageService = messageService;
        this.http = http;
        // Optional, limit types acceptable
        this.accept = '';
        // Optional, sets upload input to required
        this.required = false;
        // Optional, overrides button text so it's not a +
        this.buttonText = '';
        // Optional, do the upload through Angular instead of .NET
        this.angularUpload = false;
        this.doneUploadingEvent = new EventEmitter();
        this.filesToUpload = [];
        this.successfulUploads = 0;
    }
    BlobManagerComponent.prototype.ngOnInit = function () {
        this.isRequired = this.required;
        if (this.containerName == null) {
            throw new Error('BlobManager requires \'containerName\' attribute.');
        }
    };
    BlobManagerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // go get, and list, the blobs if it is not a new record
        if (this.parentRecordId > 0) {
            if (this.anonymousPossible) {
                this.blobService.getAnonymousBlobs(this.containerName, this.parentRecordId)
                    .subscribe(function (blobs) {
                    _this.blobs = blobs;
                    _this.displayBlobs = blobs.map(function (blob, index) {
                        // Kenny doesn't want customers to see the actual names, so just use 'Photo x'
                        var newBlob = new DisplayBlob(blob);
                        newBlob.DisplayName = 'Photo ' + (index + 1);
                        return newBlob;
                    });
                }, function (error) {
                    // Some error occurred, let the user know
                    console.log('Error loading uploads: ', error);
                    _this.errorMessage = 'Error loading uploads, please refresh the page.';
                });
            }
            else {
                this.blobService.getBlobs(this.containerName, this.parentRecordId)
                    .subscribe(function (blobs) {
                    _this.blobs = blobs;
                    _this.displayBlobs = blobs.map(function (blob) { return new DisplayBlob(blob); });
                }, function (error) {
                    // Some error occurred, let the user know
                    console.log('Error loading uploads: ', error);
                    _this.errorMessage = 'Error loading uploads, please refresh the page.';
                });
            }
        }
        else {
            this.blobs = [];
            this.displayBlobs = [];
            this.cdRef.detectChanges();
            // so sorry... weird bug where it didn't always detect changes when logged in, quick fix was important
            // and I didn't get back to fixing it later
        }
    };
    BlobManagerComponent.prototype.save = function () {
        if (this.filesToUpload && this.filesToUpload.length > 0) {
            return this.blobServiceWrapper.uploadFilesToBlobStorage(this.containerName, this.parentRecordId, this.filesToUpload);
        }
        else {
            return of(null);
        }
    };
    BlobManagerComponent.prototype.uploadFile = function (file, sas) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formattedContainerName, accountName, fileName, blockIDArrayList, blockSize, bytesLeft, _loop_1, this_1, blockID, httpOptions, offset, httpOptions, xml, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file.progress = 0;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        formattedContainerName = this.appConfigService.appConfig.environment.toLowerCase() + "-" + this.containerName;
                        accountName = "russelltreeappstorage";
                        fileName = this.parentRecordId + "/" + file.overwrittenFileName;
                        blockIDArrayList = [];
                        blockSize = 4 * 1024 * 1024 - 48;
                        bytesLeft = file.size;
                        _loop_1 = function (offset) {
                            var chunk;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        chunk = void 0;
                                        if (bytesLeft >= blockSize) {
                                            chunk = file.slice(offset, offset + blockSize);
                                        }
                                        else {
                                            chunk = file.slice(offset, offset + bytesLeft);
                                        }
                                        blockID = Base64.encode(Guid.newGuid());
                                        blockIDArrayList.push(blockID);
                                        httpOptions = {
                                            headers: new HttpHeaders({
                                                'x-ms-blob-type': 'BlockBlob',
                                                'x-ms-date': new Date().toUTCString(),
                                                'x-ms-version': '2015-02-21',
                                                'x-ms-client-request-id': blockID,
                                                'Content-Type': file.type
                                            })
                                        };
                                        return [4 /*yield*/, this_1.http.put("https://" + accountName + ".blob.core.windows.net/" + formattedContainerName + "/" + fileName + "?" + sas.token + "&comp=block&blockid=" + blockID, chunk, httpOptions).toPromise()
                                                .then(function (data) {
                                                file.progress = Math.round((offset / file.size) * 100);
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        offset = 0;
                        _a.label = 2;
                    case 2:
                        if (!(offset < file.size)) return [3 /*break*/, 5];
                        return [5 /*yield**/, _loop_1(offset)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        offset += blockSize;
                        return [3 /*break*/, 2];
                    case 5:
                        httpOptions = {
                            headers: new HttpHeaders({
                                'x-ms-date': new Date().toUTCString(),
                                'x-ms-version': '2015-02-21',
                                'Content-Type': 'text/plain; charset=UTF-8'
                            })
                        };
                        xml = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><BlockList>';
                        blockIDArrayList.forEach(function (id) { return xml += "<Latest>" + id + "</Latest>"; });
                        xml += '</BlockList>';
                        return [4 /*yield*/, this.http.put("https://" + accountName + ".blob.core.windows.net/" + formattedContainerName + "/" + fileName + "?" + sas.token + "&comp=blocklist&timeout=120", xml, httpOptions).toPromise()
                                .then(function (data) {
                                file.progress = 100;
                                _this.successfulUploads++;
                            }, function (err) {
                                if (file.canRetry) {
                                    file.canRetry = false;
                                    _this.messageService.clear('upload-in-progress');
                                    _this.messageService.addErrorMessage("Your upload was not successful but don't worry, we still got your quote request. You can contact us at " + _this.appConfigService.appConfig.contactPhoneNumber + " to arrange a way to send your video.", undefined, true);
                                }
                                else {
                                    file.canRetry = true;
                                }
                            })];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        console.log(err_1);
                        if (file.canRetry) {
                            file.canRetry = false;
                            this.messageService.clear('upload-in-progress');
                            this.messageService.addErrorMessage("Your upload was not successful but don't worry, we still got your quote request. You can contact us at " + this.appConfigService.appConfig.contactPhoneNumber + " to arrange a way to send your video.", undefined, true);
                        }
                        else {
                            file.canRetry = true;
                        }
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BlobManagerComponent.prototype.retryFile = function (file) {
        var _this = this;
        if (!this.sasToken) {
            this.blobService.getSAS().subscribe(function (sas) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.sasToken = sas;
                    this.uploadFile(file, sas);
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            this.uploadFile(file, this.sasToken);
        }
    };
    BlobManagerComponent.prototype.addToList = function (file) {
        var _this = this;
        if (file) {
            var index = this.filesToUpload
                .findIndex(function (x) { return (x.name === file.name || x.name === _this.spacesToUnderscores(file.name))
                && x.size === file.size && x.type === file.type; });
            if (index === -1) {
                file.overwrittenFileName = this.spacesToUnderscores(file.name);
                this.fileInUploader = null;
                if (this.parentRecordId && this.angularUpload) {
                    if (!this.sasToken) {
                        this.blobService.getSAS().subscribe(function (sas) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.sasToken = sas;
                                this.uploadFile(file, sas);
                                file.overwrittenFileName = this.spacesToUnderscores(file.name);
                                this.filesToUpload.push(file);
                                return [2 /*return*/];
                            });
                        }); });
                    }
                    else {
                        this.uploadFile(file, this.sasToken);
                        file.overwrittenFileName = this.spacesToUnderscores(file.name);
                        this.filesToUpload.push(file);
                    }
                }
                else if (this.parentRecordId) {
                    this.blobServiceWrapper.uploadFilesToBlobStorage(this.containerName, this.parentRecordId, [file]).subscribe(function (response) {
                        if (response) {
                            _this.blobs = _this.blobs.concat(response);
                            _this.displayBlobs = _this.displayBlobs.concat(response.map(function (r) {
                                return new DisplayBlob({
                                    name: r.Name,
                                    SASUri: r.SASUri,
                                });
                            }));
                        }
                        else {
                            alert('Failed to upload document/photo. Please try again later.');
                        }
                    });
                }
                else {
                    this.fileInUploader = null;
                    file.overwrittenFileName = this.spacesToUnderscores(file.name);
                    this.filesToUpload.push(file);
                    this.isRequired = false;
                }
            }
            else {
                alert('That file is already in your list.');
            }
        }
    };
    BlobManagerComponent.prototype.removeFromList = function (index) {
        if (confirm('Are you sure you want to remove this item from the upload list?')) {
            var removedFile = this.filesToUpload.splice(index, 1);
            // if it was originally required and we have 0 files, change required back to lock form
            this.isRequired = this.required && this.filesToUpload.length == 0;
            // Don't call delete API when there is not a parent record ID, it'll fail
            if (this.parentRecordId) {
                if (this.authService.isLoggedIn()) {
                    this.blobService.deleteBlob(this.containerName, removedFile[0].overwrittenFileName, this.parentRecordId)
                        .subscribe(function (response) {
                        if (!response) {
                            alert('Failed to delete document/photo. Please try again later.');
                        }
                    });
                }
                else {
                    this.blobService.deleteBlobAnonymous(this.containerName, removedFile[0].overwrittenFileName, this.parentRecordId)
                        .subscribe(function (response) {
                        if (!response) {
                            alert('Failed to delete document/photo. Please try again later.');
                        }
                    });
                }
            }
        }
    };
    BlobManagerComponent.prototype.saveAll = function () {
        if (this.filesToUpload && this.filesToUpload.length > 0) {
            return this.blobServiceWrapper.uploadFilesToBlobStorage(this.containerName, this.parentRecordId, this.filesToUpload);
        }
        else {
            return of(null);
        }
    };
    BlobManagerComponent.prototype.delete = function (blob, index) {
        var _this = this;
        if (confirm('Are you sure you want to remove ' + blob.DisplayName + ' from this record?')) {
            this.blobService.deleteBlob(this.containerName, blob.DisplayName, this.parentRecordId)
                .subscribe(function (response) {
                if (response) {
                    _this.displayBlobs.splice(index, 1);
                    _this.blobs.splice(index, 1);
                }
                else {
                    alert('Failed to delete document/photo. Please try again later.');
                }
            });
        }
    };
    BlobManagerComponent.prototype.scrubSpacesFromInput = function (file) {
        file.overwrittenFileName = this.spacesToUnderscores(file.overwrittenFileName);
    };
    BlobManagerComponent.prototype.spacesToUnderscores = function (toReplace) {
        return toReplace.replace(/ /g, '_');
    };
    return BlobManagerComponent;
}());
export { BlobManagerComponent };
