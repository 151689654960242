import { Injectable } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Equipment, Employee, BigDayBucketSubrange, BigDayEmployee, BigDayEquipment } from '../services_autogenerated/generated_services';

@Injectable({
  providedIn: 'root'
})
export class MultidayDragAndDropService {
  public allCDKEquipmentListIds: string[] = ['equipmentPanelList'];
  public allCDKEmployeeListIds: string[] = ['employeePanelList'];

  private eqpOrEmp;

  constructor() { }

  isEmployee(item): item is Employee {
    return item.isEmployee !== undefined;
  }

  isEquipment(item): item is Equipment {
    return item.isEquipment !== undefined;
  }

  addSubrangeToCDKLists(subrange: BigDayBucketSubrange) {
    this.allCDKEmployeeListIds.push(`subrangeEmployeeList-${subrange.id}`);
    this.allCDKEquipmentListIds.push(`subrangeEquipmentList-${subrange.id}`);
  }

  startDrag(event) {
    this.eqpOrEmp = event;
  }

  dropOntoSubrange(event, subrange: BigDayBucketSubrange) {
    console.log('DROP ONTO SUBRANGE');
    const droppedItem = event.previousContainer.data[event.previousIndex];

    if (event.previousContainer === event.container) {
      // Do not allow movement in the array
    } else if (this.isEmployee(droppedItem) && subrange.bigDayEmployees.find(e => e.employeeId === droppedItem.id)) {
      // bucket already has that employee, do not duplicate it.
    } else if (this.isEquipment(droppedItem) && subrange.bigDayEquipment.find(e => e.equipmentId === droppedItem.id)) {
      // bucket already has the equipmnet, do not duplicate it.
    } else if (this.isEmployee(droppedItem)) {
      // add employee to bucket
      const bigDayEmp = new BigDayEmployee();
      bigDayEmp.id = 0;
      bigDayEmp.bigDayBucketSubrangeId = subrange.id;
      bigDayEmp.employeeId = droppedItem.id;
      bigDayEmp.employee = droppedItem;
      subrange.bigDayEmployees.push(bigDayEmp);
    } else if (this.isEquipment(droppedItem)) {
      // add equipment to bucket
      const bigDayEqp = new BigDayEquipment();
      bigDayEqp.id = 0;
      bigDayEqp.bigDayBucketSubrangeId = subrange.id;
      bigDayEqp.equipmentId = droppedItem.id;
      bigDayEqp.equipment = droppedItem;
      subrange.bigDayEquipment.push(bigDayEqp);
    } else {

    }
  }

}
