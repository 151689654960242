import { OnInit } from '@angular/core';
import { QuoteRequest, QuoteRequestGenSvc, StateGenSvc, Address, Customer, BroughtBy, BroughtByGenSvc, EmployeeGenSvc, CustomerGenSvc, QuoteRequestCompletionStatus, QuoteRequestType, CustomerContact, EmailAddress, NotificationsGenSvc } from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { LocationColorService, AddressSetResult } from 'src/app/services/location-color.service';
import { CustomerTypes } from 'src/app/models/enums/customerTypes';
import { ContactInfoComponent } from '../customer-maintenance/contact-info/contact-info.component';
import { QuoteAndAuthenticationGenSvc } from '../../services_autogenerated/generated_services';
import { CustomerService } from '../../services/customer.service';
import { CustomerDropdownComponent } from '../customer-dropdown/customer-dropdown.component';
import { QuoteRequestService } from 'src/app/services/quote-request.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
var RequestedQuoteMaintenanceComponent = /** @class */ (function () {
    function RequestedQuoteMaintenanceComponent(quoteRequestService, broughtByService, stateService, route, router, messageService, locationColorService, authHelper, employeeService, customerService, authHelperService, customCustomerService, quoteAndAuthenticationService, notificationService, customQuoteRequestService, configService) {
        var _this = this;
        this.quoteRequestService = quoteRequestService;
        this.broughtByService = broughtByService;
        this.stateService = stateService;
        this.route = route;
        this.router = router;
        this.messageService = messageService;
        this.locationColorService = locationColorService;
        this.authHelper = authHelper;
        this.employeeService = employeeService;
        this.customerService = customerService;
        this.authHelperService = authHelperService;
        this.customCustomerService = customCustomerService;
        this.quoteAndAuthenticationService = quoteAndAuthenticationService;
        this.notificationService = notificationService;
        this.customQuoteRequestService = customQuoteRequestService;
        this.configService = configService;
        this.customerTypesEnum = CustomerTypes;
        this.canEdit = true;
        this.showMainContent = true;
        this.captchaSuccess = false;
        this.customers = [];
        this.isEmbedded = false;
        this.errorMessage = '';
        this.primaryContactIndex = 0;
        this.quoteRequestError = function (error) {
            _this.saving = false;
            if (JSON.parse(error.response).Message) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Saving',
                    detail: JSON.parse(error.response).Message,
                });
            }
            else {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Saving',
                    detail: 'Could not save the quote request, please check that all fields are valid and retry.'
                });
            }
        };
        this.doneSaving = function () {
            if (_this.quoteRequest.quoteRequestType === QuoteRequestType.Anytime && _this.isNew) {
                _this.notificationService.sendAnytimeConfirmEmail(_this.quoteRequest).subscribe();
            }
            if (_this.quoteRequest.quoteRequestType === QuoteRequestType.Virtual && _this.isNew) {
                if (_this.isNew) {
                    _this.virtualNext();
                    _this.isNew = false;
                    _this.notificationService.sendVirtualConfirmEmail(_this.quoteRequest).subscribe();
                    return; // exit early when it's new since there's a second page for virtual quotes
                }
            }
            if (_this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !_this.quoteRequest.eventStart) {
                _this.showScheduleButton = false;
                _this.navigate(_this.quoteRequest.id);
                _this.saving = false;
            }
            else if (_this.isAnon) {
                _this.messageService.add({
                    severity: 'success',
                    summary: 'Quote Request Created',
                    detail: 'Your quote request was created successfully.',
                    sticky: true
                });
                var confirmLink = _this.configService.appConfig.quoteRequestConfirmationLink;
                if (_this.isEmbedded && _this.quoteRequest.quoteRequestType !== QuoteRequestType.Firmtime) {
                    window.top.location.href = confirmLink;
                }
                else {
                    window.location.assign(confirmLink);
                }
            }
            else if (_this.isCustomer && _this.token) {
                _this.router.navigateByUrl('/customerLandingPage/' + _this.token.id);
            }
            else {
                _this.router.navigateByUrl('/quoteList');
            }
            _this.saving = false;
        };
        this.customSearch = function (searchTerm, customer) {
            return _this.customCustomerService.customerSearch(searchTerm, customer);
        };
    }
    Object.defineProperty(RequestedQuoteMaintenanceComponent.prototype, "Type", {
        // use type on template
        get: function () {
            return QuoteRequestType;
        },
        enumerable: true,
        configurable: true
    });
    // current logic understanding. Request for Quotes get turned into a Quote Work Order
    // Once customer approves, it transforms into a Work Order but gets a Ready to Schedule or Ready for Lead Review status.
    RequestedQuoteMaintenanceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
        this.broughtByService.getAll().subscribe(function (broughtBys) { return _this.broughtBys = broughtBys; });
        this.route.queryParams
            .subscribe(function (params) {
            _this.isEmbedded = params.embedded;
        });
        this.editable = false;
        var id = +this.route.snapshot.paramMap.get('id');
        this.setPermissions();
        this.stateService.getAll().subscribe(function (states) {
            _this.states = states;
            if (!id) {
                _this.selectedState = states.find(function (state) { return state.name === 'Ohio'; });
            }
        });
        this.employeeService.getRegionalManagers().subscribe(function (rms) { return _this.rms = rms; });
        if (!this.isCustomer) {
            this.customerService.getActive().subscribe(function (customers) {
                _this.customers = customers;
                if (_this.quoteRequest) {
                    _this.selectCustomerFromQuoteRequestCustomerId();
                }
            });
        }
        if (id) {
            this.quoteRequestService.get(id).subscribe(function (qr) {
                _this.quoteRequest = qr;
                _this.selectedCustomer = qr.customer;
                _this.primaryContactIndex = qr.customer.customerContacts.findIndex(function (x) { return x.primary; });
                _this.disableDelete = false;
                _this.showScheduleButton = _this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime &&
                    _this.quoteRequest.eventStart === undefined;
                if (_this.token && _this.token.roles.includes('Regional Manager')) {
                    _this.editable = true;
                }
                if (qr.completionStatus === QuoteRequestCompletionStatus.Transformed_to_Quote) {
                    _this.editable = false;
                }
                _this.canTransform = _this.quoteRequest.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote;
                _this.canSendOnMyWay = _this.canSendOnMyWay
                    && _this.quoteRequest.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote;
                _this.selectedState = qr.address.state;
            });
        }
        else {
            this.isNew = true;
            this.editable = true;
            this.quoteRequest = new QuoteRequest();
            this.quoteRequest.completionStatus = QuoteRequestCompletionStatus.Pending;
            if (this.isCustomer && this.token) {
                this.customerService.get(this.token.id).subscribe(function (customer) {
                    _this.quoteRequest.customer = customer;
                    if (_this.quoteRequest.addressSameAsCustomer) {
                        _this.quoteRequest.address = customer.address;
                        _this.selectedState = _this.quoteRequest.address.state;
                    }
                });
            }
            this.quoteRequest.addressSameAsCustomer = true;
            this.quoteRequest.broughtBy = new BroughtBy();
            this.quoteRequest.createdDate = new Date();
            this.disableDelete = true;
            this.quoteRequest.isUrgent = false;
            if (this.isAnon) {
                var newCustomer = new Customer({
                    active: true,
                    address: new Address(),
                    customerTypeId: 1
                });
                this.quoteRequest.customer = newCustomer;
                this.quoteRequest.address = new Address();
                this.addressSameAsCustomerToggle();
            }
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.addNewCustomer = function () {
        var customerContacts = [];
        var newContact = new CustomerContact();
        newContact.firstName = '';
        newContact.lastName = '';
        newContact.primary = true;
        newContact.active = true;
        newContact.emailAddress = new EmailAddress({ email: '' });
        newContact.receiveInvoicingEmails = true,
            newContact.receiveNonInvoicingEmails = true,
            customerContacts.push(newContact);
        var newCustomer = new Customer({
            customerContacts: customerContacts,
            active: true,
            address: new Address(),
            customerTypeId: 1,
            paymentInfoRequired: true
        });
        this.quoteRequest.customer = newCustomer;
        this.displayCustomerDialog = true;
    };
    RequestedQuoteMaintenanceComponent.prototype.allowNewCustomer = function () {
        return this.quoteRequest && !this.quoteRequest.quoteId;
    };
    RequestedQuoteMaintenanceComponent.prototype.selectCustomerFromQuoteRequestCustomerId = function () {
        var _this = this;
        this.selectedCustomer = this.customers.find(function (c) { return c.id === _this.quoteRequest.customerId; });
        if (this.selectedCustomer) {
            this.primaryContactIndex = this.selectedCustomer.customerContacts.findIndex(function (x) { return x.primary; });
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.setCustomer = function (id) {
        var _this = this;
        if (id) {
            this.customerService.get(id).subscribe(function (c) {
                _this.selectedCustomer = c;
                _this.quoteRequest.customer = c;
                _this.primaryContactIndex = c.customerContacts.findIndex(function (x) { return x.primary; });
                _this.addressSameAsCustomerToggle();
            }, function (error) { return console.log(error); }); // TODO handle error properly
        }
        else {
            this.selectedCustomer = null;
            this.quoteRequest.customer = null;
            this.quoteRequest.addressSameAsCustomer = true;
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.closedCustomerDialog = function (customer) {
        this.displayCustomerDialog = false;
        if (customer) {
            this.quoteRequest.customer = customer;
            this.customers = this.customers.concat([customer]);
            this.selectedCustomer = customer;
            this.primaryContactIndex = customer.customerContacts.findIndex(function (x) { return x.primary; });
            this.addressSameAsCustomerToggle();
            this.customerDropdown.setCustomerFromNew(customer);
        }
        else {
            this.quoteRequest.customer = null;
            this.quoteRequest.addressSameAsCustomer = true;
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.addressSameAsCustomerToggle = function () {
        if (this.quoteRequest.addressSameAsCustomer) {
            this.quoteRequest.address = this.quoteRequest.customer.address;
        }
        else {
            this.quoteRequest.address = new Address();
            this.quoteRequest.address.state = this.selectedState;
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.quoteOptionChanged = function () {
        if (this.quoteRequest.quoteRequestType === QuoteRequestType.Anytime) {
            this.showScheduleButton = false;
            this.showNextButton = false;
        }
        else if (this.quoteRequest.quoteRequestType === QuoteRequestType.Virtual) {
            this.showScheduleButton = false;
            this.showNextButton = true;
        }
        else if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
            localStorage.setItem('zipCode', this.quoteRequest.address.zip);
            this.showScheduleButton = true;
            this.showNextButton = false;
            this.checkForRM();
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.chooseAnytime = function () {
        this.quoteRequest.quoteRequestType = QuoteRequestType.Anytime;
    };
    RequestedQuoteMaintenanceComponent.prototype.chooseFirmtime = function () {
        this.quoteRequest.quoteRequestType = QuoteRequestType.Firmtime;
        this.showScheduleButton = true;
    };
    // goToSchedule() {
    //   localStorage.setItem('zipCode', this.quoteRequest.address.zip);
    //   const extras: NavigationExtras = {
    //     queryParams: { 'asAnonymous': 'true', 'embedded': this.isEmbedded }
    //   };
    //   this.router.navigate(['/ftSchedulePage/' + this.quoteRequest.representative.id], extras);
    // }
    RequestedQuoteMaintenanceComponent.prototype.setPermissions = function () {
        this.token = this.authHelper.getDecodedAccessToken();
        this.isCustomer = this.token == null || this.token.userType === 'customer';
        if (!this.token) {
            this.hasQuotePermissions = true;
            this.isAnon = true;
        }
        else {
            var claims = this.token.claimNames;
            if (claims.includes('View/Edit Request for Quote')
                || claims.includes('View/Edit Quote')
                || claims.includes('View/Edit Their Own Work Order')) {
                this.hasQuotePermissions = true;
            }
            this.canSendOnMyWay = this.hasQuotePermissions
                && (this.token.roles.includes('Admin') || this.token.roles.includes('Regional Manager'));
            if (this.token.claimNames.includes('Full Control')) {
                this.editable = true;
            }
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.save = function (ngForm) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (this.isAnon) {
            this.errorMessage = '';
        }
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (ngForm.valid) {
            if (this.isAnon) {
                if (!this.quoteRequest.recaptchToken) {
                    this.errorMessage = 'Please check the reCAPTCHA.';
                    return;
                }
                this.contactInfoComponent.preSave();
            }
            this.saving = true;
            this.locationColorService.geocodeAddress(this.quoteRequest.address).subscribe(function (res) {
                var result = _this.locationColorService
                    .setAddressWithGeocodeResponse(_this.quoteRequest.address, res, _this.isCustomer || _this.isAnon, true);
                if (result === AddressSetResult.Successful) {
                    _this.saveRequest();
                }
                else if (result === AddressSetResult.Failed) {
                    _this.locationColorService.geocodeAddressLeastSpecific(_this.quoteRequest.address).subscribe(function (res2) {
                        var secondResult = _this.locationColorService
                            .setAddressWithGeocodeResponse(_this.quoteRequest.address, res2, _this.isCustomer || _this.isAnon);
                        if (secondResult !== AddressSetResult.Cancelled) {
                            _this.saveRequest();
                        }
                        else {
                            _this.saving = false;
                        }
                    });
                }
                else if (result === AddressSetResult.Cancelled) {
                    _this.saving = false;
                }
            }, function (error) {
                _this.saveRequest();
                if (!_this.isCustomer && !_this.isAnon) {
                    // Customers don't need to see this message
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Geocode Response Failed',
                        detail: 'Could not get the Geocode Response from the MapQuest provider. The color will be set to black.'
                    });
                }
            });
        }
        else {
            if (this.quoteForm.value.broughtBy === 9 && !this.quoteForm.value.reason) {
                this.errorMessage = 'Since you selected Other, please explain what brought you to our site by filling in the text-box.';
            }
            else {
                this.errorMessage = 'There are errors on this page. Please correct the errors and resubmit.';
            }
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.disableVirtualSubmit = function () {
        return !this.blobManagerComponent.filesToUpload.every(function (f) { return f.progress == 100; })
            || !this.blobManagerComponent.filesToUpload
            || this.blobManagerComponent.filesToUpload.length == 0;
    };
    RequestedQuoteMaintenanceComponent.prototype.checkForRM = function () {
        var _this = this;
        if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
            // Find an RM who has been assigned the zip code AND has assigned that zip code to a specific day
            this.quoteRequest.representative =
                this.rms.find(function (rm) { return rm.zipCodeAssignments.some(function (zipCode) { return zipCode.zipCode.code === _this.quoteRequest.address.zip; }) &&
                    rm.regionalManagerTimeSlots.some(function (timeSlot) {
                        return timeSlot.regionalManagerTimeSlotZipCodes.some(function (zipCode) { return zipCode.zipCode.code === _this.quoteRequest.address.zip; });
                    }); });
            if (!this.quoteRequest.representative) {
                if (confirm("Your zip code or area is not recognized in the normal range or region. We can only accommodate an anytime quote at this time. Please contact Russell Tree at " + this.contactPhoneNumber + " with any questions. Please click Ok to continue.")) {
                    this.quoteRequest.quoteRequestType = undefined;
                    this.quoteRequest.quoteRequestType = QuoteRequestType.Anytime;
                    this.quoteRequest.representative = this.rms.find(function (rm) { return rm.zipCodeAssignments.some(function (zipCode) {
                        return zipCode.zipCode.code === '00000';
                    }); });
                    this.showScheduleButton = false;
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        return true;
    };
    RequestedQuoteMaintenanceComponent.prototype.saveRequest = function () {
        if (this.quoteRequest.id) {
            this.updateQuote();
        }
        else {
            if (this.checkForRM()) {
                if (this.token) {
                    this.addQuoteAndUpdateCustomer();
                }
                else {
                    this.addQuoteAndCustomer();
                }
            }
            else {
                this.saving = false;
            }
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.updateQuote = function () {
        var _this = this;
        this.quoteAndAuthenticationService.updateQuoteAndUpdateCustomer(this.quoteRequest)
            .subscribe(function (savedQR) {
            _this.saveBlobs(savedQR);
        }, this.quoteRequestError);
    };
    RequestedQuoteMaintenanceComponent.prototype.addQuoteAndUpdateCustomer = function () {
        var _this = this;
        this.quoteAndAuthenticationService.addQuoteAndUpdateCustomer(this.quoteRequest)
            .subscribe(function (qr) {
            if (qr.token != null) {
                // localStorage.setItem(this.authHelperService.authToken, qr.token);
            }
            _this.saveBlobs(qr);
        }, this.quoteRequestError);
    };
    RequestedQuoteMaintenanceComponent.prototype.addQuoteAndCustomer = function () {
        var _this = this;
        this.quoteAndAuthenticationService.addQuoteAndCustomer(this.quoteRequest)
            .subscribe(function (qr) {
            if (qr.token != null) {
                // localStorage.setItem(this.authHelperService.authToken, qr.token);
                _this.saveBlobs(qr);
            }
            else {
                _this.saveBlobs(qr);
                if (_this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) { }
            }
        }, this.quoteRequestError);
    };
    RequestedQuoteMaintenanceComponent.prototype.callDoneSaving = function () {
        this.doneSaving();
    };
    RequestedQuoteMaintenanceComponent.prototype.saveBlobs = function (savedQuoteRequest) {
        var _this = this;
        this.captchaSuccess = true;
        this.blobManagerComponent.parentRecordId = savedQuoteRequest.id;
        this.quoteRequest = savedQuoteRequest;
        if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !this.quoteRequest.eventStart) {
            localStorage.setItem('quoteRequestId', savedQuoteRequest.id.toString());
        }
        this.blobManagerComponent.save()
            .subscribe(function () {
            _this.doneSaving();
        }, function (error) {
            _this.isNew = false;
            _this.saving = false;
            _this.messageService.add({
                severity: 'error',
                summary: 'Error Saving',
                detail: 'Could not save the selected upload(s) to the quote request.'
            });
        });
    };
    RequestedQuoteMaintenanceComponent.prototype.navigate = function (id) {
        if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !this.quoteRequest.eventStart) {
            localStorage.setItem('quoteRequestId', id.toString());
            localStorage.setItem('zipCode', this.quoteRequest.address.zip);
            parent.postMessage('scrollEvent', '*');
            var extras = {
                queryParams: { 'asAnonymous': 'true', 'embedded': this.isEmbedded }
            };
            this.router.navigate(['/ftSchedulePage/' + this.quoteRequest.representative.id], extras);
        }
        else {
            this.doneSaving();
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.navigateToQuote = function (id) {
        this.router.navigateByUrl('/quoteWorkOrderMaintenance/' + id);
        this.saving = false;
    };
    RequestedQuoteMaintenanceComponent.prototype.turnToQuote = function (ngForm) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        // A disabled form cannot be valid, so just make sure the form is not invalid
        if (!ngForm.invalid) {
            this.saving = true;
            this.quoteRequestService.convertToQuote(this.quoteRequest).subscribe(function (quote) {
                _this.navigateToQuote(quote.id);
            });
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.onStateSelect = function (state) {
        this.quoteRequest.address.stateId = state.id;
        this.quoteRequest.address.state = state;
    };
    RequestedQuoteMaintenanceComponent.prototype.virtualNext = function () {
        this.showMainContent = false;
        this.showNextButton = false;
        this.showVirtualSubmit = true;
    };
    RequestedQuoteMaintenanceComponent.prototype.back = function () {
        this.showMainContent = true;
        this.showNextButton = true;
    };
    RequestedQuoteMaintenanceComponent.prototype.cancel = function () {
        this.errorMessage = '';
        if (this.isCustomer) {
            this.router.navigateByUrl('/customerLandingPage/' + this.token.id);
        }
        else {
            this.router.navigateByUrl('/quoteList');
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.delete = function () {
        var _this = this;
        if (confirm('Are you sure you want to permanently delete this quote request?')) {
            this.quoteRequestService.delete(this.quoteRequest.id).subscribe(function () {
                _this.router.navigateByUrl('/quoteList');
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Deleting',
                    detail: 'Could not delete the quote request, please check that the quote request exists and retry.'
                });
            });
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.onMyWay = function () {
        this.customQuoteRequestService.onMyWay(this.quoteRequest);
    };
    RequestedQuoteMaintenanceComponent.prototype.isEditMode = function () {
        if (this.quoteRequest && this.quoteRequest.id > 0 && !this.isNew) {
            return true;
        }
        else {
            return false;
        }
    };
    RequestedQuoteMaintenanceComponent.prototype.isDisabledMode = function () {
        return !this.hasQuotePermissions || !this.editable;
    };
    RequestedQuoteMaintenanceComponent.prototype.isServiceAddressDisabled = function () {
        return !this.hasQuotePermissions || this.quoteRequest.addressSameAsCustomer || !this.editable;
    };
    RequestedQuoteMaintenanceComponent.prototype.isCustomerEditable = function () {
        return !this.isCustomer && !this.isAnon && this.isNew;
    };
    RequestedQuoteMaintenanceComponent.prototype.onRecaptchaResolved = function (res) {
        this.quoteRequest.recaptchToken = res;
    };
    return RequestedQuoteMaintenanceComponent;
}());
export { RequestedQuoteMaintenanceComponent };
