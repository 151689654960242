<div *ngIf="canSeePrice">
  <div class="ui-g" style="text-align: right">
    <div class="ui-g-12" style="text-align: right">
      <button 
        type="button" 
        (click)="addPriceAdjustment()" 
        class="ui-button-raised" 
        pButton 
        [disabled]="!isEditable"
        *ngIf="!isCustomer"
        label="Price Adjustment"></button>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12" style="text-align: right">
    <label for="subTotal"><span class="totalLabel">Subtotal</span>&nbsp;&nbsp;</label>
    <input 
      pInputText 
      name="subTotal" 
      class="totalBoxes pull-right" 
      [textMask]="{mask: currencyMask}" 
      [ngModel]="helper.subTotal(workOrder)" 
      disabled="true" 
      *ngIf="workOrder"/>
    </div>
  </div>
  <ng-container *ngIf="workOrder">
    <div class="ui-g" *ngFor="let adj of workOrder.workOrderPriceAdjustments; let i = index">
      <div class="ui-g-12" style="text-align: right;display: inline-block;">
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deletePriceAdjustment(adj)"
          class="ui-button-danger tableButtonMarginLeft"
          [disabled]="!isEditable">
        </button>&nbsp;&nbsp;
        <input pInputText 
          [name]="'adjReason' + i"
          class="totalBoxes pull-right"
          [(ngModel)]="adj.priceAdjustment.reason"
          placeholder="Adjustment Reason"
          [disabled]="!isEditable"
          required/>&nbsp;&nbsp;
        <div style="display: inline-block;">
          <div class="ui-inputgroup totalBoxes">
            <input pInputText 
              type="number"
              min="-100"
              max="100"
              [name]="'adjPercentage' + i"
              [(ngModel)]="adj.priceAdjustment.percentage"
              [disabled]="!isEditable"
              required
              />
            <span class="ui-inputgroup-addon">%</span>
            &nbsp;&nbsp;
          </div>
        </div>
        <input pInputText 
          [textMask]="{mask: currencyMask}" 
          [name]="'adjChange' + i" 
          class="totalBoxes pull-right" 
          [ngModel]="helper.adjustmentAmount(adj, workOrder)" 
          disabled="true"/>
      </div>
    </div>
  </ng-container>
  <div class="ui-g" *ngIf="workOrder && workOrder.workOrderPriceAdjustments.length > 0">
    <div class="ui-g-12" style="text-align: right">
    <label for="totalAfterAdjustment"><span class="totalLabel">Total After Price Adjustment</span>&nbsp;&nbsp;</label>
    <input pInputText [textMask]="{mask: currencyMask}" name="totalAfterAdjustment" class="totalBoxes pull-right" [ngModel]="helper.totalPriceAfterAdjustment(workOrder)" disabled="true"/>
    </div>
  </div>
  <div class="ui-g" *ngIf="helper.qualifiesForFirmtimeRefund(workOrder)">
    <div class="ui-g-12 salesTaxSmallScreen" style="text-align: right;display: inline-block;">
      <label for="refund"><span class="totalLabel">Firm-Time Refund</span>&nbsp;&nbsp;</label>
      <input pInputText 
        name="refund"
        [textMask]="{mask: currencyMask}"
        [ngModel]="100"
        class="totalBoxes"
        disabled="true"
        *ngIf="workOrder"/>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12 salesTaxSmallScreen" style="text-align: right;display: inline-block;">
      <label for="salesTax"><span class="totalLabel">Sales Tax</span>&nbsp;&nbsp;</label>
      <div style="display: inline-block;">
        <div class="ui-inputgroup totalBoxes">
          <input pInputText 
            type="number" 
            min="0" 
            max="50"
            name="salesTaxPercentage" 
            [(ngModel)]="workOrder.salesTaxPercentage"
            [disabled]="!isEditable || workOrder.customer.taxExempt"
            *ngIf="workOrder" />
          <span class="ui-inputgroup-addon">%</span>
          &nbsp;&nbsp;
        </div>
      </div>
      <input pInputText 
        name="salesTax"
        [textMask]="{mask: currencyMask}"
        [ngModel]="helper.salesTaxAmount(workOrder)"
        class="totalBoxes"
        disabled="true"
        *ngIf="workOrder"/>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12" style="text-align: right">
      <label for="total"><span class="totalLabel">Total</span>&nbsp;&nbsp;</label>
      <input 
        pInputText 
        name="totalAmount" 
        [textMask]="{mask: currencyMask}" 
        [ngModel]="helper.totalAmount(workOrder)" 
        class="totalBoxes" 
        disabled="true" 
        *ngIf="workOrder"/>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12" style="text-align: right">
      <label for="total"><span class="totalLabel">Total Completed</span>&nbsp;&nbsp;</label>
      <input 
        pInputText 
        name="totalAmountUnpaid" 
        [textMask]="{mask: currencyMask}" 
        [ngModel]="helper.totalAmountCompleted(workOrder)" 
        class="totalBoxes" 
        disabled="true" 
        *ngIf="workOrder"/>
    </div>
  </div>


  <div *ngFor="let cash of asWorkOrderNotQuote()?.workOrderCashCollected; let i = index" class="ui-g"> 
    <div class="ui-g-12" style="text-align: right">
      <label for="total"><span class="totalLabel">Value of Cash or Check Collected</span>&nbsp;&nbsp;</label>
      <input
        pInputText 
        [name]="'cashCollected' + i" 
        [textMask]="{mask: currencyMask, placeholderChar: '\u2000'}" 
        [ngModel]="asWorkOrderNotQuote().workOrderCashCollected[i].amount" 
        [disabled]="!isCashCollectedEditable() || cash.invoiced"
        class="totalBoxes" 
        *ngIf="workOrder"
        (ngModelChange)="cashCollectedChange($event, i)"
        required/>
        <button pButton
        *ngIf="!cash.id && isCashCollectedEditable()"
        type="button"
        class="ui-button-raised ui-button-danger"
        (click)="removeCashCollectedEntry(i)"
        icon="pi pi-trash"
        style="margin-left:4px">
        </button>
    </div>
  </div>
  <!-- <div *ngIf="!isQuote && !isCustomer && hasCustomerMaintenancePermissions()" class="ui-g" style="text-align: right">
    <div class="ui-g-12" style="text-align: right">
      <label><span class="totalLabel">Autocharge Enabled</span>&nbsp;&nbsp;</label>
      <p-checkbox *ngIf="workOrder" name="autochargeEnabled" [disabled]="!paymentInfoRequired || !isEditable" binary="true" [(ngModel)]="workOrder.autoChargeEnabled"></p-checkbox>
    </div>
  </div> -->
</div>