import { CrewGenSvc } from '../services_autogenerated/generated_services';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../services_autogenerated/generated_services";
var CrewsDateRangeService = /** @class */ (function () {
    function CrewsDateRangeService(crewService) {
        this.crewService = crewService;
        this.crewUpdater = new BehaviorSubject(null);
    }
    CrewsDateRangeService.prototype.getCrewUpdater = function () {
        return this.crewUpdater.asObservable();
    };
    CrewsDateRangeService.prototype.emitCrewUpdate = function (crews) {
        this.crewUpdater.next(crews);
    };
    CrewsDateRangeService.prototype.setCrewsInDateRange = function (startDate, endDate) {
        var _this = this;
        this.crewService.getValidCrewsInDateRange(startDate.toDate(), endDate.toDate()).subscribe(function (crews) {
            _this.crewsInDateRange = crews;
            _this.emitCrewUpdate(crews);
        });
    };
    CrewsDateRangeService.ngInjectableDef = i0.defineInjectable({ factory: function CrewsDateRangeService_Factory() { return new CrewsDateRangeService(i0.inject(i1.CrewGenSvc)); }, token: CrewsDateRangeService, providedIn: "root" });
    return CrewsDateRangeService;
}());
export { CrewsDateRangeService };
