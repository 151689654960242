/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./priority-backlog-maintenance.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./backlog-row/backlog-row.component.ngfactory";
import * as i3 from "./backlog-row/backlog-row.component";
import * as i4 from "../../services_autogenerated/generated_services";
import * as i5 from "@angular/forms";
import * as i6 from "primeng/components/common/messageservice";
import * as i7 from "@angular/common";
import * as i8 from "./priority-backlog-maintenance.component";
var styles_PriorityBacklogMaintenanceComponent = [i0.styles];
var RenderType_PriorityBacklogMaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PriorityBacklogMaintenanceComponent, data: {} });
export { RenderType_PriorityBacklogMaintenanceComponent as RenderType_PriorityBacklogMaintenanceComponent };
function View_PriorityBacklogMaintenanceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-backlog-row", [], null, null, null, i2.View_BacklogRowComponent_0, i2.RenderType_BacklogRowComponent)), i1.ɵdid(2, 114688, null, 0, i3.BacklogRowComponent, [i4.PriorityTypeShortcutGenSvc, i5.FormBuilder, i6.MessageService], { backlog: [0, "backlog"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PriorityBacklogMaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Backlog Priority Maintenance"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriorityBacklogMaintenanceComponent_1)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backlogs; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_PriorityBacklogMaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-priority-backlog-maintenance", [], null, null, null, View_PriorityBacklogMaintenanceComponent_0, RenderType_PriorityBacklogMaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i8.PriorityBacklogMaintenanceComponent, [i4.PriorityTypeShortcutGenSvc, i6.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PriorityBacklogMaintenanceComponentNgFactory = i1.ɵccf("app-priority-backlog-maintenance", i8.PriorityBacklogMaintenanceComponent, View_PriorityBacklogMaintenanceComponent_Host_0, {}, {}, []);
export { PriorityBacklogMaintenanceComponentNgFactory as PriorityBacklogMaintenanceComponentNgFactory };
