import { Component, OnInit } from '@angular/core';
import {
  SurveyQuestion, SurveyQuestionGenSvc, SurveyHeader, SurveyAnswer,
  SurveyResponseGenSvc
} from 'src/app/services_autogenerated/generated_services';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute } from '@angular/router';
import * as objectHash from 'object-hash';

@Component({
  selector: 'app-survey-response',
  templateUrl: './survey-response.component.html',
  styleUrls: ['./survey-response.component.css']
})
export class SurveyResponseComponent implements OnInit {
  surveyHeader: SurveyHeader;

  customerId: number;
  quoteNumber: string;
  surveyHash: string;

  completed: boolean;

  errorsInForm = false;
  isCustomer = false;

  constructor(
    private surveyQuestionService: SurveyQuestionGenSvc,
    private surveyHeaderService: SurveyResponseGenSvc,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const surveyHeaderId = this.route.snapshot.paramMap.get('id');
    if (surveyHeaderId) {
      // Coming from survey list
      this.onInitById(+surveyHeaderId);
    } else {
      // Coming from email link
      this.onInitByCustIdAndQuoteNumber();
      this.isCustomer = true;
    }
  }

  onInitById(id: number) {
    this.completed = true;
    this.surveyHeaderService.get(id).subscribe((header) => {
      this.surveyHeader = header;
      this.makeAllAnsweredQuestionsVisible();
    });
  }

  onInitByCustIdAndQuoteNumber() {
    this.customerId = +this.route.snapshot.paramMap.get('custId');
    this.quoteNumber = this.route.snapshot.paramMap.get('quoteNum');
    this.surveyHash = objectHash({
      customerId: this.customerId,
      quoteNumber: this.quoteNumber
    });

    this.surveyHeaderService.getByCustIdAndQuoteNum(this.customerId, this.quoteNumber, this.surveyHash).subscribe(header => {
      if (header && header.id) {
        this.completed = true;
        this.surveyHeader = header;
        this.makeAllAnsweredQuestionsVisible();
      } else {
        this.completed = false;
        this.surveyHeader = this.getBlankSurveyHeader();
        this.surveyQuestionService.get().subscribe(questions => this.addSurveyAnswersToHeader(questions));
      }
    }, er => {
      alert('The provided route is not a valid customer id / quote number combination.');
      throw (er);
    });
  }

  // If a question is disabled after the survey is completed, we still want to see the response.
  makeAllAnsweredQuestionsVisible() {
    this.surveyHeader.surveyAnswers.forEach(answer => {
      answer.surveyQuestion.visible = answer.comment != null || answer.yesNoResponse != null;
    });
  }

  getBlankSurveyHeader(): SurveyHeader {
    const surveyHead = new SurveyHeader();
    surveyHead.customerId = this.customerId;
    surveyHead.active = true;
    surveyHead.surveyHash = this.surveyHash;
    return surveyHead;
  }

  addSurveyAnswersToHeader(questions: SurveyQuestion[]) {
    const answers: SurveyAnswer[] = [];
    questions.forEach(question => {
      answers.push(this.buildAnswer(question));
    });
    this.surveyHeader.surveyAnswers = answers;
  }

  buildAnswer(question: SurveyQuestion): SurveyAnswer {
    const ans = new SurveyAnswer();
    ans.surveyQuestionId = question.id;
    ans.surveyQuestion = question;
    return ans;
  }

  submit() {
    this.errorsInForm = false;
    this.surveyHeader.surveyAnswers.forEach(answer => {
      this.errorsInForm = this.errorsInForm ||
        (answer.yesNoResponse == null && answer.surveyQuestion.isYesNo && answer.surveyQuestion.visible);
    });

    if (!this.errorsInForm) {
      this.surveyHeader.dateCompleted = new Date();
      this.surveyHeaderService.add(this.surveyHeader).subscribe(() => {
        this.goToHome();
      });
    }
  }

  cancel() {
    this.goToHome();
  }

  goToHome() {
    this.router.navigateByUrl('/');
  }

  goToSurveyList() {
    this.router.navigateByUrl('/surveyList');
  }

}
