import { AppConfigService } from './services/app-config.service';
export function configServiceFactory(appconfigService) {
    // return () => appconfigService.init(window['tempConfigStorage']);
    return function () { return appconfigService.loadAppConfig(); };
}
var ɵ0 = function (config) {
    // Easy to add or remove dependencies
    return config.appConfig.apiUrl;
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
