
<ng-select [items]="customers$ | async"
  class="custom"
  placeholder="Search Existing Customers"
  name="customer"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [loading]="loading"
  typeToSearchText="Please enter 1 or more characters to search"
  [typeahead]="customerInput$"
  [(ngModel)]="selectedCustomer"
  (ngModelChange)="customerSelectedModelChange()"
  [virtualScroll]="true"
  [disabled]="disabled"
  [required]="required"
>
  <ng-template ng-label-tmp let-item="item">
    {{item.fullName}} - {{item.address}}, {{item.city}} {{item.state}}, {{item.zip}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    {{item.fullName}} - {{item.address}}, {{item.city}} {{item.state}}, {{item.zip}}
  </ng-template>
</ng-select>