import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerListView, CustomerGenSvc, SortOption } from 'src/app/services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { CustomerFilterPipe } from '../../pipes/customer-filter.pipe';
import { CustomerService } from 'src/app/services/customer.service';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
  providers: [CustomerFilterPipe]
})
export class CustomerListComponent implements OnInit {
  @ViewChild('customerTable') customerTable: Table;

  cols: any[];
  customers: CustomerListView[] = [];
  getInactives = false;
  loading: boolean;
  searchTerm: string;
  totalRecords: number;
  previousLazyLoadRequest: any;

  canAddAndDelete = false;
  exportEnabled = false;

  constructor(
    private router: Router,
    private customerService: CustomerGenSvc,
    private authHelperService: AuthHelperService,
    public customCustomerService: CustomerService,
    private messageService: MessageService,
  ) {
    this.cols = [
      { field: 'fullName', header: 'Full Name', width: '13%' },
      { field: 'customerType', header: 'Customer Type', width: '13%' },
      { field: 'phoneNumber', header: 'Phone Number', width: '13%' },
      { field: 'address', subfield: 'street', header: 'Address', width: '14%' },
      { field: 'state', subfield: 'state', header: 'State', width: '15%' },
      { field: 'zip', subfield: 'zip', header: 'Zip', width: '9%' },
      { field: 'taxExempt', header: 'Tax', width: '8%'},
      { field: 'active', header: 'Status', width: '8%' },
      { header: '', width: '7%' }
    ];
  }

  ngOnInit() {
    this.customerTable.filterDelay = 500;
    // console.time('Get All');
    // this.customerSerivce.getAll().subscribe(qr => {
    //   this.datasource = qr;

    //   if (this.getInactives) {
    //     this.totalRecords = qr.length;
    //   } else {
    //     this.totalRecords = qr.filter(q => q.active).length;
    //   }
    //   console.timeEnd('Get All');
    // });
    this.loading = true;

    const claimNames = this.authHelperService.getDecodedAccessToken().claimNames;
    this.canAddAndDelete = claimNames.includes('View/Edit Customer Maint.');
  }

  showDeleteForCustomer(customer: CustomerListView): boolean {
    let showDelete = false;

    showDelete = this.authHelperService.getDecodedAccessToken().claimNames.includes('View/Edit Customer Maint.');

    return showDelete;
  }

  setCustomers() {
    this.customerTable.sortOrder = 0;
    this.customerTable. sortField = '';
    this.customerTable.reset();

    // if (this.getInactives) {
    //   this.customers = this.datasource;
    // } else {
    //   this.customers = this.datasource.filter(qr => qr.active);
    // }
  }

  loadRequestLazy(event: LazyLoadEvent) {
    const sortOption = this.getSortOption(event.sortField, event.sortOrder);

    this.loading = true;

    // tslint:disable-next-line: no-console
    console.time('Get Paginated');

    const req = {
      skip: event.first,
      take: event.rows,
      includeInactive: this.getInactives,
      sort: sortOption,
      filter: event.globalFilter ? event.globalFilter : ''
    };
    // If filter changes, set skip to 0. For some reason primeng doesn't do this. But it does for sort.
    if (this.previousLazyLoadRequest && this.previousLazyLoadRequest.filter !== req.filter) {
      req.skip = 0;
    }
    this.previousLazyLoadRequest = req;

    this.customerService.getPaginated(req.skip, req.take, req.includeInactive, req.sort, req.filter)
      .subscribe(res => {
        this.customers = res.results;
        this.totalRecords = res.totalResults;
        this.loading = false;
        // tslint:disable-next-line: no-console
        console.timeEnd('Get Paginated');
        // console.log('Get Paginated request and response', req, res);
        this.exportEnabled = true;
      },
      error => {
        this.loading = false;
        this.addErrorMessage('Could not load the customers, please refresh the page and try again.');
      });
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }

  addNew() {
    this.router.navigateByUrl('/customerLandingPage');
  }

  editCustomer(customer) {
    this.router.navigateByUrl('/customerLandingPage/' + customer.id);
  }

  deleteCustomer(customer) {
    if (confirm('Delete this customer?')) {
      this.customerService.delete(customer, customer.id.toString()).subscribe(() => {
        this.customers = this.customers.filter(obj => obj !== customer);
      });
    }
  }

  private getSortOption(field: string, order: number): SortOption {
    if (field === 'fullName') {
      if (order === 1) {
        return SortOption.CustomerName;
      } else {
        return SortOption.CustomerNameDesc;
      }
    } else if (field === 'customerType') {
      if (order === 1) {
        return SortOption.CustomerType;
      } else {
        return SortOption.CustomerTypeDesc;
      }
    } else if (field === 'phoneNumber') {
      if (order === 1) {
        return SortOption.CustomerPhone;
      } else {
        return SortOption.CustomerPhoneDesc;
      }
    } else if (field === 'address') {
      if (order === 1) {
        return SortOption.StreetAddress;
      } else {
        return SortOption.StreetAddressDesc;
      }
    } else if (field === 'state') {
      if (order === 1) {
        return SortOption.StateName;
      } else {
        return SortOption.StateNameDesc;
      }
    } else if (field === 'zip') {
      if (order === 1) {
        return SortOption.Zip;
      } else {
        return SortOption.ZipDesc;
      }
    } else if (field === 'taxExempt') {
      if (order === 1) {
        return SortOption.Tax;
      } else {
        return SortOption.TaxDesc;
      }
    } else if (field === 'active') {
      if (order === 1) {
        return SortOption.Status;
      } else {
        return SortOption.StatusDesc;
      }
    }

    return SortOption.None;
  }
}
