import { Component, OnInit } from '@angular/core';
import { AuthHelperService } from '../../services/auth-helper.service';
import { AuthenticationGenSvc, LoginRequest } from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isRequesting = false;
  loginRequest = new LoginRequest();
  redirectMessage: string;
  firmtimeRedirectMessage: string;
  firmtimeRedirectMessage2: string;
  contactPhoneNumber: string;
  rememberMe = false;
  submissionError = false;
  title = 'Login';

  constructor(
    private authHelperService: AuthHelperService,
    private authGenSvc: AuthenticationGenSvc,
    private route: ActivatedRoute,
    private router: Router,
    private configService: AppConfigService
  ) { }

  ngOnInit() {
    this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
    const isLoggedIn = this.authHelperService.isLoggedIn();
    if (isLoggedIn) {
      this.authHelperService.redirectToHome();
    } else {
      // Expired token
      if (this.authHelperService.getDecodedAccessToken) {
        localStorage.removeItem(this.authHelperService.authToken);
      }
    }

    const redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
    if (redirectUrl && redirectUrl !== 'firmtime' && redirectUrl !== 'quote') {
      const redirectAction = this.route.snapshot.queryParamMap.get('redirectUrl') === 'quote' ? 'Request for Quote' : 'action';

      this.redirectMessage = `To continue with your ${redirectAction} please login.
      If you do not know your login information please click "Need/Reset Password" below or contact Russell Tree at ${this.contactPhoneNumber}`;
    } else if (redirectUrl === 'firmtime' || redirectUrl === 'quote') {
      this.firmtimeRedirectMessage = '<div>We see that you have previously worked with us and we are happy to introduce our <b>new online portal</b> to manage your services!' +
                                     ' Log in below to finalize and pay for your firm-time quote request!</div>';
      this.firmtimeRedirectMessage2 = '<div><b>Need a password?</b> Click the "Need/Reset Password” link below to receive your password via email then return to this page to log in.</div>';
      this.title = 'Welcome Back!';
    }
  }

  login() {
    this.isRequesting = true;
    this.submissionError = false;
    this.authGenSvc.userAuthenticate(this.loginRequest)
      .subscribe(loginResponse => {
        this.isRequesting = false;
        if (loginResponse != null && loginResponse.Success && loginResponse.Token != null) {
          localStorage.setItem(this.authHelperService.authToken, loginResponse.Token);
          // this.authHelperService.updateNavbar(loginResponse.Token);

          // TODO will probably need to update the redirect to firmtime so it actually redirects to the RFQ
          // Right now it just goes to the homepage
          if (this.route.snapshot.queryParamMap.get('redirectUrl') && this.route.snapshot.queryParamMap.get('redirectUrl') !== 'firmtime') {
            this.router.navigateByUrl(this.route.snapshot.queryParamMap.get('redirectUrl'));
          } else {
            this.authHelperService.redirectToHome();
          }
        } else {
          this.submissionError = true;
        }
      }, error => {
        this.isRequesting = false;
        this.submissionError = true;
      });
  }

  createNewAccount() {
    // TODO create new account functionality
    alert('This feature is coming soon!');
  }

}
