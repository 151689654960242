import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ChemicalGenSvc, Chemical } from '../../services_autogenerated/generated_services';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-chemical-maintenance',
  templateUrl: './chemical-maintenance.component.html',
  styleUrls: ['./chemical-maintenance.component.css']
})
export class ChemicalMaintenanceComponent implements OnInit {
  @ViewChild('chemicalNameInput') chemicalNameInput: ElementRef;

  cols: any[];
  chemical: Chemical = new Chemical();
  chemicals: Chemical[] = [];
  saving: boolean;

  constructor(
    private chemicalService: ChemicalGenSvc,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'chemicalName', header: 'Chemical Name' },
      { field: 'tradeName', header: 'Trade Name' },
      { field: 'registrationNumber', header: 'Registration Number' },
      { field: 'mixRate', header: 'Mix Rate' },
      { header: '', width: '100px' }
    ];
  }

  ngOnInit() {
    this.chemicalService.getAll().subscribe(chemicals => {
      this.chemicals = chemicals;
    });
  }

  clear(ngForm: NgForm) {
    this.chemical = new Chemical();
    this.emptyInputs();
    ngForm.resetForm();
  }

  editChemical(chemical: Chemical) {
    this.chemical.chemicalName = chemical.chemicalName;
    this.chemical.tradeName = chemical.tradeName;
    this.chemical.registrationNumber = chemical.registrationNumber;
    this.chemical.mixRate = chemical.mixRate;
    this.chemical.active = chemical.active;
    this.chemical.id = chemical.id;

    this.chemicalNameInput.nativeElement.focus();
  }

  deleteChemical(chemical) {
    // remove chemical
    if (confirm('Are you sure you want to remove this record?')) {
      this.chemicalService.delete(chemical.id).subscribe(ok => {
        this.chemicals = this.chemicals.filter(c => c.id !== chemical.id);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the chemical, please check that the chemical exists and retry.'
        });
      });
    }
  }

  saveChemical(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach( key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    if (ngForm.valid) {
      this.saving = true;
      // Edit
      if (this.chemical.id) {
        this.chemicalService.update(this.chemical, this.chemical.id.toString()).subscribe(chemical => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the chemical, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      } else { // New Save
        this.chemical.active = true;
        this.chemicalService.add(this.chemical).subscribe(chemical => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the chemical, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      }
    }
  }

  doneSaving(ngForm: NgForm) {
    this.saving = false;
    ngForm.resetForm();
    this.emptyInputs();
    this.chemicalService.getAll().subscribe(chemicals => {
      this.chemicals = chemicals; // This is not a good idea but turbo table wont refresh. Less than 100 items in grid. Moving on.
    });
  }

  emptyInputs() {
    this.chemical = new Chemical();
    this.chemical.chemicalName = null;
    this.chemical.tradeName = null;
    this.chemical.registrationNumber = null;
    this.chemical.mixRate = null;
    this.chemical.active = null;
    this.chemical.id = null;
  }
}
