import { Component, OnInit, Input } from '@angular/core';
import { QuoteRequest } from 'src/app/services_autogenerated/generated_services';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-customer-firmtime-quote-row',
  templateUrl: './customer-firmtime-quote-row.component.html',
  styleUrls: ['./customer-firmtime-quote-row.component.css']
})
export class CustomerFirmtimeQuoteRowComponent implements OnInit {
  @Input() quote: QuoteRequest;
  firmtimeQuotePrice = 100;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigate() {
    const extras: NavigationExtras = {
      queryParams: { 'asAnonymous': 'true' }
    };
    localStorage.setItem('quoteRequestId', this.quote.id.toString());
    localStorage.setItem('zipCode', this.quote.address.zip.toString());
    this.router.navigate(['/ftSchedulePage/' + this.quote.representativeId], extras);
  }

}
