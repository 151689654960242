import { Pipe, PipeTransform } from '@angular/core';
import { Customer, CustomerGenSvc, CustomerContact } from '../services_autogenerated/generated_services';
import { WorkOrderFilterPipe } from './work-order-filter.pipe';
import { CustomerService } from '../services/customer.service';

@Pipe({
  name: 'customerFilter'
})
export class CustomerFilterPipe implements PipeTransform {
  constructor(private customCustomerService: CustomerService) {}

  transform(customers: Customer[], searchTerm: any): any {
    if (!customers) { return []; }
    if (!searchTerm) {return customers; }

    return customers.filter(customer => {
      return this.customCustomerService.customerSearch(searchTerm.toLowerCase(), customer);
    });
  }
}
