<div [id]="workOrder.quoteNumber" class="wo-card middle">
  <div class="flex-container"
    [ngStyle]="{
      'background-color': workOrder.address.geoColor,
      'color': workOrder.address.textColor,
      'border-radius': '5px',
      'margin': '.01em'
    }"
  >
    <div class="grow pad-item">
      {{customCustomerService.getCustomerFullName(workOrder.customer)}}
    </div>
    <div class="pad-item">
      {{workOrder.address.zip}}
    </div>
  </div>
  <div class="flex-container">
    <div class="grow pad-item">
      <a href="tel: {{customCustomerService.getPrimaryContact(workOrder.customer).phoneNumber}}" style="color: white;">
        {{customCustomerService.getPrimaryContact(workOrder.customer).phoneNumber | phoneNumber}}
      </a>
    </div>
    <div class="pad-item">
      
    </div>
  </div>
</div>