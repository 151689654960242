<div id="unavaiable-eqp-and-emps" (click)="dblclickHandler()" style="cursor: pointer;">
  <p-card class="bucket-card">
    <div class="ui-12">
      <span>{{eqpNumbers.join(", ")}}</span>
    </div>
    <div class="ui-12">
      <span *ngFor="let emp of employees, let i=index">
        {{emp.lastName}}{{i === employees.length - 1 ? '' : ', '}}
      </span>
    </div>
  </p-card>
</div>