<br>
<div>
  <div>
    <h4>Zip Code Assignments</h4>
  </div>
</div>
<br>
<div *ngIf="zipCodes">
  <button pButton label="Save All" class="ui-button-raised ui-button-success" (click)="saveAll()" [disabled]="!canSave || saving"></button>
  <div *ngFor="let rm of rms">
    <app-zip-code-assignment-item [rm]="rm" [zipCodes]="zipCodes"></app-zip-code-assignment-item>
  </div>
</div>