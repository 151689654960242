import { OnInit, Injectable, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { WorkWorkOrder,
  QuoteWorkOrder,
  SkillGenSvc,
  EquipmentTypeGenSvc,
  TreeTypeGenSvc,
  PriorityLevelGenSvc,
  PriorityLevel,
  WellnessTaskTemplate,
  WorkTaskTemplate,
  WellnessTask,
  WorkTask,
  WellnessWorkOrder} from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { MaskService } from 'src/app/services/mask.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { NgForm } from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';

@Injectable()
export abstract class BaseTaskCardMaintenanceComponent {
  @Input() abstract task: WellnessTask | WorkTask;
  @Input() abstract workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder;

  @Output() timeToClose = new EventEmitter<WellnessTask | WorkTask>();
  @Output() delete = new EventEmitter<WellnessTask | WorkTask>();
  @Output() cancelled = new EventEmitter<WellnessTask | WorkTask>();

  @ViewChildren(BlobManagerComponent) blobManagerComponents: QueryList<BlobManagerComponent>;

  canComplete: boolean;
  canEditUploads: boolean;
  currencyMask: any;
  isEditable: boolean;
  isQuoteTask: boolean;
  isSaving: boolean;
  priorites: PriorityLevel[];
  statuses: any[];
  taxCodes: any[];
  treeTypeOptions: any[];
  uploadId: number;
  taskUnmodified: WellnessTask | WorkTask;

  constructor(
    public skillService: SkillGenSvc,
    public equipmentTypeService: EquipmentTypeGenSvc,
    public treeTypeService: TreeTypeGenSvc,
    public priorityLevelService: PriorityLevelGenSvc,
    public messageService: MessageService,
    public maskService: MaskService,
    public authHelper: AuthHelperService) {
    }

  abstract completeTask(): void;

  abstract deleteButtonPressed(): void;

  abstract saveTask(ngForm: NgForm): void;

  cancelButtonPressed(): void {
    this.resetTaskToOriginal();
    this.cancelled.emit(this.task);
  }

  copyTask(): void {
    this.taskUnmodified = cloneDeep(this.task, true);
  }

  resetTaskToOriginal(): void {
    this.task = this.taskUnmodified;
  }

  onTemplateSelect(template: WellnessTaskTemplate | WorkTaskTemplate): void {
    this.task.cost = template.cost;
    this.task.description = template.description;
    this.task.hours = template.hours;
    this.task.name = template.name;
    this.task.price = template.price;

    if (this.task instanceof WellnessTask && template instanceof WellnessTaskTemplate) {
      this.task.growingDegreeDay = template.growingDegreeDay;
    }
  }

  saveBlobs(task: WellnessTask | WorkTask): void {
    // Currently only 1 blob manager should be not view only. If that changes, you'll need to upate this and loop through all the
    // ones that are not view only and save them.
    const blobManagerComponent: BlobManagerComponent = this.blobManagerComponents.find(blobComponent => blobComponent.viewOnly === false);

    if (blobManagerComponent) {
      blobManagerComponent.parentRecordId = task.id;
      blobManagerComponent.save().subscribe(() => {
        this.timeToClose.emit(task);
        this.isSaving = false;
      });
    } else {
      this.timeToClose.emit(task);
    }
  }

  setUploadId() {
    // When you are editing a task on a quote, use the task id
    // when editing a task on a WO, use the primordial task id, since WO task's have a different task id from the Quote's task
    // (So both tasks look at the same set of uploads)
    this.uploadId = this.workOrder instanceof QuoteWorkOrder ? this.task.id : this.task.primordialTaskId;
  }
}
