<br>
<div>
  <div>
    <h4>Zip Code to Day Assignments</h4>
  </div>
</div>
<br>
<div *ngIf="employee && employeeIsRM">
  <div class="p-grid">
    <div class="p-col-2">
      <h6>Sunday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(0)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of sundayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != sundayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, sundayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <h6>Monday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(1)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of mondayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != mondayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, mondayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <h6>Tuesday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(2)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of tuesdayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != tuesdayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, tuesdayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <h6>Wednesday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(3)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of wednesdayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != wednesdayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, wednesdayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <h6>Thursday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(4)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of thursdayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != thursdayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, thursdayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <h6>Friday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(5)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of fridayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != fridayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, fridayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <h6>Saturday</h6>
      <button pButton icon="pi pi-plus" label="Add New Time Slot" (click)="addNewTimeSlot(6)"></button>
    </div>
    <div class="p-col-10" *ngFor="let timeSlot of saturdayTimeSlots" [ngClass]="{'p-offset-2': timeSlot != saturdayTimeSlots[0]}">
      <app-day-zip-code-item
        [zipCodeAssignments]="employee.zipCodeAssignments"
        [timeSlot]="timeSlot"
        (deletedItem)="handleDeletedItem(timeSlot, saturdayTimeSlots)"
      ></app-day-zip-code-item>
    </div>
  </div>
</div>


<div *ngIf="employee && !employeeIsRM">
  Not a Regional Manager
</div>