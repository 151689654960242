<p-card [ngClass]="{'taskGrayOverlay': isDisabled }">
  <div style="padding: .3em">
    <div class="task-name">
      {{task.name}}: {{treeTypes}}<span *ngIf="task.isGoBack"> - GB</span>
    </div>
    <div class="task-info" *ngIf="!task.isGoBack">
      <span *ngIf="showPrice">{{task.price | currency}} <i class="pi pi-circle-on" style="font-size: .5em;"></i> </span>{{task.hours}} {{unit}}
      <i class="pi pi-check middle-this" *ngIf="task.completionStatus == 'Completed'" pTooltip="Task is Complete"></i>
      <i class="pi pi-info-circle middle-this" *ngIf="task.completionStatus == 'Unable to be Completed'" pTooltip="Task will not be completed"></i>
    </div>
    <div class="task-info" *ngIf="task.isGoBack">
      <span>$0.00 <i class="pi pi-circle-on" style="font-size: .5em;"></i> </span>{{task.goBackHoursEstimate}} {{goBackUnit}}
      <i class="pi pi-check middle-this" *ngIf="task.completionStatus == 'Completed'" pTooltip="Task is Complete"></i>
      <i class="pi pi-info-circle middle-this" *ngIf="task.completionStatus == 'Unable to be Completed'" pTooltip="Task will not be completed"></i>
    </div>
  </div>
</p-card>