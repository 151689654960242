import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { CustomerGenSvc, CustomerListView, Customer, BasicWorkOrderViewModel, WorkOrderGenSvc } from 'src/app/services_autogenerated/generated_services';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-basic-work-order-dropdown',
  templateUrl: './basic-work-order-dropdown.component.html',
  styleUrls: ['./basic-work-order-dropdown.component.css']
})
export class BasicWorkOrderDropdownComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() required: boolean = true;
  @Input() existingSelectionQuoteNumber: string;

  // Emit the id of the selected customer
  @Output() workOrderSelected = new EventEmitter<BasicWorkOrderViewModel>();

  workOrders$: Observable<BasicWorkOrderViewModel[]>;
  workOrderInput$ = new Subject<string>();

  loading: boolean;
  selectedWorkOrder: BasicWorkOrderViewModel;

  constructor(private workOrderService: WorkOrderGenSvc) { }

  ngOnInit() {
    this.loadWorkOrders();
    
    if (this.existingSelectionQuoteNumber) {
      this.workOrderService.getByFilter(this.existingSelectionQuoteNumber).subscribe(res => {
        this.selectedWorkOrder = res[0];
      });
    }
  }

  setWorkOrderFromNew(quoteNumber: string) {
    this.workOrderService.getByFilter(quoteNumber).subscribe(res => {
      this.selectedWorkOrder = res[0];
    });
  }
  
  trackByFn(workOrder: BasicWorkOrderViewModel) {
    return workOrder.quoteNumber;
  }

  workOrderSelectedModelChange() {
    if (this.selectedWorkOrder) {
      this.workOrderSelected.emit(this.selectedWorkOrder);
    } else {
      this.workOrderSelected.emit(null);
    }
  }

  private loadWorkOrders() {
    this.workOrders$ = concat(
      of([]), // default items
      this.workOrderInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => {
          if (term) {
            return this.workOrderService.getByFilter(term).pipe(
              catchError(err => {
                console.log(err);
                return of([]) // empty list on error
              }),
              tap(() => this.loading = false)
            );
          } else {
            this.loading = false;
            return of([]); // empty list on invalid search
          }
        })
      )
    );
  }
}
