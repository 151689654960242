import { Injectable } from '@angular/core';
import {
    WellnessWorkOrder,
    WellnessWorkOrderGenSvc,
    WorkWorkOrder,
    WorkWorkOrderGenSvc,
    WellnessTaskCompletionStatus,
    WorkTaskCompletionStatus,
    WellnessWorkOrderCompletionStatus,
    WorkWorkOrderCompletionStatus,
    ScheduledBucketDTO,
    LogGenSvc,
    LogViewModel,
    LogViewModelLevel} from '../services_autogenerated/generated_services';
import { BucketListDay } from '../models/bucketListDay';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkOrdersPanelComponent } from '../components/work-orders-panel/work-orders-panel.component';
import { MessageWrapperService } from './message-wrapper.service';

@Injectable({
    providedIn: 'root'
})
export class DragAndDropService {

    /** Angular Only Service **/

    bucketListDayList: BucketListDay[];
    dayBucketIndexRecords: ScheduledBucketDTO[];
    dragStartBucket: ScheduledBucketDTO;
    workOrderPanel: WorkOrdersPanelComponent;

    public allCDKWorkOrderListIds: string[] = ['workOrdersPanelList'];
    public allCDKEquipmentListIds: string[] = ['equipmentPanelList'];
    public allCDKEmployeeListIds: string[] = ['employeePanelList'];
    public bucketWOListIds: string[] = [];

    private bucketUpdater = new BehaviorSubject<ScheduledBucketDTO>(null);
    private workOrderPanelUpdater = new BehaviorSubject<WellnessWorkOrder | WorkWorkOrder>(null);
    private workOrderUpdater = new BehaviorSubject<WellnessWorkOrder | WorkWorkOrder>(null);

    dragStartWO: WellnessWorkOrder | WorkWorkOrder;

    constructor(
        private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
        private workWorkOrderService: WorkWorkOrderGenSvc,
        private logService: LogGenSvc,
        private messageService: MessageWrapperService
    ) {
        this.initializeCDKLists();
    }

    setBucketListDayList(bucketListDayList: BucketListDay[]) {
        this.bucketListDayList = bucketListDayList;
    }

    setWorkOrderPanel(workOrderPanel: WorkOrdersPanelComponent) {
        this.workOrderPanel = workOrderPanel;
    }

    setDayBucketIndexRecords(dayBucketIndexRecords: ScheduledBucketDTO[]) {
        this.dayBucketIndexRecords = dayBucketIndexRecords;
    }

    initializeCDKLists() {
        this.allCDKWorkOrderListIds = ['workOrdersPanelList'];
        this.allCDKEquipmentListIds = ['equipmentPanelList'];
        this.allCDKEmployeeListIds = ['employeePanelList'];
    }

    addBucketToCDKLists(bucket: ScheduledBucketDTO) {
        this.bucketWOListIds.push(`bucketWorkOrderList-${bucket.id}`);
        this.allCDKWorkOrderListIds.push(`bucketWorkOrderList-${bucket.id}`);
        this.allCDKEmployeeListIds.push(`bucketEmployeeList-${bucket.id}`);
        this.allCDKEquipmentListIds.push(`bucketEquipmentList-${bucket.id}`);
    }

    addWorkOrderTaskListTOCDKList(workOrder) {
        this.allCDKWorkOrderListIds.push(`woTaskList-${workOrder.id}`);
    }

    getBucketUpdater(): Observable<ScheduledBucketDTO> {
        return this.bucketUpdater.asObservable();
    }

    emitBucketUpdateByBucketId(bucketId: number) {
        const bucket = this.dayBucketIndexRecords.find(r => r.id === bucketId);
        this.bucketUpdater.next(bucket);
    }

    /**
   * Used to update the provided bucket in the UI. Sets the progress bar, validation, employee list, and equipment list.
   * Ultimately gets subscribed to in base-calendar.component.ts
   * @param {ScheduledBucketDTO=} bucket Bucket that needs updated
   */
    emitBucketUpdate(bucket: ScheduledBucketDTO) {
        this.bucketUpdater.next(bucket);
    }

    getWorkOrderPanelUpdater(): Observable<WellnessWorkOrder | WorkWorkOrder> {
        return this.workOrderPanelUpdater.asObservable();
    }

    emitWorkOrderToReAdd(workOrder: WellnessWorkOrder | WorkWorkOrder) {
        this.workOrderPanelUpdater.next(workOrder);
    }

    getWorkOrderUpdater(): Observable<WellnessWorkOrder | WorkWorkOrder> {
        return this.workOrderUpdater.asObservable();
    }

    /**
   * Used to update the provided WO card in the UI. Sets the scheduled/unschedule task info and the price/hours summary on the bottom.
   * Ultimately gets subscribed to in base-wellness-work-order-card.component.ts and base-work-work-order-card-component.ts
   * @param {WellnessWorkOrder | WorkWorkOrder=} workOrder The WO that needs to be updated
   */
    emitWorkOrderToUpdate(workOrder: WellnessWorkOrder | WorkWorkOrder) {
        this.workOrderUpdater.next(workOrder);
    }

    setDragStartBucket(previousBucket: ScheduledBucketDTO) {
        this.dragStartBucket = previousBucket;
    }

    // This update method is here so that the various drop zones can update a bucket and know which index it should be saved as
    // public updateBucketInDatabase(bucket: ScheduledBucketDTO) {
    //     const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
    //     this.scheduledItemBucketService.update(bucket, bucket.id.toString())
    //         .subscribe(x => {
    //             this.dayBucketIndexRecords[recordIndex] = x;
    //         },
    //         error => {
    //             this.messageService.add({
    //                 severity: 'error',
    //                 summary: 'Error Message',
    //                 detail: 'Could not save the scheduled bucket, please refresh and retry.'
    //             });
    //         });
    // }

    // This remove method is here so that the various drop zones can remove a WO from scheduling when needed
    removeWorkOrderFromScheduling(workOrder: WellnessWorkOrder | WorkWorkOrder, bucketId: number) {
        // Only unschedule tasks that match the bucket the wo came from
        if (workOrder instanceof WellnessWorkOrder) {
            workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Ready_to_Schedule;

            workOrder.workOrderWellnessTasks.forEach(task => {
                if (!task.wellnessTask.isGoBack && task.wellnessTask.currentBucketId === bucketId) {
                    task.wellnessTask.scheduleDateFrom = null;
                    task.wellnessTask.scheduleDateTo = null;
                    task.wellnessTask.currentBucketId = null;
                    task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                } else if (task.wellnessTask.isGoBack && task.wellnessTask.goBackBucketId === bucketId) {
                    task.wellnessTask.goBackBucketId = null;
                    task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                }
            });

            this.wellnessWorkOrderService.unscheduleWorkOrder(workOrder.id, bucketId).subscribe(() => {
                this.afterWorkOrderSubscribe(workOrder);
            }, error => {
                const logMessage = new LogViewModel({
                    level: LogViewModelLevel.Error,
                    message: error.stack ? error.message + ' -- Stack: ' + error.stack : error.message,
                    entryDate: new Date()
                  });
                this.logService.logSchedule(logMessage).subscribe();
                this.messageService.addErrorMessage('Unable to unschedule WO, please refresh and retry.', error);
            });
        }

        if (workOrder instanceof WorkWorkOrder) {
            // Update WO on front end
            workOrder.completionStatus = WorkWorkOrderCompletionStatus.Ready_to_Schedule;

            workOrder.workOrderWorkTasks.forEach(task => {
                // Update tasks on front end
                if (!task.workTask.isGoBack && task.workTask.currentBucketId === bucketId) {
                    task.workTask.scheduleDateFrom = null;
                    task.workTask.scheduleDateTo = null;
                    task.workTask.currentBucketId = null;
                    task.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                } else if (task.workTask.isGoBack && task.workTask.goBackBucketId === bucketId) {
                    task.workTask.goBackBucketId = null;
                    task.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                }
            });

            // Update WO and tasks on back end.
            this.workWorkOrderService.unscheduleWorkOrder(workOrder.id, bucketId).subscribe(() => {
                this.afterWorkOrderSubscribe(workOrder)
            }, error => {
                const logMessage = new LogViewModel({
                    level: LogViewModelLevel.Error,
                    message: error.stack ? error.message + ' -- Stack: ' + error.stack : error.message,
                    entryDate: new Date()
                  });
                this.logService.logSchedule(logMessage).subscribe();
                this.messageService.addErrorMessage('Unable to unschedule WO, please refresh and retry.', error);
            }
            );
        }


        this.emitWorkOrderToReAdd(workOrder);
    }

    private afterWorkOrderSubscribe(workOrder: WellnessWorkOrder | WorkWorkOrder) {
        this.emitWorkOrderToUpdate(workOrder);
    }
}
