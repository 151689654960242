import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Equipment } from '../../services_autogenerated/generated_services';
import { EquipmentGenSvc } from '../../services_autogenerated/generated_services';
import { SpecificationService } from 'src/app/services/specification.service';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent implements OnInit {

  cols: any[];
  equipment: EquipmentService[] = [];
  getInactives = true;

  constructor(
    private router: Router,
    private equipmentService: EquipmentGenSvc,
    public specificationService: SpecificationService
  ) {
    this.cols = [
      { field: 'number', header: 'Number', width: '7%'},
      { field: 'name', header: 'Name', width: '38%'  },
      { field: 'equipmentType.type', subfield: 'type', header: 'Type', width: '16%'  },
      // { field: 'baseFee', header: 'Hourly Cost', width: '16%'  },
      { field: 'inService', header: 'Status', width: '16%'  },
      { header: '', width: '7%'  }
    ];
  }
  ngOnInit() {
    this.getEquipment();
  }

  // I know this isn't the most efficient if they flip flop a lot, but they're probably not ever going to flip flop
  getEquipment() {
    if (this.getInactives) {
      this.equipmentService.getAll().subscribe(equipment => {
        this.equipment = equipment as EquipmentService[];
        this.setEquipmentServiceInfo();
      });
    } else {
      this.equipmentService.getEquipmentInService().subscribe(equipment => {
        this.equipment = equipment as EquipmentService[];
        this.setEquipmentServiceInfo();
      });
    }
  }

  setEquipmentServiceInfo() {
    this.equipment.forEach(equip => equip.inService = this.specificationService.EquipmentInService(equip));
  }

  addNew() {
    this.router.navigateByUrl('/equipmentMaintenance');
  }

  editEquipment(equipment) {
    this.router.navigateByUrl('/equipmentMaintenance/' + equipment.id);
  }

  deleteEquipment(equipment) {
    if (confirm('Are you sure you want to permanently delete this equipment?')) {
      this.equipmentService.delete(equipment.id).subscribe(() => {
        this.equipment = this.equipment.filter(obj => obj !== equipment);
      });
    }
  }
}

class EquipmentService extends Equipment {
  inService: boolean;
}
