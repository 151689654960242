import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageWrapperService {

  constructor(private messageService: MessageService) { }
  /**
   * Used to display a standard success message using PrimeNG's messages.
   * @param {string=} message Message to show to the user
   * @param {string=} title Title, defaults to success but can be overridden
   */
  public addSuccessMessage(message: string, title: string = 'Success') {
    this.messageService.add({
      severity: 'success',
      summary: title,
      detail: message
    });
  }

  /**
   * Used to display an error message using PrimeNG's messages. Will use message if there is no
   * error response from the error param.
   * @param {string=} message Default message
   * @param {any=} error Error from the API, will unwrap and get the error response if available
   * @param {boolean=} sticky If message should remain until closed by user or not, 
   * defaults to false so it auto closes
   */
  public addErrorMessage(message: string, error, sticky: boolean = false) {
    if (error && error.response && JSON.parse(error.response).Message) {
      message = JSON.parse(error.response).Message;
    }

    if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
      message = JSON.parse(error.response).ExceptionMessage;
    }

    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      sticky: sticky
    });
  }

  public clear(id: string) {
    this.messageService.clear(id);
  }
}
