/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-skill-upload.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../blob-manager/blob-manager.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../blob-manager/blob-manager.component";
import * as i5 from "../../services_autogenerated/generated_services";
import * as i6 from "../../services/auth-helper.service";
import * as i7 from "../../services/blob-manager-wrapper.service";
import * as i8 from "../../services/app-config.service";
import * as i9 from "../../services/message-wrapper.service";
import * as i10 from "@angular/common/http";
import * as i11 from "./employee-skill-upload.component";
var styles_EmployeeSkillUploadComponent = [i0.styles];
var RenderType_EmployeeSkillUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeSkillUploadComponent, data: {} });
export { RenderType_EmployeeSkillUploadComponent as RenderType_EmployeeSkillUploadComponent };
export function View_EmployeeSkillUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { blobManagerComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-blob-manager", [], null, null, null, i2.View_BlobManagerComponent_0, i2.RenderType_BlobManagerComponent)), i1.ɵprd(14336, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(3, 4308992, [[1, 4]], 0, i4.BlobManagerComponent, [i5.BlobGenSvc, i6.AuthHelperService, i7.BlobManagerWrapperService, i1.ChangeDetectorRef, i8.AppConfigService, i9.MessageWrapperService, i10.HttpClient], { containerName: [0, "containerName"], parentRecordId: [1, "parentRecordId"], viewOnly: [2, "viewOnly"], uploadLabel: [3, "uploadLabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "employee-skill"; var currVal_1 = _co.employeeSkill.id; var currVal_2 = _co.viewOnly; var currVal_3 = "Doc"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_EmployeeSkillUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-skill-upload", [], null, null, null, View_EmployeeSkillUploadComponent_0, RenderType_EmployeeSkillUploadComponent)), i1.ɵdid(1, 114688, null, 0, i11.EmployeeSkillUploadComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeSkillUploadComponentNgFactory = i1.ɵccf("app-employee-skill-upload", i11.EmployeeSkillUploadComponent, View_EmployeeSkillUploadComponent_Host_0, { employeeSkill: "employeeSkill", viewOnly: "viewOnly" }, {}, []);
export { EmployeeSkillUploadComponentNgFactory as EmployeeSkillUploadComponentNgFactory };
