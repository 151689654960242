<div [id]="quoteRequest.id" 
  [ngClass]="{
              'itemStyle': true, 
              'anytime': quoteRequest.quoteRequestType == 'Anytime', 
              'virtual': quoteRequest.quoteRequestType == 'Virtual', 
              'middle': true, 
              'urgent': quoteRequest.isUrgent
            }"
>
  <div class="flex-container"
    [ngStyle]="{
      'background-color': quoteRequest.address.geoColor,
      'color': quoteRequest.address.textColor,
      'border-radius': '5px',
      'margin': '.01em'
    }"
  >
    <div class="grow pad-item">
      {{customCustomerService.getCustomerFullName(quoteRequest.customer)}}
    </div>
    <div class="pad-item">
      {{quoteRequest.address.zip}}
    </div>
  </div>
  <div class="flex-container">
    <div class="grow pad-item">
      <a href="tel: {{customCustomerService.getPrimaryContact(quoteRequest.customer).phoneNumber}}">
        {{customCustomerService.getPrimaryContact(quoteRequest.customer).phoneNumber | phoneNumber}}
      </a>
    </div>
    <div class="pad-item">
      {{dateDifference}} {{unit}}
    </div>
  </div>
</div>
