import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ChemicalApplicationLocation, ChemicalApplicationLocationGenSvc } from '../../services_autogenerated/generated_services';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-chemical-location-maintenance',
  templateUrl: './chemical-location-maintenance.component.html',
  styleUrls: ['./chemical-location-maintenance.component.css']
})
export class ChemicalLocationMaintenanceComponent implements OnInit {
  @ViewChild('locationInput') locationInput: ElementRef;

    cols: any[];
    location: ChemicalApplicationLocation = new ChemicalApplicationLocation();
    locations: ChemicalApplicationLocation[] = [];
    saving: boolean;

    constructor(
      private locationService: ChemicalApplicationLocationGenSvc,
      private messageService: MessageService
    ) {
      this.cols = [
        { field: 'location', header: 'Location Name' },
        { header: '', width: '100px' }
      ];
    }

    ngOnInit() {
      this.locationService.getAll().subscribe(locations => {
        this.locations = locations;
      });
    }

    clear(ngForm: NgForm) {
      this.location = new ChemicalApplicationLocation();
      this.emptyInputs();
      ngForm.resetForm();
    }

    editChemicalApplicationLocation(location: ChemicalApplicationLocation) {
      this.location.location = location.location;
      this.location.active = location.active;
      this.location.id = location.id;

      this.locationInput.nativeElement.focus();
    }

    deleteChemicalApplicationLocation(location) {
      // remove location
      if (confirm('Are you sure you want to remove this record?')) {
        this.locationService.delete(location.id).subscribe(ok => {
          this.locations = this.locations.filter(c => c.id !== location.id);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Deleting',
            detail: 'Could not delete the location, please check that the location exists and retry.'
          });
        });
      }
    }

    saveChemicalApplicationLocation(ngForm: NgForm) {
      Object.keys(ngForm.controls).forEach( key => {
        ngForm.controls[key].markAsTouched();
        ngForm.controls[key].markAsDirty();
      });
      if (ngForm.valid) {
        // Edit
        if (this.location.id) {
          this.locationService.update(this.location, this.location.id.toString()).subscribe(location => {
            this.doneSaving(ngForm);
          }, error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: 'Could not save the location, please check that all fields are valid and retry.'
            });
            this.doneSaving(ngForm);
          });
        } else { // New Save
          this.location.active = true;
          this.locationService.add(this.location).subscribe(location => {
            this.doneSaving(ngForm);
          }, error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: 'Could not save the location, please check that all fields are valid and retry.'
            });
            this.doneSaving(ngForm);
          });
        }
      }
    }

    doneSaving(ngForm: NgForm) {
      this.saving = false;
      ngForm.resetForm();
      this.emptyInputs();
      this.locationService.getAll().subscribe(locations => {
        this.locations = locations; // This is not a good idea but turbo table wont refresh. Less than 100 items in grid. Moving on.
      });
    }

    emptyInputs() {
      this.location = new ChemicalApplicationLocation();
      this.location.location = null;
      this.location.active = null;
      this.location.id = null;
    }
}
