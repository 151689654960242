import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseWellnessViewComponent } from '../base-wellness-view/base-wellness-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { ActivatedRoute } from '@angular/router';
var SchedulingMultidayComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SchedulingMultidayComponent, _super);
    function SchedulingMultidayComponent(dragAndDropService, route) {
        var _this = _super.call(this, dragAndDropService) || this;
        _this.route = route;
        return _this;
    }
    SchedulingMultidayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.type = this.route.snapshot.paramMap.get('type');
        this.route.params.subscribe(function (val) {
            _this.changeType();
        });
        this.header = this.type === 'wellness' ? 'Tree Wellness Multi-day Jobs' : 'Tree Work Multi-day Jobs';
    };
    SchedulingMultidayComponent.prototype.changeType = function () {
        this.type = this.route.snapshot.paramMap.get('type');
        this.header = this.type === 'wellness' ? 'Tree Wellness Multi-day Jobs' : 'Tree Work Multi-day Jobs';
    };
    return SchedulingMultidayComponent;
}(BaseWellnessViewComponent));
export { SchedulingMultidayComponent };
