import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EquipmentType, EquipmentTypeGenSvc } from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-equipment-type-maintenance',
  templateUrl: './equipment-type-maintenance.component.html',
  styleUrls: ['./equipment-type-maintenance.component.css']
})
export class EquipmentTypeMaintenanceComponent implements OnInit {
  @ViewChild('typeNameInput') typeNameInput: ElementRef;

  cols: any[];
  equipmentType: EquipmentType = new EquipmentType();
  equipmentTypes: EquipmentType[] = [];
  saving: boolean;

  constructor(
    private equipmentTypeSerivce: EquipmentTypeGenSvc,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'type', header: 'Type Name', width: '78%' },
      { field: 'abbreviation', header: 'Abbreviation', width: '15%' },
      { header: '', width: '7%' }
    ];
  }

  ngOnInit() {
    this.equipmentType.active = true;
    this.equipmentTypeSerivce.getActive().subscribe(equipTypes => {
      this.equipmentTypes = equipTypes;
    });
  }

  clear(ngForm: NgForm) {
    this.equipmentType = new EquipmentType();
    ngForm.resetForm();
    this.equipmentType.active = true;
    this.emptyInputs();
  }

  edit(equipType) {
    this.equipmentType.id = equipType.id;
    this.equipmentType.type = equipType.type;
    this.equipmentType.active = equipType.active;
    this.equipmentType.abbreviation = equipType.abbreviation;

    this.typeNameInput.nativeElement.focus();
  }

  delete(equipmentType) {
    // remove priority
    if (confirm('Are you sure you want to remove this record?')) {
      this.equipmentTypeSerivce.delete(equipmentType.id).subscribe(ok => {
        this.equipmentTypes = this.equipmentTypes.filter(p => p.id !== equipmentType.id);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the equipment type, please check that the equipment type exists and retry.'
        });
      });
    }
  }

  save(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      this.saving = true;
      // Edit
      if (this.equipmentType.id) {
        this.equipmentTypeSerivce.update(this.equipmentType, this.equipmentType.id.toString()).subscribe(equipmentType => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the equipment type, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      } else { // New Save
        this.equipmentType.active = true;
        this.equipmentTypeSerivce.add(this.equipmentType).subscribe(equipmentType => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the equipment type, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      }
    }
  }

  doneSaving(ngForm: NgForm) {
    this.saving = false;
    ngForm.resetForm();
    this.emptyInputs();
    this.equipmentTypeSerivce.getActive().subscribe(equipmentTypes => {
      this.equipmentTypes = equipmentTypes; // This is not a good idea but turbo table wont refresh. Less than 100 items in grid. Moving on.
    });
  }

  emptyInputs() {
    this.equipmentType = new EquipmentType();
    this.equipmentType.type = null;
    this.equipmentType.active = true;
  }
}
