<div class="filterBar" [class.dayView]="isDayView">
  <span class="ui-float-label">
    <input id="float-input-workorder-search" type="text" class="float-input" pInputText [(ngModel)]="searchTerm" (ngModelChange)="searchInput.next($event)">
    <label for="float-input-workorder-search">
      <i class="pi pi-search"></i>
      Search
    </label>
  </span>

  <div class="filterBoxContainer">
    <div class="filterBox" [class.dayView]="isDayView">
      <button type="text" (click)="opDueDate.toggle($event)">Due Date <i class="pi pi-caret-down"></i></button>
      <p-overlayPanel #opDueDate appendTo="body">
          <h6 class="first">Enabled <p-inputSwitch [(ngModel)]="shouldFilterByDueDate"
                  (ngModelChange)="filterWorkOrders()" [style]="{'font-size': '10px', 'vertical-align': 'middle'}"></p-inputSwitch>
          </h6>
          Show Work Orders with due date
          <p>after <input type="date" pInputText style="width:150px; height:30px;"
                [max]="dueDateFilterToDate" oninput="validity.valid||(value='');"
                [(ngModel)]="dueDateFilterFromDate" (ngModelChange)="filterWorkOrders()"></p>
          <p>and </p>
          <p>before <input type="date" pInputText style="width:150px; height:30px;"
                [min]="dueDateFilterFromDate" oninput="validity.valid||(value='');"
                [(ngModel)]="dueDateFilterToDate" (ngModelChange)="filterWorkOrders()"></p>
      </p-overlayPanel>
    </div>
    <div class="filterBox" [class.dayView]="isDayView">
      <button type="text" (click)="opGeoRegion.toggle($event)">Geo Region <i
              class="pi pi-caret-down"></i></button>
      <p-overlayPanel #opGeoRegion appendTo="body">
          <h6 class="first">Enabled <p-inputSwitch [(ngModel)]="shouldFilterByGeoRegion"
                  (ngModelChange)="filterWorkOrders()" [style]="{'font-size': '10px', 'vertical-align': 'middle'}"></p-inputSwitch>
          </h6>
          Show Work Orders within <input type="number" min="0" oninput="validity.valid||(value='');" pInputText
              style="width:60px; height:30px;" [(ngModel)]="geoRegionSearchRadius"
              (ngModelChange)="filterWorkOrders()">
          <p>miles of Quote Number <input pInputText
                  style="width:85px; height:30px;" [(ngModel)]="geoRegionWorkOrderNumber"
                  (ngModelChange)="filterWorkOrders()"></p>
          <div class="alert alert-danger" *ngIf="geoRegionWorkOrderFindError">{{geoRegionWorkOrderFindError}}
          </div>
          <div class="alert alert-danger" *ngIf="geoRegionWorkOrderAddressError">
              {{geoRegionWorkOrderAddressError}}</div>
      </p-overlayPanel>
    </div>
    <div class="filterBox" [class.dayView]="isDayView">
      <button type="text" (click)="opPriority.toggle($event)">Priority <i class="pi pi-caret-down"></i></button>
      <p-overlayPanel #opPriority appendTo="body">
        <h6 class="first">Enabled <p-inputSwitch [(ngModel)]="shouldFilterByPriority"
                (ngModelChange)="filterWorkOrders()" [style]="{'font-size': '10px', 'vertical-align': 'middle'}"></p-inputSwitch>
        </h6>
        Show items with Priority:
        <p-multiSelect [options]="priorityLevels" [(ngModel)]="selectedPriorities" optionLabel="status"
        (ngModelChange)="filterWorkOrders()" [panelStyle]="{minWidth:'12em'}">
        <ng-template let-value pTemplate="selectedItems">
                <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                <span>{{val.status}}</span>
                </div>
                <span *ngIf="!value || value.length === 0"
                class="ui-multiselected-empty-token ui-corner-all">Choose</span>
        </ng-template>
        <ng-template let-item pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px">{{item.value.status}}</div>
        </ng-template>
        </p-multiSelect>
      </p-overlayPanel>
    </div>
    <div class="filterBox" [class.dayView]="isDayView" *ngIf="typeOfWorkOrders === 'wellness'">
      <button type="text" (click)="opGDD.toggle($event)">GDD <i class="pi pi-caret-down"></i></button>
      <p-overlayPanel #opGDD appendTo="body">
          <h6 class="first">Enabled <p-inputSwitch [(ngModel)]="shouldFilterByGDD"
                  (ngModelChange)="filterWorkOrders()" [style]="{'font-size': '10px', 'vertical-align': 'middle'}"></p-inputSwitch>
          </h6>
          Show Work Orders with Tasks having GDD
          <p>greater than <input type="number" min="0" oninput="validity.valid||(value='');" pInputText
                  style="width:150px; height:30px;" [(ngModel)]="gddStart" (ngModelChange)="filterWorkOrders()">
          </p>
          <p>and </p>
          <p>less than <input type="number" min="0" oninput="validity.valid||(value='');" pInputText
                  style="width:150px; height:30px;" [(ngModel)]="gddEnd" (ngModelChange)="filterWorkOrders()"></p>
      </p-overlayPanel>
    </div>
    <div class="filterBox" [class.dayView]="isDayView">
        <button type="text" (click)="opRM.toggle($event)">RM <i class="pi pi-caret-down"></i></button>
        <p-overlayPanel #opRM appendTo="body">
                <h6 class="first">Enabled <p-inputSwitch [(ngModel)]="shouldFilterByRM"
                        (ngModelChange)="filterWorkOrders()" [style]="{'font-size': '10px', 'vertical-align': 'middle'}"></p-inputSwitch>
                </h6>
                        Show items with RM:<br/>
                        <p-multiSelect [options]="rms" [(ngModel)]="selectedRMs" optionLabel="fullName"
                                (ngModelChange)="filterWorkOrders()" [panelStyle]="{minWidth:'12em'}">
                        <ng-template let-value pTemplate="selectedItems">
                                <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                                        <span>{{val.fullName}}</span>
                                </div>
                                <span *ngIf="!value || value.length === 0"
                                        class="ui-multiselected-empty-token ui-corner-all">Choose</span>
                        </ng-template>
                        <ng-template let-emp pTemplate="item">
                                <div style="font-size:14px;float:right;margin-top:4px">{{emp.value.fullName}}</div>
                        </ng-template>
                </p-multiSelect>
        </p-overlayPanel>
     </div>
  </div>
</div>
<div class="clear:both;"></div>
