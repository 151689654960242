import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CrewGenSvc, Crew } from '../services_autogenerated/generated_services';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrewsDateRangeService {

  /** Angular Only Service **/

  // Facilitates communication between the crew and equipment panel component and the calendar view component

  crewsInDateRange: Crew[];
  private crewUpdater = new BehaviorSubject<Crew[]>(null);

  constructor(private crewService: CrewGenSvc) { }

  getCrewUpdater(): Observable<Crew[]> {
    return this.crewUpdater.asObservable();
  }

  emitCrewUpdate(crews: Crew[]) {
    this.crewUpdater.next(crews);
  }

  setCrewsInDateRange(startDate: moment.Moment, endDate: moment.Moment) {
    this.crewService.getValidCrewsInDateRange(startDate.toDate(), endDate.toDate()).subscribe(crews => {
      this.crewsInDateRange = crews;
      this.emitCrewUpdate(crews);
    });
  }
}
