import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { BaseWorkOrderCardComponent } from '../base-work-order-card/base-work-order-card.component';
import { WorkWorkOrder, WorkOrderWorkTask } from 'src/app/services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
var BaseWorkWorkOrderCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BaseWorkWorkOrderCardComponent, _super);
    function BaseWorkWorkOrderCardComponent(dragAndDropService, router, priorityService, workOrderHelperService, authHelper) {
        var _this = _super.call(this, dragAndDropService, router, priorityService, workOrderHelperService, authHelper) || this;
        _this.showMap = false;
        _this.dragStartEvent = new EventEmitter();
        _this.taskDragEndEvent = new EventEmitter();
        _this.clickTime = 0;
        return _this;
    }
    BaseWorkWorkOrderCardComponent.prototype.updateWorkOrderDisplay = function () {
        this.setTasks();
        this.setBottomSection();
    };
    BaseWorkWorkOrderCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateWorkOrderDisplay();
        this.workOrder.mostUrgentPriorityLevel = this.priorityService.GetMostUrgentPriorityLevel(this.workOrder);
        this.dragAndDropService.addWorkOrderTaskListTOCDKList(this.workOrder);
        this.dragAndDropService.getWorkOrderUpdater().subscribe(function (wo) {
            if (wo instanceof WorkWorkOrder && wo.id === _this.workOrder.id) {
                _this.workOrder = wo;
            }
            _this.updateWorkOrderDisplay();
        });
        this.hasCompanyWideNotes = this.workOrder.customer.customerComments.some(function (comment) { return comment.companyWide && comment.active; });
        this.canSeeHiddenPrice = this.authHelper.getDecodedAccessToken().claimNames.includes('View Hidden Prices');
    };
    // override
    BaseWorkWorkOrderCardComponent.prototype.shouldDisableWODrag = function () {
        var _this = this;
        return _super.prototype.shouldDisableWODrag.call(this) &&
            // everything that is on this card is disabled
            this.workOrder.workOrderWorkTasks
                .filter(function (t) { return _this.bucketId ? (t.workTask.currentBucketId === _this.bucketId || t.workTask.goBackBucketId === _this.bucketId)
                : (!t.workTask.currentBucketId || (t.workTask.isGoBack && !t.workTask.goBackBucketId)); })
                .every(function (t) { return _this.shouldDisableTaskDrag(t.workTask); });
    };
    // Double click doesn't work on mobile
    // https://stackoverflow.com/questions/50752562/angular-4-dblclick-event-not-working-in-mobile-view
    BaseWorkWorkOrderCardComponent.prototype.dblclickHandler = function () {
        if (!this.clickTime) {
            // set first click
            this.clickTime = new Date().getTime();
        }
        else {
            // compare first click to this click and see if they occurred within double click threshold
            if (((new Date().getTime()) - this.clickTime) < 600) {
                // double click occurred
                this.navigateToWorkOrder();
                this.clickTime = 0;
            }
            else {
                // not a double click so set as a new first click
                this.clickTime = new Date().getTime();
            }
        }
    };
    BaseWorkWorkOrderCardComponent.prototype.navigateToWorkOrder = function () {
        if (confirm('Would you like to redirect to the work order page?')) {
            // this.router.navigateByUrl('workWorkOrderMaintenance/' + this.workOrder.id);
            window.open('workWorkOrderMaintenance/' + this.workOrder.id, '_blank');
        }
    };
    BaseWorkWorkOrderCardComponent.prototype.dragStart = function (workOrderWorkTask, event) {
        if (this.workOrder.isMultiday) {
            var evt = document.createEvent('MouseEvents');
            evt.initEvent('mouseup', true, true);
            (event.source.element.nativeElement).dispatchEvent(evt);
            if (confirm('This is a multiday order. Would you like to redirect to the multiday scheduling page?')) {
                this.router.navigateByUrl('/scheduling/multiday/work');
            }
        }
        this.dragAndDropService.dragStartWO = this.workOrder;
        this.dragStartEvent.emit(workOrderWorkTask);
    };
    BaseWorkWorkOrderCardComponent.prototype.taskDragEnd = function () {
        this.taskDragEndEvent.emit();
    };
    return BaseWorkWorkOrderCardComponent;
}(BaseWorkOrderCardComponent));
export { BaseWorkWorkOrderCardComponent };
