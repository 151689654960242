import { Component, OnInit, Inject, Input } from '@angular/core';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { EquipmentType, ScheduledBucketDTO } from 'src/app/services_autogenerated/generated_services';
import * as moment from 'moment';
import { BaseWorkWorkOrderCardComponent } from '../base-work-work-order-card/base-work-work-order-card.component';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-scheduled-bucket-work-work-order-card',
  templateUrl: './scheduled-bucket-work-work-order-card.component.html',
  styleUrls: ['./scheduled-bucket-work-work-order-card.component.css']
})
export class ScheduledBucketWorkWorkOrderCardComponent extends BaseWorkWorkOrderCardComponent implements OnInit {
  @Input() bucket: ScheduledBucketDTO;
  @Input() disableDrag: boolean;

  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
    @Inject(Router) router: Router,
    @Inject(PriorityService) priorityService: PriorityService,
    @Inject(WorkOrderHelperService) workOrderHelperService: WorkOrderHelperService,
    public customCustomerService: CustomerService,
    @Inject(AuthHelperService) authHelper: AuthHelperService
  ) {
    super(dragAndDropService, router, priorityService, workOrderHelperService, authHelper);
  }

  getEquipmentTypeDisplay() {
    let equipmentTypes: EquipmentType[] = [];

    this.scheduledTasks.forEach(wowt => {
      if (wowt.workTask.isGoBack && this.bucket.id === wowt.workTask.goBackBucketId) {
        equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskGoBackEquipment.map(wte => wte.equipmentType));
      } else if (this.bucket.id === wowt.workTask.currentBucketId) {
        equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskEquipment.map(wte => wte.equipmentType));
      }
    });

    return this.workOrderHelperService.getUnique(equipmentTypes).map(et => et.abbreviation ? et.abbreviation : et.type).join(' / ');
  }

  setBottomSection() {
    this.hoursSummary = this.scheduledTasks.reduce((acc, curr) => acc + curr.workTask.hours, 0);
    this.hoursSummary += this.scheduledTasks.reduce((acc, curr) => acc + curr.workTask.goBackHoursEstimate, 0);
    this.priceSummary = this.scheduledTasks.reduce((acc, curr) => acc + curr.workTask.price, 0);
    this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
  }

  setTasks() {
    // Total amount of task scheduled, even ones not scheduled on given bucket day
    this.scheduledTasksTotal = this.workOrder.workOrderWorkTasks.filter(task =>
      !((!task.workTask.isGoBack && !task.workTask.currentBucketId) || (task.workTask.isGoBack && !task.workTask.goBackBucketId))).length;
    // Tasks that are scheduled on the given bucket day
    this.scheduledTasks = this.workOrder.workOrderWorkTasks
      .filter(task => task.workTask.currentBucketId === this.bucket.id || task.workTask.goBackBucketId === this.bucket.id);
  }
}
