import { Component, OnInit, Inject, Input } from '@angular/core';
import { BaseWellnessWorkOrderCardComponent } from '../base-wellness-work-order-card/base-wellness-work-order-card.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { EquipmentType, ScheduledBucketDTO } from 'src/app/services_autogenerated/generated_services';
import * as moment from 'moment';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-scheduled-bucket-wellness-work-order-card',
  templateUrl: './scheduled-bucket-wellness-work-order-card.component.html',
  styleUrls: ['./scheduled-bucket-wellness-work-order-card.component.css']
})
export class ScheduledBucketWellnessWorkOrderCardComponent extends BaseWellnessWorkOrderCardComponent implements OnInit {
  @Input() bucket: ScheduledBucketDTO;
  @Input() disableDrag: boolean;

  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
    @Inject(Router) router: Router,
    @Inject(PriorityService) priorityService: PriorityService,
    @Inject(WorkOrderHelperService) workOrderHelperService: WorkOrderHelperService,
    public customCustomerService: CustomerService,
    @Inject(AuthHelperService) authHelper: AuthHelperService
  ) {
    super(dragAndDropService, router, priorityService, workOrderHelperService, authHelper);
  }

  getEquipmentTypeDisplay() {
    let equipmentTypes: EquipmentType[] = [];

    this.scheduledTasks.forEach(wowt => {
      if (wowt.wellnessTask.isGoBack && this.bucket.id === wowt.wellnessTask.goBackBucketId) {
        equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskGoBackEquipment.map(wte => wte.equipmentType));
      } else if (this.bucketId === wowt.wellnessTask.currentBucketId) {
        equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskEquipment.map(wte => wte.equipmentType));
      }
    });

    return this.workOrderHelperService.getUnique(equipmentTypes).map(et => et.abbreviation ? et.abbreviation : et.type).join(' / ');
  }

  setBottomSection() {
    this.hoursSummary = this.scheduledTasks.reduce((acc, curr) => acc + curr.wellnessTask.hours, 0);
    this.hoursSummary += this.scheduledTasks.reduce((acc, curr) => acc + curr.wellnessTask.goBackHoursEstimate, 0);
    this.priceSummary = this.scheduledTasks.reduce((acc, curr) => acc + curr.wellnessTask.price, 0);
    this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
  }

  setTasks() {
    // Total amount of task scheduled, even ones not scheduled on given bucket day
    this.scheduledTasksTotal = this.workOrder.workOrderWellnessTasks.filter(task =>
      !((!task.wellnessTask.isGoBack && !task.wellnessTask.currentBucketId) || (task.wellnessTask.isGoBack && !task.wellnessTask.goBackBucketId))).length;
    // Tasks that are scheduled on the given bucket day
    this.scheduledTasks = this.workOrder.workOrderWellnessTasks
      .filter(task => task.wellnessTask.currentBucketId === this.bucket.id || task.wellnessTask.goBackBucketId === this.bucket.id);
  }
}
