import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  PaymentDueOption,
  State,
  Employee,
  Customer,
  EmployeeGenSvc,
  StateGenSvc,
  PaymentDueOptionGenSvc,
  CustomerGenSvc,
  WellnessWorkOrder,
  WorkWorkOrder,
  QuoteWorkOrder,
  Address,
  EmailAddress,
  CustomerContact,
  QuoteWorkOrderCompletionStatus,
  NeighborPermission} from '../../services_autogenerated/generated_services';
import { WorkOrderHelperService } from '../../services/work-order-helper.service';
import { NgForm, ControlContainer } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { CustomerDropdownComponent } from '../customer-dropdown/customer-dropdown.component';
import { QuoteRequestService } from 'src/app/services/quote-request.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-work-order-maintenance-common-fields-top',
  templateUrl: './work-order-maintenance-common-fields-top.component.html',
  styleUrls: ['./work-order-maintenance-common-fields-top.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
    // Sourced from https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
})
export class WorkOrderMaintenanceCommonFieldsTopComponent implements OnInit, OnChanges {
  @Input() workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder;
  @Input() isEditable: boolean;
  @Input() isCustomer: boolean;

  @ViewChild(CustomerDropdownComponent) customerDropdown: CustomerDropdownComponent;

  states: State[];
  customers: Customer[] = [];
  canHidePrice: boolean;
  displayCustomerDialog: boolean;
  estimateDisplayValue: string;
  multidayTooltip: string;
  newCustomer: Customer;
  primaryContactIndex = 0;
  selectedCustomer: Customer;
  shouldDisableMultiday: boolean;

  isQuote: boolean;
  quoteRequestItems: string;
  quoteRequestDescription: string;

  editCrewMessage: boolean;
  editWorksiteAddress: boolean;

  neighborsPermission: NeighborPermission;

  constructor(
    private stateService: StateGenSvc,
    private paymentDueOptionService: PaymentDueOptionGenSvc,
    private customerService: CustomerGenSvc,
    public workOrderHelperService: WorkOrderHelperService,
    public customCustomerService: CustomerService,
    private authHelper: AuthHelperService,
    private quoteRequestService: QuoteRequestService,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    if (this.workOrder instanceof QuoteWorkOrder) {
      this.isQuote = true;
      this.quoteRequestDescription = this.workOrder.quoteRequest ? this.workOrder.quoteRequest.description : 'No description';
      this.quoteRequestItems = this.workOrder.quoteRequest ? this.quoteRequestService.getQuoteRequestServicesRequested(this.workOrder.quoteRequest) : 'No quote request';
    } else if (this.workOrder instanceof WellnessWorkOrder || this.workOrder instanceof WorkWorkOrder) {
      this.neighborsPermission = this.workOrder.quoteWorkOrder.neighborPermission;
    }

    const customerContacts = [];
    const newContact = new CustomerContact();
    newContact.firstName = '';
    newContact.lastName = '';
    newContact.primary = true;
    newContact.active = true;
    newContact.phoneNumber = null;
    newContact.receiveInvoicingEmails = true,
    newContact.receiveNonInvoicingEmails = true,

    customerContacts.push(newContact);

    this.newCustomer = new Customer();
    this.newCustomer.address = new Address();
    this.newCustomer.active = true;
    this.newCustomer.customerContacts = customerContacts;

    this.stateService.getAll().subscribe(states => {
      this.states = states;
    });

    this.customerService.getActive().subscribe(customers => {
      // Set display label name for dropdown
      this.customers = customers.map(customer => {
        customer['fullName'] = this.customCustomerService.getCustomerFullName(customer);
        return customer;
      });
      if (this.workOrder) {
        this.selectCustomerFromWorkOrderCustomerId();
      }
    });

    const token = this.authHelper.getDecodedAccessToken();
    this.canHidePrice = token.claimNames.includes('View Hidden Prices');
  }

  private selectCustomerFromWorkOrderCustomerId() {
    this.selectedCustomer = this.customers.find(c => c.id === this.workOrder.customerId);

    if (this.selectedCustomer) {
      this.primaryContactIndex = this.selectedCustomer.customerContacts.findIndex(x => x.primary);
    }
    this.setSalesTax();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workOrder']) {
      if (this.workOrder == null) {
        this.estimateDisplayValue = 'Auto-generated';
      } else {
        const quoteNumber = (this.workOrder as any).quoteNumber;
        if (quoteNumber)  {
          this.estimateDisplayValue = quoteNumber;
        } else if (this.workOrder.id) {
          this.estimateDisplayValue = this.workOrder.id.toString();
        } else {
          this.estimateDisplayValue = 'Auto-generated';
        }
        this.selectCustomerFromWorkOrderCustomerId();
      }
    }
  }

  addNewCustomer() {
    this.workOrder.customer = this.customCustomerService.newCustomer();
    this.displayCustomerDialog = true;
  }

  allowNewCustomer() {
    return this.workOrder && !(this.workOrder as any).quoteId && !(this.workOrder as QuoteWorkOrder).quoteRequest;
  }

  hasInternalQuoteStatus(): boolean {
    if (this.workOrder.completionStatus === QuoteWorkOrderCompletionStatus.New || !this.workOrder.id) {
      return true;
    } else {
      return false;
    }
  }

  setCustomer(id: number) {
    if (id) {
      this.customerService.get(id).subscribe(c => {
        this.selectedCustomer = c;
        this.workOrder.customer = c;
        this.primaryContactIndex = c.customerContacts.findIndex(x => x.primary);
        this.afterCustomerSet();
      }, error => console.log(error)); // TODO handle error properly
    } else {
      this.workOrder.customer = new Customer();
      this.workOrder.customer.customerContacts = [new CustomerContact({
        firstName: '', lastName: '', active: true,
        receiveInvoicingEmails: true,
        receiveNonInvoicingEmails: true,
      })];
      this.workOrder.customer.customerContacts[0].emailAddress = new EmailAddress({email: ''});
      this.workOrder.customer.customerContacts[0].phoneNumber = '';
      this.workOrder.customer.address = this.workOrder.address;
      this.primaryContactIndex = 0;
      this.workOrder.customer.active = true;
      this.afterCustomerSet();
    }
  }

  toggleCrewEdit() {
    this.editCrewMessage = !this.editCrewMessage;
  }

  editWorksiteAddressOn() {
    this.editWorksiteAddress = true;
    this.workOrder.addressSameAsCustomer = false;
    this.addressSameAsCustomerToggle();
  }

  editWorksiteAddressOff() {
    if (!this.workOrder.addressSameAsCustomer &&
      (    !this.workOrder.address.city
        || !this.workOrder.address.street
        || !this.workOrder.address.zip
        || !this.workOrder.address.state)
    ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Address Needed',
        detail: 'Please enter worksite address or select \'Address Same As Customer Address\''
      });
      return;
    }
    this.editWorksiteAddress = false;
  }

  afterCustomerSet() {
    this.addressSameAsCustomerToggle();
    this.setSalesTax();
  }

  private setSalesTax() {
    if (this.workOrder.customer.taxExempt) {
      this.workOrder.salesTaxPercentage = 0; // No tax
    } else if (this.workOrder.salesTaxPercentage == null || this.workOrder.salesTaxPercentage === undefined) {
      this.workOrder.salesTaxPercentage = 7.5; // Default tax rate
    }
  }

  closedCustomerDialog(customer: Customer) {
    this.displayCustomerDialog = false;
    if (customer) {
      this.workOrder.customer = customer;
      const fullName = this.customCustomerService.getCustomerFullName(customer);
      customer['fullName'] = fullName;
      this.customers = [...this.customers, customer];
      this.selectedCustomer = customer;
      this.primaryContactIndex = this.selectedCustomer.customerContacts.findIndex(x => x.primary);
      this.addressSameAsCustomerToggle();
      this.customerDropdown.setCustomerFromNew(customer);
    } else {
      this.workOrder.customer = this.newCustomer;
    }
  }

  addressSameAsCustomerToggle() {
    if (this.workOrder.addressSameAsCustomer) {
      this.workOrder.address = this.workOrder.customer.address;
    } else {
      this.workOrder.address = new Address();
      this.workOrder.address.state = this.states.find(state => state.name === 'Ohio');
    }
  }

  customSearch = (searchTerm: string, customer: Customer) => {
    return this.customCustomerService.customerSearch(searchTerm, customer);
  }

  isNotEditableOrExternal() {
    return (!this.hasInternalQuoteStatus() && this.workOrder.customer) || !this.isEditable;
  }

  asQuote(): QuoteWorkOrder {
    return this.workOrder as QuoteWorkOrder;
  }

  asWorkOrder(): WorkWorkOrder | WellnessWorkOrder {
    return this.workOrder as WorkWorkOrder | WellnessWorkOrder;
  }
}
