import { Injectable } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  // this one gets used like = MaskService.currencyMask; (no parens)
  public currencyMask(rawValue: string) {
    const numberMask = createNumberMask({
        prefix: '$',
        includeThousandsSeparator: true,
        allowDecimal: true,
        requireDecimal: true,
        allowLeadingZeroes: true
    });
    const mask = numberMask(rawValue);

    const decimalsRegex = /\.([0-9]{1,2})/;
    const result = decimalsRegex.exec(rawValue);
    // In case there is only one decimal
    if (result && result[1].length < 2) {
        mask.push('0');
    } else if (!result) {
        mask.push('0');
        mask.push('0');
    }

    return mask;
  }

  public currencyMaskNoDecimal(rawValue: string) {
    const numberMask = createNumberMask({
      prefix: '$',
      includeThousandsSeparator: true,
      allowDecimal: false,
      requireDecimal: false,
      allowLeadingZeroes: true
    });
    const mask = numberMask(rawValue);

    return mask;
  }

  public ccExpirationMask(): (string | RegExp)[] {
    return [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  }
}
