<div id="datePicker">
    <label for="startDate" style="margin-top: auto;">Start Date: &nbsp;</label>
    <p-calendar id="startDate" [(ngModel)]="startDate" [showIcon]="true" (ngModelChange)="onDateChange()"></p-calendar>

    <span style="margin-left: 15px;">
        <i class="fa fa-play"  *ngIf="timerPaused" (click)="startTimer()"></i>
        <i class="fa fa-pause" *ngIf="!timerPaused" (click)="pauseTimer()"></i>
        {{timeLeft | time}} until refresh
    </span> 

    <button pButton type="button" label="Notify All Customers" class="ui-button-secondary ui-button-rounded"
        (click)="showDialog(bucketListDayList)" style="float: right;"></button>
</div>

<p-dialog header="Schedule" *ngIf="displayScheduleDialog" [(visible)]="displayScheduleDialog" [modal]="true"
    [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
    <div class="form-group">
        {{message}}
    </div>
    <div class="form-group">
        <button type="button" type="submit" pButton (click)="scheduleDays()" label="Yes"></button>&nbsp;
        <button type="button" pButton (click)="displayScheduleDialog=false" label="No"
            class="ui-button-secondary"></button>
    </div>
</p-dialog>

<p-dialog header="Schedule" *ngIf="displayErrorScheduleDialog" [(visible)]="displayErrorScheduleDialog" [modal]="true"
    [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
    <form [formGroup]="commentForm">
        <div class="form-group">
            {{message}}
            <textarea pInputTextarea formControlName="errorComment" class="form-control" [required]="true"
                style="width: 100%;" autoResize="autoResize"
                placeholder="Explain why you are still proceeding despite the errors..."></textarea>
        </div>
        <div class="form-group">
            <button type="button" type="submit" [disabled]="commentForm.invalid" pButton (click)="scheduleDays()"
                label="Yes"></button>&nbsp;
            <button type="button" pButton (click)="displayErrorScheduleDialog=false" label="No"
                class="ui-button-secondary"></button>
        </div>
    </form>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="displayConfirmationDialog" [modal]="true" [responsive]="true"
    [resizable]="false" [baseZIndex]="10000" appendTo="body">
    <div>
        {{confirmMessage}}
    </div>
    <p-footer>
        <button type="button" pButton (click)="displayConfirmationDialog=false" label="Ok"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>

<div id="twoDayCalendar" class="p-grid">
    <p-card id="dayOne" [style]="{height: '98%'}" class="p-col dayCard" cdkDropList
        [cdkDropListData]="bucketListDayList[0].bucketList" cdkDropListConnectedTo="dayTwo"
        (cdkDropListDropped)="drop($event)">
        <p-header>
            <span style="float: left;">
                <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[0])"></p-calendar>
            </span>
            {{bucketListDayList[0].day | date: 'EEEE M/d' | uppercase}}
            <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
                (click)="addNewCrew(bucketListDayList[0])"></i>
            <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
                (click)="showDialog([bucketListDayList[0]])"></button>
        </p-header>
        <app-unavailable-equipment-and-employees
            *ngIf="unavailableEquipment && unavailableEquipment.length > 0 && unavailableEquipment[0].length > 0"
            [bigEquipment]="unavailableEquipment[0]"
            [bigEmployees]="unavailableEmployees[0]"
            [type]="isWellness ? 'wellness' : 'work'">
        </app-unavailable-equipment-and-employees>
        <div style="clear:both;" 
            *ngFor="let item of bucketListDayList[0].bucketList" 
            cdkDrag
            [cdkDragDisabled]="disableDrag(item)">
            <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
                (needsValidated)="validateDay(bucketListDayList[0].day)" (dragStartEvent)="woDragStart($event, item.id)"
                (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
                (taskDragEndEvent)="taskDraggedEnded()"
                [isWellness]="isWellness"
                [isWork]="isWork"></app-scheduled-item-bucket-card>
        </div>
    </p-card>
    <p-card id="dayTwo" [style]="{height: '98%'}" class="p-col dayCard" cdkDropList
        [cdkDropListData]="bucketListDayList[1].bucketList" cdkDropListConnectedTo="dayOne"
        (cdkDropListDropped)="drop($event)">
        <p-header>
            <span style="float: left;">
                <p-calendar [minDate]="today" class="only-icon rescheule-calendar" [(ngModel)]="rescheduleDate" [showIcon]="true" (ngModelChange)="rescheduleDay(bucketListDayList[1])"></p-calendar>
            </span>
            {{bucketListDayList[1].day | date: 'EEEE M/d' | uppercase}}
            <i style="float: right;" pTooltip="Add New Crew" tooltipPosition="bottom" class="pi pi-plus"
                (click)="addNewCrew(bucketListDayList[1])"></i>
            <button class="scheduleButton ui-button-info" type="button" pButton label="Notify Customers"
                (click)="showDialog([bucketListDayList[1]])"></button>
        </p-header>
        <app-unavailable-equipment-and-employees
            *ngIf="unavailableEquipment && unavailableEquipment.length > 0 && unavailableEquipment[1].length > 0"
            [bigEquipment]="unavailableEquipment[1]" 
            [bigEmployees]="unavailableEmployees[1]"
            [type]="isWellness ? 'wellness' : 'work'">
        </app-unavailable-equipment-and-employees>
        <div 
            *ngFor="let item of bucketListDayList[1].bucketList" 
            cdkDrag
            [cdkDragDisabled]="disableDrag(item)">
            <app-scheduled-item-bucket-card (isInitialized)="onNewBucket($event)" class="scheduled-item" [bucket]="item"
                (needsValidated)="validateDay(bucketListDayList[1].day)" (dragStartEvent)="woDragStart($event, item.id)"
                (dragEndEvent)="woDragEnd()" (taskDragStartEvent)="taskDraggedStart($event)"
                (taskDragEndEvent)="taskDraggedEnded()"
                [isWellness]="isWellness"
                [isWork]="isWork"></app-scheduled-item-bucket-card>
        </div>
    </p-card>
</div>