<p-dialog header="Refunds" [(visible)]="showDialog" [modal]="true" [blockScroll]="false" (onHide)="cancel()" [style]="{width: '480px'}">
  <div class="bold">
    {{workOrder.serviceType}}: {{workOrder.number}}
  </div>
  <app-loading-swirly [isLoading]="isLoading"></app-loading-swirly>
  <div class="container" *ngFor="let charge of charges; let i = index">
    <div class="bold header-line">Charge {{i + 1}}</div><br/>
    <div>Date: {{charge.created | date: 'shortDate'}}</div>
    <div>Amount: {{charge.chargeAmount | currency: 'USD' : 'symbol'}} </div>
    <div *ngIf="!charge.refunded" class="regular-link" (click)="refund(charge)">
      [Refund]
    </div>
    <div *ngIf="charge.refunded">Amount Refunded: {{charge.amountRefunded | currency: 'USD' : 'symbol'}}</div>
  </div>
  <p-footer>
    <button type="button" pButton (click)="cancel()" label="Cancel" class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>