import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { Customer, CustomerGenSvc, StripeBilling, StripeGenSvc, WorkOrderInvoicingViewModel } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-charge-card',
  templateUrl: './charge-card.component.html',
  styleUrls: ['./charge-card.component.css']
})
export class ChargeCardComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() workOrder: WorkOrderInvoicingViewModel;
  @Input() amountDue: number;

  showNoCC = false;
  customer: Customer;
  isLoading: boolean;
  stripeBilling: StripeBilling = null;
  disabled: boolean;

  @Output() close  = new EventEmitter<boolean>();
  @Output() charge = new EventEmitter<WorkOrderInvoicingViewModel>();

  constructor(private customerService: CustomerGenSvc,
              private customStripeService: StripeGenSvc) { }

  ngOnInit() {
    this.isLoading = true;
    this.disabled = true;

    this.customerService.get(this.workOrder.customerId).subscribe(res => {
      this.customer = res;
      this.isLoading = false;
      this.getStripeBilling().subscribe(stripeBilling => this.afterGetStripeBilling(stripeBilling));
    });
  }

  refreshBilling() {
    this.getStripeBilling().subscribe(stripeBilling => this.afterGetStripeBilling(stripeBilling));
  }

  getStripeBilling() {
    if (this.customer.id) {
      return this.customStripeService.getStripeBillingByCustomerId(this.customer.id);
    }
  }

  // updatedStripeCustomer(stripeCustomerId: string) {
  //   this.customer.stripeCustomerId = stripeCustomerId;
  //   this.disabled = this.customer.stripeCustomerId === null || this.customer.stripeCustomerId === undefined;
  //   this.getStripeBilling().subscribe(stripeBilling => {
  //     this.afterGetStripeBilling(stripeBilling);
  //   });
  // }

  afterGetStripeBilling(stripeBilling: StripeBilling) {
    //console.log(stripeBilling)
    this.disabled = this.customer.stripeCustomerId === null || this.customer.stripeCustomerId === undefined || stripeBilling.cCLast4 == undefined;
    if (stripeBilling.cCLast4 == undefined) {
      this.showNoCC = true;
    } else {
      this.showNoCC = false;
    }

    this.isLoading = false;
  }

  cancel() {
    this.isLoading = false;
    this.close.emit(false);
  }

  chargeCard() {
    this.isLoading = true;
    this.disabled = true;
    this.charge.emit(this.workOrder);
  }
}
