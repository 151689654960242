import * as tslib_1 from "tslib";
import { QueryList } from '@angular/core';
import { WorkWorkOrder, WorkTask, WorkWorkOrderGenSvc, Customer, Address, WorkOrderWorkTaskGenSvc, WorkOrderWellnessTaskGenSvc, WorkOrderPriceAdjustmentGenSvc, WorkTaskGenSvc, EmailAddress, CustomerContact, WorkTaskHourEntry, ScheduledBucketGenSvc, EmployeeGenSvc, CustomerComment, CustomerCommentStageGenSvc, CustomerNoteGenSvc, WorkTaskHourEntryGenSvc, WorkTaskCompletionStatus, WorkWorkOrderCompletionStatus, NotificationsGenSvc, WorkTaskPaymentStatus, BigDayBucketSubrangeGenSvc, WorkTaskTreeType, SaveWorkOrderAfterCompletionRequest, WorkWorkOrderPaymentStatus, QuoteGenSvc } from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { BaseWorkOrderMaintenanceComponent } from '../base-work-order-maintenance.ts/base-work-order-maintenance.component';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
// tslint:disable-next-line: max-line-length
import { WorkOrderMaintenanceWorkTaskCardComponent } from '../work-order-maintenance-work-task-card/work-order-maintenance-work-task-card.component';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { FormBuilder, NgForm } from '@angular/forms';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';
import { WorkTaskMaintenanceComponent } from '../work-task-maintenance/work-task-maintenance.component';
import { MaskService } from 'src/app/services/mask.service';
var WorkWorkOrderMaintenanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkWorkOrderMaintenanceComponent, _super);
    function WorkWorkOrderMaintenanceComponent(workWorkOrderService, authHelper, workTaskService, route, router, messageService, locationColorService, notificationService, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc, wopaService, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService, scheduledBucketService, workTaskHourEntryService, employeeService, authHelperService, customerCommentStageService, customerCommentService, bigDaySubrangeService, maskService, fb, quoteService) {
        var _this = _super.call(this, route, router, messageService, locationColorService, notificationService, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc, wopaService, authHelper, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService) || this;
        _this.workWorkOrderService = workWorkOrderService;
        _this.workTaskService = workTaskService;
        _this.scheduledBucketService = scheduledBucketService;
        _this.workTaskHourEntryService = workTaskHourEntryService;
        _this.employeeService = employeeService;
        _this.authHelperService = authHelperService;
        _this.customerCommentStageService = customerCommentStageService;
        _this.customerCommentService = customerCommentService;
        _this.bigDaySubrangeService = bigDaySubrangeService;
        _this.quoteService = quoteService;
        _this.canUndoWOCompletion = false;
        _this.amountToInvoice = 0;
        _this.showUndoCompleteDialog = false;
        _this.WorkWorkOrderCompletionStatus = WorkWorkOrderCompletionStatus;
        _this.commentForm = fb.group({
            description: ['']
        });
        _this.currencyMask = maskService.currencyMaskNoDecimal;
        return _this;
    }
    WorkWorkOrderMaintenanceComponent.prototype.getWO = function (id) {
        var _this = this;
        if (id) {
            this.workWorkOrderService.get(id).subscribe(function (wo) {
                _this.workOrder = wo;
                _this.onTaskChange();
                _this.canSaveAfterCompletion = _this.canSave && _this.workOrder.completionStatus === WorkWorkOrderCompletionStatus.Completed
                    && _this.token.claimNames.includes('Edit Outside Costs');
                // Admin can always edit and save
                _this.isEditable = _this.isEditable
                    && _this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Completed
                    && _this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Unable_to_be_Completed;
                _this.canSave = (_this.canSave
                    && _this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Completed
                    && _this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Unable_to_be_Completed)
                    || _this.token.claimNames.includes('Add Task to Completed Work Order');
                // canComplete is permissions based, WO's stage cannot already be complete, and ALL tasks must be be scheduled
                _this.setCanCompleteWO();
                _this.canUndoWOCompletion = _this.workOrder.completionStatus === WorkWorkOrderCompletionStatus.Completed
                    && _this.token.claimNames.includes('Undo Work Order Completion')
                    && _this.workOrder.paymentStatus !== WorkWorkOrderPaymentStatus.Paid;
                _this.setCanCompleteATaskRegular();
                _this.setUpTaskHours();
                _this.verifyPrice = _this.workOrder.workOrderWorkTasks.some(function (wowt) { return !wowt.workTask.fixedPrice; });
                _this.tasksToVerifyPrice = _this.workOrder.workOrderWorkTasks.map(function (wowt) { return wowt.workTask; }).filter(function (wt) { return !wt.fixedPrice; });
                _this.tasksToVerifyPrice.forEach(function (task) {
                    if (task.completionStatus !== WorkTaskCompletionStatus.Completed) {
                        task.price = undefined;
                    }
                });
                _this.isLoading = false;
            }, function (error) {
                _this.errorMessage = 'Could not the load work order. Please refresh and try again.';
                _this.isLoading = false;
            });
        }
        else {
            var wo = new WorkWorkOrder();
            wo.customer = new Customer();
            wo.customer.address = new Address();
            wo.customer.customerContacts = [new CustomerContact({
                    receiveInvoicingEmails: true,
                    receiveNonInvoicingEmails: true,
                })];
            wo.customer.customerContacts[0].emailAddress = new EmailAddress();
            wo.address = new Address();
            wo.createdDate = new Date();
            wo.completionStatus = WorkWorkOrderCompletionStatus.Ready_to_Schedule;
            wo.salesTaxPercentage = 0;
            wo.workOrderWorkTasks = [];
            wo.workOrderPriceAdjustments = [];
            wo.salesTaxPercentage = 7.5; // default
            this.workOrder = wo;
            this.isEditable = this.isEditable && true;
            this.canCompleteATask = false;
            this.onTaskChange();
            this.isLoading = false;
        }
        this.employeeService.getSchedulable().subscribe(function (employees) { return _this.employees = employees; });
        this.customerCommentStageService.get().subscribe(function (stages) {
            _this.stages = stages;
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.completeWO = function () {
        this.isCompleting = true;
        if (confirm('This will complete the work order. Do you want to continue?')) {
            if (this.invoiceAmtGreaterThanCashCollected()) {
                if (this.workOrder.workOrderWorkTasks.every(function (wowt) { return wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed; })) {
                    this.workOrder.completionStatus = this.WorkWorkOrderCompletionStatus.Unable_to_be_Completed;
                }
                else {
                    this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Completed;
                    if (!this.workOrder.completedDate) {
                        this.workOrder.completedDate = new Date();
                    }
                }
                this.saving = true;
                this.isEditable = false;
                this.saveWO();
            }
            else {
                this.isCompleting = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Cash Collected Discrepancy',
                    detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2) + ') to complete this work order.',
                    sticky: true
                });
            }
        }
        else {
            this.isCompleting = false;
        }
    };
    WorkWorkOrderMaintenanceComponent.prototype.undoWOCompletion = function () {
        var _this = this;
        this.saving = true;
        var comment = this.commentForm.get('description').value;
        this.workWorkOrderService.undoWorkOrderCompletion(this.workOrder.id, comment ? comment : 'Work order adjusted using uncomplete button', this.token.employeeId).subscribe(function () {
            _this.closeUndoWOCompletion();
            _this.getWO(_this.workOrder.id);
            _this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Notified;
            _this.setPermissions();
            _this.isLoading = true;
            _this.workOrder = undefined;
            _this.saving = false;
        }, function (error) {
            if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Undoing Completion',
                    detail: JSON.parse(error.response).ExceptionMessage,
                    sticky: true
                });
            }
            else {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Undoing Completion',
                    detail: 'An unexpected error occurred. Contact an admin for support.',
                    sticky: true
                });
            }
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.openUndoWOCompletion = function () {
        this.showUndoCompleteDialog = true;
    };
    WorkWorkOrderMaintenanceComponent.prototype.closeUndoWOCompletion = function () {
        this.showUndoCompleteDialog = false;
    };
    WorkWorkOrderMaintenanceComponent.prototype.invoiceAmtGreaterThanCashCollected = function () {
        var selectedTasks = this.workOrder.workOrderWorkTasks.filter(function (x) {
            return x.workTask.active && x.workTask.paymentStatus === WorkTaskPaymentStatus.Not_Paid;
        });
        var cashToInvoice = this.workOrder.workOrderCashCollected.filter(function (x) { return x.invoiced === false; }).reduce(function (a, b) { return a + b.amount; }, 0);
        // console.log(cashToInvoice);
        // console.log(selectedTasks);
        this.amountToInvoice = this.helper.totalAmountByTasks(this.workOrder, selectedTasks);
        if (this.amountToInvoice >= cashToInvoice) {
            return true;
        }
        else {
            return false;
        }
    };
    WorkWorkOrderMaintenanceComponent.prototype.saveWO = function () {
        var _this = this;
        // Updating existing work WO
        this.workWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
            .subscribe(function (wo) {
            _this.workOrder = wo;
            _this.saveBlobs(wo);
        }, function (error) {
            _this.saving = false;
            _this.messageService.add({
                severity: 'error',
                summary: 'Error Saving',
                detail: 'Could not save the work order, please check that all fields are valid and retry.'
            });
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.saveBlobs = function (wo) {
        var _this = this;
        // Currently only 1 blob manager should be not view only. If that changes, you'll need to update this and loop through all the
        // ones that are not view only and save them.
        var blobManagerComponent = this.blobManagerComponents.find(function (blobComponent) { return blobComponent.viewOnly === false; });
        if (blobManagerComponent) {
            blobManagerComponent.parentRecordId = wo.id;
            blobManagerComponent.save().subscribe(function () {
                _this.saving = false;
                _this.isCompleting = false;
                _this.messageService.add({
                    severity: 'success',
                    summary: 'Save Successful',
                    detail: 'The WO was saved successfully!'
                });
                _this.router.navigateByUrl("/workWorkOrderMaintenance/" + _this.workOrder.id);
            });
        }
        else {
            this.saving = false;
            this.isCompleting = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Save Successful',
                detail: 'The WO was saved successfully!'
            });
            this.router.navigateByUrl("/workWorkOrderMaintenance/" + this.workOrder.id);
        }
    };
    WorkWorkOrderMaintenanceComponent.prototype.preSave = function (workOrderForm) {
        this.previousWOStatus = this.workOrder.completionStatus;
        if (this.canCompleteWO) {
            if (this.invoiceAmtGreaterThanCashCollected()) {
                // this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Completed;
                // this.workWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
                //   .subscribe(() => {
                this.isEditable = false;
                this.canCompleteWO = false;
                // }, error => {
                //   this.saving = false;
                //   this.messageService.add({
                //     severity: 'error',
                //     summary: 'Error Saving',
                //     detail: 'Could not save the work order, please check that all fields are valid and retry.'
                //   });
                // });
                this.save(workOrderForm);
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Cash Collected Discrepancy',
                    detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2) + ') to complete this work order.',
                    sticky: true
                });
            }
        }
        else {
            this.save(workOrderForm);
        }
    };
    WorkWorkOrderMaintenanceComponent.prototype.saveAfterCompletion = function () {
        var _this = this;
        this.workWorkOrderService.saveAfterCompletion(new SaveWorkOrderAfterCompletionRequest({
            id: this.workOrder.id,
            hasRentalCosts: this.workOrder.hasRentalExpense,
            rentalCosts: this.workOrder.rentalCost,
            rentalDescription: this.workOrder.rentalDescription
        })).subscribe(function () { return _this.doneSaving('work'); }, function (error) {
            return _this.messageService.add({
                severity: 'error',
                summary: 'Save Error',
                detail: 'Work order not saved. Please refresh and try again.',
                sticky: true
            });
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.addNewWorkTask = function () {
        this.displayWorkTaskDialog = true;
    };
    WorkWorkOrderMaintenanceComponent.prototype.copyWorkTask = function (wowt) {
        this.selectedWorkTask = new WorkTask({
            active: true,
            location: wowt.workTask.location,
            workTaskTreeTypes: wowt.workTask.workTaskTreeTypes.map(function (wttt) { return new WorkTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType }); }),
            primordialTaskId: wowt.workTask.id,
            priorityLevelId: wowt.workTask.priorityLevelId,
            manualPriorityLevel: wowt.workTask.manualPriorityLevel,
            priorityShortcutId: wowt.workTask.priorityShortcutId,
            priorityTypeShortcut: wowt.workTask.priorityTypeShortcut,
            hasCalculatedPriority: wowt.workTask.hasCalculatedPriority,
            dueDateEnd: wowt.workTask.dueDateEnd,
            dueDateStart: wowt.workTask.dueDateStart,
            hardEndDate: wowt.workTask.hardEndDate,
            hardStartDate: wowt.workTask.hardStartDate,
            // schedulableAfterCompletionOf: wowt.workTask.schedulableAfterCompletionOf,
            // schedulableAfterCompletionOfId: wowt.workTask.schedulableAfterCompletionOfId,
            completionStatus: wowt.workTask.completionStatus,
            workTaskSkills: [],
            workTaskEquipment: [],
            fixedPrice: wowt.workTask.fixedPrice,
            taskHourEntries: [],
            currentBucketId: wowt.workTask.currentBucketId,
            scheduleDateFrom: wowt.workTask.scheduleDateFrom,
            scheduleDateTo: wowt.workTask.scheduleDateTo,
            currentBigDaySubrangeId: wowt.workTask.currentBigDaySubrangeId
        });
        this.displayWorkTaskDialog = true;
    };
    WorkWorkOrderMaintenanceComponent.prototype.copyWellnessTask = function (wowt) {
        throw new Error('Cannot add a Wellness Task to a Work Work Order');
    };
    WorkWorkOrderMaintenanceComponent.prototype.editWorkTask = function (wowt) {
        this.selectedWorkTask = wowt.workTask;
        this.displayWorkTaskDialog = true;
    };
    WorkWorkOrderMaintenanceComponent.prototype.completeTask = function (wowt) {
        var _this = this;
        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Completed;
        if (wowt.workTask.isGoBack && !wowt.workTask.goBackCompletionDate) {
            wowt.workTask.goBackCompletionDate = new Date();
        }
        else if (!wowt.workTask.completedDate) {
            wowt.workTask.completedDate = new Date();
        }
        this.workTaskService.updateCompletionStatus(wowt.workTask).subscribe(function (wt) {
            var index = _this.workOrder.workOrderWorkTasks.findIndex(function (x) { return x.workTaskId === wt.id; });
            _this.workOrder.workOrderWorkTasks[index].workTask = wt;
            _this.setUpdatedTaskForDependencies(wt);
            _this.setCanCompleteATaskRegular();
            _this.setCanCompleteWO();
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.unableToCompleteWorkTask = function (workTask) {
        var index = this.workOrder.workOrderWorkTasks.findIndex(function (x) { return x.workTaskId === workTask.id; });
        this.workOrder.workOrderWorkTasks[index].workTask = workTask;
        this.setCanCompleteATaskRegular();
        this.setCanCompleteWO();
    };
    WorkWorkOrderMaintenanceComponent.prototype.undoCompleteTask = function (workTask) {
        var index = this.workOrder.workOrderWorkTasks.findIndex(function (x) { return x.workTaskId === workTask.id; });
        this.workOrder.workOrderWorkTasks[index].workTask = workTask;
        this.canCompleteWO = false;
        this.setCanCompleteATaskRegular();
        this.setCanCompleteWO();
    };
    WorkWorkOrderMaintenanceComponent.prototype.onTaskChange = function () {
        this.requiredSkills = this.getSkillsFromTasks();
        this.requiredEquipment = this.getEquipmentFromTasks();
        this.setCanCompleteATaskRegular();
        this.setCanCompleteWO();
    };
    WorkWorkOrderMaintenanceComponent.prototype.setUpdatedTaskForDependencies = function (task) {
        var tasksThatAreDependentOnUpdatedTask = this.workOrder.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.schedulableAfterCompletionOfId === task.id; });
        tasksThatAreDependentOnUpdatedTask.forEach(function (woTask) { return woTask.workTask.schedulableAfterCompletionOf = task; });
    };
    WorkWorkOrderMaintenanceComponent.prototype.closedDialog = function (task) {
        if (task && task.id) {
            this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTask.id === task.id; }).workTask = task;
            // update copy of task dependency so updating WO doesn't overwrite it
            this.setUpdatedTaskForDependencies(task);
        }
        this.displayWorkTaskDialog = false;
        this.selectedWorkTask = undefined;
        if (this.workOrder.id) {
            this.saveWO();
        }
        this.onTaskChange();
    };
    WorkWorkOrderMaintenanceComponent.prototype.cancelledTask = function (task) {
        var _this = this;
        if (task instanceof WorkTask && task.id) {
            this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTaskId === task.id; }).workTask = task;
        }
        else if (!task) {
            if (this.selectedWorkTask && this.selectedWorkTask.id) {
                this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTaskId === _this.selectedWorkTask.id; }).workTask = this.workTaskMaintenance.taskUnmodified;
            }
        }
        this.displayWellnessTaskDialog = false;
        this.displayWorkTaskDialog = false;
        this.selectedWorkTask = undefined;
        this.onTaskChange();
    };
    WorkWorkOrderMaintenanceComponent.prototype.getEquipmentFromTasks = function () {
        var workEquipment = [];
        this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
            workEquipment = workEquipment.concat(wowt.workTask.workTaskEquipment.map(function (wte) { return wte.equipmentType; }));
        });
        return this.helper.getUnique(workEquipment);
    };
    WorkWorkOrderMaintenanceComponent.prototype.getSkillsFromTasks = function () {
        var workSkills = [];
        this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
            workSkills = workSkills.concat(wowt.workTask.workTaskSkills.map(function (wts) { return wts.skill; }));
        });
        return this.helper.getUnique(workSkills);
    };
    WorkWorkOrderMaintenanceComponent.prototype.setUpTaskHours = function () {
        var _this = this;
        var incompleteTasks = this.workOrder.workOrderWorkTasks.filter(function (wowt) {
            return _this.canComplete
                && wowt.workTask.scheduleDateFrom !== undefined
                && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Completed;
        });
        var entries = [];
        incompleteTasks.forEach(function (wowt) {
            if (wowt.workTask.currentBucketId) {
                _this.scheduledBucketService.get(wowt.workTask.currentBucketId).subscribe(function (sb) {
                    sb.scheduledBucketEmployees.forEach(function (sbe) {
                        if (!entries.find(function (entry) { return entry.employee.id === sbe.employee.id; })) {
                            entries.push(new WorkTaskHourEntry({
                                employee: sbe.employee,
                                employeeId: sbe.employee.id,
                                workTaskId: wowt.workTask.id
                            }));
                        }
                    });
                    _this.hourEntries = entries;
                });
            }
            else if (wowt.workTask.currentBigDaySubrangeId) {
                _this.bigDaySubrangeService.get(wowt.workTask.currentBigDaySubrangeId).subscribe(function (subrange) {
                    subrange.bigDayEmployees.forEach(function (emp) {
                        if (!entries.find(function (entry) { return entry.employee.id === emp.employee.id; })) {
                            entries.push(new WorkTaskHourEntry({
                                employee: emp.employee,
                                employeeId: emp.employee.id,
                                workTaskId: wowt.workTask.id
                            }));
                        }
                    });
                    _this.hourEntries = entries;
                });
            }
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.addEmployee = function () {
        this.addingEmployee = true;
    };
    WorkWorkOrderMaintenanceComponent.prototype.getHourTotal = function () {
        var _this = this;
        this.totalHours = 0;
        (this.hourEntries).forEach(function (entry) {
            _this.totalHours += +entry.hours ? +entry.hours : 0;
        });
    };
    WorkWorkOrderMaintenanceComponent.prototype.addNewTaskHourEntry = function (employee) {
        this.selectedEmployee = employee;
        if (!this.hourEntries.find(function (entry) { return entry.employee.id === employee.id; })) {
            this.hourEntries = this.hourEntries.concat([new WorkTaskHourEntry({
                    employee: employee,
                    employeeId: employee.id,
                })]);
        }
        this.addingEmployee = false;
        this.selectedEmployee = null;
    };
    WorkWorkOrderMaintenanceComponent.prototype.completeAllIncompleteTasks = function (ngForm, overlayPanel) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (this.workOrder.workOrderWorkTasks.some(function (wowt) { return !wowt.workTask.fixedPrice && (wowt.workTask.price === undefined || wowt.workTask.price === null); })) {
            return;
        }
        this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
            if (!wowt.workTask.fixedPrice && wowt.workTask.minPrice && wowt.workTask.price < wowt.workTask.minPrice) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Unable to update task price, the price must be equal to or greater than the task\'s minimum price.'
                });
                _this.hoursEntry.controls['price' + wowt.workTask.id].setErrors({ 'belowMin': true });
                return;
            }
        });
        if (ngForm.valid) {
            this.canCompleteATask = false;
            var tasksToComplete_1 = this.workOrder.workOrderWorkTasks.filter(function (wowt) {
                return _this.canComplete &&
                    _this.helper.isTaskScheduledButNotComplete(wowt.workTask);
            });
            var toDB_1 = [];
            tasksToComplete_1.forEach(function (wowt) {
                if (!wowt.workTask.fixedPrice) {
                    _this.workTaskService.updatePrice(wowt.workTask.id, wowt.workTask.price).subscribe(function () {
                        var tasksThatAreDependentOnUpdatedTask = _this.workOrder.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.schedulableAfterCompletionOfId === wowt.workTaskId; });
                        tasksThatAreDependentOnUpdatedTask.forEach(function (woTask) { return woTask.workTask.schedulableAfterCompletionOf.price = wowt.workTask.price; });
                    }, function (error) {
                        _this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Unable to update task price, please undo task completion and refresh to try again. Contact support if the error persists.'
                        });
                        return;
                    });
                }
                _this.hourEntries.forEach(function (entry) {
                    var hoursForTask = entry.hours / tasksToComplete_1.length;
                    var taskEntry = new WorkTaskHourEntry({
                        workTaskId: wowt.workTask.id,
                        employeeId: entry.employee.id,
                        hours: hoursForTask,
                        employee: entry.employee
                    });
                    toDB_1.push(taskEntry);
                });
            });
            this.workTaskHourEntryService.updateRange(toDB_1).subscribe(function (returned) {
                _this.taskCardComponents.forEach(function (cardComponent) {
                    cardComponent.workOrderWorkTask.workTask.jobFeedback = _this.overallFeedback;
                    if (tasksToComplete_1.includes(cardComponent.workOrderWorkTask)) {
                        cardComponent.workOrderWorkTask.workTask.taskHourEntries = cardComponent.workOrderWorkTask.workTask.taskHourEntries.concat(returned.filter(function (entry) { return entry.workTaskId === cardComponent.workOrderWorkTask.workTask.id; }));
                        cardComponent.canUndo = true;
                        cardComponent.setTaskHours();
                        cardComponent.completeTask();
                        _this.addComment();
                        overlayPanel.hide();
                        _this.setCanCompleteATaskMultiday();
                    }
                });
                _this.setCanCompleteATaskRegular();
            });
        }
    };
    WorkWorkOrderMaintenanceComponent.prototype.addComment = function () {
        this.customerCommentService.add(new CustomerComment({
            comment: this.overallFeedback,
            employeeId: this.authHelperService.getDecodedAccessToken().employeeId,
            customerId: this.workOrder.customerId,
            quoteNumber: this.workOrder.quoteNumber,
            companyWide: false,
            active: true,
            customerCommentStageId: this.stages.find(function (stage) { return stage.commentStage === 'Work Order'; }).id,
            customerCommentStage: this.stages.find(function (stage) { return stage.commentStage === 'Work Order'; }),
        }));
    };
    WorkWorkOrderMaintenanceComponent.prototype.setCanCompleteATaskRegular = function () {
        var _this = this;
        var relevantTasks = this.workOrder.workOrderWorkTasks.filter(function (wowt) { return _this.helper.isTaskScheduledButNotComplete(wowt.workTask); });
        this.completeableTaskCount = relevantTasks.length;
        this.canCompleteATask = this.completeableTaskCount > 0 && this.canComplete;
    };
    WorkWorkOrderMaintenanceComponent.prototype.setCanCompleteATaskMultiday = function () {
        var _this = this;
        var relevantTasks = this.workOrder.workOrderWorkTasks.filter(function (wowt) { return _this.helper.isTaskScheduledButNotComplete(wowt.workTask); });
        this.completeableTaskCount = relevantTasks.length;
        this.canCompleteATask = this.completeableTaskCount > 0 && this.canComplete;
    };
    WorkWorkOrderMaintenanceComponent.prototype.onMyWay = function () {
        var _this = this;
        if (confirm('This will send the customer an email or SMS' +
            ' message letting them know you are on your way to do this job. Click “OK” to proceed with this notification to the customer.')) {
            this.notificationService.sendWorkWorkOrderOnWayNotification(this.workOrder.id).subscribe(function () {
                _this.messageService.add({
                    severity: 'success',
                    summary: 'Message Sent',
                    detail: 'Customer was notified Russell Tree is en route.'
                });
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Sending Message',
                    detail: 'Could not enqueue message to be sent. Please try again.'
                });
            });
        }
    };
    WorkWorkOrderMaintenanceComponent.prototype.setCanCompleteWO = function () {
        var _this = this;
        this.canCompleteWO = this.canComplete && this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Completed
            && this.workOrder.workOrderWorkTasks.every(function (woTask) { return !_this.helper.isTaskCapableOfProgress(woTask.workTask); });
    };
    WorkWorkOrderMaintenanceComponent.prototype.duplicateToQuote = function () {
        var _this = this;
        if (confirm('This will create a new Quote based on this WO. Please select "Ok" to continue.')) {
            // this.saving = true;
            this.quoteService.duplicateWorkToQuote(this.workOrder.id).subscribe(function (id) { return _this.router.navigateByUrl('quoteWorkOrderMaintenance/' + id); });
        }
    };
    return WorkWorkOrderMaintenanceComponent;
}(BaseWorkOrderMaintenanceComponent));
export { WorkWorkOrderMaintenanceComponent };
