/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./billing-profile-entry-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i4 from "primeng/components/dialog/dialog";
import * as i5 from "../billing-profile-entry/billing-profile-entry.component.ngfactory";
import * as i6 from "../billing-profile-entry/billing-profile-entry.component";
import * as i7 from "../../../services/behavior-subject.service";
import * as i8 from "../../../services/script-loader.service";
import * as i9 from "../../../services_autogenerated/generated_services";
import * as i10 from "@angular/common";
import * as i11 from "./billing-profile-entry-modal.component";
var styles_BillingProfileEntryModalComponent = [i0.styles];
var RenderType_BillingProfileEntryModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BillingProfileEntryModalComponent, data: {} });
export { RenderType_BillingProfileEntryModalComponent as RenderType_BillingProfileEntryModalComponent };
function View_BillingProfileEntryModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "ui-button-raised ui-button-warning buttonStyle"], ["label", "Cancel"], ["pButton", ""], ["style", "margin-right: 5px;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Cancel"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BillingProfileEntryModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { billingProfileEntry: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "p-dialog", [["appendTo", "body"], ["class", "billing-dialog"], ["header", "Billing Information"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.display = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Dialog_0, i3.RenderType_Dialog)), i1.ɵdid(2, 180224, null, 2, i4.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], closable: [3, "closable"], responsive: [4, "responsive"], appendTo: [5, "appendTo"], style: [6, "style"], blockScroll: [7, "blockScroll"], baseZIndex: [8, "baseZIndex"], minY: [9, "minY"], maximizable: [10, "maximizable"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), i1.ɵpod(5, { width: 0 }), (_l()(), i1.ɵeld(6, 0, null, 1, 1, "app-billing-profile-entry", [], null, [[null, "emitUpdatedStripeCustomerId"], [null, "emitClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emitUpdatedStripeCustomerId" === en)) {
        var pd_0 = (_co.afterSave($event) !== false);
        ad = (pd_0 && ad);
    } if (("emitClose" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_BillingProfileEntryComponent_0, i5.RenderType_BillingProfileEntryComponent)), i1.ɵdid(7, 114688, [[1, 4]], 0, i6.BillingProfileEntryComponent, [i7.BehaviorSubjectService, i8.ScriptLoaderService, i9.StripeGenSvc], { customer: [0, "customer"], stripeBilling: [1, "stripeBilling"] }, { emitClose: "emitClose", emitUpdatedStripeCustomerId: "emitUpdatedStripeCustomerId" }), (_l()(), i1.ɵeld(8, 0, null, 1, 4, "div", [["class", "button-group"], ["style", "float: right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingProfileEntryModalComponent_1)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "ui-button-raised ui-button-success buttonStyle"], ["label", "Save"], ["pButton", ""], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display; var currVal_1 = "Billing Information"; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = "body"; var currVal_6 = _ck(_v, 5, 0, "640px"); var currVal_7 = true; var currVal_8 = 10000; var currVal_9 = 70; var currVal_10 = false; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _co.customer; var currVal_12 = _co.stripeBilling; _ck(_v, 7, 0, currVal_11, currVal_12); var currVal_13 = !_co.isCustomer; _ck(_v, 10, 0, currVal_13); var currVal_14 = "Save"; _ck(_v, 12, 0, currVal_14); }, null); }
export function View_BillingProfileEntryModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-billing-profile-entry-modal", [], null, null, null, View_BillingProfileEntryModalComponent_0, RenderType_BillingProfileEntryModalComponent)), i1.ɵdid(1, 49152, null, 0, i11.BillingProfileEntryModalComponent, [], null, null)], null, null); }
var BillingProfileEntryModalComponentNgFactory = i1.ɵccf("app-billing-profile-entry-modal", i11.BillingProfileEntryModalComponent, View_BillingProfileEntryModalComponent_Host_0, { customer: "customer", stripeBilling: "stripeBilling", display: "display" }, { emitClose: "emitClose", emitUpdatedStripeCustomerId: "emitUpdatedStripeCustomerId" }, []);
export { BillingProfileEntryModalComponentNgFactory as BillingProfileEntryModalComponentNgFactory };
