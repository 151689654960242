<div class="ui-g">
    <div class="ui-g-12 ui-lg-12">
      <h4>Chemical Information</h4>
    </div>
</div>
<form id="chemicalForm" #chemicalForm="ngForm">
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-22-percent">
      <h6>Chemical Name</h6>
      <input pInputText #chemicalNameInput class="inputTextBox" name="name" required [(ngModel)]="chemical.chemicalName"/>
    </div>
    <div class="ui-g-12 ui-lg-22-percent">
      <h6>Trade Name</h6>
      <input pInputText class="inputTextBox" name="tradeName" required [(ngModel)]="chemical.tradeName"/>
    </div>
    <div class="ui-g-12 ui-lg-22-percent">
      <h6>Registration Number</h6>
      <input pInputText class="inputTextBox" name="registrationNumber" required [(ngModel)]="chemical.registrationNumber"/>
    </div>
    <div class="ui-g-12 ui-lg-22-percent">
      <h6>Mix Rate</h6>
      <input pInputText class="inputTextBox" name="mixRate" required [(ngModel)]="chemical.mixRate"/>
    </div>
    <div class="ui-g-12 ui-lg-12-percent pushContentsToBottom">
      <button 
        pButton
        type="submit"
        (click)="saveChemical(chemicalForm)"
        [disabled]="saving"
        class="ui-button-raised ui-button-success buttonStyle"
        label="Save">
      </button>
      <button style="margin-left: 8px;"
        pButton 
        type="button" 
        class="ui-button-raised ui-button-success buttonStyle" 
        (click)="clear(chemicalForm);" 
        label="Clear">
      </button>
    </div>
  </div>
  <div class="ui-g-12 columnPadding">
    <div *ngIf="chemicalForm.invalid && chemicalForm.submitted" class="alert alert-danger" role="alert">
      There are errors on this page. Please correct the errors and resubmit.
    </div>
  </div>
</form>
<br>
<p-table [columns]="cols" [value]="chemicals" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-chemical
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{chemical.chemicalName}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{chemical.tradeName}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{chemical.registrationNumber}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{chemical.mixRate}}</td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="editChemical(chemical)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deleteChemical(chemical)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
<br>

