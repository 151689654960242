import * as tslib_1 from "tslib";
import { QueryList } from '@angular/core';
import { QuoteWorkOrder, WellnessTask, WorkTask, QuoteGenSvc, Customer, Address, WorkOrderWorkTaskGenSvc, WorkOrderWellnessTaskGenSvc, WorkOrderPriceAdjustmentGenSvc, EmailAddress, CustomerContact, QuoteWorkOrderCompletionStatus, WellnessTaskCompletionStatus, WorkTaskCompletionStatus, NotificationsGenSvc, EmployeeGenSvc, PaymentDueOptionGenSvc, NeighborPermission, WorkTaskTreeType, WellnessTaskTreeType, MessageToCustomerTemplateGenSvc, QuoteWorkOrderManualPriorityShortcutType, StripeGenSvc, CustomerNoteGenSvc, CustomerComment, Dto } from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { BaseWorkOrderMaintenanceComponent } from '../base-work-order-maintenance.ts/base-work-order-maintenance.component';
import { FormGroup } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';
import { WorkTaskMaintenanceComponent } from '../work-task-maintenance/work-task-maintenance.component';
import { WellnessTaskMaintenanceComponent } from '../wellness-task-maintenance/wellness-task-maintenance.component';
import { Base64 } from 'src/app/models/base64';
import { MaskService } from 'src/app/services/mask.service';
import * as moment from 'moment';
var QuoteWorkOrderMaintenanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QuoteWorkOrderMaintenanceComponent, _super);
    function QuoteWorkOrderMaintenanceComponent(quoteService, emailService, authHelper, employeeService, paymentDueService, route, router, messageService, locationColorService, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc, wopaService, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService, maskService, messageTemplateService, customStripeService, customerNoteService) {
        var _this = _super.call(this, route, router, messageService, locationColorService, null, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc, wopaService, authHelper, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService) || this;
        _this.quoteService = quoteService;
        _this.emailService = emailService;
        _this.employeeService = employeeService;
        _this.paymentDueService = paymentDueService;
        _this.customStripeService = customStripeService;
        _this.customerNoteService = customerNoteService;
        _this.sendQuote = false;
        _this.templates = [];
        _this.currencyMask = maskService.currencyMaskNoDecimal;
        messageTemplateService.getActive().subscribe(function (res) { return _this.templates = res; });
        return _this;
    }
    QuoteWorkOrderMaintenanceComponent.prototype.setSelectedTasks = function () {
        this.selectedWellnessTasks = this.workOrder.workOrderWellnessTasks
            .filter(function (wowt) { return wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Work_Orders_Created
            || wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Credit_Card_Needed; })
            .map(function (wowt) { return wowt.id; });
        this.selectedWorkTasks = this.workOrder.workOrderWorkTasks
            .filter(function (wowt) { return wowt.workTask.completionStatus === WorkTaskCompletionStatus.Work_Orders_Created
            || wowt.workTask.completionStatus === WorkTaskCompletionStatus.Credit_Card_Needed; })
            .map(function (wowt) { return wowt.id; });
    };
    QuoteWorkOrderMaintenanceComponent.prototype.getWO = function (id) {
        var _this = this;
        if (id) {
            this.quoteService.get(id).subscribe(function (quote) {
                if (quote.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created || quote.completionStatus === QuoteWorkOrderCompletionStatus.Credit_Card_Needed) {
                    _this.showReset = _this.token.claimNames.includes('Undo Customer Approval');
                }
                _this.workOrder = quote;
                _this.requiredSkills = _this.getSkillsFromTasks();
                _this.requiredEquipment = _this.getEquipmentFromTasks();
                _this.isEditable = _this.isEditable && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
                    && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
                    && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Expired
                    && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Credit_Card_Needed;
                _this.canSave = _this.canSave && _this.isEditable;
                _this.canSign = _this.canSign && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
                    && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
                    && _this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Credit_Card_Needed;
                _this.setSelectedTasks();
                _this.setCanComplete();
                _this.setCanAddNewTask();
                _this.setOptions();
                _this.isLoading = false;
                _this.showReactivate = quote.completionStatus === QuoteWorkOrderCompletionStatus.Quote_Expired;
            }, function (error) {
                _this.errorMessage = 'Could not load quote. Please refresh and try again. ';
                _this.isLoading = false;
            });
        }
        else {
            var q = new QuoteWorkOrder();
            q.customer = new Customer();
            q.customer.customerContacts = [new CustomerContact({
                    active: true,
                    primary: true,
                    firstName: '',
                    lastName: '',
                    phoneNumber: null,
                    receiveInvoicingEmails: true,
                    receiveNonInvoicingEmails: true,
                })];
            q.customer.customerContacts[0].emailAddress = new EmailAddress({
                email: ''
            });
            q.customer.address = new Address();
            q.customer.active = true;
            q.customer.paymentInfoRequired = true;
            q.address = new Address();
            q.createdDate = new Date();
            q.activationDate = new Date();
            q.completionStatus = QuoteWorkOrderCompletionStatus.New;
            q.salesTaxPercentage = 0;
            q.workOrderWorkTasks = [];
            q.workOrderWellnessTasks = [];
            q.workOrderPriceAdjustments = [];
            q.salesTaxPercentage = 7.5; // default
            q.addressSameAsCustomer = true;
            q.hidePrice = false;
            q.isMultiday = false;
            q.neighborPermissionRequired = false;
            q.hasRentalExpense = false;
            this.workOrder = q;
            this.isEditable = this.isEditable && true;
            this.onTaskChange();
            this.setCanComplete();
            this.setCanAddNewTask();
            this.setOptions();
            this.isLoading = false;
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.setOptions = function () {
        var _this = this;
        this.employeeService.getRegionalManagers().subscribe(function (rms) {
            _this.employees = rms;
            var token = _this.authHelper.getDecodedAccessToken();
            if (token.roles.includes('Regional Manager') && !_this.workOrder.id && !_this.workOrder.representative) {
                _this.workOrder.representative = _this.employees.find(function (e) { return e.id === token.employeeId; });
            }
        });
        this.paymentDueService.getAll().subscribe(function (options) {
            _this.paymentDueOptions = options;
            if (!_this.workOrder.paymentDueOption) {
                _this.workOrder.paymentDueOption = _this.paymentDueOptions.find(function (option) { return option.paymentDueName === 'Payment Due Upon Completion'; });
            }
        });
    };
    QuoteWorkOrderMaintenanceComponent.prototype.neighborPermissionChange = function () {
        if (this.workOrder.neighborPermissionRequired && !this.workOrder.neighborPermission) {
            this.workOrder.neighborPermission = new NeighborPermission();
        }
        else if (!this.workOrder.neighborPermissionRequired) {
            this.workOrder.neighborPermission = undefined;
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.reactivate = function () {
        var _this = this;
        this.saving = true;
        this.isLoading = true;
        this.quoteService.reactivateQuote(this.workOrder.id).subscribe(function (quote) {
            _this.getWO(quote.id);
            _this.setPermissions();
            _this.saving = false;
            _this.isLoading = false;
        }, function (err) {
            _this.saving = false;
            _this.isLoading = false;
            _this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: JSON.parse(err.response).Message ? JSON.parse(err.response).Message : 'An unknown error occurred, unable to reactivate quote.'
            });
        });
    };
    QuoteWorkOrderMaintenanceComponent.prototype.copyOneClickLink = function () {
        var domain = new URL(window.location.href).hostname;
        if (domain === 'localhost') {
            domain = 'localhost:4200';
        }
        var encodedCustId = Base64.encode(this.workOrder.customerId.toString());
        var encodedQuoteId = Base64.encode(this.workOrder.id.toString());
        var link = domain + "/customerQuotePage?cid=" + encodedCustId + "&id=" + encodedQuoteId + "&s=m";
        window.navigator['clipboard'].writeText(link);
    };
    QuoteWorkOrderMaintenanceComponent.prototype.saveAndSend = function (ngForm) {
        this.workOrder.completionStatus = QuoteWorkOrderCompletionStatus.Sent;
        this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
            wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Sent;
        });
        this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
            wowt.workTask.completionStatus = WorkTaskCompletionStatus.Sent;
        });
        if (this.token.roles.includes('Regional Manager')) {
            this.shouldNavigateToRMSchedule = true;
        }
        this.sendQuote = true;
        this.save(ngForm);
    };
    QuoteWorkOrderMaintenanceComponent.prototype.saveWO = function () {
        var _this = this;
        this.saving = true;
        if (this.workOrder.id) {
            // Updating existing quote
            this.quoteService.update(this.workOrder, this.workOrder.id.toString())
                .subscribe(function (quote) {
                _this.afterSaveWO(quote);
            }, function (error) {
                _this.saving = false;
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Saving',
                    detail: 'Could not update the quote, please check that all fields are valid and retry.'
                });
            });
        }
        else {
            // Creating new quote
            this.quoteService.add(this.workOrder)
                .subscribe(function (quote) {
                _this.afterSaveWO(quote);
            }, function (error) {
                _this.saving = false;
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Saving',
                    detail: 'Could not add the quote, please check that all fields are valid and retry.'
                });
            });
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.afterSaveWO = function (quote) {
        var _this = this;
        this.workOrder = quote;
        this.workOrder.id = quote.id;
        if (this.sendQuote) {
            this.emailService.sendQuoteCreation(this.workOrder, Base64.encode(this.workOrder.customerId.toString()), Base64.encode(this.workOrder.id.toString())).subscribe(function (x) {
                _this.workOrder.lastSent = new Date();
                _this.saveBlobs(quote, false);
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Sending Quote',
                    detail: 'Quote saved but could not send the quote to the customer.'
                });
                _this.saveBlobs(quote, true);
            });
        }
        else {
            this.saveBlobs(quote, false);
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.saveBlobs = function (savedQuote, hasError) {
        var _this = this;
        // Currently only 1 blob manager should be not view only. If that changes, you'll need to update this and loop through all the
        // ones that are not view only and save them.
        var blobManagerComponent = this.blobManagerComponents.find(function (blobComponent) { return blobComponent.viewOnly === false; });
        if (blobManagerComponent) {
            blobManagerComponent.parentRecordId = savedQuote.id;
            blobManagerComponent.save().subscribe(function () {
                if (!hasError && _this.sendQuote) {
                    _this.sendQuote = false;
                    _this.navigate();
                }
                else {
                    _this.messageService.add({
                        severity: 'success',
                        summary: 'Save Successful',
                        detail: 'The quote was saved successfully!'
                    });
                    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    _this.router.navigateByUrl("/quoteWorkOrderMaintenance/" + _this.workOrder.id);
                    // });
                    _this.saving = false;
                }
            });
        }
        else {
            if (!hasError && this.sendQuote) {
                this.sendQuote = false;
                this.navigate();
            }
            else {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Save Successful',
                    detail: 'The quote was saved successfully!'
                });
                // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl("/quoteWorkOrderMaintenance/" + this.workOrder.id);
                // });
                this.saving = false;
            }
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.navigate = function () {
        if (this.shouldNavigateToRMSchedule) {
            // They want the RMs to navigate back to their schedule page
            this.saving = false;
            this.router.navigateByUrl('rmSchedulePage/' + this.token.employeeId);
        }
        else {
            this.saving = false;
            this.cancel('quote');
        }
    };
    // Quote can be completed if user has permission, it is not new, editable, and has at least 1 task (wellness or work)
    QuoteWorkOrderMaintenanceComponent.prototype.setCanComplete = function () {
        this.canSign = this.canSign
            && this.workOrder
            && this.workOrder.id
            && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
            && (this.workOrder.workOrderWellnessTasks.length > 0 || this.workOrder.workOrderWorkTasks.length > 0);
    };
    QuoteWorkOrderMaintenanceComponent.prototype.createWOs = function (ngForm) {
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
            // If control is a form group, loop through that group to mark each control
            // This marks the child components' form controls dirty/touched
            if (ngForm.controls[key] instanceof FormGroup) {
                var formGroup_1 = ngForm.controls[key];
                Object.keys(formGroup_1.controls).forEach(function (innerKey) {
                    formGroup_1.controls[innerKey].markAsDirty();
                    formGroup_1.controls[innerKey].markAsTouched();
                });
            }
        });
        if (!ngForm.invalid) {
            this.saving = true;
            if (confirm('This will mark the quote as converted and create separate work orders for the wellness tasks and the work tasks. ' +
                'This action cannot be canceled. Do you want to continue?')) {
                this.workOrder.completionStatus = QuoteWorkOrderCompletionStatus.Work_Orders_Created;
                this.workOrder.signedDate = new Date();
                if (this.workOrder.workOrderWellnessTasks.length > 0 || this.workOrder.workOrderWorkTasks.length > 0) {
                    this.isEditable = false;
                    this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
                        if (wowt.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Work_Orders_Created) {
                            wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
                        }
                    });
                    this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
                        if (wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Work_Orders_Created) {
                            wowt.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
                        }
                    });
                    // this.quoteService.convertToWorkOrders(this.workOrder).subscribe();
                }
                this.saveWO();
            }
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.rejectQuote = function () {
        if (confirm('This will mark the whole quote as rejected. This action cannot be canceled, do you want to continue?')) {
            this.workOrder.completionStatus = QuoteWorkOrderCompletionStatus.Quote_Rejected;
            this.workOrder.rejectedDate = new Date();
            this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
                wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
            });
            this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
                wowt.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
            });
            this.saveWO();
        }
    };
    QuoteWorkOrderMaintenanceComponent.prototype.setCanAddNewTask = function () {
        var canAdd = false;
        canAdd = this.isEditable && this.workOrder.dueDateStart !== undefined && this.workOrder.dueDateStart !== null
            && this.workOrder.dueDateEnd !== undefined && this.workOrder.dueDateEnd !== null;
        if (!this.workOrder.hasCalculatedPriority) {
            canAdd = canAdd && this.workOrder.manualPriorityLevel !== undefined && this.workOrder.manualPriorityLevel !== null;
        }
        this.canAddNewTask = canAdd;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.addNewWorkTask = function () {
        this.displayWorkTaskDialog = true;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.copyWorkTask = function (wowt) {
        this.selectedWorkTask = new WorkTask({
            active: true,
            location: wowt.workTask.location,
            workTaskTreeTypes: wowt.workTask.workTaskTreeTypes.map(function (wttt) { return new WorkTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType }); }),
            primordialTaskId: wowt.workTask.id,
            priorityLevelId: wowt.workTask.priorityLevelId,
            manualPriorityLevel: wowt.workTask.manualPriorityLevel,
            priorityShortcutId: wowt.workTask.priorityShortcutId,
            priorityTypeShortcut: wowt.workTask.priorityTypeShortcut,
            hasCalculatedPriority: wowt.workTask.hasCalculatedPriority,
            dueDateEnd: wowt.workTask.dueDateEnd,
            dueDateStart: wowt.workTask.dueDateStart,
            hardEndDate: wowt.workTask.hardEndDate,
            hardStartDate: wowt.workTask.hardStartDate,
            // schedulableAfterCompletionOf: wowt.workTask.schedulableAfterCompletionOf,
            // schedulableAfterCompletionOfId: wowt.workTask.schedulableAfterCompletionOfId,
            completionStatus: wowt.workTask.completionStatus,
            workTaskSkills: [],
            workTaskEquipment: [],
            taskHourEntries: [],
            fixedPrice: wowt.workTask.fixedPrice
        });
        this.displayWorkTaskDialog = true;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.copyWellnessTask = function (wowt) {
        this.selectedWellnessTask = new WellnessTask({
            active: true,
            location: wowt.wellnessTask.location,
            wellnessTaskTreeTypes: wowt.wellnessTask.wellnessTaskTreeTypes.map(function (wttt) { return new WellnessTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType }); }),
            primordialTaskId: wowt.wellnessTask.id,
            priorityLevelId: wowt.wellnessTask.priorityLevelId,
            manualPriorityLevel: wowt.wellnessTask.manualPriorityLevel,
            priorityShortcutId: wowt.wellnessTask.priorityShortcutId,
            priorityTypeShortcut: wowt.wellnessTask.priorityTypeShortcut,
            hasCalculatedPriority: wowt.wellnessTask.hasCalculatedPriority,
            dueDateEnd: wowt.wellnessTask.dueDateEnd,
            dueDateStart: wowt.wellnessTask.dueDateStart,
            hardEndDate: wowt.wellnessTask.hardEndDate,
            hardStartDate: wowt.wellnessTask.hardStartDate,
            // schedulableAfterCompletionOf: wowt.wellnessTask.schedulableAfterCompletionOf,
            // schedulableAfterCompletionOfId: wowt.wellnessTask.schedulableAfterCompletionOfId,
            completionStatus: wowt.wellnessTask.completionStatus,
            wellnessTaskSkills: [],
            wellnessTaskEquipment: [],
            taskHourEntries: [],
            fixedPrice: true,
            renewable: wowt.wellnessTask.renewable
        });
        this.displayWellnessTaskDialog = true;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.editWorkTask = function (wowt) {
        this.selectedWorkTask = wowt.workTask;
        this.displayWorkTaskDialog = true;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.addNewWelnessTask = function () {
        this.displayWellnessTaskDialog = true;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.editWellnessTask = function (wowt) {
        this.selectedWellnessTask = wowt.wellnessTask;
        this.displayWellnessTaskDialog = true;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.closedDialog = function (task) {
        var _this = this;
        if (task instanceof WellnessTask && task.id) {
            this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTaskId === task.id; }).wellnessTask = task;
        }
        else if (task instanceof WorkTask && task.id) {
            this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTaskId === task.id; }).workTask = task;
        }
        else if (!task) {
            if (this.selectedWellnessTask && this.selectedWellnessTask.id) {
                this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTaskId === _this.selectedWellnessTask.id; }).wellnessTask = this.wellnessTaskMaintenance.taskUnmodified;
            }
            else if (this.selectedWorkTask && this.selectedWorkTask.id) {
                this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTaskId === _this.selectedWorkTask.id; }).workTask = this.workTaskMaintenance.taskUnmodified;
            }
        }
        this.displayWellnessTaskDialog = false;
        this.displayWorkTaskDialog = false;
        this.selectedWellnessTask = undefined;
        this.selectedWorkTask = undefined;
        if (this.workOrder.id) {
            this.saveWO();
        }
        this.onTaskChange();
    };
    QuoteWorkOrderMaintenanceComponent.prototype.cancelledTask = function (task) {
        var _this = this;
        if (task instanceof WellnessTask && task.id) {
            this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTaskId === task.id; }).wellnessTask = task;
        }
        else if (task instanceof WorkTask && task.id) {
            this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTaskId === task.id; }).workTask = task;
        }
        else if (!task) {
            if (this.selectedWellnessTask && this.selectedWellnessTask.id) {
                this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTaskId === _this.selectedWellnessTask.id; }).wellnessTask = this.wellnessTaskMaintenance.taskUnmodified;
            }
            else if (this.selectedWorkTask && this.selectedWorkTask.id) {
                this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTaskId === _this.selectedWorkTask.id; }).workTask = this.workTaskMaintenance.taskUnmodified;
            }
        }
        this.displayWellnessTaskDialog = false;
        this.displayWorkTaskDialog = false;
        this.selectedWellnessTask = undefined;
        this.selectedWorkTask = undefined;
        this.onTaskChange();
    };
    QuoteWorkOrderMaintenanceComponent.prototype.onTaskChange = function () {
        this.requiredSkills = this.getSkillsFromTasks();
        this.requiredEquipment = this.getEquipmentFromTasks();
        this.setCanComplete();
    };
    QuoteWorkOrderMaintenanceComponent.prototype.getSkillsFromTasks = function () {
        var wellnessSkills = [];
        var workSkills = [];
        this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
            wellnessSkills = wellnessSkills.concat(wowt.wellnessTask.wellnessTaskSkills.map(function (wts) { return wts.skill; }));
        });
        this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
            workSkills = workSkills.concat(wowt.workTask.workTaskSkills.map(function (wts) { return wts.skill; }));
        });
        return this.helper.getUnique(wellnessSkills.concat(workSkills));
    };
    QuoteWorkOrderMaintenanceComponent.prototype.getEquipmentFromTasks = function () {
        var wellnessEquipment = [];
        var workEquipment = [];
        this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
            wellnessEquipment = wellnessEquipment.concat(wowt.wellnessTask.wellnessTaskEquipment.map(function (wte) { return wte.equipmentType; }));
        });
        this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
            workEquipment = workEquipment.concat(wowt.workTask.workTaskEquipment.map(function (wte) { return wte.equipmentType; }));
        });
        return this.helper.getUnique(wellnessEquipment.concat(workEquipment));
    };
    QuoteWorkOrderMaintenanceComponent.prototype.repChanged = function () {
        this.workOrder.representativeId = this.workOrder.representative.id;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.templateChanged = function () {
        this.workOrder.note = this.selectedTemplate.message;
    };
    QuoteWorkOrderMaintenanceComponent.prototype.approveWithoutCustomer = function () {
        var _this = this;
        if (!this.approvalComment)
            return;
        this.isApproving = true;
        if (this.workOrder.workOrderWellnessTasks.length > 0 || this.workOrder.workOrderWorkTasks.length > 0) {
            this.workOrder.workOrderWellnessTasks.forEach(function (wowt) {
                wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Credit_Card_Needed;
            });
            this.workOrder.workOrderWorkTasks.forEach(function (wowt) {
                wowt.workTask.completionStatus = WorkTaskCompletionStatus.Credit_Card_Needed;
            });
        }
        var weeksOutDateCalculation = {
            start: undefined, end: undefined
        };
        if (this.workOrder.priorityTypeShortcut && this.workOrder.priorityTypeShortcut.weeksOutStart !== undefined) {
            weeksOutDateCalculation = {
                start: moment(new Date()).add(this.workOrder.priorityTypeShortcut.weeksOutStart, 'w'),
                end: moment(new Date()).add(this.workOrder.priorityTypeShortcut.weeksOutEnd, 'w')
            };
        }
        else if (this.workOrder.manualRangeStart && this.workOrder.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week) {
            weeksOutDateCalculation = {
                start: moment(new Date()).add(this.workOrder.manualRangeStart, 'w'),
                end: moment(new Date()).add(this.workOrder.manualRangeEnd, 'w')
            };
        }
        else if (this.workOrder.manualRangeStart && this.workOrder.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day) {
            weeksOutDateCalculation = {
                start: moment(new Date()).add(this.workOrder.manualRangeStart, 'd'),
                end: moment(new Date()).add(this.workOrder.manualRangeEnd, 'd')
            };
        }
        if (this.workOrder.priorityTypeShortcut && this.workOrder.priorityTypeShortcut.weeksOutStart !== undefined) {
            this.workOrder.dueDateStart = weeksOutDateCalculation.start.toDate();
            this.workOrder.dueDateEnd = weeksOutDateCalculation.end.toDate();
        }
        if (this.workOrder.manualPriorityShortcutType && this.workOrder.manualRangeStart) {
            this.workOrder.dueDateStart = weeksOutDateCalculation.start.toDate();
            this.workOrder.dueDateEnd = weeksOutDateCalculation.end.toDate();
        }
        if (!this.workOrder.originalSignedDate) {
            this.workOrder.originalSignedDate = new Date();
        }
        this.quoteService.convertToWorkOrders(this.workOrder, this.workOrder.workOrderWellnessTasks.map(function (wowt) { return wowt.id; }).join(','), this.workOrder.workOrderWorkTasks.map(function (wowt) { return wowt.id; }).join(',')).subscribe(function (res) {
            _this.customStripeService.addOrUpdateAndApproveQuote(_this.workOrder.customerId, '', null, _this.workOrder.id, _this.workOrder.customer.manualPayment, false).subscribe(function (x) {
                _this.customerNoteService.add(new CustomerComment({
                    active: true,
                    comment: _this.approvalComment,
                    employeeId: _this.token.employeeId,
                    customerCommentStageId: 1,
                    quoteNumber: _this.workOrder.quoteNumber,
                    companyWide: false,
                    customerId: _this.workOrder.customerId
                })).subscribe();
                _this.quoteService.markAsSigned(new Dto({
                    quoteId: _this.workOrder.id,
                    selectedWellnessTaskIds: _this.workOrder.workOrderWellnessTasks.map(function (wowt) { return wowt.id; }),
                    selectedWorkTaskIds: _this.workOrder.workOrderWorkTasks.map(function (wowt) { return wowt.id; })
                }))
                    .subscribe(function () {
                    _this.emailService.sendQuoteAcceptance(_this.workOrder, Base64.encode(_this.workOrder.customerId.toString()), Base64.encode(_this.workOrder.id.toString()), true).subscribe(function () {
                        _this.router.navigateByUrl('/workOrderList');
                        _this.displayApproveDialog = false;
                        _this.isApproving = false;
                    });
                });
            });
        });
    };
    QuoteWorkOrderMaintenanceComponent.prototype.resetToEditable = function () {
        var _this = this;
        if (confirm('This will delete any WO created from this quote and allow customer to reselect tasks.')) {
            this.disableReset = true;
            this.quoteService.resetQuote(this.workOrder.id).subscribe(function (res) {
                _this.isLoading = true;
                _this.workOrder = res;
                _this.setPermissions();
                _this.getWO(res.id);
                _this.disableReset = false;
                _this.showReset = false;
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    sticky: true,
                    detail: JSON.parse(error.response).Message ? JSON.parse(error.response).Message : 'An unknown error occurred, unable to undo customer approval.'
                });
            });
        }
    };
    return QuoteWorkOrderMaintenanceComponent;
}(BaseWorkOrderMaintenanceComponent));
export { QuoteWorkOrderMaintenanceComponent };
