import { Component, OnInit, ViewChild } from '@angular/core';
import { QuoteRequest,
  QuoteRequestGenSvc,
  State,
  StateGenSvc,
  Address,
  Customer,
  BroughtBy,
  BroughtByGenSvc,
  EmployeeGenSvc,
  Employee,
  CustomerGenSvc,
  QuoteRequestCompletionStatus,
  QuoteRequestType,
  CustomerContact,
  EmailAddress,
  NotificationsGenSvc
} from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { DecodedJwt } from 'src/app/models/decodedJwt';
import { LocationColorService, AddressSetResult } from 'src/app/services/location-color.service';
import { CustomerTypes } from 'src/app/models/enums/customerTypes';
import { ContactInfoComponent } from '../customer-maintenance/contact-info/contact-info.component';
import { QuoteAndAuthenticationGenSvc, QuoteAndCustomerRequest } from '../../services_autogenerated/generated_services';
import { CustomerService } from '../../services/customer.service';
import { CustomerDropdownComponent } from '../customer-dropdown/customer-dropdown.component';
import { QuoteRequestService } from 'src/app/services/quote-request.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';

@Component({
  selector: 'app-requested-quote-maintenance',
  templateUrl: './requested-quote-maintenance.component.html',
  styleUrls: ['./requested-quote-maintenance.component.css']
})
export class RequestedQuoteMaintenanceComponent implements OnInit {

  customerTypesEnum = CustomerTypes;
  canEdit = true;

  quoteRequest: QuoteRequest;

  // use type on template
  public get Type() {
    return QuoteRequestType;
  }

  showMainContent: boolean = true;
  captchaSuccess: boolean = false;
  enterZip: boolean;
  disableDelete: boolean;
  saving: boolean;
  states: State[];
  selectedState: State;
  broughtBys: BroughtBy[];
  customers: Customer[] = [];
  selectedCustomer: Customer;
  displayCustomerDialog: boolean;
  isCustomer: boolean;
  isNew: boolean;
  editable: boolean;
  hasQuotePermissions: boolean;
  showScheduleButton: boolean;
  showNextButton: boolean;
  showVirtualSubmit: boolean;
  canTransform: boolean;
  canSendOnMyWay: boolean;
  contactPhoneNumber: string;

  isAnon: boolean;

  token: DecodedJwt;

  rms: Employee[];

  isEmbedded: boolean = false;

  errorMessage: string = '';

  primaryContactIndex = 0;
  @ViewChild(BlobManagerComponent) blobManagerComponent: BlobManagerComponent;
  @ViewChild(ContactInfoComponent) contactInfoComponent: ContactInfoComponent;
  @ViewChild(CustomerDropdownComponent) customerDropdown: CustomerDropdownComponent;
  @ViewChild(NgForm) quoteForm: NgForm;

  constructor(
    private quoteRequestService: QuoteRequestGenSvc,
    private broughtByService: BroughtByGenSvc,
    private stateService: StateGenSvc,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    protected locationColorService: LocationColorService,
    private authHelper: AuthHelperService,
    public employeeService: EmployeeGenSvc,
    private customerService: CustomerGenSvc,
    private authHelperService: AuthHelperService,
    public customCustomerService: CustomerService,
    private quoteAndAuthenticationService: QuoteAndAuthenticationGenSvc,
    private notificationService: NotificationsGenSvc,
    private customQuoteRequestService: QuoteRequestService,
    private configService: AppConfigService
  ) { }

  // current logic understanding. Request for Quotes get turned into a Quote Work Order
  // Once customer approves, it transforms into a Work Order but gets a Ready to Schedule or Ready for Lead Review status.

  ngOnInit() {
    this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
    this.broughtByService.getAll().subscribe(broughtBys => this.broughtBys = broughtBys);
    this.route.queryParams
      .subscribe(params => {
        this.isEmbedded = params.embedded;
    });
    this.editable = false;

    const id = +this.route.snapshot.paramMap.get('id');

    this.setPermissions();
    this.stateService.getAll().subscribe(states => {
      this.states = states;

      if (!id) {
        this.selectedState = states.find(state => state.name === 'Ohio');
      }
    });

    this.employeeService.getRegionalManagers().subscribe(rms => this.rms = rms);

    if (!this.isCustomer) {
      this.customerService.getActive().subscribe(customers => {
        this.customers = customers;
        if (this.quoteRequest) {
          this.selectCustomerFromQuoteRequestCustomerId();
        }
      });
    }

    if (id) {
      this.quoteRequestService.get(id).subscribe(qr => {
        this.quoteRequest = qr;
        this.selectedCustomer = qr.customer;
        this.primaryContactIndex = qr.customer.customerContacts.findIndex(x => x.primary);
        this.disableDelete = false;
        this.showScheduleButton = this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime &&
          this.quoteRequest.eventStart === undefined;
        if (this.token && this.token.roles.includes('Regional Manager')) {
          this.editable = true;
        }
        if (qr.completionStatus === QuoteRequestCompletionStatus.Transformed_to_Quote) {
          this.editable = false;
        }
        this.canTransform = this.quoteRequest.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote;
        this.canSendOnMyWay = this.canSendOnMyWay
                              && this.quoteRequest.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote;

        this.selectedState = qr.address.state;
      });
    } else {
      this.isNew = true;
      this.editable = true;
      this.quoteRequest = new QuoteRequest();
      this.quoteRequest.completionStatus = QuoteRequestCompletionStatus.Pending;

      if (this.isCustomer && this.token) {
        this.customerService.get(this.token.id).subscribe(customer => {
          this.quoteRequest.customer = customer;
          if (this.quoteRequest.addressSameAsCustomer) {
            this.quoteRequest.address = customer.address;
            this.selectedState = this.quoteRequest.address.state;
          }
        });
      }

      this.quoteRequest.addressSameAsCustomer = true;
      this.quoteRequest.broughtBy = new BroughtBy();
      this.quoteRequest.createdDate = new Date();
      this.disableDelete = true;
      this.quoteRequest.isUrgent = false;

      if (this.isAnon) {
        const newCustomer = new Customer({
          active: true,
          address: new Address(),
          customerTypeId: 1
        });

        this.quoteRequest.customer = newCustomer;
        this.quoteRequest.address = new Address();
        this.addressSameAsCustomerToggle();
      }
    }
  }

  addNewCustomer() {
    const customerContacts = [];
    const newContact = new CustomerContact();
    newContact.firstName = '';
    newContact.lastName = '';
    newContact.primary = true;
    newContact.active = true;
    newContact.emailAddress = new EmailAddress({email: ''});
    newContact.receiveInvoicingEmails = true,
    newContact.receiveNonInvoicingEmails = true,

    customerContacts.push(newContact);

    const newCustomer = new Customer({
      customerContacts: customerContacts,
      active: true,
      address: new Address(),
      customerTypeId: 1,
      paymentInfoRequired: true
    });

    this.quoteRequest.customer = newCustomer;
    this.displayCustomerDialog = true;
  }

  allowNewCustomer() {
    return this.quoteRequest && !(this.quoteRequest as any).quoteId;
  }

  private selectCustomerFromQuoteRequestCustomerId() {
    this.selectedCustomer = this.customers.find(c => c.id === this.quoteRequest.customerId);
    if (this.selectedCustomer) {
      this.primaryContactIndex = this.selectedCustomer.customerContacts.findIndex(x => x.primary);
    }
  }

  setCustomer(id: number) {
    if (id) {
      this.customerService.get(id).subscribe(c => {
        this.selectedCustomer = c;
        this.quoteRequest.customer = c;
        this.primaryContactIndex = c.customerContacts.findIndex(x => x.primary);
        this.addressSameAsCustomerToggle();
      }, error => console.log(error)); // TODO handle error properly
    } else {
      this.selectedCustomer = null;
      this.quoteRequest.customer = null;
      this.quoteRequest.addressSameAsCustomer = true;
    }
  }

  closedCustomerDialog(customer: Customer) {
    this.displayCustomerDialog = false;

    if (customer) {
      this.quoteRequest.customer = customer;
      this.customers = [...this.customers, customer];
      this.selectedCustomer = customer;
      this.primaryContactIndex = customer.customerContacts.findIndex(x => x.primary);
      this.addressSameAsCustomerToggle();
      this.customerDropdown.setCustomerFromNew(customer);
    } else {
      this.quoteRequest.customer = null;
      this.quoteRequest.addressSameAsCustomer = true;
    }
  }

  addressSameAsCustomerToggle() {
    if (this.quoteRequest.addressSameAsCustomer) {
      this.quoteRequest.address = this.quoteRequest.customer.address;
    } else {
      this.quoteRequest.address = new Address();
      this.quoteRequest.address.state = this.selectedState;
    }
  }

  quoteOptionChanged() {
    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Anytime) {
      this.showScheduleButton = false;
      this.showNextButton = false;
    } else if (this.quoteRequest.quoteRequestType === QuoteRequestType.Virtual) {
      this.showScheduleButton = false;
      this.showNextButton = true;
    } else if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
      localStorage.setItem('zipCode', this.quoteRequest.address.zip);
      this.showScheduleButton = true;
      this.showNextButton = false;
      this.checkForRM();
    }
  }

  chooseAnytime() {
    this.quoteRequest.quoteRequestType = QuoteRequestType.Anytime;
  }

  chooseFirmtime() {
    this.quoteRequest.quoteRequestType = QuoteRequestType.Firmtime;
    this.showScheduleButton = true;
  }

  // goToSchedule() {
  //   localStorage.setItem('zipCode', this.quoteRequest.address.zip);
  //   const extras: NavigationExtras = {
  //     queryParams: { 'asAnonymous': 'true', 'embedded': this.isEmbedded }
  //   };
  //   this.router.navigate(['/ftSchedulePage/' + this.quoteRequest.representative.id], extras);
  // }

  setPermissions() {
    this.token = this.authHelper.getDecodedAccessToken();

    this.isCustomer = this.token == null || this.token.userType === 'customer';
    if (!this.token) {
      this.hasQuotePermissions = true;
      this.isAnon = true;
    } else {
      const claims = this.token.claimNames;
      if (claims.includes('View/Edit Request for Quote')
          || claims.includes('View/Edit Quote')
          || claims.includes('View/Edit Their Own Work Order')) {
        this.hasQuotePermissions = true;
      }

      this.canSendOnMyWay = this.hasQuotePermissions
                            && (this.token.roles.includes('Admin') || this.token.roles.includes('Regional Manager'));
      if (this.token.claimNames.includes('Full Control')) {
        this.editable = true;
      }
    }
  }

  save(ngForm: NgForm): void {
    Object.keys(ngForm.controls).forEach( key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (this.isAnon) {
      this.errorMessage = '';
    }

    Object.keys(ngForm.controls).forEach( key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    if (ngForm.valid) {
      if (this.isAnon) {
        if (!this.quoteRequest.recaptchToken) {
          this.errorMessage = 'Please check the reCAPTCHA.';
          return;
        }
        this.contactInfoComponent.preSave();
      }

      this.saving = true;

      this.locationColorService.geocodeAddress(this.quoteRequest.address).subscribe(res => {
        const result = this.locationColorService
              .setAddressWithGeocodeResponse(this.quoteRequest.address, res, this.isCustomer || this.isAnon, true);
        if (result === AddressSetResult.Successful) {
          this.saveRequest();
        } else if (result === AddressSetResult.Failed) {
          this.locationColorService.geocodeAddressLeastSpecific(this.quoteRequest.address).subscribe(res2 => {
            const secondResult = this.locationColorService
                  .setAddressWithGeocodeResponse(this.quoteRequest.address, res2, this.isCustomer || this.isAnon);
            if (secondResult !== AddressSetResult.Cancelled) {
              this.saveRequest();
            } else {
              this.saving = false;
            }
          });
        } else if (result === AddressSetResult.Cancelled) {
          this.saving = false;
        }
      }, error => {
        this.saveRequest();

        if (!this.isCustomer && !this.isAnon) {
          // Customers don't need to see this message
          this.messageService.add({
            severity: 'error',
            summary: 'Geocode Response Failed',
            detail: 'Could not get the Geocode Response from the MapQuest provider. The color will be set to black.'
          });
        }
      });
    } else {
      if (this.quoteForm.value.broughtBy === 9 && !this.quoteForm.value.reason) {
        this.errorMessage = 'Since you selected Other, please explain what brought you to our site by filling in the text-box.';
      } else {
        this.errorMessage = 'There are errors on this page. Please correct the errors and resubmit.';
      }
    }
  }

  disableVirtualSubmit() {
    return   !this.blobManagerComponent.filesToUpload.every(f => f.progress == 100) 
          || !this.blobManagerComponent.filesToUpload 
          || this.blobManagerComponent.filesToUpload.length == 0;
  }

  checkForRM(): boolean {
    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
      // Find an RM who has been assigned the zip code AND has assigned that zip code to a specific day
      this.quoteRequest.representative =
        this.rms.find(rm => rm.zipCodeAssignments.some(zipCode => zipCode.zipCode.code === this.quoteRequest.address.zip) &&
        rm.regionalManagerTimeSlots.some(timeSlot =>
          timeSlot.regionalManagerTimeSlotZipCodes.some(zipCode => zipCode.zipCode.code === this.quoteRequest.address.zip)
        )
      );

      if (!this.quoteRequest.representative) {
        if (confirm(`Your zip code or area is not recognized in the normal range or region. We can only accommodate an anytime quote at this time. Please contact Russell Tree at ${this.contactPhoneNumber} with any questions. Please click Ok to continue.`)) {
          this.quoteRequest.quoteRequestType = undefined;
          this.quoteRequest.quoteRequestType = QuoteRequestType.Anytime;
          this.quoteRequest.representative = this.rms.find(rm => rm.zipCodeAssignments.some(zipCode =>
            zipCode.zipCode.code === '00000'));
          this.showScheduleButton = false;
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  private saveRequest() {
    if (this.quoteRequest.id) {
      this.updateQuote();
    } else {
      if (this.checkForRM()) {
        if (this.token) {
          this.addQuoteAndUpdateCustomer();
        } else {
          this.addQuoteAndCustomer();
        }
      } else {
        this.saving = false;
      }
    }
  }

  updateQuote() {
    this.quoteAndAuthenticationService.updateQuoteAndUpdateCustomer(this.quoteRequest)
    .subscribe(savedQR => {
      this.saveBlobs(savedQR);
    }, this.quoteRequestError);
  }

  addQuoteAndUpdateCustomer() {
    this.quoteAndAuthenticationService.addQuoteAndUpdateCustomer(this.quoteRequest)
    .subscribe(qr => {

      if (qr.token != null) {
        // localStorage.setItem(this.authHelperService.authToken, qr.token);
      }
      
      this.saveBlobs(qr);
    }, this.quoteRequestError);
  }

  addQuoteAndCustomer() {
    this.quoteAndAuthenticationService.addQuoteAndCustomer(this.quoteRequest)
    .subscribe(qr => {
      if (qr.token != null) {
        // localStorage.setItem(this.authHelperService.authToken, qr.token);
        this.saveBlobs(qr);
      } else {
        this.saveBlobs(qr);
        if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {}
      }
    }, this.quoteRequestError);
  }

  quoteRequestError = (error: any) => {
    this.saving = false;
    if (JSON.parse(error.response).Message) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Saving',
        detail: JSON.parse(error.response).Message,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Saving',
        detail: 'Could not save the quote request, please check that all fields are valid and retry.'
      });
    }
  }

  callDoneSaving() {
    this.doneSaving();
  }

  private saveBlobs(savedQuoteRequest: QuoteRequest | QuoteAndCustomerRequest) {
    this.captchaSuccess = true;
    this.blobManagerComponent.parentRecordId = savedQuoteRequest.id;
    this.quoteRequest = savedQuoteRequest as QuoteRequest;

    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !this.quoteRequest.eventStart) {
      localStorage.setItem('quoteRequestId', savedQuoteRequest.id.toString());
    }

    this.blobManagerComponent.save()
      .subscribe(() => {
        this.doneSaving();
      },
      (error) => {
        this.isNew = false;
        this.saving = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error Saving',
          detail: 'Could not save the selected upload(s) to the quote request.'
        });
      });
  }

  private navigate(id) {
    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !this.quoteRequest.eventStart) {
      localStorage.setItem('quoteRequestId', id.toString());
      localStorage.setItem('zipCode', this.quoteRequest.address.zip);
      parent.postMessage('scrollEvent', '*');

      const extras: NavigationExtras = {
        queryParams: { 'asAnonymous': 'true', 'embedded': this.isEmbedded }
      };

      this.router.navigate(['/ftSchedulePage/' + this.quoteRequest.representative.id], extras);
    } else {
      this.doneSaving();
    }
  }

  private navigateToQuote(id: number) {
    this.router.navigateByUrl('/quoteWorkOrderMaintenance/' + id);
    this.saving = false;
  }

  turnToQuote(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach( key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    // A disabled form cannot be valid, so just make sure the form is not invalid
    if (!ngForm.invalid) {
      this.saving = true;

      this.quoteRequestService.convertToQuote(this.quoteRequest).subscribe(quote => {
        this.navigateToQuote(quote.id);
      });
    }
  }

  onStateSelect(state: State) {
    this.quoteRequest.address.stateId = state.id;
    this.quoteRequest.address.state = state;
  }

  virtualNext() {
    this.showMainContent = false;
    this.showNextButton = false;
    this.showVirtualSubmit = true;
  }
  
  back() {
    this.showMainContent = true;
    this.showNextButton = true;
  }

  private doneSaving = () => {
    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Anytime && this.isNew) {
      this.notificationService.sendAnytimeConfirmEmail(this.quoteRequest).subscribe();
    }

    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Virtual && this.isNew) {
      if (this.isNew) {
        this.virtualNext();
        this.isNew = false;
        this.notificationService.sendVirtualConfirmEmail(this.quoteRequest).subscribe();
        return; // exit early when it's new since there's a second page for virtual quotes
      }
    }

    if (this.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !this.quoteRequest.eventStart) {
      this.showScheduleButton = false;
      this.navigate(this.quoteRequest.id);
      this.saving = false;
    } else if (this.isAnon) {
      this.messageService.add({
        severity: 'success',
        summary: 'Quote Request Created',
        detail: 'Your quote request was created successfully.',
        sticky: true
      });

      const confirmLink = this.configService.appConfig.quoteRequestConfirmationLink;
      if (this.isEmbedded && this.quoteRequest.quoteRequestType !== QuoteRequestType.Firmtime) {
        window.top.location.href = confirmLink;
      } else {
        window.location.assign(confirmLink);
      }
    } else if (this.isCustomer && this.token) {
      this.router.navigateByUrl('/customerLandingPage/' + this.token.id);
    } else {
      this.router.navigateByUrl('/quoteList');
    }

    this.saving = false;
  }

  cancel() {
    this.errorMessage = '';
    if (this.isCustomer) {
      this.router.navigateByUrl('/customerLandingPage/' + this.token.id);
    } else {
      this.router.navigateByUrl('/quoteList');
    }
  }

  delete() {
    if (confirm('Are you sure you want to permanently delete this quote request?')) {
      this.quoteRequestService.delete(this.quoteRequest.id).subscribe(() => {
        this.router.navigateByUrl('/quoteList');
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the quote request, please check that the quote request exists and retry.'
        });
      });
    }
  }

  onMyWay() {
    this.customQuoteRequestService.onMyWay(this.quoteRequest);
  }

  customSearch = (searchTerm: string, customer: Customer) => {
    return this.customCustomerService.customerSearch(searchTerm, customer);
  }

  isEditMode() {
    if (this.quoteRequest && this.quoteRequest.id > 0 && !this.isNew) {
      return true;
    } else {
      return false;
    }
  }

  isDisabledMode() {
    return !this.hasQuotePermissions || !this.editable;
  }

  isServiceAddressDisabled() {
    return !this.hasQuotePermissions || this.quoteRequest.addressSameAsCustomer || !this.editable;
  }

  isCustomerEditable() {
    return !this.isCustomer && !this.isAnon && this.isNew;
  }

  onRecaptchaResolved(res: string) {
    this.quoteRequest.recaptchToken = res;
  }

}
