import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SystemSettingDTO, SystemSettingGenSvc } from 'src/app/services_autogenerated/generated_services';
var SettingRowComponent = /** @class */ (function () {
    function SettingRowComponent(systemSettingService, formBuilder, messageService) {
        this.systemSettingService = systemSettingService;
        this.formBuilder = formBuilder;
        this.messageService = messageService;
        this.settingForm = this.formBuilder.group({
            value: [0, Validators.required]
        });
    }
    SettingRowComponent.prototype.ngOnInit = function () {
        this.settingForm.controls['value'].setValue(this.setting.value);
    };
    Object.defineProperty(SettingRowComponent.prototype, "value", {
        get: function () { return this.settingForm.get('value'); },
        enumerable: true,
        configurable: true
    });
    SettingRowComponent.prototype.save = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (key) {
            formGroup.controls[key].markAsDirty();
            formGroup.controls[key].markAsTouched();
        });
        if (formGroup.valid) {
            this.isLoading = true;
            this.setting.value = this.value.value;
            this.systemSettingService.update(this.setting).subscribe(function () {
                _this.isLoading = false;
                _this.messageService.add({
                    severity: 'success',
                    summary: 'Saved Successfully',
                    detail: "The value for " + _this.setting.name + " have been updated."
                });
            }, function (error) {
                if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
                    _this.isLoading = false;
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error Updating Value',
                        detail: JSON.parse(error.response).ExceptionMessage,
                        sticky: true
                    });
                }
                else {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Save Failed',
                        detail: 'The setting\'s value were not updated, refresh the page and try again.'
                    });
                }
            });
        }
    };
    return SettingRowComponent;
}());
export { SettingRowComponent };
