import { BigDayEmployee, BigDayEquipment } from '../services_autogenerated/generated_services';
import * as i0 from "@angular/core";
var MultidayDragAndDropService = /** @class */ (function () {
    function MultidayDragAndDropService() {
        this.allCDKEquipmentListIds = ['equipmentPanelList'];
        this.allCDKEmployeeListIds = ['employeePanelList'];
    }
    MultidayDragAndDropService.prototype.isEmployee = function (item) {
        return item.isEmployee !== undefined;
    };
    MultidayDragAndDropService.prototype.isEquipment = function (item) {
        return item.isEquipment !== undefined;
    };
    MultidayDragAndDropService.prototype.addSubrangeToCDKLists = function (subrange) {
        this.allCDKEmployeeListIds.push("subrangeEmployeeList-" + subrange.id);
        this.allCDKEquipmentListIds.push("subrangeEquipmentList-" + subrange.id);
    };
    MultidayDragAndDropService.prototype.startDrag = function (event) {
        this.eqpOrEmp = event;
    };
    MultidayDragAndDropService.prototype.dropOntoSubrange = function (event, subrange) {
        console.log('DROP ONTO SUBRANGE');
        var droppedItem = event.previousContainer.data[event.previousIndex];
        if (event.previousContainer === event.container) {
            // Do not allow movement in the array
        }
        else if (this.isEmployee(droppedItem) && subrange.bigDayEmployees.find(function (e) { return e.employeeId === droppedItem.id; })) {
            // bucket already has that employee, do not duplicate it.
        }
        else if (this.isEquipment(droppedItem) && subrange.bigDayEquipment.find(function (e) { return e.equipmentId === droppedItem.id; })) {
            // bucket already has the equipmnet, do not duplicate it.
        }
        else if (this.isEmployee(droppedItem)) {
            // add employee to bucket
            var bigDayEmp = new BigDayEmployee();
            bigDayEmp.id = 0;
            bigDayEmp.bigDayBucketSubrangeId = subrange.id;
            bigDayEmp.employeeId = droppedItem.id;
            bigDayEmp.employee = droppedItem;
            subrange.bigDayEmployees.push(bigDayEmp);
        }
        else if (this.isEquipment(droppedItem)) {
            // add equipment to bucket
            var bigDayEqp = new BigDayEquipment();
            bigDayEqp.id = 0;
            bigDayEqp.bigDayBucketSubrangeId = subrange.id;
            bigDayEqp.equipmentId = droppedItem.id;
            bigDayEqp.equipment = droppedItem;
            subrange.bigDayEquipment.push(bigDayEqp);
        }
        else {
        }
    };
    MultidayDragAndDropService.ngInjectableDef = i0.defineInjectable({ factory: function MultidayDragAndDropService_Factory() { return new MultidayDragAndDropService(); }, token: MultidayDragAndDropService, providedIn: "root" });
    return MultidayDragAndDropService;
}());
export { MultidayDragAndDropService };
