<br>
<form id="equipmentForm" #equipmentForm="ngForm">
    <h4>Equipment Information</h4>
    <div class="ui-g">
        <div class="ui-g-12 ui-lg-2">
            <h6>Number</h6>
            <input pInputText class="inputTextBox" name="number" [(ngModel)]="equipment.number" *ngIf="equipment"
                required />
        </div>
        <div class="ui-g-12 ui-lg-4">
            <h6>Name</h6>
            <input pInputText class="inputTextBox" name="name" [(ngModel)]="equipment.name" *ngIf="equipment"
                required />
        </div>
        <div class="ui-g-12 ui-lg-3">
            <h6>Type</h6>
            <ng-select class="custom" required [items]="equipmentTypeOptions" placeholder="Select a Type" [selectOnTab]="true"
                name="equipmentType" bindLabel="type" [(ngModel)]="equipment.equipmentType" *ngIf="equipment">
            </ng-select>
        </div>
        <div class="ui-g-12 ui-lg-3">
            <h6>Status</h6>
            <input pInputText class="inputTextBox" name="status" [ngModel]="equipmentStatus" *ngIf="equipment"
                disabled />
        </div>
        <!--
        <div class="ui-g-12 ui-lg-1">
            <h6>Hourly Cost</h6>
            <input pInputText class="inputTextBox" name="baseFee" numeric [textMask]="{mask: currencyMask}" [ngModel]="equipment.baseFee" (ngModelChange)="equipment.baseFee=$event" *ngIf="equipment"/>
        </div>
        -->
        <!-- <div class="ui-g-12 ui-lg-6 multiSelect">
            <h6>Alternative(s)</h6>
            <p-multiSelect [options]="equipmentAlternativeOptions" name="equipmentAlternatives" [(ngModel)]="equipment.equipmentAlternatives" [panelStyle]="{minWidth:'12em'}" optionLabel="alternative.name" *ngIf="equipment">
                <ng-template let-value pTemplate="selectedItems">
                    <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                        <span>{{val.alternative.name}}</span>
                    </div>
                    <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Choose</span>
                </ng-template>
                <ng-template let-alternativeOption pTemplate="item">
                    {{alternativeOption.value.alternative.name}}
                </ng-template>
            </p-multiSelect>
        </div> -->
        <div class="ui-g-4 multiSelect">
            <h6>Skill/Certification</h6>
            <p-multiSelect [options]="equipmentSkillOptions" name="equipmentSkills"
                [(ngModel)]="equipment.equipmentSkills" [panelStyle]="{minWidth:'12em'}" optionLabel="skill.name"
                *ngIf="equipment">
                <ng-template let-value pTemplate="selectedItems">
                    <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                        <span>{{val.skill.name}}</span>
                    </div>
                    <span *ngIf="!value || value.length === 0"
                        class="ui-multiselected-empty-token ui-corner-all">Choose</span>
                </ng-template>
                <ng-template let-skillOption pTemplate="item">
                    {{skillOption.value.skill.name}}
                </ng-template>
            </p-multiSelect>
        </div>
        <div class="ui-g-4"></div>
        <div class="ui-g-4">
            <h6>Add Additional Information</h6>
            <p-checkbox binary="true" name="additionalInfo" [(ngModel)]="additionalInfo">
            </p-checkbox>
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>VIN</h6>
            <input pInputText class="inputTextBox" name="vIN" [(ngModel)]="equipment.vIN" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Year</h6>
            <input pInputText class="inputTextBox" name="year" [(ngModel)]="equipment.year" type="number" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Make</h6>
            <input pInputText class="inputTextBox" name="make" [(ngModel)]="equipment.make" type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Model</h6>
            <input pInputText class="inputTextBox" name="model" [(ngModel)]="equipment.model" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Motor/Engine</h6>
            <input pInputText class="inputTextBox" name="motor" [(ngModel)]="equipment.motor" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Boom</h6>
            <input pInputText class="inputTextBox" name="boom" [(ngModel)]="equipment.boom" type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Serial #</h6>
            <input pInputText class="inputTextBox" name="serialNo" [(ngModel)]="equipment.serialNo" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Fuel</h6>
            <input pInputText class="inputTextBox" name="fuel" [(ngModel)]="equipment.fuel" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Tires</h6>
            <input pInputText class="inputTextBox" name="tires" [(ngModel)]="equipment.tires" type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Engine Oil</h6>
            <input pInputText class="inputTextBox" name="engineOil" [(ngModel)]="equipment.engineOil" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Oil Drain Plug</h6>
            <input pInputText class="inputTextBox" name="oilDrainPlug" [(ngModel)]="equipment.oilDrainPlug"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Engine Oil Filter</h6>
            <input pInputText class="inputTextBox" name="engineOilFilter" [(ngModel)]="equipment.engineOilFilter"
                type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Fuel First Filter</h6>
            <input pInputText class="inputTextBox" name="fuelFirstFilter" [(ngModel)]="equipment.fuelFirstFilter"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Fuel/Water Sep</h6>
            <input pInputText class="inputTextBox" name="fuelWaterSep" [(ngModel)]="equipment.fuelWaterSep"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Air Filter</h6>
            <input pInputText class="inputTextBox" name="airFilter" [(ngModel)]="equipment.airFilter" type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Hydraulic Oil</h6>
            <input pInputText class="inputTextBox" name="hydraulicOil" [(ngModel)]="equipment.hydraulicOil"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Hydraulic Oil Filter</h6>
            <input pInputText class="inputTextBox" name="hydraulicOilFilter" [(ngModel)]="equipment.hydraulicOilFilter"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Grease</h6>
            <input pInputText class="inputTextBox" name="grease" [(ngModel)]="equipment.grease" type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Coolant</h6>
            <input pInputText class="inputTextBox" name="coolant" [(ngModel)]="equipment.coolant" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Axel Filter</h6>
            <input pInputText class="inputTextBox" name="axelFilter" [(ngModel)]="equipment.axelFilter" type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Brakes</h6>
            <input pInputText class="inputTextBox" name="brakes" [(ngModel)]="equipment.brakes" type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Power Steering Oil</h6>
            <input pInputText class="inputTextBox" name="powerSteeringOil" [(ngModel)]="equipment.powerSteeringOil"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Transmission Type</h6>
            <input pInputText class="inputTextBox" name="transmissionType" [(ngModel)]="equipment.transmissionType"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Transmission Oil</h6>
            <input pInputText class="inputTextBox" name="transmissionOil" [(ngModel)]="equipment.transmissionOil"
                type="text" />
        </div>

        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Transmission Filter</h6>
            <input pInputText class="inputTextBox" name="transmissionFilter" [(ngModel)]="equipment.transmissionFilter"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Differential Type</h6>
            <input pInputText class="inputTextBox" name="differentialType" [(ngModel)]="equipment.differentialType"
                type="text" />
        </div>
        <div class="ui-g-12 ui-lg-4" *ngIf="additionalInfo">
            <h6>Differential Oil</h6>
            <input pInputText class="inputTextBox" name="differentialOil" [(ngModel)]="equipment.differentialOil"
                type="text" />
        </div>

        <div class="ui-g-12">
            <h6>Equipment Service Records</h6>
            <button type="button" class="btn btn-success addNewButton" (click)="addNewServiceRecord();">Add Service
                Record</button>
            <p-table [columns]="cols" [value]="equipment.equipmentServiceRecords" [responsive]="true" *ngIf="equipment"
                class="innerMaintenanceTable">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <td pEditableColumn><span class="ui-column-title">{{columns[0].header}}</span>
                            <input pInputText [name]="'description'+rowIndex" [(ngModel)]="rowData.description"
                                style="width:100%;" />
                        </td>
                        <td pEditableColumn><span class="ui-column-title">{{columns[1].header}}</span>
                            <p-calendar [(ngModel)]="rowData.dateServicePerformed"
                                [name]="'dateServicePerformed'+rowIndex" [appendTo]="'body'" class="dateInTable"
                                dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY">
                            </p-calendar>
                        </td>
                        <td pEditableColumn><span class="ui-column-title">{{columns[2].header}}</span>
                            <input pInputText type="number" [name]="'mileage'+rowIndex" [(ngModel)]="rowData.mileage"
                                style="width:100%;" />
                        </td>
                        <td pEditableColumn><span class="ui-column-title">{{columns[3].header}}</span>
                            <p-calendar [(ngModel)]="rowData.inactiveDateFrom" [name]="'inactiveDateFrom'+rowIndex"
                                (ngModelChange)="onESRFromDateChange(equipmentForm, rowIndex)" [appendTo]="'body'"
                                class="dateInTable" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" required>
                            </p-calendar>
                        </td>
                        <td pEditableColumn><span class="ui-column-title">{{columns[4].header}}</span>
                            <p-calendar [(ngModel)]="rowData.inactiveDateTo" [minDate]="rowData.inactiveDateFrom"
                                [appDateGreaterThanValidator]="rowData.inactiveDateFrom"
                                (ngModelChange)="setEquipmentStatus()" [name]="'inactiveDateTo'+rowIndex"
                                [appendTo]="'body'" class="dateInTable" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY">
                            </p-calendar>
                        </td>
                        <td><span class="ui-column-title">{{columns[5].header}}</span>
                            <button
                                type="button"
                                class="btn btn-light buttonOutline"
                                style="margin-left:5px;"
                                (click)="removeServiceRecord(rowIndex);">
                                <i class="pi pi-trash" style="color:red" ></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- <div class="ui-g-6">
                <h6>Description</h6>
                <input pInputText class="inputTextBox" name="description" [(ngModel)]="equipment.description" *ngIf="equipment"/>
            </div> -->
        <!-- <div class="ui-g-12 ui-lg-3">
                <h6>Inactive Date From</h6>
                <p-calendar
                    name="inactiveDateFrom"
                    [(ngModel)]="equipment.inactiveDateFrom"
                    (ngModelChange)="onFromDateChange(equipmentForm)"
                    dateFormat="mm/dd/yy" 
                    placeholder="MM/DD/YYYY" 
                    *ngIf="equipment">
                </p-calendar>
            </div>
            <div class="ui-g-12 ui-lg-3">
                <h6>Inactive Date To</h6>
                <p-calendar
                    name="inactiveDateTo"
                    [(ngModel)]="equipment.inactiveDateTo" 
                    [minDate]="equipment.inactiveDateFrom"
                    [appDateGreaterThanValidator]="equipment.inactiveDateFrom"
                    dateFormat="mm/dd/yy" 
                    placeholder="MM/DD/YYYY" 
                    *ngIf="equipment">
                </p-calendar>
            </div> -->
    </div>
    <div class="ui-g">
        <div class="ui-g-12 columnPadding">
            <button pButton type="button" class="ui-button-raised ui-button-success buttonStyle"
                (click)="save(equipmentForm)" [disabled]="saving" label="Save">
            </button>
            <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" (click)="cancel()"
                label="Cancel">
            </button>
            <button pButton type="button" class="ui-button-raised ui-button-danger buttonStyle" (click)="delete()"
                [disabled]="disableDelete" label="Delete">
            </button>
        </div>
    </div>
</form>