import { Component, OnInit, ViewChild, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { PaymentType } from 'src/app/models/paymentType';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { CustomerGenSvc, Customer, SystemSettingGenSvc } from 'src/app/services_autogenerated/generated_services';
import { StripeBillingComponent } from '../../stripe-billing/stripe-billing.component';
import { BasePaymentProcessingComponent } from '../../base-payment-processing/base-payment-processing.component';
import { QuoteRequest,
  QuoteWorkOrder,
  QuoteRequestType,
  QuoteRequestGenSvc,
  StripeGenSvc,
  ChargeFirmtimeRequest } from '../../../services_autogenerated/generated_services';
import { ActivatedRoute } from '@angular/router';
import { SwaggerExceptionResponse } from '../../../models/SwaggerExceptionResponse';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { AppConfigService } from 'src/app/services/app-config.service';

declare var Plaid: any;

@Component({
  selector: 'app-quote-payment',
  templateUrl: './quote-payment.component.html',
  styleUrls: ['./quote-payment.component.css']
})
export class QuotePaymentComponent extends BasePaymentProcessingComponent implements OnInit  {
  QuoteRequestType = QuoteRequestType;
  @ViewChild(StripeBillingComponent) stripeBillingComponent: StripeBillingComponent;

  // tslint:disable: no-input-rename
  @Input('paymentAmount') amount: number;
  amountString: string;
  @Input('customerId') customerId: string;
  @Input('quote') quote: QuoteRequest | QuoteWorkOrder;
  @Input('maxWidth') maxWidth = '800px';
  @Input('showManualPaymentChargeMessage') showManualPaymentChargeMessage = false;

  @Output() billingSaved = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() convertQuote = new EventEmitter();

  error: string;
  serverError: string;
  saving = false;
  customer: Customer;
  submitLabel = '';
  paymentTypes = [PaymentType.Credit, PaymentType.ACH, PaymentType.Manual];
  isQuoteRequest = false;
  showLeaveConfirm = true;
  contactPhoneNumber: string;

  quoteStep: number = 1;
  hasToggledPayment: boolean = false;

  numberOfDaysForCash: number;

  constructor(
    behaviorSubjectService: BehaviorSubjectService,
    scriptLoaderService: ScriptLoaderService,
    customStripeService: StripeGenSvc,
    private customerService: CustomerGenSvc,
    private quoteRequestService: QuoteRequestGenSvc,
    private route: ActivatedRoute,
    private authService: AuthHelperService,
    private configService: AppConfigService,
    private systemSettingsService: SystemSettingGenSvc
  ) {
    super(
      behaviorSubjectService,
      scriptLoaderService,
      customStripeService,
      );
   }

  ngOnInit() {
    this.systemSettingsService.getManualPaymentDaysDue().subscribe(res => this.numberOfDaysForCash = parseFloat(res.value));
    this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
    this.amountString = this.amount.toFixed(2);
    this.getQuote();
    this.getCustomer();
    this.setupPlaid();
    this.isQuoteRequest = this.quoteIsRequest(this.quote);
    // console.log('ISQuoteRequest: ');
    // console.log(this.isQuoteRequest);
    if (this.isQuoteRequest) {
      this.forcePaymentType(PaymentType.Credit);
    }

    // var showAlert = true;
    // document.addEventListener("mouseleave", function(event){
    //   if((event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) && showAlert)
    //   {
    //     showAlert = false;
    //     alert('Are you sure you want to leave this page? If you don’t provide your credit/debit card information your services will not be in our queue to be completed.');
    //   }
    // });
  }

  getCustomer() {
    const token = this.authService.getDecodedAccessToken();

    if (token) {
      this.customerService.get(+this.customerId).subscribe(customer => {
        this.customer = customer;
      });
    } else {
      this.customerService.getAnonymous(+this.customerId).subscribe(customer => {
        this.customer = customer;
      });
    }
  }

  getQuote() {
    // console.log('the quote: ');
    // console.log(this.quote);
    if (!this.quote) {
      const quoteId = +this.route.snapshot.paramMap.get('id');
      this.quoteRequestService.get(quoteId)
        .subscribe(quote => {
          this.quote = quote;
          // if (quote.quoteRequestType == QuoteRequestType.Firmtime) {
          //   this.amount = 100;
          // } else {

          // }
        });
    }
  }

  processBillingRequest(token: string) {
    if (this.isQuoteRequest) {
      const quote = <QuoteRequest>(this.quote);
      if (quote.quoteRequestType === QuoteRequestType.Firmtime) {
        this.processFirmtimeQuoteRequestBilling(token);
      } else {
        // this.processAnytimeQuoteRequestBilling(token);
      }
    } else {
      this.processQuoteBilling(token);
    }
  }

  quoteIsRequest(quote) {
    return this.quote.constructor === QuoteRequest;
  }

  forcePaymentType(paymentTypeToForce: PaymentType) {
    this.paymentTypes = [PaymentType.Credit];
  }

  // processAnytimeQuoteRequestBilling(token: string) {
  //   this.customStripeService.addOrUpdateAndAcceptAnytimeRequest(this.customer.id, token,
  //        this.stripeBillingComponent.stripeBilling, this.quote.id, true)
  //   .subscribe((stripeCustomerId) => {
  //     this.afterBillingSave();
  //   },(error) => {
  //     this.onServerError(error);
  //   });
  // }

  processFirmtimeQuoteRequestBilling(token: string) {
    const request = new ChargeFirmtimeRequest();
    request.customerId = this.customer.id;
    request.billingToken = token;
    request.stripeBilling = this.stripeBillingComponent.stripeBilling;
    request.quoteId = this.quote.id;
    request.eventStart = (<QuoteRequest>(this.quote)).eventStart;

    this.customStripeService.addOrUpdateAndChargeFirmtimeRequest(request)
    .subscribe((stripeCustomerId) => {
      this.afterBillingSave();
    }, (error) => {
      this.onServerError(error);
    });
  }

  // ,(error) => {
  //   this.onError(error);
  // });

  onError(event) {
    this.saving = false;
    if (event) {
      if (event.response) {
        const eventResponse = (JSON.parse(event.response) as SwaggerExceptionResponse);
        this.error = eventResponse.ExceptionMessage ? eventResponse.ExceptionMessage : eventResponse.Message;
      } else {
        this.error = event.message;
      }
    } else {
      this.error = null;
    }
  }

  onServerError(event) {
    this.saving = false;
    if (event) {
      if (event.response) {
        const eventResponse = (JSON.parse(event.response) as SwaggerExceptionResponse);
        this.serverError = eventResponse.ExceptionMessage ? eventResponse.ExceptionMessage : eventResponse.Message;
      } else {
        this.serverError = event.message;
      }
    } else {
      this.serverError = null;
    }
  }

  afterBillingSave() {
    console.log('billing saved emit 1');

    if (this.quote instanceof QuoteWorkOrder && this.quoteStep === 1) {
      this.quoteStep = 2;
      this.saving = false;
    } else if (this.quote instanceof QuoteWorkOrder && this.quoteStep === 3) {
      this.quoteStep = 4;
      this.billingSaved.emit();
    } else if (this.quote instanceof QuoteRequest) {
      this.billingSaved.emit();
    } else {
      this.closed.emit();
    }
  }

  processQuoteBilling(token: string) {
    if (this.quote instanceof QuoteWorkOrder && this.quoteStep === 1) {
      this.quoteStep = 2;
      this.saving = false;
      this.convertQuote.emit();
    } else {
      this.customStripeService.addOrUpdateAndApproveQuote(this.quote.customerId,
                                                          token,
                                                          this.stripeBillingComponent.stripeBilling,
                                                          this.quote.id,
                                                          this.stripeBillingComponent.customer.manualPayment,
                                                          true)
      .subscribe((stripeCustomerId) => {
        this.afterBillingSave();
      }, (error) => {
        this.onServerError(error);
      });
    }
  }

  toggleManualPayment(isManual: boolean) {
    this.customer.manualPayment = isManual;
    this.hasToggledPayment = true;
    this.quoteStep = 3;
  }

  onSubmit() {
    this.serverError = null;
    if (!this.error) {
      if (!this.stripeBillingComponent.checkFormError()) {
        if (this.stripeBillingComponent.paymentType === PaymentType.Credit) {
          this.submitCard();
        } else if (this.stripeBillingComponent.paymentType === PaymentType.ACH) {
          this.submitACH();
        }
      }
    }
  }

  // Again, I don't really like this work around
  // But Quotes do not have a property that is needed for QuoteRequests
  // So pass the variable back as a QR to get at that property
  asQuoteRequestNotQuote() {
    return this.quote as QuoteRequest;
  }
}
