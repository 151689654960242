<div *ngIf="!confirmPageDisplay" class="p-grid" style="max-width: 900px; margin: auto;">
  <div class="ui-g-12">
    <h4>Schedule Your Appointment</h4>
  </div>
  <div class="ui-g-12">
    This quote option includes a scheduled 45-minute face-to-face meeting with a Russell Tree Experts ISA Certified Arborist® to discuss your landscape.
    You will also receive a detailed quote following our visit. It's priced at $100 and will be credited back to you if you purchase $500+ of resulting work.
  </div>
  <!-- Removed calendar here -->
  <div class="ui-g-12">
    <!-- Loop over a week from the start date -->
    <div class="ui-g " *ngFor="let day of displayAvailable">
      <h6 class="ui-g-12">{{day.day | date:'EEEE, LLLL d'}}</h6>
      <div class="ui-g-12 ui-md-4 ui-lg-3 light-pad" *ngFor="let time of day.times">
        <button pButton class="smaller-button content-to-hide-small" [label]="time | date:'h:mm a'" (click)="eventSelect(time)"></button>
        <button pButton class="buttonStyle content-to-hide-large" [label]="time | date:'h:mm a'" (click)="eventSelect(time)"></button>
      </div>
      <div class="ui-g-12" *ngIf="day.times.length == 0">
        No times available for this day.
      </div>
    </div>
  <h6 class="ui-g-12 blue-link" (click)="clickForMore()">
    &#8595; Load More Options &#8595;
  </h6>
  </div>
</div>

<div *ngIf="confirmPageDisplay" class="p-grid" style="max-width: 900px; margin: auto;">
  <div class="ui-g-12">
    <h4>Confirm & Pay</h4>
    <div>
      <h6>Appointment:</h6>
      <div class="margin-bottom">
        {{selectedTime | date:'EEEE, LLLL d, h:mm a'}}
      </div>
      <h6>Location:</h6>
      <div class="margin-bottom">
        {{quoteRequest.address.street}}, {{quoteRequest.address.city}}, {{quoteRequest.address.state.abbreviation}} {{quoteRequest.address.zip}}
      </div>
      <h6>Your Arborist:</h6>
      <div class="margin-bottom" *ngIf="quoteRequest.representative.employeeSignature" style="white-space: pre-wrap">
        {{quoteRequest.representative.employeeSignature}}
      </div>
      <div class="margin-bottom" *ngIf="!quoteRequest.representative.employeeSignature">
        {{quoteRequest.representative.firstName}} {{quoteRequest.representative.lastName}}
      </div>
      <div class="margin-bottom" *ngIf="!quoteRequest.representative.employeeSignature">
        {{quoteRequest.representative.phoneNumber | phoneNumber}}
      </div>
    </div>
    <div style="padding-top:4px">
      <app-quote-billing [quoteId]="quoteId" (billingSaved)="onPaymentSuccess()" [maxWidth]="'900px'" [isQuoteRequest]="true" [eventStart]="selectedTime"></app-quote-billing>
    </div>
    <div class="text-center">
      <span class="blue button-text" (click)="confirmPageDisplay=false"><< Go Back to Schedule <<</span>
    </div>
  </div>
</div>

<!-- <app-quote-billing-modal *ngIf="quoteBillingModalDisplay" [quoteId]="quoteId" [display]="quoteBillingModalDisplay" (emitClose)="closeQuoteBillingModal()" (emitPaymentSuccess)="onPaymentSuccess()"></app-quote-billing-modal> -->

<p-confirmDialog [key]="1"></p-confirmDialog>
