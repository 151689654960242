import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-swirly',
  templateUrl: './loading-swirly.component.html',
  styleUrls: ['./loading-swirly.component.css']
})
export class LoadingSwirlyComponent implements OnInit {
  @Input() isLoading: boolean;

  constructor() { }

  ngOnInit() {
  }

}
