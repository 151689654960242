<form #PasswordChangeForm="ngForm" autocomplete="off" (ngSubmit)="submit(PasswordChangeForm)">
    <div class="m-auto">
        <div class="p-grid">
            <div class="p-col-12" *ngIf="!wasAnon">
                <h1 class="title">Change Password</h1>
            </div>
            <div class="p-col-12" *ngIf="wasAnon">
                <h1 class="title">Welcome</h1>
                <div>
                    Welcome to the Russell Tree Experts Customer Online Portal! You can log in to review quotes, view schedule dates of upcoming work, view past work, update credit/debit card information, download receipts, and more! First, let’s start by creating a new password below!
                </div>
            </div>
            <!-- <div class="p-col-12">
                <label for="username">Reset Code</label>
                <input class="form-control" name="username" type="text" [(ngModel)]="previousPassword" pInputText
                    required>
            </div> -->
            <div class="p-col-12">
                <label for="password" class="m-top">New Password</label>
                <div class="ui-inputgroup">
                    <input 
                        class="form-control" 
                        name="password" 
                        #password="ngModel"
                        [(ngModel)]="newPassword"
                        pInputText 
                        required
                        [ngClass]="{ 'is-invalid': submitted && password.invalid }"
                        minlength="8"
                        [type]="hidePassword ? 'password' : 'text'"
                        tabindex="1"
                    />
                    <button pButton type="button" [icon]="hidePassword ? 'pi pi-eye-slash' : 'pi pi-eye'" class="ui-button-secondary" (click)="hidePassword = !hidePassword"></button>    
                </div>
                <app-password-strength [passwordToCheck]="newPassword" (passwordStrength)="onPasswordStrengthChanged($event)"></app-password-strength>
            </div>
            <div class="p-col-12">
                <label for="password" class="m-top">New Password Confirmation</label>
                <div class="ui-inputgroup">
                    <input 
                        class="form-control" 
                        name="confirmPassword" 
                        type="password" 
                        #confirmPassword="ngModel"
                        [(ngModel)]="newPasswordConfirmed"
                        pInputText 
                        required
                        [ngClass]="{ 'is-invalid': submitted && confirmPassword.invalid }"
                        minlength="8"
                        [type]="hideConfirmPassword ? 'password' : 'text'"
                        tabindex="2"
                    >
                    <button pButton type="button" [icon]="hideConfirmPassword ? 'pi pi-eye-slash' : 'pi pi-eye'" class="ui-button-secondary" (click)="hideConfirmPassword = !hideConfirmPassword"></button>    
                </div>
                <div>
                    <ul class="no-bullets" *ngIf="newPasswordConfirmed?.length">
                        <li><span  *ngIf="!passwordsMatch()"><i class="pi pi-times"></i></span><span  *ngIf="passwordsMatch()"><i class="pi pi-check"></i></span>passwords must match</li>
                    </ul>
                </div>
            </div>
            <div class="p-col-12">
                <hr style="margin: 0;">
            </div>
            <div class="p-col-12">
                <button type="submit" pButton class="ui-button-success" label="Submit" style="width: 100%;" [disabled]="saving || !strongPassword || !passwordsMatch()" 
                tabindex="3"></button>
            </div>
            <p-message *ngIf="errorMessage" severity="error" [text]="errorMessage"></p-message>
        </div>
    </div>
</form>