<!--If you do not know how WorkTaskTemplate corresponds to WorkTask, then please get clarification
  prior to changing this page. -->

<br>
<div>
  <span class="headerText">Work Tasks</span>
  <div style="float:right">
    <div>
      <div class="headerToggleDiv">
        <span>Show Inactive Work Tasks</span>
        <p-checkbox binary="true" name="showInactive" [(ngModel)]="getInactives" (ngModelChange)="getTasks()">
        </p-checkbox>
      </div>
      <button type="button" class="btn btn-success addNewButton" (click)="addNew();">Add New</button>
    </div>
  </div>
</div>

<p-table [columns]="cols" [value]="tasks" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon *ngIf="col.header != ''" [field]="col.field" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-task let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{task.name}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{task.description}}</td>
      <!--<td><span class="ui-column-title">{{columns[2].header}}</span>{{task.cost | currency: 'USD' : symbol}}</td>-->
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{task.price | currency: 'USD' : 'symbol'}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{task.active == true ? 'Active' : 'Not Active'}}
      </td>
      <td>
        <button pButton type="button" icon="pi pi-pencil" (click)="editTask(task)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button pButton type="button" icon="pi pi-trash" (click)="deleteTask(task)" class="ui-button-danger" *ngIf="showDeleteForTask(task)">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>