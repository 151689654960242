import { Component, OnInit, Inject } from '@angular/core';
import { BaseWellnessViewComponent } from '../base-wellness-view/base-wellness-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-scheduling-multiday',
  templateUrl: './scheduling-multiday.component.html',
  styleUrls: ['./scheduling-multiday.component.css']
})
export class SchedulingMultidayComponent extends BaseWellnessViewComponent implements OnInit {

  type: string;
  header: string;
  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
    private route: ActivatedRoute
  ) {
    super(dragAndDropService);
  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');

    this.route.params.subscribe(val => {
      this.changeType();
    });

    this.header = this.type === 'wellness' ? 'Tree Wellness Multi-day Jobs' : 'Tree Work Multi-day Jobs';
  }

  changeType() {
    this.type = this.route.snapshot.paramMap.get('type');

    this.header = this.type === 'wellness' ? 'Tree Wellness Multi-day Jobs' : 'Tree Work Multi-day Jobs';
  }
}
