import { Injectable, OnInit, Input } from '@angular/core';
import { ScheduledBucketDTO } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

@Injectable()
export abstract class BaseSchedulingViewComponent implements OnInit {

    constructor(
        private dragAndDropService: DragAndDropService
    ) { }

    ngOnInit(): void { }

    // The cards will emit an event that runs this
    generateWorkOrderLists(bucket: ScheduledBucketDTO): void {
        this.dragAndDropService.addBucketToCDKLists(bucket);
    }
}
