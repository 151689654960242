import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WellnessTaskTemplate } from '../../services_autogenerated/generated_services';
import { WellnessTaskTemplateGenSvc } from '../../services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './wellness-task-template-list.component.html',
  styleUrls: ['./wellness-task-template-list.component.css']
})

/**
 * NOTE:
 * If you do not know how WellnessTaskTemplate corresponds to WellnessTask, then please get clarification
 * prior to changing this component.
 */

export class WellnessTaskTemplateListComponent implements OnInit {

  cols: any[];
  tasks: WellnessTaskTemplate[] = [];
  getInactives: boolean;

  constructor(
    private wellnessTaskTemplateService: WellnessTaskTemplateGenSvc,
    private router: Router,
    private authHelper: AuthHelperService
  ) {
    this.cols = [
      { field: 'name', header: 'Task Name', width: '18%'  },
      { field: 'description', header: 'Description', width: '60%'  },
      // { field: 'cost', header: 'Cost', width: '8%'  },
      { field: 'price', header: 'Price', width: '8%'  },
      { field: 'active', header: 'Status', width: '7%' },
      { header: '', width: '7%'  }
    ];
  }

  ngOnInit() {
    this.getTasks();
  }

  getTasks() {
    if (this.getInactives) {
      this.wellnessTaskTemplateService.getAll().subscribe(tasks => {
        this.tasks = tasks;
      });
    } else {
      this.wellnessTaskTemplateService.getAllActive().subscribe(tasks => {
        this.tasks = tasks;
      });
    }
  }

  showDeleteForTask(task): boolean {
    let showDelete = false;
    const token = this.authHelper.getDecodedAccessToken();

    // only show the "delete" button if user has permissions and the task is inactive
    showDelete = token.claimNames.includes('View/Edit Task Template Page');

    return showDelete;
  }

  addNew() {
    this.router.navigateByUrl('/wellnessTaskTemplateMaintenance');
  }

  editTask(task) {
    this.router.navigateByUrl('/wellnessTaskTemplateMaintenance/' + task.id);
  }

  deleteTask(task) {
    if (confirm('Do you want to delete this wellness task template?')) {
      this.wellnessTaskTemplateService.delete(task.id).subscribe(() => {
        this.tasks = this.tasks.filter(obj => obj !== task);
      });
    }
  }
}
