import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkWorkOrder,
  BigDayBucket,
  BigDayBucketGenSvc,
  Employee} from '../../services_autogenerated/generated_services';
import { WorkOrderFilterPipe } from 'src/app/pipes/work-order-filter.pipe';
import { FilteredWorkWorkOrder } from 'src/app/models/filteredWorkWorkOrder';
import { WorkOrderFilterBarComponent } from '../work-order-filter-bar/work-order-filter-bar.component';
import { FilteredWellnessWorkOrder } from 'src/app/models/filteredWellnessWorkOrder';
import { FilteredQuoteWorkOrder } from 'src/app/models/filteredQuoteWorkOrder';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';

@Component({
  selector: 'app-multiday-jobs-work-panel',
  templateUrl: './multiday-jobs-work-panel.component.html',
  styleUrls: ['./multiday-jobs-work-panel.component.css'],
  providers: [WorkOrderFilterPipe]
})
export class MultidayJobsWorkPanelComponent implements OnInit {
  selectedView = 'day';
  allBigDayBuckets: BigDayBucket[] = [];
  filteredBigDayBuckets: BigDayBucket[] = [];
  rms: Employee[];

  allWorkOrders: WorkWorkOrder[];
  filteredWorkOrders: FilteredWorkWorkOrder[];
  @ViewChild(WorkOrderFilterBarComponent) filterBarComponent: WorkOrderFilterBarComponent;

  constructor(
    private bigDayBucketService: BigDayBucketGenSvc,
    private workOrderHelper: WorkOrderHelperService
  ) { }

  ngOnInit() {
    this.bigDayBucketService.getWithWorkWorkOrders().subscribe(bdb => {
      this.allBigDayBuckets = bdb;
      this.filteredBigDayBuckets = bdb;

      this.allWorkOrders = this.allBigDayBuckets.filter(x => x.workWorkOrder).map(x => x.workWorkOrder);

      this.rms = this.workOrderHelper.getUnique(this.allWorkOrders.map(wo => wo.representative));

      this.filteredWorkOrders = this.allWorkOrders;
    });
  }

  updateBigDayBuckets() {
    const workorkOrderIds = this.filteredWorkOrders.map(x => x.id);

    this.filteredBigDayBuckets = this.allBigDayBuckets.filter(bdb => {
      return workorkOrderIds.includes(bdb.workWorkOrderId);
    });
  }

  OnWoFilteringFinished(filteredWorkOrders: (FilteredWellnessWorkOrder | FilteredWorkWorkOrder | FilteredQuoteWorkOrder)[]) {
    this.filteredWorkOrders = filteredWorkOrders as FilteredWorkWorkOrder[];
    this.updateBigDayBuckets();
  }
}
