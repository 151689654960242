/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quote-billing-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i3 from "primeng/components/dialog/dialog";
import * as i4 from "../quote-billing.component.ngfactory";
import * as i5 from "../quote-billing.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../services_autogenerated/generated_services";
import * as i8 from "../../../services/auth-helper.service";
import * as i9 from "./quote-billing-modal.component";
var styles_QuoteBillingModalComponent = [i0.styles];
var RenderType_QuoteBillingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuoteBillingModalComponent, data: {} });
export { RenderType_QuoteBillingModalComponent as RenderType_QuoteBillingModalComponent };
export function View_QuoteBillingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p-dialog", [["class", "billing-dialog"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.display = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Dialog_0, i2.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i3.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], positionTop: [1, "positionTop"], modal: [2, "modal"], closable: [3, "closable"], responsive: [4, "responsive"], style: [5, "style"], blockScroll: [6, "blockScroll"], baseZIndex: [7, "baseZIndex"], minY: [8, "minY"], maximizable: [9, "maximizable"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpod(4, { width: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 1, "app-quote-billing", [], null, [[null, "billingSaved"], [null, "closed"], [null, "convertQuote"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("billingSaved" === en)) {
        var pd_0 = (_co.afterSave() !== false);
        ad = (pd_0 && ad);
    } if (("closed" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } if (("convertQuote" === en)) {
        var pd_2 = (_co.convertQuote() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_QuoteBillingComponent_0, i4.RenderType_QuoteBillingComponent)), i1.ɵdid(6, 114688, null, 0, i5.QuoteBillingComponent, [i6.ActivatedRoute, i7.QuoteRequestGenSvc, i7.StripeGenSvc, i7.CustomerGenSvc, i7.QuoteGenSvc, i8.AuthHelperService], { quoteId: [0, "quoteId"], isQuoteRequest: [1, "isQuoteRequest"], paymentAmount: [2, "paymentAmount"], showManualPaymentChargeMessage: [3, "showManualPaymentChargeMessage"] }, { billingSaved: "billingSaved", convertQuote: "convertQuote", closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display; var currVal_1 = 0; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = _ck(_v, 4, 0, "640px"); var currVal_6 = true; var currVal_7 = 10000; var currVal_8 = 70; var currVal_9 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.quoteId; var currVal_11 = _co.isQuoteRequest; var currVal_12 = _co.paymentAmount; var currVal_13 = _co.showManualPaymentChargeMessage; _ck(_v, 6, 0, currVal_10, currVal_11, currVal_12, currVal_13); }, null); }
export function View_QuoteBillingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quote-billing-modal", [], null, null, null, View_QuoteBillingModalComponent_0, RenderType_QuoteBillingModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.QuoteBillingModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuoteBillingModalComponentNgFactory = i1.ɵccf("app-quote-billing-modal", i9.QuoteBillingModalComponent, View_QuoteBillingModalComponent_Host_0, { quoteId: "quoteId", display: "display", isQuoteRequest: "isQuoteRequest", paymentAmount: "paymentAmount", showManualPaymentChargeMessage: "showManualPaymentChargeMessage" }, { emitClose: "emitClose", emitPaymentSuccess: "emitPaymentSuccess", emitConvertQuote: "emitConvertQuote" }, []);
export { QuoteBillingModalComponentNgFactory as QuoteBillingModalComponentNgFactory };
