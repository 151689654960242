<form id="backlogForm" [formGroup]="backlogForm" (ngSubmit)="save(backlogForm)" *ngIf="!isLoading" class="backlog-container">
  <span style="min-width: 150px; max-width: 150px; margin: auto, 0, auto, 0;">{{backlog.name}}: </span>
  <span>
    <input 
      pInputText 
      name="start"
      class="inputTextBox" 
      type="number"
      min="0" 
      formControlName="start"
      pKeyFilter="pint" 
    /> to 
    <input 
      pInputText 
      name="end"
      #end
      type="number" 
      formControlName="end"
      min="0" 
      pKeyFilter="pint" 
      class="inputTextBox"
    /> Weeks Out
  </span>
  <span>
    <button
      pButton 
      type="submit" 
      class="ui-button-success ui-button-raised ui-button-success buttonStyle" 
      [disabled]="!backlogForm.valid"
      label="Save" >
    </button>    
  </span>
  <br/>
  <span *ngIf="start.invalid && (start.dirty || start.touched)">
    <span *ngIf="start.errors.required">
      <p-message severity="error" [text]="'A starting value is required'" class="backlog-message"></p-message>
    </span>
  </span>
  <span *ngIf="endC.invalid && (endC.dirty || endC.touched)">
    <span *ngIf="endC.errors.required">
      <p-message severity="error" [text]="'An ending value is required'" class="backlog-message"></p-message>
    </span>
    <span *ngIf="endC.errors.range && (endC.dirty || endC.touched)">
      <p-message severity="error" [text]="'The ending value must be equal to or greater than the starting value.'" class="backlog-message"></p-message>
    </span>
  </span>
</form>
<div>
  <span *ngIf="isLoading" style="margin-left:8px">
    <i class="pi pi-spin pi-spinner" style="font-size: 3.5em"></i>
  </span>
</div>
<hr/>