import { Pipe, PipeTransform } from '@angular/core';
import { QuoteRequest, Customer } from '../services_autogenerated/generated_services';
import { CustomerService } from '../services/customer.service';

@Pipe({
  name: 'quoteRequestFilter'
})
export class QuoteRequestFilterPipe implements PipeTransform {
  constructor(private customCustomerService: CustomerService) {}

  transform(quoteRequests: QuoteRequest[], searchTerm?: string): QuoteRequest[] {
    if (!quoteRequests) { return []; }
    if (!searchTerm) { return quoteRequests; }

    searchTerm = searchTerm.toLowerCase();

    return quoteRequests.filter(quoteRequest => {
      const customerMatches = this.checkForCustomerMatch(quoteRequest.customer, searchTerm);

      const statusMatches: boolean = quoteRequest.completionStatus.toString().toLowerCase().includes(searchTerm);

      const broughtByMatches = quoteRequest.broughtBy.name.toLowerCase().includes(searchTerm);

      let broughtByNoteMatches = false;
      if (quoteRequest.broughtByOtherNote) {
        broughtByNoteMatches = quoteRequest.broughtByOtherNote.toLowerCase().includes(searchTerm);
      }

      const descriptionMatches = quoteRequest.description.toLowerCase().includes(searchTerm);

      const serviceLocationMatches = quoteRequest.address.city.toLowerCase().includes(searchTerm) ||
        quoteRequest.address.zip.toLowerCase().includes(searchTerm) ||
        quoteRequest.address.street.toLowerCase().includes(searchTerm) ||
        quoteRequest.address.state.name.toLowerCase().includes(searchTerm);

      return customerMatches || statusMatches || broughtByMatches || broughtByNoteMatches || descriptionMatches || serviceLocationMatches;
    });
  }

  private checkForCustomerMatch(customer: Customer, searchTerm: string): boolean {
    const customerNameMatches: boolean = this.customCustomerService.getCustomerFullName(customer).toLowerCase().includes(searchTerm);
    const addressMatches = customer.address.city.toLowerCase().includes(searchTerm) ||
      customer.address.zip.toLowerCase().includes(searchTerm) ||
      customer.address.street.toLowerCase().includes(searchTerm) || customer.address.state.name.toLowerCase().includes(searchTerm);

    const emailMatches: boolean = this.customCustomerService
      .getPrimaryContact(customer)
      .emailAddress.email
      .toLowerCase().
      includes(searchTerm);

    return customerNameMatches || addressMatches || emailMatches;
  }
}
