<form id="cashCollectedForm" #cashCollectedForm="ngForm">
  <p-dialog header="Collect Cash/Check" [(visible)]="showDialog" [modal]="true" [blockScroll]="true" (onHide)="cancel(false)" [style]="{width: '480px'}">
    <div *ngIf="!isLoading">
      <span *ngIf="cashCollected.length == 0" class="fine-print" style="margin-bottom: 5px;">No Cash or Check Collected</span>
      <div *ngFor="let cash of cashCollected; let i = index" style="margin-bottom: 5px; display: flex; align-items: center;"> 
        <label for="total"><span class="totalLabel">Value of Cash or Check Collected</span>&nbsp;&nbsp;</label>
        <input
          pInputText 
          [name]="'cashCollected' + i" 
          [textMask]="{mask: currencyMask, placeholderChar: '\u2000'}" 
          [ngModel]="cash.amount" 
          [disabled]="!isCashCollectedEditable() || cash.invoiced"
          class="totalBoxes" 
          *ngIf="workOrder"
          (ngModelChange)="cashCollectedChange($event, i)"
          required
        />
        <button pButton
          *ngIf="!cash.id && isCashCollectedEditable()"
          type="button"
          class="ui-button-raised ui-button-danger"
          (click)="removeCashCollectedEntry(i)"
          icon="pi pi-trash"
          style="margin-left:4px">
        </button>
      </div>
      <div>
        <button pButton
            *ngIf="isCashCollectedEditable()"
            type="button"
            class="ui-button-raised ui-button-success smallButton"
            (click)="pushNewCashCollectedEntry()"
            label="Cash or Check Collected">
          </button>
      </div>
    </div>
    <app-loading-swirly [isLoading]="isLoading"></app-loading-swirly>
    <p-footer>
      <app-loading-swirly [isLoading]="saving"></app-loading-swirly>
      <button type="button" pButton (click)="save(cashCollectedForm)" label="Save" [disabled]="saving || disableSave"></button>
      <button type="button" pButton (click)="cancel(false)" label="Cancel" class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
</form>