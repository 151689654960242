import * as i0 from "@angular/core";
var WorkWorkOrderMaintenanceService = /** @class */ (function () {
    function WorkWorkOrderMaintenanceService() {
    }
    WorkWorkOrderMaintenanceService.prototype.removeWorkTaskDependentReferences = function (workOrder, deleted) {
        var _this = this;
        workOrder.workOrderWorkTasks.map(function (task) {
            if (_this.isWorkTaskIsDependent(task.workTask, deleted)) {
                task.workTask.schedulableAfterCompletionOfId = null;
                task.workTask.schedulableAfterCompletionOf = null;
            }
        });
    };
    WorkWorkOrderMaintenanceService.prototype.isWorkTaskIsDependent = function (task, wowt) {
        if (task) {
            var hasMatchingId = wowt.workTaskId && task.schedulableAfterCompletionOfId == wowt.workTaskId;
            var hasMatchingReference = task.schedulableAfterCompletionOf == wowt.workTask;
            if (hasMatchingId || hasMatchingReference) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    WorkWorkOrderMaintenanceService.ngInjectableDef = i0.defineInjectable({ factory: function WorkWorkOrderMaintenanceService_Factory() { return new WorkWorkOrderMaintenanceService(); }, token: WorkWorkOrderMaintenanceService, providedIn: "root" });
    return WorkWorkOrderMaintenanceService;
}());
export { WorkWorkOrderMaintenanceService };
