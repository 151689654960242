<div class="strength">
  <ul class="strengthBar mt-2">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
  </ul>

  <p class="text-center mb-0" [style.color]="messageColor" *ngIf="passwordToCheck?.length">{{ message }}</p>
</div>
<div>
  <ul class="no-bullets" *ngIf="passwordToCheck?.length">
    <li><span *ngIf="!lengthMet"><i class="pi pi-times"></i></span><span *ngIf="lengthMet"><i class="pi pi-check"></i></span>contains at least 8 characters</li>
    <li><span *ngIf="!upperLettersMet"><i class="pi pi-times"></i></span><span *ngIf="upperLettersMet"><i class="pi pi-check"></i></span>contains an upper case letter</li>
    <li><span *ngIf="!lowerLettersMet"><i class="pi pi-times"></i></span><span *ngIf="lowerLettersMet"><i class="pi pi-check"></i></span>contains a lower case letter</li>
    <li><span *ngIf="!numbersMet"><i class="pi pi-times"></i></span><span *ngIf="numbersMet"><i class="pi pi-check"></i></span>contains a number</li>
    <li><span *ngIf="!symbolsMet"><i class="pi pi-times"></i></span><span *ngIf="symbolsMet"><i class="pi pi-check"></i></span>contains a symbol</li>
  </ul>
</div>