<span class="ui-float-label">
    <input id="float-input-crew-search" type="text" class="float-input" pInputText [(ngModel)]="searchTerm" [ngClass]="{'shortInput': selectedView === 'week'}">
    <label for="float-input-crew-search">
        <i class="pi pi-search"></i>
        Search
    </label>
</span>

<p-panel *ngIf="showRecommended" header="Recommended" [toggleable]="true" class="innerHeader">
    <div class="p-grid" id="crewPanelList" cdkDropList [cdkDropListData]="allCrews | crewFilter: searchTerm"
        [cdkDropListConnectedTo]="(selectedView == 'day') ? ['dayOne', 'dayTwo'] : ['dayOne', 'dayTwo', 'dayThree', 'dayFour', 'dayFive', 'daySix', 'daySeven']"
        (cdkDropListDropped)="drop($event)">
        <app-crew-card [ngClass]="{'p-lg-2 p-md-3 p-sm-6': selectedView == 'day', 'p-col-12': selectedView == 'week'}"
            *ngFor="let crew of allCrews | crewFilter: searchTerm" [crew]="crew" cdkDrag></app-crew-card>
    </div>
</p-panel>
<p-panel header="Equipment" [toggleable]="true" class="innerHeader">
    <div class="p-grid" id="equipmentPanelList" cdkDropList
        [cdkDropListData]="allEquipment | equipmentFilter: searchTerm"
        [cdkDropListConnectedTo]="dragAndDropService.allCDKEquipmentListIds" (cdkDropListDropped)="drop($event)">
        <app-equipment-card class="match-height-card"
            [ngClass]="{'p-lg-2 p-md-3 p-sm-6': selectedView == 'day', 'p-col-12': selectedView == 'week', 'p-md-4 p-sm-6 lessPad': selectedView == 'multiDay'}"
            *ngFor="let equip of allEquipment | equipmentFilter: searchTerm" [equipment]="equip" cdkDrag>
        </app-equipment-card>
    </div>
</p-panel>
<p-panel header="Crew" [toggleable]="true" class="innerHeader">
    <div class="p-grid" id="employeePanelList" cdkDropList [cdkDropListData]="allEmployees | peopleFilter: searchTerm"
        [cdkDropListConnectedTo]="dragAndDropService.allCDKEmployeeListIds" (cdkDropListDropped)="drop($event)">
        <app-employee-card class="match-height-card"
            [ngClass]="{'p-lg-2 p-md-3 p-sm-6': selectedView == 'day', 'p-col-12': selectedView == 'week', 'p-md-4 p-sm-6 lessPad': selectedView == 'multiDay'}"
            *ngFor="let employee of allEmployees | peopleFilter: searchTerm" [employee]="employee" cdkDrag>
        </app-employee-card>
    </div>
</p-panel>