<p-card class="wo-card" [ngClass]="{'multiday': workOrder.isMultiday, 'grayOverlay': shouldDisableWODrag()}" (click)="dblclickHandler()">
  <p-header *ngIf="workOrder">
    <div class="wo-header ui-g">
      <div class="zip-code ui-g-6"
        [style.background]="getZipBackgroundColor()"
        [style.color]="getZipColor()">
        {{workOrder.address?.zip}}
      </div>
      <div class="status ui-g-6" (mouseenter)="datePanel.show($event)" (mouseleave)="datePanel.hide($event)"
        [style.background]="getPriorityBackgroundColor()"
        [style.color]="getPriorityColor()"
        style="overflow: hidden;">
        {{workOrder.mostUrgentPriorityLevel?.status}}
        <p-overlayPanel #datePanel [appendTo]="'body'">
          <div>
            {{workOrder.mostUrgentPriorityLevel?.status}}
          </div>
          <div *ngIf="workOrder.completionStatus != 'Completed' && workOrder.completionStatus !='Unable to be Completed'">
            <span *ngFor="let task of unscheduledTasks">
              <div *ngIf="!task.workTask.isGoBack">
                {{getDueDateDisplay(task.workTask)}}
              </div>
              <div *ngIf="task.workTask.isGoBack">
                GO BACK
              </div>
            </span>
          </div>
        </p-overlayPanel>
      </div>
    </div>
  </p-header>

  <div class="customer-name">
    <a [routerLink]="" (click)="navigateToWorkOrder()">{{workOrder.quoteNumber}}</a> <span *ngIf="workOrder.isMultiday">- Multiday</span><br>
    {{customCustomerService.getCustomerFullName(workOrder.customer)}} <i class="fa fa-map-marked-alt" *ngIf="showMap" (click)="openMap()"></i><br />
    <i class="fa fa-sticky-note" (click)="op.toggle($event)" *ngIf="hasCompanyWideNotes"></i>
      <p-overlayPanel #op [appendTo]="'body'">
        <app-customer-comment-view-only [customer]="workOrder.customer"></app-customer-comment-view-only>
      </p-overlayPanel>
  </div>

  <div class="equipment-type">
    {{ getEquipmentTypeDisplay() ? getEquipmentTypeDisplay() : 'No equipment required' }}
  </div>

  <div class="toggle-more-information">
    <div class="left" (click)="taskPanel.toggle($event)">
      {{scheduledTasksTotal}}/{{workOrder.workOrderWorkTasks.length}}
      <p-overlayPanel #taskPanel appendTo="body">
        <div class="center bold">Work Tasks</div>
        <div *ngFor="let woWorkTask of workOrder.workOrderWorkTasks">
          <span class="left">{{woWorkTask.workTask.name}}</span><i class="pi pi-star middle"
            [pTooltip]="woWorkTask.workTask.scheduleDateFrom | date: 'shortDate'"
            *ngIf="woWorkTask.workTask.scheduleDateFrom"></i>
        </div>
      </p-overlayPanel>
    </div>
    <div *ngIf="isCollapsed">
      <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        +
      </span>
    </div>
    <div *ngIf="!isCollapsed">
      <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        -
      </span>
    </div>
    <div [ngbCollapse]="isCollapsed" class="more-information">
      <div [id]="'woTaskList-' + workOrder.id" cdkDropList [cdkDropListData]="unscheduledTasks"
        [cdkDropListConnectedTo]="dragAndDropService.bucketWOListIds" cdkDropListSortingDisabled="true">
        <div *ngFor="let woWorkTask of unscheduledTasks" class="inner-card" cdkDrag
          (cdkDragStarted)="dragStart(woWorkTask, $event)" (cdkDragEnded)="taskDragEnd()"
          [cdkDragDisabled]="shouldDisableTaskDrag(woWorkTask.workTask)">
          <app-task-card [task]="woWorkTask.workTask" [isDisabled]="shouldDisableTaskDrag(woWorkTask.workTask)" [showPrice]="!workOrder.hidePrice || canSeeHiddenPrice"></app-task-card>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer" *ngIf="workOrder">
    <span *ngIf="workOrder.completionStatus">{{workOrder.completionStatus}}</span>
    <hr style="padding: 0px; margin: 0px;"/>
    <span><span *ngIf="!workOrder.hidePrice || canSeeHiddenPrice">{{priceSummary | currency}} <i class="pi pi-circle-on" style="font-size: .5em;"></i></span> {{hoursSummary}} {{unit}}</span>
  </div>
</p-card>
