import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WorkTask,
  WorkTaskTemplate,
  WorkTaskSkill,
  WorkTaskEquipment,
  SkillGenSvc,
  TreeTypeGenSvc,
  WorkTaskTemplateGenSvc,
  PriorityLevelGenSvc,
  WorkTaskGenSvc,
  WorkTaskTreeType,
  QuoteWorkOrder,
  EquipmentTypeGenSvc,
  QuoteWorkOrderCompletionStatus,
  WorkTaskCompletionStatus,
  WorkWorkOrder,
  WorkOrderWorkTask,
  WorkWorkOrderCompletionStatus,
  WorkTaskPaymentStatus,
  WorkTaskGoBackEquipment,
  ScheduledBucketGenSvc,
  WorkTaskUpdateDTO,
  WorkTaskUpdateDTOCompletionStatus,
  WorkTaskUpdateDTOManualPriorityShortcutType} from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { MaskService } from 'src/app/services/mask.service';
import { NgForm } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BaseTaskCardMaintenanceComponent } from '../base-task-card-maintenance/base-task-card-maintenance.component';
import * as moment from 'moment';
import { PriorityAndDateSelectorComponent } from '../priority-and-date-selector/priority-and-date-selector.component';

@Component({
  selector: 'app-work-task-maintenance',
  templateUrl: './work-task-maintenance.component.html',
  styleUrls: ['./work-task-maintenance.component.css']
})
export class WorkTaskMaintenanceComponent extends BaseTaskCardMaintenanceComponent implements OnInit {
  @ViewChild('prioritySelector') prioritySelector: PriorityAndDateSelectorComponent;
  @Input() task: WorkTask;
  @Input() workOrder: WorkWorkOrder | QuoteWorkOrder;

  equipmentOptions: WorkTaskEquipment[];
  goBackEquipmentOptions: WorkTaskEquipment[];
  dependencyOptions: WorkTask[];
  skillOptions: WorkTaskSkill[];
  templateOptions: WorkTaskTemplate[];
  isInvoiced = false;
  showSignError: boolean;

  canChangeStatus: boolean;
  isIncompleteGoBack: boolean;
  statuses: string[] = [WorkTaskCompletionStatus.Completed];

  constructor(
    skillService: SkillGenSvc,
    equipmentTypeService: EquipmentTypeGenSvc,
    treeTypeService: TreeTypeGenSvc,
    priorityLevelService: PriorityLevelGenSvc,
    messageService: MessageService,
    maskService: MaskService,
    authHelper: AuthHelperService,
    private templateService: WorkTaskTemplateGenSvc,
    private workTaskService: WorkTaskGenSvc,
    private scheduledBucketService: ScheduledBucketGenSvc
  ) {
      super(skillService,
        equipmentTypeService,
        treeTypeService,
        priorityLevelService,
        messageService,
        maskService,
        authHelper);
  }

  ngOnInit() {
    this.currencyMask = this.maskService.currencyMaskNoDecimal;
    this.isQuoteTask = this.workOrder instanceof QuoteWorkOrder;

    if (!this.task) {
      const task = new WorkTask();
      task.active = true;
      task.fixedPrice = true;
      task.completionStatus = this.workOrder instanceof WorkWorkOrder ?
      WorkTaskCompletionStatus.Ready_to_Schedule :
      WorkTaskCompletionStatus.New;

      task.taskHourEntries = [];
      task.workTaskEquipment = [];
      task.workTaskSkills = [];
      task.workTaskTreeTypes = [];
      this.task = task;

      if (this.workOrder instanceof QuoteWorkOrder) {
        // defaults to the quote's priority type/dates
        this.task.hasCalculatedPriority = this.workOrder.hasCalculatedPriority;
        this.task.dueDateStart = this.workOrder.dueDateStart;
        this.task.dueDateEnd = this.workOrder.dueDateEnd;
        this.task.hardStartDate = this.workOrder.hardStartDate;
        this.task.hardEndDate = this.workOrder.hardEndDate;

        this.task.manualPriorityShortcutType = this.workOrder.manualPriorityShortcutType as any;
        this.task.manualRangeEnd = this.workOrder.manualRangeEnd;
        this.task.manualRangeStart = this.workOrder.manualRangeStart;

        this.task.manualPriorityLevel = this.workOrder.manualPriorityLevel;
        this.task.priorityLevelId = this.workOrder.priorityLevelId;
        this.task.priorityShortcutId = this.workOrder.priorityShortcutId;
        this.task.priorityTypeShortcut = this.workOrder.priorityTypeShortcut;
      } else if (this.workOrder.workOrderWorkTasks.length > 0) {
        const firstTaskOnWO = this.workOrder.workOrderWorkTasks[0].workTask;
        this.task.hasCalculatedPriority = firstTaskOnWO.hasCalculatedPriority;
        this.task.dueDateStart = firstTaskOnWO.dueDateStart;
        this.task.dueDateEnd = firstTaskOnWO.dueDateEnd;
        this.task.hardStartDate = firstTaskOnWO.hardStartDate;
        this.task.hardEndDate = firstTaskOnWO.hardEndDate;

        this.task.manualPriorityShortcutType = firstTaskOnWO.manualPriorityShortcutType as any;
        this.task.manualRangeEnd = firstTaskOnWO.manualRangeEnd;
        this.task.manualRangeStart = firstTaskOnWO.manualRangeStart;

        this.task.manualPriorityLevel = firstTaskOnWO.manualPriorityLevel;
        this.task.priorityLevelId = firstTaskOnWO.priorityLevelId;
        this.task.priorityShortcutId = firstTaskOnWO.priorityShortcutId;
        this.task.priorityTypeShortcut = firstTaskOnWO.priorityTypeShortcut;

        if (firstTaskOnWO.currentBucketId) {
          this.task.currentBucketId = firstTaskOnWO.currentBucketId;
          this.task.scheduleDateFrom = firstTaskOnWO.scheduleDateFrom;
          this.task.scheduleDateTo = firstTaskOnWO.scheduleDateTo;
        }
      }
    } else {
      this.copyTask();
      this.isInvoiced = this.task.paymentStatus === WorkTaskPaymentStatus.Invoiced || this.task.paymentStatus === WorkTaskPaymentStatus.Paid;
    }

    this.setUploadId();

    this.dependencyOptions = this.workOrder.workOrderWorkTasks
      .map(wowt => wowt.workTask)
      .filter(wt => wt.active && wt !== this.task);

    const claims = this.authHelper.getDecodedAccessToken().claimNames;
    this.isEditable = (
                        this.task.completionStatus !== WorkTaskCompletionStatus.Completed
                        && this.task.completionStatus !== WorkTaskCompletionStatus.Go_Back
                        && this.task.completionStatus !== WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                      )
                      && ((this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
                      && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected)
                      || claims.includes('Full Control'));
    this.canComplete = this.task.id &&
                      this.task.completionStatus !== WorkTaskCompletionStatus.Completed
                      && this.task.scheduleDateFrom !== undefined
                      && this.isEditable
                      && claims.includes('Completing a Task');
    this.canEditUploads = claims.includes('View/Edit Work Order');

    this.isIncompleteGoBack = this.task.completionStatus !== WorkTaskCompletionStatus.Completed && this.task.isGoBack;
    if (claims.includes('Can Initiate Go Back')) {
      this.statuses.push(WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up);
      this.canChangeStatus = this.task.completionStatus === WorkTaskCompletionStatus.Completed
                            || this.task.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                            || this.task.completionStatus === WorkTaskCompletionStatus.Go_Back
                            || this.isIncompleteGoBack;
    }

    if (claims.includes('Can Set Go Back')) {
      this.statuses.push(WorkTaskCompletionStatus.Go_Back);
      this.canChangeStatus = this.task.completionStatus === WorkTaskCompletionStatus.Completed
                            || this.task.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                            || this.task.completionStatus === WorkTaskCompletionStatus.Go_Back
                            || this.isIncompleteGoBack;
    }
    this.templateService.getAllActive().subscribe(templates => {
      this.templateOptions = templates;
    });

    this.skillService.getAll().subscribe(skills => {
      if (this.task.workTaskSkills) {
        this.skillOptions = this.task.workTaskSkills;
      } else {
        this.skillOptions = [];
        this.task.workTaskSkills = [];
      }

      const otherSkills = skills.filter(s => !this.task.workTaskSkills.some(wts => wts.skillId === s.id));

      this.skillOptions = this.skillOptions.concat(otherSkills.map(skill => {
        return new WorkTaskSkill({ skill: skill, skillId: skill.id, workTaskId: this.task.id });
      }));
    });

    this.equipmentTypeService.getActive().subscribe(equipmentTypes => {
      if (this.task.workTaskEquipment) {
        this.equipmentOptions = this.task.workTaskEquipment;
      } else {
        this.equipmentOptions = [];
        this.task.workTaskEquipment = [];
      }

      if (this.task.workTaskGoBackEquipment) {
        this.goBackEquipmentOptions = this.task.workTaskGoBackEquipment;
      } else {
        this.goBackEquipmentOptions = [];
        this.task.workTaskGoBackEquipment = [];
      }

      const otherEquipment = equipmentTypes.filter(e => !this.task.workTaskEquipment.some(wte => wte.equipmentTypeId === e.id));
      const otherGoBackEquipment = equipmentTypes.filter(e => !this.task.workTaskGoBackEquipment.some(wte => wte.goBackEquipmentId === e.id));

      this.equipmentOptions = this.equipmentOptions.concat(otherEquipment.map(equip => {
        return new WorkTaskEquipment({ equipmentType: equip, equipmentTypeId: equip.id, workTaskId: this.task.id
        });
      }));

      this.goBackEquipmentOptions = this.goBackEquipmentOptions.concat(otherGoBackEquipment.map(equip => {
        return new WorkTaskGoBackEquipment({ equipmentType: equip, goBackEquipmentId: equip.id, workTaskId: this.task.id
        });
      }));
    });

    this.treeTypeService.getAll().subscribe(types => {
      if (this.task.workTaskTreeTypes) {
        this.treeTypeOptions = this.task.workTaskTreeTypes; // start with existing types
      } else {
        this.treeTypeOptions = [];
        this.task.workTaskTreeTypes = [];
      }

      // add other options from types they could pick from
      const otherTypes = types.filter(t => !this.task.workTaskTreeTypes.some(wttt => wttt.treeTypeId === t.id));
      this.treeTypeOptions = this.treeTypeOptions.concat(otherTypes.map(tt => {
        const newWTTT = new WorkTaskTreeType();
        newWTTT.treeType = tt;
        newWTTT.treeTypeId = tt.id;
        newWTTT.workTaskId = this.task.id; // may be null if new equipment
        return newWTTT;
      }));
      this.treeTypeOptions = this.treeTypeOptions.map(tto => {
        return {
          label: tto.treeType.genus ?
                `${tto.treeType.commonName} (${tto.treeType.genus} ${tto.treeType.species})` :
                `${tto.treeType.commonName}`,
          value: tto
        };
      });
    });
  }

  completeTask() {
    this.task.completionStatus = WorkTaskCompletionStatus.Completed;
    if (!this.task.completedDate) {
      this.task.completedDate = new Date();
    }
  }

  onSchedulableAfterCompletionOfIdChange() {
    if (!this.task.schedulableAfterCompletionOfId) {
      this.task.schedulableAfterCompletionOf = null;
    }
  }

  saveTask(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (!this.isQuoteTask && !this.task.id && !this.task.customerSignature) {
      this.showSignError = true;
      return;
    } else {
      this.showSignError = false;
    }

    if (!ngForm.invalid) {
      this.isSaving = true;
      if (this.task.id) {
        const dto = new WorkTaskUpdateDTO({
          id: this.task.id,
          name: this.task.name,
          price: this.task.price,
          hours: this.task.hours,
          description: this.task.description,
          treeTypeIds: this.task.workTaskTreeTypes.map(wttt => wttt.treeTypeId),
          location: this.task.location,
          skillIds: this.task.workTaskSkills.map(wts => wts.skillId),
          equipmentTypeIds: this.task.workTaskEquipment.map(wte => wte.equipmentTypeId),
          schedulableAfterCompletionOfId: this.task.schedulableAfterCompletionOfId,
          completionStatus: this.dumbEnumConversion(this.task.completionStatus), // kinda strange that WorkTaskUpdateDTOCompletionStatus[this.task.completionStatus] didn't work
          customerIssue: this.task.customerIssue,
          goBackHoursEstimate: this.task.goBackHoursEstimate,
          goBackEquipmentTypeIds: this.task.workTaskGoBackEquipment.map(wtgbe => wtgbe.goBackEquipmentId),
          // priority stuff
          priorityLevelId: this.task.manualPriorityLevel ? this.task.manualPriorityLevel.id : null,
          hasCalculatedPriority: this.task.hasCalculatedPriority,
          priorityShortcutId: this.task.priorityShortcutId,
          dueDateStart: this.task.dueDateStart,
          dueDateEnd: this.task.dueDateEnd,
          hardStartDate: this.task.hardStartDate,
          hardEndDate: this.task.hardEndDate,
          manualPriorityShortcutType: WorkTaskUpdateDTOManualPriorityShortcutType[this.task.manualPriorityShortcutType],
          manualRangeStart: this.task.manualRangeStart,
          manualRangeEnd: this.task.manualRangeEnd,
          // work task only updates
          fixedPrice: this.task.fixedPrice,
          hourlyRate: this.task.hourlyRate,
          minPrice: this.task.minPrice,
          maxPrice: this.task.maxPrice,
        });
        this.workTaskService.updateWithDTO(dto).subscribe(workTask => {
          if (this.task.completionStatus === WorkTaskCompletionStatus.Go_Back) {
            this.task.hasCalculatedPriority = false;
          }

          this.saveBlobs(workTask);
          this.task = workTask;
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Could not save the task, please ensure all the fields are valid and retry.'
          });
          this.isSaving = false;
        });
      } else {
        // Save the work task
        this.workTaskService.add(this.task).subscribe(workTask => {
          this.task = workTask;
          const woWorkTask = new WorkOrderWorkTask();
          woWorkTask.workTask = workTask;
          woWorkTask.workTaskId = workTask.id;

          if (this.workOrder instanceof WorkWorkOrder) {
            woWorkTask.workWorkOrderId = this.workOrder.id;

            if (this.workOrder.completionStatus === WorkWorkOrderCompletionStatus.Completed) {
              // We are adding a task to completed WO (it's probably a go back) so change the WO's completion status
              this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Ready_to_Schedule;
            }

            if (this.task.priorityShortcutId) {
              const backlog = this.prioritySelector.priorityTypeOptions.find(p => p.id === this.task.priorityShortcutId);

              if (backlog && backlog.weeksOutStart !== undefined) {
                let dueDateStart = new Date();
                let dueDateEnd = new Date();

                if (this.workOrder.workOrderWorkTasks.length > 0 && this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTask.priorityShortcutId === 3)) {
                  dueDateStart = this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTask.priorityShortcutId === 3).workTask.dueDateStart;
                  dueDateEnd = this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTask.priorityShortcutId === 3).workTask.dueDateEnd;
                } else if (backlog) {
                  const daysOutStart = (backlog.weeksOutStart !== undefined ? backlog.weeksOutStart : 4) * 7;
                  const daysOutEnd = (backlog.weeksOutEnd !== undefined ? backlog.weeksOutEnd : 5) * 7;

                  dueDateStart = moment(dueDateStart).add(daysOutStart, 'd').toDate();
                  dueDateEnd = moment(dueDateEnd).add(daysOutEnd, 'd').toDate();
                }

                this.task.dueDateStart = dueDateStart;
                this.task.dueDateEnd = dueDateEnd;
              }
            }
          } else {
            woWorkTask.quoteWorkOrderId = this.workOrder.id;
          }

          this.workOrder.workOrderWorkTasks.push(woWorkTask);
          this.saveBlobs(workTask);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Could not add the task to the work order, please ensure all the fields are valid and retry.'
          });
          this.isSaving = false;
        });
      }
    }
  }

  onUpdatedSignature(signature: string) {
    this.task.signedDate = new Date();
    this.task.customerSignature = signature;
  }


  deleteButtonPressed() {
    // if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
    //   const deleteIndex = this.workOrder.workOrderWorkTasks.findIndex(wt => wt.workTask.id === this.task.id);
    //   const deleted = this.workOrder.workOrderWorkTasks[deleteIndex];
    //   if (deleted.id) {
    //     this.workTaskService.delete(this.task.id).subscribe(() => {
    //       this.workOrder.workOrderWorkTasks.splice(deleteIndex, 1);
    //       this.workWorkOrderMaintenanceService.removeWorkTaskDependentReferences(this.workOrder, deleted);
    //     });
    //   } else {
    //     this.workOrder.workOrderWorkTasks.splice(deleteIndex, 1);
    //     this.workWorkOrderMaintenanceService.removeWorkTaskDependentReferences(this.workOrder, deleted);
    //   }
    // }

    this.delete.emit(this.task);
  }

  dumbEnumConversion(workTaskCompletionStatus: WorkTaskCompletionStatus): WorkTaskUpdateDTOCompletionStatus {
    // most common ones on top
    if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up) {
      return WorkTaskUpdateDTOCompletionStatus.Customer_Unsatisfied__RM_Follow_Up;
    } else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Go_Back) {
      return WorkTaskUpdateDTOCompletionStatus.Go_Back;
    } else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Completed) {
      return WorkTaskUpdateDTOCompletionStatus.Completed;
    } else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Created) {
      return WorkTaskUpdateDTOCompletionStatus.Created;
    } else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Ready_to_Schedule) {
      return WorkTaskUpdateDTOCompletionStatus.Ready_to_Schedule;
    } else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Not_Notified) {
      return WorkTaskUpdateDTOCompletionStatus.Customer_Not_Notified;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Notified) {
      return WorkTaskUpdateDTOCompletionStatus.Customer_Notified;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Partially_Complete) {
      return WorkTaskUpdateDTOCompletionStatus.Partially_Complete;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Credit_Card_Needed) {
      return WorkTaskUpdateDTOCompletionStatus.Credit_Card_Needed;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Work_Orders_Created) {
      return WorkTaskUpdateDTOCompletionStatus.Work_Orders_Created;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed) {
      return WorkTaskUpdateDTOCompletionStatus.Unable_to_be_Completed;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Paid) {
      return WorkTaskUpdateDTOCompletionStatus.Paid;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Satisfied) {
      return WorkTaskUpdateDTOCompletionStatus.Customer_Satisfied;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Quote_Expired) {
      return WorkTaskUpdateDTOCompletionStatus.Quote_Expired;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Quote_Rejected) {
      return WorkTaskUpdateDTOCompletionStatus.Quote_Rejected
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Opened) {
      return WorkTaskUpdateDTOCompletionStatus.Opened;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Sent) {
      return WorkTaskUpdateDTOCompletionStatus.Sent;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.New) {
      return WorkTaskUpdateDTOCompletionStatus.New;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Transformed_to_Quote) {
      return WorkTaskUpdateDTOCompletionStatus.Transformed_to_Quote;
    }else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Pending) {
      return WorkTaskUpdateDTOCompletionStatus.Pending;
    }
  }
}
