import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseWellnessViewComponent } from '../base-wellness-view/base-wellness-view.component';
import { WeekCalendarViewComponent } from '../week-calendar-view/week-calendar-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
var WellnessWeekViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WellnessWeekViewComponent, _super);
    function WellnessWeekViewComponent(dragAndDropService) {
        var _this = _super.call(this, dragAndDropService) || this;
        _this.selectedView = 'week';
        return _this;
    }
    WellnessWeekViewComponent.prototype.ngOnInit = function () {
    };
    return WellnessWeekViewComponent;
}(BaseWellnessViewComponent));
export { WellnessWeekViewComponent };
