<p-card class="wo-card" [ngClass]="{'multiday': workOrder.isMultiday, 'grayOverlay': shouldDisableWODrag()}"  (click)="dblclickHandler()">
  <p-header *ngIf="workOrder">
    <div class="wo-header ui-g">
      <div class="zip-code ui-g-6"
        [style.background]="(workOrder.address && workOrder.address.geoColor) ? workOrder.address.geoColor : ''"
        [style.color]="(workOrder.address && workOrder.address.textColor) ? workOrder.address.textColor : ''">
        {{workOrder.address?.zip}}
      </div>
      <div class="status ui-g-6" (mouseenter)="datePanel.show($event)" (mouseleave)="datePanel.hide($event)"
        [style.background]="(workOrder.mostUrgentPriorityLevel && workOrder.mostUrgentPriorityLevel.backgroundColor) ? workOrder.mostUrgentPriorityLevel.backgroundColor : ''"
        [style.color]="(workOrder.mostUrgentPriorityLevel && workOrder.mostUrgentPriorityLevel.textColor) ? workOrder.mostUrgentPriorityLevel.textColor : ''" style="overflow: hidden;">
        {{workOrder.mostUrgentPriorityLevel?.status}}
        <p-overlayPanel #datePanel [appendTo]="'body'">
          <div>
            {{workOrder.mostUrgentPriorityLevel?.status}}
          </div>
          <div *ngIf="workOrder.completionStatus != 'Completed' && workOrder.completionStatus !='Unable to be Completed'">
            <span *ngFor="let task of scheduledTasks">
              <div *ngIf="!task.workTask.isGoBack">
                {{getDueDateDisplay(task.workTask)}}
              </div>
              <div *ngIf="task.workTask.isGoBack">
                GO BACK
              </div>
            </span>
          </div>
        </p-overlayPanel>
      </div>
    </div>
  </p-header>

  <div class="customer-name">
    <a [routerLink]="" (click)="navigateToWorkOrder()">{{workOrder.quoteNumber}}</a> <span *ngIf="workOrder.isMultiday">- Multiday</span><i class="pi pi-check middle-this" *ngIf="workOrder.completionStatus == 'Completed' || workOrder.completionStatus =='Unable to be Completed'" pTooltip="Work Order is Complete"></i>
    <br>{{customCustomerService.getCustomerFullName(workOrder.customer)}} <i class="fa fa-map-marked-alt" *ngIf="showMap" (click)="openMap()"></i><br />
    <i class="fa fa-sticky-note" (click)="op.toggle($event)" *ngIf="hasCompanyWideNotes"></i>
      <p-overlayPanel #op [appendTo]="'body'">
        <app-customer-comment-view-only [customer]="workOrder.customer"></app-customer-comment-view-only>
      </p-overlayPanel>
  </div>

  <div class="equipment-type">
    {{ getEquipmentTypeDisplay() ? getEquipmentTypeDisplay() : 'No equipment required' }}
  </div>

  <div class="toggle-more-information">
    <div class="left" (click)="taskPanel.toggle($event)">
      {{scheduledTasksTotal}}/{{workOrder.workOrderWorkTasks.length}}
      <p-overlayPanel #taskPanel appendTo="body">
        <div class="center bold">Work Tasks</div>
        <div *ngFor="let woWorkTask of workOrder.workOrderWorkTasks">
          <span class="left">{{woWorkTask.workTask.name}}
            <i class="pi pi-star middle" [pTooltip]="woWorkTask.workTask.scheduleDateFrom | date: 'shortDate'"
              *ngIf="woWorkTask.workTask.scheduleDateFrom"></i>
          </span>
        </div>
      </p-overlayPanel>
    </div>
    <div *ngIf="isCollapsed">
      <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        +
      </span>
    </div>
    <div *ngIf="!isCollapsed">
      <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        -
      </span>
    </div>
    <div [ngbCollapse]="isCollapsed" class="more-information">
      <div [id]="'woTaskList-' + workOrder.id + '-' + bucket.id" cdkDropList [cdkDropListData]="scheduledTasks"
        [cdkDropListConnectedTo]="dragAndDropService.bucketWOListIds" cdkDropListSortingDisabled="true">
        <div *ngFor="let woWorkTask of scheduledTasks" class="inner-card" cdkDrag
          [cdkDragDisabled]="disableDrag || shouldDisableTaskDrag(woWorkTask.workTask)"
          (cdkDragStarted)="dragStart(woWorkTask, $event)" (cdkDragEnded)="taskDragEnd()">
          <app-task-card [task]="woWorkTask.workTask" [isDisabled]="shouldDisableTaskDrag(woWorkTask.workTask)" [showPrice]="!workOrder.hidePrice || canSeeHiddenPrice"></app-task-card>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer" *ngIf="workOrder">
    <span *ngIf="workOrder.completionStatus">{{workOrder.completionStatus}}</span>
    <hr style="padding: 0px; margin: 0px;"/>
    <span><span *ngIf="!workOrder.hidePrice || canSeeHiddenPrice">{{priceSummary | currency}} <i class="pi pi-circle-on" style="font-size: .5em;"></i></span> {{hoursSummary}} {{unit}}</span>
  </div>
</p-card>
