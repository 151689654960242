<div class="checkout" [style.max-width]="maxWidth" [hidden]="quoteStep != 1">
  <!-- <div class="ui-g ui-g-12 remove-bottom-space">
    <div class="ui-g-12 remove-bottom-space">
      <h5 class="center bold">NEXT STEP</h5>
    </div>
  </div> -->
  <app-stripe-billing *ngIf="customer" (errorEmitter)="onError($event)" [customer]="customer" [paymentTypes]="paymentTypes" 
  [isQuote]="true"
  [showManualPaymentChargeMessage]="showManualPaymentChargeMessage" 
  [isPayOnCompletion]="asQuoteRequestNotQuote().quoteRequestType != QuoteRequestType?.Firmtime && amount == 0" 
  [isPayInAdvance]="asQuoteRequestNotQuote().quoteRequestType != QuoteRequestType?.Firmtime && amount > 0"
  [isFirmtime]="asQuoteRequestNotQuote().quoteRequestType == QuoteRequestType?.Firmtime"></app-stripe-billing>
  <div class="ui-g ui-g-12">
    <div class="ui-g-12">
      <button pButton
          type="button"
          class="ui-button-raised full-width content-to-hide-large"
          [ngClass]="asQuoteRequestNotQuote().quoteRequestType == QuoteRequestType?.Firmtime ? 'buttonStyle' : null"
          [label]="asQuoteRequestNotQuote().quoteRequestType != QuoteRequestType?.Firmtime && amount > 0 ? 'Pay $' + amountString : (asQuoteRequestNotQuote().quoteRequestType != QuoteRequestType?.Firmtime && amount == 0 ? 'NEXT' : 'CONFIRM & CHARGE CARD') "
          (click)="onSubmit()"
          [disabled]="saving"
        >
        <p-progressSpinner *ngIf="saving" [style]="{width: '26px', height: '26px', position: 'absolute', right: '4px', top: '0px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"></p-progressSpinner>
      </button>
      <button pButton
          type="button"
          class="ui-button-raised full-width content-to-hide-small"
          [ngClass]="asQuoteRequestNotQuote().quoteRequestType == QuoteRequestType?.Firmtime ? 'smaller-button' : null"
          [label]="asQuoteRequestNotQuote().quoteRequestType != QuoteRequestType?.Firmtime && amount > 0 ? 'Pay $' + amountString : (asQuoteRequestNotQuote().quoteRequestType != QuoteRequestType?.Firmtime && amount == 0 ? 'NEXT' : 'CONFIRM & CHARGE CARD') "
          (click)="onSubmit()"
          [disabled]="saving"
        >
        <p-progressSpinner *ngIf="saving" [style]="{width: '26px', height: '26px', position: 'absolute', right: '4px', top: '0px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"></p-progressSpinner>
      </button>
    </div>
    <div class="ui-g-12" *ngIf="error">
      <div id="errors" role="alert" class="alert alert-danger" style="text-align:left">{{ error }}</div>
    </div>
    <div class="ui-g-12" *ngIf="serverError">
      <div id="errors" role="alert" class="alert alert-danger" style="text-align:left">{{ serverError }}</div>
    </div>
    
    <div class="center" style="margin: 10px; width: 100%;">
      Questions? Give us a call at {{contactPhoneNumber}}
    </div>
  </div>
</div>

<div class="checkout" [style.max-width]="maxWidth" *ngIf="quoteStep == 2 || quoteStep == 3">
  <div class="ui-g ui-g-12 remove-bottom-space">
    <div class="ui-g-12 remove-bottom-space">
      <h5 class="center bold">PAYMENT PREFERENCE</h5>
    </div>
  </div>
  <div class="ui-g ui-g-12" style="padding-top:0px; text-align: center">
    <div class="ui-g-12">
      <div>
        Please select your payment preference below then click next.
      </div>
    </div>
  </div>
  <div class="ui-g ui-g-12">
    <div class="ui-g-12 ui-md-6">
      <button pButton
        type="button"
        class="ui-button-raised full-width regular-boy-button content-to-hide-large"
        [ngClass]="!customer.manualPayment || !hasToggledPayment ? 'ui-button-primary' : 'ui-button-secondary'"
        (click)="toggleManualPayment(false)"
        [disabled]="saving"
      >
        <i class="pi pi-check-circle check-icon" *ngIf="!customer.manualPayment && hasToggledPayment"></i>
        <div style="margin: auto;">
          <div class="bold" style="font-size: 1rem;">CREDIT CARD</div>
          <div>Your credit card on file (provided in previous step) will be charged following the completion of each service.</div> 
        </div>
      </button>
      <button pButton
        type="button"
        class="ui-button-raised full-width big-boy-button content-to-hide-small"
        [ngClass]="!customer.manualPayment || !hasToggledPayment ? 'ui-button-primary' : 'ui-button-secondary'"
        (click)="toggleManualPayment(false)"
        [disabled]="saving"
      >
        <i class="pi pi-check-circle check-icon" *ngIf="!customer.manualPayment && hasToggledPayment"></i>
        <img class="content-to-hide-small" src="../../../../assets/credit-card-icon.png" style="height: 200px" />
        <div class="bold" style="font-size: 1rem;">CREDIT CARD</div>
        <div>Your credit card on file (provided in previous step) will be charged following the completion of each service.</div>      
      </button>
    </div>
    <div class="ui-g-12 ui-md-6">
      <button pButton
        type="button"
        class="ui-button-raised full-width regular-boy-button content-to-hide-large"
        [ngClass]="customer.manualPayment || !hasToggledPayment ? 'ui-button-primary' : 'ui-button-secondary'"
        (click)="toggleManualPayment(true)"
        [disabled]="saving"
        style="margin-bottom: 10px;"
      >
        <i class="pi pi-check-circle check-icon" *ngIf="customer.manualPayment && hasToggledPayment"></i>
        <div style="margin: auto;">
          <div class="bold" style="font-size: 1rem;">CHECK / CASH</div>
          <div>Check/cash due upon completion of each service. Note: Your credit card on file will be charged if cash/check payment is not received within {{numberOfDaysForCash}} days.</div>
        </div>
      </button>
      <button pButton
        type="button"
        class="ui-button-raised full-width big-boy-button content-to-hide-small"
        [ngClass]="customer.manualPayment || !hasToggledPayment ? 'ui-button-primary' : 'ui-button-secondary'"
        (click)="toggleManualPayment(true)"
        [disabled]="saving"
      >
        <i class="pi pi-check-circle check-icon" *ngIf="customer.manualPayment && hasToggledPayment"></i>
        <img src="../../../../assets/check-icon.png" style="width: 230px; margin: 35px 0px;" />
        <div class="bold" style="font-size: 1rem;">CHECK / CASH</div>
        <div>Check/cash due upon completion of each service. Note: Your credit card on file will be charged if cash/check payment is not received within {{numberOfDaysForCash}} days.</div>
      </button>
    </div>
  </div>
  <div class="ui-g ui-g-12">
    <div class="ui-g-12">
      <button pButton
        type="button"
        class="ui-button-raised full-width content-to-hide-large"
        [label]="'NEXT'"
        (click)="onSubmit()"
        [disabled]="saving || quoteStep != 3"
      >
        <p-progressSpinner *ngIf="saving" [style]="{width: '26px', height: '26px', position: 'absolute', right: '4px', top: '0px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"></p-progressSpinner>
      </button>
      <button pButton
        type="button"
        class="ui-button-raised full-width content-to-hide-small"
        [label]="'NEXT'"
        (click)="onSubmit()"
        [disabled]="saving || quoteStep != 3"
      >
        <p-progressSpinner *ngIf="saving" [style]="{width: '26px', height: '26px', position: 'absolute', right: '4px', top: '0px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"></p-progressSpinner>
      </button>
    </div>

    <div class="ui-g-12" *ngIf="error">
      <div id="errors" role="alert" class="alert alert-danger" style="text-align:left">{{ error }}</div>
    </div>
    <div class="ui-g-12" *ngIf="serverError">
      <div id="errors" role="alert" class="alert alert-danger" style="text-align:left">{{ serverError }}</div>
    </div>
    
    <div class="center" style="margin: 10px; width: 100%;">
      Questions? Give us a call at {{contactPhoneNumber}}
    </div>
  </div>
</div>

<div class="checkout" [style.max-width]="maxWidth" *ngIf="quoteStep == 4">
  <div class="ui-g ui-g-12 remove-bottom-space">      
    <span style="float: right; width: 100%">
      <i class="pi pi-times" style="font-size: 1.5rem; cursor: pointer; float: right;" (click)="afterBillingSave()"></i>
    </span>
    <div class="ui-g-12 remove-bottom-space">
      <h5 class="center bold">WE'RE ALL SET!</h5>
    </div>
  </div>
  <div class="ui-g ui-g-12" style="padding-top:0px; text-align: center">
    <div class="ui-g-12">
      <div class="justified">
        Thank you! Your tree work order is in our scheduling queue! We will be in touch via email with a specific day two days prior to the crew's dispatch.
      </div>
      <div class="justified">
        FYI - Tree work, tree wellness, and stump grinding are performed by different crews and each service type will be scheduled and billed separately.
      </div>
      <div class="justified">
        Please allow a two-day window from notification for tree wellness services to account for postponement due to high winds or excessive precipitation.
      </div>
      <div class="center" style="margin: 10px; width: 100%; font-size: 1rem;">
        Questions? Give us a call at {{contactPhoneNumber}}
      </div>
    </div>
  </div>
</div>