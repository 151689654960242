<!-- This crew card is for the scheduling pages, it has indelible employees/equipment and
  can be dragged onto a day for scheduling.
  Basically, this card is used to view crews.
  WARNING: If you make a change to this card, check the other crew card to see if it needs the change as well.
-->
<p-card class="crew-card" [ngClass]="{'crew-invalid': crewErrors.length > 0 }">
  <p-header>
    {{crewTitle}}

    <div *ngIf="crewErrors.length > 0" style="float: right;">
      <i class="pi pi-exclamation-triangle" (click)="errorPanel.toggle($event)"></i>
      <p-overlayPanel #errorPanel>
        <div *ngFor="let error of crewErrors">
          {{error}}
        </div>
      </p-overlayPanel>
    </div>
  </p-header>

  {{employeeList}}

  <div class="toggle-tasks">
    <div *ngIf="isCollapsed">
      <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        +
      </span>
    </div>
    <div *ngIf="!isCollapsed">
      <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        -
      </span>
    </div>
    <div [ngbCollapse]="isCollapsed" class="crew-work-orders">
      <div *ngIf="crew.crewEquipment">
        Equipment
        <div *ngFor="let crewEquip of crew.crewEquipment" class="inner-card">
          <app-equipment-card [equipment]="crewEquip.equipment"></app-equipment-card>
        </div>
      </div>
      <div *ngIf="crew.crewEmployees">
        Employees
        <div *ngFor="let crewEmployee of crew.crewEmployees" class="inner-card">
          <app-employee-card [employee]="crewEmployee.employee"></app-employee-card>
        </div>
      </div>
    </div>
  </div>
</p-card>
