/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unavailable-equipment-and-employees.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/card/card.ngfactory";
import * as i3 from "primeng/components/card/card";
import * as i4 from "@angular/common";
import * as i5 from "./unavailable-equipment-and-employees.component";
import * as i6 from "@angular/router";
var styles_UnavailableEquipmentAndEmployeesComponent = [i0.styles];
var RenderType_UnavailableEquipmentAndEmployeesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnavailableEquipmentAndEmployeesComponent, data: {} });
export { RenderType_UnavailableEquipmentAndEmployeesComponent as RenderType_UnavailableEquipmentAndEmployeesComponent };
function View_UnavailableEquipmentAndEmployeesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.lastName; var currVal_1 = ((_v.context.index === (_co.employees.length - 1)) ? "" : ", "); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_UnavailableEquipmentAndEmployeesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["id", "unavaiable-eqp-and-emps"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dblclickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "p-card", [["class", "bucket-card"]], null, null, null, i2.View_Card_0, i2.RenderType_Card)), i1.ɵdid(2, 49152, null, 2, i3.Card, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { headerFacet: 0 }), i1.ɵqud(335544320, 2, { footerFacet: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "div", [["class", "ui-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 1, 2, "div", [["class", "ui-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnavailableEquipmentAndEmployeesComponent_1)), i1.ɵdid(10, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.employees; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eqpNumbers.join(", "); _ck(_v, 7, 0, currVal_0); }); }
export function View_UnavailableEquipmentAndEmployeesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unavailable-equipment-and-employees", [], null, null, null, View_UnavailableEquipmentAndEmployeesComponent_0, RenderType_UnavailableEquipmentAndEmployeesComponent)), i1.ɵdid(1, 114688, null, 0, i5.UnavailableEquipmentAndEmployeesComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnavailableEquipmentAndEmployeesComponentNgFactory = i1.ɵccf("app-unavailable-equipment-and-employees", i5.UnavailableEquipmentAndEmployeesComponent, View_UnavailableEquipmentAndEmployeesComponent_Host_0, { bigEmployees: "bigEmployees", bigEquipment: "bigEquipment", type: "type" }, {}, []);
export { UnavailableEquipmentAndEmployeesComponentNgFactory as UnavailableEquipmentAndEmployeesComponentNgFactory };
