import { Pipe, PipeTransform } from '@angular/core';
import { Crew } from '../services_autogenerated/generated_services';
import * as moment from 'moment';

@Pipe({
  name: 'crewFilter'
})
export class CrewFilterPipe implements PipeTransform {

  transform(crews: Crew[], searchTerm: string): Crew[] {
    if (!crews) { return []; }
    if (!searchTerm) { return crews; }

    searchTerm = searchTerm.toLowerCase();

    return crews.filter(crew => {
      // Check equipment for matches
      const equipmentMatches = crew.crewEquipment.some(crewEquip => {
        const equipmentNameMatches = crewEquip.equipment.fullTitle.toLowerCase().includes(searchTerm);

        const typeMatches = crewEquip.equipment.equipmentType.type.toLowerCase().includes(searchTerm);

        const feeMatches = crewEquip.equipment.baseFee.toString().includes(searchTerm);

        return equipmentNameMatches || typeMatches || feeMatches;
      });

      // Check employees for match
      const personMatches =  crew.crewEmployees.some(crewEmp => {
        const nameMatches = crewEmp.employee.fullName.toLowerCase().includes(searchTerm);

        const roleMatches = crewEmp.employee.employeeRoles.some(er => er.role.name.toLowerCase().includes(searchTerm));

        const skillMatches = crewEmp.employee.employeeSkills.some(es => es.skill.name.toLowerCase().includes(searchTerm) || es.skill.shortName.toLowerCase().includes(searchTerm));

        return nameMatches || roleMatches || skillMatches;
      });

      // Check if serached term is a date and if it falls within the crew's start/end date range
      let fallsInDateRange = false;

      // Check if search term is a date, using moment's strict date format with isValid allows 01/01/2019, 1/1/2019, 01-01-2019, 1-1-2019
      const searchTermIsDate: boolean = moment(searchTerm, 'MM/DD/YYYY', true).isValid() ||
        moment(searchTerm, 'M/D/YYYY', true).isValid() ||
        moment(searchTerm, 'MM-DD-YYYY', true).isValid() ||
        moment(searchTerm, 'M-D-YYYY', true).isValid();

      if (searchTermIsDate) {
        // Convert to moment, JS dates were being dumb for checking the date range
        const searchDate: moment.Moment = moment(searchTerm, 'MM/DD/YYYY', false);
        const startDate: moment.Moment = moment(crew.startDate);
        const endDate: moment.Moment = moment(crew.endDate);
        // Use inbetween with inclusivity on both sides, basically does this startDate <= searchDate <= endDate
        if (searchDate.isBetween(startDate, endDate, 'days', '[]')) {
          fallsInDateRange = true;
        }
      }

      return equipmentMatches || personMatches || fallsInDateRange;
    });
  }

}
