import { Injectable, Inject, ViewChild } from '@angular/core';
import { BaseSchedulingViewComponent } from '../base-scheduling-view/base-scheduling-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { WorkWorkOrder, EmployeeSkill, WorkTask } from 'src/app/services_autogenerated/generated_services';
import { BaseCalendarComponent } from '../base-calendar/base-calendar.component';

@Injectable()
export abstract class BaseWorkViewComponent extends BaseSchedulingViewComponent {
    @ViewChild(BaseCalendarComponent) calendarComponent: BaseCalendarComponent;

    constructor(
      @Inject(DragAndDropService) dragAndDropService: DragAndDropService
    ) {
      super(dragAndDropService);
    }

    // This captures the event emitter object thing from
    // scheduled-item-bucket-card.component so it can gray out the
    // buckets that don't meet the WO's requirements
    onWoDragged(event: {workOrder: WorkWorkOrder, bucketId: number}) {
      this.woDragStart(event.workOrder);
    }

    // This captures the event emitter object thing from
    // scheduled-item-bucket-card.component so it can ungray out
    // all the buckets
    onWoDraggedEnd() {
      this.clearGrayOverlayOnBuckets();
    }

    // these are on the work base because we are only checking for work task errors
    public woDragStart(workOrder: WorkWorkOrder) {
    // Get all the bucket components and check the bucket to see if the component needs to be grayed out
    const bucketComponets = this.calendarComponent.bucketCardComponents;

    bucketComponets.forEach(bucketComponet => {
      workOrder.workOrderWorkTasks.forEach(wowt => {
        // If the equipment type isn't found in the bucket's equipment, gray it out
        wowt.workTask.workTaskEquipment.forEach(wte => {
          if (!bucketComponet.bucket.scheduledBucketEquipment
              .find(sbe => sbe.equipment.equipmentType.type === wte.equipmentType.type)) {
            bucketComponet.grayOut = true;
          }
        });

        // Skills of all the employees.
        const skillsInBucket: EmployeeSkill[] = bucketComponet.bucket.scheduledBucketEmployees
          .map(sbe => sbe.employee.employeeSkills).reduce((acc, curr) => acc.concat(curr), []);

        // If the skill name isn't found in the bucket's skills, gray it out
        wowt.workTask.workTaskSkills.forEach(wts => {
          if (!skillsInBucket.find(sib => sib.skill.name.toLowerCase().includes(wts.skill.name.toLowerCase()))) {
            bucketComponet.grayOut = true;
          }
        });
      });
    });
  }

  clearGrayOverlayOnBuckets() {
    // Clear the gray overlay when WO is done being dragged
    const bucketComponets = this.calendarComponent.bucketCardComponents;

    bucketComponets.forEach(bucketComponet => {
      bucketComponet.grayOut = false;
    });
  }

  taskDragStart(workTask: WorkTask) {
    // Get all the bucket components and check the bucket to see if the component needs to be grayed out
    const bucketComponets = this.calendarComponent.bucketCardComponents;
    bucketComponets.forEach(bucketComponet => {
        workTask.workTaskEquipment.forEach(wte => {
        if (!bucketComponet.bucket.scheduledBucketEquipment
            .find(sbe => sbe.equipment.equipmentType.type === wte.equipmentType.type)) {
          bucketComponet.grayOut = true;
        }
      });

      // Skills of all the employees.
      const skillsInBucket: EmployeeSkill[] = bucketComponet.bucket.scheduledBucketEmployees
        .map(sbe => sbe.employee.employeeSkills).reduce((acc, curr) => acc.concat(curr), []);

      // If the skill name isn't found in the bucket's skills, gray it out
      workTask.workTaskSkills.forEach(wts => {
        if (!skillsInBucket.find(sib => sib.skill.name.toLowerCase().includes(wts.skill.name.toLowerCase()))) {
          bucketComponet.grayOut = true;
        }
      });
    });
  }

  taskDragEnd() {
    this.clearGrayOverlayOnBuckets();
  }
}
