<div class="ui-g">
    <div class="ui-g-12 ui-lg-12">
      <h4>Chemical Application Method Information</h4>
    </div>
</div>
<form id="methodForm" #methodForm="ngForm">
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-88-percent">
      <h6>Method Name</h6>
      <input pInputText #methodInput required name="name" class="inputTextBox" [(ngModel)]="method.method"/>
    </div>
    <div class="ui-g-12 ui-lg-12-percent pushContentsToBottom">
      <button 
        pButton
        type="submit"
        (click)="saveChemicalApplicationMethod(methodForm)"
        [disabled]="saving"
        class="ui-button-raised ui-button-success buttonStyle"
        label="Save">
      </button>
      <button style="margin-left: 8px;"
        pButton 
        type="button" 
        class="ui-button-raised ui-button-success buttonStyle" 
        (click)="clear(methodForm);" 
        label="Clear">
      </button>
    </div>
  </div>
  <div class="ui-g-12 columnPadding">
    <div *ngIf="methodForm.invalid && methodForm.submitted" class="alert alert-danger" role="alert">
      There are errors on this page. Please correct the errors and resubmit.
    </div>
  </div>
</form>
<br>
<p-table [columns]="cols" [value]="methods" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-method
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{method.method}}</td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="editChemicalApplicationMethod(method)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deleteChemicalApplicationMethod(method)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
<br>
