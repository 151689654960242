import { Component, OnInit, Input } from '@angular/core';
import { QuoteRequest } from '../../../services_autogenerated/generated_services';
import * as moment from 'moment';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-quote-request-item',
  templateUrl: './quote-request-item.component.html',
  styleUrls: ['./quote-request-item.component.css']
})
export class QuoteRequestItemComponent implements OnInit {
  @Input() quoteRequest: QuoteRequest;
  dateDifference: number;
  unit = 'Days';

  constructor(public customCustomerService: CustomerService) { }

  ngOnInit() {
    this.dateDifference = moment().diff(moment(this.quoteRequest.createdDate), 'days');
    if (this.dateDifference === 1) {
      this.unit = 'Day';
    }
  }
}
