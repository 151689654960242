import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TwoDayCalendarViewComponent } from '../two-day-calendar-view/two-day-calendar-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { BaseWorkViewComponent } from '../base-work-view/base-work-view.component';

@Component({
  selector: 'app-work-day-view',
  templateUrl: './work-day-view.component.html',
  styleUrls: ['./work-day-view.component.css']
})
export class WorkDayViewComponent extends BaseWorkViewComponent implements OnInit {
  selectedView = 'day';

  @ViewChild(TwoDayCalendarViewComponent) calendarComponent: TwoDayCalendarViewComponent;

  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService
  ) {
    super(dragAndDropService);
  }

  ngOnInit() {
  }

}
