<div #container class="form-group">
  <div>{{name}} Signature
    <br/>
    If you are using a mobile device, please sign using your finger. If on a computer, please sign using your mouse.
  </div>
  <signature-pad 
      class="sigPadWrapper" 
      [options]="signaturePadOptions" 
      (onBeginEvent)="drawStart()" 
      (onEndEvent)="drawComplete()" style="width:50%;">
  </signature-pad>
  <div>
  <!-- <button type="button" (click)="turnEditOn()" *ngIf="!enabled && !isAlwaysDisabled && isEmpty" class="btn btn-primary visible-xs hidden-print" id="signatureButton">Tap to sign above with your finger</button>
  <button type="button" (click)="turnEditOn()" *ngIf="!enabled && !isAlwaysDisabled && isEmpty" class="btn btn-primary hidden-xs hidden-print" id="signatureButton">Click to sign above with your mouse/finger</button> -->
  <!-- <button type="button" (click)="turnEditOn()" *ngIf="!enabled && !isAlwaysDisabled && !isEmpty" class="btn btn-primary">Edit Signature</button> -->
  <!-- <button type="button" (click)="turnEditOff()" *ngIf="enabled" class="btn btn-primary hidden-print" id="doneButton">Done Editing</button> -->
  <button 
    pButton 
    type="button" 
    icon="pi pi-times"
    class="ui-button-rounded ui-button-secondary hidden-print clear-btn" 
    (click)="clearSignature()" 
    *ngIf="enabled"
  >
  </button>
  <!-- <button class="btn btn-warning hidden-print" (click)="revertSignature()" *ngIf="enabled">Revert</button> -->
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="signatureError">
      Signature is required
  </div>
</div>