import { Component, Inject, ViewChildren, QueryList, ViewChild } from '@angular/core';
import {
  WorkWorkOrder,
  WorkTask,
  Skill,
  WorkWorkOrderGenSvc,
  Customer,
  Address,
  WorkOrderWorkTask,
  WorkOrderWorkTaskGenSvc,
  WorkOrderWellnessTaskGenSvc,
  WorkOrderPriceAdjustmentGenSvc,
  WorkTaskGenSvc,
  EquipmentType,
  EmailAddress,
  CustomerContact,
  WorkTaskHourEntry,
  ScheduledBucketGenSvc,
  EmployeeGenSvc,
  Employee,
  CustomerComment,
  CustomerCommentStageGenSvc,
  CustomerNoteGenSvc,
  WorkTaskHourEntryGenSvc,
  WorkTaskCompletionStatus,
  WorkWorkOrderCompletionStatus,
  NotificationsGenSvc,
  CustomerCommentStage,
  WorkTaskPaymentStatus,
  BigDayBucketSubrangeGenSvc,
  WorkOrderWellnessTask,
  WorkTaskTreeType,
  SaveWorkOrderAfterCompletionRequest,
  WorkWorkOrderPaymentStatus,
  QuoteGenSvc
} from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { BaseWorkOrderMaintenanceComponent } from '../base-work-order-maintenance.ts/base-work-order-maintenance.component';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
// tslint:disable-next-line: max-line-length
import { WorkOrderMaintenanceWorkTaskCardComponent } from '../work-order-maintenance-work-task-card/work-order-maintenance-work-task-card.component';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';
import { WorkTaskMaintenanceComponent } from '../work-task-maintenance/work-task-maintenance.component';
import { MaskService } from 'src/app/services/mask.service';

@Component({
  selector: 'app-work-work-order-maintenance',
  templateUrl: './work-work-order-maintenance.component.html',
  styleUrls: ['./work-work-order-maintenance.component.css']
})
export class WorkWorkOrderMaintenanceComponent extends BaseWorkOrderMaintenanceComponent {
  @ViewChildren(WorkOrderMaintenanceWorkTaskCardComponent) taskCardComponents: QueryList<WorkOrderMaintenanceWorkTaskCardComponent>;
  workOrder: WorkWorkOrder;
  selectedWorkTask: WorkTask;
  canCompleteWO: boolean;
  canUndoWOCompletion = false;
  previousWOStatus: WorkWorkOrderCompletionStatus;

  isCompleting: boolean;

  addingEmployee: boolean;
  canCompleteATask: boolean;
  completeableTaskCount: number;
  employees: Employee[];
  hourEntries: WorkTaskHourEntry[];
  selectedEmployee: Employee;
  stages: CustomerCommentStage[];
  totalHours: number;
  overallFeedback: string;
  amountToInvoice = 0;

  showUndoCompleteDialog = false;
  undoCompleteReason: string;
  commentForm: FormGroup;

  verifyPrice: boolean;
  tasksToVerifyPrice: WorkTask[];

  public WorkWorkOrderCompletionStatus = WorkWorkOrderCompletionStatus;

  @ViewChildren(BlobManagerComponent) blobManagerComponents: QueryList<BlobManagerComponent>;
  @ViewChild(WorkTaskMaintenanceComponent) workTaskMaintenance: WorkTaskMaintenanceComponent;

  currencyMask: any;

  canSaveAfterCompletion: boolean;
  @ViewChild('hoursEntry') hoursEntry: NgForm;

  constructor(
    private workWorkOrderService: WorkWorkOrderGenSvc,
    @Inject(AuthHelperService) authHelper: AuthHelperService,
    private workTaskService: WorkTaskGenSvc,
    @Inject(ActivatedRoute) route: ActivatedRoute,
    @Inject(Router) router: Router,
    @Inject(MessageService) messageService: MessageService,
    @Inject(LocationColorService) locationColorService: LocationColorService,
    @Inject(NotificationsGenSvc) notificationService: NotificationsGenSvc,
    @Inject(WorkOrderHelperService) helper: WorkOrderHelperService,
    @Inject(WorkOrderWorkTaskGenSvc) workOrderWorkTaskGenSvc: WorkOrderWorkTaskGenSvc,
    @Inject(WorkOrderWellnessTaskGenSvc) workOrderWellnessTaskGenSvc: WorkOrderWellnessTaskGenSvc,
    @Inject(WorkOrderPriceAdjustmentGenSvc) wopaService: WorkOrderPriceAdjustmentGenSvc,
    @Inject(WorkWorkOrderMaintenanceService) workWorkOrderMaintenanceService: WorkWorkOrderMaintenanceService,
    @Inject(WellnessWorkOrderMaintenanceService) wellnessWorkOrderMaintenanceService: WellnessWorkOrderMaintenanceService,
    private scheduledBucketService: ScheduledBucketGenSvc,
    private workTaskHourEntryService: WorkTaskHourEntryGenSvc,
    private employeeService: EmployeeGenSvc,
    private authHelperService: AuthHelperService,
    private customerCommentStageService: CustomerCommentStageGenSvc,
    private customerCommentService: CustomerNoteGenSvc,
    private bigDaySubrangeService: BigDayBucketSubrangeGenSvc,
    @Inject(MaskService) maskService: MaskService,
    @Inject(FormBuilder) fb: FormBuilder,
    @Inject(QuoteGenSvc) public quoteService: QuoteGenSvc
  ) {
    super(route, router, messageService, locationColorService, notificationService, helper, workOrderWorkTaskGenSvc,
      workOrderWellnessTaskGenSvc, wopaService, authHelper, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService);

    this.commentForm = fb.group({
      description: ['']
    });

    this.currencyMask = maskService.currencyMaskNoDecimal;
  }

  getWO(id: number) {
    if (id) {
      this.workWorkOrderService.get(id).subscribe(wo => {
        this.workOrder = wo;

        this.onTaskChange();
        this.canSaveAfterCompletion = this.canSave && this.workOrder.completionStatus === WorkWorkOrderCompletionStatus.Completed
                                      && this.token.claimNames.includes('Edit Outside Costs');
        // Admin can always edit and save
        this.isEditable = this.isEditable
                          && this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Completed
                          && this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Unable_to_be_Completed;
        this.canSave = (this.canSave
                        && this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Completed
                        && this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Unable_to_be_Completed)
                      || this.token.claimNames.includes('Add Task to Completed Work Order');
        // canComplete is permissions based, WO's stage cannot already be complete, and ALL tasks must be be scheduled
        this.setCanCompleteWO();
        this.canUndoWOCompletion = this.workOrder.completionStatus === WorkWorkOrderCompletionStatus.Completed
                                   && this.token.claimNames.includes('Undo Work Order Completion')
                                   && this.workOrder.paymentStatus !== WorkWorkOrderPaymentStatus.Paid;

      this.setCanCompleteATaskRegular();
      this.setUpTaskHours();
      this.verifyPrice = this.workOrder.workOrderWorkTasks.some(wowt => !wowt.workTask.fixedPrice);

      this.tasksToVerifyPrice = this.workOrder.workOrderWorkTasks.map(wowt => wowt.workTask).filter(wt => !wt.fixedPrice);
      this.tasksToVerifyPrice.forEach(task => {
        if (task.completionStatus !== WorkTaskCompletionStatus.Completed) {
          task.price = undefined;
        }
      });
      this.isLoading = false;
      }, error => {
        this.errorMessage = 'Could not the load work order. Please refresh and try again.';
        this.isLoading = false;
      });
    } else {
      const wo = new WorkWorkOrder();
      wo.customer = new Customer();
      wo.customer.address = new Address();
      wo.customer.customerContacts = [new CustomerContact({
        receiveInvoicingEmails: true,
        receiveNonInvoicingEmails: true,
      })];
      wo.customer.customerContacts[0].emailAddress = new EmailAddress();
      wo.address = new Address();
      wo.createdDate = new Date();
      wo.completionStatus = WorkWorkOrderCompletionStatus.Ready_to_Schedule;
      wo.salesTaxPercentage = 0;
      wo.workOrderWorkTasks = [];
      wo.workOrderPriceAdjustments = [];
      wo.salesTaxPercentage = 7.5; // default

      this.workOrder = wo;
      this.isEditable = this.isEditable && true;
      this.canCompleteATask = false;
      this.onTaskChange();
      this.isLoading = false;
    }

    this.employeeService.getSchedulable().subscribe(employees => this.employees = employees);
    this.customerCommentStageService.get().subscribe(stages => {
      this.stages = stages;
    });
  }

  completeWO() {
    this.isCompleting = true;
    if (confirm('This will complete the work order. Do you want to continue?')) {
      if (this.invoiceAmtGreaterThanCashCollected()) {
        if (this.workOrder.workOrderWorkTasks.every(wowt => wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed)) {
          this.workOrder.completionStatus = this.WorkWorkOrderCompletionStatus.Unable_to_be_Completed;
        } else {
          this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Completed;
          if (!this.workOrder.completedDate) {
            this.workOrder.completedDate = new Date();
          }
        }

        this.saving = true;
        this.isEditable = false;
        this.saveWO();
      } else {
        this.isCompleting = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Cash Collected Discrepancy',
          detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2)  + ') to complete this work order.',
          sticky: true
        });
      }
    } else {
      this.isCompleting = false;
    }
  }

  undoWOCompletion() {
    this.saving = true;
    const comment: string = this.commentForm.get('description').value;
    this.workWorkOrderService.undoWorkOrderCompletion(this.workOrder.id, comment ? comment : 'Work order adjusted using uncomplete button', this.token.employeeId).subscribe(() => {
      this.closeUndoWOCompletion();
      this.getWO(this.workOrder.id);
      this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Notified;
      this.setPermissions();
      this.isLoading = true;
      this.workOrder = undefined;
      this.saving = false;
    }, error => {
      if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Undoing Completion',
          detail: JSON.parse(error.response).ExceptionMessage,
          sticky: true
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Undoing Completion',
          detail: 'An unexpected error occurred. Contact an admin for support.',
          sticky: true
        });
      }
    });
  }

  openUndoWOCompletion() {
    this.showUndoCompleteDialog = true;
  }

  closeUndoWOCompletion() {
    this.showUndoCompleteDialog = false;
  }

  invoiceAmtGreaterThanCashCollected(): boolean {
    const selectedTasks = this.workOrder.workOrderWorkTasks.filter(x =>
      x.workTask.active && x.workTask.paymentStatus === WorkTaskPaymentStatus.Not_Paid);
    const cashToInvoice = this.workOrder.workOrderCashCollected.filter(x => x.invoiced === false).reduce((a, b) => a + b.amount, 0);
    // console.log(cashToInvoice);
    // console.log(selectedTasks);
    this.amountToInvoice = this.helper.totalAmountByTasks(this.workOrder, selectedTasks);
    if (this.amountToInvoice >= cashToInvoice) {
      return true;
    } else {
      return false;
    }
  }

  protected saveWO() {
    // Updating existing work WO
    this.workWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
      .subscribe(wo => {
        this.workOrder = wo;
        this.saveBlobs(wo);
      }, error => {
        this.saving = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error Saving',
          detail: 'Could not save the work order, please check that all fields are valid and retry.'
        });
      });
  }

  private saveBlobs(wo: WorkWorkOrder) {
    // Currently only 1 blob manager should be not view only. If that changes, you'll need to update this and loop through all the
    // ones that are not view only and save them.
    const blobManagerComponent: BlobManagerComponent = this.blobManagerComponents.find(blobComponent => blobComponent.viewOnly === false);

    if (blobManagerComponent) {
      blobManagerComponent.parentRecordId = wo.id;
      blobManagerComponent.save().subscribe(() => {
        this.saving = false;
        this.isCompleting = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Save Successful',
          detail: 'The WO was saved successfully!'
        });
        this.router.navigateByUrl(`/workWorkOrderMaintenance/${this.workOrder.id}`);
      });
    } else {
      this.saving = false;
      this.isCompleting = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Save Successful',
        detail: 'The WO was saved successfully!'
      });
      this.router.navigateByUrl(`/workWorkOrderMaintenance/${this.workOrder.id}`);
    }
  }

  preSave(workOrderForm: NgForm) {
    this.previousWOStatus = this.workOrder.completionStatus;
    if (this.canCompleteWO) {
      if (this.invoiceAmtGreaterThanCashCollected()) {
        // this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Completed;
        // this.workWorkOrderService.update(this.workOrder, this.workOrder.id.toString())
        //   .subscribe(() => {
            this.isEditable = false;
            this.canCompleteWO = false;
          // }, error => {
          //   this.saving = false;
          //   this.messageService.add({
          //     severity: 'error',
          //     summary: 'Error Saving',
          //     detail: 'Could not save the work order, please check that all fields are valid and retry.'
          //   });
          // });
          this.save(workOrderForm);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Cash Collected Discrepancy',
          detail: 'Work order not completed. The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2)  + ') to complete this work order.',
          sticky: true
        });
      }
    } else {
      this.save(workOrderForm);
    }
  }

  saveAfterCompletion() {
    this.workWorkOrderService.saveAfterCompletion(new SaveWorkOrderAfterCompletionRequest({
        id: this.workOrder.id,
        hasRentalCosts: this.workOrder.hasRentalExpense,
        rentalCosts: this.workOrder.rentalCost,
        rentalDescription: this.workOrder.rentalDescription
    })
    ).subscribe(() => this.doneSaving('work')
    , error =>
      this.messageService.add({
        severity: 'error',
        summary: 'Save Error',
        detail: 'Work order not saved. Please refresh and try again.',
        sticky: true
      }));
  }

  addNewWorkTask() {
    this.displayWorkTaskDialog = true;
  }

  copyWorkTask(wowt: WorkOrderWorkTask) {
    this.selectedWorkTask = new WorkTask({
      active: true,
      location: wowt.workTask.location,
      workTaskTreeTypes: wowt.workTask.workTaskTreeTypes.map(wttt => new WorkTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType}) ),
      primordialTaskId: wowt.workTask.id,
      priorityLevelId: wowt.workTask.priorityLevelId,
      manualPriorityLevel: wowt.workTask.manualPriorityLevel,
      priorityShortcutId: wowt.workTask.priorityShortcutId,
      priorityTypeShortcut: wowt.workTask.priorityTypeShortcut,
      hasCalculatedPriority: wowt.workTask.hasCalculatedPriority,
      dueDateEnd: wowt.workTask.dueDateEnd,
      dueDateStart: wowt.workTask.dueDateStart,
      hardEndDate: wowt.workTask.hardEndDate,
      hardStartDate: wowt.workTask.hardStartDate,
      // schedulableAfterCompletionOf: wowt.workTask.schedulableAfterCompletionOf,
      // schedulableAfterCompletionOfId: wowt.workTask.schedulableAfterCompletionOfId,
      completionStatus: wowt.workTask.completionStatus,
      workTaskSkills: [],
      workTaskEquipment: [],
      fixedPrice: wowt.workTask.fixedPrice,
      taskHourEntries: [],
      currentBucketId: wowt.workTask.currentBucketId,
      scheduleDateFrom: wowt.workTask.scheduleDateFrom,
      scheduleDateTo: wowt.workTask.scheduleDateTo,
      currentBigDaySubrangeId: wowt.workTask.currentBigDaySubrangeId
    });
    this.displayWorkTaskDialog = true;
  }

  copyWellnessTask(wowt: WorkOrderWellnessTask) {
    throw new Error('Cannot add a Wellness Task to a Work Work Order');
  }

  editWorkTask(wowt: WorkOrderWorkTask) {
    this.selectedWorkTask = wowt.workTask;
    this.displayWorkTaskDialog = true;
  }

  completeTask(wowt: WorkOrderWorkTask) {
    wowt.workTask.completionStatus = WorkTaskCompletionStatus.Completed;

    if (wowt.workTask.isGoBack && !wowt.workTask.goBackCompletionDate) {
      wowt.workTask.goBackCompletionDate = new Date();
    } else if (!wowt.workTask.completedDate) {
      wowt.workTask.completedDate = new Date();
    }

    this.workTaskService.updateCompletionStatus(wowt.workTask).subscribe((wt) => {
      const index = this.workOrder.workOrderWorkTasks.findIndex(x => x.workTaskId === wt.id);
      this.workOrder.workOrderWorkTasks[index].workTask = wt;

      this.setUpdatedTaskForDependencies(wt);

      this.setCanCompleteATaskRegular();
      this.setCanCompleteWO();
    });
  }

  unableToCompleteWorkTask(workTask: WorkTask) {
    const index = this.workOrder.workOrderWorkTasks.findIndex(x => x.workTaskId === workTask.id);
    this.workOrder.workOrderWorkTasks[index].workTask = workTask;

    this.setCanCompleteATaskRegular();
    this.setCanCompleteWO();
  }

  undoCompleteTask(workTask: WorkTask) {
    const index = this.workOrder.workOrderWorkTasks.findIndex(x => x.workTaskId === workTask.id);
    this.workOrder.workOrderWorkTasks[index].workTask = workTask;

    this.canCompleteWO = false;
    this.setCanCompleteATaskRegular();
    this.setCanCompleteWO();
  }

  protected onTaskChange() {
    this.requiredSkills = this.getSkillsFromTasks();
    this.requiredEquipment = this.getEquipmentFromTasks();
    this.setCanCompleteATaskRegular();
    this.setCanCompleteWO();
  }

  protected setUpdatedTaskForDependencies(task: WorkTask) {
    var tasksThatAreDependentOnUpdatedTask = this.workOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.schedulableAfterCompletionOfId === task.id);
      tasksThatAreDependentOnUpdatedTask.forEach(woTask => woTask.workTask.schedulableAfterCompletionOf = task);
  }

  protected closedDialog(task: WorkTask) {
    if (task && task.id) {
      this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTask.id === task.id).workTask = task;
      // update copy of task dependency so updating WO doesn't overwrite it
      this.setUpdatedTaskForDependencies(task);
    }

    this.displayWorkTaskDialog = false;
    this.selectedWorkTask = undefined;

    if (this.workOrder.id) {
      this.saveWO();
    }

    this.onTaskChange();
  }
  protected cancelledTask(task: WorkTask) {
    if (task instanceof WorkTask && task.id) {
      this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTaskId === task.id).workTask = task;
    } else if (!task) {
      if (this.selectedWorkTask && this.selectedWorkTask.id) {
        this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTaskId === this.selectedWorkTask.id).workTask = (this.workTaskMaintenance.taskUnmodified as WorkTask);
      }
    }

    this.displayWellnessTaskDialog = false;
    this.displayWorkTaskDialog = false;
    this.selectedWorkTask = undefined;

    this.onTaskChange();
  }
  getEquipmentFromTasks() {
    let workEquipment: EquipmentType[] = [];
    this.workOrder.workOrderWorkTasks.forEach(wowt => {
      workEquipment = workEquipment.concat(wowt.workTask.workTaskEquipment.map(wte => wte.equipmentType));
    });

    return this.helper.getUnique(workEquipment);
  }

  getSkillsFromTasks() {
    let workSkills: Skill[] = [];

    this.workOrder.workOrderWorkTasks.forEach(wowt => {
      workSkills = workSkills.concat(wowt.workTask.workTaskSkills.map(wts => wts.skill));
    });

    return this.helper.getUnique(workSkills);
  }

  setUpTaskHours() {
    const incompleteTasks = this.workOrder.workOrderWorkTasks.filter(wowt => {
      return this.canComplete
              && wowt.workTask.scheduleDateFrom !== undefined
              && wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Completed;
    });

    const entries = [];
    incompleteTasks.forEach(wowt => {
      if (wowt.workTask.currentBucketId) {
        this.scheduledBucketService.get(wowt.workTask.currentBucketId).subscribe(sb => {
          sb.scheduledBucketEmployees.forEach(sbe => {
            if (!entries.find(entry => entry.employee.id === sbe.employee.id)) {
              entries.push(new WorkTaskHourEntry({
                employee: sbe.employee,
                employeeId: sbe.employee.id,
                workTaskId: wowt.workTask.id
              }));
            }
          });

          this.hourEntries = entries;
        });
      } else if (wowt.workTask.currentBigDaySubrangeId) {
        this.bigDaySubrangeService.get(wowt.workTask.currentBigDaySubrangeId).subscribe(subrange => {
          subrange.bigDayEmployees.forEach(emp => {
            if (!entries.find(entry => entry.employee.id === emp.employee.id)) {
              entries.push(new WorkTaskHourEntry({
                employee: emp.employee,
                employeeId: emp.employee.id,
                workTaskId: wowt.workTask.id
              }));
            }
          });

          this.hourEntries = entries;
        });
      }
    });
  }

  addEmployee() {
    this.addingEmployee = true;
  }

  getHourTotal() {
    this.totalHours = 0;
    (this.hourEntries).forEach(entry => {
      this.totalHours += +entry.hours ? +entry.hours : 0;
    });
  }

  addNewTaskHourEntry(employee: Employee) {
    this.selectedEmployee = employee;

    if (!this.hourEntries.find(entry => entry.employee.id === employee.id)) {
      this.hourEntries = [...this.hourEntries, new WorkTaskHourEntry({
        employee: employee,
        employeeId: employee.id,
      })];
    }

    this.addingEmployee = false;
    this.selectedEmployee = null;
  }

  completeAllIncompleteTasks(ngForm: NgForm, overlayPanel) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (this.workOrder.workOrderWorkTasks.some(wowt => !wowt.workTask.fixedPrice && (wowt.workTask.price === undefined || wowt.workTask.price === null))) {
      return;
    }

    this.workOrder.workOrderWorkTasks.forEach(wowt => {
      if (!wowt.workTask.fixedPrice && wowt.workTask.minPrice && wowt.workTask.price < wowt.workTask.minPrice) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to update task price, the price must be equal to or greater than the task\'s minimum price.'
        });
        this.hoursEntry.controls['price' + wowt.workTask.id].setErrors({'belowMin': true});
        return;
      }
    });

    if (ngForm.valid) {
      this.canCompleteATask = false;
      const tasksToComplete = this.workOrder.workOrderWorkTasks.filter(wowt => {
        return this.canComplete &&
          this.helper.isTaskScheduledButNotComplete(wowt.workTask);
      });

      const toDB: any[] = [];

      tasksToComplete.forEach(wowt => {
        if (!wowt.workTask.fixedPrice) {
          this.workTaskService.updatePrice(wowt.workTask.id, wowt.workTask.price).subscribe(() => {
            var tasksThatAreDependentOnUpdatedTask = this.workOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.schedulableAfterCompletionOfId === wowt.workTaskId);
            tasksThatAreDependentOnUpdatedTask.forEach(woTask => woTask.workTask.schedulableAfterCompletionOf.price = wowt.workTask.price);
          },
          error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to update task price, please undo task completion and refresh to try again. Contact support if the error persists.'
            });
            return;
          });
        }

        this.hourEntries.forEach(entry => {
          const hoursForTask = entry.hours / tasksToComplete.length;

          const taskEntry = new WorkTaskHourEntry({
            workTaskId: wowt.workTask.id,
            employeeId: entry.employee.id,
            hours: hoursForTask,
            employee: entry.employee
          });

          toDB.push(taskEntry);
        });
      });

      this.workTaskHourEntryService.updateRange(toDB).subscribe(returned => {
        this.taskCardComponents.forEach(cardComponent => {
          cardComponent.workOrderWorkTask.workTask.jobFeedback = this.overallFeedback;
          if (tasksToComplete.includes(cardComponent.workOrderWorkTask)) {
            cardComponent.workOrderWorkTask.workTask.taskHourEntries =
              [ ...cardComponent.workOrderWorkTask.workTask.taskHourEntries,
                ...returned.filter(entry => entry.workTaskId === cardComponent.workOrderWorkTask.workTask.id)];
            cardComponent.canUndo = true;
            cardComponent.setTaskHours();
            cardComponent.completeTask();

            this.addComment();
            overlayPanel.hide();
            this.setCanCompleteATaskMultiday();
          }
        });
        this.setCanCompleteATaskRegular();
      });
    }
  }

  addComment() {
    this.customerCommentService.add(new CustomerComment({
      comment: this.overallFeedback,
      employeeId: this.authHelperService.getDecodedAccessToken().employeeId,
      customerId: this.workOrder.customerId,
      quoteNumber: this.workOrder.quoteNumber,
      companyWide: false,
      active: true,
      customerCommentStageId: this.stages.find(stage => stage.commentStage === 'Work Order').id,
      customerCommentStage: this.stages.find(stage => stage.commentStage === 'Work Order'),
    }));
    }

    setCanCompleteATaskRegular() {
      const relevantTasks = this.workOrder.workOrderWorkTasks.filter(wowt => this.helper.isTaskScheduledButNotComplete(wowt.workTask));
      this.completeableTaskCount = relevantTasks.length;
      this.canCompleteATask = this.completeableTaskCount > 0 && this.canComplete;
    }

    setCanCompleteATaskMultiday() {
      const relevantTasks = this.workOrder.workOrderWorkTasks.filter(wowt => this.helper.isTaskScheduledButNotComplete(wowt.workTask));
      this.completeableTaskCount = relevantTasks.length;
      this.canCompleteATask = this.completeableTaskCount > 0 && this.canComplete;
    }

    onMyWay() {
        if (confirm('This will send the customer an email or SMS' +
            ' message letting them know you are on your way to do this job. Click “OK” to proceed with this notification to the customer.')) {
            this.notificationService.sendWorkWorkOrderOnWayNotification(this.workOrder.id).subscribe(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Message Sent',
                    detail: 'Customer was notified Russell Tree is en route.'
                });
            }, (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error Sending Message',
                    detail: 'Could not enqueue message to be sent. Please try again.'
                });
            });
        }
    }

    setCanCompleteWO() {
      this.canCompleteWO = this.canComplete && this.workOrder.completionStatus !== WorkWorkOrderCompletionStatus.Completed
      && this.workOrder.workOrderWorkTasks.every(woTask => !this.helper.isTaskCapableOfProgress(woTask.workTask));
    }

    
  duplicateToQuote() {
    if (confirm('This will create a new Quote based on this WO. Please select "Ok" to continue.')) {
      // this.saving = true;
      this.quoteService.duplicateWorkToQuote(this.workOrder.id).subscribe(id => this.router.navigateByUrl('quoteWorkOrderMaintenance/' + id));
    }
  }
}
