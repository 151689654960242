/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-quote-row.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/button/button";
import * as i4 from "./customer-quote-row.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/work-order-helper.service";
import * as i7 from "../../../services/auth-helper.service";
var styles_CustomerQuoteRowComponent = [i0.styles];
var RenderType_CustomerQuoteRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerQuoteRowComponent, data: {} });
export { RenderType_CustomerQuoteRowComponent as RenderType_CustomerQuoteRowComponent };
function View_CustomerQuoteRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Quote Expires: ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.getExpireDate(), "shortDate")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "color: green"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Quote Signed: ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.quote.signedDate, "shortDate")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Quote Rejected: ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.quote.rejectedDate, "shortDate")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Quote Expired: ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.getExpireDate(), "shortDate")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.price)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " per hour"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.parent.parent.context.$implicit.hourlyRate)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please note there will be a "]))], null, null); }
function View_CustomerQuoteRowComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["minimum charge of ", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.parent.parent.context.$implicit.minPrice)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" and a "]))], null, null); }
function View_CustomerQuoteRowComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["maximum charge of ", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.parent.parent.context.$implicit.maxPrice)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerQuoteRowComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "fine-print"], ["style", "text-align: right; width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_9)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_10)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_11)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_12)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.hourlyRate; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.minPrice || _v.parent.context.$implicit.maxPrice); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.minPrice; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.parent.context.$implicit.minPrice && _v.parent.context.$implicit.maxPrice); _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.maxPrice; _ck(_v, 11, 0, currVal_4); }, null); }
function View_CustomerQuoteRowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "p-grid hide-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "p-col-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": ", ", ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "p-col-3"], ["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "p-col-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.canSeeHiddenPrice || !_co.quote.hidePrice) && _v.context.$implicit.fixedPrice); _ck(_v, 5, 0, currVal_3); var currVal_4 = ((_co.canSeeHiddenPrice || !_co.quote.hidePrice) && !_v.context.$implicit.fixedPrice); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; var currVal_1 = _co.getTreeList(_v.context.$implicit); var currVal_2 = _v.context.$implicit.location; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.getTaskStatusDisplay(_v.context.$implicit); _ck(_v, 9, 0, currVal_5); }); }
export function View_CustomerQuoteRowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 23, "div", [["class", "p-grid workStyle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "p-col-12 p-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "p-col-12 p-md-8 header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Quote ["])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["] "])), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "p-col-12 p-md-4"], ["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" Quote Created: ", " "])), i1.ɵppd(12, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_3)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_4)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerQuoteRowComponent_5)), i1.ɵdid(22, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "p-col-12 p-md-4"], ["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "buttonStyle"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.current; _ck(_v, 14, 0, currVal_2); var currVal_3 = (!_co.current && _co.quote.signedDate); _ck(_v, 16, 0, currVal_3); var currVal_4 = (!_co.current && _co.quote.rejectedDate); _ck(_v, 18, 0, currVal_4); var currVal_5 = (!_co.current && (_co.quote.completionStatus === _co.completionStatus.Quote_Expired)); _ck(_v, 20, 0, currVal_5); var currVal_6 = _co.tasks; _ck(_v, 22, 0, currVal_6); var currVal_7 = _co.buttonText; _ck(_v, 25, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quote.quoteNumber; _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.quote.createdDate, "shortDate")); _ck(_v, 11, 0, currVal_1); }); }
export function View_CustomerQuoteRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-quote-row", [], null, null, null, View_CustomerQuoteRowComponent_0, RenderType_CustomerQuoteRowComponent)), i1.ɵdid(1, 114688, null, 0, i4.CustomerQuoteRowComponent, [i5.Router, i6.WorkOrderHelperService, i7.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerQuoteRowComponentNgFactory = i1.ɵccf("app-customer-quote-row", i4.CustomerQuoteRowComponent, View_CustomerQuoteRowComponent_Host_0, { quote: "quote", current: "current" }, {}, []);
export { CustomerQuoteRowComponentNgFactory as CustomerQuoteRowComponentNgFactory };
