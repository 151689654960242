import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Customer, StripeGenSvc } from 'src/app/services_autogenerated/generated_services';
import { StripeBilling } from '../../services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-customer-billing-profile',
  templateUrl: './customer-billing-profile.component.html',
  styleUrls: ['./customer-billing-profile.component.css']
})
export class CustomerBillingProfileComponent implements OnInit {

  @Input() customer: Customer;
  @Output() billingProfileEntryModalClosed = new EventEmitter();
  stripeBilling: StripeBilling = null;
  displayBillingProfileEntryModal = false;
  loaded = false;

  constructor(
    private customStripeService: StripeGenSvc,
    private authHelperService: AuthHelperService
  ) { }

  ngOnInit() {
    this.loaded = true;
    this.getStripeBilling().subscribe(stripeBilling => this.afterGetStripeBilling(stripeBilling));
  }

  getLast4Display(num: number) {
    return String(num).padStart(4, '0');
  }

  getStripeBilling() {
    if (this.customer.id) {
      return this.customStripeService.getStripeBillingByCustomerId(this.customer.id);
    }
  }

  openBillingProfileEntryModal() {
    if (this.hasBillingAddEditPermission()) {
      this.displayBillingProfileEntryModal = true;
    }
  }

  closeBillingProfileEntryModal() {
    console.log('closed');
    this.displayBillingProfileEntryModal = false;
    this.billingProfileEntryModalClosed.emit(this.stripeBilling);
  }

  updatedStripeCustomer(stripeCustomerId: string) {
    console.log('updated');
    this.customer.stripeCustomerId = stripeCustomerId;
    this.getStripeBilling().subscribe(stripeBilling => {
      this.afterGetStripeBilling(stripeBilling);
    });
  }

  afterGetStripeBilling(stripeBilling) {
    this.stripeBilling = stripeBilling;
    // console.log('updated billing: ');
    // console.log(this.stripeBilling);
  }

  hasBillingAddEditPermission() {
    const token = this.authHelperService.getDecodedAccessToken();
    const claimNames = token.claimNames;
    const hasCustomerMaintenancePermission = claimNames.includes('View/Edit Customer Maint.');
    const isCustomer = token.userType === 'customer';
    return hasCustomerMaintenancePermission || isCustomer;
  }
}
