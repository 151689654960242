import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthHelperService } from './services/auth-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorGuard implements CanActivate {
  constructor(
    private authService: AuthHelperService,
    private router: Router
  ) { }
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // If there is a token and that token is marked as needing to setup mfa, force them to go to the setup mfa screen
    // If there isn't a token then or there's a token and it doesn't need to setup mfa, then allow them (this lets non auth-guarded pages load appropriately)
    if (this.authService.getDecodedAccessToken() && this.authService.requiresMultiFactorAuthSetup(this.authService.getDecodedAccessToken())) {
      this.router.navigate(['multiFactor', 'setup']);
      return false;
    }

    // If there is a token and that token is marked as needing to do mfa, force them to go to the mfa screen
    // If there isn't a token then or there's a token and it doesn't need to do mfa, then allow them (this lets non auth-guarded pages load appropriately)
    if (this.authService.getDecodedAccessToken() && this.authService.requiresMultiFactorAuth(this.authService.getDecodedAccessToken())) {
      this.router.navigate(['multiFactor', 'process']);
      return false;
    }

    return true;
  }
}
