import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import * as moment from 'moment';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import {
  ScheduledBucketGenSvc,
  BigDayBucketSubrangeGenSvc, BigDayBucketSubrange, ScheduledBucketDTO
} from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-my-schedule',
  templateUrl: './my-schedule.component.html',
  styleUrls: ['./my-schedule.component.css']
})
export class MyScheduleComponent implements OnInit {
  startDate: Date;
  week: Date[];
  colSize: number;
  currentBreakpoint: BreakpointState;
  userId: number;
  scheduledBucketWeek: ScheduledBucketDTO[][];
  bigDayBucketSubrangeWeek: BigDayBucketSubrange[][];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authHelperService: AuthHelperService,
    private scheduledBucketService: ScheduledBucketGenSvc,
    private bigDayBucketSubrangeService: BigDayBucketSubrangeGenSvc
  ) { }

  ngOnInit() {
    this.startDate = new Date();
    this.week = [];

    this.userId = this.authHelperService.getDecodedAccessToken().employeeId;

    this.breakpointObserver.observe([
      '(max-width: 599.99px)',
      '(min-width: 600px) and (max-width: 899.99px)',
      '(min-width: 900px) and (max-width: 1559.99px)'
    ]).subscribe(result => {
      this.currentBreakpoint = result;
      this.onDateChange();
    });
  }

  onDateChange() {
    this.scheduledBucketWeek = undefined;
    this.bigDayBucketSubrangeWeek = undefined
    if (this.currentBreakpoint.breakpoints['(max-width: 599.99px)']) {
      // 1 per row
      this.initializeDates(4);
      this.colSize = 12;
    } else if (this.currentBreakpoint.breakpoints['(min-width: 600px) and (max-width: 899.99px)']) {
      // 1 per row
      this.initializeDates(5);
      this.colSize = 12;
    } else if (this.currentBreakpoint.breakpoints['(min-width: 900px) and (max-width: 1559.99px)']) {
      // 1 per row
      this.initializeDates(6);
      this.colSize = 12;
    } else {
      // 7 per row
      this.initializeDates(7);
      this.colSize = 0;
    }

    this.getNewBuckets();
  }

  // Set the dates, week begins on Sunday
  initializeDates(numberOfDays: number) {
    this.week = [];
    for (let index = 0; index < numberOfDays; index++) {
      const start = numberOfDays === 7 ? moment(this.startDate).startOf('week') : moment(this.startDate).startOf('d');
      this.week.push(start.add(index, 'days').toDate());
    }
  }

  getNewBuckets() {
    const firstDay = moment(this.week[0]);
    const lastDay = moment(this.week[this.week.length - 1]);

    this.scheduledBucketService.getAllScheduledBucketsInDateRangeForAUser(this.userId,
                                                                        firstDay.toDate(),
                                                                        lastDay.toDate()).subscribe((buckets) => {
      this.processScheduledBuckets(buckets);
    });

    this.bigDayBucketSubrangeService.getAllBigDayBucketSubrangeInDateRangeForAUser(this.userId,
                                                                                  firstDay.toDate(),
                                                                                  lastDay.toDate()).subscribe(subranges => {
      this.processBigDayBucketSubranges(subranges);
    });
  }

  processScheduledBuckets(buckets: ScheduledBucketDTO[]) {
    this.scheduledBucketWeek = [];
    this.scheduledBucketWeek = new Array(this.week.length).fill(undefined).map(() => []);

    buckets.forEach(bucket => {
      const bucketMoment = moment(bucket.date).startOf('day');
      const firstDayMoment = moment(this.week[0]).startOf('day');
      const dayDiff = bucketMoment.diff(firstDayMoment.startOf('day'), 'days');

      this.scheduledBucketWeek[dayDiff].push(bucket);
    });
  }

  processBigDayBucketSubranges(subranges: BigDayBucketSubrange[]) {
    this.bigDayBucketSubrangeWeek = new Array(this.week.length).fill(undefined).map(() => []);

    this.week.forEach((day, i) => {
      this.bigDayBucketSubrangeWeek[i] = subranges.filter(x => moment(day).isBetween(x.fromDate, x.toDate, 'days', '[]'));
    });
  }

}
