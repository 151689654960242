/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/tooltip/tooltip";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/primeng/components/card/card.ngfactory";
import * as i5 from "primeng/components/card/card";
import * as i6 from "./task-card.component";
import * as i7 from "../../services/drag-and-drop.service";
var styles_TaskCardComponent = [i0.styles];
var RenderType_TaskCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskCardComponent, data: {} });
export { RenderType_TaskCardComponent as RenderType_TaskCardComponent };
function View_TaskCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" - GB"]))], null, null); }
function View_TaskCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵppd(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "pi pi-circle-on"], ["style", "font-size: .5em;"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.task.price)); _ck(_v, 1, 0, currVal_0); }); }
function View_TaskCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "pi pi-check middle-this"], ["pTooltip", "Task is Complete"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null)], function (_ck, _v) { var currVal_0 = "Task is Complete"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TaskCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "pi pi-info-circle middle-this"], ["pTooltip", "Task will not be completed"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null)], function (_ck, _v) { var currVal_0 = "Task will not be completed"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TaskCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "task-info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, ["", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_5)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPrice; _ck(_v, 2, 0, currVal_0); var currVal_3 = (_co.task.completionStatus == "Completed"); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.task.completionStatus == "Unable to be Completed"); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.task.hours; var currVal_2 = _co.unit; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_TaskCardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "pi pi-check middle-this"], ["pTooltip", "Task is Complete"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null)], function (_ck, _v) { var currVal_0 = "Task is Complete"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TaskCardComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "pi pi-info-circle middle-this"], ["pTooltip", "Task will not be completed"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null)], function (_ck, _v) { var currVal_0 = "Task will not be completed"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TaskCardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "task-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["$0.00 "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "pi pi-circle-on"], ["style", "font-size: .5em;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_7)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_8)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.task.completionStatus == "Completed"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.task.completionStatus == "Unable to be Completed"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task.goBackHoursEstimate; var currVal_1 = _co.goBackUnit; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_TaskCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "p-card", [], null, null, null, i4.View_Card_0, i4.RenderType_Card)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "taskGrayOverlay": 0 }), i1.ɵdid(4, 49152, null, 2, i5.Card, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { headerFacet: 0 }), i1.ɵqud(335544320, 2, { footerFacet: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 8, "div", [["style", "padding: .3em"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "task-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ": ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskCardComponent_6)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isDisabled); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.task.isGoBack; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.task.isGoBack; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.task.isGoBack; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.task.name; var currVal_2 = _co.treeTypes; _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_TaskCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-card", [], null, null, null, View_TaskCardComponent_0, RenderType_TaskCardComponent)), i1.ɵdid(1, 114688, null, 0, i6.TaskCardComponent, [i7.DragAndDropService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskCardComponentNgFactory = i1.ɵccf("app-task-card", i6.TaskCardComponent, View_TaskCardComponent_Host_0, { task: "task", isDisabled: "isDisabled", showPrice: "showPrice" }, {}, []);
export { TaskCardComponentNgFactory as TaskCardComponentNgFactory };
