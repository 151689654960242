import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { MessageToCustomerTemplate, MessageToCustomerTemplateGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-message-to-customer-template',
  templateUrl: './message-to-customer-template.component.html',
  styleUrls: ['./message-to-customer-template.component.css']
})
export class MessageToCustomerTemplateComponent implements OnInit {

  cols: any[];
  templates: MessageToCustomerTemplate[] = [];
  template: MessageToCustomerTemplate = new MessageToCustomerTemplate();
  saving: boolean;

  constructor(
    private messageTemplateService: MessageToCustomerTemplateGenSvc,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'name', header: 'Name', width: '41%' },
      { field: 'message', header: 'Message', width: '41%' },
      { field: 'active', header: 'Active', width: '31%' },
      { header: '', width: '7%' }
    ];
  }

  ngOnInit() {
    this.messageTemplateService.get().subscribe(res => this.templates = res);
  }

  saveTemplate(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      this.saving = true;
      if (this.template.id) {
        // update
        this.messageTemplateService.update(this.template, this.template.id.toString()).subscribe(res => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not add the message template, please check that all fields are valid and retry.'
          });
        })
      } else {
        // add
        this.messageTemplateService.add(this.template).subscribe(res => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the message template, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      }
    }
  }

  editTemplate(template) {
    this.template.message = template.message;
    this.template.id = template.id;
    this.template.active = template.active;
    this.template.name = template.name;
  }

  clear(ngForm: NgForm) {
    ngForm.resetForm();
    this.template = new MessageToCustomerTemplate();
    this.emptyInputs();
  }

  doneSaving(ngForm: NgForm) {
    this.saving = false;
    ngForm.resetForm();
    this.emptyInputs();
    this.messageTemplateService.get().subscribe(res => this.templates = res);
  }
  
  emptyInputs() {
    this.template = new MessageToCustomerTemplate();
    this.template.message = null;
    this.template.active = null;
  }
}
