import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Skill } from '../../services_autogenerated/generated_services';
import { SkillGenSvc } from '../../services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-skill-maintenance',
  templateUrl: './skill-maintenance.component.html',
  styleUrls: ['./skill-maintenance.component.css']
})
export class SkillMaintenanceComponent implements OnInit {

  constructor(
    private skillService: SkillGenSvc,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  skill: Skill;
  disableDelete: boolean;
  saving: boolean;

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');

    if (id) {
      this.skillService.get(id).subscribe(skill => {
        this.skill = skill;
      });
    } else {
      this.skill = new Skill();
      this.skill.active = true;
      this.disableDelete = true;
    }
  }

  save(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (ngForm.valid) {
      this.saving = true;
      if (this.skill.id) {
        this.skillService.update(this.skill, this.skill.id.toString()).subscribe(() => {
          this.doneSaving();
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Please check fields to ensure they are valid.'
          });
          this.doneSaving();
        });
      } else {
        this.skillService.add(this.skill).subscribe(() => {
          this.doneSaving();
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Please check fields to ensure they are valid.'
          });
          this.doneSaving();
        });
      }
    }
  }

  private doneSaving() {
    this.saving = false;
    this.router.navigateByUrl('/skillList');
  }

  cancel() {
    this.router.navigateByUrl('/skillList');
  }

  delete() {
    if (confirm('Delete this skill?')) {
      this.skillService.delete(this.skill.id).subscribe(() => {
        this.router.navigateByUrl('/skillList');
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the skill, please check that the skill exists and retry.'
        });
      });
    }
  }
}
