<div>
  <h4>System Settings Maintenance</h4>
  <br/>
  <hr/>
  <span *ngIf="isLoading" style="margin-left:8px">
    <i class="pi pi-spin pi-spinner" style="font-size: 3.5em"></i>
  </span>
  <div *ngFor="let setting of settings" style="margin-bottom: 10px;">
    <app-setting-row [setting]="setting" *ngIf="!setting.claimName || claims.includes(setting.claimName)"></app-setting-row>
  </div>
</div>