import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Customer } from 'src/app/services_autogenerated/generated_services';
import { PaymentType } from 'src/app/models/paymentType';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { StripeGenSvc } from 'src/app/services_autogenerated/generated_services';
import { StripeBillingComponent } from 'src/app/components/stripe-billing/stripe-billing.component';
import { StripeBilling } from '../../../services_autogenerated/generated_services';
import { BasePaymentProcessingComponent } from '../../base-payment-processing/base-payment-processing.component';
var BillingProfileEntryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BillingProfileEntryComponent, _super);
    function BillingProfileEntryComponent(behaviorSubjectService, scriptLoaderService, customStripeService) {
        var _this = _super.call(this, behaviorSubjectService, scriptLoaderService, customStripeService) || this;
        _this.stripeBilling = null;
        _this.emitClose = new EventEmitter();
        _this.emitUpdatedStripeCustomerId = new EventEmitter();
        _this.saving = false;
        return _this;
    }
    BillingProfileEntryComponent.prototype.ngOnInit = function () {
        this.setupPlaid();
    };
    BillingProfileEntryComponent.prototype.save = function () {
        if (!this.error) {
            if (!this.stripeBillingComponent.checkFormError()) {
                // if (!this.customer.paymentInfoRequired) {
                // }
                if (this.stripeBillingComponent.paymentType === PaymentType.Credit) {
                    this.submitCard();
                }
                else if (this.stripeBillingComponent.paymentType === PaymentType.ACH) {
                    this.submitACH();
                }
            }
        }
    };
    BillingProfileEntryComponent.prototype.afterBillingSave = function (stripeCustomerId) {
        // console.log('profile entry: ' + stripeCustomerId)
        this.saving = false;
        // if (this.customer.stripeCustomerId) {
        this.emitUpdatedStripeCustomerId.emit(stripeCustomerId);
        // }
        this.emitClose.emit();
    };
    BillingProfileEntryComponent.prototype.cancel = function () {
        this.emitClose.emit();
    };
    BillingProfileEntryComponent.prototype.onError = function (event) {
        if (event) {
            if (event.response) {
                var eventResponse = JSON.parse(event.response);
                this.error = eventResponse.ExceptionMessage ? eventResponse.ExceptionMessage : eventResponse.Message;
            }
            else {
                this.error = event.message;
            }
        }
        else {
            this.error = null;
        }
    };
    BillingProfileEntryComponent.prototype.processBillingRequest = function (token) {
        var _this = this;
        this.customStripeService.addOrUpdate(this.customer.id, token, this.stripeBillingComponent.stripeBilling, this.customer.manualPayment)
            .subscribe(function (stripeCustomerId) {
            _this.afterBillingSave(stripeCustomerId);
        }), function (error) {
            _this.onError(error);
        };
    };
    return BillingProfileEntryComponent;
}(BasePaymentProcessingComponent));
export { BillingProfileEntryComponent };
