import { Component, OnInit, Input } from '@angular/core';
import { Employee, ZipCode, ZipCodeAssignment } from '../../../services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-zip-code-assignment-item',
  templateUrl: './zip-code-assignment-item.component.html',
  styleUrls: ['./zip-code-assignment-item.component.css']
})
export class ZipCodeAssignmentItemComponent implements OnInit {
  @Input() rm: Employee;
  @Input() zipCodes: ZipCode[];

  canEdit: boolean;
  zipCodeOptions: any[];

  constructor(private authHelper: AuthHelperService) { }

  ngOnInit() {
    this.setPermissions();

    if (this.rm.zipCodeAssignments) {
      this.zipCodeOptions = this.rm.zipCodeAssignments.sort((a, b) => a.zipCode.code > b.zipCode.code ? 1 : -1);
    } else {
      this.zipCodeOptions = [];
      this.rm.zipCodeAssignments = [];
    }

    const otherZips = this.zipCodes.filter(zip => !this.rm.zipCodeAssignments.some(zca => zca.zipCodeId === zip.id));
    this.zipCodeOptions = this.zipCodeOptions.concat(otherZips.map(zip => {
      return new ZipCodeAssignment({
        employeeId: this.rm.id,
        zipCode: zip,
        zipCodeId: zip.id,
      });
    }));

    this.zipCodeOptions = this.zipCodeOptions.map(option => {
      return {
        label: `${option.zipCode.code} - ${option.zipCode.city}`,
        value: option
      };
    });
  }

  setPermissions() {
    this.canEdit = this.authHelper.getDecodedAccessToken().claimNames.includes('Can Edit Zip Code Maintenance');
  }
}
