<br>
<div>
  <span class="headerText">Employees</span>
  <div style="float:right">
    <div>
      <div class="headerToggleDiv">
        <span>Show Not Employed</span>
        <p-checkbox binary="true" name="showInactive" [(ngModel)]="getInactives" (ngModelChange)="getEmployees()"></p-checkbox>
      </div>
      <button type="button" class="btn btn-success addNewButton" (click)="addNew();">Add New</button>
    </div>
  </div>
</div>

<p-table [columns]="cols" [value]="employees" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''"  ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-employee
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{employee.lastName}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{employee.firstName}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{employee.phoneNumber | phoneNumber}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{employee.address.street}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{employee.address.state.name}}</td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{employee.address.zip}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>{{employee.currentlyEmployed == true ? 'Employed' : 'Not Employed'}}</td>
      <td>
        <button
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="editEmployee(employee)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deleteEmployee(employee)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
