import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthHelperService } from './services/auth-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "./services/auth-helper.service";
import * as i2 from "@angular/router";
var MultiFactorGuard = /** @class */ (function () {
    function MultiFactorGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    MultiFactorGuard.prototype.canActivate = function (route, state) {
        // If there is a token and that token is marked as needing to setup mfa, force them to go to the setup mfa screen
        // If there isn't a token then or there's a token and it doesn't need to setup mfa, then allow them (this lets non auth-guarded pages load appropriately)
        if (this.authService.getDecodedAccessToken() && this.authService.requiresMultiFactorAuthSetup(this.authService.getDecodedAccessToken())) {
            this.router.navigate(['multiFactor', 'setup']);
            return false;
        }
        // If there is a token and that token is marked as needing to do mfa, force them to go to the mfa screen
        // If there isn't a token then or there's a token and it doesn't need to do mfa, then allow them (this lets non auth-guarded pages load appropriately)
        if (this.authService.getDecodedAccessToken() && this.authService.requiresMultiFactorAuth(this.authService.getDecodedAccessToken())) {
            this.router.navigate(['multiFactor', 'process']);
            return false;
        }
        return true;
    };
    MultiFactorGuard.ngInjectableDef = i0.defineInjectable({ factory: function MultiFactorGuard_Factory() { return new MultiFactorGuard(i0.inject(i1.AuthHelperService), i0.inject(i2.Router)); }, token: MultiFactorGuard, providedIn: "root" });
    return MultiFactorGuard;
}());
export { MultiFactorGuard };
