import { Component, OnInit, Input } from '@angular/core';
import { WellnessWorkOrder, BigDayBucketSubrange, WorkWorkOrder, ScheduledBucketDTO } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-my-schedule-crew-card',
  templateUrl: './my-schedule-crew-card.component.html',
  styleUrls: ['./my-schedule-crew-card.component.css']
})
export class MyScheduleCrewCardComponent implements OnInit {
  @Input() inputBucket: any;
  empsString: string;
  eqpString: string;
  workOrders: WellnessWorkOrder[];
  workWorkOrders: WorkWorkOrder[];

  // Will be null if a big day subrange is passed
  scheduledBucket: ScheduledBucketDTO;

  constructor() { }

  ngOnInit() {
    if (this.inputBucket.bigDayBucketId) {
      const subrange: BigDayBucketSubrange = this.inputBucket;

      this.empsString = subrange.bigDayEmployees.map((bdemp) => bdemp.employee.fullName).join(', ');
      this.eqpString = subrange.bigDayEquipment.map(bdeqp => bdeqp.equipment.fullTitle).join(', ');
      this.workOrders = [];
      this.workWorkOrders = [];

      if (subrange.bigDayBucket.wellnessWorkOrder) {
        this.workOrders.push(subrange.bigDayBucket.wellnessWorkOrder);
      } else if (subrange.bigDayBucket.workWorkOrder) {
        this.workWorkOrders.push(subrange.bigDayBucket.workWorkOrder);
      }
    } else {
      this.scheduledBucket = this.inputBucket;

      this.empsString = this.scheduledBucket.scheduledBucketEmployees.map((sbemp) => sbemp.employee.fullName).join(', ');
      this.eqpString = this.scheduledBucket.scheduledBucketEquipment.map(sbeqp => sbeqp.equipment.fullTitle).join(', ');
      this.workOrders = this.scheduledBucket.scheduledBucketWellnessWorkOrders.map(sbwwo => sbwwo.wellnessWorkOrder);
      this.workWorkOrders = this.scheduledBucket.scheduledBucketWorkWorkOrders.map(sbwwo => sbwwo.workWorkOrder);
    }

    if (!this.eqpString) {
      this.eqpString = 'No eqp scheduled';
    }
  }

}
