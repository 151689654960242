<div class="p-grid">
  <div class="p-col-12 rfq-header">
    Choose Your Quote Option
  </div>  
  <div class="p-col-12">
    <div class="card-header grayBG rfq-header">
      <span>The Free Anytime Quote</span>
      <span>
        <label for="anytime">FREE! &nbsp;</label><p-radioButton id="anytime" name="quoteRequestType" [value]="Type.Anytime" [(ngModel)]="quoteRequest.quoteRequestType" (ngModelChange)="optionChanged()" [disabled]="isDisabled || quoteRequest.id" required></p-radioButton>
      </span>
    </div>
    <div class="card-body">
      An ISA Certified Arborist® will visit your property at an unscheduled time
      to review your tree work needs.        
    </div>
  </div>
  <div class="p-col-12">
    <div class="card-header grayBG rfq-header">
      <span>** NEW ** The Virtual Quote</span>
      <span>
        <label for="virtual">FREE! &nbsp;</label><p-radioButton id="virtual" name="quoteRequestType" [value]="Type.Virtual" [(ngModel)]="quoteRequest.quoteRequestType" (ngModelChange)="optionChanged()" [disabled]="isDisabled || quoteRequest.id" required></p-radioButton>
      </span>
    </div>
    <div class="card-body">
      You upload a detailed video of your tree care needs using your cell phone and an ISA Certified Arborist® will provide you with a quote!
    </div>
  </div>
  <div class="p-col-12">
    <div class="card-header grayBG rfq-header">
      <span>The Firm-Time Quote</span>
      <span>
        <label for="firmtime">$100 &nbsp;</label><p-radioButton id="firmtime" name="quoteRequestType" [value]="Type.Firmtime" [(ngModel)]="quoteRequest.quoteRequestType" (ngModelChange)="optionChanged()" [disabled]="isDisabled || quoteRequest.id" required></p-radioButton>
      </span>
    </div>
    <div class="card-body">
      Schedule an in person meeting with an ISA Certified Arborist® to discuss
      your tree work needs. (Up to 45 minutes. $100 credit applied towards future
      work exceeding $500.)
    </div>
  </div>
</div>