import { OnInit, QueryList, EventEmitter, OnDestroy } from '@angular/core';
import { copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { WellnessWorkOrder, ScheduledBucketEmployee, ScheduledBucketEquipment, WellnessTaskCompletionStatus, WorkTaskCompletionStatus, WellnessWorkOrderCompletionStatus, WorkWorkOrderCompletionStatus, WorkWorkOrder, ScheduledBucketDTO } from '../../services_autogenerated/generated_services';
import * as moment from 'moment';
import { ScheduledItemBucketCardComponent } from '../scheduled-item-bucket-card/scheduled-item-bucket-card.component';
import { ScheduleComment } from '../../services_autogenerated/generated_services';
// tslint:disable-next-line: max-line-length
import { UnavailableEquipmentAndEmployeesComponent } from '../unavailable-equipment-and-employees/unavailable-equipment-and-employees.component';
import { Guid } from '../../models/guid';
var BaseCalendarComponent = /** @class */ (function () {
    function BaseCalendarComponent(scheduledBucketService, fb, scheduleCommentService, dragAndDropService, messageMainService, crewDateRangeService, bigDayBucketSubrangeService, bucketValidationHelper, scheduledItemBucketService, authService, emailService, wellnessWorkOrderService, workWorkOrderService, confirmationService, messageService) {
        this.scheduledBucketService = scheduledBucketService;
        this.fb = fb;
        this.scheduleCommentService = scheduleCommentService;
        this.dragAndDropService = dragAndDropService;
        this.messageMainService = messageMainService;
        this.crewDateRangeService = crewDateRangeService;
        this.bigDayBucketSubrangeService = bigDayBucketSubrangeService;
        this.bucketValidationHelper = bucketValidationHelper;
        this.scheduledItemBucketService = scheduledItemBucketService;
        this.authService = authService;
        this.emailService = emailService;
        this.wellnessWorkOrderService = wellnessWorkOrderService;
        this.workWorkOrderService = workWorkOrderService;
        this.confirmationService = confirmationService;
        this.messageService = messageService;
        this.woDragged = new EventEmitter();
        this.woDraggedEnd = new EventEmitter();
        this.taskDragged = new EventEmitter();
        this.taskDraggedEnd = new EventEmitter();
        this.displayScheduleDialog = false;
        this.displayConfirmationDialog = false;
        this.displayErrorScheduleDialog = false;
        this.message = 'Schedule and send notifications to the customers?';
        this.confirmMessage = 'Notifications were sent to the customers.';
        // At the moment there are two instances of each scheduled bucket that is used.
        // The instance inside of this.bucketListDayList is used to get the actual scheduled bucket values
        // and this.dayBucketIndexRecords is used exclusively to determine the ordering and date of the scheduled buckets
        // TODO refactor
        this.dayBucketIndexRecords = [];
        this.otherViewScheduledBuckets = [];
        this.subrangesForView = [];
        this.today = new Date();
        this.timerPaused = true;
        this.defaultTime = 300;
        this.timeLeft = this.defaultTime;
        this.intervals = [];
    }
    BaseCalendarComponent.prototype.ngOnDestroy = function () {
        this.intervals.forEach(function (interval) {
            clearInterval(interval);
        });
    };
    BaseCalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.startDate = new Date();
        this.initializeDates(this.howManyDays);
        this.getAllScheduledItems();
        this.startTimer();
        this.commentForm = this.fb.group({
            errorComment: ['']
        });
        this.dragAndDropService.getBucketUpdater().subscribe(function (bucket) { return _this.onBucketUpdate(bucket); });
    };
    BaseCalendarComponent.prototype.onBucketUpdate = function (bucket) {
        if (bucket && this.bucketCardComponents) {
            var match = this.bucketCardComponents.find(function (x) { return x.bucket.id === bucket.id; });
            if (match) {
                match.onBucketUpdate();
            }
        }
    };
    // Set the dates
    BaseCalendarComponent.prototype.initializeDates = function (numberOfDays) {
        this.bucketListDayList = [];
        for (var index = 0; index < numberOfDays; index++) {
            var start = moment(this.startDate);
            this.bucketListDayList.push({ bucketList: [], day: start.add(index, 'days') });
        }
        this.dragAndDropService.setBucketListDayList(this.bucketListDayList);
        this.crewDateRangeService.setCrewsInDateRange(this.bucketListDayList[0].day, this.bucketListDayList[0].day.clone().add(numberOfDays, 'd'));
    };
    BaseCalendarComponent.prototype.onDateChange = function () {
        this.initializeDates(this.howManyDays);
        this.getAllScheduledItems();
    };
    BaseCalendarComponent.prototype.validateDay = function (day) {
        var _this = this;
        var bucketsOnThatDay = this.bucketCardComponents
            .filter(function (bcc) {
            return moment(bcc.bucket.date).isSame(moment(day), 'day');
        });
        var rangesOnThatDay = this.subrangesForView.filter(function (sfv) {
            return moment(day).isBetween(sfv.fromDate, sfv.toDate, 'd', '[]');
        });
        var otherViewScheduledBucketsOnThatDay = this.otherViewScheduledBuckets.filter(function (sbForView) {
            return moment(day).isSame(sbForView.date, 'day');
        });
        bucketsOnThatDay.forEach(function (bucketComponent) {
            bucketComponent.bucketErrors = [];
            // Basic error checking, for foreman, having equipment, WO skills/equipment needed, equipment OOS, over total hours
            bucketComponent.bucketErrors = _this.bucketValidationHelper.validateBucket(bucketComponent.bucket);
            var otherBucketComponents = bucketsOnThatDay.filter(function (bucket) { return bucket.bucket.id !== bucketComponent.bucket.id; });
            // Add an error if an employee is on another bucket that day
            bucketComponent.bucket.scheduledBucketEmployees.forEach(function (sbe) {
                var bucketsWithSameEmployees = otherBucketComponents.filter(function (bucket) {
                    return bucket.bucket.scheduledBucketEmployees.some(function (innerSbe) {
                        return innerSbe.employeeId === sbe.employeeId;
                    });
                });
                var rangeWithSameEmployees = rangesOnThatDay.filter(function (range) {
                    return range.bigDayEmployees.some(function (bde) {
                        return bde.employeeId === sbe.employeeId;
                    });
                });
                var otherViewBucketWithSameEmployees = otherViewScheduledBucketsOnThatDay.filter(function (bucket) {
                    return bucket.scheduledBucketEmployees.some(function (innerSbe) {
                        return innerSbe.employeeId === sbe.employeeId;
                    });
                });
                if (bucketsWithSameEmployees.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.employee.fullName + ' is on another crew on this day');
                }
                if (rangeWithSameEmployees.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.employee.fullName + ' is on a multi-day crew on this day');
                }
                if (otherViewBucketWithSameEmployees.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.employee.fullName + " is already on a " + (_this.isWellness ? 'Tree Work' : 'Tree Wellness') + " crew on this day");
                }
            });
            // Add an error if a piece of equipment is on another bucket that day
            bucketComponent.bucket.scheduledBucketEquipment.forEach(function (sbe) {
                var bucketsWithSameEquipment = otherBucketComponents.filter(function (bucket) {
                    return bucket.bucket.scheduledBucketEquipment.some(function (innerSbe) {
                        return innerSbe.equipmentId === sbe.equipmentId;
                    });
                });
                var rangeWithSameEquipment = rangesOnThatDay.filter(function (range) {
                    return range.bigDayEquipment.some(function (bde) {
                        return bde.equipmentId === sbe.equipmentId;
                    });
                });
                var otherViewBucketWithSameEquipment = otherViewScheduledBucketsOnThatDay.filter(function (bucket) {
                    return bucket.scheduledBucketEquipment.some(function (innerSbe) {
                        return innerSbe.equipmentId === sbe.equipmentId;
                    });
                });
                if (bucketsWithSameEquipment.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.equipment.number + ' is on another crew on this day');
                }
                if (rangeWithSameEquipment.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.equipment.number + ' is on a mulit-day crew on this day');
                }
                if (otherViewBucketWithSameEquipment.length > 0) {
                    bucketComponent.bucketErrors.push(sbe.equipment.fullTitle + " is already on a " + (_this.isWellness ? 'Tree Work' : 'Tree Wellness') + " crew on this day");
                }
            });
        });
    };
    BaseCalendarComponent.prototype.showDialog = function (bucketListDays) {
        var _this = this;
        var errors = 0;
        // This gets the bucket card(s) for the day(s) passed in.
        // Either 1 day or all the days on that view are passed in.
        this.bucketCardComponents.filter(function (bucketCard) {
            return bucketListDays.map(function (bld) { return bld.day; }).some(function (day) { return day.isSame(bucketCard.bucket.date, 'days'); });
        }).forEach(function (bucket) {
            errors += bucket.bucketErrors.length;
        });
        if (errors > 0) {
            this.message = errors + ' error(s) were detected, would you like to schedule and send notifications to the customer anyway?';
            // This is for the scheduled comment ScheduledDate field.
            // If 1 day is passed it, that is the day it uses, if multiple days are passed in it just uses the first
            // NOTE: If they want to change the scheduled day when scheduling multiple days, do that here
            this.dayScheduledField = bucketListDays[0].day;
            this.displayErrorScheduleDialog = true;
        }
        else {
            this.message = 'Schedule and send notifications to the customers?';
            this.displayScheduleDialog = true;
        }
        this.bucketListDaysToBeScheduled = bucketListDays;
        // subrangesForView contains both work and wellness because the validation needs to check if the employee/equipment is on ANY multi-day subrange for that day
        // but for the notifications, we only want to send it out based on the type
        var subrangesForType = this.subrangesForView.filter(function (subrange) { return _this.isWellness ? subrange.bigDayBucket.wellnessWorkOrder : subrange.bigDayBucket.workWorkOrder; });
        this.multidayCrewsToBeScheduled = subrangesForType.filter(function (subrange) { return bucketListDays.map(function (bld) { return bld.day; }).some(function (day) { return day.isBetween(subrange.fromDate, subrange.toDate, 'days', '[]'); }); });
    };
    BaseCalendarComponent.prototype.scheduleDays = function () {
        var _this = this;
        this.displayScheduleDialog = false;
        this.displayErrorScheduleDialog = false;
        this.displayConfirmationDialog = true;
        var comment = this.commentForm.get('errorComment').value;
        var scheduleComment = new ScheduleComment();
        if (comment) {
            scheduleComment.dateEntered = new Date();
            // If the scheduled date was set, use it, otherwise just put today.
            scheduleComment.dateScheduled = this.dayScheduledField.isValid() ? this.dayScheduledField.toDate() : new Date();
            scheduleComment.comment = comment;
            var token = this.authService.getDecodedAccessToken();
            scheduleComment.employeeId = +token.employeeId;
            this.scheduleCommentService.add(scheduleComment).subscribe(function (sc) {
            }, function (error) {
                _this.messageService.addErrorMessage('Could not save the scheduled comment, please refresh and retry.', error);
            });
        }
        // This flattens the matrix this.bucketListDaysToBeScheduled[0, 1, ...].bucketList
        var bucketsToSchedule = [].concat.apply([], this.bucketListDaysToBeScheduled.map(function (x) { return x.bucketList; }));
        // This notifies the customers - only notify the customers who have not been notified already.
        this.emailService.sendWorkOrdersScheduledByScheduledBucket(bucketsToSchedule.map(function (b) { return b.id; })).subscribe(function (response) {
            if (response) {
                _this.messageMainService.add({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: response,
                    life: 10000 // 10 seconds
                });
            }
            // This updates the front end task status
            bucketsToSchedule.forEach(function (bucket) {
                bucket.scheduledBucketWellnessWorkOrders.forEach(function (sbWO) {
                    if (sbWO.wellnessWorkOrder.completionStatus === WellnessWorkOrderCompletionStatus.Customer_Not_Notified) {
                        sbWO.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Notified;
                        sbWO.wellnessWorkOrder.workOrderWellnessTasks.filter(function (wowt) { return wowt.wellnessTask.currentBucketId === bucket.id; })
                            .forEach(function (task) {
                            if (task.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Customer_Not_Notified) {
                                task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Notified;
                            }
                        });
                    }
                });
                bucket.scheduledBucketWorkWorkOrders.forEach(function (sbWO) {
                    if (sbWO.workWorkOrder.completionStatus === WorkWorkOrderCompletionStatus.Customer_Not_Notified) {
                        sbWO.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Notified;
                        sbWO.workWorkOrder.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.currentBucketId === bucket.id; })
                            .forEach(function (task) {
                            if (task.workTask.completionStatus === WorkTaskCompletionStatus.Customer_Not_Notified) {
                                task.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Notified;
                            }
                        });
                    }
                });
            });
        });
        this.emailService.sendWorkOrdersScheduledBySubrange(this.multidayCrewsToBeScheduled.map(function (m) { return m.id; })).subscribe(function (res) {
            _this.multidayCrewsToBeScheduled.forEach(function (subrange) {
                // theoretically, multiday crews are scheduled for ALL tasks on the WO - but let's do some checking beforehand in case the workflow changes
                if (subrange.bigDayBucket.wellnessWorkOrder && subrange.bigDayBucket.wellnessWorkOrder.completionStatus === WellnessWorkOrderCompletionStatus.Customer_Not_Notified) {
                    subrange.bigDayBucket.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Notified;
                    subrange.bigDayBucket.wellnessWorkOrder.workOrderWellnessTasks.filter(function (wowt) { return wowt.wellnessTask.currentBigDaySubrangeId === subrange.id; })
                        .forEach(function (task) {
                        if (task.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Customer_Not_Notified) {
                            task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Notified;
                        }
                    });
                }
                if (subrange.bigDayBucket.workWorkOrder && subrange.bigDayBucket.workWorkOrder.completionStatus === WorkWorkOrderCompletionStatus.Customer_Not_Notified) {
                    subrange.bigDayBucket.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Notified;
                    subrange.bigDayBucket.workWorkOrder.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.currentBigDaySubrangeId === subrange.id; })
                        .forEach(function (task) {
                        if (task.workTask.completionStatus === WorkTaskCompletionStatus.Customer_Not_Notified) {
                            task.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Notified;
                        }
                    });
                }
            });
        });
        // After scheduling, remove the comment for next time.
        this.commentForm.reset();
    };
    BaseCalendarComponent.prototype.startTimer = function () {
        var _this = this;
        if (this.timerPaused) {
            this.timerPaused = false;
            this.intervals.push(setInterval(function () {
                if (_this.timeLeft > 0) {
                    _this.timeLeft--;
                }
                else {
                    _this.getAllScheduledItems();
                    _this.timeLeft = _this.defaultTime;
                }
            }, 1000));
        }
    };
    BaseCalendarComponent.prototype.pauseTimer = function () {
        this.timerPaused = true;
        this.intervals.forEach(function (interval) {
            clearInterval(interval);
        });
    };
    BaseCalendarComponent.prototype.getAllScheduledItems = function () {
        var _this = this;
        this.scheduledBucketService.getAllScheduledBucketsInDateRange(this.bucketListDayList[0].day.startOf('d').toDate(), this.bucketListDayList[this.bucketListDayList.length - 1].day.endOf('d').toDate())
            .subscribe(function (records) {
            _this.dayBucketIndexRecords = records.filter(function (record) { return record.isWork === _this.isWork; }); // Current view records
            _this.otherViewScheduledBuckets = records.filter(function (record) { return !(record.isWork === _this.isWork); }); // Other view records
            _this.dragAndDropService.setDayBucketIndexRecords(_this.dayBucketIndexRecords);
            _this.bucketListDayList.forEach(function (bucketListDay) {
                var dayMatches = _this.dayBucketIndexRecords.filter(function (x) {
                    return bucketListDay.day.isSame(moment(x.date), 'day');
                });
                bucketListDay.bucketList = dayMatches.sort(function (a, b) { return a.bucketIndex - b.bucketIndex; });
            });
            // this.dragAndDropService.setBucketListDayList(this.bucketListDayList);
        });
        this.getUnavailableBigDayEquipmentAndEmployees();
    };
    BaseCalendarComponent.prototype.getUnavailableBigDayEquipmentAndEmployees = function () {
        var _this = this;
        this.unavailableEquipment = [];
        this.unavailableEmployees = [];
        this.bigDayBucketSubrangeService.getAllBigDayBucketSubrangeInDateRange(this.bucketListDayList[0].day.toDate(), this.bucketListDayList[this.bucketListDayList.length - 1].day.toDate()).
            subscribe(function (subranges) {
            _this.subrangesForView = subranges;
            var _loop_1 = function (i) {
                // const subrangesOnThisDay = subranges.filter(x =>
                //     x.fromDate <= this.bucketListDayList[i].day.toDate() && x.toDate >= this.bucketListDayList[i].day.toDate());
                var subrangesOnThisDay = subranges.filter(function (x) {
                    return moment(_this.bucketListDayList[i].day.toDate()).isBetween(x.fromDate, x.toDate, 'day', '[]');
                });
                var eqpMatrix = subrangesOnThisDay.map(function (subs) { return subs.bigDayEquipment; }).slice();
                var eqpArray = [].concat.apply([], eqpMatrix);
                _this.unavailableEquipment.push(eqpArray);
                var empMatrix = subrangesOnThisDay.map(function (subs) { return subs.bigDayEmployees; }).slice();
                var empArray = [].concat.apply([], empMatrix);
                _this.unavailableEmployees.push(empArray);
            };
            for (var i = 0; i < _this.howManyDays; i++) {
                _loop_1(i);
            }
        });
    };
    BaseCalendarComponent.prototype.indexHasUnavailableEquipmentOrEmployees = function (index) {
        var hasUnavailableEquipment = this.unavailableEquipment &&
            this.unavailableEquipment.length > 0 && this.unavailableEquipment[index].length > 0;
        var hasUnavailableEmployees = this.unavailableEmployees &&
            this.unavailableEmployees.length > 0 && this.unavailableEmployees[index].length > 0;
        return hasUnavailableEquipment || hasUnavailableEmployees;
    };
    BaseCalendarComponent.prototype.rescheduleDay = function (bucketDayList) {
        var _this = this;
        this.confirmationService.confirm({
            header: 'Reschedule',
            message: "Are you sure that you want reschedule all draggable crews from " + bucketDayList.day.format('M/DD/YY') + " to " + moment(this.rescheduleDate).format('M/DD/YY') + "?\n                        Any crews that remain on " + bucketDayList.day.format('M/DD/YY') + " have work that has been completed and need to be manually reviewed to reschedule.",
            accept: function () {
                var oldBucketList = bucketDayList.bucketList;
                var targetDayList = _this.bucketListDayList.find(function (x) { return x.day.isSame(moment(_this.rescheduleDate), 'd'); });
                if (targetDayList) {
                    oldBucketList.forEach(function (bucket, newIndex) {
                        if (!_this.disableDrag(bucket)) { // only update buckets that are draggable
                            copyArrayItem(oldBucketList, targetDayList.bucketList, newIndex, newIndex);
                            var recordIndex_1 = _this.dayBucketIndexRecords.findIndex(function (x) { return x.id === bucket.id; });
                            _this.dayBucketIndexRecords[recordIndex_1].bucketIndex = newIndex;
                            var record = _this.dayBucketIndexRecords[recordIndex_1];
                            record.date = record.date;
                            _this.scheduledItemBucketService.rescheduleBucket(bucket.id, newIndex, moment(record.date).startOf('d').utc().toDate())
                                .subscribe(function (x) {
                                var index = oldBucketList.findIndex(function (sb) { return sb.id === x.id; });
                                oldBucketList.splice(index, 1);
                                _this.dayBucketIndexRecords[recordIndex_1] = x;
                                _this.validateDay(x.date);
                            }, function (error) {
                                _this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                            });
                        }
                    });
                    // bucketDayList.bucketList = [];
                    targetDayList.bucketList.forEach(function (bucket, newIndex) {
                        var recordIndex = _this.dayBucketIndexRecords.findIndex(function (x) { return x.id === bucket.id; });
                        _this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                        _this.dayBucketIndexRecords[recordIndex].active = true;
                        // if (newIndex === 0) {
                        _this.dayBucketIndexRecords[recordIndex].date = targetDayList.day.format('MM/DD/YYYY');
                        bucket.date = targetDayList.day.format('MM/DD/YYYY');
                        _this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, moment(bucket.date).startOf('d').utc().toDate()).subscribe();
                        // }
                        bucket.scheduledBucketWellnessWorkOrders.forEach(function (sbwwo) {
                            sbwwo.wellnessWorkOrder.workOrderWellnessTasks.forEach(function (wowt) {
                                if (wowt.wellnessTask.currentBucketId === bucket.id) {
                                    wowt.wellnessTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                    wowt.wellnessTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                    wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                    wowt.wellnessTask.reschedule = true;
                                    sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                }
                            });
                            // tslint:disable-next-line: max-line-length
                            _this.wellnessWorkOrderService.update(sbwwo.wellnessWorkOrder, sbwwo.wellnessWorkOrder.id.toString()).subscribe();
                        });
                        bucket.scheduledBucketWorkWorkOrders.forEach(function (sbwwo) {
                            sbwwo.workWorkOrder.workOrderWorkTasks.forEach(function (wowt) {
                                if (wowt.workTask.currentBucketId === bucket.id) {
                                    wowt.workTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                    wowt.workTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                    wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                    wowt.workTask.reschedule = true;
                                    sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                }
                            });
                            _this.workWorkOrderService.update(sbwwo.workWorkOrder, sbwwo.workWorkOrder.id.toString()).subscribe();
                        });
                        var record = _this.dayBucketIndexRecords[recordIndex];
                        record.date = moment(record.date).startOf('d').format('MM/DD/YYYY');
                        _this.scheduledItemBucketService.updateBucketOrdering(record.id, newIndex, moment(record.date).startOf('d').utc().toDate())
                            .subscribe(function (x) {
                            _this.dayBucketIndexRecords[recordIndex] = x;
                        }, function (error) {
                            _this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                        });
                    });
                    _this.rescheduleDate = null;
                }
                else {
                    oldBucketList.forEach(function (bucket, newIndex) {
                        if (!_this.disableDrag(bucket)) { // only update buckets that are draggable
                            bucket.date = moment(_this.rescheduleDate).startOf('d').format('MM/DD/YYYY');
                            _this.scheduledItemBucketService.rescheduleBucket(bucket.id, newIndex, moment(bucket.date).startOf('d').utc().toDate()).subscribe(function (res) {
                                var index = oldBucketList.findIndex(function (sb) { return sb.id === res.id; });
                                oldBucketList.splice(index, 1);
                            });
                            // Update work orders - this also sends out rescheduled emails if needed
                            bucket.scheduledBucketWellnessWorkOrders.forEach(function (sbwwo) {
                                sbwwo.wellnessWorkOrder.workOrderWellnessTasks.forEach(function (wowt) {
                                    if (wowt.wellnessTask.currentBucketId === bucket.id) {
                                        wowt.wellnessTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.wellnessTask.reschedule = true;
                                        sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });
                                // tslint:disable-next-line: max-line-length
                                _this.wellnessWorkOrderService.update(sbwwo.wellnessWorkOrder, sbwwo.wellnessWorkOrder.id.toString()).subscribe();
                            });
                            bucket.scheduledBucketWorkWorkOrders.forEach(function (sbwwo) {
                                sbwwo.workWorkOrder.workOrderWorkTasks.forEach(function (wowt) {
                                    if (wowt.workTask.currentBucketId === bucket.id) {
                                        wowt.workTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.workTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.workTask.reschedule = true;
                                        sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });
                                _this.workWorkOrderService.update(sbwwo.workWorkOrder, sbwwo.workWorkOrder.id.toString()).subscribe();
                            });
                        }
                    });
                    _this.rescheduleDate = null;
                }
            }, reject: function () {
                _this.rescheduleDate = null;
                _this.messageMainService.add({
                    severity: 'info',
                    summary: 'No Action Taken',
                    detail: 'No work orders have been rescheduled'
                });
            }
        });
    };
    BaseCalendarComponent.prototype.addNewCrew = function (bucketDayList) {
        var _this = this;
        var newBucket = new ScheduledBucketDTO();
        newBucket.isBucket = true;
        newBucket.date = bucketDayList.day.format('MM/DD/YYYY');
        newBucket.active = true;
        newBucket.scheduledBucketEmployees = [];
        newBucket.scheduledBucketEquipment = [];
        newBucket.scheduledBucketWellnessWorkOrders = [];
        newBucket.scheduledBucketWorkWorkOrders = [];
        newBucket.bucketIndex = 0;
        newBucket.isWork = this.isWork;
        // Make a temporary GUID to match so when the real IDs come back we can update the objects
        var tempGuid = Guid.newGuid();
        newBucket.temp = tempGuid;
        bucketDayList.bucketList.splice(0, 0, newBucket);
        newBucket.date = moment(newBucket.date).startOf('d').format('MM/DD/YYYY');
        this.scheduledBucketService.add(newBucket).subscribe(function (addedBucket) {
            var match = bucketDayList.bucketList.find(function (x) {
                return x.temp === tempGuid;
            });
            match.id = addedBucket.id;
            _this.dragAndDropService.addBucketToCDKLists(addedBucket);
            _this.dayBucketIndexRecords.push(addedBucket);
            _this.dragAndDropService.dayBucketIndexRecords.push(addedBucket);
        });
    };
    BaseCalendarComponent.prototype.woCanMove = function (workOrder, bucket) {
        if (workOrder instanceof WellnessWorkOrder) {
            return workOrder.workOrderWellnessTasks
                .some(function (wowt) { return wowt.wellnessTask.currentBucketId === bucket.id &&
                moment(wowt.wellnessTask.scheduleDateFrom).isSame(bucket.date, 'd') &&
                (wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed ||
                    wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed); });
        }
        else if (workOrder instanceof WorkWorkOrder) {
            return workOrder.workOrderWorkTasks
                .some(function (wowt) { return wowt.workTask.currentBucketId === bucket.id &&
                moment(wowt.workTask.scheduleDateFrom).isSame(bucket.date, 'd') &&
                (wowt.workTask.completionStatus === WorkTaskCompletionStatus.Completed ||
                    wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed); });
        }
    };
    BaseCalendarComponent.prototype.disableDrag = function (bucket) {
        var hasCompletedItem = false;
        // Checks each work order to see if any of the tasks have been completed (or can not be completed).
        // Will disable drag if there is a task complete, so they cannot unschedule complete tasks.
        // They can still drag tasks that are not complete but cannot drag the whole bucket
        // This ONLY applies when the completed task is scheduled for the date of the schedule bucket
        hasCompletedItem = bucket.scheduledBucketWellnessWorkOrders.some(function (sbwwo) { return sbwwo.wellnessWorkOrder.workOrderWellnessTasks
            .some(function (wowt) { return (wowt.wellnessTask.currentBucketId === bucket.id || wowt.wellnessTask.goBackBucketId === bucket.id) &&
            (wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed ||
                wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed); }); }) ||
            bucket.scheduledBucketWorkWorkOrders.some(function (sbwwo) { return sbwwo.workWorkOrder.workOrderWorkTasks
                .some(function (wowt) { return (wowt.workTask.currentBucketId === bucket.id || wowt.workTask.goBackBucketId === bucket.id) &&
                (wowt.workTask.completionStatus === WorkTaskCompletionStatus.Completed ||
                    wowt.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed); }); });
        return hasCompletedItem;
    };
    BaseCalendarComponent.prototype.onNewBucket = function (bucket) {
        this.dragAndDropService.addBucketToCDKLists(bucket);
    };
    BaseCalendarComponent.prototype.woDragStart = function (workOrder, bucketId) {
        this.pauseTimer();
        this.woDragged.emit({ workOrder: workOrder, bucketId: bucketId });
    };
    BaseCalendarComponent.prototype.woDragEnd = function () {
        this.startTimer();
        this.woDraggedEnd.emit();
    };
    BaseCalendarComponent.prototype.taskDraggedStart = function (task) {
        this.pauseTimer();
        this.taskDragged.emit(task);
    };
    BaseCalendarComponent.prototype.taskDraggedEnded = function () {
        this.startTimer();
        this.taskDraggedEnd.emit();
    };
    // Handle your own drop events calendar
    BaseCalendarComponent.prototype.drop = function (event) {
        var _this = this;
        console.log('DROP ONTO CALENDAR');
        if (event.isPointerOverContainer) {
            var droppedItem_1 = event.previousContainer.data[event.previousIndex];
            //////////////////////////////////////////////////////////////////////////////////////////
            // This code runs when you are reordering an already-created bucket around ON THE SAME DAY.
            //////////////////////////////////////////////////////////////////////////////////////////
            if (event.previousContainer === event.container) {
                var bucketList = event.container.data;
                // This just updates all the indices in javascript
                moveItemInArray(bucketList, event.previousIndex, event.currentIndex);
                // Data call to update the DB
                bucketList.forEach(function (bucket, newIndex) {
                    var recordIndex = _this.dayBucketIndexRecords.findIndex(function (x) { return x.id === bucket.id; });
                    var record = _this.dayBucketIndexRecords[recordIndex];
                    _this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                    var date = moment(record.date).startOf('d').utc().toDate();
                    _this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, date)
                        .subscribe(function (x) {
                        _this.dayBucketIndexRecords[recordIndex] = x;
                    }, function (error) {
                        _this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                    });
                });
            }
            else {
                //////////////////////////////////////////////////////////////////////////////////////////
                // Move a bucket from one day to another
                //////////////////////////////////////////////////////////////////////////////////////////
                if (droppedItem_1 instanceof ScheduledBucketDTO) {
                    droppedItem_1.active = true;
                    var oldBucketList = event.previousContainer.data;
                    var newBucketList = event.container.data;
                    var targetDayList_1 = this.bucketListDayList.find(function (x) { return x.bucketList === event.container.data; });
                    // This just updates all the indices in javascript
                    transferArrayItem(oldBucketList, newBucketList, event.previousIndex, event.currentIndex);
                    // Data call to update the DB - updating index for buckets on the day we're moving off of
                    oldBucketList.forEach(function (bucket, newIndex) {
                        var recordIndex = _this.dayBucketIndexRecords.findIndex(function (x) { return x.id === bucket.id; });
                        var record = _this.dayBucketIndexRecords[recordIndex];
                        _this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                        var date = moment(record.date).startOf('d').utc().toDate();
                        record.date = date.toDateString();
                        _this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, date)
                            .subscribe(function (x) {
                            _this.dayBucketIndexRecords[recordIndex] = x;
                            _this.validateDay(x.date);
                        }, function (error) {
                            _this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                        });
                    });
                    // updating indexes for buckets on the way we're moving to
                    newBucketList.forEach(function (bucket, newIndex) {
                        var recordIndex = _this.dayBucketIndexRecords.findIndex(function (x) { return x.id === bucket.id; });
                        _this.dayBucketIndexRecords[recordIndex].bucketIndex = newIndex;
                        // only update the tasks for the bucket that is being moved
                        if (bucket.id === droppedItem_1.id) {
                            bucket.date = targetDayList_1.day.format('MM/DD/YYYY');
                            bucket.scheduledBucketWellnessWorkOrders.forEach(function (sbwwo) {
                                sbwwo.wellnessWorkOrder.workOrderWellnessTasks.forEach(function (wowt) {
                                    if (!wowt.wellnessTask.isGoBack && wowt.wellnessTask.currentBucketId === bucket.id) {
                                        wowt.wellnessTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.wellnessTask.reschedule = true;
                                        sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                    else if (wowt.wellnessTask.isGoBack && wowt.wellnessTask.goBackBucketId === bucket.id) {
                                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.wellnessTask.reschedule = true;
                                        sbwwo.wellnessWorkOrder.completionStatus = WellnessWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });
                            });
                            bucket.scheduledBucketWorkWorkOrders.forEach(function (sbwwo) {
                                sbwwo.workWorkOrder.workOrderWorkTasks.forEach(function (wowt) {
                                    if (!wowt.workTask.isGoBack && wowt.workTask.currentBucketId === bucket.id) {
                                        wowt.workTask.scheduleDateFrom = moment(bucket.date).utc().toDate();
                                        wowt.workTask.scheduleDateTo = moment(bucket.date).utc().toDate();
                                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.workTask.reschedule = true;
                                        sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                    else if (wowt.workTask.isGoBack && wowt.workTask.goBackBucketId === bucket.id) {
                                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Customer_Not_Notified;
                                        wowt.workTask.reschedule = true;
                                        sbwwo.workWorkOrder.completionStatus = WorkWorkOrderCompletionStatus.Customer_Not_Notified;
                                    }
                                });
                            });
                            _this.scheduledItemBucketService.rescheduleBucket(bucket.id, newIndex, new Date(bucket.date)).subscribe(function (x) {
                                _this.dayBucketIndexRecords[recordIndex] = x;
                                _this.validateDay(x.date);
                            }, function (error) {
                                _this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                            });
                        }
                        else {
                            // reorder other buckets
                            _this.scheduledItemBucketService.updateBucketOrdering(bucket.id, newIndex, new Date(bucket.date))
                                .subscribe(function (x) {
                                _this.dayBucketIndexRecords[recordIndex] = x;
                                _this.validateDay(x.date);
                            }, function (error) {
                                _this.messageService.addErrorMessage('Could not save the scheduled bucket index, please refresh and retry.', error);
                            });
                        }
                    });
                }
                else {
                    //////////////////////////////////////////////////////////////////////////////////////////
                    // Create a new bucket
                    //////////////////////////////////////////////////////////////////////////////////////////
                    var bucketList_1 = event.container.data;
                    var newBucket = this.prepareItemForScheduleBucket(droppedItem_1);
                    var targetDayList = this.bucketListDayList.find(function (x) { return x.bucketList === event.container.data; });
                    // Make a temporary GUID to match so when the real IDs come back we can update the objects
                    var tempGuid_1 = Guid.newGuid();
                    newBucket.temp = tempGuid_1;
                    bucketList_1.splice(event.currentIndex, 0, newBucket);
                    newBucket.active = true;
                    // Only delete item from previous container if it is coming off a bucket
                    if (event.previousContainer.id.includes('bucket')) {
                        event.previousContainer.data.splice(event.previousIndex, 1);
                    }
                    newBucket.date = targetDayList.day.format('MM/DD/YYYY');
                    newBucket.bucketIndex = event.currentIndex;
                    newBucket.date = moment(newBucket.date).startOf('d').format('MM/DD/YYYY');
                    this.scheduledItemBucketService.add(newBucket).subscribe(function (addedBucket) {
                        // const record = new ScheduledBucketIndex();
                        var match = bucketList_1.find(function (x) {
                            return x.temp === tempGuid_1;
                        });
                        match.id = addedBucket.id;
                        _this.dragAndDropService.addBucketToCDKLists(addedBucket);
                        _this.dayBucketIndexRecords.push(addedBucket);
                    }, function (error) {
                        _this.messageService.addErrorMessage('Could not save the scheduled bucket, please refresh and retry.', error);
                    });
                }
            }
        }
        else {
            //////////////////////////////////////////////////////////////////////////////////////////
            // If the event is not over a permitted container, delete it.
            //////////////////////////////////////////////////////////////////////////////////////////
            var bucketList = event.previousContainer.data;
            var bucket_1 = bucketList[event.previousIndex];
            bucket_1.scheduledBucketWellnessWorkOrders.forEach(function (wo) {
                _this.dragAndDropService.removeWorkOrderFromScheduling(wo.wellnessWorkOrder, bucket_1.id);
            });
            bucket_1.scheduledBucketWorkWorkOrders.forEach(function (wo) {
                _this.dragAndDropService.removeWorkOrderFromScheduling(wo.workWorkOrder, bucket_1.id);
            });
            // rxjs uses cold observables meaning that you have to subscribe to make it work.
            var recordIndex = this.dayBucketIndexRecords.findIndex(function (x) { return x.id === bucket_1.id; });
            this.scheduledItemBucketService.delete(bucket_1.id).subscribe(function () {
                _this.validateDay(bucket_1.date);
            });
            // This just updates all the indices in javascript
            bucketList.splice(event.previousIndex, 1);
            this.dayBucketIndexRecords.splice(recordIndex, 1);
        }
    };
    BaseCalendarComponent.prototype.prepareItemForScheduleBucket = function (item) {
        var bucket = new ScheduledBucketDTO();
        bucket.isBucket = true;
        bucket.isWork = this.isWork;
        bucket.scheduledBucketWellnessWorkOrders = [];
        bucket.scheduledBucketWorkWorkOrders = [];
        bucket.scheduledBucketEmployees = [];
        bucket.scheduledBucketEquipment = [];
        item.crewEmployees.forEach(function (ce) {
            var scheduledBucketEmployee = new ScheduledBucketEmployee();
            scheduledBucketEmployee.employeeId = ce.employeeId;
            scheduledBucketEmployee.scheduledBucketId = bucket.id;
            scheduledBucketEmployee.employee = ce.employee;
            bucket.scheduledBucketEmployees.push(scheduledBucketEmployee);
        });
        item.crewEquipment.forEach(function (ce) {
            var scheduledBucketEquipment = new ScheduledBucketEquipment();
            scheduledBucketEquipment.equipmentId = ce.equipmentId;
            scheduledBucketEquipment.scheduledBucketId = bucket.id;
            scheduledBucketEquipment.equipment = ce.equipment;
            bucket.scheduledBucketEquipment.push(scheduledBucketEquipment);
        });
        return bucket;
    };
    return BaseCalendarComponent;
}());
export { BaseCalendarComponent };
