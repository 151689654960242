<div class="p-grid workStyle">
  <div class="p-col-12 p-md-8">
    <div class="p-grid">
      <div class="p-col-8 header">
        Quote [{{quote.id}}]
      </div>
      <div class="p-col-4" style="font-weight: bold;">
        Quote Created: {{quote.createdDate | date: 'shortDate'}}
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-5">
        <span>Firmtime Quote</span>
      </div>
      <div class="p-col-3" style="text-align: right;">
        <span>{{firmtimeQuotePrice | currency}}</span>
      </div>
      <div class="p-col-4">

      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-4" style="text-align: right;">
    <button pButton type="button" label="Schedule & Pay" (click)="navigate()" class="buttonStyle"></button>
  </div>
</div>