import { Directive, Input, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { BigDayBucketSubrange } from '../services_autogenerated/generated_services';

@Directive({
  selector: '[appDateGreaterThanAllSubrangeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateGreaterThanAllSubrangeValidatorDirective, multi: true }]
})
export class DateGreaterThanAllSubrangeValidatorDirective implements Validator {
  @Input() appDateGreaterThanAllSubrangeValidator: BigDayBucketSubrange[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    const subranges = this.appDateGreaterThanAllSubrangeValidator;
    return (subranges && subranges.length > 0) ? dateCompareValidator(control.value, subranges)(control)
      : null;
  }
}

/** A  */
export function dateCompareValidator(thisDate: Date, subranges: BigDayBucketSubrange[]): ValidatorFn {
  let minDate = subranges[0].toDate;
  subranges.forEach((subrange) => {
    if (subrange.toDate > minDate) {
      minDate = subrange.toDate;
    }
  });
  return (control: AbstractControl): { [key: string]: any } | null => {
    return thisDate < minDate ? { 'Start Date must be before End Date': { value: control.value } } : null;
  };
}
