<!-- This crew card is for the crew creation page, it has deliable employees/equipment, a date range,
  and has drag and drop cabilities for employees/equipment.
  Basically, this card is used to create/edit crews.
  WARNING: If you make a change to this card, check the other crew card to see if it needs the change as well.
-->

<p-card class="crew-card" [ngClass]="{'crew-invalid': crewErrors.length > 0 }">
    <p-header>
      {{crewTitle}}

      <div *ngIf="crewErrors.length > 0" style="float: right;">
        <i class="pi pi-exclamation-triangle"  (mouseenter)="errorPanel.show($event)" (mouseleave)="errorPanel.hide($event)" (click)="errorPanel.toggle($event)"></i>
        <p-overlayPanel #errorPanel>
          <div *ngFor="let error of crewErrors">
            {{error}}
          </div>
        </p-overlayPanel>
      </div>
    </p-header>

    {{employeeList}}

    <div class="p-grid">
      <div class="p-col">
        <p-calendar
          [maxDate]="crew.endDate"
          [(ngModel)]="crew.startDate"
          class="secondary"
          (ngModelChange)="save()"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          appendTo="body"></p-calendar>
      </div>
      <div class="p-col-1 center">
        to
      </div>
      <div class="p-col">
        <p-calendar
          [minDate]="crew.startDate"
          [(ngModel)]="crew.endDate"
          class="secondary"
          (ngModelChange)="save()"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          appendTo="body"></p-calendar>
      </div>
    </div>

    <div class="toggle-crew-information">
      <div *ngIf="isCollapsed">
        <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
          +
        </span>
      </div>
      <div *ngIf="!isCollapsed">
        <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
          -
        </span>
      </div>
      <div [ngbCollapse]="isCollapsed" class="crew-information">
        <div [id]="'crewEquipmentList-' + crew.id"
            [cdkDropListData]="crew.crewEquipment"
            [cdkDropListConnectedTo]="dragAndDropService.allCrewEquipmentLists"
            cdkDropList
            (cdkDropListDropped)="drop($event)">
          <span *ngIf="crew.crewEquipment">Equipment</span>
          <div *ngFor="let crewEquip of crew.crewEquipment"
            class="inner-card"
            cdkDrag
            (cdkDragStarted)="dragStart($event)">
            <app-equipment-card [equipment]="crewEquip.equipment" [deleteable]="true" (deleted)="deleteEquipmentFromCrew($event)"></app-equipment-card>
          </div>
          <div *ngIf="crew.crewEquipment.length == 0" class="inner-card drag-placeholder">
            <p-card>Drag Equipment Here</p-card>
          </div>
        </div>
        <div [id]="'crewEmployeeList-' + crew.id"
          [cdkDropListData]="crew.crewEmployees"
          [cdkDropListConnectedTo]="dragAndDropService.allCrewEmployeeLists"
          cdkDropList
          (cdkDropListDropped)="drop($event)">
          <span *ngIf="crew.crewEmployees">Employees</span>
          <div *ngFor="let crewEmployee of crew.crewEmployees"
            class="inner-card"
            (cdkDragStarted)="dragStart($event)"
            cdkDrag>
            <app-employee-card [employee]="crewEmployee.employee" [deleteable]="true" (deleted)="deleteEmployeeFromCrew($event)"></app-employee-card>
          </div>
          <div *ngIf="crew.crewEmployees.length == 0" class="inner-card drag-placeholder">
            <p-card>Drag Person Here</p-card>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <button pButton type="button" label="Delete Crew" class="ui-button-rounded ui-button-raised ui-button-danger deleteButton" (click)="delete()"></button>
    </p-footer>
  </p-card>
