import { Component, OnInit } from '@angular/core';
import { PeopleFilterPipe } from 'src/app/pipes/people-filter.pipe';
import { EquipmentFilterPipe } from 'src/app/pipes/equipment-filter.pipe';
import { CrewFilterPipe } from 'src/app/pipes/crew-filter.pipe';
import { Equipment, Employee, EquipmentGenSvc, EmployeeGenSvc } from 'src/app/services_autogenerated/generated_services';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MultidayDragAndDropService } from 'src/app/services/multiday-drag-and-drop.service';

@Component({
  selector: 'app-multiday-equipment-and-employees',
  templateUrl: './multiday-equipment-and-employees.component.html',
  styleUrls: ['./multiday-equipment-and-employees.component.css'],
  providers: [PeopleFilterPipe, EquipmentFilterPipe, CrewFilterPipe]
})
export class MultidayEquipmentAndEmployeesComponent implements OnInit {
  allEquipment: Equipment[];
  allEmployees: Employee[];
  // @Input() allCDKEmployeeListIds: string[];
  // @Input() allCDKEquipmentListIds: string[];
  allCDKEmployeeListIds: string[];
  allCDKEquipmentListIds: string[];
  searchTerm = '';

  constructor(private equipmentService: EquipmentGenSvc,
    private employeeService: EmployeeGenSvc,
    private peopleFilter: PeopleFilterPipe,
    private equipmentFilter: EquipmentFilterPipe,
    public multidayDragAndDropService: MultidayDragAndDropService) { }

  ngOnInit() {

    this.equipmentService.getAll().subscribe(equipment => {
      this.allEquipment = equipment;
    });

    this.employeeService.getCurrentlyEmployed().subscribe(employees => {
      this.allEmployees = employees;
    });
  }

  startDrag(event) {
    // go to multiday service and cache object
    this.multidayDragAndDropService.startDrag(event);
  }

  drop(event) {

  }
}
