import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddressGenSvc } from '../services_autogenerated/generated_services';
import { environment } from '../../environments/environment';
import * as haversine from 'haversine';
import { MessageService } from 'primeng/api';
import { SharedColorService } from './shared-color.service';
import { ColorResponse, Locations } from '../models/colorModels';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "primeng/components/common/messageservice";
import * as i3 from "./shared-color.service";
import * as i4 from "../services_autogenerated/generated_services";
export var AddressSetResult;
(function (AddressSetResult) {
    AddressSetResult[AddressSetResult["Successful"] = 0] = "Successful";
    AddressSetResult[AddressSetResult["Failed"] = 1] = "Failed";
    AddressSetResult[AddressSetResult["Cancelled"] = 2] = "Cancelled";
})(AddressSetResult || (AddressSetResult = {}));
// tslint:disable: max-line-length
var cityName = 'Columbus';
var country = 'USA';
var ColumbusCenter = {
    latitude: 40,
    longitude: -82.985
};
// They may want to change this if they do business farther away.
var colorWheelRadius = 30; // miles, arbitrarily picked
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var LocationColorService = /** @class */ (function () {
    function LocationColorService(http, messageService, sharedColorService, addressService) {
        this.http = http;
        this.messageService = messageService;
        this.sharedColorService = sharedColorService;
        this.addressService = addressService;
        /** Angular Only Service **/
        this.urlBase = "https://open.mapquestapi.com/geocoding/v1/address?key=" + environment.MAPQUEST_API_KEY;
        this.optimizeUrlBase = "https://www.mapquestapi.com/directions/v2/optimizedroute?key=" + environment.MAPQUEST_API_KEY;
    }
    LocationColorService.prototype.geocodeAddress = function (address) {
        return this.addressService.geocode(this.formatAddressGeneral(address));
        return this.http.get(this.urlBase + "&location=" + this.formatAddressGeneral(address));
    };
    LocationColorService.prototype.geocodeAddressLeastSpecific = function (address) {
        return this.addressService.geocode(this.formatAddressLeastSpecific(address));
        return this.http.get(this.urlBase + "&location=" + this.formatAddressLeastSpecific(address));
    };
    // Sometimes MapQuest needs a simiplified version of the address format or it is too nitpicky
    LocationColorService.prototype.formatAddressGeneral = function (address) {
        return this.spaceToPlus(address.street) + "," + address.zip + "," + country; // works better
    };
    LocationColorService.prototype.optimizeRoute = function (addresses) {
        var _this = this;
        // default address is RTE office, used as the start and end location for route optimization
        var defaultAddress = '3427 E Dublin Granville Rd,43081';
        return this.addressService.routeOptimize("{\"locations\": [\"" + defaultAddress + "\"," + addresses.map(function (address) { return "\"" + _this.formatAddressMoreSpecific(address) + "\""; }) + ",\"" + defaultAddress + "\"]}");
        return this.http.get(this.optimizeUrlBase + "&json={\n            \"locations\": [\n                " + defaultAddress + ",\n                " + addresses.map(function (address) { return "\"" + _this.formatAddressMoreSpecific(address) + "\""; }) + ",\n                " + defaultAddress + "\n            ]\n        }", httpOptions);
    };
    // Sometimes MapQuest needs a more detailed version of the address or it does weird stuff when it goes to MapQuest
    LocationColorService.prototype.formatAddressMoreSpecific = function (address) {
        return address.street + "," + this.spaceToPlus(address.city) + "," + address.state.abbreviation + "," + address.zip;
    };
    // Sometimes MapQuest needs a less detailed version of the address because they can't find it for some reason
    // Use country with zip because apparently MapQuest thinks zip code 43230 is in Somalia so bound address searches by country
    LocationColorService.prototype.formatAddressLeastSpecific = function (address) {
        return address.zip + "," + country;
    };
    LocationColorService.prototype.spaceToPlus = function (str) {
        return str;
        return this.replaceAll(str, ' ', '+');
    };
    LocationColorService.prototype.replaceAll = function (str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    };
    LocationColorService.prototype.escapeRegExp = function (str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    };
    // Assumes valid response
    // willReattempt means if this call returns false, an immediate reattempt will be made by the calling code
    LocationColorService.prototype.setAddressWithGeocodeResponse = function (address, res, skipConfirm, willReattempt) {
        if (willReattempt === void 0) { willReattempt = false; }
        // If the first location has geocode quality of country, then it's just returning the middle of the US as the result.
        // Arguably, it should return with a different status code, but it doesn't. Because reasons. That are known only to MapQuest.
        if (res.results[0].locations[0].geocodeQuality === 'COUNTRY') {
            if (!willReattempt) {
                if (skipConfirm || confirm('The provided address could not be found, if you continue the color for this address will be black. \nContinue?')) {
                    address.latitude = res.results[0].locations[0].latLng.lat;
                    address.longitude = res.results[0].locations[0].latLng.lng;
                    var colors = this.getColorsForAddress(address);
                    address.geoColor = colors.geoColor;
                    address.textColor = colors.textColor;
                    return AddressSetResult.Successful;
                }
                else {
                    return AddressSetResult.Cancelled;
                }
            }
            else {
                return AddressSetResult.Failed;
            }
        }
        else if (res.results.some(function (r) { return r.locations.some(function (l) { return l.geocodeQuality === 'STREET'
            || l.geocodeQuality === 'ADDRESS'
            || l.geocodeQuality === 'INTERSECTION'
            || l.geocodeQuality === 'POINT'
            || l.geocodeQuality === 'CITY'
            || l.geocodeQuality === 'ZIP'
            || l.geocodeQuality === 'NEIGHBORHOOD'
            || l.geocodeQuality === 'ZIP_EXTENDED'; }); })) {
            var bestLocation = this.getBestLocationResult(res.results[0].locations);
            address.latitude = bestLocation.latLng.lat;
            address.longitude = bestLocation.latLng.lng;
            var distance = this.distance(ColumbusCenter.latitude, ColumbusCenter.longitude, address.latitude, address.longitude);
            // if too far away and the user doesn't OK that, return false;
            if (!skipConfirm && distance > colorWheelRadius * 2
                && !confirm('This address is outside of the ' + cityName + ' metropolitan area; if you continue, the color for this address will be black. \nContinue?')) {
                return AddressSetResult.Cancelled;
            }
            var colors = this.getColorsForAddress(address);
            address.geoColor = colors.geoColor;
            address.textColor = colors.textColor;
            return AddressSetResult.Successful;
        }
        else if (!res) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error Message',
                detail: "Error: No geocode response"
            });
            return AddressSetResult.Failed;
        }
        return AddressSetResult.Failed;
    };
    LocationColorService.prototype.getBestLocationResult = function (locations) {
        return locations.reduce(function (a, b) {
            // https://stackoverflow.com/questions/41032573/typescript-computed-getter-not-working/41035685
            // Using getter for order requires you to new up the object instead of just using the returned JSON
            var aLocation = Object.assign(new Locations(), a);
            var bLocation = Object.assign(new Locations(), b);
            return (aLocation.order < bLocation.order ? a : b);
        });
    };
    LocationColorService.prototype.getColorsForAddress = function (address) {
        return this.getColorsForLatLong(address.latitude, address.longitude);
    };
    LocationColorService.prototype.getColorsForLatLong = function (lat, lng) {
        var distance = this.distance(ColumbusCenter.latitude, ColumbusCenter.longitude, lat, lng);
        // distance of 0 = 100% luminosity; distance of colorWheel = 50%, infinite distance = 0% luminosity.
        var maxDistance = 2 * colorWheelRadius;
        var luminosity = (distance > maxDistance) ? 0 : ((1 - (distance / (2 * colorWheelRadius))) * 100);
        var bearing = this.bearing(ColumbusCenter.latitude, ColumbusCenter.longitude, lat, lng);
        return new ColorResponse("hsl(" + bearing + ",100%," + luminosity.toFixed(0) + "%)", this.sharedColorService.findContrastingTextColor(bearing, 100, luminosity));
    };
    LocationColorService.prototype.distance = function (lat1, lng1, lat2, lng2) {
        return haversine({ latitude: lat1, longitude: lng1 }, { latitude: lat2, longitude: lng2 }, { unit: 'mile' });
    };
    LocationColorService.prototype.bearing = function (lat1, lng1, lat2, lng2) {
        var dLon = (lng2 - lng1);
        var y = Math.sin(dLon) * Math.cos(lat2);
        var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        var brng = this.toDeg(Math.atan2(y, x));
        return 360 - ((brng + 360) % 360);
    };
    LocationColorService.prototype.toRad = function (deg) {
        return deg * Math.PI / 180;
    };
    LocationColorService.prototype.toDeg = function (rad) {
        return rad * 180 / Math.PI;
    };
    LocationColorService.ngInjectableDef = i0.defineInjectable({ factory: function LocationColorService_Factory() { return new LocationColorService(i0.inject(i1.HttpClient), i0.inject(i2.MessageService), i0.inject(i3.SharedColorService), i0.inject(i4.AddressGenSvc)); }, token: LocationColorService, providedIn: "root" });
    return LocationColorService;
}());
export { LocationColorService };
