import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PriorityLevel } from '../../services_autogenerated/generated_services';
import { PriorityLevelGenSvc } from '../../services_autogenerated/generated_services';
import { LocationColorService } from '../../services/location-color.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SharedColorService } from 'src/app/services/shared-color.service';

@Component({
  selector: 'app-priority-maintenance',
  templateUrl: './priority-maintenance.component.html',
  styleUrls: ['./priority-maintenance.component.css']
})
export class PriorityMaintenanceComponent implements OnInit {
  @ViewChild('priorityNameInput') priorityNameInput: ElementRef;

  cols: any[];
  color = '#FFFFFF';
  priority: PriorityLevel = new PriorityLevel();
  priorities: PriorityLevel[] = [];
  saving: boolean;

  isLoading: boolean = false;

  constructor(
    private priorityService: PriorityLevelGenSvc,
    private sharedColorService: SharedColorService,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'status', header: 'Name', width: '33%' },
      { field: 'backgroundColor', header: 'Color', width: '15%' },
      { field: 'order', header: 'Order', width: '15%' },
      { field: 'startDate', header: 'Start Date', width: '15%' },
      { field: 'endDate', header: 'End Date', width: '15%' },
      { header: '', width: '7%' }
    ];
  }

  ngOnInit() {
    this.getPriorities();
  }

  clear(ngForm: NgForm) {
    ngForm.resetForm();
    this.priority = new PriorityLevel();
    this.emptyInputs();
  }

  editPriority(priority) {
    this.priority.status = priority.status;
    this.priority.order = priority.order;
    this.priority.active = priority.active;
    this.priority.id = priority.id;
    this.priority.startDate = priority.startDate;
    this.priority.endDate = priority.endDate;
    if (priority.backgroundColor) {
      this.color = priority.backgroundColor;
    }

    this.priorityNameInput.nativeElement.focus();
  }

  deletePriority(priority) {
    // remove priority
    if (confirm('Are you sure you want to remove this record?')) {
      this.priorityService.delete(priority.id).subscribe(ok => {
        this.priorities = this.priorities.filter(p => p.id !== priority.id);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not save the priority level, please check that the priority level exists and retry.'
        });
      });
    }
  }
  

  savePriority(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      this.saving = true;
      // Edit
      if (this.priority.id) {
        // Set color and Font color
        if (this.color) {
          if (this.color.substring(0, 3) === 'rgb') {
            this.priority.backgroundColor = this.color;
            this.priority.textColor = this.sharedColorService.getFontColor(this.sharedColorService.rgbStrToRgbObject(this.color));
          } else {
            this.priority.backgroundColor = this.sharedColorService.hexToRgbStr(this.color);
            this.priority.textColor = this.sharedColorService.getFontColor(this.sharedColorService.hextoRgb(this.color));
          }
        }
        this.priorityService.update(this.priority, this.priority.id.toString()).subscribe(priority => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the priority level, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      } else { // New Save
        // Set color and Font color
        if (this.color) {
          this.priority.backgroundColor = this.sharedColorService.hexToRgbStr(this.color);
          this.priority.textColor = this.sharedColorService.getFontColor(this.sharedColorService.hextoRgb(this.color));
        }
        this.priority.active = true;
        this.priorityService.add(this.priority).subscribe(priority => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the priority level, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      }
    }
  }

  doneSaving(ngForm: NgForm) {
    this.saving = false;
    ngForm.resetForm();
    this.emptyInputs();
    this.getPriorities();
  }

  private getPriorities() {
    this.isLoading = true;
    this.priorityService.getAll().subscribe(priorities => {
      this.isLoading = false;
      this.priorities = priorities;
    }, err => {
      this.isLoading = false;
      this.priorities = [];
      this.messageService.add({
        severity: 'error',
        summary: 'Error Loading',
        detail: 'Could not get the priority levels, please refresh and retry.'
      });
    });
  }

  emptyInputs() {
    this.priority = new PriorityLevel();
    this.priority.status = null;
    this.priority.order = null;
    this.priority.backgroundColor = null;
    this.priority.textColor = null;
    this.color = '#FFFFFF';
  }
}
