import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Customer,
  State,
  CustomerGenSvc,
  CustomerType, } from 'src/app/services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { LocationColorService } from 'src/app/services/location-color.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { CustomerBillingProfileComponent } from '../customer-billing-profile/customer-billing-profile.component';

@Component({
  selector: 'app-customer-maintenance',
  templateUrl: './customer-maintenance.component.html',
  styleUrls: ['./customer-maintenance.component.css']
})
export class CustomerMaintenanceComponent implements OnInit {
  @Input() isDialog: boolean;
  @Input() sendWelcomeEmailOnAdd = true;

  @Input() customer: Customer;
  states: State[];
  cols: any[];
  saving = false;
  disableDelete: boolean;
  customerId: number;
  canEdit = false;
  customerTypes: CustomerType[];
  claimNames: string[];

  isForeman: boolean;
  isCustomer: boolean;
  hasCustomerDocumentEditPermission: boolean;

  textingOptions = [
    { label: 'When on-route', value: false },
    { label: 'Opt-out', value: true }
  ];

  @ViewChild(BlobManagerComponent) blobManagerComponent: BlobManagerComponent;
  @ViewChild(ContactInfoComponent) contactInfoComponent: ContactInfoComponent;
  @ViewChild(CustomerBillingProfileComponent) customerBillingProfileComponent: CustomerBillingProfileComponent;
  @ViewChild(NgForm) customerForm: NgForm;

  // If isDialog, need a close event
  @Output() timeToClose = new EventEmitter<Customer>();

  constructor(
    private router: Router,
    private customerService: CustomerGenSvc,
    private messageService: MessageService,
    private locationColorService: LocationColorService,
    private authHelperService: AuthHelperService,
  ) {
    this.cols = [
      { field: 'shortName', header: 'Skill/Certification', width: '30%' },
      { field: 'startDate', header: 'Start Date', type: Date, width: '20%' },
      { field: 'endDate', header: 'End Date', type: Date, width: '20%' },
      { header: 'Action', width: '30%' }
    ];
  }

  ngOnInit() {
    if (this.customer.id === undefined) {
      this.disableDelete = true;
    }

    const token = this.authHelperService.getDecodedAccessToken();
    this.claimNames = token.claimNames;
    if (this.claimNames) {
      // Can edit if they have permission to view/edit all customers OR (they have permission to view/edit their own profile AND the
      // user's id matches the customer's id they are trying to view/edit)
      this.canEdit = this.claimNames.some(claim => claim === 'View/Edit Customer Maint.') ||
        (this.claimNames.some(claim => claim === 'View/Edit Their Own Profile') && +token.id === this.customer.id);
    }
    this.isCustomer = token.userType === 'customer'; // Hides certain things from the customer
    this.hasCustomerDocumentEditPermission = token.claimNames.includes('Edit Customer Documents');
  }

  save(ngForm: NgForm): void {
    this.saving = true;
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    this.markAllAsDirty();
    if (ngForm.valid) {
      this.contactInfoComponent.preSave();
    } else {
      this.saving = false;
    }
  }

  markAllAsDirty() {
    Object.keys(this.customerForm.controls).forEach(key => {
      this.customerForm.controls[key].markAsTouched();
      this.customerForm.controls[key].markAsDirty();
    });
  }


  customerPreSave(preSaveResult: boolean) {
    if (preSaveResult) {
      this.saveCustomer();
    } else {
      this.saving = false;
    }
  }

  saveCustomer() {
    if (this.customer.id) {
      // console.log('BEFORE: ', this.customer)
      this.customerService.update(this.customer)
        .subscribe(updatedCustomer => {
          this.customer = updatedCustomer;
          this.saveBlobs(updatedCustomer);
        }, error => {
          this.showErrorMessage(error.status);
          this.saving = false;
        });
    } else {
      this.customerService.add(this.customer, this.sendWelcomeEmailOnAdd)
        .subscribe(addedCustomer => {
          this.customer = addedCustomer;
          this.saveBlobs(addedCustomer);
        }, error => {
          if (error && error.response && JSON.parse(error.response).Message) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: JSON.parse(error.response).Message,
            });
          } else {
            this.showErrorMessage(error.status);
          }

          this.saving = false;
        });
    }
  }

  private saveBlobs(savedCustomer: Customer) {
    // This is so new customers will have the right id
    this.blobManagerComponent.parentRecordId = savedCustomer.id;
    this.blobManagerComponent.save().subscribe(
      () =>
        this.doneSaving(),
      (error) => {
        this.saving = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error Saving',
          detail: 'The customer data saved correctly but the documents couldn\'t be attached.',
        });
      });
  }

  private showErrorMessage(status) {
    if (status === 409) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Saving',
        detail: 'The entered email address is already in use, please enter another one.',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Saving',
        detail: 'Could not save the customer, please check that all fields are valid and retry.'
      });
    }
  }

  private doneSaving = () => {
    this.saving = false;

    if (this.isDialog) {
      this.timeToClose.emit(this.customer);
    } else {
      this.messageService.add({
        severity: 'success',
        summary: 'Save Successful',
        detail: 'The information was saved successfully!'
      });
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(`/customerLandingPage/${this.customer.id}/1`);
      });
    }
  }

  cancel() {
    if (this.isDialog) {
      this.timeToClose.emit(null);
    } else {
      this.router.navigateByUrl('/customerList');
    }
  }

  delete() {
    if (confirm('Delete this customer?')) {
      this.customerService.delete(this.customer, this.customer.id.toString()).subscribe(() => {
        if (!this.isDialog) {
          this.router.navigateByUrl('/customerList');
        }
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the customer, please check that the customer exists and retry.'
        });
      });
    }
  }

  paymentInfoRequiredSet() {
    if (this.customerBillingProfileComponent) {
      const stripeBilling = this.customerBillingProfileComponent.stripeBilling;
      if (this.customer.paymentInfoRequired && stripeBilling && (stripeBilling.cCLast4 || stripeBilling.aCHLast4) && !this.isCustomer) {
        // fine
      } else {
        this.customerBillingProfileComponent.openBillingProfileEntryModal();
        // this.showErrorMessage("No payment information entered ");
      }
    }
  }

  billingProfileEntryModalClosed() {
    this.saving = true;
    this.customerBillingProfileComponent.getStripeBilling()
      .subscribe(stripeBilling => {
        this.saving = false;
        if (this.customer.paymentInfoRequired && stripeBilling && (stripeBilling.cCLast4 || stripeBilling.aCHLast4) && !this.isCustomer) {
          // fine
        } else {
          this.customer.paymentInfoRequired = false;
        }
      });
  }

  // addContact(primary: boolean) {
  //   const customerContact = new CustomerContact();
  //   customerContact.emailAddress = new EmailAddress();
  //   customerContact.primary = primary;
  //   this.customer.customerContacts.push(customerContact);
  //   if (primary) {
  //     this.primaryContactIndex = this.customer.customerContacts.length - 1;
  //   }
  // }

  // deleteContact(contact: CustomerContact) {
  //   const deleteIndex = this.customer.customerContacts.indexOf(contact);
  //   this.customer.customerContacts.splice(deleteIndex, 1);
  //   if (deleteIndex === this.primaryContactIndex) {
  //     this.addContact(true);
  //   } else {
  //     this.primaryContactIndex = this.customer.customerContacts.findIndex(x => x.primary);
  //   }
  // }

  // onStateSelect(state: State) {
  //   this.customer.address.stateId = state.id;
  // }

  // onPhoneChange() {
  //   if (!this.isPhoneNumberEmpty() &&
  //     !this.customer.customerContacts[this.primaryContactIndex].textingOptOut) {
  //       this.customer.customerContacts[this.primaryContactIndex].textingOptOut = false;
  //   } else if (this.isPhoneNumberEmpty()) {
  //       this.customer.customerContacts[this.primaryContactIndex].textingOptOut = null;
  //   }
  // }

  // isPhoneNumberEmpty() {
  //   return !this.customer.customerContacts[this.primaryContactIndex].phoneNumber ||
  //     this.customer.customerContacts[this.primaryContactIndex].phoneNumber === '(___) ___-____';
  // }
}
