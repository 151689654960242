<div class="headerText" *ngIf="showHeaders">Work Orders</div>
<div *ngIf="showHeaders">
  <div style="text-align: left;">
    <h6>Show:</h6>
    <p-selectButton [options]="types" [(ngModel)]="selectedType" (onChange)="changedType()"></p-selectButton>
  </div>
</div>
<br  *ngIf="showHeaders"/>
<div>
  <app-work-order-filter-bar
    [isDayView]="true"
    [typeOfWorkOrders]="selectedType"
    [rms]="rms"
    (OnWoFilteringFinished)="OnWoFilteringFinished()"
    >
  </app-work-order-filter-bar>
</div>

<p-table 
  [columns]="cols" 
  [value]="allWorkOrders" 
  [paginator]="true" 
  [rows]="rows" 
  [responsive]="true"
  [lazy]="true"
  (onLazyLoad)="loadRequestLazy($event)"
  [totalRecords]="totalRecords"
  [loading]="isLoading"
  [sortField]="'Number'"
  [sortOrder]="-1"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon *ngIf="col.header != ''" [field]="col.field" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workOrder let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{workOrder.number}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{workOrder.fullName}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{workOrder.wellnessTasksCount}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{workOrder.workTasksCount}}</td>
      <td><span  *ngIf="!workOrder.hidePrice || canSeeHiddenPrice"><span class="ui-column-title">{{columns[4].header}}</span>{{workOrder.price | currency: 'USD' : 'symbol'}}</span></td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{workOrder.hours}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>{{workOrder.createdOn | date: 'shortDate' }}</td>
      <td><span class="ui-column-title">{{columns[7].header}}</span>{{workOrder.status}}</td>
      <td>
        <button pButton type="button" (click)="edit(maintenanceLink + '/' + workOrder.id)" icon="pi pi-pencil"
          class="ui-button-secondary blackBorder">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>