import { Component, OnInit, Inject } from '@angular/core';
import { BaseWorkWorkOrderCardComponent } from '../base-work-work-order-card/base-work-work-order-card.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { EquipmentType, WorkTask, WorkTaskCompletionStatus } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-work-work-order-card',
  templateUrl: './work-work-order-card.component.html',
  styleUrls: ['./work-work-order-card.component.css']
})
export class WorkWorkOrderCardComponent extends BaseWorkWorkOrderCardComponent implements OnInit {
  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
    @Inject(Router) router: Router,
    @Inject(PriorityService) priorityService: PriorityService,
    @Inject(WorkOrderHelperService) workOrderHelperService: WorkOrderHelperService,
    @Inject(CustomerService) public customCustomerService: CustomerService,
    @Inject(AuthHelperService) authHelper: AuthHelperService
  ) {
    super(dragAndDropService, router, priorityService, workOrderHelperService, authHelper);
  }

  getEquipmentTypeDisplay() {
    let equipmentTypes: EquipmentType[] = [];

    this.unscheduledTasks.forEach(wowt => {
      if (wowt.workTask.isGoBack) {
        equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskGoBackEquipment.map(wte => wte.equipmentType));
      } else {
        equipmentTypes = equipmentTypes.concat(wowt.workTask.workTaskEquipment.map(wte => wte.equipmentType));
      }
    });

    return this.workOrderHelperService.getUnique(equipmentTypes).map(et => et.abbreviation ? et.abbreviation : et.type).join(' / ');
  }

  // Override
  setTasks() {
    // Total amount of task scheduled, even ones not scheduled on given bucket day
    this.scheduledTasksTotal = this.workOrder.workOrderWorkTasks.filter(task =>
      !((!task.workTask.isGoBack && !task.workTask.currentBucketId) || (task.workTask.isGoBack && !task.workTask.goBackBucketId))).length;
    // Tasks still unscheduled
    this.unscheduledTasks = this.workOrder.workOrderWorkTasks.filter(task =>
      (!task.workTask.isGoBack && !task.workTask.currentBucketId) || (task.workTask.isGoBack && !task.workTask.goBackBucketId));
  }

  // Override
  setBottomSection() {
    this.hoursSummary = this.unscheduledTasks.reduce((acc, curr) => acc + curr.workTask.hours, 0);
    this.hoursSummary += this.unscheduledTasks.reduce((acc, curr) => acc + curr.workTask.goBackHoursEstimate, 0);
    this.priceSummary = this.unscheduledTasks.reduce((acc, curr) => acc + curr.workTask.price, 0);
    this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
  }
}
