<br>
<div>
    <span class="headerText">Equipment</span>
    <div style="float:right">
        <div>
            <div class="headerToggleDiv">
                <span>Show Equipment Out of Service</span>
                <p-checkbox binary="true" name="showInactive" [(ngModel)]="getInactives" (ngModelChange)="getEquipment()"></p-checkbox>
            </div>
            <button type="button" class="btn btn-success addNewButton" (click)="addNew();">Add New</button>
        </div>
    </div>
</div>

<p-table [columns]="cols" [value]="equipment" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon *ngIf="col.header != ''" [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-equipment
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{equipment.number}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{equipment.name}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{equipment.equipmentType.type}}</td>
      <!--<td><span class="ui-column-title">{{columns[3].header}}</span>{{equipment.baseFee | currency: 'USD' : symbol}}</td>-->
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{specificationService.EquipmentInService(equipment) ? 'In Service' : 'Out of Service'}}</td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="editEquipment(equipment)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deleteEquipment(equipment)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
