import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

// 1x1 white pixel
const blank = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

@Component({
  selector: 'app-signature-area',
  templateUrl: './signature-area.component.html',
  styleUrls: ['./signature-area.component.css']
})
export class SignatureAreaComponent implements OnInit, AfterViewInit {
    @Input() signature: string;
    @Input() name: string;
    dataURL: string;

    enabled: boolean;
    isEmpty: boolean;
    @Input() isAlwaysDisabled = false;
    @Input() signatureError = false;

    @Output() currentSignature: EventEmitter <string> = new EventEmitter<string>();

    @ViewChild('container') container: ElementRef;

    @ViewChild(SignaturePad) signaturePad: SignaturePad;
    public signaturePadOptions: Object = { // temporary initialize
            'canvasWidth': 200,
            'canvasHeight': 125,
            'backgroundColor': '#fff'
        };

    constructor(
    ) { }

    ngOnInit() {
        this.signature ? this.isEmpty = false : this.isEmpty = true;
        this.enabled = false;
        window.addEventListener('resize', () => {
            this.getSignature();
            this.resizeCanvas();
        });
    }

    ngAfterViewInit() { // this.signaturePad is now available
        if (!this.isAlwaysDisabled) {
            this.turnEditOn();
        } else {
            this.turnEditOff();
        }

        this.getSignature();
        this.resizeCanvas(); // These are backwards on purpose.
    }

    getSignature() {
        if (this.signature) {
            this.dataURL = 'data:image/png;base64,' + this.signature;
            this.isEmpty = false;
        } else {
            this.dataURL = blank;
            this.isEmpty = true;
        }
        this.getSignatureFromDataUrl();
    }

    resizeCanvas() {
        const myWidth = this.container.nativeElement.clientWidth - 4; // seems to always be off by 4
        const canvas = this.signaturePad.queryPad()._canvas;
        canvas.width = myWidth;
        canvas.height = 125;
        this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }

    getSignatureFromDataUrl(): void {
        this.signaturePad.clear();
        this.signaturePad.fromDataURL(this.dataURL, {
            width: this.container.nativeElement.clientWidth - 4,
            height: 125
        });
    }

    drawComplete() {
        this.currentSignature.emit(this.cropSignatureCanvas(this.signaturePad.queryPad()._canvas).split(',')[1]);
    }

    drawStart() {
        this.isEmpty = false;
    }

    turnEditOn() {
        this.enabled = true;
        this.signaturePad.on();
    }

    turnEditOff() {
        this.enabled = false;
        this.signaturePad.off();
    }

    clearSignature(): void {
        this.signaturePad.clear();
        this.currentSignature.emit(null);
        this.isEmpty = true;
    }

    revertSignature(): void {
        this.signaturePad.clear();
        this.getSignatureFromDataUrl();
        this.isEmpty = this.dataURL === blank;
    }

    cropSignatureCanvas(canvas: HTMLCanvasElement) {

        // First duplicate the canvas to not alter the original
        const croppedCanvas = document.createElement('canvas'),
            croppedCtx    = croppedCanvas.getContext('2d');
        // croppedCanvas.setAttribute('id', this.uniqueCanvas);

            croppedCanvas.width  = canvas.width;
            croppedCanvas.height = canvas.height;
            croppedCtx.drawImage(canvas, 0, 0);

        // Next do the actual cropping
        let w         = croppedCanvas.width,
            h         = croppedCanvas.height,
            pix       = {x: [], y: []},
            imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height),
            x, y, index;

        for (y = 0; y < h; y++) {
            for (x = 0; x < w; x++) {
                index = (y * w + x) * 4;
                if (imageData.data[index + 3] > 0) {
                    pix.x.push(x);
                    pix.y.push(y);

                }
            }
        }
        pix.x.sort(function(a, b){return a - b; });
        pix.y.sort(function(a, b){return a - b; });
        const n = pix.x.length - 1;

        w = pix.x[n] - pix.x[0];
        h = pix.y[n] - pix.y[0];
        const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

        croppedCanvas.width = w;
        croppedCanvas.height = h;
        croppedCtx.putImageData(cut, 0, 0);

        return croppedCanvas.toDataURL();
    }
}
