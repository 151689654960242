<div class="p-grid workStyle">
  <div class="p-col-12 p-md-8">
    <div class="p-grid">
      <div class="p-col-5 header">
        Tree {{type}} [<a [routerLink]="getNavigationURL()" tabindex="0" target="blank">{{workOrder.quoteNumber}}</a>]
      </div>
      <div class="p-col-3" style="text-align: right; font-weight: bold;">
        {{workOrder.priceSummary | currency}}
      </div>
      <div class="p-col-2" style="font-weight:bold">
        Work Status
      </div>
      <div class="p-col-2" style="font-weight:bold">
        Pay Status
      </div>
    </div>
    <div class="p-grid" *ngFor="let task of tasks">
      <div class="p-col-5">
        <p-checkbox [(ngModel)]="task.selected" binary="true" [disabled]="task.disabled" (ngModelChange)="confirmInvoiceRowChecked($event, task)"></p-checkbox>
        {{task.name}}: {{getTreeList(task)}}, {{task.location}}
      </div>
      <div class="p-col-3" style="text-align: right;">
        {{task.price | currency}}
      </div>
      <div class="p-col-2">
        {{showStatusAndApplicableDateIfAny(task)}}
      </div>
      <div class="p-col-2">       
        {{task.paymentStatus ? task.paymentStatus : 'Not Paid'}}
      </div>
    </div>
    <!-- <div class="p-grid">
      <div class="p-col-5" style="font-weight: bold; white-space: pre-wrap;">
        Cash Collected
      </div>
      <div class="p-col-3" style="text-align: right; font-weight: bold;">
      </div>
      <div class="p-col-2" style="font-weight:bold">
      </div>
      <div class="p-col-2" style="font-weight:bold">
      </div>
    </div> -->
    <div *ngIf="cashToInvoice > 0" class="p-grid">
      <div class="p-col-12">
        <label for="total" style="font-weight:bold"><span class="totalLabel">Cash Or Check Collected And Not Invoiced</span>&nbsp;&nbsp;</label>
          <div>
            <input 
              pInputText 
              name="cashCollected" 
              [textMask]="{mask: currencyMask}" 
              [ngModel]="cashToInvoice" 
              [disabled]="true"
              class="totalBoxes" 
              required/>
              <!-- <button pButton
              *ngIf="!cash.id && isEditable"
              type="button"
              class="ui-button-raised ui-button-danger"
              (click)="removeCashCollectedEntry(i)"
              icon="pi pi-trash"
              style="margin-left:4px">
              </button> -->
          </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-4" style="text-align: right;">
    <div style="margin-bottom:4px;">
      <label><span class="totalLabel">Autocharge: <b>{{workOrder.autoChargeEnabled ? 'Enabled' : 'Disabled'}}</b></span></label>
    </div>
    <div>
      <button pButton type="button" label="Generate Invoice" (click)="confirmGenerateInvoice()" class="buttonStyle" [disabled]="generating || !canInvoice"></button>
    </div>
  </div>
</div>