<br>
<div>
  <span class="headerText">Quotes</span>
  <div class="flex-header">
    <span>
      <h6>Show:</h6>
      <p-selectButton [options]="types" [(ngModel)]="selectedType" (onChange)="changedType()"></p-selectButton>
    </span>
    <button type="button" class="btn btn-success add-new-button" (click)="addNew();">Add New</button>
  </div>
</div>

<p-table
  #qrTable
  [columns]="cols"
  [value]="quoteRequests"
  [paginator]="true"
  [rows]="30"
  [lazy]="true"
  (onLazyLoad)="loadRequestLazy($event)"
  [responsive]="true"
  [totalRecords]="totalRecords"
  [loading]="loading"
  *ngIf="selectedType === 'quoteRequest'"
>
  <ng-template pTemplate="caption">
    <div style="text-align: right">
      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
      <input
        type="text"
        pInputText
        size="50"
        placeholder="Search Quote Requests..."
        [(ngModel)]="searchTerm"
        style="width:auto"
        (input)="qrTable.filterGlobal($event.target.value, 'contains')">
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns"  [style.width]="col.width" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''"  ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-quoteRequest
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{quoteRequest.fullName}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{quoteRequest.phoneNumber | phoneNumber}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{quoteRequest.address}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{quoteRequest.state}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{quoteRequest.zip}}</td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{quoteRequest.completionStatus}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>
        <span *ngIf="quoteRequest.type == 'Anytime'">N/A</span>
        <span *ngIf="quoteRequest.type == 'Virtual'">N/A</span>
        <span *ngIf="quoteRequest.type == 'Firmtime'">{{quoteRequest.paymentStatus}}</span>
      </td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{quoteRequest.createdDate | date: 'shortDate'}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>{{quoteRequest.isUrgent ? 'Yes' : 'No' }}</td>
      <td>
        <button
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="edit(quoteRequest)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="delete(quoteRequest)"
          class="ui-button-danger"
          *ngIf="showDeleteForRFQ(quoteRequest)">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div *ngIf="selectedType === 'quote'">
  <app-work-order-list [showHeaders]="false"></app-work-order-list>
</div>