import { Injectable } from '@angular/core';
import { EmployeeSkill, WorkOrderWellnessTask, WorkOrderWorkTask, Equipment, Employee, ScheduledBucketDTO } from '../services_autogenerated/generated_services';
import * as moment from 'moment';
import { SpecificationService } from './specification.service';
import { wtfEnabled } from '@angular/core/src/profile/profile';

@Injectable({
  providedIn: 'root'
})
export class BucketValidationHelperService {

  constructor(public specificationService: SpecificationService) { }

  private calculateTaskHours(bucket: ScheduledBucketDTO): number {
    let hours = 0;
    const totalHours: number = this.calculateTotalHours(bucket);
    let taskHours = 0;

    if (bucket.scheduledBucketWellnessWorkOrders) {
      bucket.scheduledBucketWellnessWorkOrders.forEach(sbwo => {
        if (sbwo.wellnessWorkOrder.workOrderWellnessTasks) {
          taskHours += sbwo.wellnessWorkOrder.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.currentBucketId === bucket.id)
            .map(wowt => wowt.wellnessTask).map(t => t.hours).reduce((a, b) => a + b, 0);
          taskHours += sbwo.wellnessWorkOrder.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.goBackBucketId === bucket.id)
            .map(wowt => wowt.wellnessTask).map(t => t.goBackHoursEstimate).reduce((a, b) => a + b, 0);
        }
      });
    }

    if (bucket.scheduledBucketWorkWorkOrders) {
      bucket.scheduledBucketWorkWorkOrders.forEach(sbwo => {
        if (sbwo.workWorkOrder.workOrderWorkTasks) {
          taskHours += sbwo.workWorkOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.currentBucketId === bucket.id)
            .map(wowt => wowt.workTask).map(t => t.hours).reduce((a, b) => a + b, 0);
            taskHours += sbwo.workWorkOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.goBackBucketId === bucket.id)
              .map(wowt => wowt.workTask).map(t => t.goBackHoursEstimate).reduce((a, b) => a + b, 0);
        }
      });
    }

    hours = Number.isNaN(taskHours / totalHours * 100) ? 0 : taskHours / totalHours * 100;

    return hours;
  }

  private calculateTotalHours(bucket: ScheduledBucketDTO): number {
    let totalHours = 0;
    if (bucket.scheduledBucketEmployees.length > 0) {
      totalHours = bucket.scheduledBucketEmployees.length * 10;
    }

    return totalHours;
  }

  public validateBucket(bucket: ScheduledBucketDTO): string[] {
    let bucketErrors = [];

    if (!bucket.scheduledBucketEquipment || bucket.scheduledBucketEquipment.length < 1) {
      bucketErrors.push('No equipment');
    }

    if (!bucket.scheduledBucketEmployees) {
      bucketErrors.push('No employees');
    }

    if (bucket.scheduledBucketEmployees && !bucket.scheduledBucketEmployees.find((sbe) => sbe.employee.employeeRoles
      .some((er) => er.role.name.toLowerCase().includes('foreman')))) {
      bucketErrors.push('No foreman');
    }

    if (this.calculateTaskHours(bucket) > 100) {
      bucketErrors.push('Over ' + this.calculateTotalHours(bucket) + ' Hours of Tasks Scheduled ');
    }

    let errors: string[] = [];

    bucket.scheduledBucketEquipment.forEach(sbe => {
      if (!this.specificationService.EquipmentInService(sbe.equipment)) {
        errors.push(sbe.equipment.name + ' is out of service on this date.');
      }
    });

    const workOrderWellnessTasks: WorkOrderWellnessTask[] =
      bucket.scheduledBucketWellnessWorkOrders.map(wo => wo.wellnessWorkOrder.workOrderWellnessTasks
        .filter(task => task.wellnessTask.currentBucketId === bucket.id || task.wellnessTask.goBackBucketId === bucket.id))
      .reduce((acc, curr) => acc.concat(curr), []);

    workOrderWellnessTasks.forEach(workOrderWellnessTask => {
      // If the equipment type isn't found in the bucket's equipment - check go back equipment if priority is go back
      if (workOrderWellnessTask.wellnessTask.isGoBack && bucket.id === workOrderWellnessTask.wellnessTask.goBackBucketId) {
        workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment.forEach(wtgbe => {
          if (!bucket.scheduledBucketEquipment.find(equip => equip.equipment.equipmentType.type === wtgbe.equipmentType.type)) {
            if (wtgbe.equipmentType.type !== 'No Equipment') {
              errors.push(wtgbe.equipmentType.type + ' not in crew');
            } else {
              if (bucketErrors.some(be => be === 'No equipment')) {
                bucketErrors = bucketErrors.filter(e => e !== 'No equipment');
              }
            }
          }
        });
      } else {
        workOrderWellnessTask.wellnessTask.wellnessTaskEquipment.forEach(wte => {
          if (!bucket.scheduledBucketEquipment
            .find(equip => equip.equipment.equipmentType.type === wte.equipmentType.type)) {
            if (wte.equipmentType.type !== 'No Equipment') {
              errors.push(wte.equipmentType.type + ' not in crew');
            } else {
              if (bucketErrors.some(be => be === 'No equipment')) {
                bucketErrors = bucketErrors.filter(e => e !== 'No equipment');
              }
            }
          }
        });
      }

      // Skills of all the employees.
      const skills: EmployeeSkill[] = bucket.scheduledBucketEmployees
      .map(sbe => sbe.employee.employeeSkills).reduce((acc, curr) => acc.concat(curr), []);

      // If the skill name isn't found in the bucket's skills
      workOrderWellnessTask.wellnessTask.wellnessTaskSkills.forEach(wts => {
        if (!skills.find(bucketSkill => bucketSkill.skill.name === wts.skill.name)) {
          errors.push(wts.skill.name + ' not in crew');
        }
      });
    });

    const workOrderWorkTasks: WorkOrderWorkTask[] =
    bucket.scheduledBucketWorkWorkOrders.map(wo => wo.workWorkOrder.workOrderWorkTasks
        .filter(task => task.workTask.currentBucketId === bucket.id || task.workTask.goBackBucketId === bucket.id))
      .reduce((acc, curr) => acc.concat(curr), []);

    workOrderWorkTasks.forEach(workOrderWorkTask => {
      if (workOrderWorkTask.workTask.isGoBack && bucket.id === workOrderWorkTask.workTask.goBackBucketId) {
        workOrderWorkTask.workTask.workTaskGoBackEquipment.forEach(wtgbe => {
          if (!bucket.scheduledBucketEquipment
            .find(equip => equip.equipment.equipmentType.type === wtgbe.equipmentType.type)) {
            if (wtgbe.equipmentType.type !== 'No Equipment') {
              errors.push(wtgbe.equipmentType.type + ' not in crew');
            } else {
              if (bucketErrors.some(be => be === 'No equipment')) {
                bucketErrors = bucketErrors.filter(e => e !== 'No equipment');
              }
            }
          }
        });
      } else {
        workOrderWorkTask.workTask.workTaskEquipment.forEach(wte => {
          if (!bucket.scheduledBucketEquipment
            .find(equip => equip.equipment.equipmentType.type === wte.equipmentType.type)) {
            if (wte.equipmentType.type !== 'No Equipment') {
              errors.push(wte.equipmentType.type + ' not in crew');
            } else {
              if (bucketErrors.some(be => be === 'No equipment')) {
                bucketErrors = bucketErrors.filter(e => e !== 'No equipment');
              }
            }
          }
        });
      }

      // Skills of all the employees.
      const skills: EmployeeSkill[] = bucket.scheduledBucketEmployees
      .map(sbe => sbe.employee.employeeSkills).reduce((acc, curr) => acc.concat(curr), []);

      // If the skill name isn't found in the bucket's skills
      workOrderWorkTask.workTask.workTaskSkills.forEach(wts => {
        if (!skills.find(bucketSkill => bucketSkill.skill.name === wts.skill.name)) {
          errors.push(wts.skill.name + ' not in crew');
        }
      });
    });

    // Concat wos errors with bucket errors so all errors will be shown.
    errors = bucketErrors.concat(errors);
    // Filter out repeating errors
    bucketErrors = errors.filter(function(item, pos) { return errors.indexOf(item) === pos; });
    return bucketErrors;
  }
}
