/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./office-manager-dashboard.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/button/button";
import * as i3 from "@angular/common";
import * as i4 from "./office-manager-dashboard.component";
import * as i5 from "../../../services_autogenerated/generated_services";
import * as i6 from "@angular/router";
var styles_OfficeManagerDashboardComponent = [i0.styles];
var RenderType_OfficeManagerDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfficeManagerDashboardComponent, data: {} });
export { RenderType_OfficeManagerDashboardComponent as RenderType_OfficeManagerDashboardComponent };
function View_OfficeManagerDashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "p-col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn-margin"], ["label", "Go To Employee's RM Schedule"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToRMSchedulingPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["label", "Go To Employee's Zip Code to Day Assignments"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToRMTimeSlotPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_2 = "Go To Employee's RM Schedule"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "Go To Employee's Zip Code to Day Assignments"; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.firstName; var currVal_1 = _v.context.$implicit.lastName; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_OfficeManagerDashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfficeManagerDashboardComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rms; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OfficeManagerDashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "fine-print"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There aren't any Regional Managers in system."]))], null, null); }
function View_OfficeManagerDashboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "margin-left:8px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "pi pi-spin pi-spinner"], ["style", "font-size: 3.5em"]], null, null, null, null, null))], null, null); }
export function View_OfficeManagerDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Regional Managers"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfficeManagerDashboardComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfficeManagerDashboardComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfficeManagerDashboardComponent_4)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.rms == null) ? null : _co.rms.length) > 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = (!_co.isLoading && (_co.rms.length <= 0)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.isLoading; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_OfficeManagerDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-office-manager-dashboard", [], null, null, null, View_OfficeManagerDashboardComponent_0, RenderType_OfficeManagerDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i4.OfficeManagerDashboardComponent, [i5.EmployeeGenSvc, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfficeManagerDashboardComponentNgFactory = i1.ɵccf("app-office-manager-dashboard", i4.OfficeManagerDashboardComponent, View_OfficeManagerDashboardComponent_Host_0, {}, {}, []);
export { OfficeManagerDashboardComponentNgFactory as OfficeManagerDashboardComponentNgFactory };
