<br/>
<div class="p-grid">
  <div class="p-col-12">
    <h4>Tree Type Maintenance</h4>
  </div>
</div>
<br/>
<form id="treeTypeMaintenanceForm" #treeTypeMaintenanceForm="ngForm">
  <div class="p-grid">
    <div class="p-col-12 p-lg-6">
      <h6>Common Name</h6>
      <input pInputText #commonNameInput required name="commonName" class="inputTextBox" *ngIf="treeType" [(ngModel)]="treeType.commonName" />
    </div>
    <div class="p-col-12 p-lg-2">
      <h6>Genus</h6>
      <input pInputText required name="genus" class="inputTextBox" *ngIf="treeType" [(ngModel)]="treeType.genus" />
    </div>
    <div class="p-col-12 p-lg-2">
      <h6>Species</h6>
      <input pInputText required name="species" class="inputTextBox" *ngIf="treeType" [(ngModel)]="treeType.species"  />
    </div>
    <div class="p-col-12 p-lg-2 pushContentsToBottom">
      <button 
        pButton
        type="submit"
        (click)="save(treeTypeMaintenanceForm)"
        class="ui-button-raised ui-button-success buttonStyle"
        [disabled]="saving"
        label="Save">
      </button>
      <button style="margin-left: 8px;"
        pButton 
        type="button" 
        class="ui-button-raised ui-button-success buttonStyle" 
        (click)="clear(treeTypeMaintenanceForm)" 
        label="Clear">
      </button>
    </div>
    <div class="p-col-12 columnPadding">
      <div *ngIf="treeTypeMaintenanceForm.invalid && treeTypeMaintenanceForm.submitted" class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
  </div>
</form>
<br/>
<br/>
<p-table [columns]="cols" [value]="treeTypes" [paginator]="true" [rows]="30" [responsive]="true" sortField="commonName">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-treeType let-columns="columns">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{treeType.commonName}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{treeType.genus}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{treeType.species}}</td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="edit(treeType)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="delete(treeType)"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<br/>