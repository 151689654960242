import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { WorkOrderWellnessTask,
  WellnessTaskGenSvc,
  WellnessTask,
  WellnessTaskCompletionStatus,
  PriorityLevel,
  PriorityLevelGenSvc,
  CustomerNoteGenSvc,
  WellnessTaskPaymentStatus,
  ScheduledBucketGenSvc,
  UnableToCompleteTaskDTO,
  ScheduledBucketDTO} from '../../services_autogenerated/generated_services';
import { AuthHelperService } from '../../services/auth-helper.service';
import { MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-work-order-maintenance-wellness-task-card',
  templateUrl: './work-order-maintenance-wellness-task-card.component.html',
  styleUrls: ['./work-order-maintenance-wellness-task-card.component.css']
})
export class WorkOrderMaintenanceWellnessTaskCardComponent implements OnInit {
  @Input() workOrderWellnessTask: WorkOrderWellnessTask;
  @Input() isEditable: boolean;
  @Input() canEditAssignedTasks: boolean;
  @Input() isCustomer: boolean;
  @Input() fromQuote: boolean;
  @Input() customerId: number;
  @Input() quoteNumber: string;
  @Input() hidePrice: boolean;

  @Output() completeTaskWithoutPestRecordEvent = new EventEmitter<WorkOrderWellnessTask>();
  @Output() copyWellnessTaskEvent = new EventEmitter<WellnessTask>();
  @Output() undoCompleteTask = new EventEmitter<WellnessTask>();
  @Output() editWellnessTaskEvent = new EventEmitter<WorkOrderWellnessTask>();
  @Output() wellnessTaskToPestAppEvent = new EventEmitter<WorkOrderWellnessTask>();
  @Output() deleteWellnessTaskEvent = new EventEmitter<WorkOrderWellnessTask>();
  @Output() taskSelectedChangeEvent = new EventEmitter<null>();
  @Output() unableToCompleteWellnessTaskEvent = new EventEmitter<WellnessTask>();

  @ViewChild(Dialog) dialog;

  canComplete: boolean;
  canSeePreviousCrew: boolean;
  completeTask: boolean;
  isSelected = false;

  canSeeHiddenPrice: boolean;
  canSetGoBack: boolean;
  previousStage: WellnessTaskCompletionStatus = WellnessTaskCompletionStatus.Customer_Notified;
  canUndo: boolean;

  isIncompleteGoBack: boolean;
  showStatusBar = false;
  showTaskHour = false;
  taskHoursForGoBack = false;

  withRecord: boolean;
  unableToComplete = false;
  taskHourTotal: number;
  taskHourGoBackTotal: number;
  returnedCustomerCommentId: number;
  priorities: PriorityLevel[];
  uploadId: number;

  scheduledBucket: ScheduledBucketDTO;
  goBackScheduledBucket: ScheduledBucketDTO;

  constructor(private authHelper: AuthHelperService,
    private wellnessTaskService: WellnessTaskGenSvc,
    private priorityLevelService: PriorityLevelGenSvc,
    private messageService: MessageService,
    private customerCommentService: CustomerNoteGenSvc,
    private scheduledBucketService: ScheduledBucketGenSvc,
    private authHelperService: AuthHelperService) { }

  ngOnInit() {
    // When you are looking at a task on a quote, use the task id
    // when looking at a task on a WO, use the primordial task id
    // (So both tasks look at the same set of uploads, since WO task's have a different task id from the Quote's task)
    this.uploadId = this.fromQuote ? this.workOrderWellnessTask.wellnessTask.id : this.workOrderWellnessTask.wellnessTask.primordialTaskId;

    this.canUndo = this.workOrderWellnessTask.wellnessTask.paymentStatus !== WellnessTaskPaymentStatus.Paid
                && this.workOrderWellnessTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed;

    this.isSelected = this.workOrderWellnessTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Work_Orders_Created;
    const token = this.authHelper.getDecodedAccessToken();
    this.canComplete = token.claimNames.includes('Completing a Task');
    this.canSeeHiddenPrice = token.claimNames.includes('View Hidden Prices');
    this.canSetGoBack = token.claimNames.includes('Can Initiate Go Back') || token.claimNames.includes('Can Set Go Back');
    // If the task is a go back, don't let schedulable employees see the previous crew - otherwise just show the schedule bucket crew
    this.canSeePreviousCrew = this.workOrderWellnessTask.wellnessTask.isGoBack
          ? !token.claimNames.includes('Schedulable')
            || token.claimNames.includes('Full Control')
            || token.roles.includes('Tree Wellness Production Manager')
            || token.roles.includes('Tree Work Production Manager')
          : true;

    this.priorityLevelService.getAll().subscribe(priorities => this.priorities = priorities);
    this.setUpStatusBar();
    this.setTaskHours();

    if (this.workOrderWellnessTask.wellnessTask.currentBucketId) {
      this.scheduledBucketService.get(this.workOrderWellnessTask.wellnessTask.currentBucketId).subscribe(sb => {
        this.scheduledBucket = sb;
        if (this.canEditAssignedTasks) {
          const token = this.authHelperService.getDecodedAccessToken();
          this.canEditAssignedTasks = this.canEditAssignedTasks
                          && this.workOrderWellnessTask.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Completed
                          && this.workOrderWellnessTask.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed
                          && sb.scheduledBucketEmployees.some(sbe => sbe.employeeId === token.employeeId);
        }
      });
    }

    if (this.workOrderWellnessTask.wellnessTask.goBackBucketId) {
      this.scheduledBucketService.get(this.workOrderWellnessTask.wellnessTask.goBackBucketId).subscribe(sb => {
        this.goBackScheduledBucket = sb;
      });
    }
  }

  copyTask() {
    this.copyWellnessTaskEvent.emit(this.workOrderWellnessTask);
  }

  editWellnessTask() {
    this.editWellnessTaskEvent.emit(this.workOrderWellnessTask);
  }

  showTaskHoursDialog(withRecord: boolean, asGoBack: boolean) {
    this.taskHoursForGoBack = asGoBack;
    this.completeTask = true;
    this.withRecord = withRecord;
    this.showTaskHour = true;
  }

  showTaskHoursDialogUnableToComplete(asGoBack: boolean) {
    this.taskHoursForGoBack = asGoBack;
    this.completeTask = false;
    this.unableToComplete = true;
    this.showTaskHour = true;
  }

  showTaskHoursDialogAddHours() {
    this.completeTask = false;
    this.showTaskHour = true;
  }

  setTaskHours() {
    this.taskHourTotal = this.workOrderWellnessTask.wellnessTask.taskHourEntries.reduce((acc, curr) => acc + +curr.hours, 0);
    this.taskHourGoBackTotal = this.workOrderWellnessTask.wellnessTask.taskHourEntries.filter(entry => entry.isGoBack).reduce((acc, curr) => acc + +curr.hours, 0);
  }

  setUpStatusBar() {
    this.isIncompleteGoBack = this.workOrderWellnessTask.wellnessTask.isGoBack && this.workOrderWellnessTask.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Completed;

    this.showStatusBar = this.workOrderWellnessTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed
                      || this.workOrderWellnessTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Go_Back
                      || this.workOrderWellnessTask.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                      || this.isIncompleteGoBack;
  }

  taskHoursCompleted() {
    this.showTaskHour = false;
    if (!this.workOrderWellnessTask.wellnessTask.completedDate) {
      this.workOrderWellnessTask.wellnessTask.completedDate = new Date();
    }
    this.setTaskHours();
    this.setUpStatusBar();
    if (this.withRecord) {
      this.wellnessTaskService.updateJobFeedback(this.workOrderWellnessTask.wellnessTask.id, this.workOrderWellnessTask.wellnessTask.jobFeedback).subscribe()
      this.fromWorkOrderWellnessTaskToPesticideApplicationRecord();
    } else {
      this.completeWorkOrderWellnessTaskWithoutPestRecord();
    }
    this.unableToComplete = false;
  }

  taskHoursSubmitted(permanent: boolean) {
    this.showTaskHour = false;
    this.setTaskHours();
    this.setUpStatusBar();

    if (permanent) {
      this.workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Unable_to_be_Completed;

      // Update task
      this.wellnessTaskService.update(this.workOrderWellnessTask.wellnessTask)
        .subscribe(() => {
          this.unableToCompleteWellnessTaskEvent.emit();
          this.messageService.add({ severity: 'success', summary: 'Task has been set to unable to be completed.' });
          this.setUpStatusBar();
        });
      this.unableToComplete = false;
    } else {
      // Unschedule task and set it as a Go Back
      this.workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Go_Back;
      this.workOrderWellnessTask.wellnessTask.priorityLevelId = 1; // Go Back priority is seeded into the database and cannot be deleted
      this.workOrderWellnessTask.wellnessTask.hasCalculatedPriority = false;

      // Update task
      this.wellnessTaskService.unableToCompleteToday(new UnableToCompleteTaskDTO({
          id: this.workOrderWellnessTask.wellnessTask.id,
          feedback: this.workOrderWellnessTask.wellnessTask.isGoBack ?
            this.workOrderWellnessTask.wellnessTask.goBackFeedback : this.workOrderWellnessTask.wellnessTask.jobFeedback
        }))
        .subscribe(res => {
          this.unableToCompleteWellnessTaskEvent.emit(res);
          this.messageService.add({ severity: 'success', summary: 'Task has been set to Go Back status.' });
        });
      this.unableToComplete = false;
    }
  }

  addedHours() {
    this.showTaskHour = false;
    this.setTaskHours();
    this.setUpStatusBar();
    this.wellnessTaskService.update(this.workOrderWellnessTask.wellnessTask)
        .subscribe(() => this.messageService.add({ severity: 'success', summary: 'Task hours have been saved.' }) );
  }

  closedTaskHoursDialog() {
    this.unableToComplete = false;
    this.showTaskHour = false;
    this.setUpStatusBar();
  }

  fromWorkOrderWellnessTaskToPesticideApplicationRecord() {
    this.wellnessTaskToPestAppEvent.emit(this.workOrderWellnessTask);
  }

  completeWorkOrderWellnessTaskWithoutPestRecord() {
    this.previousStage = this.workOrderWellnessTask.wellnessTask.completionStatus;
    this.canUndo = true;
    this.setUpStatusBar();
    this.closedTaskHoursDialog();
    this.completeTaskWithoutPestRecordEvent.emit(this.workOrderWellnessTask);
  }

  undoComplete() {
    this.workOrderWellnessTask.wellnessTask.completionStatus = this.previousStage;
    this.workOrderWellnessTask.wellnessTask.completedDate = null;
    this.canUndo = false;
    if (this.returnedCustomerCommentId) {
      // Delete the customer comment that was entered when completing the task
      this.customerCommentService.delete(this.returnedCustomerCommentId).subscribe();
    }
    this.wellnessTaskService.updateCompletionStatus(this.workOrderWellnessTask.wellnessTask).subscribe((wt) => {
      this.undoCompleteTask.emit(wt);
      this.setUpStatusBar();
    });
  }

  addCustomerCommentId(id: number) {
    this.returnedCustomerCommentId = id;
  }

  workCheckChanged(checked) {
    if (checked) {
      this.workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Work_Orders_Created;
    } else {
      this.workOrderWellnessTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
    }

    this.taskSelectedChangeEvent.emit();
  }

  deleteWellnessTask() {
    this.deleteWellnessTaskEvent.emit(this.workOrderWellnessTask);
  }

  isDeletable() {
    return this.isEditable
    && this.workOrderWellnessTask.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Completed
    && this.workOrderWellnessTask.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed
    && this.workOrderWellnessTask.wellnessTask.paymentStatus !== WellnessTaskPaymentStatus.Invoiced
    && this.workOrderWellnessTask.wellnessTask.paymentStatus !== WellnessTaskPaymentStatus.Paid;
  }
}
