import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegionalManagerScheduleEvent } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-new-event-modal',
  templateUrl: './new-event-modal.component.html',
  styleUrls: ['./new-event-modal.component.css']
})
export class NewEventModalComponent implements OnInit {
  @Input() display: boolean;
  @Input() blockToUpdate: RegionalManagerScheduleEvent;

  newEvent = { title: '', additionalText: '', id: undefined };

  @Output() emitClose = new EventEmitter();
  @Output() emitSubmit = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (this.blockToUpdate) {
      this.newEvent.title = this.blockToUpdate.title;
      this.newEvent.additionalText = this.blockToUpdate.additionalText;
      this.newEvent.id = this.blockToUpdate.id;
    }
  }

  submit(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach( key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (ngForm.valid) {
      this.emitSubmit.emit(this.newEvent);
    }
  }

  cancel() {
    this.display = false;
    this.emitClose.emit();
  }
}
