import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { QuoteGenSvc, QuoteWorkOrder, QuoteRequestGenSvc, QuoteRequest, QuoteRequestType, StripeGenSvc, Customer, StripeBilling, CustomerGenSvc } from '../../services_autogenerated/generated_services';
import { BillingProfileEntryComponent } from '../customer-billing-profile/billing-profile-entry/billing-profile-entry.component';

@Component({
  selector: 'app-quote-billing',
  templateUrl: './quote-billing.component.html',
  styleUrls: ['./quote-billing.component.css']
})
export class QuoteBillingComponent implements OnInit {


  stripeBilling: StripeBilling;
  @Input() quoteId: number;
  @Input('maxWidth') maxWidth = '800px';
  @Input() isQuoteRequest = false;
  @Input() paymentAmount = 0;
  @Input() eventStart: Date = null;
  @Input('showManualPaymentChargeMessage') showManualPaymentChargeMessage = false;

  @Output() billingSaved = new EventEmitter();
  @Output() convertQuote = new EventEmitter();
  @Output() closed = new EventEmitter();

  quote: QuoteRequest | QuoteWorkOrder;
  QuoteRequestType = QuoteRequestType;
  saving = false;

  constructor(
    private route: ActivatedRoute,
    private quoteRequestService: QuoteRequestGenSvc,
    private customStripeService: StripeGenSvc,
    private customerService: CustomerGenSvc,
    private quoteService: QuoteGenSvc,
    private authService: AuthHelperService
  ) { }

  ngOnInit() {
    this.getQuote();
  }

  getQuote() {
    let quoteId = this.quoteId;
    if (!quoteId) {
      quoteId = +this.route.snapshot.paramMap.get('id');
    }
    if (this.isQuoteRequest) {
      this.quoteRequestService.get(quoteId)
      .subscribe(quote => {
        this.quote = quote;
        if (quote.quoteRequestType == QuoteRequestType.Firmtime) {
          if (this.eventStart) {
            this.quote.eventStart = this.eventStart;
          }
          this.paymentAmount = 100;
        } else {
        }
      });
    } else {
      const token = this.authService.getDecodedAccessToken();
      if (token) {
        this.quoteService.get(quoteId).subscribe(quote => {
          this.quote = quote;
          this.paymentAmount = this.paymentAmount;
        });
      } else {
          this.quoteService.getAnonForOneClick(quoteId).subscribe(quote => {
            this.quote = quote;
            this.paymentAmount = this.paymentAmount;
          });
       }
    }
  }

  onBillingSaved() {
    console.log('billing saved emit 2');
    this.billingSaved.emit();
  }

  emitClose() {
    this.closed.emit();
  }

  emitConvertQuote() {
    this.convertQuote.emit();
  }
}
