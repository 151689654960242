<ng-select class="custom" required [items]="views" id="viewDropdown" name="views" bindLabel="label" bindValue="value" [selectOnTab]="true"
  [searchable]="false" [clearable]="false" [(ngModel)]="selectedView">
  <ng-template ng-label-tmp let-item="item">
    {{item.label}}
  </ng-template>
</ng-select>
<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
  <p-tabPanel *ngIf="canViewWellness" header="Tree Wellness">
    <div *ngIf="selectedView == '2day' && index == 0">
      <app-wellness-day-view></app-wellness-day-view>
    </div>

    <div *ngIf="selectedView == 'week' && index == 0">
      <app-wellness-week-view></app-wellness-week-view>
    </div>
  </p-tabPanel>

  <p-tabPanel *ngIf="canViewWork" header="Tree Work">
    <div *ngIf="selectedView == '2day' && index == 1">
      <app-work-day-view></app-work-day-view>
    </div>

    <div *ngIf="selectedView == 'week' && index == 1">
      <app-work-week-view></app-work-week-view>
    </div>
  </p-tabPanel>
</p-tabView>