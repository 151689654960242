import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phone: any, args?: any): any {
    if (typeof phone === 'string' && phone.length === 10 && phone !== 'Need Phone') {
      return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
    } else {
      return phone;
    }
  }

}
