import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Customer, CustomerComment } from '../../services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-customer-comment-view-only',
  templateUrl: './customer-comment-view-only.component.html',
  styleUrls: ['./customer-comment-view-only.component.css']
})
export class CustomerCommentViewOnlyComponent implements OnInit, OnChanges {
  @Input() customer: Customer;

  companyWideNotes: CustomerComment[];
  hasCompanyWideNotes: boolean;
  isCustomer: boolean;

  constructor(private authService: AuthHelperService) { }

  ngOnInit() {
    var token = this.authService.getDecodedAccessToken();
    this.isCustomer = token == null || token.userType === 'customer';
   }

  ngOnChanges() {
    if (this.customer && this.customer.customerComments) {
      this.companyWideNotes = this.customer.customerComments.filter(comment => comment.companyWide && comment.active);
      this.hasCompanyWideNotes = this.customer.customerComments.some(comment => comment.companyWide && comment.active);
    }
  }
}
