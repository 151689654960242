<div>
  <h4>Export</h4>
  <br/>
  <hr/>
  <span *ngIf="isLoading" style="margin-left:8px">
    <i class="pi pi-spin pi-spinner" style="font-size: 3.5em"></i>
  </span>
  <div *ngFor="let export of exports" style="margin-bottom: 10px;">
    <app-export-row [export]="export"></app-export-row>
  </div>
</div>