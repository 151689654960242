import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ZipCode, ZipCodeGenSvc } from '../../../services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-zip-code-maintenance',
  templateUrl: './zip-code-maintenance.component.html',
  styleUrls: ['./zip-code-maintenance.component.css']
})
export class ZipCodeMaintenanceComponent implements OnInit {
  @ViewChild('zipCodeInput') zipCodeInput: ElementRef;

  cols: any[];
  zipCode: ZipCode = new ZipCode();
  zipCodes: ZipCode[] = [];
  saving: boolean;

  constructor(
    private zipCodeService: ZipCodeGenSvc,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'code', header: 'Zip Code', width: '46.5%' },
      { field: 'city', header: 'City', width: '46.5%' }
    ];
  }

  ngOnInit() {
    this.zipCodeService.getAll().subscribe(codes => {
      this.zipCodes = codes;
    });
  }

  clear(ngForm: NgForm) {
    ngForm.resetForm();
    this.zipCode = new ZipCode();
    this.emptyInputs();
  }

  editZipCode(zipCode) {
    this.zipCode.code = zipCode.code;
    this.zipCode.city = zipCode.city;
    this.zipCode.id = zipCode.id;

    this.zipCodeInput.nativeElement.focus();
  }

  deleteZipCode(zipCode) {
    // remove priority
    if (confirm('Are you sure you want to remove this record?')) {
      this.zipCodeService.delete(zipCode, zipCode.id.toString()).subscribe(ok => {
        this.zipCodes = this.zipCodes.filter(zc => zc.id !== zipCode.id);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not save the zip code, please check that the zip code exists and retry.'
        });
      });
    }
  }

  saveZipCode(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      this.saving = true;
      // Edit
      if (this.zipCode.id) {
        // Set color and Font color
        this.zipCodeService.update(this.zipCode, this.zipCode.id.toString()).subscribe(zipCode => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the zip code, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      } else { // New Save
        // Set color and Font color
        this.zipCodeService.add(this.zipCode).subscribe(zipCode => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the zip code, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      }
    }
  }

  doneSaving(ngForm: NgForm) {
    this.saving = false;
    ngForm.resetForm();
    this.emptyInputs();
    this.zipCodeService.getAll().subscribe(zipCodes => {
      this.zipCodes = zipCodes; // This is not a good idea but turbo table wont refresh.
    });
  }

  emptyInputs() {
    this.zipCode = new ZipCode();
    this.zipCode.code = null;
    this.zipCode.city = null;
  }
}
