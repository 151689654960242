<br>
<div class="ui-g">
  <div class="ui-g-12">
    <span class="headerText">Survey Maintenance</span>
    <div style="float:right" *ngIf="!currentSurveyQuestion && !currentNonDeletableQuestion">
      <div>
        <button type="button" class="btn btn-success addNewButton" (click)="addNew();">Add New</button>
      </div>
    </div>
  </div>
</div>

<form id="surveyQuestionForm" #surveyQuestionForm="ngForm" *ngIf="currentSurveyQuestion">
  <div class="ui-g">
    <div class="ui-g-7 ui-md-9 ui-lg-10 ui-xl-11">
      <h6>Question</h6>
      <textarea pInputTextarea #questionInput name="question" [(ngModel)]="currentSurveyQuestion.question"
        required></textarea>
      <!-- <input pInputText maxlength="255" class="inputTextBox" name="question"
        [(ngModel)]="currentSurveyQuestion.question" required /> -->
    </div>
    <div class="ui-g-5 ui-md-3 ui-lg-2 ui-xl-1">
      <h6>&nbsp;</h6>
      <div>
        <p-radioButton name="isYesNoGroup" [value]="true" [(ngModel)]="currentSurveyQuestion.isYesNo" label="Yes/No">
        </p-radioButton>
      </div>
      <div>
        <p-radioButton name="isYesNoGroup" [value]="false" [(ngModel)]="currentSurveyQuestion.isYesNo" label="Comment">
        </p-radioButton>
      </div>
    </div>
  </div>
  <div class="ui-g">
    <div *ngIf="surveyQuestionForm.invalid && surveyQuestionForm.submitted" class="ui-g-12 columnPadding">
      <div class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 buttonStyle">
      <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle"
        (click)="save(surveyQuestionForm, currentSurveyQuestion)" label="Save">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" (click)="cancel()"
        label="Cancel">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-danger buttonStyle" (click)="deleteCurrent()"
        [disabled]="!currentSurveyQuestion.id" label="Delete">
      </button>
    </div>
  </div>
</form>
<form id="surveyNonDeleteQuestionForm" #surveyNonDeleteQuestionForm="ngForm" *ngIf="currentNonDeletableQuestion">
  <div class="ui-g">
    <div class="ui-g-12">
      <h6>Question</h6>
      <textarea pInputTextarea #indelibleQuestionInput name="nonDeletableQuestion"
        [(ngModel)]="currentNonDeletableQuestion.question" required></textarea>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12 columnPadding"
      *ngIf="surveyNonDeleteQuestionForm.invalid && surveyNonDeleteQuestionForm.submitted">
      <div class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 buttonStyle">
      <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle"
        (click)="save(surveyNonDeleteQuestionForm, currentNonDeletableQuestion)" label="Save">
      </button>
      <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" (click)="cancelNonDelete()"
        label="Cancel">
      </button>
    </div>
  </div>
</form>
<div class="ui-g" *ngIf="surveyQuestions && surveyQuestions.length > 0">
  <div class="ui-g-12">
    <div>
      <p-checkbox binary="true" name="displayTopQuestions" [(ngModel)]="displayTopQuestions"
        (click)="toggleDisplaySatisfied()"></p-checkbox>
      <span class="checkboxText">Check to display the following mandatory questions on the outgoing surveys</span>
    </div>
  </div>
  <div class="ui-g-12">
    <div class="question-list">
      <div class="question-box" *ngFor="let question of hideableSurveyQuestions">
        <span style="display: inline-block; padding-left: .5em;">
          {{question.question}}
          <b>&nbsp;- {{question.isYesNo ? 'Yes/No' : 'Comment'}}</b>
        </span>
        <span style="display: inline-block;">
          <button pButton type="button" icon="pi pi-pencil" (click)="editNonDelete(question)"
            class="ui-button-secondary blackBorder editButtonMargin">
          </button>
        </span>
      </div>
    </div>
  </div>
  <div class="ui-g-12">
    <!-- <span class="noteText">Note: If the first question on the survey is a Yes/No question, its results will be displayed
      in the
      survey results list.</span>
    <span class="noteText">For consistency, we recommend the first question is along the lines of "Was our
      service satisfactory?"</span> -->
    <br>
    <h5>Additional Questions</h5>
  </div>
  <div class="ui-g-12">
    <div cdkDropList class="question-list" (cdkDropListDropped)="drop($event)">
      <div class="question-box cursor-move" *ngFor="let question of deletableSurveyQuestions" cdkDrag>
        <span style="display: inline-block;">
          <i class="pi pi-sort" style="margin-right: .5em;"></i>
          {{question.question}}
          <b>&nbsp;- {{question.isYesNo ? 'Yes/No' : 'Comment'}}</b>
        </span>
        <span style="display: inline-block;">
          <button pButton type="button" icon="pi pi-pencil" (click)="edit(question)"
            class="ui-button-secondary blackBorder editButtonMargin">
          </button>
          <button pButton type="button" icon="pi pi-trash" (click)="deleteFromList(question)" class="ui-button-danger">
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
