import * as tslib_1 from "tslib";
import { ErrorHandler, Injector, NgZone } from '@angular/core';
import { LogGenSvc, LogViewModel, LogViewModelLevel } from '../services_autogenerated/generated_services';
import { Router } from '@angular/router';
var CustomErrorHandlerService = /** @class */ (function (_super) {
    tslib_1.__extends(CustomErrorHandlerService, _super);
    function CustomErrorHandlerService(injector, zone) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        _this.zone = zone;
        return _this;
    }
    CustomErrorHandlerService.prototype.handleError = function (error) {
        var loggingService = this.injector.get(LogGenSvc);
        var router = this.injector.get(Router);
        var message = "Message: " + (error.message ? error.message : error.toString());
        if (error.status)
            message += " \n   -- Status: " + error.status;
        if (router.url)
            message += " \n   -- Page: " + router.url;
        if (error.stack)
            message += " \n   -- Stack: " + error.stack;
        var errorModel = new LogViewModel({
            level: LogViewModelLevel.Error,
            message: message,
            entryDate: new Date()
        });
        loggingService.add(errorModel).subscribe();
        if (error.status && error.status === 401) {
            this.zone.run(function () { return router.navigate(['/login']); });
        }
        _super.prototype.handleError.call(this, error);
    };
    return CustomErrorHandlerService;
}(ErrorHandler));
export { CustomErrorHandlerService };
