import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { BaseWellnessViewComponent } from '../base-wellness-view/base-wellness-view.component';
import { TwoDayCalendarViewComponent } from '../two-day-calendar-view/two-day-calendar-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

@Component({
    selector: 'app-wellness-day-view',
    templateUrl: './wellness-day-view.component.html',
    styleUrls: ['./wellness-day-view.component.css']
})
export class WellnessDayViewComponent extends BaseWellnessViewComponent implements OnInit {
    selectedView = 'day';

    @ViewChild(TwoDayCalendarViewComponent) calendarComponent: TwoDayCalendarViewComponent;

    constructor(
        @Inject(DragAndDropService) dragAndDropService: DragAndDropService
    ) {
        super(dragAndDropService);
    }

    ngOnInit() {
    }

}
