<div class="my-schedule-card">
  <p-card class="bucket-card">
    <div style="margin-bottom: 1em;" class="inner-card">
      <p class="field-desc">Equipment</p>
      <p-card>
        <p class="field-data"> {{ eqpString }} </p>
      </p-card>
    </div>
    <div style="margin-bottom: 1em;" class="inner-card">
      <p class="field-desc">Employees</p>
      <p-card>
        <p class="field-data"> {{ empsString }} </p>
      </p-card>
    </div>
    <div>
      <p class="field-desc">Work Orders</p>
      <div class="inner-card">
        <p-card *ngIf="(!workOrders || workOrders.length === 0) && (!workWorkOrders || workWorkOrders.length === 0)">
          <p class="field-data">No work orders</p>
        </p-card>
      </div>
      <div *ngFor="let wellnessWorkOrder of workOrders" class="inner-card">
        <app-scheduled-bucket-wellness-work-order-card *ngIf="scheduledBucket && scheduledBucket.scheduledBucketWellnessWorkOrders" [showScheduled]="true"
          [bucket]="scheduledBucket" [bucketId]="scheduledBucket.id" [workOrder]="wellnessWorkOrder" [disableDrag]="true" [showMap]="true">
        </app-scheduled-bucket-wellness-work-order-card>
        <!-- Big Day Subrange -->
        <app-wellness-work-order-card *ngIf="!scheduledBucket" [workOrder]="wellnessWorkOrder" [showMap]="true">
        </app-wellness-work-order-card>
      </div>
      <div *ngFor="let workWorkOrder of workWorkOrders" class="inner-card">
        <app-scheduled-bucket-work-work-order-card *ngIf="scheduledBucket && scheduledBucket.scheduledBucketWorkWorkOrders" [showScheduled]="true"
          [bucket]="scheduledBucket" [bucketId]="scheduledBucket.id" [workOrder]="workWorkOrder" [disableDrag]="true" [showMap]="true">
        </app-scheduled-bucket-work-work-order-card>
        <!-- Big Day Subrange -->
        <app-work-work-order-card *ngIf="!scheduledBucket" [workOrder]="workWorkOrder" [showMap]="true">
        </app-work-work-order-card>
      </div>
    </div>
  </p-card>
</div>