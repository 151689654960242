import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, EventEmitter, QueryList, OnDestroy } from '@angular/core';
import { WellnessWorkOrderGenSvc, PriorityLevelGenSvc, ScheduledBucketWellnessWorkOrder, WorkOrderWellnessTask, WorkWorkOrderGenSvc, WellnessTaskGenSvc, ScheduledBucketGenSvc, WorkWorkOrder, ScheduledBucketWorkWorkOrder, WorkOrderWorkTask, WellnessWorkOrder, WorkTaskGenSvc, WorkTaskCompletionStatus, WellnessTaskCompletionStatus, } from '../../services_autogenerated/generated_services';
import { LocationColorService } from '../../services/location-color.service';
import { WorkOrderFilterPipe } from '../../pipes/work-order-filter.pipe';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { WellnessWorkOrderCardComponent } from '../wellness-work-order-card/wellness-work-order-card.component';
import { WorkWorkOrderCardComponent } from '../work-work-order-card/work-work-order-card.component';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderFilterBarComponent } from '../work-order-filter-bar/work-order-filter-bar.component';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
var WorkOrdersPanelComponent = /** @class */ (function () {
    function WorkOrdersPanelComponent(wellnessWorkOrderService, workWorkOrderService, locationColorService, priorityService, workOrderFilter, dragAndDropService, router, wellnessTaskService, scheduledItemBucketService, workTaskService, priorityLevelService, workOrderHelper) {
        this.wellnessWorkOrderService = wellnessWorkOrderService;
        this.workWorkOrderService = workWorkOrderService;
        this.locationColorService = locationColorService;
        this.priorityService = priorityService;
        this.workOrderFilter = workOrderFilter;
        this.dragAndDropService = dragAndDropService;
        this.router = router;
        this.wellnessTaskService = wellnessTaskService;
        this.scheduledItemBucketService = scheduledItemBucketService;
        this.workTaskService = workTaskService;
        this.priorityLevelService = priorityLevelService;
        this.workOrderHelper = workOrderHelper;
        this.dragStartEvent = new EventEmitter();
        this.dragEndEvent = new EventEmitter();
        this.taskDraggedStartEvent = new EventEmitter();
        this.taskDraggedEndEvent = new EventEmitter();
        this.shouldShowLeftColor = true;
        this.shouldShowRightColor = true;
        this.isLoadingWOs = true;
        this.timerPaused = false;
        this.defaultTime = 300;
        this.timeLeft = this.defaultTime;
    }
    WorkOrdersPanelComponent.prototype.ngOnInit = function () {
        this.dragAndDropService.setWorkOrderPanel(this);
        console.time('Get WOs');
    };
    WorkOrdersPanelComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.interval);
    };
    WorkOrdersPanelComponent.prototype.getWorkOrders = function () {
        var _this = this;
        if (this.isWellnessOnly) {
            this.wellnessWorkOrderService.getWorkOrdersWithOpenWellnessTasks().subscribe(function (workOrders) {
                _this.onWellnessWorkOrdersSubscribe(workOrders);
                _this.isLoadingWOs = false;
                console.timeEnd('Get WOs');
            });
        }
        else if (this.isTreeWorkOnly) {
            this.workWorkOrderService.getWorkOrdersWithOpenWorkTasks().subscribe(function (workOrders) {
                _this.onWorkWorkOrdersSubscribe(workOrders);
                _this.isLoadingWOs = false;
                console.timeEnd('Get WOs');
            });
        }
    };
    WorkOrdersPanelComponent.prototype.ngAfterViewInit = function () {
        this.doStuff();
        this.startTimer();
    };
    WorkOrdersPanelComponent.prototype.startTimer = function () {
        var _this = this;
        this.timerPaused = false;
        this.interval = setInterval(function () {
            if (_this.timeLeft > 0) {
                _this.timeLeft--;
            }
            else {
                _this.doStuff();
                _this.timeLeft = _this.defaultTime;
            }
        }, 1000);
    };
    WorkOrdersPanelComponent.prototype.pauseTimer = function () {
        this.timerPaused = true;
        clearInterval(this.interval);
    };
    WorkOrdersPanelComponent.prototype.doStuff = function () {
        var _this = this;
        this.isLoadingWOs = true;
        this.wellnessWorkOrders = [];
        this.workWorkOrders = [];
        this.filteredWellnessWorkOrders = [];
        this.filteredWorkWorkOrders = [];
        this.getWorkOrders();
        this.dragAndDropService.getWorkOrderPanelUpdater().subscribe(function (wo) {
            if (wo && _this.wellnessWorkOrders && wo instanceof WellnessWorkOrder) {
                var existingWO = _this.wellnessWoCardComponents.find(function (card) { return card.workOrder.id === wo.id; });
                if (existingWO) {
                    // Update wo for display
                    existingWO.workOrder = wo;
                    existingWO.updateWorkOrderDisplay();
                }
                else {
                    // Push only if WO is not currently in the panel
                    _this.wellnessWorkOrders.push(wo);
                }
            }
            if (wo && _this.workWorkOrders && wo instanceof WorkWorkOrder) {
                var existingWO = _this.workWoCardComponents.find(function (card) { return card.workOrder.id === wo.id; });
                if (existingWO) {
                    // Update wo for display
                    existingWO.workOrder = wo;
                    existingWO.updateWorkOrderDisplay();
                }
                else {
                    // Push only if WO is not currently in the panel
                    _this.workWorkOrders.push(wo);
                }
            }
            _this.sortWorkOrders();
            _this.filterBarComponent.filterWorkOrders();
        });
    };
    WorkOrdersPanelComponent.prototype.OnWoFilteringFinished = function (filteredWorkOrders) {
        if (this.isWellnessOnly) {
            this.filteredWellnessWorkOrders = filteredWorkOrders;
        }
        else {
            this.filteredWorkWorkOrders = filteredWorkOrders;
        }
    };
    WorkOrdersPanelComponent.prototype.onWellnessWorkOrdersSubscribe = function (workOrders) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.wellnessWorkOrders = workOrders.map(function (wo) {
                    wo.mostUrgentPriorityLevel = _this.priorityLevelService.GetMostUrgentPriorityLevel(wo);
                    return wo;
                });
                this.rms = this.workOrderHelper.getUnique(workOrders.map(function (wo) { return wo.representative; }));
                this.sortWorkOrders();
                this.filteredWellnessWorkOrders = this.wellnessWorkOrders;
                return [2 /*return*/];
            });
        });
    };
    WorkOrdersPanelComponent.prototype.onWorkWorkOrdersSubscribe = function (workOrders) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.workWorkOrders = workOrders.map(function (wo) {
                    wo.mostUrgentPriorityLevel = _this.priorityLevelService.GetMostUrgentPriorityLevel(wo);
                    return wo;
                });
                this.rms = this.workOrderHelper.getUnique(workOrders.map(function (wo) { return wo.representative; }));
                this.sortWorkOrders();
                this.filteredWorkWorkOrders = this.workWorkOrders;
                return [2 /*return*/];
            });
        });
    };
    WorkOrdersPanelComponent.prototype.sortWorkOrders = function () {
        // This method was needed because when dragging from schedule to work order panel,
        // the dragged work order would go to the end of the list
        if (this.wellnessWorkOrders) {
            this.wellnessWorkOrders.sort(function (a, b) { return a.mostUrgentPriorityLevel.order - b.mostUrgentPriorityLevel.order; });
        }
        if (this.workWorkOrders) {
            this.workWorkOrders.sort(function (a, b) { return a.mostUrgentPriorityLevel.order - b.mostUrgentPriorityLevel.order; });
        }
    };
    WorkOrdersPanelComponent.prototype.dragStart = function (wo, event) {
        this.pauseTimer();
        if (wo.isMultiday) {
            var evt = document.createEvent('MouseEvents');
            evt.initEvent('mouseup', true, true);
            (event.source.element.nativeElement).dispatchEvent(evt);
            if (confirm('This is a multiday order. Would you like to redirect to the multiday scheduling page?')) {
                if (wo instanceof WellnessWorkOrder) {
                    this.router.navigateByUrl('scheduling/multiday/wellness');
                }
                else {
                    this.router.navigateByUrl('scheduling/multiday/work');
                }
            }
        }
        this.dragAndDropService.workOrderPanel = this;
        this.dragStartEvent.emit(wo);
    };
    WorkOrdersPanelComponent.prototype.dragEnd = function (wo) {
        this.startTimer();
        this.dragEndEvent.emit(wo);
    };
    WorkOrdersPanelComponent.prototype.taskDragged = function (woTask) {
        this.pauseTimer();
        this.dragAndDropService.workOrderPanel = this;
        // emit the task to be picked up by the base wellness view for graying out buckets that do not meet
        // the requirements for the task
        if (woTask instanceof WorkOrderWellnessTask) {
            this.taskDraggedStartEvent.emit(woTask.wellnessTask);
        }
        else if (woTask instanceof WorkOrderWorkTask) {
            this.taskDraggedStartEvent.emit(woTask.workTask);
        }
    };
    WorkOrdersPanelComponent.prototype.taskDragEnd = function () {
        this.startTimer();
        this.taskDraggedEndEvent.emit();
    };
    // Handle your own drop events WO panel
    WorkOrdersPanelComponent.prototype.drop = function (event) {
        var _this = this;
        console.log('DROP ONTO WORK ORDERS PANEL');
        var droppedItem = event.previousContainer.data[event.previousIndex];
        //////////////////////////////////////////////////////////////////////////////////////////
        // Un-schedule the work order
        //////////////////////////////////////////////////////////////////////////////////////////
        if (event.isPointerOverContainer && event.previousContainer !== event.container &&
            droppedItem instanceof ScheduledBucketWellnessWorkOrder) {
            var oldWorkOrderList = event.previousContainer.data;
            var workOrderToUnschedule_1 = oldWorkOrderList[event.previousIndex].wellnessWorkOrder;
            var buckets = this.dragAndDropService.bucketListDayList.map(function (x) { return x.bucketList; }).reduce(function (acc, curr) { return acc.concat(curr); });
            var matchingBucket_1 = buckets.find(function (bucket) { return bucket.id === droppedItem.scheduledBucketId; });
            // This just updates all the indices in javascript - updates front-end
            var index = matchingBucket_1.scheduledBucketWellnessWorkOrders.findIndex(function (sbwo) {
                return sbwo.wellnessWorkOrder.id === workOrderToUnschedule_1.id;
            });
            matchingBucket_1.scheduledBucketWellnessWorkOrders[index].wellnessWorkOrder.workOrderWellnessTasks.filter(function (wowt) {
                return wowt.wellnessTask.currentBucketId === matchingBucket_1.id;
            }).forEach(function (wowt) {
                wowt.wellnessTask.currentBucketId = null;
                wowt.wellnessTask.scheduleDateFrom = null;
                wowt.wellnessTask.scheduleDateTo = null;
            });
            matchingBucket_1.scheduledBucketWellnessWorkOrders[index].wellnessWorkOrder.workOrderWellnessTasks.filter(function (wowt) {
                return wowt.wellnessTask.goBackBucketId === matchingBucket_1.id;
            }).forEach(function (wowt) {
                wowt.wellnessTask.goBackBucketId = null;
            });
            var woPanelMatchingWO = this.wellnessWorkOrders.find(function (wo) { return wo.id === droppedItem.wellnessWorkOrderId; });
            if (woPanelMatchingWO) {
                // Update task on frontend
                woPanelMatchingWO.workOrderWellnessTasks.filter(function (wowt) { return wowt.wellnessTask.currentBucketId === matchingBucket_1.id || wowt.wellnessTask.goBackBucketId === matchingBucket_1.id; })
                    .forEach(function (wowt) {
                    if (wowt.wellnessTask.isGoBack) {
                        wowt.wellnessTask.goBackBucketId = null;
                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                    }
                    else {
                        wowt.wellnessTask.scheduleDateFrom = null;
                        wowt.wellnessTask.scheduleDateTo = null;
                        wowt.wellnessTask.currentBucketId = null;
                        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                    }
                });
            }
            matchingBucket_1.scheduledBucketWellnessWorkOrders.splice(index, 1);
            // Update front-end
            this.dragAndDropService.emitBucketUpdate(matchingBucket_1);
            // Update back-end
            this.dragAndDropService.removeWorkOrderFromScheduling(workOrderToUnschedule_1, matchingBucket_1.id);
        }
        else if (droppedItem instanceof WorkOrderWellnessTask) {
            var buckets = this.dragAndDropService.bucketListDayList.map(function (x) { return x.bucketList; }).reduce(function (acc, curr) { return acc.concat(curr); });
            var matchingBucket_2 = buckets.find(function (bucket) { return bucket.id === _this.dragAndDropService.dragStartBucket.id; });
            // This is the matching WO from the scheduled bucket
            var matchingWorkOrder_1 = matchingBucket_2.scheduledBucketWellnessWorkOrders.find(function (sbwwo) {
                return sbwwo.wellnessWorkOrder.id === droppedItem.wellnessWorkOrderId;
            }).wellnessWorkOrder;
            var woPanelMatchingWO = this.wellnessWorkOrders.find(function (wo) { return wo.id === droppedItem.wellnessWorkOrderId; });
            if (droppedItem.wellnessTask.isGoBack) {
                droppedItem.wellnessTask.goBackBucketId = null;
                droppedItem.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
            }
            else {
                droppedItem.wellnessTask.currentBucketId = null;
                droppedItem.wellnessTask.scheduleDateFrom = null;
                droppedItem.wellnessTask.scheduleDateTo = null;
                droppedItem.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
            }
            if (matchingWorkOrder_1 &&
                !matchingWorkOrder_1.workOrderWellnessTasks.some(function (wowt) { return wowt.wellnessTask.currentBucketId === matchingBucket_2.id
                    || wowt.wellnessTask.goBackBucketId === matchingBucket_2.id; })) {
                var woIndex = matchingBucket_2.scheduledBucketWellnessWorkOrders.findIndex(function (wo) { return wo.wellnessWorkOrder.id === matchingWorkOrder_1.id; });
                if (woIndex >= 0) {
                    // Remove the WO from the bucket if there are no tasks with that bucket's ID, updates front-end
                    matchingBucket_2.scheduledBucketWellnessWorkOrders.splice(woIndex, 1);
                    // Remove the scheduled bucket wellness WO from back-end
                    this.scheduledItemBucketService.deleteScheduledBucketWellnessWorkOrder(matchingBucket_2.id, matchingWorkOrder_1.id).subscribe();
                }
            }
            if (woPanelMatchingWO) {
                // Update task on frontend
                var woPanelWOTask = woPanelMatchingWO.workOrderWellnessTasks.find(function (wowt) { return wowt.id === droppedItem.id; });
                if (woPanelWOTask.wellnessTask.isGoBack) {
                    woPanelWOTask.wellnessTask.goBackBucketId = null;
                    woPanelWOTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                }
                else {
                    woPanelWOTask.wellnessTask.scheduleDateFrom = null;
                    woPanelWOTask.wellnessTask.scheduleDateTo = null;
                    woPanelWOTask.wellnessTask.currentBucketId = null;
                    woPanelWOTask.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                }
                this.wellnessTaskService.unschedule(woPanelWOTask.wellnessTask.id).subscribe();
                this.dragAndDropService.emitWorkOrderToUpdate(woPanelMatchingWO);
            }
            if (!this.wellnessWorkOrders.find(function (wo) { return wo.id === matchingWorkOrder_1.id; })) {
                // Only push WO into front-end WO list if it is currently not there
                this.wellnessWorkOrders.push(matchingWorkOrder_1);
                this.filterBarComponent.filterWorkOrders();
            }
            // Update bucket and WO on the front end
            this.dragAndDropService.emitBucketUpdate(matchingBucket_2);
            this.dragAndDropService.emitWorkOrderToUpdate(matchingWorkOrder_1);
            // Unschedule task in the back end
            this.wellnessTaskService.unschedule(droppedItem.wellnessTask.id).subscribe();
        }
        else if (event.isPointerOverContainer && event.previousContainer !== event.container &&
            droppedItem instanceof ScheduledBucketWorkWorkOrder) {
            var oldWorkOrderList = event.previousContainer.data;
            var workOrderToUnschedule_2 = oldWorkOrderList[event.previousIndex].workWorkOrder;
            var buckets = this.dragAndDropService.bucketListDayList.map(function (x) { return x.bucketList; }).reduce(function (acc, curr) { return acc.concat(curr); });
            var matchingBucket_3 = buckets.find(function (bucket) { return bucket.id === droppedItem.scheduledBucketId; });
            // This just updates all the indices in javascript - updates front-end
            var index = matchingBucket_3.scheduledBucketWorkWorkOrders.findIndex(function (sbwo) {
                return sbwo.workWorkOrder.id === workOrderToUnschedule_2.id;
            });
            matchingBucket_3.scheduledBucketWorkWorkOrders[index].workWorkOrder.workOrderWorkTasks.filter(function (wowt) {
                return wowt.workTask.currentBucketId === matchingBucket_3.id;
            }).forEach(function (wowt) {
                wowt.workTask.currentBucketId = null;
                wowt.workTask.scheduleDateFrom = null;
                wowt.workTask.scheduleDateTo = null;
            });
            matchingBucket_3.scheduledBucketWorkWorkOrders[index].workWorkOrder.workOrderWorkTasks.filter(function (wowt) {
                return wowt.workTask.goBackBucketId === matchingBucket_3.id;
            }).forEach(function (wowt) {
                wowt.workTask.goBackBucketId = null;
            });
            var woPanelMatchingWO = this.workWorkOrders.find(function (wo) { return wo.id === droppedItem.workWorkOrderId; });
            if (woPanelMatchingWO) {
                // Update task on frontend
                woPanelMatchingWO.workOrderWorkTasks.filter(function (wowt) { return wowt.workTask.currentBucketId === matchingBucket_3.id || wowt.workTask.goBackBucketId === matchingBucket_3.id; })
                    .forEach(function (wowt) {
                    if (wowt.workTask.isGoBack) {
                        wowt.workTask.goBackBucketId = null;
                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                    }
                    else {
                        wowt.workTask.scheduleDateFrom = null;
                        wowt.workTask.scheduleDateTo = null;
                        wowt.workTask.currentBucketId = null;
                        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                    }
                });
            }
            matchingBucket_3.scheduledBucketWorkWorkOrders.splice(index, 1);
            // Update bucket on front-end
            this.dragAndDropService.emitBucketUpdate(matchingBucket_3);
            // Update WO and bucket on the back-end
            this.dragAndDropService.removeWorkOrderFromScheduling(workOrderToUnschedule_2, matchingBucket_3.id);
        }
        else if (droppedItem instanceof WorkOrderWorkTask) {
            var buckets = this.dragAndDropService.bucketListDayList.map(function (x) { return x.bucketList; }).reduce(function (acc, curr) { return acc.concat(curr); });
            var matchingBucket_4 = buckets.find(function (bucket) { return bucket.id === _this.dragAndDropService.dragStartBucket.id; });
            // This is the matching WO from the scheduled bucket
            var matchingWorkOrder_2 = matchingBucket_4.scheduledBucketWorkWorkOrders.find(function (sbwwo) {
                return sbwwo.workWorkOrder.id === droppedItem.workWorkOrderId;
            }).workWorkOrder;
            var woPanelMatchingWO = this.workWorkOrders.find(function (wo) { return wo.id === droppedItem.workWorkOrderId; });
            // Update task on front end
            if (droppedItem.workTask.isGoBack) {
                droppedItem.workTask.goBackBucketId = null;
                droppedItem.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
            }
            else {
                droppedItem.workTask.currentBucketId = null;
                droppedItem.workTask.scheduleDateFrom = null;
                droppedItem.workTask.scheduleDateTo = null;
                droppedItem.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
            }
            if (matchingWorkOrder_2 && !matchingWorkOrder_2.workOrderWorkTasks.some(function (wowt) {
                return wowt.workTask.currentBucketId === matchingBucket_4.id || wowt.workTask.goBackBucketId === matchingBucket_4.id;
            })) {
                var woIndex = matchingBucket_4.scheduledBucketWorkWorkOrders
                    .findIndex(function (wo) { return wo.workWorkOrder.id === matchingWorkOrder_2.id; });
                if (woIndex >= 0) {
                    // Remove the WO from the bucket if there are no tasks with that bucket's ID, front-end update
                    matchingBucket_4.scheduledBucketWorkWorkOrders.splice(woIndex, 1);
                    // Remove the scheduled bucket WO from the back-end
                    this.scheduledItemBucketService.deleteScheduledBucketWorkWorkOrder(matchingBucket_4.id, matchingWorkOrder_2.id).subscribe();
                }
            }
            if (woPanelMatchingWO) {
                var woPanelWOTask = woPanelMatchingWO.workOrderWorkTasks.find(function (wowt) { return wowt.id === droppedItem.id; });
                // Update task on front end
                if (woPanelWOTask.workTask.isGoBack) {
                    woPanelWOTask.workTask.goBackBucketId = null;
                    woPanelWOTask.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                }
                else {
                    woPanelWOTask.workTask.scheduleDateFrom = null;
                    woPanelWOTask.workTask.scheduleDateTo = null;
                    woPanelWOTask.workTask.currentBucketId = null;
                    woPanelWOTask.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                }
                this.dragAndDropService.emitWorkOrderToUpdate(woPanelMatchingWO);
            }
            if (!this.workWorkOrders.find(function (wo) { return wo.id === matchingWorkOrder_2.id; })) {
                // Only push WO into front-end WO list if it is currently not there
                this.workWorkOrders.push(matchingWorkOrder_2);
                this.filterBarComponent.filterWorkOrders();
            }
            // Update bucket and WO on the front end
            this.dragAndDropService.emitBucketUpdate(matchingBucket_4);
            this.dragAndDropService.emitWorkOrderToUpdate(matchingWorkOrder_2);
            // Unschedule task in the back end
            this.workTaskService.unschedule(droppedItem.workTask.id).subscribe();
        }
    };
    WorkOrdersPanelComponent.prototype.shouldDisableWODrag = function (workOrder) {
        var cardComponent;
        if (workOrder instanceof WellnessWorkOrder) {
            cardComponent = this.wellnessWoCardComponents.find(function (card) { return card.workOrder.id === workOrder.id; });
        }
        else if (workOrder instanceof WorkWorkOrder) {
            cardComponent = this.workWoCardComponents.find(function (card) { return card.workOrder.id === workOrder.id; });
        }
        if (workOrder && cardComponent) {
            return cardComponent.shouldDisableWODrag();
        }
        else {
            return false;
        }
    };
    return WorkOrdersPanelComponent;
}());
export { WorkOrdersPanelComponent };
