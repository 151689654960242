import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { SystemSettingDTO, SystemSettingGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.css']
})
export class SystemSettingsComponent implements OnInit {
  isLoading: boolean;
  settings: SystemSettingDTO[];

  claims: string[];

  constructor(private settingsService: SystemSettingGenSvc,
    private messageService: MessageService,
    private authSerivce: AuthHelperService) { }

  ngOnInit() {
    this.claims = this.authSerivce.getDecodedAccessToken().claimNames;
    this.isLoading = true;

    this.settingsService.get().subscribe(res => {
      this.isLoading = false;
      this.settings = res;
    }, error => {
      this.isLoading = false;
      if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Getting Values',
          detail: JSON.parse(error.response).ExceptionMessage,
          sticky: true
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Getting Values',
          detail: 'Unable to retrieve the settings, refresh the page and try again.'
        });
      }
    });
  }

}
