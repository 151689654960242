import { Component, OnInit } from '@angular/core';
import { ScheduledBucketGenSvc,
  ScheduledBucketDTO,
  WorkWorkOrder,
  WellnessWorkOrder,
  BigDayBucketSubrangeGenSvc,
  BigDayBucketSubrange,
  WellnessTaskCompletionStatus,
  WorkTaskCompletionStatus } from 'src/app/services_autogenerated/generated_services';
import * as moment from 'moment';

@Component({
  selector: 'app-crew-log',
  templateUrl: './crew-log.component.html',
  styleUrls: ['./crew-log.component.css']
})
export class CrewLogComponent implements OnInit {
  date: Date;
  scheduledBuckets: CrewView[] = [];
  isLoading: boolean;
  isLoadingMultiday: boolean;

  constructor(private scheduledBucketService: ScheduledBucketGenSvc,
    private bigDayBucketService: BigDayBucketSubrangeGenSvc) { }

  ngOnInit() {
    this.date = new Date();
    this.onDateChange();
  }

  onDateChange() {
    this.scheduledBuckets = [];
    this.isLoading = true;
    this.isLoadingMultiday = true;
    const startOfDate = moment(this.date).startOf('d').toDate();
    const endOfDate = moment(this.date).endOf('d').toDate();

    this.scheduledBucketService.getAllScheduledBucketsInDateRange(startOfDate, endOfDate).subscribe(response => {
      this.scheduledBuckets = this.scheduledBuckets.concat(response
                                                      .filter(sb => moment(sb.date).isSame(moment(this.date), 'day'))
                                                      .filter(sb => sb.scheduledBucketEmployees.length > 0
                                                                  || sb.scheduledBucketEquipment.length > 0
                                                                  || (sb.scheduledBucketWorkWorkOrders.length > 0 || sb.scheduledBucketWellnessWorkOrders.length > 0))
                                                      .map(sb => new CrewView(sb)));
      this.isLoading = false;
    });

    this.bigDayBucketService.getAllBigDayBucketSubrangeInDateRange(startOfDate, endOfDate).subscribe(response => {
      this.scheduledBuckets = this.scheduledBuckets.concat(response.map(bdbs => new CrewView(bdbs)));
      this.isLoadingMultiday = false;
    });
  }

  exportToGoogle(crew: CrewView) {
    if (crew && crew.workOrders && crew.workOrders.length > 0) {

      const addresses = crew.workOrders.filter(wo => !wo.allScheduledTasksComplete).map(c => c.workOrderAddress);
      let googleURL = 'https://www.google.com/maps/dir/Current+Location';
      addresses.forEach(address => {
        googleURL += this.formatAddress(`/${address}`);
      });

      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        window.open(googleURL, '_blank');
      } else {
        window.open(googleURL + '//@', '_blank');
      }
    } else {

    }
  }

  formatAddress(str) {
    return str.replaceAll(', ', ',').replaceAll(' ', '+');
  }
}


class WorkOrderView {
  constructor(workOrder: WellnessWorkOrder | WorkWorkOrder, bucketId: number) {
    this.quoteNumber = workOrder.quoteNumber;
    this.customerName = workOrder.customer.fullName;
    this.customerPhone = workOrder.customer.primaryPhone;
    this.customerLink = '/customerLandingPage/' + workOrder.customer.id;
    this.workOrderAddress = `${workOrder.address.street}, ${workOrder.address.zip}`;
    this.hidePrice = workOrder.hidePrice;

    if (workOrder instanceof WellnessWorkOrder) {
      this.workOrderLink = '/wellnessWorkOrderMaintenance/' + workOrder.id;
      const tasks = workOrder.workOrderWellnessTasks.filter(wowt => wowt.wellnessTask.currentBigDaySubrangeId ?
                                                                      wowt.wellnessTask.currentBigDaySubrangeId :
                                                                      wowt.wellnessTask.isGoBack ?
                                                                        wowt.wellnessTask.goBackBucketId === bucketId :
                                                                        wowt.wellnessTask.currentBucketId === bucketId
                                                                      );

      // Get price of tasks that are scheduled to the current bucket (check for scheduled bucket id first, then check big day bucket id)
      // Take into account the hide price flag, put 0 if price should be hidden
      this.priceSummary = tasks.map(wowt => workOrder.hidePrice ? 0 : wowt.wellnessTask.price)
                                                            .reduce((acc, curr) => acc + curr, 0);

      // Get hours of the tasks that are scheduled to the current bucket (check for scheduled bucket id first, then check big day bucket id)
      this.hoursSummary = tasks.map(wowt => wowt.wellnessTask.hours)
                                                            .reduce((acc, curr) => acc + curr, 0);

      this.hasGoBack = workOrder.workOrderWellnessTasks.some(wowt =>
                                                              wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Go_Back
                                                              || (wowt.wellnessTask.isGoBack && wowt.wellnessTask.goBackBucketId === bucketId)
                                                              || wowt.wellnessTask.goBackCompletionDate !== undefined
                                                            );

      this.allScheduledTasksComplete = tasks.every(t => t.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Completed
                                                        || t.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed);
    } else if (workOrder instanceof WorkWorkOrder) {
      this.workOrderLink = '/workWorkOrderMaintenance/' + workOrder.id;
      const tasks = workOrder.workOrderWorkTasks.filter(wowt => wowt.workTask.currentBigDaySubrangeId ?
                                                                wowt.workTask.currentBigDaySubrangeId :
                                                                wowt.workTask.isGoBack ?
                                                                  wowt.workTask.goBackBucketId === bucketId :
                                                                  wowt.workTask.currentBucketId === bucketId
                                                                );

      // Get price of tasks that are scheduled to the current bucket (check for scheduled bucket id first, then check big day bucket id)
      // Take into account the hide price flag, put 0 if price should be hidden
      this.priceSummary = tasks.map(wowt => workOrder.hidePrice ? 0 : wowt.workTask.price)
                                                            .reduce((acc, curr) => acc + curr, 0);

      // Get hours of the tasks that are scheduled to the current bucket (check for scheduled bucket id first, then check big day bucket id)
      this.hoursSummary = tasks.map(wowt => wowt.workTask.hours)
                                                            .reduce((acc, curr) => acc + curr, 0);

      this.hasGoBack = workOrder.workOrderWorkTasks.some(wowt =>
                                                          wowt.workTask.completionStatus === WorkTaskCompletionStatus.Go_Back
                                                          || (wowt.workTask.isGoBack && wowt.workTask.goBackBucketId === bucketId)
                                                          || wowt.workTask.goBackCompletionDate !== undefined
                                                        );

      this.allScheduledTasksComplete = tasks.every(t => t.workTask.completionStatus === WorkTaskCompletionStatus.Completed
        || t.workTask.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed);
    }
  }

  workOrderLink: string;
  customerLink: string;
  quoteNumber: string;
  customerName: string;
  customerPhone: string;
  workOrderAddress: string;
  priceSummary: number;
  hidePrice: boolean;
  hoursSummary: number;
  hasGoBack: boolean;
  allScheduledTasksComplete: boolean;
}

class CrewView {
  constructor(bucket: ScheduledBucketDTO | BigDayBucketSubrange) {
    if (bucket instanceof ScheduledBucketDTO) {
      this.employeeString = bucket.scheduledBucketEmployees.length > 0 ? bucket.scheduledBucketEmployees.map(sbe => sbe.employee.fullName).join(', ') : 'No employees';
      this.equipmentString = bucket.scheduledBucketEquipment.length > 0 ? bucket.scheduledBucketEquipment.map(sbe => sbe.equipment.number).join(', ') : 'No equipment';
      // Get both wellness WOs and work WOs
      this.workOrders = bucket.scheduledBucketWellnessWorkOrders.map(sbwwo => new WorkOrderView(sbwwo.wellnessWorkOrder, bucket.id))
                    .concat(bucket.scheduledBucketWorkWorkOrders.map(sbwwo => new WorkOrderView(sbwwo.workWorkOrder, bucket.id)));
      this.total = this.workOrders.map(wo => wo.priceSummary).reduce((acc, curr) => acc + curr, 0);
    } else if (bucket instanceof BigDayBucketSubrange) {
      this.employeeString = bucket.bigDayEmployees.length > 0 ? bucket.bigDayEmployees.map(bde => bde.employee.fullName).join(', ') : 'No employees';
      this.equipmentString = bucket.bigDayEquipment.length > 0 ? bucket.bigDayEquipment.map(bde => bde.equipment.number).join(', ') : 'No equipment';
      this.workOrders = bucket.bigDayBucket.wellnessWorkOrder ? [new WorkOrderView(bucket.bigDayBucket.wellnessWorkOrder, bucket.id)]
                                                              : [new WorkOrderView(bucket.bigDayBucket.workWorkOrder, bucket.id)];
    }

    this.total = this.workOrders.map(wo => wo.priceSummary).reduce((acc, curr) => acc + curr, 0);
    this.hours = this.workOrders.map(wo => wo.hoursSummary).reduce((acc, curr) => acc + curr, 0);
  }

  employeeString: string;
  equipmentString: string;
  workOrders: WorkOrderView[] = [];
  total: number;
  hours: number;
}
