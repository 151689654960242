import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { StateGenSvc,
  CustomerTypeGenSvc,
  CustomerType,
  State,
  CustomerContact,
  EmailAddress,
  Customer } from '../../../services_autogenerated/generated_services';
import { CustomerTypes } from 'src/app/models/enums/customerTypes';
import { AuthHelperService } from '../../../services/auth-helper.service';
import { LocationColorService, AddressSetResult } from 'src/app/services/location-color.service';
import { MessageService } from 'primeng/api';
import { NgForm, ControlContainer } from '@angular/forms';
import { CustomerContactComponent } from '../../customer-contact/customer-contact.component';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
})
export class ContactInfoComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() canEdit: boolean;
  @Input() canEditBilling: boolean;
  @Input() customer: Customer;
  @Input() hideAddContact: boolean;
  @Input() smallerHeaders: boolean;

  @Output() customerPreSave = new EventEmitter<boolean>();
  @Output() paymentInfoRequiredSet = new EventEmitter();

  customerTypes: CustomerType[];
  states: State[];
  selectedState: State;
  primaryContactIndex = 0;
  customerTypesEnum = CustomerTypes;
  isNew = false;
  isCustomer = false;
  claimNames: string[];
  token;

  @ViewChildren(CustomerContactComponent) customerContactComponents: QueryList<CustomerContactComponent>;
  @ViewChild('firstName') firstNameInput: ElementRef;
  @ViewChild('companyName') companyNameInput: ElementRef;

  textingOptions = [
    { label: 'When en route', value: false },
    { label: 'Opt-out', value: true }
  ];

  constructor(
    private customerTypeService: CustomerTypeGenSvc,
    private stateService: StateGenSvc,
    private authHelperService: AuthHelperService,
    private locationColorService: LocationColorService,
    private messageService: MessageService
  ) { }

  ngOnChanges() {
    this.matchPrimaryContactIndex();
    this.setFocus();
  }

  ngOnInit() {
    this.setupNewCustomer();
    this.stateService.getAll().subscribe(states => {
      this.states = states;
      if (this.isNew) {
        this.selectedState = states.find(state => state.name === 'Ohio');
        this.customer.address.state = this.selectedState;
      } else {
        this.selectedState = this.customer.address.state;
      }
    });
    this.customerTypeService.get()
      .subscribe(customerTypes => {
        this.customerTypes = customerTypes;
      });
    if (this.isNew) {
      this.customer.customerTypeId = CustomerTypes.Residential;
    }
    this.token = this.authHelperService.getDecodedAccessToken();
    this.isCustomer = !this.token || this.token.userType === 'customer'; // Hides certain things from the customer
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  typeChange() {
    if (this.isNew && (this.customer.customerTypeId === this.customerTypesEnum.Commercial || this.customer.customerTypeId === this.customerTypesEnum.MunicipalityGovernment)) {
      this.customer.paymentInfoRequired = false;
    } else if (this.isNew && !(this.customer.customerTypeId === this.customerTypesEnum.Commercial || this.customer.customerTypeId === this.customerTypesEnum.MunicipalityGovernment)) {
      this.customer.paymentInfoRequired = true;
    }
    setTimeout(() => {
      this.setFocus();
    }, 1);
  }

  emailChanged(email: EmailAddress) {
    email.email = email.email.trim();
  }

  setFocus() {
    if (this.firstNameInput) {
      this.firstNameInput.nativeElement.focus();
    } else if (this.companyNameInput) {
      this.companyNameInput.nativeElement.focus();
    }
  }

  setupNewCustomer() {
    if (!this.customer || !this.customer.id) {
      this.isNew = true;
      const newContact = new CustomerContact({
        emailAddress: new EmailAddress,
        primary: true,
        canLogin: true,
        active: true,
        receiveInvoicingEmails: true,
        receiveNonInvoicingEmails: true,
      });

      this.customer.customerContacts = [newContact];
    } else {
      if (this.customer.customerContacts.length > 0) {
        this.customer.customerContacts = this.customer.customerContacts.filter(x => x.active);
      } else {
        const newContact = new CustomerContact({
          emailAddress: new EmailAddress,
          primary: true,
          canLogin: true,
          active: true,
          receiveInvoicingEmails: true,
          receiveNonInvoicingEmails: true,
        });

        this.customer.customerContacts = [newContact];
      }
    }
  }

  addContact(primary: boolean) {
    const customerContact = new CustomerContact({
      emailAddress: new EmailAddress,
      active: true,
      receiveInvoicingEmails: true,
      receiveNonInvoicingEmails: true,
    });
    if (primary) {
      customerContact.primary = true;
      customerContact.canLogin = true;
      this.customer.customerContacts.splice(this.primaryContactIndex, 0, customerContact);
    } else {
      this.customer.customerContacts.push(customerContact);
    }
  }

  deleteContact(contact: CustomerContact) {
    const deleteIndex = this.customer.customerContacts.indexOf(contact);
    this.customer.customerContacts.splice(deleteIndex, 1);
    if (deleteIndex === this.primaryContactIndex) {
      this.addContact(true);
    }
  }

  paymentInfoRequiredChange() {
    if (this.customer.paymentInfoRequired) {
      this.paymentInfoRequiredSet.emit();
    }
  }

  onStateSelect(state: State) {
    this.customer.address.stateId = state.id;
    this.customer.address.state = state;
  }

  preSave() {
    // Cannot add additional contacts for residential and cannot disable login
    if (this.customer.customerTypeId === CustomerTypes.Residential && this.customer.customerContacts.length > 1) {
      const primaryContact = this.customer.customerContacts[this.primaryContactIndex];
      primaryContact.canLogin = true;
      // this.customer.customerContacts = [primaryContact];
    }

    // Set up color address thing
    this.locationColorService.geocodeAddress(this.customer.address).subscribe(res => {
      const result = this.locationColorService.setAddressWithGeocodeResponse(this.customer.address, res, this.isCustomer, true);
      // If result is successful then continue with save
      // If result was failed then try again with the least specific address possible (should be zip code, country)
        // If still not found, use will be asked if setting color to black is okay and will save if Yes
      // Otherwise the user cancelled
      if (result === AddressSetResult.Successful) {
        this.customerPreSave.emit(true);
      } else if (result === AddressSetResult.Failed) {
        this.locationColorService.geocodeAddressLeastSpecific(this.customer.address).subscribe(res2 => {
          const secondResult = this.locationColorService.setAddressWithGeocodeResponse(this.customer.address, res2, this.isCustomer);
          if (secondResult !== AddressSetResult.Cancelled) {
            this.customerPreSave.emit(true);
          } else {
            this.customerPreSave.emit(false);
          }
        });
      } else if (result === AddressSetResult.Cancelled) {
        this.customerPreSave.emit(false);
      } else {
        this.customerPreSave.emit(false);
      }
    }, error => {
      this.customerPreSave.emit(true);
      // Continue
      if (!this.isCustomer) {
        // Customers don't need to see this error
        this.messageService.add({
          severity: 'error',
          summary: 'Geocode Response Failed',
          detail: 'Could not get the Geocode Response from the MapQuest provider. The color will be set to black.'
        });
      }
    });
  }

  onPhoneChange() {
    if (!this.isPhoneNumberEmpty() &&
      !this.customer.customerContacts[this.primaryContactIndex].textingOptOut) {
        this.customer.customerContacts[this.primaryContactIndex].textingOptOut = false;
    } else if (this.isPhoneNumberEmpty()) {
        this.customer.customerContacts[this.primaryContactIndex].textingOptOut = null;
    }
  }

  isPhoneNumberEmpty() {
    return !this.customer.customerContacts[this.primaryContactIndex].phoneNumber ||
      this.customer.customerContacts[this.primaryContactIndex].phoneNumber === '(___) ___-____';
  }

  matchPrimaryContactIndex() {
    if (this.customer.customerContacts) {
      const primaryIndex = this.customer.customerContacts.findIndex(cc => cc.primary && cc.active);
      if (primaryIndex !== this.primaryContactIndex) {
        const primaryContact = this.customer.customerContacts.splice(primaryIndex, 1)[0];
        this.customer.customerContacts.splice(this.primaryContactIndex, 0, primaryContact);
      }
    }
  }

}
