<div class="qrf-container" style="font-size: 13px;">
  <div>
    <h3 class="center">Quote Request Form</h3>
  </div>

  <form id="quoteForm" #quoteForm="ngForm">
    <span [hidden]="!showMainContent">
      <div *ngIf="quoteRequest && quoteRequest.customer && quoteRequest.customer.customerComments && !isCustomer" style="height: 51px">
        <button type="button" class="buttonStyle" pButton label="Company Wide Notes" (click)="op.toggle($event)" style="margin-right: 10px; float: left;"></button>
        <p-overlayPanel #op>
          <app-customer-comment-view-only [customer]="quoteRequest.customer"></app-customer-comment-view-only>
        </p-overlayPanel>
        <button
          pButton
          type="button"
          class="ui-button-raised ui-button-primary buttonStyle"
          (click)="onMyWay()"
          *ngIf="isEditMode() && canSendOnMyWay"
          style="float: left;"
          label="On My Way">
        </button>
      </div>

      <div *ngIf="isCustomerEditable()">
        <app-customer-dropdown
          [disabled]="!hasQuotePermissions || !isNew || !editable"
          [existingSelectionId]="quoteRequest?.customer?.id"
          [required]="true"
          (customerSelected)="setCustomer($event)"
        ></app-customer-dropdown>
      </div>
      <div *ngIf="isCustomerEditable()" class="center">
        - OR -
      </div>
      <div *ngIf="allowNewCustomer() && isCustomerEditable()">
        <ng-container>
          <button
            type="button"
            class="btn btn-success"
            [disabled]="selectedCustomer || isDisabledMode()"
            style="width: 100%;"
            (click)="addNewCustomer()">Add New Customer
          </button>
        </ng-container>
      </div>
      <div>
        <!--
          Only the anonymous RFQ pathway can edit customer info here
          Other pathways can only ADD a new customer, cannot edit info beyond that
        -->
        <app-contact-info
          *ngIf="quoteRequest && quoteRequest.customer"
          [customer]="quoteRequest.customer"
          [canEdit]="isAnon"
          [smallerHeaders]="true"
          [canEditBilling]="false">
        </app-contact-info>
      </div>
      <div class="p-grid" *ngIf="!isCustomer && quoteRequest">
        <div class="p-col-12">
          <div class="rfq-header">
            Is this Quote Request urgent?
          </div>
          <p-radioButton
            name="isUrgent"
            [value]="true"
            [label]="'Yes'"
            [(ngModel)]="quoteRequest.isUrgent"
            [disabled]="isDisabledMode()"
            (ngModelChange)="addressSameAsCustomerToggle()"
            required
            *ngIf="quoteRequest">
          </p-radioButton>
        &nbsp;
          <p-radioButton
            name="isUrgent"
            [value]="false"
            [label]="'No'"
            [(ngModel)]="quoteRequest.isUrgent"
            [disabled]="isDisabledMode()"
            (ngModelChange)="addressSameAsCustomerToggle()"
            required
            *ngIf="quoteRequest">
          </p-radioButton>
        </div>
      </div>
      <div>
        <hr [style.margin-top]="quoteRequest?.customer?.customerTypeId != customerTypesEnum.Residential ? '60px' : null"/>
        <div class="p-grid">
          <div class="p-col-12" *ngIf="quoteRequest && quoteRequest.customer && quoteRequest.customer.address">
            <div class="rfq-header">
              Is the mailing address the same location as the tree work you are needing?
            </div>
            <div *ngIf="quoteRequest" class="less-top-no-bottom-padding">
              <p-radioButton
                name="addressIsSameAsCustomer"
                [value]="true"
                [label]="'Yes'"
                [(ngModel)]="quoteRequest.addressSameAsCustomer"
                [disabled]="isDisabledMode()"
                (ngModelChange)="addressSameAsCustomerToggle()"
                required
                *ngIf="quoteRequest">
              </p-radioButton>
            </div>
            <div *ngIf="quoteRequest" class="less-top-no-bottom-padding">
              <p-radioButton
                name="addressIsNotSameAsCustomer"
                [value]="false"
                [label]="'No'"
                [(ngModel)]="quoteRequest.addressSameAsCustomer"
                [disabled]="isDisabledMode()"
                (ngModelChange)="addressSameAsCustomerToggle()"
                required
                *ngIf="quoteRequest">
              </p-radioButton>
            </div>
          </div>
          <div class="p-grid ui-fluid" *ngIf="quoteRequest && !quoteRequest.addressSameAsCustomer">
            <div class="p-col-12 p-md-6">
              <label class="rfq-header">Service Address Line 1</label>
              <input
                type="text"
                name="serviceAddress1"
                pInputText
                class="inputTextBox"
                [(ngModel)]="quoteRequest.address.street"
                required
                [disabled]="isServiceAddressDisabled()"
                *ngIf="quoteRequest">
            </div>
            <div class="p-col-12 p-md-6">
              <label class="rfq-header">Service Address Line 2</label>
              <input
                type="text"
                name="serviceAddress2"
                pInputText
                class="inputTextBox"
                [(ngModel)]="quoteRequest.address.apartment"
                [disabled]="isServiceAddressDisabled()"
                *ngIf="quoteRequest">
            </div>
            <div class="p-col-12 p-md-6">
              <label class="rfq-header">City</label>
              <input
                type="text"
                name="serviceCity"
                pInputText
                class="inputTextBox"
                [(ngModel)]="quoteRequest.address.city"
                required
                [disabled]="isServiceAddressDisabled()"
                *ngIf="quoteRequest">
            </div>
            <div class="p-col-12 p-md-6">
              <label class="rfq-header">State</label>
              <ng-select
                class="custom"
                required
                [items]="states"
                placeholder="Select a State"
                (ngModelChange)="onStateSelect($event)"
                [selectOnTab]="true"
                name="serviceState"
                bindLabel="name"
                [(ngModel)]="selectedState"
                [disabled]="isServiceAddressDisabled()"
                *ngIf="quoteRequest"
              ></ng-select>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="rfq-header">Zip/Postal Code</label>
              <input
                type="text"
                name="serviceZip"
                pInputText
                class="inputTextBox"
                [(ngModel)]="quoteRequest.address.zip"
                (ngModelChange)="quoteOptionChanged()"
                required
                [disabled]="isServiceAddressDisabled()"
                *ngIf="quoteRequest">
            </div>
          </div>
        </div>
      </div>
      <hr *ngIf="quoteRequest && quoteRequest.customer && quoteRequest.customer.address" />
      <div class="p-grid">
        <div class="p-col-12">
          <app-services-section *ngIf="quoteRequest" [isDisabled]="!editable" [quoteRequest]="quoteRequest"></app-services-section>
        </div>
      </div>

      <div class="p-grid" style="margin-top: 5px;" *ngIf="quoteRequest">
        <div class="p-col-12">
          <div class="rfq-header">Please Describe Need:</div>
          <div class="rfq-subheader">Please be as detailed as possible (Ex: amount/size/location of the tree(s), etc.)</div>
          <textarea
            class="width100"
            name="description"
            pInputTextarea
            [(ngModel)]="quoteRequest.description"
            [disabled]="isDisabledMode()"
            [rows]="5"
            required
            *ngIf="quoteRequest"></textarea>
        </div>
      </div>

      <hr />
      <div *ngIf="quoteRequest">
        <div class="rfq-header" style="margin-bottom: 6px;">What brought you to our site today?</div>
        <div class="p-grid">
          <div class="p-col-12 less-top-no-bottom-padding" *ngFor="let broughtBy of broughtBys">
            <p-radioButton
              name="broughtBy"
              [value]="broughtBy.id"
              [label]="broughtBy.name"
              [(ngModel)]="quoteRequest.broughtBy.id"
              [disabled]="isDisabledMode()"
              required
              *ngIf="quoteRequest"></p-radioButton>
          </div>
          <div class="p-col-12" *ngIf="quoteRequest && quoteRequest.broughtBy.id == 9">
            <div class="rfq-header">If you selected "Other" in previous question, please explain.</div>
            <textarea
              class="width100"
              name="reason"
              pInputTextarea
              [(ngModel)]="quoteRequest.broughtByOtherNote"
              [disabled]="isDisabledMode()"
              [rows]="1"
              required
              *ngIf="quoteRequest"></textarea>
          </div>
        </div>
      </div>
      <hr />
      
      <app-quote-option-section 
        *ngIf="quoteRequest" 
        [isDisabled]="!editable" 
        [quoteRequest]="quoteRequest" 
        (quoteOptionChanged)="quoteOptionChanged()">
      </app-quote-option-section>

      <hr *ngIf="quoteRequest && quoteRequest.quoteRequestType && !showNextButton && showMainContent" />
      <div class="p-grid" [style.display]="quoteRequest && quoteRequest.quoteRequestType && !showNextButton && showMainContent ? 'block' : 'none'" *ngIf="!showVirtualSubmit">
        <div class="p-col-12">
          <div class="rfq-header">Photo/Document Upload</div>
          <div class="rfq-subheader">You can optionally attach photos or documents.</div>
          <app-blob-manager [containerName]="'quote-request'" [parentRecordId]="quoteRequest.id" [uploadLabel]="'Document/Photo'" [viewOnly]="!editable"></app-blob-manager>
        </div>
      </div>
    </span>

    <span *ngIf="!showMainContent">
      <div class="p-col-12">
        <div class="rfq-header">
          Provide Your Video!
        </div>
        <!-- Review the video below -->
      </div>
      <!-- Embedded video -->
      <div class="p-col-12">
        <div class="rfq-header">
          Video Requirements:
        </div>
        <ul>
          <li>Show a full shot of the entire tree that is in need of tree work. Be sure to show the whole height and width.</li>
          <li>Show if there are any power or communication lines within the area.</li>
          <li>Show all structure (buildings, roads, etc.) within the area of the tree.</li>
          <li>Please narrate the video with any specifics you can provide.</li>
          <li>Show the driveway and road access to the property.</li>
          <li>Show and explain any known underground utilities (septic tanks, water lines, etc.).</li>
        </ul>
      </div>
      <div class="p-col-12">
        <app-blob-manager 
          [containerName]="'quote-request'" 
          [parentRecordId]="quoteRequest.id" 
          [viewOnly]="!editable"
          [accept]="'video/*'"
          [required]="true"
          [buttonText]="'UPLOAD / RECORD VIDEO'"
          [angularUpload]="true"
          [anonymousPossible]="true"
          (doneUploadingEvent)="doneSaving()"
        ></app-blob-manager>
      </div>
    </span>
    <div *ngIf="isAnon && quoteRequest && !captchaSuccess" style="margin-top: 2em;">
      <re-captcha #captcha name="recaptchaReactive" (resolved)="onRecaptchaResolved($event)"
            siteKey="6LfZXrsZAAAAAPf99RA9WdiaA_h2Elx6Q7kGaf3M"></re-captcha>
    </div>

    <div class="p-grid" *ngIf="quoteRequest">
      <div *ngIf="errorMessage"
          class="p-col-12 columnPadding">
          <div class="alert alert-danger" style="margin-top: 8px;">
            {{errorMessage}}
        </div>
      </div>
      <div class="p-col-12 columnPadding">    
        <button
          pButton
          type="submit"
          class="ui-button-raised ui-button-success buttonStyle"
          (click)="save(quoteForm)"
          [disabled]="isDisabledMode() || saving || quoteForm.invalid"
          *ngIf="showNextButton"
          [label]="'Next'">
        </button>
        <button
          pButton
          type="submit"
          class="ui-button-raised ui-button-success buttonStyle"
          (click)="doneSaving()"
          [disabled]="disableVirtualSubmit()"
          *ngIf="showVirtualSubmit"
          [label]="'Submit'">
        </button>
        <button
          pButton
          type="submit"
          class="ui-button-raised ui-button-success buttonStyle"
          (click)="save(quoteForm)"
          [disabled]="isDisabledMode() || saving || quoteForm.invalid"
          *ngIf="showScheduleButton"
          [label]="'Submit and Schedule'">
        </button>
        <button
          pButton
          type="submit"
          class="ui-button-raised ui-button-success buttonStyle"
          (click)="save(quoteForm)"
          [disabled]="isDisabledMode() || saving || quoteForm.invalid"
          *ngIf="!showScheduleButton && !showNextButton && !showVirtualSubmit"
          [label]="isEditMode() ? 'Save' : 'Submit'">
        </button>           
        <button
          pButton
          type="button"
          class="ui-button-raised ui-button-warning buttonStyle"
          *ngIf="isEditMode() && !showVirtualSubmit"
          (click)="cancel()"
          [label]="editable ? 'Cancel' : 'Back'">
        </button>
        <button
          pButton
          type="button"
          class="ui-button-raised ui-button-danger buttonStyle"
          (click)="delete()"
          *ngIf="isEditMode() && !showVirtualSubmit"
          [disabled]="disableDelete || isDisabledMode()"
          label="Delete">
        </button>
        <button
          pButton
          type="button"
          class="ui-button-raised ui-button-primary buttonStyle"
          (click)="turnToQuote(quoteForm)"
          [disabled]="quoteRequest.quoteRequestType === 'Firmtime' && quoteRequest.paymentStatus !== 'Paid'"
          *ngIf="isEditMode() && hasQuotePermissions && canTransform"
          label="Transform to Quote">
        </button>
      </div>
    </div>
  </form>
</div>



<!-- Add New Customer dialog -->
<!-- Only dialogs inside class scrollableDialog get the CSS for scrolling. Only use scrollableDialog if the dialog actually has enough content that
it needs to scroll because it looks weird otherwise. -->
<div class="scrollableDialog" *ngIf="displayCustomerDialog">
  <p-dialog header="Customer Info" [(visible)]="displayCustomerDialog" [modal]="true" [responsive]="true" [resizable]="false"
      [baseZIndex]="10000" [maximizable]="true" [style]="{'width': '800px'}" [focusOnShow]="false"
      (onHide)="closedCustomerDialog()" [closable]="false" [blockScroll]="true">
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" tabindex="0" role="button" (click)="closedCustomerDialog()" (keydown.enter)="closedCustomerDialog()" (mousedown)="closedCustomerDialog()">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-customer-maintenance [isDialog]="true" (timeToClose)="closedCustomerDialog($event)" [customer]="quoteRequest.customer"></app-customer-maintenance>
  </p-dialog>
</div>
