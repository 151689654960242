// This is not comprehensive; I just pulled in what I wanted
export class GeocodingResponse {
    info: {
        statusCode: number
    };
    results: Results[];
}

export class Results {
    locations: Locations[];
}

export class Locations {
    geocodeQuality: string;
    geocodeQualityCode: string;
    latLng: {
        lng: number,
        lat: number
    };
    get order(): number {
        if (this.geocodeQuality === 'POINT')
            return 0;
        else if (this.geocodeQuality === 'ADDRESS')
            return 1;
        else if (this.geocodeQuality === 'ZIP')
            return 2;
        else if (this.geocodeQuality === 'ZIP_EXTENDED')
            return 3;
        else if (this.geocodeQuality === 'STREET')
            return 4;
        else if (this.geocodeQuality === 'CITY')
            return 5;
        else if (this.geocodeQuality === 'NEIGHBORHOOD')
            return 6;
        else if (this.geocodeQuality === 'INTERSECTION')
            return 7;
        else
            return 8;
    }
}

export class ColorResponse {
    constructor(geoColor, textColor) {
        this.geoColor = geoColor;
        this.textColor = textColor;
    }

    geoColor: string;
    textColor: string;
}