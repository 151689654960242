import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as i0 from "@angular/core";
var MaskService = /** @class */ (function () {
    function MaskService() {
    }
    // this one gets used like = MaskService.currencyMask; (no parens)
    MaskService.prototype.currencyMask = function (rawValue) {
        var numberMask = createNumberMask({
            prefix: '$',
            includeThousandsSeparator: true,
            allowDecimal: true,
            requireDecimal: true,
            allowLeadingZeroes: true
        });
        var mask = numberMask(rawValue);
        var decimalsRegex = /\.([0-9]{1,2})/;
        var result = decimalsRegex.exec(rawValue);
        // In case there is only one decimal
        if (result && result[1].length < 2) {
            mask.push('0');
        }
        else if (!result) {
            mask.push('0');
            mask.push('0');
        }
        return mask;
    };
    MaskService.prototype.currencyMaskNoDecimal = function (rawValue) {
        var numberMask = createNumberMask({
            prefix: '$',
            includeThousandsSeparator: true,
            allowDecimal: false,
            requireDecimal: false,
            allowLeadingZeroes: true
        });
        var mask = numberMask(rawValue);
        return mask;
    };
    MaskService.prototype.ccExpirationMask = function () {
        return [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
    };
    MaskService.ngInjectableDef = i0.defineInjectable({ factory: function MaskService_Factory() { return new MaskService(); }, token: MaskService, providedIn: "root" });
    return MaskService;
}());
export { MaskService };
