<div class="headerText">Accounts Receivable</div>
<br/>
As Of: 
<p-calendar [(ngModel)]="advancedFilters.asOfDate" [showIcon]="true" (ngModelChange)="dateChanged()" [disabled]="isLoading"></p-calendar>
<br/><br/>
<p-table 
  #table
  class="no-pad-table-header"
  [columns]="cols" 
  [value]="allWorkOrders" 
  [paginator]="true" 
  [rows]="rows" 
  [responsive]="true"
  [lazy]="false"
  [loading]="isLoading"
  [dataKey]="expandField"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <button type="button" pButton iconPos="left" label="Export" (click)="exportToCSV()" style="float:left" [disabled]="isLoading"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [style.width]="col.width">
        <span>{{col.header}}</span>    
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
    <tr class="ui-widget-header" *ngIf="rowGroupMetadata[rowData[expandField]].index === rowIndex">
      <td>
        <a href="#" [pRowToggler]="rowData">
          <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
          <span>{{rowData[expandField]}}</span>
        </a>
      </td>
      <td [pRowToggler]="rowData">
        <span class="ui-column-title">{{columns[1].header}}</span>
        <span>{{rowData.fullName}}</span>
      </td>
      <td [pRowToggler]="rowData"><span class="ui-column-title">{{columns[2].header}}</span>{{rowData.serviceType}}</td>
      <td [pRowToggler]="rowData" class="fine-print">Expand to see details</td>
      <td [pRowToggler]="rowData" class="fine-print">Expand to see details</td>
      <td [pRowToggler]="rowData" class="fine-print">Expand to see details</td>
      <td [pRowToggler]="rowData">
        <span class="ui-column-title">Total</span>
        <span>{{rowGroupMetadata[rowData[expandField]].amount | currency: 'USD'}}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-workOrder let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td>
        <span class="ui-column-title">{{columns[0].header}}</span>
        <span class="regular-link" (click)="navigateToWorkOrder(workOrder)">
          {{workOrder.number}}
        </span>
      </td>
      <td>
        <span class="ui-column-title">{{columns[1].header}}</span>
        <span class="regular-link" (click)="navigateToCustomer(workOrder)">{{workOrder.fullName}}</span>
      </td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{workOrder.serviceType}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{workOrder.dateCompleted | date: 'shortDate'}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{workOrder.paidDate | date: 'shortDate'}}</td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{workOrder.paymentStatus}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>{{workOrder.amountDue | currency: 'USD'}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" style="text-align: right;">
    <span *ngIf="!isLoading">Total: {{total | currency: 'USD'}}</span>
  </ng-template>
</p-table>
