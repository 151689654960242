import { Component, OnInit, Input } from '@angular/core';
import { CustomerGenSvc, AllWorkOrders, Customer, AllWorkOrdersWithBilling, WellnessWorkOrder, WorkWorkOrder, WorkOrderBilling, WellnessWorkOrderCompletionStatus, WorkWorkOrderCompletionStatus, QuoteRequest} from '../../../services_autogenerated/generated_services';
import { WorkOrderHelperService } from '../../../services/work-order-helper.service';

@Component({
  selector: 'app-customer-main-tab',
  templateUrl: './customer-main-tab.component.html',
  styleUrls: ['./customer-main-tab.component.css']
})
export class CustomerMainTabComponent implements OnInit {
  @Input() customer: Customer;

  unfinalizedQuoteRequests: QuoteRequest[];
  currentInfo: AllWorkOrders;
  historyInfo: AllWorkOrdersWithBilling;
  WorkWorkOrderCompletionStatus = WorkWorkOrderCompletionStatus;
  WellnessWorkOrderCompletionStatus = WellnessWorkOrderCompletionStatus;

  constructor(private customerService: CustomerGenSvc,
    private woHelper: WorkOrderHelperService) { }

  ngOnInit() {
    this.customerService.getUnfinalizedQuoteRequests(this.customer.id).subscribe(requests => {
      this.unfinalizedQuoteRequests = requests;
    });

    this.customerService.getCurrentInfo(this.customer.id).subscribe(current => {
      current.wellnessWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      current.workWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });
      this.currentInfo = current;
    });

    this.customerService.getHistoryInfo(this.customer.id).subscribe(history => {
      history.wellnessWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      history.workWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      this.historyInfo = history;
    });
  }

  getWorkOrderBillingByWorkOrder(workOrder: WellnessWorkOrder | WorkWorkOrder): WorkOrderBilling {
    let woBilling = null;
    if (workOrder.constructor == WellnessWorkOrder) {
      woBilling = this.historyInfo.workOrderBilling.find(billing => +billing.workOrderId == workOrder.id && billing.workOrderType == 'Wellness');
      // TODO: type enum
    } else if (workOrder.constructor == WorkWorkOrder) {
      woBilling = this.historyInfo.workOrderBilling.find(billing => +billing.workOrderId == workOrder.id && billing.workOrderType == 'Work');
    }
    return woBilling;
  }
}
