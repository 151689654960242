import { Component, Inject, ViewChildren, QueryList, ViewChild } from '@angular/core';
import {
  QuoteWorkOrder,
  WellnessTask,
  WorkTask,
  Skill,
  QuoteGenSvc,
  Customer,
  Address,
  WorkOrderWorkTask,
  WorkOrderWellnessTask,
  WorkOrderWorkTaskGenSvc,
  WorkOrderWellnessTaskGenSvc,
  WorkOrderPriceAdjustmentGenSvc,
  EquipmentType,
  EmailAddress,
  CustomerContact,
  QuoteWorkOrderCompletionStatus,
  WellnessTaskCompletionStatus,
  WorkTaskCompletionStatus,
  NotificationsGenSvc,
  Employee,
  EmployeeGenSvc,
  PaymentDueOption,
  PaymentDueOptionGenSvc,
  NeighborPermission,
  WorkTaskTreeType,
  WellnessTaskTreeType,
  MessageToCustomerTemplate,
  MessageToCustomerTemplateGenSvc,
  QuoteWorkOrderManualPriorityShortcutType,
  StripeGenSvc,
  CustomerNoteGenSvc,
  CustomerComment,
  Dto
} from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocationColorService } from 'src/app/services/location-color.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { BaseWorkOrderMaintenanceComponent } from '../base-work-order-maintenance.ts/base-work-order-maintenance.component';
import { NgForm, FormGroup } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';
import { WorkWorkOrderMaintenanceService } from 'src/app/services/work-work-order-maintenance.service';
import { WellnessWorkOrderMaintenanceService } from 'src/app/services/wellness-work-order-maintenance.service';
import { WorkTaskMaintenanceComponent } from '../work-task-maintenance/work-task-maintenance.component';
import { WellnessTaskMaintenanceComponent } from '../wellness-task-maintenance/wellness-task-maintenance.component';
import { Base64 } from 'src/app/models/base64';
import { MaskService } from 'src/app/services/mask.service';
import * as moment from 'moment';

@Component({
  selector: 'app-quote-work-order-maintenance',
  templateUrl: './quote-work-order-maintenance.component.html',
  styleUrls: ['./quote-work-order-maintenance.component.css'],
  providers: [PhoneNumberPipe]
})
export class QuoteWorkOrderMaintenanceComponent extends BaseWorkOrderMaintenanceComponent {
  workOrder: QuoteWorkOrder;
  selectedWellnessTask: WellnessTask;
  selectedWorkTask: WorkTask;

  selectedWellnessTasks: number[];
  selectedWorkTasks: number[];

  canAddNewTask: boolean;
  sendQuote = false;

  employees: Employee[];
  paymentDueOptions: PaymentDueOption[];
  shouldNavigateToRMSchedule: boolean;

  disableReset: boolean;
  showReset: boolean;
  showReactivate: boolean;

  selectedTemplate: MessageToCustomerTemplate;
  templates: MessageToCustomerTemplate[] = [];

  currencyMask: any;

  displayApproveDialog: boolean;
  approvalComment: string;
  isApproving: boolean;

  @ViewChildren(BlobManagerComponent) blobManagerComponents: QueryList<BlobManagerComponent>;
  @ViewChild(WorkTaskMaintenanceComponent) workTaskMaintenance: WorkTaskMaintenanceComponent;
  @ViewChild(WellnessTaskMaintenanceComponent) wellnessTaskMaintenance: WellnessTaskMaintenanceComponent;

  constructor(
    private quoteService: QuoteGenSvc,
    private emailService: NotificationsGenSvc,
    authHelper: AuthHelperService,
    private employeeService: EmployeeGenSvc,
    private paymentDueService: PaymentDueOptionGenSvc,
    @Inject(ActivatedRoute) route: ActivatedRoute,
    @Inject(Router) router: Router,
    @Inject(MessageService) messageService: MessageService,
    @Inject(LocationColorService) locationColorService: LocationColorService,
    @Inject(WorkOrderHelperService) helper: WorkOrderHelperService,
    @Inject(WorkOrderWorkTaskGenSvc) workOrderWorkTaskGenSvc: WorkOrderWorkTaskGenSvc,
    @Inject(WorkOrderWellnessTaskGenSvc) workOrderWellnessTaskGenSvc: WorkOrderWellnessTaskGenSvc,
    @Inject(WorkOrderPriceAdjustmentGenSvc) wopaService: WorkOrderPriceAdjustmentGenSvc,
    @Inject(WorkWorkOrderMaintenanceService) workWorkOrderMaintenanceService: WorkWorkOrderMaintenanceService,
    @Inject(WellnessWorkOrderMaintenanceService) wellnessWorkOrderMaintenanceService: WellnessWorkOrderMaintenanceService,
    @Inject(MaskService) maskService: MaskService,
    @Inject(MessageToCustomerTemplateGenSvc) messageTemplateService: MessageToCustomerTemplateGenSvc,
    @Inject(StripeGenSvc) private customStripeService: StripeGenSvc,
    @Inject(CustomerNoteGenSvc) private customerNoteService: CustomerNoteGenSvc
  ) {
    super(route, router, messageService, locationColorService, null, helper, workOrderWorkTaskGenSvc, workOrderWellnessTaskGenSvc,
      wopaService, authHelper, workWorkOrderMaintenanceService, wellnessWorkOrderMaintenanceService);

      this.currencyMask = maskService.currencyMaskNoDecimal;

      messageTemplateService.getActive().subscribe(res => this.templates = res);
  }

  setSelectedTasks() {
    this.selectedWellnessTasks = this.workOrder.workOrderWellnessTasks
      .filter(wowt => wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Work_Orders_Created
        || wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Credit_Card_Needed)
      .map(wowt => wowt.id);
    this.selectedWorkTasks = this.workOrder.workOrderWorkTasks
      .filter(wowt => wowt.workTask.completionStatus === WorkTaskCompletionStatus.Work_Orders_Created
        || wowt.workTask.completionStatus === WorkTaskCompletionStatus.Credit_Card_Needed)
      .map(wowt => wowt.id);
  }

  getWO(id: number) {
    if (id) {
      this.quoteService.get(id).subscribe(quote => {
        if (quote.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created || quote.completionStatus === QuoteWorkOrderCompletionStatus.Credit_Card_Needed) {
          this.showReset = this.token.claimNames.includes('Undo Customer Approval');
        }
        this.workOrder = quote;
        this.requiredSkills = this.getSkillsFromTasks();
        this.requiredEquipment = this.getEquipmentFromTasks();
        this.isEditable = this.isEditable && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
          && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
          && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Expired
          && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Credit_Card_Needed;
        this.canSave = this.canSave && this.isEditable;
        this.canSign = this.canSign && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
          && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
          && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Credit_Card_Needed;

        this.setSelectedTasks();
        this.setCanComplete();
        this.setCanAddNewTask();

        this.setOptions();
        this.isLoading = false;

        this.showReactivate = quote.completionStatus === QuoteWorkOrderCompletionStatus.Quote_Expired;
      }, error => {
        this.errorMessage = 'Could not load quote. Please refresh and try again. ';
        this.isLoading = false;
      });
    } else {
      const q = new QuoteWorkOrder();
      q.customer = new Customer();
      q.customer.customerContacts = [new CustomerContact({
        active: true,
        primary: true,
        firstName: '',
        lastName: '',
        phoneNumber: null,
        receiveInvoicingEmails: true,
        receiveNonInvoicingEmails: true,
      })];
      q.customer.customerContacts[0].emailAddress = new EmailAddress({
        email: ''
      });
      q.customer.address = new Address();
      q.customer.active = true;
      q.customer.paymentInfoRequired = true;
      q.address = new Address();
      q.createdDate = new Date();
      q.activationDate = new Date();
      q.completionStatus = QuoteWorkOrderCompletionStatus.New;
      q.salesTaxPercentage = 0;
      q.workOrderWorkTasks = [];
      q.workOrderWellnessTasks = [];
      q.workOrderPriceAdjustments = [];
      q.salesTaxPercentage = 7.5; // default
      q.addressSameAsCustomer = true;
      q.hidePrice = false;
      q.isMultiday = false;
      q.neighborPermissionRequired = false;
      q.hasRentalExpense = false;

      this.workOrder = q;
      this.isEditable = this.isEditable && true;
      this.onTaskChange();
      this.setCanComplete();
      this.setCanAddNewTask();

      this.setOptions();
      this.isLoading = false;
    }
  }

  setOptions() {
    this.employeeService.getRegionalManagers().subscribe(rms => {
      this.employees = rms;
      const token = this.authHelper.getDecodedAccessToken();
      if (token.roles.includes('Regional Manager') && !this.workOrder.id && !this.workOrder.representative) {
        this.workOrder.representative = this.employees.find(e => e.id === token.employeeId);
      }
    });

    this.paymentDueService.getAll().subscribe(options => {
      this.paymentDueOptions = options;

      if (!this.workOrder.paymentDueOption) {
        this.workOrder.paymentDueOption = this.paymentDueOptions.find(option => option.paymentDueName === 'Payment Due Upon Completion');
      }
    });
  }

  neighborPermissionChange() {
    if (this.workOrder.neighborPermissionRequired && !this.workOrder.neighborPermission) {
      this.workOrder.neighborPermission = new NeighborPermission();
    } else if (!this.workOrder.neighborPermissionRequired) {
      this.workOrder.neighborPermission = undefined;
    }
  }

  reactivate() {
    this.saving = true;
    this.isLoading = true;
    this.quoteService.reactivateQuote(this.workOrder.id).subscribe(quote => {
      this.getWO(quote.id);
      this.setPermissions();
      this.saving = false;
      this.isLoading = false;
    }, err => {
      this.saving = false;
      this.isLoading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: JSON.parse(err.response).Message ? JSON.parse(err.response).Message : 'An unknown error occurred, unable to reactivate quote.'
      });
    });
  }

  copyOneClickLink() {
    let domain = new URL(window.location.href).hostname;

    if (domain === 'localhost') {
      domain = 'localhost:4200';
    }

    const encodedCustId = Base64.encode(this.workOrder.customerId.toString());
    const encodedQuoteId = Base64.encode(this.workOrder.id.toString());

    const link: string = `${domain}/customerQuotePage?cid=${encodedCustId}&id=${encodedQuoteId}&s=m`;
    window.navigator['clipboard'].writeText(link);
  }

  saveAndSend(ngForm: NgForm) {
    this.workOrder.completionStatus = QuoteWorkOrderCompletionStatus.Sent;

    this.workOrder.workOrderWellnessTasks.forEach(wowt => {
      wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Sent;
    });

    this.workOrder.workOrderWorkTasks.forEach(wowt => {
      wowt.workTask.completionStatus = WorkTaskCompletionStatus.Sent;
    });

    if (this.token.roles.includes('Regional Manager')) {
      this.shouldNavigateToRMSchedule = true;
    }

    this.sendQuote = true;
    this.save(ngForm);
  }

  protected saveWO() {
    this.saving = true;
    if (this.workOrder.id) {
      // Updating existing quote
      this.quoteService.update(this.workOrder, this.workOrder.id.toString())
        .subscribe(quote => {
          this.afterSaveWO(quote);
        }, error => {
          this.saving = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not update the quote, please check that all fields are valid and retry.'
          });
        });
    } else {
      // Creating new quote
      this.quoteService.add(this.workOrder)
        .subscribe(quote => {
          this.afterSaveWO(quote);
        }, error => {
          this.saving = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not add the quote, please check that all fields are valid and retry.'
          });
        });
    }
  }

  private afterSaveWO(quote: QuoteWorkOrder) {

    this.workOrder = quote;
    this.workOrder.id = quote.id;
    if (this.sendQuote) {
      this.emailService.sendQuoteCreation(this.workOrder, Base64.encode(this.workOrder.customerId.toString()), Base64.encode(this.workOrder.id.toString())).subscribe(x => {
        this.workOrder.lastSent = new Date();
        this.saveBlobs(quote, false);
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Sending Quote',
          detail: 'Quote saved but could not send the quote to the customer.'
        });
        this.saveBlobs(quote, true);
      });
    } else {
      this.saveBlobs(quote, false);
    }
  }

  private saveBlobs(savedQuote: QuoteWorkOrder, hasError: boolean) {
    // Currently only 1 blob manager should be not view only. If that changes, you'll need to update this and loop through all the
    // ones that are not view only and save them.
    const blobManagerComponent: BlobManagerComponent = this.blobManagerComponents.find(blobComponent => blobComponent.viewOnly === false);

    if (blobManagerComponent) {
      blobManagerComponent.parentRecordId = savedQuote.id;
       blobManagerComponent.save().subscribe(() => {
        if (!hasError && this.sendQuote) {
          this.sendQuote = false;
          this.navigate();
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Save Successful',
            detail: 'The quote was saved successfully!'
          });
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(`/quoteWorkOrderMaintenance/${this.workOrder.id}`);
          // });
          this.saving = false;
        }
      });
    } else {
      if (!hasError && this.sendQuote) {
        this.sendQuote = false;
        this.navigate();
      } else {
        this.messageService.add({
          severity: 'success',
          summary: 'Save Successful',
          detail: 'The quote was saved successfully!'
        });
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/quoteWorkOrderMaintenance/${this.workOrder.id}`);
        // });
        this.saving = false;
      }
    }
  }

  private navigate() {
    if (this.shouldNavigateToRMSchedule) {
      // They want the RMs to navigate back to their schedule page
      this.saving = false;
      this.router.navigateByUrl('rmSchedulePage/' + this.token.employeeId);
    } else {
      this.saving = false;
      this.cancel('quote');
    }
  }

  // Quote can be completed if user has permission, it is not new, editable, and has at least 1 task (wellness or work)
  private setCanComplete(): void {
    this.canSign = this.canSign
                    && this.workOrder
                    && this.workOrder.id
                    && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
                    && (this.workOrder.workOrderWellnessTasks.length > 0 || this.workOrder.workOrderWorkTasks.length > 0);
  }

  createWOs(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();

      // If control is a form group, loop through that group to mark each control
      // This marks the child components' form controls dirty/touched
      if (ngForm.controls[key] instanceof FormGroup) {
        const formGroup: FormGroup = ngForm.controls[key] as FormGroup;
        Object.keys(formGroup.controls).forEach(innerKey => {
          formGroup.controls[innerKey].markAsDirty();
          formGroup.controls[innerKey].markAsTouched();
        });
      }
    });

    if (!ngForm.invalid) {
      this.saving = true;
      if (confirm('This will mark the quote as converted and create separate work orders for the wellness tasks and the work tasks. ' +
        'This action cannot be canceled. Do you want to continue?')) {
          this.workOrder.completionStatus = QuoteWorkOrderCompletionStatus.Work_Orders_Created;
          this.workOrder.signedDate = new Date();

        if (this.workOrder.workOrderWellnessTasks.length > 0 || this.workOrder.workOrderWorkTasks.length > 0) {
          this.isEditable = false;

          this.workOrder.workOrderWellnessTasks.forEach(wowt => {
            if (wowt.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Work_Orders_Created) {
              wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
            }
          });
          this.workOrder.workOrderWorkTasks.forEach(wowt => {
            if (wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Work_Orders_Created) {
              wowt.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
            }
          });

          // this.quoteService.convertToWorkOrders(this.workOrder).subscribe();
        }

        this.saveWO();
      }
    }
  }

  rejectQuote() {
    if (confirm('This will mark the whole quote as rejected. This action cannot be canceled, do you want to continue?')) {
      this.workOrder.completionStatus = QuoteWorkOrderCompletionStatus.Quote_Rejected;
      this.workOrder.rejectedDate = new Date();

      this.workOrder.workOrderWellnessTasks.forEach(wowt => {
        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
      });

      this.workOrder.workOrderWorkTasks.forEach(wowt => {
        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
      });

      this.saveWO();
    }
  }

  setCanAddNewTask() {
    let canAdd = false;

    canAdd = this.isEditable && this.workOrder.dueDateStart !== undefined && this.workOrder.dueDateStart !== null
      && this.workOrder.dueDateEnd !== undefined && this.workOrder.dueDateEnd !== null;

    if (!this.workOrder.hasCalculatedPriority) {
      canAdd = canAdd && this.workOrder.manualPriorityLevel !== undefined && this.workOrder.manualPriorityLevel !== null;
    }

    this.canAddNewTask = canAdd;
  }

  addNewWorkTask() {
    this.displayWorkTaskDialog = true;
  }

  copyWorkTask(wowt: WorkOrderWorkTask) {
    this.selectedWorkTask = new WorkTask({
                            active: true,
                            location: wowt.workTask.location,
                            workTaskTreeTypes: wowt.workTask.workTaskTreeTypes.map(wttt => new WorkTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType}) ),
                            primordialTaskId: wowt.workTask.id,
                            priorityLevelId: wowt.workTask.priorityLevelId,
                            manualPriorityLevel: wowt.workTask.manualPriorityLevel,
                            priorityShortcutId: wowt.workTask.priorityShortcutId,
                            priorityTypeShortcut: wowt.workTask.priorityTypeShortcut,
                            hasCalculatedPriority: wowt.workTask.hasCalculatedPriority,
                            dueDateEnd: wowt.workTask.dueDateEnd,
                            dueDateStart: wowt.workTask.dueDateStart,
                            hardEndDate: wowt.workTask.hardEndDate,
                            hardStartDate: wowt.workTask.hardStartDate,
                            // schedulableAfterCompletionOf: wowt.workTask.schedulableAfterCompletionOf,
                            // schedulableAfterCompletionOfId: wowt.workTask.schedulableAfterCompletionOfId,
                            completionStatus: wowt.workTask.completionStatus,
                            workTaskSkills: [],
                            workTaskEquipment: [],
                            taskHourEntries: [],
                            fixedPrice: wowt.workTask.fixedPrice
                          });
    this.displayWorkTaskDialog = true;
  }

  copyWellnessTask(wowt: WorkOrderWellnessTask) {
    this.selectedWellnessTask = new WellnessTask({
                            active: true,
                            location: wowt.wellnessTask.location,
                            wellnessTaskTreeTypes: wowt.wellnessTask.wellnessTaskTreeTypes.map(wttt => new WellnessTaskTreeType({ treeTypeId: wttt.treeTypeId, treeType: wttt.treeType}) ),
                            primordialTaskId: wowt.wellnessTask.id,
                            priorityLevelId: wowt.wellnessTask.priorityLevelId,
                            manualPriorityLevel: wowt.wellnessTask.manualPriorityLevel,
                            priorityShortcutId: wowt.wellnessTask.priorityShortcutId,
                            priorityTypeShortcut: wowt.wellnessTask.priorityTypeShortcut,
                            hasCalculatedPriority: wowt.wellnessTask.hasCalculatedPriority,
                            dueDateEnd: wowt.wellnessTask.dueDateEnd,
                            dueDateStart: wowt.wellnessTask.dueDateStart,
                            hardEndDate: wowt.wellnessTask.hardEndDate,
                            hardStartDate: wowt.wellnessTask.hardStartDate,
                            // schedulableAfterCompletionOf: wowt.wellnessTask.schedulableAfterCompletionOf,
                            // schedulableAfterCompletionOfId: wowt.wellnessTask.schedulableAfterCompletionOfId,
                            completionStatus: wowt.wellnessTask.completionStatus,
                            wellnessTaskSkills: [],
                            wellnessTaskEquipment: [],
                            taskHourEntries: [],
                            fixedPrice: true,
                            renewable: wowt.wellnessTask.renewable
                          });
    this.displayWellnessTaskDialog = true;
  }

  editWorkTask(wowt: WorkOrderWorkTask) {
    this.selectedWorkTask = wowt.workTask;
    this.displayWorkTaskDialog = true;
  }

  addNewWelnessTask() {
    this.displayWellnessTaskDialog = true;
  }

  editWellnessTask(wowt: WorkOrderWellnessTask) {
    this.selectedWellnessTask = wowt.wellnessTask;
    this.displayWellnessTaskDialog = true;
  }

  protected closedDialog(task: WellnessTask | WorkTask) {
    if (task instanceof WellnessTask && task.id) {
      this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTaskId === task.id).wellnessTask = task;
    } else if (task instanceof WorkTask && task.id) {
      this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTaskId === task.id).workTask = task;
    } else if (!task) {
      if (this.selectedWellnessTask && this.selectedWellnessTask.id) {
        this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTaskId === this.selectedWellnessTask.id).wellnessTask = (this.wellnessTaskMaintenance.taskUnmodified as WellnessTask);
      } else if (this.selectedWorkTask && this.selectedWorkTask.id) {
        this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTaskId === this.selectedWorkTask.id).workTask = (this.workTaskMaintenance.taskUnmodified as WorkTask);
      }
    }
    this.displayWellnessTaskDialog = false;
    this.displayWorkTaskDialog = false;
    this.selectedWellnessTask = undefined;
    this.selectedWorkTask = undefined;

    if (this.workOrder.id) {
      this.saveWO();
    }

    this.onTaskChange();
  }

  protected cancelledTask(task: WellnessTask | WorkTask) {
    if (task instanceof WellnessTask && task.id) {
      this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTaskId === task.id).wellnessTask = task;
    } else if (task instanceof WorkTask && task.id) {
      this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTaskId === task.id).workTask = task;
    } else if (!task) {
      if (this.selectedWellnessTask && this.selectedWellnessTask.id) {
        this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTaskId === this.selectedWellnessTask.id).wellnessTask = (this.wellnessTaskMaintenance.taskUnmodified as WellnessTask);
      } else if (this.selectedWorkTask && this.selectedWorkTask.id) {
        this.workOrder.workOrderWorkTasks.find(wowt => wowt.workTaskId === this.selectedWorkTask.id).workTask = (this.workTaskMaintenance.taskUnmodified as WorkTask);
      }
    }

    this.displayWellnessTaskDialog = false;
    this.displayWorkTaskDialog = false;
    this.selectedWellnessTask = undefined;
    this.selectedWorkTask = undefined;

    this.onTaskChange();
  }

  protected onTaskChange() {
    this.requiredSkills = this.getSkillsFromTasks();
    this.requiredEquipment = this.getEquipmentFromTasks();
    this.setCanComplete();
  }

  getSkillsFromTasks() {
    let wellnessSkills: Skill[] = [];
    let workSkills: Skill[] = [];

    this.workOrder.workOrderWellnessTasks.forEach(wowt => {
      wellnessSkills = wellnessSkills.concat(wowt.wellnessTask.wellnessTaskSkills.map(wts => wts.skill));
    });

    this.workOrder.workOrderWorkTasks.forEach(wowt => {
      workSkills = workSkills.concat(wowt.workTask.workTaskSkills.map(wts => wts.skill));
    });

    return this.helper.getUnique(wellnessSkills.concat(workSkills));
  }

  getEquipmentFromTasks() {
    let wellnessEquipment: EquipmentType[] = [];
    let workEquipment: EquipmentType[] = [];

    this.workOrder.workOrderWellnessTasks.forEach(wowt => {
      wellnessEquipment = wellnessEquipment.concat(wowt.wellnessTask.wellnessTaskEquipment.map(wte => wte.equipmentType));
    });

    this.workOrder.workOrderWorkTasks.forEach(wowt => {
      workEquipment = workEquipment.concat(wowt.workTask.workTaskEquipment.map(wte => wte.equipmentType));
    });

    return this.helper.getUnique(wellnessEquipment.concat(workEquipment));
  }

  repChanged() {
    this.workOrder.representativeId = this.workOrder.representative.id;
  }

  templateChanged() {
    this.workOrder.note = this.selectedTemplate.message;
  }

  approveWithoutCustomer() {
    if (!this.approvalComment) return;

    this.isApproving = true;

    if (this.workOrder.workOrderWellnessTasks.length > 0 || this.workOrder.workOrderWorkTasks.length > 0) {
      this.workOrder.workOrderWellnessTasks.forEach(wowt => {
        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Credit_Card_Needed;
      });
      this.workOrder.workOrderWorkTasks.forEach(wowt => {
        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Credit_Card_Needed;
      });
    }

    var weeksOutDateCalculation = {
      start: undefined, end: undefined
    };
    if (this.workOrder.priorityTypeShortcut && this.workOrder.priorityTypeShortcut.weeksOutStart !== undefined) {
      weeksOutDateCalculation = {
        start: moment(new Date()).add(this.workOrder.priorityTypeShortcut.weeksOutStart, 'w'),
        end: moment(new Date()).add(this.workOrder.priorityTypeShortcut.weeksOutEnd, 'w')
      };
    } else if (this.workOrder.manualRangeStart && this.workOrder.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week) {
      weeksOutDateCalculation = {
        start: moment(new Date()).add(this.workOrder.manualRangeStart, 'w'),
        end: moment(new Date()).add(this.workOrder.manualRangeEnd, 'w')
      };
    } else if (this.workOrder.manualRangeStart && this.workOrder.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day) {
      weeksOutDateCalculation = {
        start: moment(new Date()).add(this.workOrder.manualRangeStart, 'd'),
        end: moment(new Date()).add(this.workOrder.manualRangeEnd, 'd')
      };
    }

    if (this.workOrder.priorityTypeShortcut && this.workOrder.priorityTypeShortcut.weeksOutStart !== undefined) {
      this.workOrder.dueDateStart = weeksOutDateCalculation.start.toDate();
      this.workOrder.dueDateEnd = weeksOutDateCalculation.end.toDate();
    }

    if (this.workOrder.manualPriorityShortcutType && this.workOrder.manualRangeStart) {
      this.workOrder.dueDateStart = weeksOutDateCalculation.start.toDate();
      this.workOrder.dueDateEnd = weeksOutDateCalculation.end.toDate();
    }

    if (!this.workOrder.originalSignedDate) {
      this.workOrder.originalSignedDate = new Date();
    }

    this.quoteService.convertToWorkOrders(this.workOrder, this.workOrder.workOrderWellnessTasks.map(wowt => wowt.id).join(','), this.workOrder.workOrderWorkTasks.map(wowt => wowt.id).join(',')).subscribe(res => {
      this.customStripeService.addOrUpdateAndApproveQuote(this.workOrder.customerId,
        '',
        null,
        this.workOrder.id,
        this.workOrder.customer.manualPayment,
        false).subscribe(x => {
          this.customerNoteService.add(new CustomerComment({
            active: true,
            comment: this.approvalComment,
            employeeId: this.token.employeeId,
            customerCommentStageId: 1, // 1 is the quote stage id
            quoteNumber: this.workOrder.quoteNumber,
            companyWide: false,
            customerId: this.workOrder.customerId
          })).subscribe();
          this.quoteService.markAsSigned(new Dto({
            quoteId: this.workOrder.id,
            selectedWellnessTaskIds: this.workOrder.workOrderWellnessTasks.map(wowt => wowt.id),
            selectedWorkTaskIds: this.workOrder.workOrderWorkTasks.map(wowt => wowt.id)
          }))
            .subscribe(() => {
          this.emailService.sendQuoteAcceptance(this.workOrder, Base64.encode(this.workOrder.customerId.toString()), Base64.encode(this.workOrder.id.toString()), true).subscribe(() => {
              this.router.navigateByUrl('/workOrderList');
              this.displayApproveDialog = false;
              this.isApproving = false;
            });
          });
        });
    });
  }

  resetToEditable() {
    if (confirm('This will delete any WO created from this quote and allow customer to reselect tasks.')) {
      this.disableReset = true;
      this.quoteService.resetQuote(this.workOrder.id).subscribe(res => {
        this.isLoading = true;
        this.workOrder = res;
        this.setPermissions();
        this.getWO(res.id);
        this.disableReset = false;
        this.showReset = false;
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          sticky: true,
          detail: JSON.parse(error.response).Message ? JSON.parse(error.response).Message : 'An unknown error occurred, unable to undo customer approval.'
        });
      });
    }
  }
}
