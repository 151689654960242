import * as moment from 'moment';
import * as i0 from "@angular/core";
var SpecificationService = /** @class */ (function () {
    function SpecificationService() {
    }
    // IF YOU CHANGE THIS, CHANGE THE BACK END ONE TOO.
    SpecificationService.prototype.EquipmentInService = function (equipment, dateOfInterest) {
        if (dateOfInterest === void 0) { dateOfInterest = new Date(); }
        var momentOfInterest = moment(dateOfInterest);
        // 1. if there is no equipment it's out of service
        if (equipment == null) {
            return false;
        }
        // 2. no service records means it's in service
        if (equipment.equipmentServiceRecords == null) {
            return true;
        }
        // 3. if any record exists, it's currently inactive. So put a ! in front of this
        return !equipment.equipmentServiceRecords.some(function (r) {
            if (r.inactiveDateTo) {
                return momentOfInterest.isBetween(moment(r.inactiveDateFrom), moment(r.inactiveDateTo), 'days', '[]');
            }
            else {
                return momentOfInterest.isSameOrAfter(moment(r.inactiveDateFrom), 'days');
            }
        });
    };
    SpecificationService.ngInjectableDef = i0.defineInjectable({ factory: function SpecificationService_Factory() { return new SpecificationService(); }, token: SpecificationService, providedIn: "root" });
    return SpecificationService;
}());
export { SpecificationService };
