import { WellnessWorkOrder, WorkWorkOrder, QuoteWorkOrder, WellnessTask, WorkTask, WorkTaskCompletionStatus, QuoteRequestType, QuoteWorkOrderCompletionStatus, WellnessTaskPaymentStatus, WellnessTaskCompletionStatus, } from '../services_autogenerated/generated_services';
import * as i0 from "@angular/core";
var WorkOrderHelperService = /** @class */ (function () {
    // tslint:disable: max-line-length
    function WorkOrderHelperService() {
    }
    // TODO the union type is used here because, atm, the service generator doesn't generate the IWorkOrder class from the C#
    WorkOrderHelperService.prototype.getTasks = function (workOrder, tasks) {
        if (workOrder instanceof WellnessWorkOrder) {
            // return wellness tasks
            return workOrder.workOrderWellnessTasks.map(function (wowt) { return tasks && tasks.length > 0 ? (tasks.find(function (x) { return x.id === wowt.wellnessTask.id; }) ? wowt.wellnessTask : null) : wowt.wellnessTask; }).filter(function (x) { return x != null; });
        }
        else if (workOrder instanceof WorkWorkOrder) {
            return workOrder.workOrderWorkTasks.map(function (wowt) { return tasks && tasks.length > 0 ? (tasks.find(function (x) { return x.id === wowt.workTask.id; }) ? wowt.workTask : null) : wowt.workTask; }).filter(function (x) { return x != null; });
        }
        else if (workOrder instanceof QuoteWorkOrder) {
            // return both wellness and work tasks
            return workOrder.workOrderWellnessTasks.map(function (wowt) { return tasks && tasks.length > 0 ? (tasks.find(function (x) { return x.id === wowt.wellnessTask.id; }) ? wowt.wellnessTask : null) : wowt.wellnessTask; })
                .concat(workOrder.workOrderWorkTasks.map(function (wowt) { return tasks && tasks.length > 0 ? (tasks.find(function (x) { return x.id === wowt.workTask.id; }) ? wowt.workTask : null) : wowt.workTask; })).filter(function (x) { return x != null; }); // dumb generated service
        }
        return [];
    };
    // TODO the union type is used here because, atm, the service generator doesn't generate the IWorkOrder class from the C#
    // getWorkOrderTasks(workOrder: WellnessWorkOrder | WorkWorkOrder | QuoteWorkOrder): (WorkOrderWellnessTask | WorkOrderWorkTask)[] {
    //   if (workOrder instanceof WellnessWorkOrder) {
    //     // return wellness tasks
    //     return workOrder.workOrderWellnessTasks.map(wowt => wowt);
    //   } else if (workOrder instanceof WorkWorkOrder) {
    //     // return work tasks
    //     return workOrder.workOrderWorkTasks.map(wowt => wowt);
    //   } else if (workOrder instanceof QuoteWorkOrder) {
    //     // return both wellness and work tasks
    //     return workOrder.workOrderWellnessTasks.map(wowt => wowt)
    //       .concat(workOrder.workOrderWorkTasks.map(wowt => wowt));
    //   }
    //   return [];
    // }
    WorkOrderHelperService.prototype.subTotal = function (workOrder, tasks, includePaidTasks) {
        if (includePaidTasks === void 0) { includePaidTasks = true; }
        // if it is a quote, then we only want to calculate the total for items that were agreed upon by the customer (signed)
        if (workOrder instanceof QuoteWorkOrder) {
            return this.getTasks(workOrder, tasks)
                .filter(function (task) { return (task.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created || task.completionStatus === QuoteWorkOrderCompletionStatus.Credit_Card_Needed); })
                .reduce(function (acc, curr) { return acc + (curr.price ? curr.price : 0); }, 0);
        }
        if (includePaidTasks) {
            return this.getTasks(workOrder, tasks).reduce(function (acc, curr) { return acc + (curr.price ? curr.price : 0); }, 0);
        }
        else {
            return this.getTasks(workOrder, tasks)
                .filter(function (task) { return (task.paymentStatus !== WellnessTaskPaymentStatus.Paid
                && task.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed); })
                .reduce(function (acc, curr) { return acc + (curr.price ? curr.price : 0); }, 0);
        }
    };
    WorkOrderHelperService.prototype.totalPriceAfterAdjustment = function (workOrder, tasks, includePaidTasks) {
        var _this = this;
        if (includePaidTasks === void 0) { includePaidTasks = true; }
        var basePrice = this.subTotal(workOrder, tasks, includePaidTasks);
        if (workOrder.workOrderPriceAdjustments.length > 0) {
            return workOrder.workOrderPriceAdjustments.reduce(function (acc, curr) { return acc + _this.adjustmentAmount(curr, workOrder, tasks); }, basePrice);
        }
        else {
            return basePrice;
        }
    };
    WorkOrderHelperService.prototype.adjustmentAmount = function (wopa, workOrder, tasks) {
        if (wopa.priceAdjustment.active) {
            if (workOrder instanceof QuoteWorkOrder) {
                return this.getTasks(workOrder, tasks)
                    .filter(function (task) { return task.active && (task.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created || task.completionStatus === QuoteWorkOrderCompletionStatus.Credit_Card_Needed); })
                    .reduce(function (acc, curr) { return acc + Math.floor((curr.price ? curr.price : 0) * (wopa.priceAdjustment.percentage || 0) / 100); }, 0);
            }
            return this.getTasks(workOrder, tasks)
                .filter(function (task) { return (task.active); })
                .reduce(function (acc, curr) { return acc + Math.floor((curr.price ? curr.price : 0) * (wopa.priceAdjustment.percentage || 0) / 100); }, 0);
        }
        else {
            return 0;
        }
    };
    WorkOrderHelperService.prototype.adjustmentAmountCompleted = function (wopa, workOrder) {
        if (wopa.priceAdjustment.active) {
            return this.getTasks(workOrder)
                .filter(function (task) { return (task.active) && task.completionStatus === QuoteWorkOrderCompletionStatus.Completed; })
                .reduce(function (acc, curr) { return acc + Math.floor((curr.price ? curr.price : 0) * (wopa.priceAdjustment.percentage || 0) / 100); }, 0);
        }
        else {
            return 0;
        }
    };
    // TODO: Make these functions get called from back end so there is only one source for Stripe/UI ?
    // Make sure this matches the back end equivalent if changed
    WorkOrderHelperService.prototype.subTotalCompleted = function (workOrder) {
        // if it is a quote, then we only want to calculate the total for items that were already completed
        if (workOrder instanceof QuoteWorkOrder) {
            return this.getTasks(workOrder)
                .filter(function (task) { return task.completionStatus === QuoteWorkOrderCompletionStatus.Completed; })
                .reduce(function (acc, curr) { return acc + (curr.price ? curr.price : 0); }, 0);
        }
        return this.getTasks(workOrder)
            .filter(function (task) { return task.completionStatus === WorkTaskCompletionStatus.Completed; })
            .reduce(function (acc, curr) { return acc + (curr.price ? curr.price : 0); }, 0);
    };
    WorkOrderHelperService.prototype.salesTaxAmount = function (workOrder, tasks, includePaidTasks) {
        var _this = this;
        if (includePaidTasks === void 0) { includePaidTasks = true; }
        var allSalesTax = 0;
        if (workOrder.salesTaxPercentage && workOrder.salesTaxPercentage > 0 && !workOrder.customer.taxExempt) {
            var taskSalesTax = 0;
            if (workOrder instanceof QuoteWorkOrder) {
                taskSalesTax = this.getTasks(workOrder, tasks)
                    .filter(function (task) { return (task.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created || task.completionStatus === QuoteWorkOrderCompletionStatus.Credit_Card_Needed); })
                    .reduce(function (acc, curr) { return acc + _this.currencyRound(((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100))); }, 0);
            }
            else {
                // This is the only difference between 'totalPriceAfterAdjustment()' and 'subTotal()' code in here but needs to happen because Stripe tax is rounded per line item
                if (includePaidTasks) {
                    taskSalesTax = this.getTasks(workOrder, tasks).reduce(function (acc, curr) { return acc + _this.currencyRound((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100)); }, 0);
                }
                else {
                    taskSalesTax = this.getTasks(workOrder, tasks)
                        .filter(function (task) {
                        return task.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed
                            && task.paymentStatus !== WellnessTaskPaymentStatus.Paid;
                    })
                        .reduce(function (acc, curr) { return acc + _this.currencyRound((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100)); }, 0);
                }
            }
            // Add adjustment sales tax
            if (workOrder.workOrderPriceAdjustments.length > 0) {
                allSalesTax = workOrder.workOrderPriceAdjustments.reduce(function (acc, curr) { return acc + _this.currencyRound(_this.adjustmentAmount(curr, workOrder, tasks) * (workOrder.salesTaxPercentage / 100)); }, taskSalesTax);
            }
            else {
                allSalesTax = taskSalesTax;
            }
            // Apply firmtime sales tax discount
            if (this.qualifiesForFirmtimeRefund(workOrder, tasks)) {
                allSalesTax = allSalesTax - this.currencyRound(100 * (workOrder.salesTaxPercentage / 100));
            }
            return allSalesTax;
        }
        else {
            // // No sales tax
            return allSalesTax;
        }
    };
    WorkOrderHelperService.prototype.salesTaxAmountCompleted = function (workOrder) {
        var _this = this;
        var allSalesTax = 0;
        if (workOrder.salesTaxPercentage && workOrder.salesTaxPercentage > 0 && !workOrder.customer.taxExempt) {
            var taskSalesTax = 0;
            taskSalesTax = this.getTasks(workOrder)
                .filter(function (task) { return task.completionStatus === QuoteWorkOrderCompletionStatus.Completed; })
                .reduce(function (acc, curr) { return acc + _this.currencyRound(((curr.price ? curr.price : 0) * (workOrder.salesTaxPercentage / 100))); }, 0);
            // Add adjustment sales tax
            if (workOrder.workOrderPriceAdjustments.length > 0) {
                allSalesTax = workOrder.workOrderPriceAdjustments.reduce(function (acc, curr) { return acc + _this.currencyRound(_this.adjustmentAmountCompleted(curr, workOrder) * (workOrder.salesTaxPercentage / 100)); }, taskSalesTax);
            }
            else {
                allSalesTax = taskSalesTax;
            }
            // Apply firmtime sales tax discount
            if (this.qualifiesForFirmtimeRefund(workOrder)) {
                allSalesTax = allSalesTax - this.currencyRound(100 * (workOrder.salesTaxPercentage / 100));
            }
            return allSalesTax;
        }
        else {
            // No sales tax
            return allSalesTax;
        }
    };
    // TODO: Make these functions get called from back end so there is only one source for Stripe/UI ?
    // Make sure this matches the back end equivalent if changed
    WorkOrderHelperService.prototype.totalAmount = function (workOrder, includePaidTasks) {
        if (includePaidTasks === void 0) { includePaidTasks = true; }
        var total = this.totalPriceAfterAdjustment(workOrder, undefined, includePaidTasks);
        if (this.qualifiesForFirmtimeRefund(workOrder)) {
            total = total - 100;
        }
        return this.currencyRound(total + this.salesTaxAmount(workOrder, null, includePaidTasks));
    };
    WorkOrderHelperService.prototype.totalAmountByTasks = function (workOrder, tasks) {
        var total = this.totalPriceAfterAdjustment(workOrder, tasks);
        if (this.qualifiesForFirmtimeRefund(workOrder, tasks)) {
            total = total - 100;
        }
        return this.currencyRound(total + this.salesTaxAmount(workOrder, tasks));
    };
    WorkOrderHelperService.prototype.qualifiesForFirmtimeRefund = function (workOrder, tasks) {
        if (workOrder instanceof QuoteWorkOrder && workOrder.quoteRequest && workOrder.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
            if (this.totalPriceAfterAdjustment(workOrder, tasks) > 500 && !workOrder) {
                return true;
            }
        }
        else if (workOrder instanceof WellnessWorkOrder && workOrder.quoteWorkOrder.quoteRequest && workOrder.quoteWorkOrder.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !workOrder.ignoreFirmtime) {
            if (this.totalPriceAfterAdjustment(workOrder, tasks) > 500) {
                return true;
            }
        }
        else if (workOrder instanceof WorkWorkOrder && workOrder.quoteWorkOrder.quoteRequest && workOrder.quoteWorkOrder.quoteRequest.quoteRequestType === QuoteRequestType.Firmtime && !workOrder.ignoreFirmtime) {
            if (this.totalPriceAfterAdjustment(workOrder, tasks) > 500) {
                return true;
            }
        }
        return false;
    };
    // evenRound(num, decimalPlaces) {
    //   const d = decimalPlaces || 0;
    //   const m = Math.pow(10, d);
    //   const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    //   const i = Math.floor(n), f = n - i;
    //   const e = 1e-8; // Allow for rounding errors in f
    //   const r = (f > 0.5 - e && f < 0.5 + e) ?
    //               ((i % 2 === 0) ? i : i + 1) : Math.round(n);
    //   return d ? r / m : r;
    // }
    // Matches Stripe logic
    WorkOrderHelperService.prototype.currencyRound = function (num) {
        // Javascript does weird things and this handles them (Ex: rounding 1.005)
        return Math.round((num + 0.00001) * 100) / 100;
    };
    WorkOrderHelperService.prototype.getUnique = function (arr) {
        var unique = arr
            .map(function (e) { return e['id']; })
            // store the keys of the unique objects
            .map(function (e, i, final) { return final.indexOf(e) === i && i; })
            // eliminate the dead keys & store unique objects
            .filter(function (e) { return arr[e]; }).map(function (e) { return arr[e]; });
        return unique;
    };
    WorkOrderHelperService.prototype.getUniqueEquipment = function (workOrder) {
        var equipment = [];
        this.getTasks(workOrder).forEach(function (wowt) {
            if (wowt instanceof WorkTask) {
                var workTasks = wowt;
                equipment = equipment.concat(workTasks.workTaskEquipment.map(function (wte) { return wte.equipmentType; }));
            }
            if (wowt instanceof WellnessTask) {
                var wellnessTasks = wowt;
                equipment = equipment.concat(wellnessTasks.wellnessTaskEquipment.map(function (wte) { return wte.equipmentType; }));
            }
        });
        return this.getUnique(equipment);
    };
    // This tells you whether it is qualified to be scheduled. It could be scheduled, or it could not be.
    WorkOrderHelperService.prototype.isTaskQualifiedToBeScheduled = function (task) {
        return this.isTaskCapableOfProgress(task) &&
            !(task.schedulableAfterCompletionOf && this.isTaskCapableOfProgress(task.schedulableAfterCompletionOf));
    };
    WorkOrderHelperService.prototype.isTaskCapableOfProgress = function (task) {
        return task.active && !(task.completionStatus === WorkTaskCompletionStatus.Completed || task.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed || task.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up);
    };
    // Maybe include tax here?
    WorkOrderHelperService.prototype.totalAmountCompleted = function (workOrder) {
        var total = this.totalCompletedPriceAfterAdjustment(workOrder);
        if (this.qualifiesForFirmtimeRefund(workOrder)) {
            total = total - 100;
        }
        else {
            return total;
        }
    };
    // TODO: Make these functions get called from back end so there is only one source for Stripe/UI ?
    // Make sure this matches the back end equivalent if changed
    WorkOrderHelperService.prototype.totalCompletedPriceAfterAdjustment = function (workOrder) {
        var _this = this;
        var basePrice = this.subTotalCompleted(workOrder);
        if (workOrder.workOrderPriceAdjustments.length > 0) {
            return workOrder.workOrderPriceAdjustments.reduce(function (acc, curr) { return acc + _this.adjustmentAmountCompleted(curr, workOrder); }, basePrice) + this.salesTaxAmountCompleted(workOrder);
        }
        else {
            return basePrice + this.salesTaxAmountCompleted(workOrder);
        }
    };
    WorkOrderHelperService.prototype.isTaskScheduledButNotComplete = function (task) {
        return (!task.isGoBack && (task.currentBucketId || task.currentBigDaySubrangeId)
            || (task.isGoBack && task.goBackBucketId))
            && this.isTaskCapableOfProgress(task);
    };
    WorkOrderHelperService.prototype.isTaskScheduledForGoBackButNotComplete = function (task) {
        return this.isTaskCapableOfProgress(task) && task.goBackBucketId;
    };
    WorkOrderHelperService.prototype.isTaskMultiDayScheduledButNotComplete = function (task) {
        return task.scheduleDateFrom &&
            task.currentBigDaySubrangeId &&
            this.isTaskCapableOfProgress(task);
    };
    WorkOrderHelperService.prototype.openMap = function (workOrder) {
        /* if we're on iOS, open in Apple Maps */
        if ((navigator.platform.indexOf('iPhone') !== -1)
            || (navigator.platform.indexOf('iPad') !== -1)
            || (navigator.platform.indexOf('iPod') !== -1)) {
            window.open("maps://maps.apple.com/maps?q=" + workOrder.address.street + ", " + workOrder.address.zip, '_blank');
        }
        else { /* else use Google  */
            window.open("https://maps.google.com/maps?q=" + workOrder.address.street + ", " + workOrder.address.zip, '_blank');
        }
    };
    WorkOrderHelperService.ngInjectableDef = i0.defineInjectable({ factory: function WorkOrderHelperService_Factory() { return new WorkOrderHelperService(); }, token: WorkOrderHelperService, providedIn: "root" });
    return WorkOrderHelperService;
}());
export { WorkOrderHelperService };
