<div class="p-grid margin-up" *ngIf="!isCustomer">
  <!-- <div class="p-col-12 p-md-12" *ngIf="!isCustomer && !assumeMobile">
    <app-day-zip-code-view-only *ngIf="employee" [employee]="employee"></app-day-zip-code-view-only>
  </div> -->
  <!-- <div *ngIf="!isCustomer" [ngClass]="{'p-col-12 p-md-2': !isCustomer && !assumeMobile, 'p-col-12': isCustomer || assumeMobile}">
    <div class="key">
      <div><i class="fa fa-square" [ngStyle]="getKeyStyles('firmtime')"></i> - Firmtime Quote</div>
      <div><i class="fa fa-square" [ngStyle]="getKeyStyles('anytime')"></i> - Anytime Quote</div>
      <div><i class="fa fa-square" [ngStyle]="getKeyStyles('block')"></i> - Blocked Time Slot</div>
    </div>
  </div> -->
  <div class="calendarContainer p-col-12 p-md-10">
    <full-calendar 
      #calendar
      *ngIf="options"
      [businessHours]="isCustomer ? businessHours : false"
      [events]="events"
      [allDaySlot]="false" 
      [customButtons]="options.customButtons"
      [defaultView]="options.defaultView" 
      [height]="options.height"
      [plugins]="options.plugins"
      [droppable]="options.droppable"
      [header]="options.header"
      [defaultTimedEventDuration]="options.defaultEventMinutes"
      [selectable]="options.selectable"
      [eventOverlap]="options.eventOverlap"
      [selectOverlap]="options.selectOverlap"
      [eventLimit]="3"
      [longPressDelay]="longPressDelay"
      [eventLongPressDelay]="longPressDelay"
      [selectLongPressDelay]="longPressDelay"
      [editable]="true"
      (eventRender)="eventRender($event)"
      (eventReceive)="eventReceive($event)"
      (eventDrop)="eventDrop($event)"
      (eventClick)="clickEvent($event)"
      (eventResize)="eventResize($event)"
      (select)="eventSelect($event)"
      (datesRender)="getScheduledEvents($event)"
      (viewSkeletonRender)="viewRender($event)">
    </full-calendar>
  </div>
  <div class="p-col-12 p-md-2 ui-fluid hide-small calendarContainer">
    <div class="p-col-12 center" *ngIf="customerIssueWOs?.length > 0">
      <h6>Customer Issues</h6>
    </div>
    <p-scrollPanel class="customer-issues" *ngIf="customerIssueWOs?.length > 0">
      <div *ngFor="let wo of customerIssueWOs">
        <app-work-order-item class="fc-event" [workOrder]="wo" (click)="clickWOEvent(wo)"></app-work-order-item>
      </div>
    </p-scrollPanel>
    <div class="p-col-12 center">
      <h6>Anytime Quotes</h6>
    </div>
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon" style="border-top-left-radius: 15px; border-bottom-left-radius: 15px;"><i class="pi pi-search"></i></span>
      <input type="text" pInputText placeholder="Filter" [(ngModel)]="searchTerm">
    </div>
    <p-scrollPanel class="anytimeQuotes" [style]="{'height': '100%', 'max-height': '75vh'}">
      <div #external>
        <div *ngIf="loadingUnscheduledAnytime" style="text-align: center; margin-top: 10px;">
          <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
        </div>
        <div *ngIf="!loadingUnscheduledAnytime && anytimeQuoteRequests?.length <= 0" class="zipSection">
          There are currently no unscheduled Anytime Quote Requests for you.
        </div>
        <div *ngFor="let quoteRequest of anytimeQuoteRequests | quoteRequestFilter: searchTerm" class="zipSection">
          <app-quote-request-item 
            [quoteRequest]="quoteRequest" 
            class='fc-event' 
            [id]="quoteRequest.id"
            [attr.id]="quoteRequest.id"
            [attr.editable]="true"
            [attr.address]="quoteRequest.address.street + ', ' + quoteRequest.address.city + ' ' + (quoteRequest.address.state != null ? quoteRequest.address.state.abbreviation : '') + ', ' + quoteRequest.address.zip"
            [attr.customerId]="quoteRequest.customerId"
            [attr.customerName]="customerService.getCustomerFullName(this.quoteRequest.customer)"
            [attr.description]="quoteRequest.description"
            [attr.isUrgent]="quoteRequest.isUrgent"
            [attr.phoneNumber]="customerService.getPrimaryContact(quoteRequest.customer).phoneNumber"
            [attr.quoteRequestId]="quoteRequest.id"
            [attr.services]="customQuoteRequestService.getQuoteRequestServicesRequested(quoteRequest)"
            [attr.timeBackgroundColor]="quoteRequest.customer.address.geoColor"
            [attr.timeTextColor]="quoteRequest.customer.address.textColor"
            [attr.type]="quoteRequest.quoteRequestType"
            [attr.zipCode]="quoteRequest.address.zip"
            (click)="clickEvent(quoteRequest.id)"></app-quote-request-item>
        </div>
      </div> 
    </p-scrollPanel>
  </div>
</div>

<app-quote-billing-modal *ngIf="quoteBillingModalDisplay" [quoteId]="quoteId" [display]="quoteBillingModalDisplay" (emitClose)="closeQuoteBillingModal()" (emitPaymentSuccess)="onPaymentSuccess()" [isQuoteRequest]="true"></app-quote-billing-modal>
<app-new-event-modal *ngIf="displayNewEventModal" [display]="displayNewEventModal" [blockToUpdate]="blockToUpdate" (emitClose)="closeNewEventModal()" (emitSubmit)="saveNewEvent($event)"></app-new-event-modal>
<app-additional-info-modal *ngIf="showAdditionalInfoModal" [display]="showAdditionalInfoModal" [quoteRequest]="selectedRFQ" [event]="selectedEvent" (emitClose)="closeAdditionalInfoModal()"></app-additional-info-modal>

<p-confirmDialog [key]="1"></p-confirmDialog>