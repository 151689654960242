import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Equipment } from '../../services_autogenerated/generated_services';

@Component({
  selector: 'app-equipment-card',
  templateUrl: './equipment-card.component.html',
  styleUrls: ['./equipment-card.component.css']
})
export class EquipmentCardComponent implements OnInit {
  @Input() equipment: Equipment;
  @Input() deleteable: boolean;

  @Output() deleted = new EventEmitter<Equipment>();

  constructor() { }

  ngOnInit() {
  }

  // This will emit an event that is caught by whatever container the equipment is in (scheduled bucket or crew)
  deleteFromContainer() {
    if (this.deleteable) {
      this.deleted.emit(this.equipment);
    }
  }
}
