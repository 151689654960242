import { Component, OnInit, Input } from '@angular/core';
import { WorkTask, WellnessTask } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.css']
})
export class TaskCardComponent implements OnInit {
  @Input() task: WorkTask | WellnessTask;
  @Input() isDisabled = false;
  @Input() showPrice: boolean;
  unit: string;
  goBackUnit: string;
  treeTypes: string;

  constructor(private dragAndDropService: DragAndDropService) { }

  ngOnInit() {
    if (this.task instanceof WellnessTask) {
      this.treeTypes = this.task.wellnessTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(', ');
    } else if (this.task instanceof WorkTask) {
      this.treeTypes = this.task.workTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(', ');
    }

    if (this.task.hours === 1) {
      this.unit = 'hr';
    } else {
      this.unit = 'hrs';
    }

    if (this.task.isGoBack && this.task.goBackHoursEstimate === 0) {
      this.goBackUnit = 'hr';
    } else if (this.task.isGoBack) {
      this.goBackUnit = 'hrs';
    }
  }

}
