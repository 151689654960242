<br>
<form id="customerForm" #customerForm="ngForm" *ngIf="customer">
    <app-contact-info [customer]="customer"
      [canEdit]="canEdit"
      [canEditBilling]="true"
      (paymentInfoRequiredSet)="paymentInfoRequiredSet()"
      (customerPreSave)="customerPreSave($event)"
    ></app-contact-info>
    <!-- End of Non-Residential Customer Type Layout-->
    <app-customer-billing-profile *ngIf="customer.id && !isDialog && (isCustomer || canEdit)" [(customer)]="customer" (billingProfileEntryModalClosed)="billingProfileEntryModalClosed()"></app-customer-billing-profile>
    <div>
        <h4>Customer Documents</h4>
        <app-blob-manager [parentRecordId]="customer.id" [containerName]="'customer'" [uploadLabel]="'Document'" [viewOnly]="!hasCustomerDocumentEditPermission"></app-blob-manager>
    </div>
    <div class="ui-g">
        <div class="ui-g-12">
            <div *ngIf="customerForm.invalid && customerForm.submitted" class="alert alert-danger" role="alert">
                There are errors on this page. Please correct the errors and resubmit.
            </div>
        </div>
        <div class="ui-g-12" *ngIf="canEdit">
            <button pButton type="submit" class="ui-button-raised ui-button-success buttonStyle" label="Save"
                    (click)="save(customerForm)" [disabled]="saving"></button>
            <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" label="Cancel"
                    (click)="cancel()" *ngIf="!isCustomer"></button>
            <button pButton type="button" class="ui-button-raised ui-button-danger buttonStyle" label="Delete"
                    [disabled]="disableDelete" (click)="delete()" *ngIf="!isCustomer"></button>
        </div>
    </div>
</form>

<div class="ui-g" *ngIf="!canEdit && !isCustomer">
  <div class="ui-g-12 columnPadding">
    <button pButton type="button" class="ui-button-raised ui-button-warning buttonStyle" label="Return to all customers"
      (click)="cancel()">
    </button>
  </div>
</div>

<br>
<!-- <app-customer-comments *ngIf="customer && customer.id && !isCustomer" [customerIdInput]="customer.id" [claimNames]="claimNames">
</app-customer-comments> -->