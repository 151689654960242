import { Component, OnInit } from '@angular/core';
import { AuthenticationGenSvc } from '../../../services_autogenerated/generated_services';
import { AuthHelperService } from '../../../services/auth-helper.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit {

  newPassword: string;
  newPasswordConfirmed: string;
  previousPassword: string;
  submitted = false;
  saving = false;
  wasAnon = false;

  strongPassword: boolean;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;

  errorMessage: string;

  constructor(
    private authenticationService: AuthenticationGenSvc,
    private authHelperService: AuthHelperService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.wasAnon = this.route.snapshot.queryParams.asAnonymous;
  }

  submit(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    this.submitted = true;
    if (ngForm.valid && this.passwordsMatch()) {
      this.saving = true;
      this.authenticationService.changePassword(this.newPasswordConfirmed)
        .pipe(catchError((error) => {
          this.errorMessage = 'Unable to save password. Please confirm the criteria and retry. Contact support if the issue persists.'
          this.saving = false;
          return throwError(error);
        }))
        .subscribe(token => {
          this.saving = false;
          if (token != null) {
            localStorage.setItem(this.authHelperService.authToken, token);
          }
          this.messageService.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Password changed.'
          });
          this.authHelperService.redirectToHome();
        });
    }
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  passwordsMatch() {
    return this.newPasswordConfirmed === this.newPassword;
  }
}
