import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { WorkTask,
  WellnessTask,
  ScheduledBucketGenSvc,
  WellnessTaskHourEntry,
  WellnessTaskHourEntryGenSvc,
  CustomerNoteGenSvc,
  CustomerComment,
  CustomerCommentStageGenSvc,
  CustomerCommentStage,
  WorkTaskHourEntryGenSvc,
  WorkTaskHourEntry,
  Employee,
  EmployeeGenSvc,
  BigDayBucketSubrange,
  BigDayBucketSubrangeGenSvc,
  WorkTaskGenSvc,
  ScheduledBucketDTO
} from '../../services_autogenerated/generated_services';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { NgForm } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MaskService } from 'src/app/services/mask.service';
import { MessageService } from 'primeng/api';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-task-hours-entry',
  templateUrl: './task-hours-entry.component.html',
  styleUrls: ['./task-hours-entry.component.css']
})
export class TaskHoursEntryComponent implements OnInit {
  @Input() asGoBack: boolean;
  @Input() complete: boolean;
  @Input() customerId: number;
  @Input() quoteNumber: string;
  @Input() task: WellnessTask | WorkTask;
  @Input() unableToComplete: boolean;
  @Input() withRecord: boolean;

  @Output() addHours = new EventEmitter<boolean>();
  @Output() commentId = new EventEmitter<number>();
  @Output() completed = new EventEmitter<boolean>();
  @Output() unableToCompleteSubmitted = new EventEmitter<boolean>();

  title = 'FEEDBACK ON THE JOB';
  totalHours = 0;
  scheduledBucket: ScheduledBucketDTO;
  bigDayBucketSubrange: BigDayBucketSubrange;
  stages: CustomerCommentStage[];
  addingEmployee: boolean;
  employees: Employee[];
  selectedEmployee: Employee;
  unableToCompletePermanently = false;

  currencyMask: any;

  verifyPrice: boolean;

  priceInfo: string = '';

  isTouchscreen: boolean;
  iconMessage = 'Task will not be completed ever (for example, customer decides against service). '
    + 'This will allow the WO to be completed without this task being done.';
  @ViewChild(OverlayPanel) op: OverlayPanel;
  @ViewChild(NgForm) hoursEntryForm: NgForm;

  disableSubmit: boolean = false;

  constructor(private scheduledBucketService: ScheduledBucketGenSvc,
    private wellnessTaskHourEntryService: WellnessTaskHourEntryGenSvc,
    private workTaskHourEntryService: WorkTaskHourEntryGenSvc,
    private customerCommentService: CustomerNoteGenSvc,
    private authHelperService: AuthHelperService,
    private customerCommentStageService: CustomerCommentStageGenSvc,
    private employeeService: EmployeeGenSvc,
    private bigDaySubrangeService: BigDayBucketSubrangeGenSvc,
    private maskService: MaskService,
    private workTaskService: WorkTaskGenSvc,
    private messageService: MessageService,
    private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.currencyMask = this.maskService.currencyMaskNoDecimal;
    if (!this.task.taskHourEntries) { this.task.taskHourEntries = []; }
    if (this.unableToComplete) { this.title = 'REASON JOB NOT COMPLETED'; }
    this.verifyPrice = this.task instanceof WorkTask && !this.task.fixedPrice;
    if (this.verifyPrice) {
      this.task.price = undefined;
    }
    this.setPriceInfo();

    this.customerCommentStageService.get().subscribe(stages => {
      this.stages = stages;
    });

    this.employeeService.getSchedulable().subscribe(employees => this.employees = employees);

    if (this.task.currentBucketId && !this.task.isGoBack) {
      this.scheduledBucketService.get(this.task.currentBucketId).subscribe(bucket => {
        this.scheduledBucket = bucket;

        bucket.scheduledBucketEmployees.forEach(sbEmp => {
          this.addTaskHourEntry(sbEmp.employee);
        });

        this.getHourTotal();
      });
    } else if (this.task.isGoBack && this.task.goBackBucketId) {
      this.scheduledBucketService.get(this.task.goBackBucketId).subscribe(bucket => {
        this.scheduledBucket = bucket;
        bucket.scheduledBucketEmployees.forEach(sbEmp => {
          this.addTaskHourEntry(sbEmp.employee);
        });

        this.getHourTotal();
      });
    } else {
      this.bigDaySubrangeService.get(this.task.currentBigDaySubrangeId).subscribe(subrange => {
        this.bigDayBucketSubrange = subrange;

        subrange.bigDayEmployees.forEach(emp => {
          this.addTaskHourEntry(emp.employee);
        });

        this.getHourTotal();
      });
    }
    this.setIsTouchscreen();
  }

  private addTaskHourEntry(employee: Employee) {
    if (this.task instanceof WellnessTask) {
      // Only add a new task entry if the employee is not in the entries already and the go back status matches
      // Allows duplicate entries for an employee if they are on the original and go back crews
      if (!this.task.taskHourEntries.find(entry => entry.employee.id === employee.id && entry.isGoBack === this.task.isGoBack)) {
        this.task.taskHourEntries = [...this.task.taskHourEntries, new WellnessTaskHourEntry({
          wellnessTaskId: this.task.id,
          employee: employee,
          employeeId: employee.id,
          isGoBack: this.asGoBack
        })];
      }
    } else if (this.task instanceof WorkTask) {
      if (!this.task.taskHourEntries.find(entry => entry.employee.id === employee.id && entry.isGoBack === this.task.isGoBack)) {
        this.task.taskHourEntries = [...this.task.taskHourEntries, new WorkTaskHourEntry({
          workTaskId: this.task.id,
          employee: employee,
          employeeId: employee.id,
          isGoBack: this.asGoBack
        })];
      }
    }
  }

  getHourTotal() {
    this.totalHours = 0;
    (this.task.taskHourEntries as any).forEach(entry => {
      this.totalHours += +entry.hours ? +entry.hours : 0;
    });
  }

  addEmployee() {
    this.addingEmployee = true;
  }

  addNewTaskHourEntry(employee: Employee) {
    this.selectedEmployee = employee;

    this.addTaskHourEntry(employee);
    this.addingEmployee = false;
    this.selectedEmployee = null;
  }

  submit(ngForm: NgForm) {
    this.disableSubmit = true;
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });

    if (this.task.price === undefined || this.task.price === null) {
      this.disableSubmit = false;
      return;
    }

    if (!this.task.fixedPrice && this.task.minPrice && this.task.price < this.task.minPrice) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to update task price, the price must be equal to or greater than the task\'s minimum price.'
      });
      this.hoursEntryForm.controls['price'].setErrors({'belowMin': true});
      this.disableSubmit = false;
      return;
    }

    if (ngForm.valid) {
      if (this.task instanceof WellnessTask) {
        this.wellnessTaskHourEntryService.updateRange(this.task.taskHourEntries).subscribe(res => {
          this.task.taskHourEntries = res;
        });

        this.addComment();
      } else if (this.task instanceof WorkTask) {
        if (this.verifyPrice) {
          this.workTaskService.updatePrice(this.task.id, this.task.price).subscribe(() => {}, err => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to update task price, please undo task completion to try again. Contact support if the error persists.'
            });
          });
        }
        this.workTaskHourEntryService.updateRange(this.task.taskHourEntries).subscribe(res => {
          this.task.taskHourEntries = res;
        });

        this.addComment();
      }
    }
  }

  addComment() {
    this.customerCommentService.add(new CustomerComment({
      comment: this.task.name + ': ' + this.task.jobFeedback,
      employeeId: this.authHelperService.getDecodedAccessToken().employeeId,
      customerId: this.customerId,
      quoteNumber: this.quoteNumber,
      companyWide: false,
      active: true,
      customerCommentStageId: this.stages.find(stage => stage.commentStage === 'Work Order').id,
      customerCommentStage: this.stages.find(stage => stage.commentStage === 'Work Order'),
    })).subscribe(cc => {
      // Emit the customer comment Id so the comment can be deleted if user presses "Unco Complete" button for the task
      if (this.unableToComplete) {
        this.commentId.emit(cc.id);
        this.unableToCompleteSubmitted.emit(this.unableToCompletePermanently);
      } else if (this.complete) {
        this.commentId.emit(cc.id);
        this.completed.emit(this.withRecord);
      } else {
        this.commentId.emit(cc.id);
        this.addHours.emit();
      }
    });
  }

  setIsTouchscreen() {
    // if it claims it's android or ios, great. Otherwise see if it's a touch device.
    // We want to rule out a tablet that moves the mouse cursor by touching.
    this.isTouchscreen = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || (navigator.platform.indexOf('iPhone') !== -1)
    || (navigator.platform.indexOf('iPad') !== -1) || (navigator.platform.indexOf('iPod') !== -1)
    || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
  }

  onInfoClick(ev: MouseEvent) {
    if (this.isTouchscreen) {
      this.op.toggle(ev);
    }
  }

  private setPriceInfo() {
    if (this.task instanceof WorkTask && !this.task.fixedPrice) {
      if (this.task.hourlyRate) {
        this.priceInfo += this.currencyPipe.transform(this.task.hourlyRate, 'USD') + ' per hour';
      }

      if (this.task.hourlyRate && (this.task.minPrice || this.task.maxPrice)) {
        this.priceInfo += ' - ';
      }

      if (this.task.minPrice) {
        this.priceInfo += this.currencyPipe.transform(this.task.minPrice, 'USD');
      }

      if (this.task.minPrice && this.task.maxPrice) {
        this.priceInfo += ' to ';
      }

      if (this.task.maxPrice) {
        this.priceInfo += this.currencyPipe.transform(this.task.maxPrice, 'USD');
      }
    }
  }
}
