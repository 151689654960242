// This is not comprehensive; I just pulled in what I wanted
var GeocodingResponse = /** @class */ (function () {
    function GeocodingResponse() {
    }
    return GeocodingResponse;
}());
export { GeocodingResponse };
var Results = /** @class */ (function () {
    function Results() {
    }
    return Results;
}());
export { Results };
var Locations = /** @class */ (function () {
    function Locations() {
    }
    Object.defineProperty(Locations.prototype, "order", {
        get: function () {
            if (this.geocodeQuality === 'POINT')
                return 0;
            else if (this.geocodeQuality === 'ADDRESS')
                return 1;
            else if (this.geocodeQuality === 'ZIP')
                return 2;
            else if (this.geocodeQuality === 'ZIP_EXTENDED')
                return 3;
            else if (this.geocodeQuality === 'STREET')
                return 4;
            else if (this.geocodeQuality === 'CITY')
                return 5;
            else if (this.geocodeQuality === 'NEIGHBORHOOD')
                return 6;
            else if (this.geocodeQuality === 'INTERSECTION')
                return 7;
            else
                return 8;
        },
        enumerable: true,
        configurable: true
    });
    return Locations;
}());
export { Locations };
var ColorResponse = /** @class */ (function () {
    function ColorResponse(geoColor, textColor) {
        this.geoColor = geoColor;
        this.textColor = textColor;
    }
    return ColorResponse;
}());
export { ColorResponse };
