<app-work-order-filter-bar
    [isDayView]="selectedView == 'day'"
    [typeOfWorkOrders]="isWellnessOnly ? 'wellness' : 'work'"
    [allWorkOrders]="isWellnessOnly ? wellnessWorkOrders : workWorkOrders"
    [rms]="rms"
    (OnWoFilteringFinished)="OnWoFilteringFinished($event)"
    [useSearchDelay]="false"
    >
</app-work-order-filter-bar>

<div class="showColorBar">
    <span style="float: left">
        <i class="fa fa-play"  *ngIf="timerPaused" (click)="startTimer()"></i>
        <i class="fa fa-pause" *ngIf="!timerPaused" (click)="pauseTimer()"></i>
        {{timeLeft | time}} until refresh
    </span> 
    <span *ngIf="selectedView == 'day'">Show Zip Code Colors</span>
    <span *ngIf="selectedView == 'week'">Zip</span>
    <p-checkbox binary="true" name="shouldShowLeftColor" [(ngModel)]="shouldShowLeftColor"></p-checkbox>
    <span *ngIf="selectedView == 'day'">Show Priority Colors</span>
    <span *ngIf="selectedView == 'week'">Priority</span>
    <p-checkbox binary="true" name="shouldShowRightColor" [(ngModel)]="shouldShowRightColor"></p-checkbox>
</div>

<div *ngIf="isWellnessOnly"
    class="p-grid" 
    id="workOrdersPanelList" 
    cdkDropList 
    [cdkDropListData]="filteredWellnessWorkOrders"
    [cdkDropListConnectedTo]="dragAndDropService.allCDKWorkOrderListIds" 
    (cdkDropListDropped)="drop($event)"
    cdkDropListSortingDisabled="true">
        <div *ngIf="isLoadingWOs">
            <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
        </div>
        <div *ngIf="!isLoadingWOs && filteredWellnessWorkOrders.length == 0">
            No wellness work orders to schedule at this time.
        </div>
        <app-wellness-work-order-card 
            [ngClass]="{'custom-p-col-lg p-md-4 p-sm-6': selectedView == 'day', 'p-col-12': selectedView == 'week'}"
            *ngFor="let workOrder of filteredWellnessWorkOrders" 
            [workOrder]="workOrder" 
            [showScheduled]="false"
            [showMap]="false"
            (dragStartEvent)="taskDragged($event)"
            (taskDragEndEvent)="taskDragEnd()"
            cdkDrag
            (cdkDragStarted)="dragStart(workOrder, $event)" 
            (cdkDragEnded)="dragEnd(workOrder)"
            [cdkDragDisabled]="shouldDisableWODrag(workOrder)"
            [shouldShowLeftColor]="shouldShowLeftColor"
            [shouldShowRightColor]="shouldShowRightColor"></app-wellness-work-order-card>
</div>

<div  *ngIf="isTreeWorkOnly"
    class="p-grid" 
    id="workOrdersPanelList" 
    cdkDropList 
    [cdkDropListData]="filteredWorkWorkOrders"
    [cdkDropListConnectedTo]="dragAndDropService.allCDKWorkOrderListIds" 
    (cdkDropListDropped)="drop($event)"
    cdkDropListSortingDisabled="true">
    <div *ngIf="isLoadingWOs">
        <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
    </div>
    <div *ngIf="!isLoadingWOs && filteredWorkWorkOrders.length == 0">
        No tree work work orders to schedule at this time.
    </div>
    <app-work-work-order-card 
        [ngClass]="{'custom-p-col-lg p-md-4 p-sm-6': selectedView == 'day', 'p-col-12': selectedView == 'week'}"
        *ngFor="let workOrder of filteredWorkWorkOrders" 
        [workOrder]="workOrder" 
        [showScheduled]="false"
        (dragStartEvent)="taskDragged($event)"
        (taskDragEndEvent)="taskDragEnd()"
        cdkDrag
        (cdkDragStarted)="dragStart(workOrder, $event)" 
        (cdkDragEnded)="dragEnd(workOrder)"
        [cdkDragDisabled]="shouldDisableWODrag(workOrder)"
        [shouldShowLeftColor]="shouldShowLeftColor"
        [shouldShowRightColor]="shouldShowRightColor"></app-work-work-order-card>
</div>