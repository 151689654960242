import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ChemicalApplicationMethod, ChemicalApplicationMethodGenSvc } from '../../services_autogenerated/generated_services';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-chemical-method-maintenance',
  templateUrl: './chemical-method-maintenance.component.html',
  styleUrls: ['./chemical-method-maintenance.component.css']
})
export class ChemicalMethodMaintenanceComponent implements OnInit {
  @ViewChild('methodInput') methodInput: ElementRef;

  cols: any[];
  method: ChemicalApplicationMethod = new ChemicalApplicationMethod();
  methods: ChemicalApplicationMethod[] = [];
  saving: boolean;

  constructor(
    private methodService: ChemicalApplicationMethodGenSvc,
    private messageService: MessageService
  ) {
    this.cols = [
      { field: 'method', header: 'Method Name' },
      { header: '', width: '100px' }
    ];
  }

  ngOnInit() {
    this.methodService.getAll().subscribe(methods => {
      this.methods = methods;
    });
  }

  clear(ngForm: NgForm) {
    this.method = new ChemicalApplicationMethod();
    this.emptyInputs();
    ngForm.resetForm();
  }

  editChemicalApplicationMethod(method: ChemicalApplicationMethod) {
    this.method.method = method.method;
    this.method.active = method.active;
    this.method.id = method.id;

    this.methodInput.nativeElement.focus();
  }

  deleteChemicalApplicationMethod(method) {
    // remove method
    if (confirm('Are you sure you want to remove this record?')) {
      this.methodService.delete(method.id).subscribe(ok => {
        this.methods = this.methods.filter(c => c.id !== method.id);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not save the method, please check that the method exists and retry.'
        });
      });
    }
  }

  saveChemicalApplicationMethod(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      // Edit
      if (this.method.id) {
        this.methodService.update(this.method, this.method.id.toString()).subscribe(method => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the method, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      } else { // New Save
        this.method.active = true;
        this.methodService.add(this.method).subscribe(method => {
          this.doneSaving(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the method, please check that all fields are valid and retry.'
          });
          this.doneSaving(ngForm);
        });
      }
    }
  }

  doneSaving(ngForm: NgForm) {
    this.saving = false;
    ngForm.resetForm();
    this.emptyInputs();
    this.methodService.getAll().subscribe(methods => {
      this.methods = methods; // This is not a good idea but turbo table wont refresh. Less than 100 items in grid. Moving on.
    });
  }

  emptyInputs() {
    this.method = new ChemicalApplicationMethod();
    this.method.method = null;
    this.method.active = null;
    this.method.id = null;
  }
}
