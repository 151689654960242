/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-area.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../node_modules/angular2-signaturepad/signature-pad.ngfactory";
import * as i4 from "angular2-signaturepad/signature-pad";
import * as i5 from "@angular/common";
import * as i6 from "./signature-area.component";
var styles_SignatureAreaComponent = [i0.styles];
var RenderType_SignatureAreaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureAreaComponent, data: {} });
export { RenderType_SignatureAreaComponent as RenderType_SignatureAreaComponent };
function View_SignatureAreaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "ui-button-rounded ui-button-secondary hidden-print clear-btn"], ["icon", "pi pi-times"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSignature() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "pi pi-times"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SignatureAreaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Signature is required "]))], null, null); }
export function View_SignatureAreaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), i1.ɵqud(402653184, 2, { signaturePad: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["container", 1]], null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " Signature "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you are using a mobile device, please sign using your finger. If on a computer, please sign using your mouse. "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "signature-pad", [["class", "sigPadWrapper"], ["style", "width:50%;"]], null, [[null, "onBeginEvent"], [null, "onEndEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onBeginEvent" === en)) {
        var pd_0 = (_co.drawStart() !== false);
        ad = (pd_0 && ad);
    } if (("onEndEvent" === en)) {
        var pd_1 = (_co.drawComplete() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SignaturePad_0, i3.RenderType_SignaturePad)), i1.ɵdid(8, 1097728, [[2, 4]], 0, i4.SignaturePad, [i1.ElementRef], { options: [0, "options"] }, { onBeginEvent: "onBeginEvent", onEndEvent: "onEndEvent" }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureAreaComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureAreaComponent_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.signaturePadOptions; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.enabled; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.signatureError; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 4, 0, currVal_0); }); }
export function View_SignatureAreaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature-area", [], null, null, null, View_SignatureAreaComponent_0, RenderType_SignatureAreaComponent)), i1.ɵdid(1, 4308992, null, 0, i6.SignatureAreaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureAreaComponentNgFactory = i1.ɵccf("app-signature-area", i6.SignatureAreaComponent, View_SignatureAreaComponent_Host_0, { signature: "signature", name: "name", isAlwaysDisabled: "isAlwaysDisabled", signatureError: "signatureError" }, { currentSignature: "currentSignature" }, []);
export { SignatureAreaComponentNgFactory as SignatureAreaComponentNgFactory };
