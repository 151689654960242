import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PeopleFilterPipe } from '../../pipes/people-filter.pipe';
import { EquipmentFilterPipe } from 'src/app/pipes/equipment-filter.pipe';
import { CrewFilterPipe } from '../../pipes/crew-filter.pipe';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

import {
  EquipmentGenSvc,
  CrewGenSvc,
  EmployeeGenSvc,
  ScheduledBucketEquipment,
  ScheduledBucketEmployee,
  ScheduledBucketGenSvc,
  ScheduledBucketDTO
} from '../../services_autogenerated/generated_services';
import { Equipment, Crew, Employee } from '../../services_autogenerated/generated_services';
import { CrewsDateRangeService } from 'src/app/services/crews-date-range.service';
import { IDroppable } from '../../models/IDroppable';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';

@Component({
  selector: 'app-crews-and-equipment-panel',
  templateUrl: './crews-and-equipment-panel.component.html',
  styleUrls: ['./crews-and-equipment-panel.component.css'],
  providers: [PeopleFilterPipe, EquipmentFilterPipe, CrewFilterPipe]
})
export class CrewsAndEquipmentPanelComponent implements OnInit, IDroppable {
  @Input() selectedView: string;
  allEquipment: Equipment[];
  allEmployees: Employee[];
  allCrews: Crew[];
  @Input() allCDKEmployeeListIds: string[];
  @Input() allCDKEquipmentListIds: string[];
  searchTerm = '';
  @Input() showRecommended = true;

  constructor(private equipmentService: EquipmentGenSvc,
    private employeeService: EmployeeGenSvc,
    public dragAndDropService: DragAndDropService,
    public crewDateRangeService: CrewsDateRangeService,
    private scheduledItemBucketService: ScheduledBucketGenSvc,
    private messageService: MessageWrapperService) { }

  ngOnInit() {

    this.equipmentService.getAll().subscribe(equipment => {
      this.allEquipment = equipment;
    });

    this.employeeService.getSchedulable().subscribe(employees => {
      this.allEmployees = employees;
    });

    this.crewDateRangeService.getCrewUpdater().subscribe(crews => this.onDateRangeUpdate(crews));
  }

  onDateRangeUpdate(crews: Crew[]) {
    this.allCrews = crews;
  }

  drop(event: CdkDragDrop<ScheduledBucketEquipment[] | ScheduledBucketEmployee[]>) {
    console.log('DROP ONTO CREWS AND EQUIPMENT PANEL');
    //////////////////////////////////////////////////////////////////////////////////////////
    // Un-schedule the equipment/employee
    //////////////////////////////////////////////////////////////////////////////////////////
    if (event.isPointerOverContainer && event.previousContainer !== event.container) {
      const thingToUnschedule = event.previousContainer.data[event.previousIndex];

      const buckets = this.dragAndDropService.bucketListDayList.map(x => x.bucketList).reduce((acc, curr) => acc.concat(curr));
      let matchingBucket: ScheduledBucketDTO;
      if (thingToUnschedule instanceof ScheduledBucketEquipment) {
        matchingBucket = buckets.find(bucket => bucket.id === thingToUnschedule.scheduledBucketId);

          this.scheduledItemBucketService.removeEquipmentFromBucket(thingToUnschedule.equipmentId, matchingBucket.id).subscribe(() => {
          }, error => {
            // show user error, re-add unscheduled thing, update UI
            this.messageService.addErrorMessage('Unable to delete the equipment.', error);
            matchingBucket.scheduledBucketEquipment.splice(event.previousIndex, 0, thingToUnschedule);
            this.dragAndDropService.emitBucketUpdate(matchingBucket);
          });
      } else {
        matchingBucket = buckets.find(bucket => bucket.id === thingToUnschedule.scheduledBucketId);

        this.scheduledItemBucketService.removeEmployeeFromBucket(thingToUnschedule.employeeId, matchingBucket.id).subscribe(() => {
          this.dragAndDropService.emitBucketUpdate(matchingBucket);
        }, error => {
          // show user error, re-add unscheduled thing, update UI
          this.messageService.addErrorMessage('Unable to delete the employee.', error);
          matchingBucket.scheduledBucketEmployees.splice(event.previousIndex, 0, thingToUnschedule);
          this.dragAndDropService.emitBucketUpdate(matchingBucket);
        });
      }

      // This just updates all the indices in javascript
      event.previousContainer.data.splice(event.previousIndex, 1);
      this.dragAndDropService.emitBucketUpdate(matchingBucket);
    }
  }
}
