import { OnInit } from '@angular/core';
import { EquipmentType, Equipment, EquipmentSkill, EquipmentServiceRecord } from '../../services_autogenerated/generated_services';
import { SkillGenSvc, EquipmentGenSvc, EquipmentTypeGenSvc } from '../../services_autogenerated/generated_services';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SpecificationService } from 'src/app/services/specification.service';
import { MaskService } from 'src/app/services/mask.service';
var EquipmentMaintenanceComponent = /** @class */ (function () {
    function EquipmentMaintenanceComponent(skillService, equipmentService, equipmentTypeService, route, router, messageService, specificationService, maskService) {
        var _this = this;
        this.skillService = skillService;
        this.equipmentService = equipmentService;
        this.equipmentTypeService = equipmentTypeService;
        this.route = route;
        this.router = router;
        this.messageService = messageService;
        this.specificationService = specificationService;
        this.maskService = maskService;
        // equipmentAlternativeOptions: EquipmentAlternative[];
        this.equipmentTypeOptions = [];
        this.disableDelete = false;
        this.additionalInfo = false;
        this.doneSaving = function () {
            _this.router.navigateByUrl('/equipmentList');
            _this.saving = false;
        };
        this.statuses = [
            { label: 'In Service', value: true },
            { label: 'Out of Service', value: false }
        ];
        this.cols = [
            { field: 'description', header: 'Description', width: '35%' },
            { field: 'dateServicePerformed', header: 'Date Service Performed', type: Date, width: '14%' },
            { field: 'mileage', header: 'Mileage', type: Number, width: '14%' },
            { field: 'inactiveDateFrom', header: 'Inactive From', type: Date, width: '14%' },
            { field: 'inactiveDateTo', header: 'Inactive To', type: Date, width: '14%' },
            { field: '', header: 'Delete', type: Date, width: '9%' },
        ];
    }
    EquipmentMaintenanceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id = +this.route.snapshot.paramMap.get('id');
        this.currencyMask = this.maskService.currencyMask;
        this.equipmentTypeService.getActive().subscribe(function (et) {
            _this.equipmentTypeOptions = et;
        });
        Observable.forkJoin(this.skillService.getAll(), this.equipmentService.getAll())
            .subscribe(function (_a) {
            var skills = _a[0], allEquipment = _a[1];
            if (id) {
                _this.equipment = allEquipment.filter(function (x) { return x.id === id; })[0];
            }
            else {
                _this.disableDelete = true;
                var eq = new Equipment();
                eq.active = true;
                // Even though eqp alternatives has been removed, this line is needed for the save to work.
                eq.equipmentAlternatives = [];
                eq.equipmentSkills = [];
                eq.equipmentServiceRecords = [];
                _this.equipment = eq;
            }
            // this.equipmentAlternativeOptions = this.equipment.equipmentAlternatives;
            // const otherAlternatives = allEquipment.filter(e => !this.equipment.equipmentAlternatives.some(ea => ea.equipmentId === e.id))
            //   .filter(e => e.id !== this.equipment.id) // Equipment can't be alternative to itself
            //   .map(e => {
            //     const newEA = new EquipmentAlternative();
            //     newEA.alternative = e;
            //     newEA.alternativeId = e.id;
            //     newEA.equipmentId = id; // may be null if new equipment
            //     return newEA;
            //   });
            // this.equipmentAlternativeOptions = this.equipmentAlternativeOptions.concat(otherAlternatives);
            _this.equipmentSkillOptions = _this.equipment.equipmentSkills; // start with existing skills
            // add other options from skills they could pick from
            var otherSkills = skills.filter(function (r) { return !_this.equipment.equipmentSkills.some(function (es) { return es.skillId === r.id; }); });
            _this.equipmentSkillOptions = _this.equipmentSkillOptions.concat(otherSkills.map(function (s) {
                var newSO = new EquipmentSkill();
                newSO.skill = s;
                newSO.skillId = s.id;
                newSO.equipmentId = id; // may be null if new equipment
                return newSO;
            })).sort(function (a, b) { return a.skill.name.localeCompare(b.skill.name); });
            _this.setEquipmentStatus();
        });
    };
    EquipmentMaintenanceComponent.prototype.save = function (ngForm) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (ngForm.valid) {
            this.saving = true;
            if (this.equipment.id) {
                this.equipmentService.update(this.equipment, this.equipment.id.toString())
                    .subscribe(function () {
                    _this.doneSaving();
                }, function (error) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error Saving',
                        detail: 'Could not save the equipment, please check that all fields are valid and retry.'
                    });
                    _this.doneSaving();
                });
            }
            else {
                this.equipmentService.add(this.equipment)
                    .subscribe(function () {
                    _this.doneSaving();
                }, function (error) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error Saving',
                        detail: 'Could not save the equipment, please check that all fields are valid and retry.'
                    });
                    _this.doneSaving();
                });
            }
        }
    };
    EquipmentMaintenanceComponent.prototype.cancel = function () {
        this.router.navigateByUrl('/equipmentList');
    };
    EquipmentMaintenanceComponent.prototype.delete = function () {
        var _this = this;
        if (confirm('Are you sure you want to permanently delete this piece of equipment?')) {
            this.equipmentService.delete(this.equipment.id).subscribe(function () {
                _this.router.navigateByUrl('/equipmentList');
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Deleting',
                    detail: 'Could not delete the employee, please check that the employee exists and retry.'
                });
            });
        }
    };
    // TODO change this to ESR
    // if the from date changes, validate the To Date
    EquipmentMaintenanceComponent.prototype.onESRFromDateChange = function (form, rowIndex) {
        this.setEquipmentStatus();
        setTimeout(function () {
            form.controls['inactiveDateTo' + rowIndex].updateValueAndValidity();
        }, 200);
    };
    EquipmentMaintenanceComponent.prototype.addNewServiceRecord = function () {
        var esr = new EquipmentServiceRecord();
        esr.equipmentId = this.equipment.id;
        this.equipment.equipmentServiceRecords.push(esr);
        this.setEquipmentStatus();
    };
    EquipmentMaintenanceComponent.prototype.removeServiceRecord = function (rowIndex) {
        this.equipment.equipmentServiceRecords.splice(rowIndex, 1);
    };
    EquipmentMaintenanceComponent.prototype.setEquipmentStatus = function () {
        if (this.specificationService.EquipmentInService(this.equipment)) {
            this.equipmentStatus = 'In Service';
        }
        else {
            this.equipmentStatus = 'Out of Service';
        }
    };
    return EquipmentMaintenanceComponent;
}());
export { EquipmentMaintenanceComponent };
