import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NotificationsGenSvc, QuoteRequestGenSvc, QuoteRequestType } from '../services_autogenerated/generated_services';
import * as i0 from "@angular/core";
import * as i1 from "../services_autogenerated/generated_services";
import * as i2 from "primeng/components/common/messageservice";
import * as i3 from "@angular/router";
var QuoteRequestService = /** @class */ (function () {
    function QuoteRequestService(notificationService, messageService, quoteRequestService, router) {
        this.notificationService = notificationService;
        this.messageService = messageService;
        this.quoteRequestService = quoteRequestService;
        this.router = router;
    }
    QuoteRequestService.prototype.verifyQuoteRequestAndBuildQuote = function (quoteRequest) {
        var _this = this;
        if (confirm('This will build the quote for this Quote Request')) {
            if (quoteRequest && quoteRequest.customerId && quoteRequest.addressId && quoteRequest.description) {
                this.quoteRequestService.convertToQuote(quoteRequest).subscribe(function (quote) {
                    _this.router.navigateByUrl('/quoteWorkOrderMaintenance/' + quote.id);
                });
            }
            else {
                alert('Please verify that this quote request has a customer, address, and description before trying again.');
            }
        }
    };
    QuoteRequestService.prototype.onMyWay = function (quoteRequest) {
        var _this = this;
        if (confirm('This will send the customer an email or SMS' +
            ' message letting them know you are on your way to build their quote. Click “OK” to proceed with this notification to the customer.')) {
            if (quoteRequest.quoteRequestType === QuoteRequestType.Firmtime) {
                this.notificationService.sendFirmtimeOnWayNotification(quoteRequest.id).subscribe(function () {
                    _this.messageService.add({
                        severity: 'success',
                        summary: 'Message Sent',
                        detail: 'Customer was notified Russell Tree is en route.'
                    });
                }, function (error) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error Sending Message',
                        detail: 'Could not enqueue message to be sent. Please try again.'
                    });
                });
            }
            else {
                this.notificationService.sendAnytimeOnWayNotification(quoteRequest.id).subscribe(function () {
                    _this.messageService.add({
                        severity: 'success',
                        summary: 'Message Sent',
                        detail: 'Customer was notified Russell Tree is en route.'
                    });
                }, function (error) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error Sending Message',
                        detail: 'Could not enqueue message to be sent. Please try again.'
                    });
                });
            }
        }
    };
    QuoteRequestService.prototype.getQuoteRequestServicesRequested = function (quoteRequest) {
        var quoteRequestItems = '';
        quoteRequestItems = this.addItemToQuoteRequestItems('Pruning', quoteRequest.needsPruning, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Removal', quoteRequest.needsRemoval, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Cabling & Bracing', quoteRequest.needsCablingAndBracing, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Tree Lightning Protection', quoteRequest.needsLightningProtection, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Stump Removal', quoteRequest.needsStumpRemoval, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Lot Clearing & Forestry Mower Services', quoteRequest.needsLotClearingAndForestryMower, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Municipal Tree Services', quoteRequest.needsMunicipalTreeServices, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Commerical Tree Services', quoteRequest.needsCommercialTreeServices, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Fertilization', quoteRequest.needsFertilization, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Insect/Disease Control', quoteRequest.needsInsectOrDiseaseControl, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Integrated Tree Health Management', quoteRequest.needsHealthManagement, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Root Zone Management', quoteRequest.needsRootZoneManagement, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Landscape Inspections', quoteRequest.needsLandscapeInspection, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Tree Risk Assessment', quoteRequest.needsTreeRiskAssessment, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Pre-Construction Tree Preservation Consulting', quoteRequest.needsPreConstructionTreeConsulting, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Tree Mapping', quoteRequest.needsTreeMapping, quoteRequestItems);
        quoteRequestItems = this.addItemToQuoteRequestItems('Tree Recommendations', quoteRequest.needsTreeRecommendations, quoteRequestItems);
        return quoteRequestItems;
    };
    QuoteRequestService.prototype.addItemToQuoteRequestItems = function (value, add, quoteRequestItems) {
        // If you need to add the value
        if (add) {
            // Check if something is already in the string and add value with comma
            if (quoteRequestItems) {
                quoteRequestItems += ", " + value;
            }
            else {
                // Otherwise just set it to the value with no comma
                quoteRequestItems += " " + value;
            }
        }
        return quoteRequestItems;
    };
    QuoteRequestService.ngInjectableDef = i0.defineInjectable({ factory: function QuoteRequestService_Factory() { return new QuoteRequestService(i0.inject(i1.NotificationsGenSvc), i0.inject(i2.MessageService), i0.inject(i1.QuoteRequestGenSvc), i0.inject(i3.Router)); }, token: QuoteRequestService, providedIn: "root" });
    return QuoteRequestService;
}());
export { QuoteRequestService };
