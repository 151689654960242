<div [ngClass]="{ 'grayOverlay': grayOut }">
  <p-card class="bucket-card" [ngClass]="{'crew-invalid': bucketErrors.length > 0 }">
    <p-header>
      {{bucketTitle}}

      <div *ngIf="bucketErrors.length > 0" style="float: right;">
        <i class="pi pi-exclamation-triangle" (mouseenter)="errorPanel.show($event)" (mouseleave)="errorPanel.hide($event)" (click)="errorPanel.toggle($event)"></i>
        <p-overlayPanel #errorPanel [appendTo]="'body'">
          <div *ngFor="let error of bucketErrors">
            {{error}}
          </div>
        </p-overlayPanel>
      </div>
    </p-header>

    {{employeeList}}

    <div class="toggle-tasks">
      <div *ngIf="isCollapsed">
        <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span"> + </span>
      </div>
      <div *ngIf="!isCollapsed">
        <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span"> - </span>
      </div>
      <div [ngbCollapse]="isCollapsed" class="crew-work-orders">
        <div [id]="'bucketEquipmentList-' + bucket.id"
          [cdkDropListData]="bucket.scheduledBucketEquipment"
          [cdkDropListConnectedTo]="dragAndDropService.allCDKEquipmentListIds"
          cdkDropList
          (cdkDropListDropped)="drop($event)">
          <span *ngIf="bucket.scheduledBucketEquipment">Equipment</span>
          <div *ngFor="let bucketEquipment of bucket.scheduledBucketEquipment"
            class="inner-card"
            cdkDrag
            (cdkDragStarted)="dragStart($event)">
            <app-equipment-card [equipment]="bucketEquipment.equipment" [deleteable]="true" (deleted)="deleteEquipmentFromBucket($event)"></app-equipment-card>
          </div>
          <div *ngIf="bucket.scheduledBucketEquipment.length == 0" class="inner-card drag-placeholder">
            <p-card>Drag Equipment Here</p-card>
          </div>
        </div>
        <div [id]="'bucketEmployeeList-' + bucket.id"
          cdkDropList
          [cdkDropListData]="bucket.scheduledBucketEmployees"
          [cdkDropListConnectedTo]="dragAndDropService.allCDKEmployeeListIds"
          (cdkDropListDropped)="drop($event)">
          <span *ngIf="bucket.scheduledBucketEmployees">Employees</span>
          <div *ngFor="let bucketEmployee of bucket.scheduledBucketEmployees"
            class="inner-card"
            (cdkDragStarted)="dragStart($event)"
            cdkDrag>
            <app-employee-card [employee]="bucketEmployee.employee" [deleteable]="true" (deleted)="deleteEmployeeFromBucket($event)"></app-employee-card>
          </div>
          <div *ngIf="bucket.scheduledBucketEmployees.length == 0" class="inner-card drag-placeholder">
            <p-card>Drag Person Here</p-card>
          </div>
        </div>
        <span>Work Orders</span>
        <div [id]="'bucketWorkOrderList-' + bucket.id"
          style="padding-bottom: 5px;"
          cdkDropList
          [cdkDropListData]="bucket.scheduledBucketWellnessWorkOrders"
          [cdkDropListConnectedTo]="dragAndDropService.allCDKWorkOrderListIds"
          (cdkDropListDropped)="drop($event)"
          *ngIf="isWellness">
          <div *ngFor="let bucketWorkOrder of bucket.scheduledBucketWellnessWorkOrders"
            class="inner-card"
            (cdkDragStarted)="dragStart(bucketWorkOrder.wellnessWorkOrder)"
            cdkDrag
            (cdkDragEnded)="dragWOEnd()"
            (cdkDragEnded)="dragWOEnd()"
            [cdkDragDisabled]="disableDrag(bucketWorkOrder)">
            <app-scheduled-bucket-wellness-work-order-card [showScheduled]="true"
              [bucket]="bucket"
              [bucketId]="bucket.id"
              [workOrder]="bucketWorkOrder.wellnessWorkOrder"
              (dragStartEvent)="dragStart($event)"
              (taskDragEndEvent)="taskDragEnd()"></app-scheduled-bucket-wellness-work-order-card>
          </div>
          <div *ngIf="bucket.scheduledBucketWellnessWorkOrders.length == 0" class="inner-card drag-placeholder">
            <p-card>Drag Work Order Here</p-card>
          </div>
        </div>

        <div [id]="'bucketWorkOrderList-' + bucket.id"
          style="padding-bottom: 5px;"
          cdkDropList
          [cdkDropListData]="bucket.scheduledBucketWorkWorkOrders"
          [cdkDropListConnectedTo]="dragAndDropService.allCDKWorkOrderListIds"
          (cdkDropListDropped)="drop($event)"
          *ngIf="isWork">
          <div *ngFor="let bucketWorkOrder of bucket.scheduledBucketWorkWorkOrders"
            class="inner-card"
            (cdkDragStarted)="dragStart(bucketWorkOrder.workWorkOrder)"
            cdkDrag
            (cdkDragEnded)="dragWOEnd()"
            [cdkDragDisabled]="disableDrag(bucketWorkOrder)">
            <app-scheduled-bucket-work-work-order-card [showScheduled]="true"
              [bucket]="bucket"
              [bucketId]="bucket.id"
              [workOrder]="bucketWorkOrder.workWorkOrder"
              (dragStartEvent)="dragStart($event)"
              (taskDragEndEvent)="taskDragEnd()"></app-scheduled-bucket-work-work-order-card>
          </div>
          <div *ngIf="bucket.scheduledBucketWorkWorkOrders.length == 0" class="inner-card drag-placeholder">
            <p-card>Drag Work Order Here</p-card>
          </div>
        </div>
      </div>
    </div>
    &nbsp;
    <ngb-progressbar
        [type]="type"
        [value]="hours"
        height="20px"
    >{{taskHours}} / {{totalHours}} Hrs</ngb-progressbar>
  </p-card>
</div>
