<form id="settingForm" [formGroup]="settingForm" (ngSubmit)="save(settingForm)" class="setting-container">
  <span style="min-width: 290px; max-width: 290px; margin-top: auto; margin-bottom: auto;">{{setting.name}}: </span>
  <span>
    <input 
      pInputText 
      name="value"
      [type]="setting.type"
      formControlName="value"
      min="0" 
      pKeyFilter="pint" 
      class="inputTextBox" 
      [disabled]="isLoading"
    />
  </span>
  <span>
    <button
      pButton 
      type="submit" 
      class="ui-button-success ui-button-raised ui-button-success buttonStyle" 
      [disabled]="!settingForm.valid || isLoading"
      label="Save">
    </button>
    <span *ngIf="isLoading" style="vertical-align: middle; margin-left: 5px;">
      <p-progressSpinner [style]="{width: '26px', height: '26px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"></p-progressSpinner>
    </span>
  </span>
  <br/>
  <span *ngIf="value.invalid && (value.dirty || value.touched)">
    <span *ngIf="value.errors.required">
      <p-message severity="error" [text]="'A value is required'" class="setting-message"></p-message>
    </span>
  </span>
</form>
<hr/>