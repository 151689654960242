import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationsGenSvc, StripeGenSvc, WellnessWorkOrder, WorkOrderInvoicingViewModel, WorkWorkOrder } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-payment-options-td',
  templateUrl: './payment-options-td.component.html',
  styleUrls: ['./payment-options-td.component.css']
})
export class PaymentOptionsTdComponent implements OnInit {
  @Input() workOrder: WorkOrderInvoicingViewModel;

  pdfReceiptCount = 0;
  nonPdfReceiptCount = 0;
  isSending: boolean = false;

  @Output() invoicesEvent = new EventEmitter<WorkOrderInvoicingViewModel>();
  @Output() addCashEvent = new EventEmitter<WorkOrderInvoicingViewModel>();
  @Output() chargeInvoiceEvent = new EventEmitter<WorkOrderInvoicingViewModel>();
  @Output() refundEvent = new EventEmitter<WorkOrderInvoicingViewModel>();
  @Output() finalizeEvent = new EventEmitter<WorkOrderInvoicingViewModel>();

  constructor(private customStripeService: StripeGenSvc,
              private messageService: MessageService,
              private notificationService: NotificationsGenSvc) { }

  ngOnInit() {
    if (this.workOrder.workOrderBilling && this.workOrder.workOrderBilling.receiptURLs.length > 0) {
      this.workOrder.workOrderBilling.receiptURLs.forEach((receiptLink) => {
        if (this.isPDFReceipt(receiptLink)) {
          // receipt link
          this.pdfReceiptCount++;
        } else {
          // invoice PDF
          this.nonPdfReceiptCount++;
        }
      });
    }
  }

  canCollectCash() {
    return this.workOrder.amountDue && (this.workOrder.amountDue > 0 || (this.workOrder.amountDue && (this.workOrder.wOStatus === 'Partially Complete' || this.workOrder.showAsPartiallyComplete)));
  }

  canChargeCard() {
    return this.workOrder.paymentStatus !== 'Paid' && (this.canCollectCash());
  }

  canCloseOut() {
    return this.workOrder.paymentStatus === 'Not Paid' && this.workOrder.amountDue === 0 && (this.workOrder.wOStatus === 'Partially Complete' || this.workOrder.showAsPartiallyComplete);
  }

  openInvoices() {
    this.invoicesEvent.emit(this.workOrder);
  }

  addCash() {
    this.addCashEvent.emit(this.workOrder);
  }

  chargeInvoice() {
    if (this.workOrder.hasHourlyRateTask) {
      alert('WO has incomplete hourly rate task. It cannot be billed until the task is complete and the price is finalized.');
      return;
    }
    this.chargeInvoiceEvent.emit(this.workOrder);
  }

  finalize() {
    if (this.workOrder.hasHourlyRateTask) {
      alert('WO has incomplete hourly rate task. It cannot be billed until the task is complete and the price is finalized.');
      return;
    }
    this.finalizeEvent.emit(this.workOrder);
  }

  resendInvoice() {
    this.isSending = true;
    this.notificationService.resendWorkOrderCompleteEmail(this.workOrder.workOrderId, this.workOrder.serviceType).subscribe(res => {
      this.isSending = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Email sent successfully.'
      });
    }, err => {
      this.isSending = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Email failed, try again or manually send receipt to customer.'
      });
    });
  }

  openNonPDFReceipts() {
    if (this.workOrder.workOrderBilling.receiptURLs.length > 0) {
      this.workOrder.workOrderBilling.receiptURLs.forEach((receiptLink) => {
        if (!this.isPDFReceipt(receiptLink)) {
          window.open(receiptLink, '_blank');
        }
      });
    }
  }

  openPDFReceipts() {
    if (this.workOrder.workOrderBilling.receiptURLs.length > 0) {
      this.workOrder.workOrderBilling.receiptURLs.forEach((receiptLink) => {
        if (this.isPDFReceipt(receiptLink)) {
          window.open(receiptLink, '_blank');
        }
      });
    }
  }

  sendReceiptsToCustomer() {
    // prevent spamming button
    if (!this.isSending) {
      this.isSending = true;
      const type = this.workOrder.serviceType === 'Tree Wellness' ? 'Wellness' : 'Work';
      this.customStripeService.emailReceiptsForWorkOrder(this.workOrder.workOrderId, type).subscribe(res => {
        this.isSending = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Email successfull sent'
        });
      }, err => {
        this.isSending = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Email failed, try again or manually send receipt to customer.'
        });
      });
    }
  }

  isPDFReceipt(link: string): boolean {
    if (link.indexOf('receipts') >= 0) {
      return false;
    } else {
      return true;
    }
  }

  openRefund() {
    this.refundEvent.emit(this.workOrder);
  }
}
