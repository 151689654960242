import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TreeType, TreeTypeGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-tree-type-maintenance',
  templateUrl: './tree-type-maintenance.component.html',
  styleUrls: ['./tree-type-maintenance.component.css']
})
export class TreeTypeMaintenanceComponent implements OnInit {
  @ViewChild('commonNameInput') commonNameInput: ElementRef;

  cols: any[];
  saving: boolean = false;
  treeType: TreeType = new TreeType();
  treeTypes: TreeType[] = [];

  constructor(private treeTypeService: TreeTypeGenSvc,
    private messageService: MessageService) {
    this.cols = [
      { field: 'commonName', header: 'Common Name', width: '53%' },
      { field: 'genus', header: 'Genus', width: '20%' },
      { field: 'species', header: 'Species', width: '20%' },
      { header: '', width: '7%' },
    ];
  }

  ngOnInit() {
    this.treeType.active = true;
    this.treeTypeService.getAll().subscribe(treeTypes => this.treeTypes = treeTypes);
  }

  clear(ngForm: NgForm) {
    ngForm.resetForm();
    this.emptyInputs();
  }

  delete(treeType: TreeType) {
    if (confirm('Are you sure you want to remove this tree type?')) {
      this.treeTypeService.delete(treeType.id).subscribe(ok => {
        this.treeTypes = this.treeTypes.filter(tt => tt.id !== treeType.id);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the tree type, please check that the tree type exists and retry.'
        });
        console.log(error);
      });
    }
  }

  edit(treeType: TreeType) {
    this.treeType.id = treeType.id;
    this.treeType.commonName = treeType.commonName;
    this.treeType.genus = treeType.genus;
    this.treeType.species = treeType.species;
    this.treeType.active = treeType.active;

    this.commonNameInput.nativeElement.focus();
  }

  emptyInputs() {
    this.treeType = new TreeType();
    this.treeType.commonName = null;
    this.treeType.active = true;
    this.treeType.genus = null;
    this.treeType.species = null;
  }

  save(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      this.saving = true;

      if (this.treeType.id) { // Edit existing
        this.treeTypeService.update(this.treeType).subscribe(() => {
          const oldTreeType = this.treeTypes.find(tt => tt.id === this.treeType.id);
          oldTreeType.commonName = this.treeType.commonName;
          oldTreeType.genus = this.treeType.genus;
          oldTreeType.species = this.treeType.species;

          this.saveFinished(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the tree type, please check that all fields are valid and try again.'
          });
          console.log(error);
          this.saveFinished(ngForm);
        });
      } else { // New save
        this.treeType.active = true;
        this.treeTypeService.add(this.treeType).subscribe(treeType => {
          this.treeTypes = [...this.treeTypes, treeType];
          this.saveFinished(ngForm);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the tree type, please check that all fields are valid and try again.'
          });
          console.log(error);
          this.saveFinished(ngForm);
        });
      }
    }
  }

  saveFinished(ngForm: NgForm) {
    this.saving = false;
    this.clear(ngForm);
  }
}
