import { Injectable } from '@angular/core';
import { Equipment } from '../services_autogenerated/generated_services';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SpecificationService {

  constructor() { }

  // IF YOU CHANGE THIS, CHANGE THE BACK END ONE TOO.
  EquipmentInService(equipment: Equipment, dateOfInterest = new Date()): boolean {
    const momentOfInterest = moment(dateOfInterest);

    // 1. if there is no equipment it's out of service
    if (equipment == null) {
        return false;
    }

    // 2. no service records means it's in service
    if (equipment.equipmentServiceRecords == null) {
        return true;
    }

    // 3. if any record exists, it's currently inactive. So put a ! in front of this
    return !equipment.equipmentServiceRecords.some(
        r => {
            if (r.inactiveDateTo) {
                return momentOfInterest.isBetween(
                    moment(r.inactiveDateFrom), moment(r.inactiveDateTo), 'days', '[]');
            } else {
                return momentOfInterest.isSameOrAfter(moment(r.inactiveDateFrom), 'days');
            }
        });
    }
}
