import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Employee, EmployeeGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-office-manager-dashboard',
  templateUrl: './office-manager-dashboard.component.html',
  styleUrls: ['./office-manager-dashboard.component.css']
})
export class OfficeManagerDashboardComponent implements OnInit {
  isLoading: boolean;
  rms: Employee[];

  constructor(private employeeService: EmployeeGenSvc,
              private router: Router) { }

  ngOnInit() {
    this.isLoading = true;
    this.employeeService.getRegionalManagers().subscribe(rms => {
      this.rms = rms;
      this.isLoading = false;
    }, error => {
      console.log(error);
      this.isLoading = false;
    });
  }

  navigateToRMSchedulingPage(rm: Employee) {
    this.router.navigateByUrl('rmSchedulePage/' + rm.id);
  }

  navigateToRMTimeSlotPage(rm: Employee) {
    this.router.navigateByUrl('assignZipCodeToDay/' + rm.id);
  }
}
