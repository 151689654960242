import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { WeekCalendarViewComponent } from '../week-calendar-view/week-calendar-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { BaseWorkViewComponent } from '../base-work-view/base-work-view.component';

@Component({
  selector: 'app-work-week-view',
  templateUrl: './work-week-view.component.html',
  styleUrls: ['./work-week-view.component.css']
})
export class WorkWeekViewComponent extends BaseWorkViewComponent implements OnInit {
  selectedView = 'week';

  @ViewChild(WeekCalendarViewComponent) calendarComponent: WeekCalendarViewComponent;

  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService
  ) {
    super(dragAndDropService);
  }

  ngOnInit() {
  }

}
