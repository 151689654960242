import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { InvoiceChargeViewModel, StripeGenSvc, WorkOrderInvoicingViewModel } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-refund-invoice',
  templateUrl: './refund-invoice.component.html',
  styleUrls: ['./refund-invoice.component.css']
})
export class RefundInvoiceComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() workOrder: WorkOrderInvoicingViewModel;

  @Output() close  = new EventEmitter<boolean>();

  charges: InvoiceChargeViewModel[];
  isLoading: boolean;

  constructor(private customStripeService: StripeGenSvc,
    private messageService: MessageService) { }

  ngOnInit() {
    this.isLoading = true;
    const type = this.workOrder.serviceType === 'Tree Wellness' ? 'Wellness' : 'Work';
    this.customStripeService.getAllInvoicesForWorkOrder(this.workOrder.workOrderId, type).subscribe(res => {
      this.isLoading = false;
      this.charges = res;
    }, error => {
      this.isLoading = false;
      console.log(error);
      this.addErrorMessage('Unexpected error, please reload and try again.');
    });
  }

  refund(charge: InvoiceChargeViewModel) {
    this.customStripeService.refundChargeById(charge.chargeId).subscribe(res => {
      this.close.emit(true);
    }, error => {
      console.log(error);
      this.addErrorMessage('Unable to issue refund, please try again in Stripe.');
    });
  }

  cancel() {
    this.close.emit(false);
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }
}
