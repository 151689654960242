import { Injectable } from '@angular/core';
import { QuoteWorkOrder, WellnessWorkOrder, WorkOrderWellnessTask, WellnessTask } from '../services_autogenerated/generated_services';

@Injectable({
  providedIn: 'root'
})
export class WellnessWorkOrderMaintenanceService {

  constructor() { }

  removeWellnessTaskDependentReferences(workOrder: WellnessWorkOrder | QuoteWorkOrder, deleted: WorkOrderWellnessTask) {
    workOrder.workOrderWellnessTasks.map(task => {
        if (this.isWellnessTaskDependent(task.wellnessTask, deleted)) {
            task.wellnessTask.schedulableAfterCompletionOfId = null;
            task.wellnessTask.schedulableAfterCompletionOf = null;
        }
    })
}

isWellnessTaskDependent(task: WellnessTask, wowt: WorkOrderWellnessTask) {
    if (task) {
        const hasMatchingId = wowt.wellnessTaskId && task.schedulableAfterCompletionOfId == wowt.wellnessTaskId;
        const hasMatchingReference = task.schedulableAfterCompletionOf == wowt.wellnessTask;
        if (hasMatchingId || hasMatchingReference) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
}
