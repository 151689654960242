import { Component, OnInit, ViewChild } from '@angular/core';
import { QuoteRequestGenSvc,
  SortOption2,
  QuoteRequestListView,
  QuoteRequestPaymentStatus,
  QuoteRequestCompletionStatus } from '../../services_autogenerated/generated_services';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { QuoteRequestFilterPipe } from '../../pipes/quote-request-filter.pipe';
import { CustomerService } from '../../services/customer.service';
import { Table } from 'primeng/table';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-requested-quote-list',
  templateUrl: './requested-quote-list.component.html',
  styleUrls: ['./requested-quote-list.component.css'],
  providers: [QuoteRequestFilterPipe]
})
export class RequestedQuoteListComponent implements OnInit {
  @ViewChild('qrTable') qrTable: Table;

  types: SelectItem[];
  selectedType: string;

  cols: any[];
  quoteRequests: QuoteRequestListView[] = [];
  getInactives = false;
  loading: boolean;
  searchTerm: string;
  totalRecords: number;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private quoteRequestService: QuoteRequestGenSvc,
    public customCustomerService: CustomerService,
    private authService: AuthHelperService
    ) {
    this.cols = [
      { field: 'customer.fullName', header: 'Full Name', width: '15%' },
      { field: 'customer.primaryPhone', header: 'Phone Number', width: '10%' },
      { field: 'address.street', header: 'Address', width: '15%' },
      { field: 'address.state.name', header: 'State', width: '6%' },
      { field: 'address.zip', header: 'Zip', width: '5%' },
      { field: 'completionStatus', header: 'Status', width: '10%' },
      { field: 'paymentStatus', header: 'Payment Status', width: '10%' },
      { field: 'createdDate', header: 'Created Date', width: '10%' },
      { field: 'isUrgent', header: 'Urgent', width: '5%' },
      { header: '', width: '7%' }
    ];

    this.types = [
      { label: 'Quote Requests', value: 'quoteRequest' },
      { label: 'Quotes', value: 'quote'}
    ];

    this.selectedType = this.types[0].value;
  }

  ngOnInit() {
    const type = this.route.snapshot.paramMap.get('type');

    if (type) {
      this.selectedType = type;
    }

    if (this.qrTable) {
      this.qrTable.filterDelay = 500;
    }

    this.loading = true;
  }

  changedType() {
    this.router.navigateByUrl(`/quoteList/${this.selectedType}`);
  }

  showDeleteForRFQ(qr: QuoteRequestListView): boolean {
    let showDelete = false;

    const token = this.authService.getDecodedAccessToken();
    // Can only delete a RFQ if you have permission, it's active, it's not transformed, it's not paid for, and it's not scheduled
    showDelete = token.claimNames.includes('View/Edit Request for Quote') && qr.active
      && qr.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote && qr.paymentStatus !== QuoteRequestPaymentStatus.Paid
      && !qr.isScheduled;

    return showDelete;
  }

  setQuotes() {
    this.qrTable.sortOrder = 0;
    this.qrTable. sortField = '';
    this.qrTable.reset();
  }

  loadRequestLazy(event: LazyLoadEvent) {
    const sortOption = this.getSortOption(event.sortField, event.sortOrder);

    this.loading = true;

    this.quoteRequestService.getPaginated(event.first,
                                          event.rows,
                                          this.getInactives,
                                          sortOption,
                                          event.globalFilter ? event.globalFilter : '')
    .subscribe(res => {
      this.quoteRequests = res.results;
      this.totalRecords = res.totalResults;
      this.loading = false;
    },
    error => {
      this.loading = false;
      this.addErrorMessage('Could not load the quote requests, please refresh the page and try again.');
    });
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }

  addNew() {
    if (this.selectedType === 'quoteRequest') {
      this.router.navigateByUrl('/requestedQuoteMaintenance');
    } else if (this.selectedType === 'quote') {
      this.router.navigateByUrl('/quoteWorkOrderMaintenance');
    }
  }

  edit(quoteRequest) {
    // this.router.navigateByUrl('/requestedQuoteMaintenance/' + quoteRequest.id);
    window.open('requestedQuoteMaintenance/' + quoteRequest.id, '_blank');
  }

  delete(quoteRequest) {
    if (confirm('Are you sure you want to delete this quote request?')) {
      this.quoteRequestService.delete(quoteRequest.id).subscribe(() => {
        this.quoteRequests = this.quoteRequests.filter(obj => obj !== quoteRequest);
      }, error => {
        this.addErrorMessage('Could not delete the quote request, please verify it exists and retry.');
      });
    }
  }

  private getSortOption(field: string, order: number): SortOption2 {
    if (field === 'customer.fullName') {
      if (order === 1) {
        return SortOption2.CustomerName;
      } else {
        return SortOption2.CustomerNameDesc;
      }
    } else if (field === 'completionStatus') {
      if (order === 1) {
        return SortOption2.CompletionStatus;
      } else {
        return SortOption2.CompletionStatusDesc;
      }
    } else if (field === 'customer.primaryPhone') {
      if (order === 1) {
        return SortOption2.CustomerPhone;
      } else {
        return SortOption2.CustomerPhoneDesc;
      }
    } else if (field === 'address.street') {
      if (order === 1) {
        return SortOption2.StreetAddress;
      } else {
        return SortOption2.StreetAddressDesc;
      }
    } else if (field === 'address.state.name') {
      if (order === 1) {
        return SortOption2.StateName;
      } else {
        return SortOption2.StateNameDesc;
      }
    } else if (field === 'address.zip') {
      if (order === 1) {
        return SortOption2.Zip;
      } else {
        return SortOption2.ZipDesc;
      }
    } else if (field === 'paymentStatus') {
      if (order === 1) {
        return SortOption2.PaymentStatus;
      } else {
        return SortOption2.PaymentStatusDesc;
      }
    } else if (field === 'createdDate') {
      if (order === 1) {
        return SortOption2.Date;
      } else {
        return SortOption2.DateDesc;
      }
    }  else if (field === 'isUrgent') {
    if (order === 1) {
      return SortOption2.Urgent;
    } else {
      return SortOption2.UrgentDesc;
    }
  }

    return SortOption2.None;
  }

}
