import { OnInit, QueryList } from '@angular/core';
import { Employee, Address, EmployeeSkill, EmployeeRole, StateGenSvc, EmailAddress } from '../../services_autogenerated/generated_services';
import { RoleGenSvc, SkillGenSvc, EmployeeGenSvc } from '../../services_autogenerated/generated_services';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { Guid } from 'src/app/models/guid';
import { EmployeeSkillUploadComponent } from '../employee-skill-upload/employee-skill-upload.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
var EmployeeMaintenanceComponent = /** @class */ (function () {
    function EmployeeMaintenanceComponent(route, router, roleService, skillService, employeeService, stateService, messageService, authHelper) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.roleService = roleService;
        this.skillService = skillService;
        this.employeeService = employeeService;
        this.stateService = stateService;
        this.messageService = messageService;
        this.authHelper = authHelper;
        this.disableDelete = false;
        this.saving = false;
        this.doneSaving = function () {
            _this.saving = false;
            if (_this.token.claimNames.includes('View/Edit Employee Maint.')) {
                _this.router.navigateByUrl('/employeeList');
            }
            else {
                _this.authHelper.redirectToHome();
            }
        };
        this.cols = [
            { field: 'shortName', header: 'Skill/Certification', width: '30%' },
            { field: 'startDate', header: 'Start Date', type: Date, width: '15%' },
            { field: 'endDate', header: 'End Date', type: Date, width: '15%' },
            { header: 'File', width: '30%' },
            { header: 'Delete', width: '10%' }
        ];
    }
    EmployeeMaintenanceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id = +this.route.snapshot.paramMap.get('id');
        this.token = this.authHelper.getDecodedAccessToken();
        this.canEdit = this.token.claimNames.includes('View/Edit Employee Maint.');
        this.skillService.getAll().subscribe(function (skills) {
            _this.skills = skills;
        });
        this.stateService.getAll().subscribe(function (states) { return _this.states = states; });
        if (id) {
            // tslint:disable-next-line: deprecation
            Observable.forkJoin(this.employeeService.get(id), this.roleService.getAll())
                .subscribe(function (_a) {
                var employee = _a[0], roles = _a[1];
                _this.roles = roles;
                _this.employee = employee;
                _this.afterEmployeeAndRolesReturn(_this.employee, _this.roles);
                _this.disableDelete = !_this.token.claimNames.includes('View/Edit Employee Maint.');
                _this.selectedState = employee.address.state;
            });
        }
        else {
            this.disableDelete = true;
            var emp = new Employee();
            emp.currentlyEmployed = true;
            emp.employeeSkills = [];
            emp.employeeRoles = [];
            emp.address = new Address();
            emp.emailAddress = new EmailAddress();
            this.employee = emp;
            this.roleService.getAll().subscribe(function (roles) {
                _this.roles = roles;
                _this.afterEmployeeAndRolesReturn(_this.employee, _this.roles);
            });
            this.stateService.getAll().subscribe(function (states) {
                _this.states = states;
                _this.selectedState = states.find(function (state) { return state.name === 'Ohio'; });
                _this.employee.address.state = _this.selectedState;
            });
        }
    };
    EmployeeMaintenanceComponent.prototype.afterEmployeeAndRolesReturn = function (employee, roles) {
        this.employeeRoleOptions = employee.employeeRoles; // start with existing roles
        // add other options from roles they could pick from
        var otherRoles = roles.filter(function (r) { return !employee.employeeRoles.some(function (er) { return er.roleId === r.id; }); });
        this.employeeRoleOptions = this.employeeRoleOptions.concat(otherRoles.map(function (r) {
            var newER = new EmployeeRole();
            newER.roleId = r.id;
            newER.role = r;
            newER.employeeId = employee.id; // may be null if new employee
            return newER;
        }));
        this.updateEmployeIsRM();
    };
    EmployeeMaintenanceComponent.prototype.updateEmployeIsRM = function () {
        this.employeeIsRM = this.employee.employeeRoles.some(function (er) { return er.role.name.includes('Regional Manager'); });
    };
    EmployeeMaintenanceComponent.prototype.save = function (ngForm) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (ngForm.valid) {
            this.saving = true;
            if (this.employee.id) {
                this.employeeService.update(this.employee, this.employee.id.toString())
                    .subscribe(function (emp) {
                    if (_this.employee.employeeSkills && _this.employee.employeeSkills.length > 0) {
                        _this.saveBlobs(emp);
                    }
                    else {
                        _this.doneSaving();
                    }
                }, function (error) {
                    _this.showErrorMessage(error.status);
                    _this.doneSaving();
                });
            }
            else {
                this.employeeService.add(this.employee)
                    .subscribe(function (emp) {
                    if (_this.employee.employeeSkills && _this.employee.employeeSkills.length > 0) {
                        _this.saveBlobs(emp);
                    }
                    else {
                        _this.doneSaving();
                    }
                }, function (error) {
                    _this.showErrorMessage(error.status);
                    _this.doneSaving();
                });
            }
        }
    };
    EmployeeMaintenanceComponent.prototype.saveBlobs = function (savedEmp) {
        var _this = this;
        var blobObservables = [];
        this.employeeSkillUploadComponents.forEach(function (uploadComponent) {
            uploadComponent.blobManagerComponent.parentRecordId = savedEmp.employeeSkills
                .find(function (empSkill) { return (empSkill.id && empSkill.id === uploadComponent.employeeSkill.id)
                || (empSkill.tempSaveGUID && empSkill.tempSaveGUID === uploadComponent.employeeSkill.tempSaveGUID); }).id;
            blobObservables.push(uploadComponent.blobManagerComponent.save());
        });
        Observable.forkJoin(blobObservables)
            .pipe(catchError(function (error) {
            return of(null);
        }))
            .subscribe(function (res) {
            if (res) {
                _this.doneSaving();
            }
            else {
                alert('Failed to upload Photos/Documents. Please try again later.');
            }
        });
    };
    EmployeeMaintenanceComponent.prototype.showErrorMessage = function (status) {
        if (status === 409) {
            this.messageService.add({
                severity: 'error',
                summary: 'Email Already In Use',
                detail: 'The entered email address is already in use, please select another one.'
            });
        }
        else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error Saving',
                detail: 'Could not save the employee, please check that all fields are valid and retry.'
            });
        }
    };
    EmployeeMaintenanceComponent.prototype.cancel = function () {
        if (this.token.claimNames.includes('View/Edit Employee Maint.')) {
            this.router.navigateByUrl('/employeeList');
        }
        else {
            this.authHelper.redirectToHome();
        }
    };
    EmployeeMaintenanceComponent.prototype.delete = function () {
        var _this = this;
        if (confirm('Are you sure you want to permanently delete this user? This cannot be undone.')) {
            this.employeeService.delete(this.employee.id).subscribe(function () {
                _this.doneSaving();
            }, function (error) {
                _this.messageService.add({
                    severity: 'error',
                    summary: 'Error Deleting',
                    detail: 'Could not delete the employee, please check that the employee exists and retry.'
                });
            });
        }
    };
    EmployeeMaintenanceComponent.prototype.addNewSkill = function () {
        var empSkill = new EmployeeSkill();
        empSkill.tempSaveGUID = Guid.newGuid();
        empSkill.employeeId = this.employee.id;
        this.employee.employeeSkills.push(empSkill);
    };
    EmployeeMaintenanceComponent.prototype.removeSkill = function (rowIndex) {
        this.employee.employeeSkills.splice(rowIndex, 1);
    };
    EmployeeMaintenanceComponent.prototype.onSkillChange = function (es, skill) {
        es.skillId = skill.id;
    };
    EmployeeMaintenanceComponent.prototype.onStateSelect = function (state) {
        this.employee.address.stateId = state.id;
        this.employee.address.state = state;
    };
    EmployeeMaintenanceComponent.prototype.onSkillFromDateChange = function (form, rowIndex, es) {
        // auto-populate the To Date, if it makes sense
        if (es && es.skill && es.skill.length && !es.toDate) {
            es.toDate = moment(es.fromDate).add(es.skill.length, 'years').toDate();
        }
        // if the from date changes, validate the To Date
        setTimeout(function () {
            form.controls['toDate' + rowIndex].updateValueAndValidity();
        }, 200);
    };
    EmployeeMaintenanceComponent.prototype.navigateToRMSchedulingPage = function () {
        this.router.navigateByUrl('rmSchedulePage/' + this.employee.id);
    };
    return EmployeeMaintenanceComponent;
}());
export { EmployeeMaintenanceComponent };
