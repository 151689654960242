import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SystemSettingDTO, SystemSettingGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-setting-row',
  templateUrl: './setting-row.component.html',
  styleUrls: ['./setting-row.component.css']
})
export class SettingRowComponent implements OnInit {
  @Input() setting: SystemSettingDTO;
  settingForm: FormGroup;
  isLoading: boolean;

  claims: string[];

  constructor(private systemSettingService: SystemSettingGenSvc,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
    this.settingForm = this.formBuilder.group({
      value: [0, Validators.required]
    } );
   }

  ngOnInit() {
    this.settingForm.controls['value'].setValue(this.setting.value);
  }

  public get value() { return this.settingForm.get('value'); }

  save(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.controls[key].markAsDirty();
      formGroup.controls[key].markAsTouched();
    });
    if (formGroup.valid) {
      this.isLoading = true;
      this.setting.value = this.value.value;
      this.systemSettingService.update(this.setting).subscribe(() => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Saved Successfully',
          detail: `The value for ${this.setting.name} have been updated.`
        });
      }, error => {
        if (error && error.response && JSON.parse(error.response).ExceptionMessage) {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error Updating Value',
            detail: JSON.parse(error.response).ExceptionMessage,
            sticky: true
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Save Failed',
            detail: 'The setting\'s value were not updated, refresh the page and try again.'
          });
        }
      });
    }
  }
}
