<div class="headerText">Work Orders</div>
<div>
  <div class="filterBar dayView">
    <span class="ui-float-label">
      <input id="float-input-workorder-search" type="text" class="float-input" pInputText [(ngModel)]="searchTerm" (ngModelChange)="searchInput.next($event)">
      <label for="float-input-workorder-search">
        <i class="pi pi-search"></i>
        Search
      </label>
    </span>
  
    <div class="filterBoxContainer">
      <div class="filterBox dayView">
        <button type="text" (click)="opServiceType.toggle($event)">Service Type <i class="pi pi-caret-down"></i></button>
        <p-overlayPanel #opServiceType appendTo="body">
            Show Work Orders with Service Type:<br/>
            <p-multiSelect [options]="serviceTypes" [(ngModel)]="advancedFilters.services" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="filterBarInput.next($event)"></p-multiSelect>
        </p-overlayPanel>
      </div>
      <div class="filterBox dayView">
        <button type="text" (click)="opWOStatus.toggle($event)">WO Status <i class="pi pi-caret-down"></i></button>
        <p-overlayPanel #opWOStatus appendTo="body">
            Show Work Orders with WO Status:<br/>
            <p-multiSelect [options]="woStatuses" [(ngModel)]="advancedFilters.completionStatuses" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="filterBarInput.next($event)"></p-multiSelect>
        </p-overlayPanel>
      </div>
      <div class="filterBox dayView">
        <button type="text" (click)="opPaymentStatus.toggle($event)">Payment Status <i class="pi pi-caret-down"></i></button>
        <p-overlayPanel #opPaymentStatus appendTo="body">
            Show Work Orders with Payment Status:<br/>
            <p-multiSelect [options]="paymentStatuses" [(ngModel)]="advancedFilters.paymentStatuses" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="filterBarInput.next($event)"></p-multiSelect>
        </p-overlayPanel>
      </div>
      <div class="filterBox dayView">
        <button type="text" (click)="opRM.toggle($event)">RM <i class="pi pi-caret-down"></i></button>
        <p-overlayPanel #opRM appendTo="body">
            Show Work Orders with RM:<br/>
            <p-multiSelect [options]="rms" [(ngModel)]="advancedFilters.rMs" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="filterBarInput.next($event)"></p-multiSelect>
        </p-overlayPanel>
      </div>    
      <div style="float: right; margin: -5px 0 0px 5px;">
        <button pButton class="ui-button-secondary ui-button-rounded" label="Refresh" (click)="filterFromBar()"></button>
      </div> 
    </div>
  </div>
  <div class="clear:both;"></div>  
</div>

<p-table 
  class="no-pad-table-header"
  [columns]="cols" 
  [value]="allWorkOrders" 
  [paginator]="true" 
  [rows]="rows" 
  [responsive]="true"
  [lazy]="true"
  (onLazyLoad)="loadRequestLazy($event)"
  [totalRecords]="totalRecords"
  [loading]="isLoading"
  [sortField]="'DateCompleted'"
  [sortOrder]="-1"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        <span *ngIf="col.header == 'Amount Due'" pTooltip="Amount due is Work Order total minus cash/checks collected">{{col.header}}</span>
        <span *ngIf="col.header != 'Amount Due'">{{col.header}}</span>
        
        <p-sortIcon *ngIf="col.header != '' && col.field" [field]="col.field" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workOrder let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td>
        <span class="ui-column-title">{{columns[0].header}}</span>
        <span class="regular-link" (click)="navigateToWorkOrder(workOrder)">
          {{workOrder.number}}
        </span>
      </td>
      <td>
        <span class="ui-column-title">{{columns[1].header}}</span>
        <span class="regular-link" (click)="navigateToCustomer(workOrder)">{{workOrder.customerName}}</span>
      </td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{workOrder.phoneNumber | phoneNumber}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{workOrder.serviceType}}</td>
      <td>
        <span class="ui-column-title">{{columns[4].header}}</span>
        {{workOrder.wOStatus}}
      </td>
      <td>
        <span class="ui-column-title">{{columns[5].header}}</span>
        {{workOrder.dateCompleted}} 
        <span *ngIf="workOrder.wOStatus == 'Partially Complete' || workOrder.showAsPartiallyComplete">
          <i class="pi pi-question-circle" pTooltip="This is the most recent completed date of the tasks on the WO." style="vertical-align: middle;"></i>
        </span>
      </td>
      <td>
        <span class="ui-column-title">{{columns[6].header}}</span>
        <span [ngClass]="workOrder.paymentStatus == 'Paid' ? 'paid' : 'unpaid' ">
          {{workOrder.paymentStatus ? workOrder.paymentStatus : 'Unpaid' }}
        </span>
      </td>
      <td><span class="ui-column-title">{{columns[7].header}}</span>{{workOrder.preferredPayment}}</td>
      <td><span class="ui-column-title">{{columns[8].header}}</span>{{workOrder.cardOnFile}}</td>
      <td>
        <span class="ui-column-title">{{columns[9].header}}</span>
        <span *ngIf="workOrder.amountDue != undefined">
          {{workOrder.amountDue | currency: 'USD' : 'symbol'}}
          <span *ngIf="workOrder.wOStatus == 'Partially Complete' || workOrder.showAsPartiallyComplete">
            <i class="pi pi-question-circle" pTooltip="This is the total due for the unpaid invoices - does not include tasks that are not complete" style="vertical-align: middle;"></i>
          </span>
        </span>
        <span *ngIf="workOrder.amountDue == undefined" style="margin-left:8px; text-align: center;">
          <i class="pi pi-spin pi-spinner" style="font-size: 14px"></i>
        </span>
      </td>
      <td>
        <span class="ui-column-title">{{columns[10].header}}</span>
        <div style="text-align: center;">
          <app-payment-options-td
            [workOrder]="workOrder"
            (invoicesEvent)="openInvoiceForRow($event)"
            (addCashEvent)="addCashForRow($event)"
            (chargeInvoiceEvent)="chargeInvoiceForRow($event)"
            (refundEvent)="refundInvoiceForRow($event)"
            (finalizeEvent)="finalize($event)">
          </app-payment-options-td>          
        </div>
      </td>
      <td class="showLineBreaks">
        <span class="ui-column-title">{{columns[11].header}}</span>
        {{workOrder.latestNote}}
        <br *ngIf="workOrder.latestNote"/>
        <span class="regular-link" (click)="addNoteForRow(workOrder)">
          [new note]
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Adding Customer Comment -->
<div class="scrollableDialog" *ngIf="showAddNoteDialog">
  <p-dialog header="Internal Notes" [(visible)]="showAddNoteDialog" [modal]="true" [responsive]="true" [resizable]="true" [baseZIndex]="10000"
    [maximizable]="true" [style]="{'width': '1560px'}" [focusOnShow]="false" (onHide)="closedDialog()" [closable]="false" [blockScroll]="true" [positionTop]="0" [minY]="70" >
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all}" tabindex="0" role="button" (click)="closedDialog()" (keydown.enter)="closedDialog()" (mousedown)="closedDialog()">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-customer-comments
      [customerIdInput]="addNoteWorkOrderRow.customerId" 
      [claimNames]="claimNames" 
      [defaultWONumber]="addNoteWorkOrderRow.number"
      [defaultCategory]="'Payment'"
    ></app-customer-comments>
  </p-dialog>
</div>

<app-invoices *ngIf="showInvoicesDialog" [showDialog]="showInvoicesDialog" [workOrder]="showInvoicesRow" (close)="closedDialog($event)"></app-invoices>

<!-- Adding Cash Collected -->
<app-add-cash *ngIf="showAddCashDialog" [showDialog]="showAddCashDialog" [workOrder]="addCashWorkOrderRow" (close)="closedDialog($event)" (charge)="chargeInvoice($event, true)"></app-add-cash>

<!-- Showing customer card data and creating/charging invoice for WO -->
<app-charge-card *ngIf="showChargeCardDialog" [showDialog]="showChargeCardDialog" [workOrder]="chargeCardWorkOrderRow" (close)="closedDialog($event)" (charge)="chargeInvoice($event)"></app-charge-card>

<app-refund-invoice *ngIf="showRefundDialog" [showDialog]="showRefundDialog" [workOrder]="refundWorkOrderRow" (close)="closedDialog($event)"></app-refund-invoice>

<p-dialog [(visible)]="showFinalizeLoading" [modal]="true" [blockScroll]="false" (onHide)="closedDialog(false)" [style]="{width: '480px'}">
  <app-loading-swirly [isLoading]="showFinalizeLoading"></app-loading-swirly> Please wait while the system processes.
</p-dialog>