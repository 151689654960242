<p-dialog header="Add New Event" [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}" [minY]="70" 
  [baseZIndex]="10000" [closable]="false" [maximizable]="false"
>
  <form id="newEventForm" #newEventForm="ngForm">
    <h6>Event Title</h6>
    <input pInputText required name="title" class="inputTextBox form-control" [(ngModel)]="newEvent.title"/>
    <h6>Additional Text</h6>
    <input pInputText name="additional" class="inputTextBox form-control" [(ngModel)]="newEvent.additionalText"/>
  
    <hr/>
  
    <div class="button-group">
      <button type="button" pButton (click)="cancel()" label="Cancel" class="ui-button-warning margin-right"></button>
      <button type="submit" pButton (click)="submit(newEventForm);" label="Submit"></button>
    </div>
  </form>
</p-dialog>