import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { EmployeeGenSvc, EmployeeMFAPreferenceViewModel } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-multi-factor-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
  public disableSend: boolean = false;
  public employeePreference: EmployeeMFAPreferenceViewModel;

  public responseMessage: string;
  public success: boolean;
  public verifying: boolean = false;

  public enteredCode: number;
  public waitingForVerification: boolean = false;

  constructor(private router: Router,
    private employeeService: EmployeeGenSvc,
    private authHelper: AuthHelperService
  ) { }

  ngOnInit() {
    this.disableSend = true;
    this.employeeService.getEmployeePreference(this.authHelper.getDecodedAccessToken().employeeId)
      .pipe(catchError((error) => {
        this.success = false;
        this.responseMessage = 'Unable to retrieve your preference. Please refresh and retry. Contact an admin if the issue persists.'
        return of(null);
      }))
      .subscribe((res) => {
        this.employeePreference = res;
        this.disableSend = false;
      }
    );
  }

  sendCode() {
    this.disableSend = true;
    this.employeeService.sendVerificationCode(this.employeePreference)
      .pipe(catchError((error) => {
        this.disableSend = false;
        this.success = false;
        this.responseMessage = 'Unable to send the verification code. Please refresh and retry. Contact an admin if the issue persists.'
        return of(null);
      }))
      .subscribe((res) => {
        this.disableSend = false;
        this.success = true;
        this.verifying = true;
        this.responseMessage = 'Verification code sent! Don\'t share your code with anyone.'

        setTimeout(() => {
          this.success = undefined;
          this.responseMessage = '';
        }, 3000);
      }
    );
  }

  verifyCode() {
    this.disableSend = true;
    this.employeeService.checkVerificationCode(this.enteredCode.toString())
      .pipe(catchError((error) => {
        this.success = false;
        this.disableSend = false;
        this.responseMessage = 'Unable to verify code or incorrect code. Please refresh and retry. Contact an admin if the issue persists.'
        return of(null);
      }))
      .subscribe(token => {
        if (token != null) {
          localStorage.setItem(this.authHelper.authToken, token);
          this.authHelper.redirectToHome();
        }
      }
    )
  }

  goToSetup() {
    this.router.navigate(['multiFactor', 'setup']);
  }
}
