import { Component, OnInit, Input } from '@angular/core';
import { QuoteWorkOrder,
  WellnessTask,
  WorkTask,
  WellnessTaskCompletionStatus,
  WorkTaskCompletionStatus,
  QuoteWorkOrderCompletionStatus} from 'src/app/services_autogenerated/generated_services';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { Router } from '@angular/router';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-customer-quote-row',
  templateUrl: './customer-quote-row.component.html',
  styleUrls: ['./customer-quote-row.component.css']
})
export class CustomerQuoteRowComponent implements OnInit {
  @Input() quote: QuoteWorkOrder;
  @Input() current: boolean;
  buttonText: string;
  canSeeHiddenPrice: boolean;
  tasks: any[];

  completionStatus = QuoteWorkOrderCompletionStatus;

  constructor(private router: Router,
    private woHelper: WorkOrderHelperService,
    private authHelper: AuthHelperService) { }

  ngOnInit() {
    if (this.current) {
      this.buttonText = 'Review & Sign';
    } else {
      this.buttonText = 'View Quote';
    }

    this.tasks = this.woHelper.getTasks(this.quote);

    this.canSeeHiddenPrice = this.authHelper.getDecodedAccessToken().claimNames.includes('View Hidden Prices');
  }

  getExpireDate(): Date {
    const result = new Date(this.quote.createdDate);
    result.setDate(result.getDate() + 90);
    return result;
  }

  getTaskStatusDisplay(task: WellnessTask | WorkTask): string {
    if (task instanceof WellnessTask) {
      if (task.completionStatus === WellnessTaskCompletionStatus.Quote_Rejected) {
        return 'Service Not Accepted';
      } else if (task.completionStatus === WellnessTaskCompletionStatus.Work_Orders_Created) {
        return ' Service Accepted';
      } else if (task.completionStatus === WellnessTaskCompletionStatus.Quote_Expired) {
        return 'Expired';
      }
    } else if (task instanceof WorkTask) {
      if (task.completionStatus === WorkTaskCompletionStatus.Quote_Rejected) {
        return 'Service Not Accepted';
      } else if (task.completionStatus === WorkTaskCompletionStatus.Work_Orders_Created) {
        return ' Service Accepted';
      } else if (task.completionStatus === WorkTaskCompletionStatus.Quote_Expired) {
        return 'Expired';
      }
    }

    return '';
  }

  navigate() {
    this.router.navigateByUrl('customerQuotePage/' + this.quote.id);
  }

  getTreeList(task) {
    if (task instanceof WellnessTask) {
      return task.wellnessTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(',');
    } else if (task instanceof WorkTask) {
      return task.workTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(',');
    }
  }
}
