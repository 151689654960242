import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  QuoteWorkOrder,
  QuoteGenSvc,
  QuoteWorkOrderCompletionStatus,
  WellnessTask,
  WellnessTaskCompletionStatus,
  WorkTask,
  WorkTaskCompletionStatus,
  StripeGenSvc,
  WorkOrderPriceAdjustment,
  NotificationsGenSvc,
  NeighborPermissionGenSvc,
  QuoteWorkOrderManualPriorityShortcutType,
  Dto
} from '../../../services_autogenerated/generated_services';
import { WorkOrderHelperService } from '../../../services/work-order-helper.service';
import { MessageService } from 'primeng/api';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { PaymentDueOptionId } from '../../../models/enums/paymentDueOptionId';
import * as moment from 'moment';
import { Base64 } from 'src/app/models/base64';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-customer-quote-page',
  templateUrl: './customer-quote-page.component.html',
  styleUrls: ['./customer-quote-page.component.css']
})
export class CustomerQuotePageComponent implements OnInit {
  @ViewChild('emailInput') emailInput;
  quote: QuoteWorkOrder;
  canAcceptOrReject: boolean;
  canSeeHiddenPrice: boolean;
  selectedWellnessTasks: number[] = [];
  selectedWorkTasks: number[] = [];
  canProceedWithoutSignature = false;
  tasksAlreadyWorkOrders = false;
  quoteBillingModalDisplay = false;
  activePriceAdjustments: WorkOrderPriceAdjustment[] = [];

  contactPhoneNumber: string;

  maxWidth = '800px';
  showThanksDialogOnly = false;

  showRequestChangesDialog: boolean;
  PaymentDueOptionId = PaymentDueOptionId;
  isSaving = false;

  isLoading = false;

  weeksOutDateCalculation = {
    start: undefined, end: undefined
  };

  warningIsShown: boolean;

  showHourlyRateMessage: boolean;

  signedQuoteWeekRangeCalculation = {
    start: undefined, end: undefined
  };

  constructor(private route: ActivatedRoute,
    private router: Router,
    private quoteService: QuoteGenSvc,
    private emailService: NotificationsGenSvc,
    public woHelperService: WorkOrderHelperService,
    private messageService: MessageService,
    public customCustomerService: CustomerService,
    private authService: AuthHelperService,
    private customStripeService: StripeGenSvc,
    private neighborPermissionService: NeighborPermissionGenSvc,
    private configService: AppConfigService
  ) { }

  ngOnInit() {
    this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
    this.isLoading = true;
    const token = this.authService.getDecodedAccessToken();
    let id: number;
    let custId: number;

    if (this.route.snapshot.queryParamMap.get('cid')) {
      id = +Base64.decode(this.route.snapshot.queryParamMap.get('id'));
      custId = +Base64.decode(this.route.snapshot.queryParamMap.get('cid'));
    } else {
      id = +this.route.snapshot.paramMap.get('id');
    }

    if (token) {
      this.canSeeHiddenPrice = token.claimNames.includes('View Hidden Prices');
      this.canProceedWithoutSignature = false;
      // this.canProceedWithoutSignature = token.roles && token.roles.includes('Admin');
    } else {
      this.canSeeHiddenPrice = true;
      this.canProceedWithoutSignature = false;
    }

    if (id && token) {
      // use anon anyway
      this.quoteService.getAnonForOneClick(id).subscribe(quote => {
        this.afterGetQuote(quote);
      if (quote.completionStatus === QuoteWorkOrderCompletionStatus.Sent && token.userType === 'customer') {
        this.quoteService.setQuoteToReviewed(quote.id).subscribe();
      }
      }, error => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to load the quote, please contact support.'
        });
      });
    } else if (id) {
      this.quoteService.getAnonForOneClick(id).subscribe(quote => {
        if (!token && custId && custId !== quote.customerId) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'You are unable to view this quote without logging in. Please log in to continue or call us at ' + this.contactPhoneNumber + '.',
            sticky: true
          });

          this.router.navigate(['login']);

          return;
        }

        this.afterGetQuote(quote);

        if (quote.completionStatus === QuoteWorkOrderCompletionStatus.Sent) {
          this.quoteService.setQuoteToReviewed(quote.id).subscribe();
        }
      }, error => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'You are unable to view this quote without logging in. Please log in to continue or call us at ' + this.contactPhoneNumber + '.',
          sticky: true
        });
      });
    }
  }

  afterGetQuote(quote: QuoteWorkOrder) {
    if (quote.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created) {
      this.signedQuoteWeekRangeCalculation = {
        start: moment(quote.dueDateStart).diff(quote.signedDate, 'w'),
        end: moment(quote.dueDateEnd).diff(quote.signedDate, 'w'),
      }
    }

    this.tasksAlreadyWorkOrders = quote.completionStatus === QuoteWorkOrderCompletionStatus.Work_Orders_Created
      || quote.completionStatus === QuoteWorkOrderCompletionStatus.Credit_Card_Needed;

    this.selectedWellnessTasks = quote.workOrderWellnessTasks
      .filter(wowt =>
        wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Work_Orders_Created
        || wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Credit_Card_Needed)
      .map(wowt => wowt.id);
    this.selectedWorkTasks = quote.workOrderWorkTasks
      .filter(wowt =>
        wowt.workTask.completionStatus === WorkTaskCompletionStatus.Work_Orders_Created
        || wowt.workTask.completionStatus === WorkTaskCompletionStatus.Credit_Card_Needed)
      .map(wowt => wowt.id);

    if (quote.priorityTypeShortcut && quote.priorityTypeShortcut.weeksOutStart !== undefined) {
      if (false) {
        this.weeksOutDateCalculation = {
          start: moment(quote.originalSignedDate).add(quote.priorityTypeShortcut.weeksOutStart, 'w'),
          end: moment(quote.originalSignedDate).add(quote.priorityTypeShortcut.weeksOutEnd, 'w')
        };
      } else {
        this.weeksOutDateCalculation = {
          start: moment(new Date()).add(quote.priorityTypeShortcut.weeksOutStart, 'w'),
          end: moment(new Date()).add(quote.priorityTypeShortcut.weeksOutEnd, 'w')
        };
      }
    } else if (quote.manualRangeStart && quote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week) {
      this.weeksOutDateCalculation = {
        start: moment(new Date()).add(quote.manualRangeStart, 'w'),
        end: moment(new Date()).add(quote.manualRangeEnd, 'w')
      };
    } else if (quote.manualRangeStart && quote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day) {
      this.weeksOutDateCalculation = {
        start: moment(new Date()).add(quote.manualRangeStart, 'd'),
        end: moment(new Date()).add(quote.manualRangeEnd, 'd')
      };
    }
    this.isLoading = false;
    this.quote = quote;
    this.activePriceAdjustments = quote.workOrderPriceAdjustments.filter(x => x.priceAdjustment.active);

    this.setShowHourlyRateMessage();

    this.canAcceptOrReject = this.quote.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
      && this.quote.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
      && this.quote.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Expired;
  }

  wellnessCheckChanged(checked, wellnessTask: WellnessTask) {
    if (checked) {
      wellnessTask.completionStatus = WellnessTaskCompletionStatus.Credit_Card_Needed;
    } else {
      wellnessTask.completionStatus = WellnessTaskCompletionStatus.Opened;
    }
  }

  workCheckChanged(checked, workTask: WorkTask) {
    if (checked) {
      workTask.completionStatus = WorkTaskCompletionStatus.Credit_Card_Needed;
    } else {
      workTask.completionStatus = WorkTaskCompletionStatus.Opened;
    }

    this.setShowHourlyRateMessage();
  }

  setShowHourlyRateMessage() {
    this.showHourlyRateMessage = this.quote.workOrderWorkTasks.some(wowt => !wowt.workTask.fixedPrice && this.selectedWorkTasks.includes(wowt.id));
  }

  approve() {
    this.isSaving = true;

    if (this.quote.customer.paymentInfoRequired || this.quote.paymentDueOption.id === PaymentDueOptionId.DueInAdvance) {
      this.openBillingModal();
    } else {
      this.convertSelectedTasksToWorkOrders();
    }

    // if (this.quote.completionStatus !== QuoteWorkOrderCompletionStatus.Credit_Card_Needed
    //   && this.quote.completionStatus !== QuoteWorkOrderCompletionStatus.Paid) {
    //   this.convertSelectedTasksToWorkOrders();
    // } else if (this.quote.completionStatus !== QuoteWorkOrderCompletionStatus.Paid) {
    //   this.afterConvertToWorkOrders();
    // } else {
    //   this.afterBillingSave();
    // }
  }

  convertSelectedTasksToWorkOrders() {
    if (this.quote.workOrderWellnessTasks.length > 0 || this.quote.workOrderWorkTasks.length > 0) {
      this.quote.workOrderWellnessTasks.forEach(wowt => {
        if (wowt.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Credit_Card_Needed) {
          wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
        }
      });
      this.quote.workOrderWorkTasks.forEach(wowt => {
        if (wowt.workTask.completionStatus !== WorkTaskCompletionStatus.Credit_Card_Needed) {
          wowt.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
        }
      });
    }

    if (this.quote.priorityTypeShortcut && this.quote.priorityTypeShortcut.weeksOutStart !== undefined) {
      this.quote.dueDateStart = this.weeksOutDateCalculation.start.toDate();
      this.quote.dueDateEnd = this.weeksOutDateCalculation.end.toDate();
    }

    if (this.quote.manualPriorityShortcutType && this.quote.manualRangeStart) {
      this.quote.dueDateStart = this.weeksOutDateCalculation.start.toDate();
      this.quote.dueDateEnd = this.weeksOutDateCalculation.end.toDate();
    }

    this.quoteService.convertToWorkOrders(this.quote, this.selectedWellnessTasks.join(','), this.selectedWorkTasks.join(',')).subscribe(res => {
      this.afterConvertToWorkOrders();
    });
  }

  afterConvertToWorkOrders() {
    if (this.quote.customer.paymentInfoRequired || this.quote.paymentDueOption.id === PaymentDueOptionId.DueInAdvance) {
      this.openBillingModal();
    } else {
      this.customStripeService.addOrUpdateAndApproveQuote(this.quote.customerId,
        '',
        null,
        this.quote.id,
        this.quote.customer.manualPayment,
        this.quote.customer.paymentInfoRequired).subscribe(x => {
          this.showThanksDialogOnly = true;
        });
    }
  }

  openBillingModal() {
    this.quoteBillingModalDisplay = true;
  }

  afterBillingSave() {
    this.showThanksDialogOnly = false;
    this.quote.completionStatus = QuoteWorkOrderCompletionStatus.Work_Orders_Created;
    this.quote.workOrderWellnessTasks.forEach(wowt => {
      if (wowt.wellnessTask.completionStatus === WellnessTaskCompletionStatus.Credit_Card_Needed) {
        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Work_Orders_Created;
      }
    });
    this.quote.workOrderWorkTasks.forEach(wowt => {
      if (wowt.workTask.completionStatus === WorkTaskCompletionStatus.Credit_Card_Needed) {
        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Work_Orders_Created;
      }
    });
    this.quote.signedDate = new Date();

    const dto = new Dto({
      quoteId: this.quote.id,
      selectedWellnessTaskIds: this.selectedWellnessTasks,
      selectedWorkTaskIds: this.selectedWorkTasks
    });
    this.quoteService.markAsSigned(dto)
      .subscribe(() => {
        // Pay advance email sent on Stripe webhook catch for due in advance
        if (this.quote.paymentDueOption.id !== PaymentDueOptionId.DueInAdvance) {
          this.emailService.sendQuoteAcceptance(this.quote, Base64.encode(this.quote.customerId.toString()), Base64.encode(this.quote.id.toString()), false).subscribe(() => {
            this.isSaving = false;
          });
        }
        this.quoteService.getAnonForOneClick(this.quote.id).subscribe(quote => {
          window.scroll(0, 0);
          this.afterGetQuote(quote);
        });
      }, error => {
        this.isSaving = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error Saving',
          detail: 'Could not accept the quote, please refresh and try again.'
        });
      }
    );
  }

  closeQuoteBillingModal() {
    this.quoteBillingModalDisplay = false;
    const token = this.authService.getDecodedAccessToken();

    if (token) {
      this.router.navigateByUrl('/customerLandingPage/' + this.quote.customerId);
    }
  }

  onPaymentSuccess() {
    this.afterBillingSave();
  }

  afterSave() {
    this.router.navigateByUrl('/customerLandingPage/' + this.quote.customerId);
  }

  reject() {
    if (confirm('This will mark the whole quote as rejected. This action cannot be canceled, do you want to continue?')) {
      this.quote.completionStatus = QuoteWorkOrderCompletionStatus.Quote_Rejected;
      this.quote.rejectedDate = new Date();

      this.quote.workOrderWellnessTasks.forEach(wowt => {
        wowt.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Quote_Rejected;
      });

      this.quote.workOrderWorkTasks.forEach(wowt => {
        wowt.workTask.completionStatus = WorkTaskCompletionStatus.Quote_Rejected;
      });

      this.quoteService.updateQuoteAndTasks(this.quote)
        .subscribe(() => {
          this.router.navigateByUrl('/customerLandingPage/' + this.quote.customerId);
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Saving',
            detail: 'Could not save the quote, please check that all fields are valid and retry.'
          });
        });
    }
  }

  requestChanges() {
    this.showRequestChangesDialog = true;
  }

  closeDialog(back) {
    this.showRequestChangesDialog = false;
    const token = this.authService.getDecodedAccessToken();
    if (back && token) {
      this.back();
    }
  }

  back() {
    this.router.navigateByUrl('/customerLandingPage/' + this.quote.customerId);
  }

  shouldDisableApprove() {
    const noTasksAreSelected = !this.selectedWorkTasks.length && !this.selectedWellnessTasks.length;
    const inInappropriateStatus = !this.canAcceptOrReject;
    const signatureIsInvalid = !this.quote.customerSignature && !this.canProceedWithoutSignature;
    const neighborPermissionInvalid = this.quote.neighborPermissionRequired 
                                 && (!this.quote.neighborPermission.email
                                  || !this.quote.neighborPermission.firstName
                                  || !this.quote.neighborPermission.lastName
                                  || !this.quote.neighborPermission.neighborSignature 
                                  || !this.quote.neighborPermission.email.trim() 
                                  || !this.quote.neighborPermission.firstName.trim() 
                                  || !this.quote.neighborPermission.lastName.trim());

    return noTasksAreSelected || inInappropriateStatus || signatureIsInvalid || this.isSaving || this.isMissingPrerequisteTask() || neighborPermissionInvalid;
  }

  isMissingPrerequisteTask(): boolean {

    let didntAcceptPrerequisiteWellnessTask = false;
    const selectedPrerequisteWellnessTaskIds = this.quote.workOrderWellnessTasks
                                          .filter(wowt => this.selectedWellnessTasks.includes(wowt.id) && wowt.wellnessTask.schedulableAfterCompletionOfId)
                                          .map(wowt => wowt.wellnessTask.schedulableAfterCompletionOfId);
    if (selectedPrerequisteWellnessTaskIds) {
      const selectedWellnessTaskIds = this.quote.workOrderWellnessTasks.filter(wowt => this.selectedWellnessTasks.includes(wowt.id)).map(wowt => wowt.wellnessTask.id);
      didntAcceptPrerequisiteWellnessTask = !selectedPrerequisteWellnessTaskIds.every(wt => selectedWellnessTaskIds.includes(wt));
    }

    let didntAcceptPrerequisiteWorkTask = false;
    const selectedPrerequisteWorkTaskIds = this.quote.workOrderWorkTasks
                                          .filter(wowt => this.selectedWorkTasks.includes(wowt.id) && wowt.workTask.schedulableAfterCompletionOfId)
                                          .map(wowt => wowt.workTask.schedulableAfterCompletionOfId);
    if (selectedPrerequisteWorkTaskIds) {
      const selectedWorkTaskIds = this.quote.workOrderWorkTasks.filter(wowt => this.selectedWorkTasks.includes(wowt.id)).map(wowt => wowt.workTask.id);
      didntAcceptPrerequisiteWorkTask = !selectedPrerequisteWorkTaskIds.every(wt => selectedWorkTaskIds.includes(wt));
    }

    return didntAcceptPrerequisiteWellnessTask || didntAcceptPrerequisiteWorkTask;
  }

  onSignPanelShow() {
    if (!this.warningIsShown && this.isMissingPrerequisteTask()) {
      this.warningIsShown = true;
      this.messageService.add({
        severity: 'warn',
        detail: 'Hello! It looks like you have selected a task that is dependent on another task being chosen and competed first. ' +
          'Please select the main task or feel free to call our office with any questions at at ' + this.contactPhoneNumber + '.',
          life: 5000 // 5 seconds
      });

      setTimeout(() => {
        this.warningIsShown = false;
      }, 5000);
    }
  }

  onUpdatedSignature(signature: string) {
    if (!this.quote.originalSignedDate) {
      this.quote.originalSignedDate = new Date();
    }

    this.quote.signedDate = new Date();
    this.quote.customerSignature = signature;
  }

  onNeighborInfoUpdate() {
    this.quote.neighborPermission.firstName = this.quote.neighborPermission.firstName.trim();
    this.quote.neighborPermission.lastName = this.quote.neighborPermission.lastName.trim();
    this.quote.neighborPermission.email = this.quote.neighborPermission.email.trim();
    if (this.quote.neighborPermission.firstName == '' ||
        this.quote.neighborPermission.lastName == '' ||
        this.quote.neighborPermission.email === '') {
          return;
    }
    this.saveNeighborInfo();
  }

  saveNeighborInfo() {
    this.neighborPermissionService.update(this.quote.neighborPermission).subscribe(res => {
    });
  }

  onUpdatedNeighborSignature(signature: string) {
    this.quote.neighborPermission.neighborSignature = signature;
    this.quote.neighborPermission.signedDate = new Date();
    this.saveNeighborInfo();
  }

  sendEmailRequestToNeighbor() {
    if (!this.quote.neighborPermission.email) {
      this.messageService.add({
        severity: 'info',
        summary: 'Need Email',
        detail: 'Please provide a your neighbor\'s email address'
      });

      return;
    }
    if (!this.quote.neighborPermission.firstName) {
      this.messageService.add({
        severity: 'info',
        summary: 'Need Name',
        detail: 'Please provide a your neighbor\'s first name'
      });

      return;
    }
    if (confirm(`This will send an email to ${this.quote.neighborPermission.email} asking for permission to do the following activities ${this.quote.neighborPermission.activiesOnNeighborProperty}`)) {
      this.emailService.sendNeighborPermissionRequest(this.quote).subscribe();
    }
  }

  getPaymentAmount() {
    if (this.quote.paymentDueOption.id === PaymentDueOptionId.DueInAdvance) {
      return this.woHelperService.totalAmount(this.quote as any);
    } else {
      return 0;
    }
  }
}
