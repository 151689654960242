import { Component, OnInit } from '@angular/core';
import { MessageWrapperService } from 'src/app/services/message-wrapper.service';
import { ExportGenSvc, ExportModel } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-export-page',
  templateUrl: './export-page.component.html',
  styleUrls: ['./export-page.component.css']
})
export class ExportPageComponent implements OnInit {
  isLoading: boolean = false;

  exports: ExportModel[] = [];

  constructor(private exportService: ExportGenSvc,
              private messageService: MessageWrapperService) { }

  ngOnInit() {
    this.isLoading = true;
    this.exportService.get().subscribe(res => {
      this.isLoading = false;
      this.exports = res;
    }, err => {
      this.isLoading = false;
      this.messageService.addErrorMessage('Could not load exports, please refresh and try again.', err);
    });
  }

}
