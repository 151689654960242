/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-firmtime-quote-row.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/button/button";
import * as i4 from "./customer-firmtime-quote-row.component";
import * as i5 from "@angular/router";
var styles_CustomerFirmtimeQuoteRowComponent = [i0.styles];
var RenderType_CustomerFirmtimeQuoteRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerFirmtimeQuoteRowComponent, data: {} });
export { RenderType_CustomerFirmtimeQuoteRowComponent as RenderType_CustomerFirmtimeQuoteRowComponent };
export function View_CustomerFirmtimeQuoteRowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "p-grid workStyle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "p-col-12 p-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "p-col-8 header"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" Quote [", "] "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "p-col-4"], ["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" Quote Created: ", " "])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "p-col-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Firmtime Quote"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "p-col-3"], ["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵppd(17, 1), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "p-col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "p-col-12 p-md-4"], ["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "buttonStyle"], ["label", "Schedule & Pay"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_3 = "Schedule & Pay"; _ck(_v, 21, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quote.id; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.quote.createdDate, "shortDate")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 1), _co.firmtimeQuotePrice)); _ck(_v, 16, 0, currVal_2); }); }
export function View_CustomerFirmtimeQuoteRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-firmtime-quote-row", [], null, null, null, View_CustomerFirmtimeQuoteRowComponent_0, RenderType_CustomerFirmtimeQuoteRowComponent)), i1.ɵdid(1, 114688, null, 0, i4.CustomerFirmtimeQuoteRowComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerFirmtimeQuoteRowComponentNgFactory = i1.ɵccf("app-customer-firmtime-quote-row", i4.CustomerFirmtimeQuoteRowComponent, View_CustomerFirmtimeQuoteRowComponent_Host_0, { quote: "quote" }, {}, []);
export { CustomerFirmtimeQuoteRowComponentNgFactory as CustomerFirmtimeQuoteRowComponentNgFactory };
