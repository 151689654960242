import { Component, OnInit } from '@angular/core';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { CustomerGenSvc, Customer, Address, EmailAddress, CustomerContact } from 'src/app/services_autogenerated/generated_services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-landing-page',
  templateUrl: './customer-landing-page.component.html',
  styleUrls: ['./customer-landing-page.component.css']
})
export class CustomerLandingPageComponent implements OnInit {
  isCustomer: boolean;
  hasCustomerDashboardAccess: boolean;
  customer: Customer;

  tabIndex: number;

  claimNames: any[];

  constructor(private authHelper: AuthHelperService,
    private customerService: CustomerGenSvc,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const token = this.authHelper.getDecodedAccessToken();
    this.hasCustomerDashboardAccess = token.claimNames.includes("View Customer Dashboard");
    this.claimNames = token.claimNames;
    this.isCustomer = token.userType === 'customer';
    const id = +this.route.snapshot.paramMap.get('id');
    const indexStr = this.route.snapshot.paramMap.get('tabIndex');
    this.tabIndex = indexStr ? +indexStr : 0;

    if (id) {
      this.customerService.get(id).subscribe(customer => {
        this.customer = customer;
      });
    } else {
      const customer = new Customer();
      customer.active = true;
      customer.paymentInfoRequired = true;
      customer.customerComments = [];
      customer.address = new Address();
      customer.customerContacts = [new CustomerContact({
        active: true,
        receiveInvoicingEmails: true,
        receiveNonInvoicingEmails: true,
      })];
      customer.customerContacts[0].emailAddress = new EmailAddress();
      this.customer = customer;
    }
  }
}
