import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { EmployeeSkill } from 'src/app/services_autogenerated/generated_services';
import { BlobManagerWrapperService } from 'src/app/services/blob-manager-wrapper.service';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';

@Component({
  selector: 'app-employee-skill-upload',
  templateUrl: './employee-skill-upload.component.html',
  styleUrls: ['./employee-skill-upload.component.css']
})
export class EmployeeSkillUploadComponent implements OnInit {
  // This is a wrapper around the blob manager for employee skill uploads, I needed only a couple extra fields
  // but did not want to mix them in with the blob manager since they are not needed for all uploads

  @Input() employeeSkill: EmployeeSkill;
  @Input() viewOnly: boolean;

  @ViewChild(BlobManagerComponent) blobManagerComponent: BlobManagerComponent;

  constructor() { }

  ngOnInit() {
  }

}
