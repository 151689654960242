<form #mfaSetupForm="ngForm" autocomplete="off" >
  <div class="m-auto">
    <div class="p-grid">
      <div class="p-col-12">
          <h3 class="title">Setup Your Two Factor Authentication Preference</h3>
      </div>
      <div class="p-col-12">
        Please select which method you prefer to authenticate your account. This is how the authentication code will be sent to you.
      </div>
      <div class="p-col-12">
        <ng-select 
          class="custom"
          required
          [items]="preferences" 
          placeholder="Select a Preference" 
          [selectOnTab]="true"
          name="preference" 
          bindLabel="name"
          bindValue="name"
          [(ngModel)]="selectedPreference">
        </ng-select>
      </div>
      <div class="p-col-12" [ngSwitch]="selectedPreference">
        <div *ngSwitchCase="'Email'">
          <label for="email">Email Address</label>
          <input
            class="ui-fluid inputTextBox"
            pInputText
            disabled
            placeholder="Email Address"
            [(ngModel)]="employeePreference.maskedEmail"
            name="email" 
          />
          <div class="fine-print">If you need to update your email address, please contact an admin.</div>
        </div>
        <div *ngSwitchCase="'Text Message'">
          <label for="phone">Text</label>
          <input
            class="ui-fluid"
            pInputText
            disabled
            mask="(XXX) XXX-0000"
            [hiddenInput]="true"
            placeholder="(     )      -"
            [(ngModel)]="employeePreference.phoneNumber"
            name="phone" 
          />
          <div class="fine-print">If you need to update your phone number, please contact an admin.</div>
        </div>
        <div *ngSwitchDefault>
          <!-- blank default -->
        </div>
      </div>
      <div class="p-col-12" *ngIf="selectedPreference">
          <button type="button" pButton class="ui-button-success" label="Save" style="width: 100%;" (click)="save()" [disabled]="disableSave || !mfaSetupForm.valid"></button>
      </div>
      <div class="p-col-12">
        <p-message *ngIf="responseMessage" [severity]="success ? 'success' : 'error'" [text]="responseMessage" style="width:100%">
        </p-message>
      </div>
    </div>
  </div>
</form>