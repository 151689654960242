<div>
  <div class="headerText">Customers</div>
</div>

<div class="p-grid">
  <div class="p-col-6">

  </div>
  <div class="p-col-6" style="text-align: right;">
    <div class="headerToggleDiv">
      <span>Show Inactive Customers</span>
      <p-checkbox binary="true" name="showInactive" [(ngModel)]="getInactives" (ngModelChange)="setCustomers()">
      </p-checkbox>
    </div>
    <button type="button" class="btn btn-success addNewButton" (click)="addNew();" [disabled]="!canAddAndDelete">Add New</button>
  </div>
</div>

<p-table
  #customerTable
  [columns]="cols"
  [value]="customers"
  [paginator]="true"
  [rows]="30"
  [lazy]="true"
  (onLazyLoad)="loadRequestLazy($event)"
  [responsive]="true"
  [totalRecords]="totalRecords"
  [loading]="loading"
  >
  <ng-template pTemplate="caption">
    <div style="display: flex; justify-content: space-between;">
      <span>
        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
        <input
          type="text"
          pInputText
          size="50"
          placeholder="Search Customers..."
          [(ngModel)]="searchTerm"
          style="width:auto"
          (input)="customerTable.filterGlobal($event.target.value, 'contains')">
        </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-customer let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{customer.fullName}}</td>
      <td style="overflow-wrap: break-word;"><span class="ui-column-title">{{columns[1].header}}</span>{{customer.customerType}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{customer.phoneNumber | phoneNumber}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{customer.address}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{customer.state}}</td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{customer.zip}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>{{customer.taxExempt ? 'Tax Exempt' : 'Taxable'}}</td>
      <td><span class="ui-column-title">{{columns[7].header}}</span>{{customer.active == true ? 'Active' : 'Not Active'}}</td>
      <td>
        <button pButton type="button" icon="pi pi-pencil" (click)="editCustomer(customer)"
          class="ui-button-secondary blackBorder">
        </button>
        <button pButton [disabled]="!canAddAndDelete" type="button" icon="pi pi-trash"
          (click)="deleteCustomer(customer)" class="ui-button-danger tableButtonMarginLeft"
          *ngIf="showDeleteForCustomer(customer)">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
