import * as tslib_1 from "tslib";
import { BaseSchedulingViewComponent } from '../base-scheduling-view/base-scheduling-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { BaseCalendarComponent } from '../base-calendar/base-calendar.component';
var BaseWorkViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BaseWorkViewComponent, _super);
    function BaseWorkViewComponent(dragAndDropService) {
        return _super.call(this, dragAndDropService) || this;
    }
    // This captures the event emitter object thing from
    // scheduled-item-bucket-card.component so it can gray out the
    // buckets that don't meet the WO's requirements
    BaseWorkViewComponent.prototype.onWoDragged = function (event) {
        this.woDragStart(event.workOrder);
    };
    // This captures the event emitter object thing from
    // scheduled-item-bucket-card.component so it can ungray out
    // all the buckets
    BaseWorkViewComponent.prototype.onWoDraggedEnd = function () {
        this.clearGrayOverlayOnBuckets();
    };
    // these are on the work base because we are only checking for work task errors
    BaseWorkViewComponent.prototype.woDragStart = function (workOrder) {
        // Get all the bucket components and check the bucket to see if the component needs to be grayed out
        var bucketComponets = this.calendarComponent.bucketCardComponents;
        bucketComponets.forEach(function (bucketComponet) {
            workOrder.workOrderWorkTasks.forEach(function (wowt) {
                // If the equipment type isn't found in the bucket's equipment, gray it out
                wowt.workTask.workTaskEquipment.forEach(function (wte) {
                    if (!bucketComponet.bucket.scheduledBucketEquipment
                        .find(function (sbe) { return sbe.equipment.equipmentType.type === wte.equipmentType.type; })) {
                        bucketComponet.grayOut = true;
                    }
                });
                // Skills of all the employees.
                var skillsInBucket = bucketComponet.bucket.scheduledBucketEmployees
                    .map(function (sbe) { return sbe.employee.employeeSkills; }).reduce(function (acc, curr) { return acc.concat(curr); }, []);
                // If the skill name isn't found in the bucket's skills, gray it out
                wowt.workTask.workTaskSkills.forEach(function (wts) {
                    if (!skillsInBucket.find(function (sib) { return sib.skill.name.toLowerCase().includes(wts.skill.name.toLowerCase()); })) {
                        bucketComponet.grayOut = true;
                    }
                });
            });
        });
    };
    BaseWorkViewComponent.prototype.clearGrayOverlayOnBuckets = function () {
        // Clear the gray overlay when WO is done being dragged
        var bucketComponets = this.calendarComponent.bucketCardComponents;
        bucketComponets.forEach(function (bucketComponet) {
            bucketComponet.grayOut = false;
        });
    };
    BaseWorkViewComponent.prototype.taskDragStart = function (workTask) {
        // Get all the bucket components and check the bucket to see if the component needs to be grayed out
        var bucketComponets = this.calendarComponent.bucketCardComponents;
        bucketComponets.forEach(function (bucketComponet) {
            workTask.workTaskEquipment.forEach(function (wte) {
                if (!bucketComponet.bucket.scheduledBucketEquipment
                    .find(function (sbe) { return sbe.equipment.equipmentType.type === wte.equipmentType.type; })) {
                    bucketComponet.grayOut = true;
                }
            });
            // Skills of all the employees.
            var skillsInBucket = bucketComponet.bucket.scheduledBucketEmployees
                .map(function (sbe) { return sbe.employee.employeeSkills; }).reduce(function (acc, curr) { return acc.concat(curr); }, []);
            // If the skill name isn't found in the bucket's skills, gray it out
            workTask.workTaskSkills.forEach(function (wts) {
                if (!skillsInBucket.find(function (sib) { return sib.skill.name.toLowerCase().includes(wts.skill.name.toLowerCase()); })) {
                    bucketComponet.grayOut = true;
                }
            });
        });
    };
    BaseWorkViewComponent.prototype.taskDragEnd = function () {
        this.clearGrayOverlayOnBuckets();
    };
    return BaseWorkViewComponent;
}(BaseSchedulingViewComponent));
export { BaseWorkViewComponent };
