<div style="margin: 1em;">
  <h3 style="margin-bottom: .5em;">WORK ORDERS WITH PARTIALLY COMPLETE WORK</h3>

  <label for="startDate">Start Date: &nbsp;</label>
  <p-calendar id="startDate" [(ngModel)]="startDate" [showIcon]="true" (ngModelChange)="onDateChange()"></p-calendar>

  <label for="endDate" style="margin-left: 1em;">End Date: &nbsp;</label>
  <p-calendar id="endDate" [(ngModel)]="endDate" [showIcon]="true" (ngModelChange)="onDateChange()"></p-calendar>
  <span *ngIf="isLoading" style="margin-left:8px">
    <i class="pi pi-spin pi-spinner" style="font-size: 1.5em"></i>
  </span>
</div>

<p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
  <p-tabPanel header="Tree Wellness">
    <br>
    <app-invoice-partial-work-row *ngFor="let workOrder of wellnessWOs" [workOrder]="workOrder">
    </app-invoice-partial-work-row>
  </p-tabPanel>

  <p-tabPanel header="Tree Work">
    <br>
    <app-invoice-partial-work-row *ngFor="let workOrder of workWOs" [workOrder]="workOrder">
    </app-invoice-partial-work-row>
  </p-tabPanel>
</p-tabView>