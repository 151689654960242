import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, Inject } from '@angular/core';
import { BaseWorkOrderCardComponent } from '../base-work-order-card/base-work-order-card.component';
import { WorkOrderWellnessTask, WellnessWorkOrder, EquipmentType } from 'src/app/services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

export abstract class BaseWellnessWorkOrderCardComponent extends BaseWorkOrderCardComponent implements OnInit {

  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
    @Inject(Router) router: Router,
    @Inject(PriorityService) priorityService: PriorityService,
    @Inject(WorkOrderHelperService) workOrderHelperService: WorkOrderHelperService,
    @Inject(AuthHelperService) authHelper: AuthHelperService
  ) {
    super(dragAndDropService, router, priorityService, workOrderHelperService, authHelper);
  }

  @Input() workOrder: WellnessWorkOrder;
  @Input() bucketId: number;

  @Input() showMap = false;
  @Output() dragStartEvent: EventEmitter<WorkOrderWellnessTask> = new EventEmitter<WorkOrderWellnessTask>();
  @Output() taskDragEndEvent = new EventEmitter();

  scheduledTasks: WorkOrderWellnessTask[];
  unscheduledTasks: WorkOrderWellnessTask[];

  scheduledTasksTotal: number;

  priceSummary: number;
  hoursSummary: number;

  // See dblclickHandler()
  clickTime = 0;

  abstract setTasks(): void;
  abstract setBottomSection(): void;
  public updateWorkOrderDisplay(): void {
    this.setTasks();
    this.setBottomSection();
  }

  ngOnInit() {
    this.updateWorkOrderDisplay();
    this.workOrder.mostUrgentPriorityLevel = this.priorityService.GetMostUrgentPriorityLevel(this.workOrder);
    this.dragAndDropService.addWorkOrderTaskListTOCDKList(this.workOrder);

    this.dragAndDropService.getWorkOrderUpdater().subscribe(wo => {
      if (wo instanceof WellnessWorkOrder && wo.id === this.workOrder.id) {
        this.workOrder = wo;
      }
      this.updateWorkOrderDisplay();
    });

    this.hasCompanyWideNotes = this.workOrder.customer.customerComments.some(comment => comment.companyWide && comment.active);
    this.canSeeHiddenPrice = this.authHelper.getDecodedAccessToken().claimNames.includes('View Hidden Prices');
  }

  // override
  shouldDisableWODrag() {
    return super.shouldDisableWODrag() &&
    // everything that is on this card is disabled
    this.workOrder.workOrderWellnessTasks
      .filter(t => this.bucketId ? t.wellnessTask.currentBucketId === this.bucketId || t.wellnessTask.goBackBucketId === this.bucketId
                                 : (!t.wellnessTask.currentBucketId || (t.wellnessTask.isGoBack && !t.wellnessTask.goBackBucketId)))
      .every(t => this.shouldDisableTaskDrag(t.wellnessTask));
  }

  // Double click doesn't work on mobile
  // https://stackoverflow.com/questions/50752562/angular-4-dblclick-event-not-working-in-mobile-view
  dblclickHandler() {
    if (!this.clickTime) {
      // set first click
      this.clickTime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (((new Date().getTime()) - this.clickTime) < 600) {
        // double click occurred
        this.navigateToWorkOrder();
        this.clickTime = 0;
      } else {
        // not a double click so set as a new first click
        this.clickTime = new Date().getTime();
      }
    }
  }

  navigateToWorkOrder() {
    if (confirm('Would you like to redirect to the work order page?')) {
      // this.router.navigateByUrl('wellnessWorkOrderMaintenance/' + this.workOrder.id);
      window.open('wellnessWorkOrderMaintenance/' + this.workOrder.id, '_blank');
    }
  }

  dragStart(workOrderWellnessTask: WorkOrderWellnessTask, event) {
    if (this.workOrder.isMultiday) {
      const evt = document.createEvent('MouseEvents');
      evt.initEvent('mouseup', true, true);
      (event.source.element.nativeElement).dispatchEvent(evt);
      if (confirm('This is a multiday order. Would you like to redirect to the multiday scheduling page?')) {
        this.router.navigateByUrl('/scheduling/multiday/wellness');
      }
    }
    this.dragAndDropService.dragStartWO = this.workOrder;
    this.dragStartEvent.emit(workOrderWellnessTask);
  }

  taskDragEnd() {
    this.taskDragEndEvent.emit();
  }
}
