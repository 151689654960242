import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {
  public readonly reportLink: SafeResourceUrl;

  constructor(private configService: AppConfigService,
    private sanitizer: DomSanitizer) {
    this.reportLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.configService.appConfig.reportLink);
  }

  ngOnInit() {
  }
}
