<p-card class="crew-card" [ngClass]="{'grayOverlay': workOrder.completionStatus == 'Completed' || workOrder.completionStatus =='Unable to be Completed'}" (click)="dblclickHandler()">
  <p-header>
    <div class="wo-header ui-g">
      <div class="zip-code ui-g-6"
        [style.background]="(workOrder.address && workOrder.address.geoColor) ? workOrder.address.geoColor : ''"
        [style.color]="(workOrder.address && workOrder.address.textColor) ? workOrder.address.textColor : ''">
        {{workOrder.address.zip}}
      </div>
      <div class="status ui-g-6" (mouseenter)="datePanel.show($event)" (mouseleave)="datePanel.hide($event)"
        [style.background]="getPriorityBackgroundColor()"
        [style.color]="getPriorityColor()">
        {{workOrder.mostUrgentPriorityLevel?.status}}
        <p-overlayPanel #datePanel [appendTo]="'body'">
          <div>
            {{workOrder.mostUrgentPriorityLevel?.status}}
          </div>
          <div *ngIf="workOrder.completionStatus != 'Completed' && workOrder.completionStatus !='Unable to be Completed'">
            <div *ngFor="let task of tasks">
              {{getDueDateDisplay(task)}}
            </div>
          </div>
        </p-overlayPanel>
      </div>
    </div>
    <!-- <div *ngIf="crewErrors.length > 0" style="float: right;">
      <i class="pi pi-exclamation-triangle" (click)="errorPanel.toggle($event)"></i>
      <p-overlayPanel #errorPanel>
        <div *ngFor="let error of crewErrors">
          {{error}}
        </div>
      </p-overlayPanel>
    </div> -->
  </p-header>

  <div style="padding: 8px 0 14px;">
    <div class="center">
      <p><a [routerLink]="" (click)="navigateToWorkOrder()">{{workOrder.quoteNumber}}</a></p>
    </div>
    <div class="center">
      <p>{{customCustomerService.getCustomerFullName(workOrder.customer)}}</p>
      <i class="fa fa-sticky-note" (click)="op.toggle($event)" *ngIf="hasCompanyWideNotes"></i>
        <p-overlayPanel #op [appendTo]="'body'">
          <app-customer-comment-view-only [customer]="workOrder.customer"></app-customer-comment-view-only>
        </p-overlayPanel>
    </div>
    
    <div class="center equipment-type">
      {{ getEquipmentTypeDisplay() ? getEquipmentTypeDisplay() : 'No equipment' }}
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col">
      <p-calendar [(ngModel)]="bigDayBucket.fromDate" class="secondary" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" [maxDate]="bigDayBucket.toDate"
        [appDateLessThanAllSubrangeValidator]="bigDayBucketSubranges" appendTo="body">
      </p-calendar>
    </div>
    <div class="p-col-1 to">
      to
    </div>
    <div class="p-col">
      <p-calendar [(ngModel)]="bigDayBucket.toDate" class="secondary leftThis" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" [minDate]="bigDayBucket.fromDate"
        [appDateGreaterThanAllSubrangeValidator]="bigDayBucketSubranges" appendTo="body"></p-calendar>
    </div>
  </div>

  <div class="toggle-tasks">
    <div *ngIf="isCollapsed" style="text-align: right;">
      <span class="point" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        +
      </span>
    </div>
    <div *ngIf="!isCollapsed" style="text-align: right;">
      <span class="point" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="plus-minus-span">
        -
      </span>
    </div>
    <div [ngbCollapse]="isCollapsed" class="crew-work-orders">
      <div class="p-col-12 clearfix">
        <button pButton class="ui-button-rounded ui-button-raised ui-button-success float-right" label="Add New"
          (click)="onAddSubrange()"></button>
      </div>
      <div>
        <div *ngFor="let subrange of bigDayBucketSubranges" class="inner-card">
          <app-multiday-subrange-card [bigDayBucketSubrange]="subrange" [bigDayBucket]="bigDayBucket"
            (deleted)="deleteSubrange($event)">
          </app-multiday-subrange-card>
          <br>
        </div>
        <div *ngIf="bigDayBucketSubranges && bigDayBucketSubranges.length == 0" class="inner-card drag-placeholder">
          <p-card>Add a Subrange</p-card>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <div class="clearfix">
      <button pButton type="button" label="Save" [disabled]="isSaving"
        class="ui-button-rounded ui-button-raised ui-button-success float-right" (click)="saveAll()"></button>
    </div>
  </p-footer>
</p-card>
