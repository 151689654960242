import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { BaseWellnessViewComponent } from '../base-wellness-view/base-wellness-view.component';
import { WeekCalendarViewComponent } from '../week-calendar-view/week-calendar-view.component';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

@Component({
    selector: 'app-wellness-week-view',
    templateUrl: './wellness-week-view.component.html',
    styleUrls: ['./wellness-week-view.component.css']
})
export class WellnessWeekViewComponent extends BaseWellnessViewComponent implements OnInit {
    selectedView = 'week';

    @ViewChild(WeekCalendarViewComponent) calendarComponent: WeekCalendarViewComponent;

    constructor(
        @Inject(DragAndDropService) dragAndDropService: DragAndDropService
    ) {
        super(dragAndDropService);
    }

    ngOnInit() {
    }

}
