import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
function bootstrapFailed(val) {
  document.getElementById('bootstrap-fail').style.display = 'block';
  document.getElementById('bootstrap-fail').innerHTML = val;
  console.error('bootstrap-fail', val);
}

fetch('/assets/data/appConfig.json')
.then(response => response.json())
.then(config => {
  if (!config || !config['apiUrl']) {
    bootstrapFailed(config);
    return;
  }
  // Store the response somewhere that your ConfigService can read it.
  window['tempConfigStorage'] = config;
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      if ('serviceWorker' in navigator && environment.deployed) {
        // && environment.production) {
        navigator.serviceWorker
          .register('./ngsw-worker.js')
          .then(function(registration) {
            console.log(
              'ServiceWorker registration successful with scope: ',
              registration.scope
            );
          })
          .catch(function(err) {
            console.log('ServiceWorker registration failed: ', err);
          });
      }
    })
    .catch(bootstrapFailed);
})
.catch(bootstrapFailed);
