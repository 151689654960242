<!-- <fieldset ngModelGroup="topWOSection">
  <div class="ui-g">
    <div class="ui-g-12 ui-md-2 ui-lg-4">
      <h6>Created</h6>
      <p-calendar
        [style]="{'width':'100%'}"
        name="createdDate"
        [(ngModel)]="workOrder.createdDate"
        [disabled]="true"
        [inputStyle]="{'width':'100%'}"
        *ngIf="workOrder"></p-calendar>
    </div>
    <div class="ui-g-12 ui-md-5 ui-lg-4">
      <h6>Status</h6>
      <ng-select
        class="custom"
        [searchable]="false"
        [selectOnTab]="true"
        placeholder="Select a Status"
        name="status"
        bindLabel="label"
        disabled="true"
        [(ngModel)]="workOrder.completionStatus"
        *ngIf="workOrder">
      </ng-select>
    </div>
    <div class="ui-g-12 ui-md-5 ui-lg-4">
      <h6>Quote #</h6>
      <input
        pInputText
        class="inputTextBox"
        name="id"
        [ngModel]="estimateDisplayValue"
        disabled/>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <h6>Regional Manager</h6>
      <ng-select
        class="custom"
        required
        [searchable]="false"
        [selectOnTab]="true"
        [items]="employees"
        placeholder="Select a Regional Manager"
        name="rep"
        bindLabel="fullName"
        [(ngModel)]="workOrder.representative"
        [disabled]="!isEditable"
        *ngIf="workOrder">
      </ng-select>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <h6>Payment Due</h6>
      <ng-select
        class="custom"
        required
        [searchable]="false"
        [selectOnTab]="true"
        [items]="paymentDueOptions"
        placeholder="Select an Option"
        name="paymentDueOption"
        bindLabel="paymentDueName"
        [(ngModel)]="workOrder.paymentDueOption"
        [disabled]="!isEditable"
        *ngIf="workOrder">
      </ng-select>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-8" *ngIf="!isCustomer">
      <h6>Select Customer</h6>
      <app-customer-dropdown
        [disabled]="isNotEditableOrExternal() || !allowNewCustomer()"
        [existingSelectionId]="workOrder.customer?.id"
        [required]="true"
        (customerSelected)="setCustomer($event)"
      ></app-customer-dropdown>
      <!--! <ng-select
        class="custom"
        placeholder="Select Customer"
        name="customer"
        [items]="customers"
        [selectOnTab]="true"
        [(ngModel)]="selectedCustomer"
        [disabled]="!isEditable || !allowNewCustomer()"
        [searchFn]="customSearch"
        required
        (ngModelChange)="setCustomer()">
        <ng-template ng-label-tmp let-item="item">
          {{customCustomerService.getCustomerFullNameWithAddress(item)}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          {{customCustomerService.getCustomerFullNameWithAddress(item)}}
        </ng-template>
      </ng-select> !-!->
    </div>
    <div class="ui-g-4" *ngIf="!isCustomer">
      <!-- I'm putting this inside this wrapper because I still want the ui-g-4 as a spacer -!->
      <ng-container *ngIf="allowNewCustomer()">
        <h6>New Customer</h6>
        <button
          type="button"
          class="btn btn-success"
          [disabled]="isNotEditableOrExternal() || workOrder.customer?.id"
          (click)="addNewCustomer()">Add New
        </button>
      </ng-container>
    </div>
    <!-- Customer's info is disabled, if they want to update it they should go to customer maintenance -!->
    <div class="ui-g-12 ui-md-4">
      <h6>First Name</h6>
      <input
        pInputText
        class="inputTextBox"
        required
        name="customerFirstName"
        [value]="workOrder.customer.customerContacts[primaryContactIndex]?.firstName"
        disabled="true"
        *ngIf="workOrder && workOrder.customer"/>
    </div>
    <div class="ui-g-12 ui-md-4">
      <h6>Last Name</h6>
      <input
        pInputText
        class="inputTextBox"
        required
        name="customerLastName"
        [value]="workOrder.customer.customerContacts[primaryContactIndex]?.lastName"
        disabled="true"
        *ngIf="workOrder && workOrder.customer"/>
    </div>
    <div class="ui-g-12 ui-md-4"></div>
    <div class="ui-g-12 ui-md-4">
      <h6>Email</h6>
      <input
        pInputText
        class="inputTextBox"
        name="customerEmail"
        [value]="workOrder.customer.customerContacts[primaryContactIndex]?.emailAddress?.email"
        disabled="true"
        *ngIf="workOrder && workOrder.customer"/>
    </div>
    <div class="ui-g-12 ui-md-4">
      <h6>Phone Number</h6>
      <!-- <input
        pInputText
        mask="(000) 000-0000"
        placeholder="(     )      -"
        class="inputTextBox"
        name="customerPhoneNumber"
        [value]="workOrder.customer.customerContacts[primaryContactIndex]?.phoneNumber"
        disabled="true"
        *ngIf="workOrder && workOrder.customer"/> -!->
      <input
        pInputText
        placeholder="{{workOrder.customer.customerContacts[primaryContactIndex]?.phoneNumber | phoneNumber}}"
        class="inputTextBox"
        disabled
        *ngIf="workOrder && workOrder.customer"/>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-6" *ngIf="!isCustomer">
      <h6>Work Order Address Same As Customer Address</h6>
      <p-checkbox binary="true"
        name="addressSameAsCustomer"
        [(ngModel)]="workOrder.addressSameAsCustomer"
        [disabled]="!isEditable || !workOrder.customer"
        (onChange)="addressSameAsCustomerToggle()"
        *ngIf="workOrder"
      ></p-checkbox>
    </div>
    <div class="ui-g-12 ui-lg-6" *ngIf="!isCustomer && canHidePrice">
      <h6>Hide Price</h6>
      <p-checkbox binary="true"
        name="hidePrice"
        [(ngModel)]="workOrder.hidePrice"
        [disabled]="!isEditable || !workOrder.customer"
        *ngIf="workOrder"
      ></p-checkbox>
    </div>
    <div class="ui-g-12 ui-lg-6" *ngIf="!isCustomer && !canHidePrice">
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-6">
      <h6>Work Order Address</h6>
      <input
        pInputText
        class="inputTextBox"
        required
        name="street"
        [(ngModel)]="workOrder.address.street"
        [disabled]="!isEditable || workOrder.addressSameAsCustomer"
        *ngIf="workOrder && workOrder.address"/>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <h6>City</h6>
      <input
        pInputText
        class="inputTextBox"
        required
        name="city"
        [(ngModel)]="workOrder.address.city"
        [disabled]="!isEditable || workOrder.addressSameAsCustomer"
        *ngIf="workOrder && workOrder.address"/>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <h6>State</h6>
      <ng-select
        class="custom"
        required
        [items]="states"
        placeholder="Select a State"
        name="state"
        [selectOnTab]="true"
        bindLabel="name"
        [(ngModel)]="workOrder.address.state"
        [disabled]="!isEditable || workOrder.addressSameAsCustomer"
        *ngIf="workOrder">
      </ng-select>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <h6>Zip</h6>
      <input
        pInputText
        class="inputTextBox"
        name="zip"
        [(ngModel)]="workOrder.address.zip"
        [disabled]="!isEditable || workOrder.addressSameAsCustomer"
        *ngIf="workOrder && workOrder.address"/>
    </div>
  </div>
  <div class="ui-g" *ngIf="!isCustomer">
    <div class="ui-g-12 ui-md-6">
        <h6>Multi-Day Job?</h6>
        <p-checkbox binary="true"
        name="isMultiday"
        [(ngModel)]="workOrder.isMultiday"
        [disabled]="shouldDisableMultiday || !isEditable"
        [title]="multidayTooltip"
        *ngIf="workOrder"
        ></p-checkbox>
    </div>
  </div>
</fieldset> -->

<fieldset ngModelGroup="topWOSection">
  <div class="ui-g grayBG" style="padding-bottom: 16px;">
    <div class="ui-g-12 ui-md-6 border-thing">
      <div class="ui-g">
        <div class="ui-g-12">
          <span class="bold">Quote Created: </span>
          {{workOrder.createdDate | date:'shortDate'}}
        </div>
        <div class="ui-g-12">
          <span class="bold">
            Status: 
          </span>
          {{workOrder.completionStatus}}
        </div>
        <div class="ui-g-12">
          <span class="bold">Quote #:</span>
          {{estimateDisplayValue}}
        </div>
        <div class="ui-g-12 ui-lg-2" *ngIf="!isNotEditableOrExternal() && allowNewCustomer()">
          <span class="bold">Customer: </span>
        </div>
        <div class="ui-g-12 ui-lg-8" *ngIf="!isNotEditableOrExternal() && allowNewCustomer()">
          <app-customer-dropdown
            [disabled]="isNotEditableOrExternal() || !allowNewCustomer()"
            [existingSelectionId]="workOrder.customer?.id"
            [required]="true"
            (customerSelected)="setCustomer($event)"
          ></app-customer-dropdown>
        </div>
        <div class="ui-g-12 ui-lg-2" *ngIf="!workOrder.customer?.id">
          <button
            type="button"
            class="btn btn-success"
            [disabled]="isNotEditableOrExternal() || workOrder.customer?.id"
            (click)="addNewCustomer()">Add New
          </button>          
        </div>
        <!-- You either see the customer dropdown or a link to the customer page-->
        <div class="ui-g-12 ui-lg-10" *ngIf="isNotEditableOrExternal() || !allowNewCustomer()">
          <span class="bold">Customer: </span>
          <a [routerLink]="['/customerLandingPage', workOrder.customer.id]" class="regular-link">{{workOrder.customer.fullName}}</a>
        </div>
        <div class="ui-g-12" *ngIf="workOrder.customer?.id">
          <span class="bold">Customer Phone: </span> {{workOrder.customer.primaryPhone | phoneNumber}}
        </div>
        <div class="ui-g-12" *ngIf="workOrder.customer?.id">
          <span class="bold">Customer Email: </span> {{customCustomerService.getPrimaryContact(workOrder.customer).emailAddress.email}}
        </div>
        <div class="ui-g-12">
          <span class="bold">Worksite Address: </span>
          <span *ngIf="workOrder.address">
            <span *ngIf="!editWorksiteAddress && workOrder.address.street">
              {{workOrder.address.street}}, {{workOrder.address.city}} {{workOrder.address.state?.abbreviation}}, {{workOrder.address.zip}}
              <i class="fa fa-map-marked-alt regular-link" (click)="workOrderHelperService.openMap(workOrder)"></i> 
            </span>
            &nbsp; 
            <span *ngIf="isEditable && editWorksiteAddress" class="link" (click)="editWorksiteAddressOff()">[Done Editing]</span>
            <span *ngIf="isEditable && !editWorksiteAddress" class="link" (click)="editWorksiteAddressOn()">[Edit]</span>
            <div *ngIf="editWorksiteAddress && isEditable" style="margin-left: 25px;">
              <span>Address Same As Customer Address</span> &nbsp;
              <p-checkbox binary="true"
                name="addressSameAsCustomer"
                [(ngModel)]="workOrder.addressSameAsCustomer"
                [disabled]="!isEditable || !workOrder.customer"
                (onChange)="addressSameAsCustomerToggle()"
                *ngIf="workOrder"
              ></p-checkbox>

              <div *ngIf="!workOrder.addressSameAsCustomer" style="max-width: 500px;">
                <div class="ui-fluid">
                  <span>Address</span>
                  <input
                    pInputText
                    class="inputTextBox"
                    required
                    name="street"
                    [(ngModel)]="workOrder.address.street"
                    [disabled]="!isEditable || workOrder.addressSameAsCustomer"
                    *ngIf="workOrder && workOrder.address"/>
                </div>
                <div class="ui-fluid">
                  <span>City</span>
                  <input
                    pInputText
                    class="inputTextBox"
                    required
                    name="city"
                    [(ngModel)]="workOrder.address.city"
                    [disabled]="!isEditable || workOrder.addressSameAsCustomer"
                    *ngIf="workOrder && workOrder.address"/>
                </div>
                <div class="ui-fluid">
                  <span>State</span>
                  <ng-select
                    class="custom"
                    required
                    [items]="states"
                    placeholder="Select a State"
                    name="state"
                    [selectOnTab]="true"
                    bindLabel="name"
                    [(ngModel)]="workOrder.address.state"
                    [disabled]="!isEditable || workOrder.addressSameAsCustomer"
                    *ngIf="workOrder">
                  </ng-select>
                </div>
                <div class="ui-fluid">
                  <span>Zip</span>
                  <input
                    pInputText
                    required
                    class="inputTextBox"
                    name="zip"
                    [(ngModel)]="workOrder.address.zip"
                    [disabled]="!isEditable || workOrder.addressSameAsCustomer"
                    *ngIf="workOrder && workOrder.address"/>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="ui-g-12 ui-md-6" *ngIf="isQuote">
      <div class="ui-g-12">
        <span class="bold">
          Services Requested: 
        </span>
        {{quoteRequestItems}}
      </div>
      <div class="ui-g-12 showLineBreaks">
        <span class="bold">Quote Request Description: </span>
        {{quoteRequestDescription}}
      </div>
      <div class="ui-g-12">
        <span class="bold">Customer Provided Uploads: </span>
        <app-blob-manager
          [containerName]="'quote-request'"
          [parentRecordId]="isQuote ? asQuote().quoteRequest?.id : asWorkOrder().quoteWorkOrder.requestForQuoteId"
          [uploadLabel]="'Document/Photo'"
          [viewOnly]="true">
        </app-blob-manager>
      </div>
    </div>
    <div class="ui-g-12 ui-md-6" *ngIf="!isQuote">
      <div class="ui-g-12" *ngIf="!isCustomer">
        <span class="bold">Company Wide Notes: </span>
        <app-customer-comment-view-only [customer]="workOrder.customer"></app-customer-comment-view-only>
      </div>
      <div class="ui-g-12 showLineBreaks" *ngIf="!isCustomer">
        <span class="bold">Message to Crew: </span>
        {{workOrder.crewNote}} &nbsp; <span  *ngIf="isEditable" class="link" (click)="toggleCrewEdit()">[Edit]</span>
        <div *ngIf="editCrewMessage && isEditable">
          <textarea pInputTextarea name="crewMessage" [(ngModel)]="workOrder.crewNote" [rows]="5"  style="width: 95%;"></textarea>
        </div>
      </div>
      <div class="ui-g-12 showLineBreaks">
        <span class="bold">Message to Customer: </span>
        {{workOrder.note}}
      </div>
      <div *ngIf="neighborsPermission" class="ui-g-12 showLineBreaks">
        <span class="bold">Neighbor's Permission: </span>
        {{neighborsPermission.firstName}} {{neighborsPermission.lastName}} granted permission on 
        {{neighborsPermission.signedDate | date: 'shortDate'}}
        for RTE to access the property at {{neighborsPermission.address}}
        for the following activities: {{neighborsPermission.activiesOnNeighborProperty}}
        &nbsp;<a class="link" target="_blank" [routerLink]="['/customerQuotePage', workOrder.quoteId]">[Go To Quote]</a>
      </div>
    </div>
  </div>
</fieldset>

<!-- Add New Customer dialog -->
<!-- Only dialogs inside class scrollableDialog get the CSS for scrolling. Only use scrollableDialog if the dialog actually has enough content that
it needs to scroll because it looks weird otherwise. -->
<div class="scrollableDialog" *ngIf="displayCustomerDialog">
  <p-dialog header="Customer Info" [(visible)]="displayCustomerDialog" [modal]="true" [responsive]="true" [resizable]="false" [baseZIndex]="10000"
      [maximizable]="true" [style]="{'width': '800px'}" [focusOnShow]="false" (onHide)="closedCustomerDialog()" [closable]="false">
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" tabindex="0" role="button" (click)="closedCustomerDialog()" (keydown.enter)="closedCustomerDialog()" (mousedown)="closedCustomerDialog()">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-customer-maintenance [customer]="workOrder.customer" [isDialog]="true" (timeToClose)="closedCustomerDialog($event)" [sendWelcomeEmailOnAdd]="false"></app-customer-maintenance>
  </p-dialog>
</div>
