/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-comment-view-only.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./customer-comment-view-only.component";
import * as i4 from "../../services/auth-helper.service";
var styles_CustomerCommentViewOnlyComponent = [i0.styles];
var RenderType_CustomerCommentViewOnlyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCommentViewOnlyComponent, data: {} });
export { RenderType_CustomerCommentViewOnlyComponent as RenderType_CustomerCommentViewOnlyComponent };
function View_CustomerCommentViewOnlyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.comment; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerCommentViewOnlyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerCommentViewOnlyComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyWideNotes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomerCommentViewOnlyComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "font-style: italic;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No company wide notes.\n"]))], null, null); }
export function View_CustomerCommentViewOnlyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerCommentViewOnlyComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerCommentViewOnlyComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasCompanyWideNotes && !_co.isCustomer); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hasCompanyWideNotes; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CustomerCommentViewOnlyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-comment-view-only", [], null, null, null, View_CustomerCommentViewOnlyComponent_0, RenderType_CustomerCommentViewOnlyComponent)), i1.ɵdid(1, 638976, null, 0, i3.CustomerCommentViewOnlyComponent, [i4.AuthHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerCommentViewOnlyComponentNgFactory = i1.ɵccf("app-customer-comment-view-only", i3.CustomerCommentViewOnlyComponent, View_CustomerCommentViewOnlyComponent_Host_0, { customer: "customer" }, {}, []);
export { CustomerCommentViewOnlyComponentNgFactory as CustomerCommentViewOnlyComponentNgFactory };
