import { Pipe, PipeTransform } from '@angular/core';
import { Customer, SurveyHeader } from '../services_autogenerated/generated_services';
import { CustomerService } from '../services/customer.service';

@Pipe({
  name: 'surveyHeaderFilter'
})
export class SurveyHeaderFilterPipe implements PipeTransform {
  constructor(private customCustomerService: CustomerService) { }

  transform(surveyHeaders: SurveyHeader[], searchTerm: any): any {
    if (!surveyHeaders) { return []; }
    if (!searchTerm) { return surveyHeaders; }

    return surveyHeaders.filter(surveyHeader => {
      const customer: Customer = surveyHeader.customer;
      const searchTermToLower = searchTerm.toLowerCase();

      if (customer) {
        const potentialMatches: string[] = [];

        const customerName = this.customCustomerService.getCustomerFullName(customer);
        potentialMatches.push(customerName);

        const primaryContact = this.customCustomerService.getPrimaryContact(customer);
        const phone = primaryContact && primaryContact.phoneNumber;
        potentialMatches.push(phone);

        // check city, zip, street
        if (customer.address) {
          potentialMatches.push(customer.address.city);
          potentialMatches.push(customer.address.zip);
          potentialMatches.push(customer.address.street);
        }

        // check date sent/completed
        const sentStr = surveyHeader.dateSent && surveyHeader.dateSent.toDateString();
        potentialMatches.push(sentStr);
        const completedStr = surveyHeader.dateCompleted && surveyHeader.dateCompleted.toDateString();
        potentialMatches.push(completedStr);

        // Check for yes/no
        potentialMatches.push((surveyHeader as any).tempSatisfied);

        return potentialMatches.find(s => s && s.toLowerCase().includes(searchTermToLower));
      }

      return true;
    });
  }
}
