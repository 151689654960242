
<form [id]="'custContactForm_'+randomGuid" #custContactForm="ngForm">
  <button pButton [disabled]="!canEdit" type="button" *ngIf="!(token && token.userType == UserTypes.Customer)"
    icon="pi pi-trash" (click)="delete()" class="ui-button-danger" style="float:right">
  </button>
  <div class="ui-g columnPadding" style="padding-top: 0px;">
    <div class="ui-g-12 ui-md-3">
      <h6 *ngIf="!smallerHeaders">First Name</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">First Name</div>
      <input pInputText [disabled]="!canEdit" class="inputTextBox" [(ngModel)]="customerContact.firstName"
        *ngIf="customerContact" [name]="'FN_'+randomGuid" required />
    </div>
    <div class="ui-g-12 ui-md-3">
      <h6 *ngIf="!smallerHeaders">Last Name</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Last Name</div>
      <input pInputText [disabled]="!canEdit" class="inputTextBox" [(ngModel)]="customerContact.lastName"
        *ngIf="customerContact" [name]="'LN_'+randomGuid" required />
    </div>
    <div class="ui-g-12 ui-md-4">
      <h6 *ngIf="!smallerHeaders"> {{customerTypeId == customerTypesEnum.Residential ? 'Relationship' : 'Position'}}</h6>
      <div *ngIf="smallerHeaders" class="rfq-header"> {{customerTypeId == customerTypesEnum.Residential ? 'Relationship' : 'Position'}}</div>
      <input pInputText [disabled]="!canEdit" class="inputTextBox" [(ngModel)]="customerContact.position"
      *ngIf="customerContact" [name]="'position_'+randomGuid" />
    </div>
    <div *ngIf="!(token && token.userType == UserTypes.Customer)" class="ui-g-12 ui-md-2">
      <h6 *ngIf="!smallerHeaders">Can Login</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Can Login</div>
      <p-checkbox binary="true" [disabled]="!canEdit || (token && token.userType == UserTypes.Customer)" [(ngModel)]="customerContact.canLogin" *ngIf="customerContact" [name]="'canLogin_'+randomGuid" >
      </p-checkbox>
    </div>
    <div class="ui-g-12 ui-md-3">
      <h6 *ngIf="!smallerHeaders">Phone Number</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Phone Number</div>
      <input
        pInputText
        mask="(000) 000-0000"
        placeholder="(     )      -"
        [style]="{'border-radius': '5px', 'width': '100%'}"
        [(ngModel)]="customerContact.phoneNumber"
        *ngIf="customerContact && canEdit"
        [name]="'phone_'+randomGuid" 
      />
      <input
        pInputText
        placeholder="{{customerContact.phoneNumber | phoneNumber}}"
        [style]="{'border-radius': '5px', 'width': '100%'}"
        disabled
        *ngIf="customerContact && !canEdit"
      />
    </div>
    <div class="ui-g-12 ui-md-3">
      <h6 *ngIf="!smallerHeaders">Email</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Email</div>
      <input pInputText [disabled]="!canEdit" class="inputTextBox" (blur)="emailChanged(customerContact.emailAddress)"
        [(ngModel)]="customerContact.emailAddress.email" *ngIf="customerContact" [name]="'Email_'+randomGuid" required email />
    </div>
    <div *ngIf="!isCustomer" class="ui-g-12 ui-md-6">
      <h6 *ngIf="!smallerHeaders">Notes</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Notes</div>
      <div *ngIf="customerContact"  class="ui-inputgroup">
        <input pInputText [disabled]="!canEdit" class="inputTextBox"
          [(ngModel)]="customerContact.notes" [name]="'Notes_'+randomGuid" />
        <button pButton [disabled]="!canEdit" type="button" class="ui-button-secondary input-group-icon-btn"
          icon="pi pi-ellipsis-v" (click)="openNotes()"></button>
      </div>
    </div>
    <div *ngIf="!(token && token.userType == UserTypes.Customer)" class="ui-g-12 ui-md-2">
      <h6 *ngIf="!smallerHeaders">Receive Non-Invoicing Emails</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Receive Non-Invoicing Emails</div>
      <p-checkbox binary="true" [disabled]="!canEdit || (token && token.userType == UserTypes.Customer)" [(ngModel)]="customerContact.receiveNonInvoicingEmails" *ngIf="customerContact" [name]="'nonInvoicing_'+randomGuid" >
      </p-checkbox>
    </div>
    <div *ngIf="!(token && token.userType == UserTypes.Customer)" class="ui-g-12 ui-md-2">
      <h6 *ngIf="!smallerHeaders">Receive Invoicing Emails</h6>
      <div *ngIf="smallerHeaders" class="rfq-header">Receive Invoicing Emails</div>
      <p-checkbox binary="true" [disabled]="!canEdit || (token && token.userType == UserTypes.Customer)" [(ngModel)]="customerContact.receiveInvoicingEmails" *ngIf="customerContact" [name]="'invoicing_'+randomGuid" >
      </p-checkbox>
    </div>
  </div>
</form>

<!-- Edit contact notes dialog -->
<div *ngIf="displayNotesDialog">
  <p-dialog header="Notes" [(visible)]="displayNotesDialog" [modal]="true" [responsive]="true" [resizable]="false"
      [baseZIndex]="11000" [style]="{'width': '600px'}" [focusOnShow]="false" (onHide)="closedNotesDialog(false)"
      [closable]="false" appendTo="body">
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" tabindex="0" role="button" (click)="closedNotesDialog(false)" (keydown.enter)="closedNotesDialog(false)" (mousedown)="closedNotesDialog(false)">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <textarea
      pInputTextarea
      [(ngModel)]="editedNote"
      [disabled]="!canEdit"
      [rows]="12"
      style="width: 100%;"
      placeholder=""
      [name]="'NotesDialog_'+randomGuid" >
    </textarea>
    <p-footer>
          <button pButton type="button" class="ui-button-secondary" (click)="closedNotesDialog(false)" label="Cancel"></button>
          &nbsp;
          <button pButton type="button" class="ui-button-success" (click)="closedNotesDialog(true)" label="Save"></button>
    </p-footer>
  </p-dialog>
</div>
