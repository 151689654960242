<form #mfaSetupForm="ngForm" autocomplete="off" >
  <div class="m-auto">
    <div class="p-grid">
      <div class="p-col-12">
        <h3 class="title">Two-Factor Verification</h3>
      </div>
      <div class="p-col-12" *ngIf="!verifying">
        Please click the "Send Code" button to have your verification code sent to you.
      </div>
      <div class="p-col-12" *ngIf="!verifying">
        If you need to change your two-factor verification preferences, please click the "Change Verification Preference" button.
      </div>
      <div class="p-col-12" *ngIf="verifying">
        A code has been sent to you via your preferred method of communication. Please enter the code and click "Submit" to finish signing in. 
      </div>
      <div class="p-col-12">
      </div>
      <div class="p-col-12" *ngIf="!verifying">
        <button type="button" pButton class="ui-button-success" label="Send Code" style="width: 100%; margin-bottom: 10px;" (click)="sendCode()" [disabled]="disableSend"></button>
        <button type="button" pButton class="ui-button-secondary" label="Change Verification Preference" style="width: 100%;" (click)="goToSetup()" [disabled]="disableSend"></button>
      </div>
      <div class="p-col-12 ui-fluid" *ngIf="verifying">
        <input 
          type="text"
          maxlength="6"
          pattern="[0-9]{6}" 
          pInputText
          [disabled]="waitingForVerification"
          [(ngModel)]="enteredCode"
          style="margin-bottom: 10px"
          name="code" 
        /> 
        <button type="button" pButton class="ui-button-success" label="Submit" style="width: 100%;" (click)="verifyCode()" [disabled]="disableSend"></button>
      </div>
      <div class="p-col-12">
        <p-message *ngIf="responseMessage" [severity]="success ? 'success' : 'error'" [text]="responseMessage" style="width:100%">
        </p-message>
      </div>
    </div>
  </div>
</form>