import { WellnessWorkOrder, WellnessWorkOrderGenSvc, WorkWorkOrder, WorkWorkOrderGenSvc, WellnessTaskCompletionStatus, WorkTaskCompletionStatus, WellnessWorkOrderCompletionStatus, WorkWorkOrderCompletionStatus, LogGenSvc, LogViewModel, LogViewModelLevel } from '../services_autogenerated/generated_services';
import { BehaviorSubject } from 'rxjs';
import { MessageWrapperService } from './message-wrapper.service';
import * as i0 from "@angular/core";
import * as i1 from "../services_autogenerated/generated_services";
import * as i2 from "./message-wrapper.service";
var DragAndDropService = /** @class */ (function () {
    function DragAndDropService(wellnessWorkOrderService, workWorkOrderService, logService, messageService) {
        this.wellnessWorkOrderService = wellnessWorkOrderService;
        this.workWorkOrderService = workWorkOrderService;
        this.logService = logService;
        this.messageService = messageService;
        this.allCDKWorkOrderListIds = ['workOrdersPanelList'];
        this.allCDKEquipmentListIds = ['equipmentPanelList'];
        this.allCDKEmployeeListIds = ['employeePanelList'];
        this.bucketWOListIds = [];
        this.bucketUpdater = new BehaviorSubject(null);
        this.workOrderPanelUpdater = new BehaviorSubject(null);
        this.workOrderUpdater = new BehaviorSubject(null);
        this.initializeCDKLists();
    }
    DragAndDropService.prototype.setBucketListDayList = function (bucketListDayList) {
        this.bucketListDayList = bucketListDayList;
    };
    DragAndDropService.prototype.setWorkOrderPanel = function (workOrderPanel) {
        this.workOrderPanel = workOrderPanel;
    };
    DragAndDropService.prototype.setDayBucketIndexRecords = function (dayBucketIndexRecords) {
        this.dayBucketIndexRecords = dayBucketIndexRecords;
    };
    DragAndDropService.prototype.initializeCDKLists = function () {
        this.allCDKWorkOrderListIds = ['workOrdersPanelList'];
        this.allCDKEquipmentListIds = ['equipmentPanelList'];
        this.allCDKEmployeeListIds = ['employeePanelList'];
    };
    DragAndDropService.prototype.addBucketToCDKLists = function (bucket) {
        this.bucketWOListIds.push("bucketWorkOrderList-" + bucket.id);
        this.allCDKWorkOrderListIds.push("bucketWorkOrderList-" + bucket.id);
        this.allCDKEmployeeListIds.push("bucketEmployeeList-" + bucket.id);
        this.allCDKEquipmentListIds.push("bucketEquipmentList-" + bucket.id);
    };
    DragAndDropService.prototype.addWorkOrderTaskListTOCDKList = function (workOrder) {
        this.allCDKWorkOrderListIds.push("woTaskList-" + workOrder.id);
    };
    DragAndDropService.prototype.getBucketUpdater = function () {
        return this.bucketUpdater.asObservable();
    };
    DragAndDropService.prototype.emitBucketUpdateByBucketId = function (bucketId) {
        var bucket = this.dayBucketIndexRecords.find(function (r) { return r.id === bucketId; });
        this.bucketUpdater.next(bucket);
    };
    /**
   * Used to update the provided bucket in the UI. Sets the progress bar, validation, employee list, and equipment list.
   * Ultimately gets subscribed to in base-calendar.component.ts
   * @param {ScheduledBucketDTO=} bucket Bucket that needs updated
   */
    DragAndDropService.prototype.emitBucketUpdate = function (bucket) {
        this.bucketUpdater.next(bucket);
    };
    DragAndDropService.prototype.getWorkOrderPanelUpdater = function () {
        return this.workOrderPanelUpdater.asObservable();
    };
    DragAndDropService.prototype.emitWorkOrderToReAdd = function (workOrder) {
        this.workOrderPanelUpdater.next(workOrder);
    };
    DragAndDropService.prototype.getWorkOrderUpdater = function () {
        return this.workOrderUpdater.asObservable();
    };
    /**
   * Used to update the provided WO card in the UI. Sets the scheduled/unschedule task info and the price/hours summary on the bottom.
   * Ultimately gets subscribed to in base-wellness-work-order-card.component.ts and base-work-work-order-card-component.ts
   * @param {WellnessWorkOrder | WorkWorkOrder=} workOrder The WO that needs to be updated
   */
    DragAndDropService.prototype.emitWorkOrderToUpdate = function (workOrder) {
        this.workOrderUpdater.next(workOrder);
    };
    DragAndDropService.prototype.setDragStartBucket = function (previousBucket) {
        this.dragStartBucket = previousBucket;
    };
    // This update method is here so that the various drop zones can update a bucket and know which index it should be saved as
    // public updateBucketInDatabase(bucket: ScheduledBucketDTO) {
    //     const recordIndex = this.dayBucketIndexRecords.findIndex(x => x.id === bucket.id);
    //     this.scheduledItemBucketService.update(bucket, bucket.id.toString())
    //         .subscribe(x => {
    //             this.dayBucketIndexRecords[recordIndex] = x;
    //         },
    //         error => {
    //             this.messageService.add({
    //                 severity: 'error',
    //                 summary: 'Error Message',
    //                 detail: 'Could not save the scheduled bucket, please refresh and retry.'
    //             });
    //         });
    // }
    // This remove method is here so that the various drop zones can remove a WO from scheduling when needed
    DragAndDropService.prototype.removeWorkOrderFromScheduling = function (workOrder, bucketId) {
        var _this = this;
        // Only unschedule tasks that match the bucket the wo came from
        if (workOrder instanceof WellnessWorkOrder) {
            workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Ready_to_Schedule;
            workOrder.workOrderWellnessTasks.forEach(function (task) {
                if (!task.wellnessTask.isGoBack && task.wellnessTask.currentBucketId === bucketId) {
                    task.wellnessTask.scheduleDateFrom = null;
                    task.wellnessTask.scheduleDateTo = null;
                    task.wellnessTask.currentBucketId = null;
                    task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                }
                else if (task.wellnessTask.isGoBack && task.wellnessTask.goBackBucketId === bucketId) {
                    task.wellnessTask.goBackBucketId = null;
                    task.wellnessTask.completionStatus = WellnessTaskCompletionStatus.Ready_to_Schedule;
                }
            });
            this.wellnessWorkOrderService.unscheduleWorkOrder(workOrder.id, bucketId).subscribe(function () {
                _this.afterWorkOrderSubscribe(workOrder);
            }, function (error) {
                var logMessage = new LogViewModel({
                    level: LogViewModelLevel.Error,
                    message: error.stack ? error.message + ' -- Stack: ' + error.stack : error.message,
                    entryDate: new Date()
                });
                _this.logService.logSchedule(logMessage).subscribe();
                _this.messageService.addErrorMessage('Unable to unschedule WO, please refresh and retry.', error);
            });
        }
        if (workOrder instanceof WorkWorkOrder) {
            // Update WO on front end
            workOrder.completionStatus = WorkWorkOrderCompletionStatus.Ready_to_Schedule;
            workOrder.workOrderWorkTasks.forEach(function (task) {
                // Update tasks on front end
                if (!task.workTask.isGoBack && task.workTask.currentBucketId === bucketId) {
                    task.workTask.scheduleDateFrom = null;
                    task.workTask.scheduleDateTo = null;
                    task.workTask.currentBucketId = null;
                    task.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                }
                else if (task.workTask.isGoBack && task.workTask.goBackBucketId === bucketId) {
                    task.workTask.goBackBucketId = null;
                    task.workTask.completionStatus = WorkTaskCompletionStatus.Ready_to_Schedule;
                }
            });
            // Update WO and tasks on back end.
            this.workWorkOrderService.unscheduleWorkOrder(workOrder.id, bucketId).subscribe(function () {
                _this.afterWorkOrderSubscribe(workOrder);
            }, function (error) {
                var logMessage = new LogViewModel({
                    level: LogViewModelLevel.Error,
                    message: error.stack ? error.message + ' -- Stack: ' + error.stack : error.message,
                    entryDate: new Date()
                });
                _this.logService.logSchedule(logMessage).subscribe();
                _this.messageService.addErrorMessage('Unable to unschedule WO, please refresh and retry.', error);
            });
        }
        this.emitWorkOrderToReAdd(workOrder);
    };
    DragAndDropService.prototype.afterWorkOrderSubscribe = function (workOrder) {
        this.emitWorkOrderToUpdate(workOrder);
    };
    DragAndDropService.ngInjectableDef = i0.defineInjectable({ factory: function DragAndDropService_Factory() { return new DragAndDropService(i0.inject(i1.WellnessWorkOrderGenSvc), i0.inject(i1.WorkWorkOrderGenSvc), i0.inject(i1.LogGenSvc), i0.inject(i2.MessageWrapperService)); }, token: DragAndDropService, providedIn: "root" });
    return DragAndDropService;
}());
export { DragAndDropService };
