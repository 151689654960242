import { Injectable } from '@angular/core';
import { Equipment, Employee } from '../services_autogenerated/generated_services';

@Injectable({
  providedIn: 'root'
})
export class CardTitleHelperService {

  constructor() { }

  // Given a list of equipment and employees, create a title that is either a CSV of the 
  // equipment numbers, a CSV of employees, or the string New Crew
  public generateTitle(equipment: Equipment[], employees: Employee[]): string {
    let title = '';
    const employeeList = this.generateEmployeeTitle(employees);
    if (equipment && equipment.length > 0) {
     return equipment.map(e => ' ' + e.number).toString();
    } else if (employees && employees.length > 0) {
      if (employeeList !== '') {
        // No equipment, just list employees
        title = employeeList;
      }
    } else {
      title = 'New Crew';
    }

    return title;
  }

  // Given a list of employees, create a CSV of their last names with the first foreman found being
  // the first employee in the list.
  public generateEmployeeTitle(employees: Employee[]): string {
    let employeeTitle = '';
    if (employees && employees.length > 0) {
      // Find first foreman
      const foreman: Employee = employees.find((e) => e.employeeRoles.some((er) => er.role.name.toLowerCase().includes('foreman')));
      if (foreman) {
        // First foreman is first entry
        employeeTitle = foreman.lastName;
        // Go through array and add rest of employees, skipping the first foreman
        for (var i = 0; i < employees.length; i++) {
          if (employees[i] !== foreman) {
            employeeTitle += ', ' + employees[i].lastName;
          }
        }
      } else {
        return employees.map(e => ' ' + e.lastName).toString();
      }
    } else {
      employeeTitle = '';
    }

    return employeeTitle;
  }
}
