import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { EquipmentType, WellnessTaskCompletionStatus, WorkOrderWellnessTask } from '../../services_autogenerated/generated_services';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { Router } from '@angular/router';
import { BaseWellnessWorkOrderCardComponent } from '../base-wellness-work-order-card/base-wellness-work-order-card.component';
import { CustomerService } from 'src/app/services/customer.service';
import { PriorityService } from 'src/app/services/priority.service';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';

@Component({
  selector: 'app-wellness-work-order-card',
  templateUrl: './wellness-work-order-card.component.html',
  styleUrls: ['./wellness-work-order-card.component.css']
})
export class WellnessWorkOrderCardComponent extends BaseWellnessWorkOrderCardComponent implements OnInit  {

  constructor(
    @Inject(DragAndDropService) dragAndDropService: DragAndDropService,
    @Inject(Router) router: Router,
    @Inject(PriorityService) priorityService: PriorityService,
    @Inject(WorkOrderHelperService) workOrderHelperService: WorkOrderHelperService,
    public customCustomerService: CustomerService,
    @Inject(AuthHelperService) authHelper: AuthHelperService
  ) {
    super(dragAndDropService, router, priorityService, workOrderHelperService, authHelper);
  }

  getEquipmentTypeDisplay() {
    let equipmentTypes: EquipmentType[] = [];

    this.unscheduledTasks.forEach(wowt => {

      if (wowt.wellnessTask.isGoBack) {
        equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskGoBackEquipment.map(wte => wte.equipmentType));
      } else {
        equipmentTypes = equipmentTypes.concat(wowt.wellnessTask.wellnessTaskEquipment.map(wte => wte.equipmentType));
      }
    });

    return this.workOrderHelperService.getUnique(equipmentTypes).map(et => et.abbreviation ? et.abbreviation : et.type).join(' / ');
  }

  // Override
  setTasks() {
    // Total amount of task scheduled, even ones not scheduled on given bucket day
    this.scheduledTasksTotal = this.workOrder.workOrderWellnessTasks.filter(task =>
      !((!task.wellnessTask.isGoBack && !task.wellnessTask.currentBucketId) || (task.wellnessTask.isGoBack && !task.wellnessTask.goBackBucketId))).length;
    // Tasks still unscheduled
    this.unscheduledTasks = this.workOrder.workOrderWellnessTasks.filter(task =>
      (!task.wellnessTask.isGoBack && !task.wellnessTask.currentBucketId) || (task.wellnessTask.isGoBack && !task.wellnessTask.goBackBucketId));
  }

  // Override
  setBottomSection() {
    this.hoursSummary = this.roundToTwoDecimalPlacesIfDecimalPlacesExist(
      this.unscheduledTasks.reduce((acc, curr) => acc + curr.wellnessTask.hours, 0)
    );
    this.hoursSummary += this.roundToTwoDecimalPlacesIfDecimalPlacesExist(
      this.unscheduledTasks.reduce((acc, curr) => acc + curr.wellnessTask.goBackHoursEstimate, 0)
    );
    this.priceSummary = this.roundToTwoDecimalPlacesIfDecimalPlacesExist(
      this.unscheduledTasks.reduce((acc, curr) => acc + curr.wellnessTask.price, 0)
    );
    this.unit = this.hoursSummary > 1 ? 'hrs' : 'hr';
  }

  roundToTwoDecimalPlacesIfDecimalPlacesExist(numberToRound) {
    if (Math.round(numberToRound) !== numberToRound) {
      return numberToRound = numberToRound.toFixed(2);
    } else {
      return numberToRound;
    }
  }
}
