import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-scheduling-tabs',
  templateUrl: './scheduling-tabs.component.html',
  styleUrls: ['./scheduling-tabs.component.css']
})
export class SchedulingTabsComponent implements OnInit {
  items: MenuItem[];
  views: SelectItem[];

  canViewWellness = false;
  canViewWork = false;

  public selectedView: string;

  index: number;

  constructor(
    protected route: ActivatedRoute,
    private authHelperService: AuthHelperService,
    private router: Router
  ) { }

  ngOnInit() {
    this.views = [
      { label: '2 - Day', value: '2day' },
      { label: 'Week', value: 'week' }
    ];

    this.changeType();

    this.route.params.subscribe(val => {
      this.changeType();
    });

    // Default to two day view
    this.selectedView = '2day';

    const claims = this.authHelperService.getDecodedAccessToken().claimNames;
    if (claims.includes('Full Control')) {
      // Admin
      this.items = [
        { label: 'Tree Wellness' },
        { label: 'Tree Work' }
      ];
      this.canViewWellness = true;
      this.canViewWork = true;
    } else if (claims.includes('Be able to Schedule') && claims.includes('View/Edit Pesticide/Chemicals')) {
      // Tree Wellness Production Manager
      this.items = [
        { label: 'Tree Wellness' }
      ];
      // Right now PMs have ability to see all schedules update this if it changes again
      this.canViewWellness = true;
      this.canViewWork = true;
    } else {
      // Tree Work Production Manager
      this.items = [
        { label: 'Tree Work' }
      ];
      // Right now PMs have ability to see all schedules update this if it changes again
      this.canViewWellness = true;
      this.canViewWork = true;
    }
  }

  changeType() {
    const type = this.route.snapshot.paramMap.get('type');

    // If there is a type in the route, pick the tab based on that type (uses tab's index to switch)
    if (type && type === 'work') {
      this.index = 1;
    } else if (type && type === 'wellness') {
      this.index = 0;
    } else {
      // Default to first tab
      this.index = 0;
    }
  }

  handleChange(event) {
    this.index = event.index;
    if (event.index === 0) {
      this.router.navigateByUrl('/scheduling/wellness');
    } else if (event.index === 1) {
      this.router.navigateByUrl('/scheduling/work');
    } else {
      this.router.navigateByUrl('/scheduling/wellness');
    }
  }
}
