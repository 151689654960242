<div>
  <p>
    The above prices, specifications and conditions are satisfactory and are hereby accepted
    Russell Tree Experts is authorized to do the work as specified. By signing the customer
    accepts full financial responsibility and agrees to the terms of this quote and that payment is <b>{{paymentDueOption.paymentDueName}}</b>. If
    insurance is a factor, customer agrees to pay Russell Tree Experts upon completion and
    await reimbursement from the customer's insurance provider.
    <br><br>
    Tree Ownership: The authorizing party warrants that all trees listed are located on the
    customer's property and, if not, that the authorizing party has received full permission from
    the owner to allow specified work. Should any tree be mistakenly identified as to
    ownership, the customer agrees to hold harmless and indemnify Russell Tree Experts ltd.
    for any damages or costs incurred from the result thereof.
    UNLESS OTHERWISE SPECIFIED: 1) Price includes total cleanup of brush and debris. 2) Any
    trees to be removed will be cut to near ground level. 3) Pruning will be done according to the
    ANSI A300 standard. 4) Unless specified in the proposal, Stump Removal is not included 5)
    Stump shavings from stump removal operations will be raked and piled on top of work site.
    <br><br>
    SAFETY: Russell Tree Experts ltd. warrants that all operations will follow the ANSI Z 133.1
    industry safety standard. The authorizing party agrees not to enter the work area during tree
    work operations.
    <br><br>
    CONCEALED CONTINGENCIES: Russell Tree Experts ltd. is not responsible for damages to
    underground sprinklers, drain lines, landscape lighting, invisible fences or underground
    cables/utilities unless the system(s) are adequately and accurately mapped by the authorizing
    party and a copy is presented before or at the time the work is performed.
    <br><br>
    HARDSCAPES: Russell Tree Experts Ltd. Will attempt to minimize impact to surfaces including
    asphalt, concrete and pavers. However cracks, divots and scrapes may happen during the
    course of work. Repairs are not covered in the contract price.
    <br><br>
    LAWN REPAIR: Russell Tree Experts ltd. will attempt to minimize all disturbances to the customer's 
    lawn. Lawn repairs are not included in the contract price unless otherwise noted on this proposal.
    <br><br>
    PLANT HEALTH CARE: Plant Health Care work is done in a good faith effort to attempt to address 
    plant problems, it is not warranted and is not eligible for returns/refunds.
    <br><br>
    YARD SIGNS: Russell Tree Experts may place a yard sign on your property following the completion of the work. 
    If you prefer to not receive a yard sign please let us know. Yard signs are very helpful to our family-owned business and we thank you for your support.
    <br><br>
    CUSTOMER SITE PREPARATION: Customer shall move all lawn ornaments, movable landscape lighting, 
    furniture, vehicles, etc. away from tree work area before work day.
    </p>
    <!-- For Credit Card payments, kindly add 3.0% for merchant fees to payments over $2500. -->
</div>
