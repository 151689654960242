import { Component, OnInit } from '@angular/core';
import {
  Skill, EquipmentType, Equipment, EmployeeSkill, EquipmentAlternative,
  EquipmentSkill,
  EquipmentServiceRecord
} from '../../services_autogenerated/generated_services';
import { SkillGenSvc, EquipmentGenSvc, EquipmentTypeGenSvc } from '../../services_autogenerated/generated_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SpecificationService } from 'src/app/services/specification.service';
import { MaskService } from 'src/app/services/mask.service';

@Component({
  selector: 'app-equipment-maintenance',
  templateUrl: './equipment-maintenance.component.html',
  styleUrls: ['./equipment-maintenance.component.css']
})
export class EquipmentMaintenanceComponent implements OnInit {

  equipment: Equipment;

  equipmentSkillOptions: EquipmentSkill[];
  // equipmentAlternativeOptions: EquipmentAlternative[];
  equipmentTypeOptions: EquipmentType[] = [];
  disableDelete = false;
  equipmentStatus: string;
  statuses: any[];
  saving: boolean;
  cols: any[];
  additionalInfo = false;

  currencyMask: any;

  constructor(
    private skillService: SkillGenSvc,
    private equipmentService: EquipmentGenSvc,
    private equipmentTypeService: EquipmentTypeGenSvc,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private specificationService: SpecificationService,
    private maskService: MaskService
  ) {
    this.statuses = [
      { label: 'In Service', value: true },
      { label: 'Out of Service', value: false }
    ];
    this.cols = [
      { field: 'description', header: 'Description', width: '35%' },
      { field: 'dateServicePerformed', header: 'Date Service Performed', type: Date, width: '14%' },
      { field: 'mileage', header: 'Mileage', type: Number, width: '14%' },
      { field: 'inactiveDateFrom', header: 'Inactive From', type: Date, width: '14%' },
      { field: 'inactiveDateTo', header: 'Inactive To', type: Date, width: '14%' },
      { field: '', header: 'Delete', type: Date, width: '9%' },
    ];
  }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.currencyMask = this.maskService.currencyMask;

    this.equipmentTypeService.getActive().subscribe(et => {
      this.equipmentTypeOptions = et;
    });

    Observable.forkJoin(this.skillService.getAll(), this.equipmentService.getAll())
      .subscribe(([skills, allEquipment]) => {
        if (id) {
          this.equipment = allEquipment.filter(x => x.id === id)[0];
        } else {
          this.disableDelete = true;
          const eq = new Equipment();
          eq.active = true;
          // Even though eqp alternatives has been removed, this line is needed for the save to work.
          eq.equipmentAlternatives = [];
          eq.equipmentSkills = [];
          eq.equipmentServiceRecords = [];
          this.equipment = eq;
        }

        // this.equipmentAlternativeOptions = this.equipment.equipmentAlternatives;

        // const otherAlternatives = allEquipment.filter(e => !this.equipment.equipmentAlternatives.some(ea => ea.equipmentId === e.id))
        //   .filter(e => e.id !== this.equipment.id) // Equipment can't be alternative to itself
        //   .map(e => {
        //     const newEA = new EquipmentAlternative();
        //     newEA.alternative = e;
        //     newEA.alternativeId = e.id;
        //     newEA.equipmentId = id; // may be null if new equipment
        //     return newEA;
        //   });

        // this.equipmentAlternativeOptions = this.equipmentAlternativeOptions.concat(otherAlternatives);

        this.equipmentSkillOptions = this.equipment.equipmentSkills; // start with existing skills

        // add other options from skills they could pick from
        const otherSkills = skills.filter(r => !this.equipment.equipmentSkills.some(es => es.skillId === r.id));
        this.equipmentSkillOptions = this.equipmentSkillOptions.concat(otherSkills.map(s => {
          const newSO = new EquipmentSkill();
          newSO.skill = s;
          newSO.skillId = s.id;
          newSO.equipmentId = id; // may be null if new equipment
          return newSO;
        })).sort((a, b) => a.skill.name.localeCompare(b.skill.name));

        this.setEquipmentStatus();
      });
  }

  save(ngForm: NgForm): void {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    if (ngForm.valid) {
      this.saving = true;
      if (this.equipment.id) {
        this.equipmentService.update(this.equipment, this.equipment.id.toString())
          .subscribe(() => {
            this.doneSaving();
          }, error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: 'Could not save the equipment, please check that all fields are valid and retry.'
            });
            this.doneSaving();
          });
      } else {
        this.equipmentService.add(this.equipment)
          .subscribe(() => {
            this.doneSaving();
          }, error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error Saving',
              detail: 'Could not save the equipment, please check that all fields are valid and retry.'
            });
            this.doneSaving();
          });
      }
    }
  }

  private doneSaving = () => {
    this.router.navigateByUrl('/equipmentList');
    this.saving = false;
  }

  cancel() {
    this.router.navigateByUrl('/equipmentList');
  }

  delete() {
    if (confirm('Are you sure you want to permanently delete this piece of equipment?')) {
      this.equipmentService.delete(this.equipment.id).subscribe(() => {
        this.router.navigateByUrl('/equipmentList');
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the employee, please check that the employee exists and retry.'
        });
      });
    }
  }

  // TODO change this to ESR
  // if the from date changes, validate the To Date
  onESRFromDateChange(form: NgForm, rowIndex: number) {
    this.setEquipmentStatus();
    setTimeout(() => {
      form.controls['inactiveDateTo' + rowIndex].updateValueAndValidity();
    }, 200);
  }

  addNewServiceRecord() {
    const esr = new EquipmentServiceRecord();
    esr.equipmentId = this.equipment.id;
    this.equipment.equipmentServiceRecords.push(esr);
    this.setEquipmentStatus();
  }

  removeServiceRecord(rowIndex) {
    this.equipment.equipmentServiceRecords.splice(rowIndex, 1);
  }

  setEquipmentStatus() {
    if (this.specificationService.EquipmentInService(this.equipment)) {
      this.equipmentStatus = 'In Service';
    } else {
      this.equipmentStatus = 'Out of Service';
    }
  }
}
