import { Component, OnInit, Input } from '@angular/core';
import {
  WellnessWorkOrder,
  WorkWorkOrder,
  StripeGenSvc,
  WellnessTask,
  WorkTask,
  WellnessTaskCompletionStatus,
  WorkTaskCompletionStatus,
  WorkTaskPaymentStatus,
  WellnessTaskPaymentStatus,
  PartialInvoiceRequest
} from 'src/app/services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { WorkOrderHelperService } from 'src/app/services/work-order-helper.service';
import { MessageService, Message } from 'primeng/api';
import { MaskService } from 'src/app/services/mask.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-invoice-partial-work-row',
  templateUrl: './invoice-partial-work-row.component.html',
  styleUrls: ['./invoice-partial-work-row.component.css']
})
export class InvoicePartialWorkRowComponent implements OnInit {
  @Input() workOrder: WellnessWorkOrder | WorkWorkOrder;
  @Input() current: boolean;
  buttonText: string;
  type: string;
  tasks: any[];
  generating = false;
  canInvoice = false;
  cashToInvoice = 0;
  currencyMask: any;
  amountToInvoice = 0;

  constructor(
    private router: Router,
    private woHelper: WorkOrderHelperService,
    private customStripeService: StripeGenSvc,
    private messageService: MessageService,
    private maskService: MaskService,
    protected currencyPipe: CurrencyPipe
  ) { }

  ngOnInit() {
    this.currencyMask = this.maskService.currencyMask;
    if (this.workOrder instanceof WellnessWorkOrder) {
      this.type = 'Wellness';
    } else {
      this.type = 'Work';
    }
    (this.workOrder.workOrderCashCollected as any[]).forEach(x => !x.invoiced ? this.cashToInvoice =+ x.amount : null);
    this.tasks = this.woHelper.getTasks(this.workOrder);

    // All task checkboxes should be disabled unless the task is complete
    this.tasks.forEach(task => {
      this.setDisabledFlag(task);
      if ((task.paymentStatus == null || task.paymentStatus == WorkTaskPaymentStatus.Not_Paid || task.paymentStatus == WellnessTaskPaymentStatus.Not_Paid)) {
        this.canInvoice = true;
      }
    });
  }

  getTreeList(task) {
    if (task instanceof WellnessTask) {
      return task.wellnessTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(',');
    } else if (task instanceof WorkTask) {
      return task.workTaskTreeTypes.map(wttt => wttt.treeType.commonName).join(',');
    }
  }

  getNavigationURL() {
    if (this.workOrder instanceof WellnessWorkOrder) {
      return '/wellnessWorkOrderMaintenance/' + this.workOrder.id;
    } else {
      return '/workWorkOrderMaintenance/' + this.workOrder.id;
    }
  }

  showStatusAndApplicableDateIfAny(task: WellnessTask | WorkTask): string {
    switch (task.completionStatus) {
      case (WellnessTaskCompletionStatus.Ready_to_Schedule || WorkTaskCompletionStatus.Ready_to_Schedule):
      case (WellnessTaskCompletionStatus.Customer_Not_Notified || WorkTaskCompletionStatus.Customer_Not_Notified):
        return  !task.scheduleDateFrom ? 'Not yet scheduled' : task.completionStatus;
      case (WellnessTaskCompletionStatus.Customer_Notified || WorkTaskCompletionStatus.Customer_Notified):
        return (task.scheduleDateFrom ? 'Scheduled: ' + task.scheduleDateFrom.toLocaleDateString() : 'Not yet scheduled');
      case (WellnessTaskCompletionStatus.Completed || WorkTaskCompletionStatus.Completed):
      case (WellnessTaskCompletionStatus.Customer_Satisfied || WorkTaskCompletionStatus.Customer_Satisfied):
        // case (WorkTaskStatus.Paid): // TODO this does not belong
        return 'Completed' + (task.completedDate ? ': ' + task.completedDate.toLocaleDateString() : '');
      case (WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up || WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up):
        return 'Customer Unsatisfied - RM Follow Up';
      case (WellnessTaskCompletionStatus.Go_Back || WorkTaskCompletionStatus.Go_Back):
        return 'Go Back';
      default:
        console.log('Error: Status not assigned a message');
        return task.completionStatus;
    }
  }

  setDisabledFlag(task) {
    // (task.completionStatus
    //   && task.completionStatus !== WellnessTaskCompletionStatus.Completed
    //   && task.completionStatus !== WorkTaskCompletionStatus.Completed)
    //   ||
    if (task.paymentStatus == 'Invoiced' || task.paymentStatus == 'Paid') {
      task.disabled = true;
    } else {
      task.disabled = false;
    }
  }

  confirmGenerateInvoice() {
    this.generating = true;
    var selectedTasks = this.tasks.filter(x => x.selected);
    this.amountToInvoice = this.woHelper.totalAmountByTasks(this.workOrder, selectedTasks);
    const validationResult = this.validateTasksToInvoice(selectedTasks);
    if (validationResult.valid) {
      let confirmMessage = this.getInvoiceConfirmationMessage();
      if (confirm(confirmMessage)) {
        // this.router.navigateByUrl('wellnessWorkOrderMaintenance/' + this.workOrder.id);
        this.generateInvoice(selectedTasks);
      } else {
        this.generating = false;
      }   
    } else {
      this.generating = false;
      if (validationResult.message) {
        this.messageService.add(validationResult.message);
      }
    }
  }

  validateTasksToInvoice(selectedTasks: any[]): ValidationResult {
    var message: Message = null;
    var valid = true;

    const amtValidation = this.validateAmountToInvoice();
    if (!amtValidation.valid) {
      return amtValidation;
    } 

    const numSelectedValidation = this.validateNumSelectedToInvoice(selectedTasks);
    if (!numSelectedValidation.valid) {
      return numSelectedValidation;
    } 

    const minCompletedValidation = this.validateMinCompletedTaskToInvoice(selectedTasks);
    if (!minCompletedValidation.valid) {
      return minCompletedValidation;
    } 

    return {
      message,
      valid
    }
  }

  validateAmountToInvoice(): ValidationResult {
    var message: Message = null;
    var valid = null;

    if (this.amountToInvoice >= this.cashToInvoice) {
      valid = true;
    } else {
      valid = false;
      message = {
        severity: 'error',
        summary: 'Cash Collected Discrepancy',
        detail: 'The cash or check collected cannot be greater than the amount to invoice ($' + this.amountToInvoice.toFixed(2)  + ') ',
        sticky: true
      }
    }

    return {
      message,
      valid
    };
  }

  validateNumSelectedToInvoice(selectedTasks: any[]): ValidationResult {
    var message: Message = null;
    var valid = null;

    if (selectedTasks.length > 0) {
      valid = true;
    } else {
      valid = false;
      message = {
        severity: 'error',
        summary: 'No Tasks Selected',
        detail: 'Select tasks to generate an invoice.',
        sticky: false
      }
    }

    return {
      message,
      valid
    };
  }

  validateMinCompletedTaskToInvoice(selectedTasks: any[]): ValidationResult {
    var message: Message = null;
    var valid = null;
    if (selectedTasks.filter(task => task.completionStatus === WorkTaskCompletionStatus.Completed).length > 0
      || selectedTasks.filter(task => task.completionStatus === WellnessTaskCompletionStatus.Completed).length > 0) {
      valid = true;
    } else {
      valid = false;
      message = {
        severity: 'error',
        summary: 'No Completed Task Selected',
        detail: 'Cannot invoice only uncompleted tasks.',
        sticky: false
      }
    }

    return {
      message,
      valid
    };
  }

  getInvoiceConfirmationMessage(): string {
    if (this.cashToInvoice > 0) {
      return 'The generated invoice will account for ' + this.currencyPipe.transform(this.cashToInvoice, 'USD') + ' already paid in cash or check. Is this amount correct?';
    } else {
      return 'The generated invoice will not account for any payment already paid in cash or check. Is this correct?';
    }
  }

  generateInvoice(selectedTasks) {
    var taskIds = selectedTasks.map(x => x.id);
    var partialInvoiceRequest = new PartialInvoiceRequest();
    partialInvoiceRequest.taskIds = taskIds;
    partialInvoiceRequest.workOrderId = this.workOrder.id;
    if (this.workOrder.constructor == WorkWorkOrder) {
      partialInvoiceRequest.workOrderType = 'Work';
    } else if (this.workOrder.constructor == WellnessWorkOrder) {
      partialInvoiceRequest.workOrderType = 'Wellness';
    }

    this.customStripeService.generatePartialInvoice(partialInvoiceRequest)
      .subscribe(result => {
        selectedTasks.map(x => { x.disabled = true; x.selected = false; x.paymentStatus = x.price == 0 ? 'Paid' : 'Invoiced' });
        this.generating = false;
      }, (error) => {
        this.generating = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error Generating Invoice',
          detail: 'Error generating invoice',
          sticky: true
        });
      });
  }

  confirmInvoiceRowChecked(selected: boolean, task: WellnessTask | WorkTask) {
    if ((task.completionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed ||
        task.completionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed)
        && selected) {
      const result = confirm('Are you sure you want to invoice a task that was unable to be completed?');
      setTimeout(() => {
        const taskAsRow = task as any;
        if (result) {
          taskAsRow.selected = taskAsRow.selected;
        } else {
          taskAsRow.selected = !taskAsRow.selected;
        }
      }, 1);
    }
  }
}

interface ValidationResult {
  message: Message;
  valid: boolean;
}
