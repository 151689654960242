import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from '../../services_autogenerated/generated_services';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.css']
})
export class EmployeeCardComponent implements OnInit {
  @Input() employee: Employee;
  @Input() deleteable: boolean;

  @Output() deleted = new EventEmitter<Employee>();

  isForeman: boolean;

  constructor() { }

  ngOnInit() {
    this.isForeman = this.employee.employeeRoles.some(er => er.role.name.toLowerCase().includes('foreman'));
  }

  // This will emit an event that is caught by whatever container the employee is in (scheduled bucket or crew)
  deleteFromContainer() {
    if (this.deleteable) {
      this.deleted.emit(this.employee);
    }
  }
}
