import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  WellnessWorkOrder, QuoteGenSvc,
  WorkWorkOrder,
  WorkOrderListView,
  AdvancedWorkOrderFilter,
  WorkWorkOrderGenSvc,
  WellnessWorkOrderGenSvc
} from 'src/app/services_autogenerated/generated_services';
import { WorkOrderFilterPipe } from 'src/app/pipes/work-order-filter.pipe';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { Subscription } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { WorkOrderFilterBarComponent } from '../work-order-filter-bar/work-order-filter-bar.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.css'],
  providers: [WorkOrderFilterPipe]
})
export class WorkOrderListComponent implements OnInit {
  @Input() showHeaders: boolean = true;
  @ViewChild(Table) table: Table;

  totalRecords: number;
  rows: number = 30;
  cols: any[];
  allWorkOrders: WellnessWorkOrder[] | WorkWorkOrder[] | WorkOrderListView[]; // Quote are work orders that aren't approved yet
  @ViewChild(WorkOrderFilterBarComponent) filterBarComponent: WorkOrderFilterBarComponent;

  types: SelectItem[];
  selectedType: string;
  maintenanceLink: string;
  rms: any[];
  canSeeHiddenPrice: boolean;
  claims: string[];

  // Keep track of the subscriptions so we can cancel them if they switch between types before the subscription completes
  subscriptions: Subscription[] = [];
  isLoading: boolean;

  lastLazyEvent: LazyLoadEvent;

  advancedFilters: AdvancedWorkOrderFilter = new AdvancedWorkOrderFilter({
    shouldFilterByRM: false,
    shouldFilterByDueDate: false,
    shouldFilterByGeoRegion: false,
    shouldFilterByPriority: false,
    shouldFilterByGDD: false,
    rMs: [],
    priorities: [],
    skip: 0,
    take: this.rows,
    globalFilter: '',
    orderBy: 'Number ASC'
  });

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    public dragAndDropService: DragAndDropService,
    private quoteService: QuoteGenSvc,
    private authHelperService: AuthHelperService,
    public customCustomerService: CustomerService,
    private workWorkOrderService: WorkWorkOrderGenSvc,
    private wellnessWorkOrderService: WellnessWorkOrderGenSvc
  ) {
    this.cols = [
      { field: 'Number', header: 'Number', width: '7%' },
      { field: 'FullName', header: 'Customer Name', width: '25%' },
      { field: 'WellnessTasksCount', header: 'Wellness Tasks', width: '10%' },
      { field: 'WorkTasksCount', header: 'Work Tasks', width: '10%' },
      { field: 'Price', header: 'Price', width: '9%' },
      { field: 'Hours', header: 'Hours', width: '10%' },
      { field: 'CreatedOn', header: 'Created On', width: '10%' },
      { field: 'Status', header: 'Status', width: '12%' },
      { header: '', width: '7%' }
    ];

    const token = this.authHelperService.getDecodedAccessToken();

    this.canSeeHiddenPrice = token.claimNames.includes('View Hidden Prices');

    this.types = [
      { label: 'Wellness WOs', value: 'wellness' },
      { label: 'Work WOs', value: 'work' }
    ];

    // If we see the headers it is on the standalone page
    // If no headers, it's inside the Quote page and should be quote
    if (this.showHeaders) {
      this.selectedType = this.types[0].value;
    } else {
      this.selectedType = 'quote';
    }

    this.maintenanceLink = `${this.selectedType}WorkOrderMaintenance`;
  }

  ngOnInit() {
    const type = this.route.snapshot.paramMap.get('type');

    if (type) {
      this.selectedType = type;
    }

    this.claims = this.authHelperService.getDecodedAccessToken().claimNames;
  }

  changedType() {
    if (this.selectedType === 'wellness') {
      this.router.navigateByUrl('/workOrderList/wellness');
    } else if (this.selectedType === 'work') {
      this.router.navigateByUrl('/workOrderList/work');
    }

    this.allWorkOrders = [];
    this.loadRequestLazy(this.lastLazyEvent);
  }

  loadRequestLazy(event: LazyLoadEvent) {
    this.isLoading = true;
    this.lastLazyEvent = event;
    const sortOption = this.getSortOption(event.sortField, event.sortOrder);

    this.advancedFilters.skip = event.first;
    this.advancedFilters.take = event.rows;
    this.advancedFilters.orderBy = sortOption;
    this.advancedFilters.globalFilter = this.filterBarComponent.searchTerm ? this.filterBarComponent.searchTerm : '';

    this.subscriptions.forEach(sub => {
      // Cancel subscriptions so if they flip through the WO types quickly, it should only show the data for the last type selected
      sub.unsubscribe();
    });

    if (this.selectedType === 'quote') {
      this.maintenanceLink = 'quoteWorkOrderMaintenance';

      this.subscriptions.push(
        this.quoteService.getPaginated(this.advancedFilters).subscribe(res => {
        this.allWorkOrders = res.results;
        this.totalRecords = res.totalResults;
        this.isLoading = false;

        // Only set these if they haven't been set yet.
        if (!this.rms) {
          this.rms = this.getUniqueNames(res.rMs);
        }
      }));
    } else if (this.selectedType === 'wellness') {
      this.maintenanceLink = 'wellnessWorkOrderMaintenance';

      this.subscriptions.push(
        this.wellnessWorkOrderService.getPaginated(this.advancedFilters).subscribe(res => {
        this.allWorkOrders = res.results;
        this.totalRecords = res.totalResults;
        this.isLoading = false;

        // Only set these if they haven't been set yet.
        if (!this.rms) {
          this.rms = this.getUniqueNames(res.rMs);
        }
      }));
    } else {
      this.maintenanceLink = 'workWorkOrderMaintenance';

      this.subscriptions.push(
        this.workWorkOrderService.getPaginated(this.advancedFilters).subscribe(res => {
        this.allWorkOrders = res.results;
        this.totalRecords = res.totalResults;
        this.isLoading = false;

        // Only set these if they haven't been set yet.
        if (!this.rms) {
          this.rms = this.getUniqueNames(res.rMs);
        }
      }));
    }
  }
  // Get wos based on type, wellness/work
  // getWorkOrders() {
  //   this.isLoading = true;

  //   this.subscriptions.forEach(sub => {
  //     // Cancel subscriptions so if they flip through the WO types quickly, it should only show the data for the last type selected
  //     sub.unsubscribe();
  //   });
  //   if (this.selectedType === 'quote') {
  //     // blank
  //   } else if (this.selectedType === 'wellness') {
  //     this.maintenanceLink = 'wellnessWorkOrderMaintenance';
  //     this.subscriptions.push(this.wellnessWorkOrderService.getAll().subscribe(wos => {
  //       this.allWorkOrders = wos.map(wo => {
  //         wo.mostUrgentPriorityLevel = this.priorityService.GetMostUrgentPriorityLevel(wo);
  //         wo.hoursSummary = wo.workOrderWellnessTasks.reduce((acc, curr) => acc + curr.wellnessTask.hours, 0);
  //         wo.priceSummary = wo.workOrderWellnessTasks.reduce((acc, curr) => acc + curr.wellnessTask.price, 0);

  //         return wo;
  //       });

  //       this.rms = this.workOrderHelper.getUnique(wos.map(wo => wo.representative));
  //       this.isLoading = false;
  //     }));
  //   } else {
  //     this.maintenanceLink = 'workWorkOrderMaintenance';
  //     this.subscriptions.push(this.workWorkOrderService.getAll().subscribe(wos => {
  //       this.allWorkOrders = wos.map(wo => {
  //         wo.mostUrgentPriorityLevel = this.priorityService.GetMostUrgentPriorityLevel(wo);
  //         wo.hoursSummary = wo.workOrderWorkTasks.reduce((acc, curr) => acc + curr.workTask.hours, 0);
  //         wo.priceSummary = wo.workOrderWorkTasks.reduce((acc, curr) => acc + curr.workTask.price, 0);

  //         return wo;
  //       });

  //       this.rms = this.workOrderHelper.getUnique(wos.map(wo => wo.representative));
  //       this.isLoading = false;
  //     }));
  //   }
  // }

  OnWoFilteringFinished() {
    const shouldRMFilter = this.advancedFilters.shouldFilterByRM !== this.filterBarComponent.shouldFilterByRM || this.filterBarComponent.shouldFilterByRM;
    this.advancedFilters.shouldFilterByRM = this.filterBarComponent.shouldFilterByRM;
    this.advancedFilters.rMs = this.filterBarComponent.selectedRMs.map(e => e.fullName);

    const shouldDueDateFilter = this.advancedFilters.shouldFilterByDueDate !== this.filterBarComponent.shouldFilterByDueDate || this.filterBarComponent.shouldFilterByDueDate;
    this.advancedFilters.shouldFilterByDueDate = this.filterBarComponent.shouldFilterByDueDate;

    if (shouldDueDateFilter) {
      if (this.filterBarComponent.dueDateFilterFromDate) {
        this.advancedFilters.startDate = new Date(this.filterBarComponent.dueDateFilterFromDate);
      }

      if (this.filterBarComponent.dueDateFilterToDate) {
        this.advancedFilters.endDate = new Date(this.filterBarComponent.dueDateFilterToDate);
      }
    }

    const shouldPriorityFilter = this.advancedFilters.shouldFilterByPriority !== this.filterBarComponent.shouldFilterByPriority || this.filterBarComponent.shouldFilterByPriority;
    this.advancedFilters.shouldFilterByPriority = this.filterBarComponent.shouldFilterByPriority;
    this.advancedFilters.priorities = this.filterBarComponent.selectedPriorities.map(e => e.status);

    const shouldGeoRegionFilter = (this.advancedFilters.shouldFilterByGeoRegion !== this.filterBarComponent.shouldFilterByGeoRegion || this.filterBarComponent.shouldFilterByGeoRegion)
                                  && this.filterBarComponent.geoRegionWorkOrderNumber && this.filterBarComponent.geoRegionSearchRadius;
    this.advancedFilters.shouldFilterByGeoRegion = this.filterBarComponent.shouldFilterByGeoRegion;
    this.advancedFilters.geoRegionMiles = this.filterBarComponent.geoRegionSearchRadius;
    this.advancedFilters.geoRegionOrigQuoteId = this.filterBarComponent.geoRegionWorkOrderNumber;

    const shouldGDDFilter = this.selectedType === 'wellness' &&
                            (this.advancedFilters.shouldFilterByGDD !== this.filterBarComponent.shouldFilterByGDD || this.filterBarComponent.shouldFilterByGDD);
    this.advancedFilters.shouldFilterByGDD = this.filterBarComponent.shouldFilterByGDD;

    if (shouldGDDFilter) {
      this.advancedFilters.gDDStart = this.filterBarComponent.gddStart;
      this.advancedFilters.gDDEnd = this.filterBarComponent.gddEnd;
    }

    const shouldTextFilter = this.advancedFilters.globalFilter !== this.filterBarComponent.searchTerm;

    if (!this.filterBarComponent.geoRegionWorkOrderNumber) {
      this.filterBarComponent.geoRegionWorkOrderFindError = 'Please enter Number';
    } else if (!this.filterBarComponent.geoRegionSearchRadius) {
      this.filterBarComponent.geoRegionWorkOrderFindError = 'Please enter miles';
    } else {
      this.filterBarComponent.geoRegionWorkOrderFindError = '';
    }

    if (shouldRMFilter || shouldDueDateFilter || shouldGeoRegionFilter || shouldPriorityFilter || shouldGDDFilter || shouldTextFilter) {
      const lazyRequest = {
        ...this.lastLazyEvent,
        first: 0,
        rows: this.rows
      };
      this.table.first = 0;
      this.loadRequestLazy(lazyRequest);
    }
  }

  edit(link: string) {
    window.open(link, '_blank');
  }

  getSortOption(field: string, order: number) {
    if (order === 1) {
      return field + ' ASC';
    } else {
      return field + ' DESC';
    }
  }

  private getUniqueNames(arr: any[]) {
    const unique = arr.map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]).sort();

    return unique.map(u => ({fullName: u}));
  }
}
