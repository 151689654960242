import { WorkWorkOrder, QuoteWorkOrder, PriorityLevel, WellnessTaskCompletionStatus, WorkTaskCompletionStatus } from '../services_autogenerated/generated_services';
import { CalculatedPriorityAgingColorService } from './calculated-priority-aging-color.service';
import * as i0 from "@angular/core";
import * as i1 from "./calculated-priority-aging-color.service";
var PriorityService = /** @class */ (function () {
    function PriorityService(calculatedPriorityAgingService) {
        this.calculatedPriorityAgingService = calculatedPriorityAgingService;
    }
    PriorityService.prototype.GetTaskPriority = function (task) {
        if (!task.hasCalculatedPriority) {
            if (task.manualPriorityLevel.id === 1) {
                // go backs are second
                task.manualPriorityLevel.order = -9999999999;
            }
            return task.manualPriorityLevel;
        }
        else {
            return this.calculatedPriorityAgingService.getPriority(task);
        }
    };
    PriorityService.prototype.GetMostUrgentPriorityLevel = function (wo) {
        if (wo instanceof QuoteWorkOrder) {
            var wellness = this.GetMostUrgentWellnessPriorityLevel(wo);
            var work = this.GetMostUrgentWorkPriorityLevel(wo);
            if (wellness && work) {
                return wellness.order < work.order ? wellness : work;
            }
            else if (wellness) {
                return wellness;
            }
            else {
                return work; // OK if undefined
            }
        }
        else if (wo instanceof WorkWorkOrder) {
            return this.GetMostUrgentWorkPriorityLevel(wo);
        }
        else { // (wo instanceof WellnessWorkOrder)
            return this.GetMostUrgentWellnessPriorityLevel(wo);
        }
    };
    // TODO include or exclude those that are scheduled?
    PriorityService.prototype.GetMostUrgentWellnessPriorityLevel = function (wo) {
        var _this = this;
        if (wo.workOrderWellnessTasks.length > 0) {
            var tasksNotComplete = wo.workOrderWellnessTasks
                .filter(function (x) { return x.wellnessTask.active
                && x.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Completed
                && x.wellnessTask.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed; });
            var taskPriorities = tasksNotComplete.map(function (x) { return _this.GetTaskPriority(x.wellnessTask); });
            if (tasksNotComplete.length) {
                return taskPriorities.reduce(function (prev, curr) {
                    if (!curr) {
                        return prev;
                    }
                    else {
                        return (prev.order < curr.order) ? prev : curr;
                    }
                }, { order: Infinity });
            }
            else {
                var completedPriority = new PriorityLevel({
                    order: Infinity, backgroundColor: 'black', textColor: 'white', status: 'Completed'
                });
                return completedPriority;
            }
        }
        else {
            return null;
        }
    };
    PriorityService.prototype.GetMostUrgentWorkPriorityLevel = function (wo) {
        var _this = this;
        if (wo.workOrderWorkTasks.length > 0) {
            var tasksNotComplete = wo.workOrderWorkTasks
                .filter(function (x) { return x.workTask.active
                && x.workTask.completionStatus !== WorkTaskCompletionStatus.Completed
                && x.workTask.completionStatus !== WorkTaskCompletionStatus.Unable_to_be_Completed; });
            var taskPriorities = tasksNotComplete.map(function (x) { return _this.GetTaskPriority(x.workTask); });
            if (tasksNotComplete.length) {
                return taskPriorities.reduce(function (prev, curr) {
                    if (!curr) {
                        return prev;
                    }
                    else {
                        return (prev.order < curr.order) ? prev : curr;
                    }
                }, { order: Infinity });
            }
            else {
                var completedPriority = new PriorityLevel({
                    order: Infinity, backgroundColor: 'black', textColor: 'white', status: 'Completed'
                });
                return completedPriority;
            }
        }
        else {
            return null;
        }
    };
    PriorityService.ngInjectableDef = i0.defineInjectable({ factory: function PriorityService_Factory() { return new PriorityService(i0.inject(i1.CalculatedPriorityAgingColorService)); }, token: PriorityService, providedIn: "root" });
    return PriorityService;
}());
export { PriorityService };
