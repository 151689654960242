import * as i0 from "@angular/core";
var SharedColorService = /** @class */ (function () {
    function SharedColorService() {
    }
    SharedColorService.prototype.findContrastingTextColor = function (h, s, l) {
        var rgb = this.convertHSLtoRGB(h, s, l);
        return this.getFontColor(rgb);
    };
    SharedColorService.prototype.getFontColor = function (rgb) {
        return (((rgb.r * 0.299) + (rgb.g * 0.587) + (rgb.b * 0.114)) > 150) ? 'black' : 'white';
    };
    SharedColorService.prototype.convertHSLtoRGB = function (h, s, l) {
        s /= 100;
        l /= 100;
        var c = (1 - Math.abs(2 * l - 1)) * s, x = c * (1 - Math.abs((h / 60) % 2 - 1)), m = l - c / 2;
        var r = 0, g = 0, b = 0;
        if (0 <= h && h < 60) {
            r = c;
            g = x;
            b = 0;
        }
        else if (60 <= h && h < 120) {
            r = x;
            g = c;
            b = 0;
        }
        else if (120 <= h && h < 180) {
            r = 0;
            g = c;
            b = x;
        }
        else if (180 <= h && h < 240) {
            r = 0;
            g = x;
            b = c;
        }
        else if (240 <= h && h < 300) {
            r = x;
            g = 0;
            b = c;
        }
        else if (300 <= h && h < 360) {
            r = c;
            g = 0;
            b = x;
        }
        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);
        return {
            r: r,
            g: g,
            b: b
        };
    };
    SharedColorService.prototype.hextoRgb = function (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };
    SharedColorService.prototype.hexToRgbStr = function (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        var resultStr = 'rgb(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ')';
        return resultStr;
    };
    SharedColorService.prototype.rgbStrToRgbObject = function (rgbString) {
        return rgbString.substring(4, rgbString.length - 1).replace(/ /g, '').split(',');
    };
    SharedColorService.prototype.rgbObjectToRgbString = function (rgb) {
        return "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";
    };
    SharedColorService.ngInjectableDef = i0.defineInjectable({ factory: function SharedColorService_Factory() { return new SharedColorService(); }, token: SharedColorService, providedIn: "root" });
    return SharedColorService;
}());
export { SharedColorService };
