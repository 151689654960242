import { Component, OnInit, Input } from '@angular/core';
import { Crew, CrewEmployee } from '../../services_autogenerated/generated_services';
import { CardTitleHelperService } from 'src/app/services/card-title-helper.service';

@Component({
    selector: 'app-crew-card',
    templateUrl: './crew-card.component.html',
    styleUrls: ['./crew-card.component.css']
})
export class CrewCardComponent implements OnInit {
  /** This crew card is for the scheduling pages, it has indelible employees/equipment and
    can be dragged onto a day for scheduling.
    Basically, this card is used to view crews.
    WARNING: If you make a change to this card, check the crew creation card to see if it needs the change as well.
   */
  @Input() crew: Crew;
  isCollapsed = true;
  crewTitle: string;
  employeeList: string;
  crewErrors: string[] = [];

  constructor(public titleHelperService: CardTitleHelperService) { }

  ngOnInit() {
    this.setCrewForemanAndEquipment();
    this.validateCrew();
  }

  validateCrew() {
    if (!this.crew.crewEquipment || this.crew.crewEquipment.length < 1) {
      this.crewErrors.push('No equipment');
    }

    if (!this.crew.crewEmployees) {
      this.crewErrors.push('No employees');
    }

    if (this.crew.crewEmployees && !this.crew.crewEmployees.find((e) => e.employee.employeeRoles
        .some((r) => r.role.name.toLowerCase().includes('foreman')))) {
      this.crewErrors.push('No foreman');
    }
  }

  setCrewForemanAndEquipment() {
    this.employeeList = this.titleHelperService.generateEmployeeTitle(this.crew.crewEmployees.map(sbe => sbe.employee));

    this.crewTitle = this.titleHelperService.generateTitle(this.crew.crewEquipment.map(sbe => sbe.equipment), 
      this.crew.crewEmployees.map(sbe => sbe.employee));
  }
}
