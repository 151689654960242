import { Injectable, OnInit, Input } from '@angular/core';
import { PlaidLinkSuccessDTO, StripeGenSvc, Customer } from '../../services_autogenerated/generated_services';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { TokenResult } from 'ngx-stripe';
import { StripeBillingComponent } from '../stripe-billing/stripe-billing.component';
declare var Plaid: any;

@Injectable()
export abstract class BasePaymentProcessingComponent implements OnInit {
    @Input() stripeBillingComponent: StripeBillingComponent;
    @Input() saving: boolean;
    @Input() customer: Customer;
    plaidKey: any;
    plaidEnvironment: any;
    plaidHandler: any;

    abstract processBillingRequest(token: string);
    abstract onError(event);

    constructor(
        private behaviorSubjectService: BehaviorSubjectService,
        private scriptLoaderService: ScriptLoaderService,
        public customStripeService: StripeGenSvc,
    ) {
        this.customStripeService = customStripeService;
     }

    ngOnInit(): void { 
    }

    setupPlaid() {
        this.getPlaidEnvironment();
        this.getPlaidKey();
        this.initializePlaid();
    }

    getPlaidEnvironment() {
        this.behaviorSubjectService.plaidEnvironmentSubject.subscribe(env => {
        this.plaidEnvironment = env;
    });
    }

    getPlaidKey() {
        this.behaviorSubjectService.plaidPublicKeySubject.subscribe(env => {
        this.plaidKey = env;
    });
    }
     
    initializePlaid() {
    this.scriptLoaderService.load({
        name: 'PlaidLink',
        src: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
        loaded: false
    }).subscribe(data => {
        this.plaidHandler = Plaid.create({
            clientName: 'Russell Tree',
            env: this.plaidEnvironment,
            key: this.plaidKey,
            product: ['auth'],
            selectAccount: true,
            // webhook: 'https://requestb.in', // Optional – use webhooks to get transaction and error updates
            onLoad: () => {
                // Optional, called when Link loads
            },
            onSuccess: (public_token, metadata) => {
                this.saving = true;
                const accessTokenRequest = new PlaidLinkSuccessDTO();
                accessTokenRequest.publicToken = public_token;
                accessTokenRequest.account_id = metadata.account_id;
                this.customStripeService.getPlaidAccessToken(accessTokenRequest)
                    .subscribe((bankAccountToken) => {
                        this.processBillingRequest(bankAccountToken);
                    });
            },
            onExit: (err, metadata) => {
                // The user exited the Link flow.
                this.saving = false;
                if (err != null) {
                    // The user encountered a Plaid API error prior to exiting.
                    alert('Plaid encountered an error. Please try again.');
                }
                // metadata contains information about the institution that the user selected and the most recent API request IDs.
                // Storing this information can be helpful for support.
            },
            onEvent: (eventName, metadata) => {
                // Optionally capture Link flow events, streamed through this callback as your users connect an Item to Plaid.
                // For example:
                // eventName = "TRANSITION_VIEW"
                // metadata  = {
                //   link_session_id: "123-abc",
                //   mfa_type:        "questions",
                //   timestamp:       "2017-09-14T14:42:19.350Z",
                //   view_name:       "MFA",
                // }
            }
        });
    });
    }

    submitACH() {
        // if (this.customer.paymentInfoRequired) {
            this.plaidHandler.open(); // will pick up the afterSubscribe in the onsuccesshandler in plaid.create
        // } else {
        //     this.afterTokenSubscribe(null);
        // }
    }

    submitCard() {
        if (!this.stripeBillingComponent.renderSavedCard) {
        this.stripeBillingComponent.generateCCToken()
            .subscribe(this.afterTokenSubscribe);
        } else {
            this.afterTokenSubscribe(null);
        }
    }

    private afterTokenSubscribe = (result: TokenResult) => {
        this.saving = true;
        // console.log(result)
        if (result && result.token) {
            // Update with new token
            this.processBillingRequest(result.token.id);
        } else if (result && result.error) { // && this.customer.paymentInfoRequired
            // Error creating the token
            this.saving = false;
            if (!this.stripeBillingComponent.checkFormError()) {
                console.log('Something is wrong:', result.error);
            } else {
                console.log('Something is wrong:', ' incomplete form');
            }
        } else if (result == null) { // || !this.customer.paymentInfoRequired
            // Update existing customer payment method
            this.processBillingRequest("");
        }
    }
}
