<div class="headerText">Work Orders</div>

<p-table 
  class="no-pad-table-header"
  [columns]="cols" 
  [value]="allWorkOrders" 
  [paginator]="true" 
  [rows]="rows" 
  [responsive]="true"
  [lazy]="true"
  (onLazyLoad)="loadRequestLazy($event)"
  [totalRecords]="totalRecords"
  [loading]="isLoading"
  [sortField]="'DateCompleted'"
  [sortOrder]="-1"
>
<ng-template pTemplate="caption">
  <div class="ui-helper-clearfix">
    <button type="button" pButton iconPos="left" label="Export" (click)="exportToCSV()" style="float:left" [disabled]="isLoading"></button>
  </div>
</ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width" style="text-align: center;">
        <span [pTooltip]="col.info">{{col.header}}</span>
        <p-sortIcon *ngIf="col.header != '' && col.field" [field]="col.field" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
    <tr>
      <th *ngFor="let col of columns" class="ui-fluid" [ngSwitch]="col.field">
        <input *ngSwitchCase="'Number'" pInputText class="inputTextBox" type="text" [(ngModel)]="advancedFilters.wONumberSearch" (ngModelChange)="searchInput.next($event)">
        <p-calendar *ngSwitchCase="'DateCompleted'" dateFormat="m/d/y" [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" appendTo="body" [showButtonBar]="true" (onClearClick)="searchInput.next($event)" [yearRange]="yearRange" [monthNavigator]="true" [yearNavigator]="true"  (ngModelChange)="searchInput.next($event)"></p-calendar>
        <p-calendar *ngSwitchCase="'SignedDate'" dateFormat="m/d/y" [(ngModel)]="signedRangeDates" selectionMode="range" [readonlyInput]="true" appendTo="body" [showButtonBar]="true" (onClearClick)="searchInput.next($event)" [yearRange]="yearRange" [monthNavigator]="true" [yearNavigator]="true"  (ngModelChange)="searchInput.next($event)"></p-calendar>
        <p-multiSelect *ngSwitchCase="'ServiceType'" [options]="serviceTypes" [(ngModel)]="advancedFilters.services" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="searchInput.next($event)"></p-multiSelect>
        <p-multiSelect *ngSwitchCase="'Representative'" [options]="rms" [(ngModel)]="advancedFilters.rMs" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="searchInput.next($event)"></p-multiSelect>
        <p-dropdown *ngSwitchCase="'CommissionEarned'" [options]="commissionEarnedItems" [style]="{'width':'100%'}" [(ngModel)]="advancedFilters.commissionEarnedSearch" (onChange)="searchInput.next($event)" placeholder="Choose" [showClear]="true"></p-dropdown>
        <p-multiSelect *ngSwitchCase="'PaidStatus'" [options]="paidStatusItems" [(ngModel)]="advancedFilters.paidStatuses" [panelStyle]="{minWidth:'12em'}" (ngModelChange)="searchInput.next($event)"></p-multiSelect>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workOrder let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td>
        <span class="ui-column-title">{{columns[0].header}}</span>
        <span class="regular-link" (click)="navigateToWorkOrder(workOrder)">
          {{workOrder.number}}
        </span>
      </td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{workOrder.dateSigned}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{workOrder.dateCompleted}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{workOrder.serviceType}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{workOrder.representative}}</td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{workOrder.total | currency: 'USD' : 'symbol'}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>{{workOrder.stumpGrindingTotal | currency: 'USD' : 'symbol'}}</td>
      <td><span class="ui-column-title">{{columns[7].header}}</span>{{workOrder.rentalCost | currency: 'USD' : 'symbol'}}</td>
      <td><span class="ui-column-title">{{columns[8].header}}</span>{{workOrder.netTotal | currency: 'USD' : 'symbol'}}</td>
      <td><span class="ui-column-title">{{columns[9].header}}</span>{{workOrder.hoursTotal}}</td>
      <td>
        <span class="ui-column-title">{{columns[10].header}}</span>
        <span [ngClass]="workOrder.workHourAverage >= commissionLaborHourRequirement ? 'commission-earned' : 'commission-unearned' ">
          {{workOrder.workHourAverage | currency: 'USD' : 'symbol'}}
        </span>
      </td>
      <td><span class="ui-column-title">{{columns[11].header}}</span>{{workOrder.commissionMinimum | currency: 'USD' : 'symbol'}}</td>
      <td>
        <span class="ui-column-title">
          {{columns[12].header}}
        </span>
        <span *ngIf="!workOrder.editing">
          {{workOrder.commissionEarned ? 'Yes' : 'No'}}
          <i *ngIf="canOverrideCommissionEarned" class="pi pi-pencil" (click)="editMode(workOrder)"></i>
        </span>
        <span *ngIf="workOrder.editing">
          <p-inputSwitch class="small-toggle" name="commissionEarnedToggle" [(ngModel)]="workOrder.commissionEarned"></p-inputSwitch>
          <i *ngIf="canOverrideCommissionEarned" class="pi pi-save" (click)="saveCommissionEarned(workOrder)"></i>
        </span>
      </td>
      <td>
        <span class="ui-column-title">{{columns[13].header}}</span>
        <span *ngIf="workOrder.commissionEarned">{{getCommissionAmount(workOrder) | currency: 'USD' : 'symbol'}}</span>
        <span *ngIf="!workOrder.commissionEarned">--</span>
      </td>
      <td><span class="ui-column-title">{{columns[13].header}}</span>{{workOrder.paidStatus}}</td>
      <td class="showLineBreaks" style="text-align: left;">
        <span class="ui-column-title">{{columns[14].header}}</span>
        {{workOrder.latestNote}}
        <br *ngIf="workOrder.latestNote"/>
        <span class="regular-link" (click)="addNoteForRow(workOrder)">
          [new note]
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Adding Customer Comment -->
<div class="scrollableDialog" *ngIf="showAddNoteDialog">
  <p-dialog header="Internal Notes" [(visible)]="showAddNoteDialog" [modal]="true" [responsive]="true" [resizable]="true" [baseZIndex]="10000"
    [maximizable]="true" [style]="{'width': '1560px'}" [focusOnShow]="false" (onHide)="closedDialog()" [closable]="false" [blockScroll]="true" [positionTop]="0" [minY]="70" >
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all}" tabindex="0" role="button" (click)="closedDialog()" (keydown.enter)="closedDialog()" (mousedown)="closedDialog()">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-customer-comments
      [customerIdInput]="addNoteWorkOrderRow.customerId" 
      [claimNames]="claimNames" 
      [defaultWONumber]="addNoteWorkOrderRow.number"
      [defaultCategory]="'Other'"
    ></app-customer-comments>
  </p-dialog>
</div>
