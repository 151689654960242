import { CurrencyPipe, DatePipe } from '@angular/common';
import { ReportGenSvc } from '../services_autogenerated/generated_services';
import { MessageWrapperService } from './message-wrapper.service';
import * as i0 from "@angular/core";
import * as i1 from "../services_autogenerated/generated_services";
import * as i2 from "@angular/common";
import * as i3 from "./message-wrapper.service";
var ReportHelperService = /** @class */ (function () {
    function ReportHelperService(reportService, datePipe, currencyPipe, messageService) {
        this.reportService = reportService;
        this.datePipe = datePipe;
        this.currencyPipe = currencyPipe;
        this.messageService = messageService;
        this.options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            headers: ['Type', 'Number', 'Customer Name', 'Date Signed', 'Date Completed', 'Regional Manager', 'Total Price', 'Task Names', 'Task Statuses',
                'Task Priorities', 'Task Hours Estimate Total', 'Address', 'Tax Exempt', 'Is Multiday', 'Has Outside Expenses', 'Expenses Description',
                'Expenses Cost', 'Quote Created', 'Sales Tax Percentage', 'Customer Note', 'Crew Note', 'Auto Renewal Accepted', 'Paid (Overall)',
                'Paid (Tasks)']
        };
    }
    ReportHelperService.ngInjectableDef = i0.defineInjectable({ factory: function ReportHelperService_Factory() { return new ReportHelperService(i0.inject(i1.ReportGenSvc), i0.inject(i2.DatePipe), i0.inject(i2.CurrencyPipe), i0.inject(i3.MessageWrapperService)); }, token: ReportHelperService, providedIn: "root" });
    return ReportHelperService;
}());
export { ReportHelperService };
