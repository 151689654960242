/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-landing-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/tabview/tabview.ngfactory";
import * as i3 from "primeng/components/tabview/tabview";
import * as i4 from "../customer-pages/customer-main-tab/customer-main-tab.component.ngfactory";
import * as i5 from "../customer-pages/customer-main-tab/customer-main-tab.component";
import * as i6 from "../../services_autogenerated/generated_services";
import * as i7 from "../../services/work-order-helper.service";
import * as i8 from "../customer-comments/customer-comments.component.ngfactory";
import * as i9 from "../customer-comments/customer-comments.component";
import * as i10 from "primeng/components/common/messageservice";
import * as i11 from "../../services/auth-helper.service";
import * as i12 from "@angular/common";
import * as i13 from "../customer-maintenance/customer-maintenance.component.ngfactory";
import * as i14 from "../customer-maintenance/customer-maintenance.component";
import * as i15 from "@angular/router";
import * as i16 from "../../services/location-color.service";
import * as i17 from "./customer-landing-page.component";
var styles_CustomerLandingPageComponent = [i0.styles];
var RenderType_CustomerLandingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerLandingPageComponent, data: {} });
export { RenderType_CustomerLandingPageComponent as RenderType_CustomerLandingPageComponent };
function View_CustomerLandingPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "p-tabPanel", [["leftIcon", "pi pi-home"]], null, null, null, i2.View_TabPanel_0, i2.RenderType_TabPanel)), i1.ɵdid(1, 1228800, [[1, 4]], 1, i3.TabPanel, [i1.ViewContainerRef], { leftIcon: [0, "leftIcon"] }, null), i1.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-customer-main-tab", [], null, null, null, i4.View_CustomerMainTabComponent_0, i4.RenderType_CustomerMainTabComponent)), i1.ɵdid(4, 114688, null, 0, i5.CustomerMainTabComponent, [i6.CustomerGenSvc, i7.WorkOrderHelperService], { customer: [0, "customer"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pi pi-home"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.customer; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomerLandingPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "p-tabPanel", [["header", "Internal Notes"]], null, null, null, i2.View_TabPanel_0, i2.RenderType_TabPanel)), i1.ɵdid(1, 1228800, [[1, 4]], 1, i3.TabPanel, [i1.ViewContainerRef], { header: [0, "header"] }, null), i1.ɵqud(603979776, 4, { templates: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-customer-comments", [], null, null, null, i8.View_CustomerCommentsComponent_0, i8.RenderType_CustomerCommentsComponent)), i1.ɵdid(4, 114688, null, 0, i9.CustomerCommentsComponent, [i10.MessageService, i6.CustomerGenSvc, i11.AuthHelperService, i6.CustomerNoteGenSvc, i6.CustomerCommentStageGenSvc], { customerIdInput: [0, "customerIdInput"], claimNames: [1, "claimNames"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Internal Notes"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.customer.id; var currVal_2 = _co.claimNames; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_CustomerLandingPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "p-tabView", [], null, null, null, i2.View_TabView_0, i2.RenderType_TabView)), i1.ɵdid(1, 1097728, null, 1, i3.TabView, [i1.ElementRef], { activeIndex: [0, "activeIndex"] }, null), i1.ɵqud(603979776, 1, { tabPanels: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CustomerLandingPageComponent_2)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, 0, 4, "p-tabPanel", [["header", "Customer Info"]], null, null, null, i2.View_TabPanel_0, i2.RenderType_TabPanel)), i1.ɵdid(6, 1228800, [[1, 4]], 1, i3.TabPanel, [i1.ViewContainerRef], { header: [0, "header"] }, null), i1.ɵqud(603979776, 3, { templates: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-customer-maintenance", [], null, null, null, i13.View_CustomerMaintenanceComponent_0, i13.RenderType_CustomerMaintenanceComponent)), i1.ɵdid(9, 114688, null, 0, i14.CustomerMaintenanceComponent, [i15.Router, i6.CustomerGenSvc, i10.MessageService, i16.LocationColorService, i11.AuthHelperService], { customer: [0, "customer"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CustomerLandingPageComponent_3)), i1.ɵdid(11, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.customer == null) ? null : _co.customer.id) && _co.hasCustomerDashboardAccess); _ck(_v, 4, 0, currVal_1); var currVal_2 = "Customer Info"; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.customer; _ck(_v, 9, 0, currVal_3); var currVal_4 = ((!_co.isCustomer && _co.customer) && _co.customer.id); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_CustomerLandingPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerLandingPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customer; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerLandingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-landing-page", [], null, null, null, View_CustomerLandingPageComponent_0, RenderType_CustomerLandingPageComponent)), i1.ɵdid(1, 114688, null, 0, i17.CustomerLandingPageComponent, [i11.AuthHelperService, i6.CustomerGenSvc, i15.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerLandingPageComponentNgFactory = i1.ɵccf("app-customer-landing-page", i17.CustomerLandingPageComponent, View_CustomerLandingPageComponent_Host_0, {}, {}, []);
export { CustomerLandingPageComponentNgFactory as CustomerLandingPageComponentNgFactory };
