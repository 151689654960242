import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numeric]'
})
export class NumericDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>(false);

  constructor(
    private elementRef: ElementRef,
    private model: NgControl
  ) {
    // this.elementRef.nativeElement.style.textAlign = 'right';
  }

  @HostListener('input') inputChange() {
    const newValue = this.unMask();
    this.model.control.setValue(newValue, {
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: false
    });
  }

  @HostListener('focusout') onFocusout() {
    this.elementRef.nativeElement.value = this.addDecimals();
    this.ngModelChange.emit(this.unMask());
  }

  @HostListener('focus') onFocus() {
    if (this.model.control.value === 0) {
      this.elementRef.nativeElement.setSelectionRange(0, this.elementRef.nativeElement.value.length);
    } else {
      this.elementRef.nativeElement.setSelectionRange(
          this.elementRef.nativeElement.value.length - 3, this.elementRef.nativeElement.value.length - 3);
    }
  }

  addDecimals() {
    const value = String(this.elementRef.nativeElement.value);
    const arrayNum = value.split('.');
    arrayNum[0] = arrayNum[0].length > 1 ? arrayNum[0] : '$0';
    arrayNum[1] = arrayNum && arrayNum.length === 2 ? ('.' + arrayNum[1].replace('_', '') + '00').substring(0, 3) : '.00';
    return arrayNum[0] + arrayNum[1];
  }

  unMask() {
    return Number(this.elementRef.nativeElement.value.replace(/[^\d.-]/g, '', ''));
  }
}
