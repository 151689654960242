<div style="width: 70%; min-width: 350px; margin: auto; text-align: center;" *ngIf="isLoading">
  <app-loading-swirly [isLoading]="isLoading"></app-loading-swirly>
</div>

<div style="width: 70%; min-width: 350px; margin: auto" *ngIf="quote">
  <h5 class="heading bold">
    Hello, {{customCustomerService.getCustomerFullName(quote.customer)}}! Your quote is below!
  </h5>
  <div class="heading">
    Quote # {{quote.quoteNumber}} 
    <span *ngIf="quote.completionStatus == 'Work Orders Created'" class=" approved"><i class="pi pi-check middle-this"></i>Approved on {{quote.signedDate | date: 'shortDate'}}</span>
  </div>

  <div class="bold">A Message From Your Arborist:</div>
  <p style="white-space: pre-wrap">
    {{quote.note}}
  </p>
  <p>
    Review your quote below and please check the boxes next to the services you would like to receive and click the "Next" button. 
    If you would like something changed on this quote, please click the "Request Changes" button and provide your comments which will
    be sent to your arborist.
  </p>

  <div class="bold">Work Address:</div>
  <p>
    {{quote.address.street}}, {{quote.address.city}}, {{quote.address.state.abbreviation}} {{quote.address.zip}}
  </p>

  <div class="bold">Estimated Date of Work Completion:</div>
  <div *ngIf="quote.workOrderWellnessTasks?.length > 0 && quote.workOrderWorkTasks?.length == 0"><!-- Only has wellness tasks -->
    <p>
      Tree Wellness services will be performed at the most optimal time to apply treatment (based on the Growing Degree Day calendar). You will be notified 1-2 days prior to service(s) being performed. Contact us for a more specific timing of your services.
    </p>
  </div>
  <span *ngIf="!(quote.workOrderWellnessTasks?.length > 0 && quote.workOrderWorkTasks?.length == 0)"> <!-- Has work tasks (can also have wellness tasks) -->
    <div *ngIf="(quote.priorityTypeShortcut?.weeksOutStart != undefined || quote.manualPriorityShortcutType === 'Week') && quote.completionStatus != 'Work Orders Created'">
      <p *ngIf="quote.priorityTypeShortcut?.weeksOutStart != undefined">
        {{quote.priorityTypeShortcut.weeksOutStart}} - {{quote.priorityTypeShortcut.weeksOutEnd}} weeks from the date quote is approved
      </p>
      <p *ngIf="quote.manualPriorityShortcutType === 'Week'">
        {{quote.manualRangeStart}} - {{quote.manualRangeEnd}} weeks from the date quote is approved
      </p>
      <p class="fine-print">
        Please note: This is an estimate and it is possible your work is scheduled before or after this window. You will be informed 2 days prior to our arrival. Stump grinding (if purchased) will be completed 7-10 business days following tree removals. Tree Wellness services will be performed at the most optimal time to apply treatment (based on the Growing Degree Day calendar).
      </p>
    </div>
    <div *ngIf="quote.manualRangeStart && quote.completionStatus != 'Work Orders Created'">
      <p>
        {{quote.manualRangeStart}} to {{quote.manualRangeEnd}} {{quote.manualPriorityShortcutType.toLocaleLowerCase()}}s out<br/>
        If you sign today, your projected date of tree work will be between {{weeksOutDateCalculation.start | date: 'shortDate'}} - {{weeksOutDateCalculation.end | date: 'shortDate'}}<br/>

      </p>
      <p class="fine-print">
        Please note: Stump grinding (if purchased) will be completed 7-10 business days following tree removals. Tree Wellness services will be performed at the most optimal time to apply treatment (based on the Growing Degree Day calendar).
      </p>
    </div>
    <div *ngIf="quote.priorityTypeShortcut?.weeksOutStart == undefined || quote.completionStatus == 'Work Orders Created'">
      <span *ngIf="(quote.priorityTypeShortcut?.weeksOutStart != undefined || quote.manualPriorityShortcutType === 'Week')">
        <p *ngIf="quote.priorityTypeShortcut?.weeksOutStart != undefined && quote.completionStatus != 'Work Orders Created'">
          {{quote.priorityTypeShortcut.weeksOutStart}} - {{quote.priorityTypeShortcut.weeksOutEnd}} weeks from the date quote is approved
        </p>
        <p *ngIf="quote.priorityTypeShortcut?.weeksOutStart != undefined && quote.completionStatus == 'Work Orders Created'">
          {{signedQuoteWeekRangeCalculation.start}} - {{signedQuoteWeekRangeCalculation.end}} weeks from the date quote is approved
        </p>
        <p *ngIf="quote.manualPriorityShortcutType === 'Week'">
          {{quote.manualRangeStart}} - {{quote.manualRangeEnd}} weeks from the date quote is approved
        </p>
        <p class="fine-print">
          Please note: This is an estimate and it is possible your work is scheduled before or after this window. You will be informed 2 days prior to our arrival. Stump grinding (if purchased) will be completed 7-10 business days following tree removals. Tree Wellness services will be performed at the most optimal time to apply treatment (based on the Growing Degree Day calendar).
        </p>
      </span>
      <span *ngIf="!(quote.priorityTypeShortcut?.weeksOutStart != undefined || quote.manualPriorityShortcutType === 'Week')">
        <p *ngIf="quote.dueDateStart && quote.dueDateEnd">
          Between {{quote.dueDateStart | date: 'shortDate'}} and {{quote.dueDateEnd | date: 'shortDate'}}
        </p>
        <p *ngIf="quote.dueDateStart && !quote.dueDateEnd">
          After {{quote.dueDateStart | date: 'shortDate'}}
        </p>
        <p *ngIf="!quote.dueDateStart && quote.dueDateEnd">
          Before {{quote.dueDateEnd | date: 'shortDate'}}
        </p>   
        <p class="fine-print">
          Please note: This is an estimate and it is possible your work is scheduled before or after this window. You will be informed 2 days prior to our arrival. Stump grinding (if purchased) will be completed 7-10 business days following tree removals. Tree Wellness services will be performed at the most optimal time to apply treatment (based on the Growing Degree Day calendar).
        </p>
      </span>
    </div>
  </span>

  <div class="bold">Sincerely, Your Arborist:</div>
  <p style="white-space: pre-line;">
    {{quote.representative.employeeSignature ? quote.representative.employeeSignature : quote.representative.fullName}}
  </p>

  <div class="p-grid task-style" *ngFor="let task of quote.workOrderWellnessTasks">
    <div class="p-col-12 p-md-10">
      <div class="bold">{{task.wellnessTask.name}}: <span *ngFor="let tree of task.wellnessTask.wellnessTaskTreeTypes">{{tree.treeType.commonName}}, </span>{{task.wellnessTask.location}}</div>
      <div class="showLineBreaks">{{task.wellnessTask.description}}</div>
      <app-blob-manager
        [containerName]="'wellness-task'"
        [parentRecordId]="task.wellnessTask.id"
        [uploadLabel]="'Document/Photo'"
        [viewOnly]="true"
        [anonymousPossible]="true"
        [hideIfNoBlobs]="true">
      </app-blob-manager>
    </div>
    <div class="p-col-12 p-md-2 bold" style="margin: auto; text-align: right;">
      <span *ngIf="canSeeHiddenPrice || !quote.hidePrice">{{task.wellnessTask.price | currency}}</span>&nbsp;
      <p-checkbox name="agree" [value]="task.id" [disabled]="!canAcceptOrReject || tasksAlreadyWorkOrders" [(ngModel)]="selectedWellnessTasks" (onChange)="wellnessCheckChanged($event, task.wellnessTask)"></p-checkbox>
    </div>
    <div class="task-renewal-row" *ngIf="task.wellnessTask.renewable">
      <div class="renewal-column left-column">
        <div class="bold">+ Yearly Service</div>
        <div class="showLineBreaks">
          We recommend that this service is performed annually to achieve optimal results. Select this box to receive this service each year. You may opt-out at any time — just call us at {{contactPhoneNumber}}!
        </div>
      </div>
      <div class="renewal-column right-column">
        <p-checkbox name="autoRenew" binary="true" [disabled]="!canAcceptOrReject || tasksAlreadyWorkOrders || !selectedWellnessTasks.includes(task.id)" [(ngModel)]="task.wellnessTask.renewalAccepted"></p-checkbox>
      </div>
    </div>
  </div>

  <div class="p-grid task-style" *ngFor="let task of quote.workOrderWorkTasks">
    <div class="p-col-12 p-md-10">
      <div class="bold">{{task.workTask.name}}: <span *ngFor="let tree of task.workTask.workTaskTreeTypes">{{tree.treeType.commonName}}, </span>{{task.workTask.location}}</div>
      <div class="showLineBreaks">{{task.workTask.description}}</div>
      <app-blob-manager
        [containerName]="'work-task'"
        [parentRecordId]="task.workTask.id"
        [uploadLabel]="'Document/Photo'"
        [viewOnly]="true"
        [anonymousPossible]="true"
        [hideIfNoBlobs]="true">
      </app-blob-manager>
    </div>
    <div class="p-col-12 p-md-2 bold" style="margin: auto; text-align: right;">
      <span *ngIf="task.workTask.fixedPrice && (canSeeHiddenPrice || !quote.hidePrice)">
        {{task.workTask.price | currency}}
      </span>
      <span *ngIf="!task.workTask.fixedPrice && (canSeeHiddenPrice || !quote.hidePrice)">
        <span *ngIf="task.workTask.hourlyRate">{{task.workTask.hourlyRate | currency}} per hour</span>
      </span>
      &nbsp;
      <p-checkbox name="agree" [value]="task.id" [disabled]="!canAcceptOrReject || tasksAlreadyWorkOrders" [(ngModel)]="selectedWorkTasks" (onChange)="workCheckChanged($event, task.workTask)"></p-checkbox>
    </div>
    <span class="fine-print" *ngIf="!task.workTask.fixedPrice && (canSeeHiddenPrice || !quote.hidePrice)" style="text-align: right; width: 100%">
      <span *ngIf="task.workTask.minPrice || task.workTask.maxPrice">Please note there will be a </span>
      <span *ngIf="task.workTask.minPrice">minimum charge of {{task.workTask.minPrice | currency}}</span>
      <span *ngIf="task.workTask.minPrice && task.workTask.maxPrice"> and a </span>
      <span *ngIf="task.workTask.maxPrice">maximum charge of {{task.workTask.maxPrice | currency}}</span>
    </span>
  </div>

  <div *ngIf="canSeeHiddenPrice || !quote.hidePrice">
    <div class="p-grid" style="text-align: right;">
      <div class="p-col-10">
        Subtotal:
      </div>
      <div class="p-col-2">
        {{woHelperService.subTotal(quote) | currency}}
      </div>
    </div>
    <div class="p-grid" style="text-align: right;" *ngFor="let adjustment of activePriceAdjustments">
      <div class="p-col-10">
        {{adjustment.priceAdjustment.reason}} ({{adjustment.priceAdjustment.percentage}}%):
      </div>
      <div class="p-col-2">
        {{woHelperService.adjustmentAmount(adjustment, quote) | currency}}
      </div>
    </div>
    <div class="p-grid" style="text-align: right;" *ngIf="activePriceAdjustments.length > 0">
      <div class="p-col-10">
        Total After Price Adjustment:
      </div>
      <div class="p-col-2">
        {{woHelperService.totalPriceAfterAdjustment(quote) | currency}}
      </div>
    </div>
    <div class="p-grid" style="text-align: right;" *ngIf="woHelperService.qualifiesForFirmtimeRefund(quote)">
      <div class="p-col-10">
        Firm-Time Refund: 
      </div>
      <div class="p-col-2">
        {{-100 | currency}}
      </div>
    </div>
    <div class="p-grid" style="text-align: right;">
      <div class="p-col-10">
        Sales Tax ({{quote.salesTaxPercentage}}%):
      </div>
      <div class="p-col-2">
        {{woHelperService.salesTaxAmount(quote) | currency}}
      </div>
    </div>
    <div class="p-grid bold" style="text-align: right;">
      <div class="p-col-10">
        Total:
      </div>
      <div class="p-col-2">
        {{woHelperService.totalAmount(quote) | currency}}
      </div>
    </div>  
    <div style="text-align: right;" *ngIf="showHourlyRateMessage">
      Plus hourly rate as indicated above, billed upon completion of service
    </div>  
  </div>
  <br>

  <app-legal-acceptance-verbiage [paymentDueOption]="quote.paymentDueOption"></app-legal-acceptance-verbiage>

  <div class="green-box" *ngIf="quote.neighborPermissionRequired">
    <div>
      <div class="bold">Neighbor's Permission <span style="text-decoration: underline;">Required:</span></div>
      <p>
        To complete your tree work for the above price, our crew requires the ability to access the neighboring property at <span class="bold">{{quote.neighborPermission.address}}</span>.
      </p>
      <p>
        Please present this quote on your mobile device to the neighboring property owner, explain the work to be performed, and ask they input their name and signature into the 
        below fields to grant Russell Tree Experts ltd permission to access their property while performing the work. If the neighboring property owner does not wish to grant
        access, please let us know so we can provide you with an updated quote to complete this work.
      </p>

      <p class="bold">For the Owner of <span style="text-decoration: underline;">{{quote.neighborPermission.address}}:</span></p>
      <p>
        By signing below [1] I acknowledge I am the owner of the property of  <span class="bold">{{quote.neighborPermission.address}};</span> [2] I grant Russell Tree Experts ltd. (RTE) permission
        to access my property while they complete tree work for <span class="bold">{{quote.customer.fullName}};</span> [3] I acknowledge RTE warrants that all operations will 
        follow the ANSI Z 133.1 Industry safety standard; [4] For everyone's safety, I agree not to enter the work area during tree work operations.
      </p>
      <p>
        Description of activities to be performed: {{quote.neighborPermission.activiesOnNeighborProperty}}
        <br/>
        Please call {{quote.representative.fullName}} at {{quote.representative.phoneNumber | phoneNumber}} with any questions.
      </p>
    </div>
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-6">
        <div>First Name</div>
        <input 
          pInputText 
          name="neighborFirstName" 
          [(ngModel)]="quote.neighborPermission.firstName" 
          style="border-radius: 5px;" 
          [disabled]="quote.completionStatus === 'Work Orders Created'"
          (blur)="onNeighborInfoUpdate()"
          [required]="quote.neighborPermissionRequired" />
      </div>
      <div class="p-col-12 p-md-6">
        <div>Last Name</div>
        <input 
          pInputText 
          name="neighborLastName" 
          [(ngModel)]="quote.neighborPermission.lastName" 
          style="border-radius: 5px;" 
          [disabled]="quote.completionStatus === 'Work Orders Created'"
          (blur)="onNeighborInfoUpdate()"
          [required]="quote.neighborPermissionRequired" />
      </div>
      <div class="p-col-12 p-md-9">
        <div>Email Address</div>
        <input 
          pInputText 
          #emailInput="ngModel"
          name="neighborEmail" 
          email
          [(ngModel)]="quote.neighborPermission.email" 
          style="border-radius: 5px;"
          [disabled]="quote.completionStatus === 'Work Orders Created'" 
          (blur)="onNeighborInfoUpdate()"
          [required]="quote.neighborPermissionRequired" />
      </div>
      <div class="p-col-12 p-md-3">
        <div>Receive Marketing Emails? 
          <span pTooltip="You will receive a confirmation email upon signing but if you want to be included on future RTE email updates, check the box below!" tooltipPosition="bottom"
              tabindex="0" tooltipEvent="focus" style="outline: 0;">
            <i class="pi pi-info-circle" style="vertical-align: middle;"></i>
          </span> <!-- https://github.com/primefaces/primeng/issues/8454 -->
        </div>
        <p-checkbox 
          binary="true" 
          name="showInactive" 
          [disabled]="quote.completionStatus === 'Work Orders Created'"
          (blur)="onNeighborInfoUpdate()"
          [(ngModel)]="quote.neighborPermission.signUpForEmails">
        </p-checkbox>
      </div>
      <div class="p-col-12">
        <app-signature-area 
          [signature]="quote.neighborPermission.neighborSignature"
          [isAlwaysDisabled]="quote.completionStatus === 'Work Orders Created'"
          [name]="'Neighbor\'s'"
          (currentSignature)="onUpdatedNeighborSignature($event)"
        >
        </app-signature-area>
      </div>
      <div *ngIf="!quote.neighborPermission.neighborSignature" class="p-col-12">
        If you are unable to have your neighbor sign on your device click <span class="link" (click)="sendEmailRequestToNeighbor()">here</span> to send them an email to the above email address requesting their signature.
      </div>
    </div>
  </div>

  <app-signature-area 
    [signature]="quote.customerSignature"
    [isAlwaysDisabled]="!canAcceptOrReject"
    [name]="'Customer'"
    (currentSignature)="onUpdatedSignature($event)"
  >
  </app-signature-area>

  <div style="text-align: right;">
    <div [style.visibility]="isSaving ? 'visible' : 'hidden'">
      <i class="pi pi-spin pi-spinner middle" style="font-size: 1.5em;"></i>
    </div>
    <!-- <button pButton
      type="button"
      class="ui-button-raised ui-button-warning buttonStyle"
      (click)="back()"
      label="Back"></button> -->
    <!-- <button pButton
      type="button"
      class="ui-button-raised ui-button-danger buttonStyle"
      (click)="reject()"
      [disabled]="selectedWorkTasks.length || selectedWellnessTasks.length || !canAcceptOrReject"
      label="Decline"></button> -->
    <button pButton
      type="button"
      class="ui-button-raised ui-button-secondary buttonStyle small-button"
      (click)="requestChanges()"
      [disabled]="!canAcceptOrReject"
      label="Request Changes"></button>
      <p-overlayPanel #signPanel appendTo="body" (onShow)="onSignPanelShow()">
        Please select at least one task and sign to continue.
      </p-overlayPanel>
    <span 
      (mouseenter)="shouldDisableApprove() && !isSaving ? signPanel.show($event) : signPanel.hide()" 
      (mouseleave)="signPanel.hide($event)"  
      (click)="shouldDisableApprove() && !isSaving ? signPanel.toggle($event) : signPanel.hide()"
    >
      <button pButton
        type="button"
        class="ui-button-raised ui-button-success buttonStyle small-button"
        [ngClass]="{'blocked': shouldDisableApprove()}"
        (click)="approve()"
        [disabled]="emailInput?.invalid || shouldDisableApprove()"
        label="Next">
      </button>
    </span>
  </div> <!--, position: 'absolute', right: '4px', top: '0px'-->
</div>

<app-request-changes-dialog [(showDialog)]="showRequestChangesDialog" [quote]="quote" (close)="closeDialog($event)"></app-request-changes-dialog>
<app-quote-billing-modal 
  *ngIf="quoteBillingModalDisplay" 
  [quoteId]="quote.id" 
  [display]="quoteBillingModalDisplay" 
  (emitClose)="closeQuoteBillingModal()" 
  (emitPaymentSuccess)="onPaymentSuccess()" 
  (emitConvertQuote)="convertSelectedTasksToWorkOrders()"
  [isQuoteRequest]="false" 
  [paymentAmount]="getPaymentAmount()" 
  [showManualPaymentChargeMessage]="quote?.paymentDueOption?.id == PaymentDueOptionId.DueInAdvance">
</app-quote-billing-modal>

<p-dialog header="Thank You" [(visible)]="showThanksDialogOnly" [modal]="true" (onHide)="afterBillingSave()" [responsive]="true" [style]="{width: '640px'}" [minY]="70" [positionTop]="0"
[baseZIndex]="10000" [closable]="false" [maximizable]="false" [blockScroll]="true" class="billing-dialog">
  <div class="checkout" [style.max-width]="maxWidth">
    <div class="ui-g ui-g-12 remove-bottom-space">
      <span style="float: right; width: 100%">
        <i class="pi pi-times" style="font-size: 1.5rem; cursor: pointer; float: right;" (click)="afterBillingSave()"></i>
      </span>
      <div class="ui-g-12 remove-bottom-space">
        <h5 class="center bold">WE'RE ALL SET!</h5>
      </div>
    </div>
    <div class="ui-g ui-g-12" style="padding-top:0px; text-align: center">
      <div class="ui-g-12">
        <div class="justified">
          Thank you! Your tree work order is in our scheduling queue! We will be in touch via email with a specific day two days prior to the crew's dispatch.
        </div>
        <div class="justified">
          FYI - Tree work, tree wellness, and stump grinding are performed by different crews and each service type will be scheduled and billed separately.
        </div>
        <div class="justified">
          Please allow a two-day window from notification for tree wellness services to account for postponement due to high winds or excessive precipitation.
        </div>
        <div class="center" style="margin: 10px; width: 100%; font-size: 1rem;">
          Questions? Give us a call at {{contactPhoneNumber}}
        </div>
      </div>
    </div>
  </div>
</p-dialog>