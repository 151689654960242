<p style="font-weight:bold;" *ngIf="titleLabel">{{titleLabel}}</p>
<p *ngIf="blobs && blobs.length == 0 && viewOnly && !hideIfNoBlobs">None</p>
<div *ngIf="blobs && !viewOnly">
    <label class="btn btn-success btn-sm buttonMargin">
        <input 
            *ngIf="accept" 
            type="file" 
            id="blobUpload" 
            [name]="containerName"
            [required]="isRequired"
            style="display:none !important" 
            [(ngModel)]="fileInUploader"
            [accept]="accept" 
            (change)="addToList($event.target.files[0])" 
            pInputText/>
        <input 
            *ngIf="!accept" 
            type="file" 
            id="blobUpload" 
            [name]="containerName"
            style="display:none !important" 
            [(ngModel)]="fileInUploader"
            (change)="addToList($event.target.files[0])" 
            pInputText/>
        <span style="vertical-align: middle;">
            <i class="pi pi-plus" *ngIf="!buttonText"></i>
            <div *ngIf="buttonText" style="padding: 6px;">{{buttonText}}</div>
        </span>
    </label>
    <span *ngIf="!buttonText"> Add {{uploadLabel}}</span>
</div>
<p *ngIf="!blobs && !errorMessage && !hideIfNoBlobs">Loading list...</p>
<p *ngIf="errorMessage && !hideIfNoBlobs">{{errorMessage}}</p>
<div *ngFor="let blob of displayBlobs; let b = index" [ngStyle]="{'margin-bottom': viewOnly ? '0px' : '12px'}">
    <div style="vertical-align: middle;">
        <button style="float: left; border-radius: 5px;" pButton type="button" class="ui-button-danger" icon="pi pi-times" *ngIf="!viewOnly" (click)="delete(blob, b)"></button>
        <span>
            &nbsp;<a style="vertical-align: middle;" class="regular-link" [href]="blob.SASUri">{{blob.DisplayName}}</a>
        </span>
    </div>
</div>
<div *ngFor="let file of filesToUpload; let f = index">
    <div class="ui-inputgroup">
        <button pButton type="button" icon="pi pi-times" class="ui-button-danger"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;" (click)="removeFromList(f)"></button>
        <span>
            &nbsp;<a style="vertical-align: middle;" class="regular-link">{{file.overwrittenFileName}}</a>
        </span>
        <div *ngIf="angularUpload && file.progress >= 0" style="width: 190px; padding-left: 5px;">
            <p-progressBar [value]="file.progress"></p-progressBar>
        </div>
        <button pButton type="button" icon="pi pi-refresh" class="ui-button-warning" *ngIf="file.canRetry"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;" (click)="retryFile(file)"></button>
    </div>
</div>