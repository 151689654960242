import { Injectable } from '@angular/core';
import { Customer, CustomerContact, QuoteWorkOrder, WellnessWorkOrder, WorkWorkOrder, EmailAddress, Address } from '../services_autogenerated/generated_services';
import { CustomerTypes } from '../models/enums/customerTypes';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor() { }

  customerSearch = (searchTerm: string, customer: Customer) => {
    searchTerm = searchTerm ? searchTerm.toLowerCase() : searchTerm;
    const activeStatus: string = customer.active ? 'Active' : 'Not Active';
    const taxStatus: string = customer.taxExempt ? 'Tax Exempt' : 'Taxable';
    const customerName: string = this.getCustomerFullName(customer);
    const customerType: string = customer.customerType.name;

    const customerNameMatches: boolean = customerName.toLowerCase().includes(searchTerm);
    const addressMatches: boolean = customer.address.city.toLowerCase().includes(searchTerm) ||
      customer.address.zip.toLowerCase().includes(searchTerm) ||
      customer.address.street.toLowerCase().includes(searchTerm) ||
      customer.address.state.name.toLowerCase().includes(searchTerm);
    const activeStatusMatches = activeStatus.toLowerCase().includes(searchTerm);
    const taxStatusMatches = taxStatus.toLowerCase().includes(searchTerm);
    const customerTypeMatches = customerType.toLowerCase().includes(searchTerm);

    return customerNameMatches || addressMatches || activeStatusMatches || taxStatusMatches || customerTypeMatches;
  }

  getPrimaryContact(customer: Customer): CustomerContact {
    let contact = new CustomerContact();
    if (customer && customer.customerContacts) {
      const existingContact = customer.customerContacts.find(x => x.active === true && x.primary);
      if (existingContact) {
        contact = existingContact;
      }
    }

    return contact;
  }

  getCustomerFullName(customer: Customer): string {
    if (customer.customerTypeId === CustomerTypes.Residential) {
      return this.getPrimaryContact(customer).fullName;
    } else {
      return customer.companyName;
    }
  }

  getCustomerFullNameWithAddress(customer: Customer): string {
    // tslint:disable-next-line: max-line-length
    return `${this.getCustomerFullName(customer)} - ${customer.address.street}, ${customer.address.city} ${(customer.address.state != null ? customer.address.state.abbreviation : '')}, ${customer.address.zip}`;

  }

  newCustomer(): Customer {
    var customer = new Customer()
    customer.address = new Address();
    customer.customerContacts = [new CustomerContact()];
    customer.customerContacts[0].emailAddress = new EmailAddress();
    customer.active = true;
    customer.paymentInfoRequired = true;

    return customer;
  }
  
}
