import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { WorkTask, WorkTaskSkill, WorkTaskEquipment, SkillGenSvc, TreeTypeGenSvc, WorkTaskTemplateGenSvc, PriorityLevelGenSvc, WorkTaskGenSvc, WorkTaskTreeType, QuoteWorkOrder, EquipmentTypeGenSvc, QuoteWorkOrderCompletionStatus, WorkTaskCompletionStatus, WorkWorkOrder, WorkOrderWorkTask, WorkWorkOrderCompletionStatus, WorkTaskPaymentStatus, WorkTaskGoBackEquipment, ScheduledBucketGenSvc, WorkTaskUpdateDTO, WorkTaskUpdateDTOCompletionStatus, WorkTaskUpdateDTOManualPriorityShortcutType } from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { MaskService } from 'src/app/services/mask.service';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BaseTaskCardMaintenanceComponent } from '../base-task-card-maintenance/base-task-card-maintenance.component';
import * as moment from 'moment';
import { PriorityAndDateSelectorComponent } from '../priority-and-date-selector/priority-and-date-selector.component';
var WorkTaskMaintenanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkTaskMaintenanceComponent, _super);
    function WorkTaskMaintenanceComponent(skillService, equipmentTypeService, treeTypeService, priorityLevelService, messageService, maskService, authHelper, templateService, workTaskService, scheduledBucketService) {
        var _this = _super.call(this, skillService, equipmentTypeService, treeTypeService, priorityLevelService, messageService, maskService, authHelper) || this;
        _this.templateService = templateService;
        _this.workTaskService = workTaskService;
        _this.scheduledBucketService = scheduledBucketService;
        _this.isInvoiced = false;
        _this.statuses = [WorkTaskCompletionStatus.Completed];
        return _this;
    }
    WorkTaskMaintenanceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currencyMask = this.maskService.currencyMaskNoDecimal;
        this.isQuoteTask = this.workOrder instanceof QuoteWorkOrder;
        if (!this.task) {
            var task = new WorkTask();
            task.active = true;
            task.fixedPrice = true;
            task.completionStatus = this.workOrder instanceof WorkWorkOrder ?
                WorkTaskCompletionStatus.Ready_to_Schedule :
                WorkTaskCompletionStatus.New;
            task.taskHourEntries = [];
            task.workTaskEquipment = [];
            task.workTaskSkills = [];
            task.workTaskTreeTypes = [];
            this.task = task;
            if (this.workOrder instanceof QuoteWorkOrder) {
                // defaults to the quote's priority type/dates
                this.task.hasCalculatedPriority = this.workOrder.hasCalculatedPriority;
                this.task.dueDateStart = this.workOrder.dueDateStart;
                this.task.dueDateEnd = this.workOrder.dueDateEnd;
                this.task.hardStartDate = this.workOrder.hardStartDate;
                this.task.hardEndDate = this.workOrder.hardEndDate;
                this.task.manualPriorityShortcutType = this.workOrder.manualPriorityShortcutType;
                this.task.manualRangeEnd = this.workOrder.manualRangeEnd;
                this.task.manualRangeStart = this.workOrder.manualRangeStart;
                this.task.manualPriorityLevel = this.workOrder.manualPriorityLevel;
                this.task.priorityLevelId = this.workOrder.priorityLevelId;
                this.task.priorityShortcutId = this.workOrder.priorityShortcutId;
                this.task.priorityTypeShortcut = this.workOrder.priorityTypeShortcut;
            }
            else if (this.workOrder.workOrderWorkTasks.length > 0) {
                var firstTaskOnWO = this.workOrder.workOrderWorkTasks[0].workTask;
                this.task.hasCalculatedPriority = firstTaskOnWO.hasCalculatedPriority;
                this.task.dueDateStart = firstTaskOnWO.dueDateStart;
                this.task.dueDateEnd = firstTaskOnWO.dueDateEnd;
                this.task.hardStartDate = firstTaskOnWO.hardStartDate;
                this.task.hardEndDate = firstTaskOnWO.hardEndDate;
                this.task.manualPriorityShortcutType = firstTaskOnWO.manualPriorityShortcutType;
                this.task.manualRangeEnd = firstTaskOnWO.manualRangeEnd;
                this.task.manualRangeStart = firstTaskOnWO.manualRangeStart;
                this.task.manualPriorityLevel = firstTaskOnWO.manualPriorityLevel;
                this.task.priorityLevelId = firstTaskOnWO.priorityLevelId;
                this.task.priorityShortcutId = firstTaskOnWO.priorityShortcutId;
                this.task.priorityTypeShortcut = firstTaskOnWO.priorityTypeShortcut;
                if (firstTaskOnWO.currentBucketId) {
                    this.task.currentBucketId = firstTaskOnWO.currentBucketId;
                    this.task.scheduleDateFrom = firstTaskOnWO.scheduleDateFrom;
                    this.task.scheduleDateTo = firstTaskOnWO.scheduleDateTo;
                }
            }
        }
        else {
            this.copyTask();
            this.isInvoiced = this.task.paymentStatus === WorkTaskPaymentStatus.Invoiced || this.task.paymentStatus === WorkTaskPaymentStatus.Paid;
        }
        this.setUploadId();
        this.dependencyOptions = this.workOrder.workOrderWorkTasks
            .map(function (wowt) { return wowt.workTask; })
            .filter(function (wt) { return wt.active && wt !== _this.task; });
        var claims = this.authHelper.getDecodedAccessToken().claimNames;
        this.isEditable = (this.task.completionStatus !== WorkTaskCompletionStatus.Completed
            && this.task.completionStatus !== WorkTaskCompletionStatus.Go_Back
            && this.task.completionStatus !== WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up)
            && ((this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created
                && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected)
                || claims.includes('Full Control'));
        this.canComplete = this.task.id &&
            this.task.completionStatus !== WorkTaskCompletionStatus.Completed
            && this.task.scheduleDateFrom !== undefined
            && this.isEditable
            && claims.includes('Completing a Task');
        this.canEditUploads = claims.includes('View/Edit Work Order');
        this.isIncompleteGoBack = this.task.completionStatus !== WorkTaskCompletionStatus.Completed && this.task.isGoBack;
        if (claims.includes('Can Initiate Go Back')) {
            this.statuses.push(WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up);
            this.canChangeStatus = this.task.completionStatus === WorkTaskCompletionStatus.Completed
                || this.task.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                || this.task.completionStatus === WorkTaskCompletionStatus.Go_Back
                || this.isIncompleteGoBack;
        }
        if (claims.includes('Can Set Go Back')) {
            this.statuses.push(WorkTaskCompletionStatus.Go_Back);
            this.canChangeStatus = this.task.completionStatus === WorkTaskCompletionStatus.Completed
                || this.task.completionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                || this.task.completionStatus === WorkTaskCompletionStatus.Go_Back
                || this.isIncompleteGoBack;
        }
        this.templateService.getAllActive().subscribe(function (templates) {
            _this.templateOptions = templates;
        });
        this.skillService.getAll().subscribe(function (skills) {
            if (_this.task.workTaskSkills) {
                _this.skillOptions = _this.task.workTaskSkills;
            }
            else {
                _this.skillOptions = [];
                _this.task.workTaskSkills = [];
            }
            var otherSkills = skills.filter(function (s) { return !_this.task.workTaskSkills.some(function (wts) { return wts.skillId === s.id; }); });
            _this.skillOptions = _this.skillOptions.concat(otherSkills.map(function (skill) {
                return new WorkTaskSkill({ skill: skill, skillId: skill.id, workTaskId: _this.task.id });
            }));
        });
        this.equipmentTypeService.getActive().subscribe(function (equipmentTypes) {
            if (_this.task.workTaskEquipment) {
                _this.equipmentOptions = _this.task.workTaskEquipment;
            }
            else {
                _this.equipmentOptions = [];
                _this.task.workTaskEquipment = [];
            }
            if (_this.task.workTaskGoBackEquipment) {
                _this.goBackEquipmentOptions = _this.task.workTaskGoBackEquipment;
            }
            else {
                _this.goBackEquipmentOptions = [];
                _this.task.workTaskGoBackEquipment = [];
            }
            var otherEquipment = equipmentTypes.filter(function (e) { return !_this.task.workTaskEquipment.some(function (wte) { return wte.equipmentTypeId === e.id; }); });
            var otherGoBackEquipment = equipmentTypes.filter(function (e) { return !_this.task.workTaskGoBackEquipment.some(function (wte) { return wte.goBackEquipmentId === e.id; }); });
            _this.equipmentOptions = _this.equipmentOptions.concat(otherEquipment.map(function (equip) {
                return new WorkTaskEquipment({ equipmentType: equip, equipmentTypeId: equip.id, workTaskId: _this.task.id
                });
            }));
            _this.goBackEquipmentOptions = _this.goBackEquipmentOptions.concat(otherGoBackEquipment.map(function (equip) {
                return new WorkTaskGoBackEquipment({ equipmentType: equip, goBackEquipmentId: equip.id, workTaskId: _this.task.id
                });
            }));
        });
        this.treeTypeService.getAll().subscribe(function (types) {
            if (_this.task.workTaskTreeTypes) {
                _this.treeTypeOptions = _this.task.workTaskTreeTypes; // start with existing types
            }
            else {
                _this.treeTypeOptions = [];
                _this.task.workTaskTreeTypes = [];
            }
            // add other options from types they could pick from
            var otherTypes = types.filter(function (t) { return !_this.task.workTaskTreeTypes.some(function (wttt) { return wttt.treeTypeId === t.id; }); });
            _this.treeTypeOptions = _this.treeTypeOptions.concat(otherTypes.map(function (tt) {
                var newWTTT = new WorkTaskTreeType();
                newWTTT.treeType = tt;
                newWTTT.treeTypeId = tt.id;
                newWTTT.workTaskId = _this.task.id; // may be null if new equipment
                return newWTTT;
            }));
            _this.treeTypeOptions = _this.treeTypeOptions.map(function (tto) {
                return {
                    label: tto.treeType.genus ?
                        tto.treeType.commonName + " (" + tto.treeType.genus + " " + tto.treeType.species + ")" :
                        "" + tto.treeType.commonName,
                    value: tto
                };
            });
        });
    };
    WorkTaskMaintenanceComponent.prototype.completeTask = function () {
        this.task.completionStatus = WorkTaskCompletionStatus.Completed;
        if (!this.task.completedDate) {
            this.task.completedDate = new Date();
        }
    };
    WorkTaskMaintenanceComponent.prototype.onSchedulableAfterCompletionOfIdChange = function () {
        if (!this.task.schedulableAfterCompletionOfId) {
            this.task.schedulableAfterCompletionOf = null;
        }
    };
    WorkTaskMaintenanceComponent.prototype.saveTask = function (ngForm) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (!this.isQuoteTask && !this.task.id && !this.task.customerSignature) {
            this.showSignError = true;
            return;
        }
        else {
            this.showSignError = false;
        }
        if (!ngForm.invalid) {
            this.isSaving = true;
            if (this.task.id) {
                var dto = new WorkTaskUpdateDTO({
                    id: this.task.id,
                    name: this.task.name,
                    price: this.task.price,
                    hours: this.task.hours,
                    description: this.task.description,
                    treeTypeIds: this.task.workTaskTreeTypes.map(function (wttt) { return wttt.treeTypeId; }),
                    location: this.task.location,
                    skillIds: this.task.workTaskSkills.map(function (wts) { return wts.skillId; }),
                    equipmentTypeIds: this.task.workTaskEquipment.map(function (wte) { return wte.equipmentTypeId; }),
                    schedulableAfterCompletionOfId: this.task.schedulableAfterCompletionOfId,
                    completionStatus: this.dumbEnumConversion(this.task.completionStatus),
                    customerIssue: this.task.customerIssue,
                    goBackHoursEstimate: this.task.goBackHoursEstimate,
                    goBackEquipmentTypeIds: this.task.workTaskGoBackEquipment.map(function (wtgbe) { return wtgbe.goBackEquipmentId; }),
                    // priority stuff
                    priorityLevelId: this.task.manualPriorityLevel ? this.task.manualPriorityLevel.id : null,
                    hasCalculatedPriority: this.task.hasCalculatedPriority,
                    priorityShortcutId: this.task.priorityShortcutId,
                    dueDateStart: this.task.dueDateStart,
                    dueDateEnd: this.task.dueDateEnd,
                    hardStartDate: this.task.hardStartDate,
                    hardEndDate: this.task.hardEndDate,
                    manualPriorityShortcutType: WorkTaskUpdateDTOManualPriorityShortcutType[this.task.manualPriorityShortcutType],
                    manualRangeStart: this.task.manualRangeStart,
                    manualRangeEnd: this.task.manualRangeEnd,
                    // work task only updates
                    fixedPrice: this.task.fixedPrice,
                    hourlyRate: this.task.hourlyRate,
                    minPrice: this.task.minPrice,
                    maxPrice: this.task.maxPrice,
                });
                this.workTaskService.updateWithDTO(dto).subscribe(function (workTask) {
                    if (_this.task.completionStatus === WorkTaskCompletionStatus.Go_Back) {
                        _this.task.hasCalculatedPriority = false;
                    }
                    _this.saveBlobs(workTask);
                    _this.task = workTask;
                }, function (error) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Could not save the task, please ensure all the fields are valid and retry.'
                    });
                    _this.isSaving = false;
                });
            }
            else {
                // Save the work task
                this.workTaskService.add(this.task).subscribe(function (workTask) {
                    _this.task = workTask;
                    var woWorkTask = new WorkOrderWorkTask();
                    woWorkTask.workTask = workTask;
                    woWorkTask.workTaskId = workTask.id;
                    if (_this.workOrder instanceof WorkWorkOrder) {
                        woWorkTask.workWorkOrderId = _this.workOrder.id;
                        if (_this.workOrder.completionStatus === WorkWorkOrderCompletionStatus.Completed) {
                            // We are adding a task to completed WO (it's probably a go back) so change the WO's completion status
                            _this.workOrder.completionStatus = WorkWorkOrderCompletionStatus.Ready_to_Schedule;
                        }
                        if (_this.task.priorityShortcutId) {
                            var backlog = _this.prioritySelector.priorityTypeOptions.find(function (p) { return p.id === _this.task.priorityShortcutId; });
                            if (backlog && backlog.weeksOutStart !== undefined) {
                                var dueDateStart = new Date();
                                var dueDateEnd = new Date();
                                if (_this.workOrder.workOrderWorkTasks.length > 0 && _this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTask.priorityShortcutId === 3; })) {
                                    dueDateStart = _this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTask.priorityShortcutId === 3; }).workTask.dueDateStart;
                                    dueDateEnd = _this.workOrder.workOrderWorkTasks.find(function (wowt) { return wowt.workTask.priorityShortcutId === 3; }).workTask.dueDateEnd;
                                }
                                else if (backlog) {
                                    var daysOutStart = (backlog.weeksOutStart !== undefined ? backlog.weeksOutStart : 4) * 7;
                                    var daysOutEnd = (backlog.weeksOutEnd !== undefined ? backlog.weeksOutEnd : 5) * 7;
                                    dueDateStart = moment(dueDateStart).add(daysOutStart, 'd').toDate();
                                    dueDateEnd = moment(dueDateEnd).add(daysOutEnd, 'd').toDate();
                                }
                                _this.task.dueDateStart = dueDateStart;
                                _this.task.dueDateEnd = dueDateEnd;
                            }
                        }
                    }
                    else {
                        woWorkTask.quoteWorkOrderId = _this.workOrder.id;
                    }
                    _this.workOrder.workOrderWorkTasks.push(woWorkTask);
                    _this.saveBlobs(workTask);
                }, function (error) {
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Could not add the task to the work order, please ensure all the fields are valid and retry.'
                    });
                    _this.isSaving = false;
                });
            }
        }
    };
    WorkTaskMaintenanceComponent.prototype.onUpdatedSignature = function (signature) {
        this.task.signedDate = new Date();
        this.task.customerSignature = signature;
    };
    WorkTaskMaintenanceComponent.prototype.deleteButtonPressed = function () {
        // if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
        //   const deleteIndex = this.workOrder.workOrderWorkTasks.findIndex(wt => wt.workTask.id === this.task.id);
        //   const deleted = this.workOrder.workOrderWorkTasks[deleteIndex];
        //   if (deleted.id) {
        //     this.workTaskService.delete(this.task.id).subscribe(() => {
        //       this.workOrder.workOrderWorkTasks.splice(deleteIndex, 1);
        //       this.workWorkOrderMaintenanceService.removeWorkTaskDependentReferences(this.workOrder, deleted);
        //     });
        //   } else {
        //     this.workOrder.workOrderWorkTasks.splice(deleteIndex, 1);
        //     this.workWorkOrderMaintenanceService.removeWorkTaskDependentReferences(this.workOrder, deleted);
        //   }
        // }
        this.delete.emit(this.task);
    };
    WorkTaskMaintenanceComponent.prototype.dumbEnumConversion = function (workTaskCompletionStatus) {
        // most common ones on top
        if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up) {
            return WorkTaskUpdateDTOCompletionStatus.Customer_Unsatisfied__RM_Follow_Up;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Go_Back) {
            return WorkTaskUpdateDTOCompletionStatus.Go_Back;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Completed) {
            return WorkTaskUpdateDTOCompletionStatus.Completed;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Created) {
            return WorkTaskUpdateDTOCompletionStatus.Created;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Ready_to_Schedule) {
            return WorkTaskUpdateDTOCompletionStatus.Ready_to_Schedule;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Not_Notified) {
            return WorkTaskUpdateDTOCompletionStatus.Customer_Not_Notified;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Notified) {
            return WorkTaskUpdateDTOCompletionStatus.Customer_Notified;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Partially_Complete) {
            return WorkTaskUpdateDTOCompletionStatus.Partially_Complete;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Credit_Card_Needed) {
            return WorkTaskUpdateDTOCompletionStatus.Credit_Card_Needed;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Work_Orders_Created) {
            return WorkTaskUpdateDTOCompletionStatus.Work_Orders_Created;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Unable_to_be_Completed) {
            return WorkTaskUpdateDTOCompletionStatus.Unable_to_be_Completed;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Paid) {
            return WorkTaskUpdateDTOCompletionStatus.Paid;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Customer_Satisfied) {
            return WorkTaskUpdateDTOCompletionStatus.Customer_Satisfied;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Quote_Expired) {
            return WorkTaskUpdateDTOCompletionStatus.Quote_Expired;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Quote_Rejected) {
            return WorkTaskUpdateDTOCompletionStatus.Quote_Rejected;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Opened) {
            return WorkTaskUpdateDTOCompletionStatus.Opened;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Sent) {
            return WorkTaskUpdateDTOCompletionStatus.Sent;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.New) {
            return WorkTaskUpdateDTOCompletionStatus.New;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Transformed_to_Quote) {
            return WorkTaskUpdateDTOCompletionStatus.Transformed_to_Quote;
        }
        else if (workTaskCompletionStatus === WorkTaskCompletionStatus.Pending) {
            return WorkTaskUpdateDTOCompletionStatus.Pending;
        }
    };
    return WorkTaskMaintenanceComponent;
}(BaseTaskCardMaintenanceComponent));
export { WorkTaskMaintenanceComponent };
