<p-card class="sameSizeCards">
  <div class="ui-g">
    <div *ngIf="showStatusBar" class="status-header ui-g-12 ui-g" [ngClass]="{
      'completed': workOrderWellnessTask.wellnessTask.completionStatus == 'Completed',
      'go-back': workOrderWellnessTask.wellnessTask.completionStatus == 'Go Back' || isIncompleteGoBack,
      'rm-follow-up': workOrderWellnessTask.wellnessTask.completionStatus == 'Customer Unsatisfied - RM Follow Up'
    }">
      <div class="status-bar ui-g-12">
        <i style="vertical-align: middle;" class="fa" [ngClass]="{
          'fa-check': workOrderWellnessTask.wellnessTask.completionStatus == 'Completed',
          'fa-exclamation': workOrderWellnessTask.wellnessTask.completionStatus == 'Customer Unsatisfied - RM Follow Up',
          'fa-arrow-left': workOrderWellnessTask.wellnessTask.completionStatus == 'Go Back'
          }"></i>
        <span style="vertical-align: middle;">&nbsp;{{workOrderWellnessTask.wellnessTask.completionStatus}}</span>
        <span style="vertical-align: middle;" *ngIf="workOrderWellnessTask.wellnessTask.paymentStatus">&nbsp;|&nbsp;{{workOrderWellnessTask.wellnessTask.paymentStatus}}</span>
        <span style="vertical-align: middle;" *ngIf="workOrderWellnessTask.wellnessTask.paidDate">&nbsp;|&nbsp;{{workOrderWellnessTask.wellnessTask.paidDate | date: 'shortDate'}}</span>
        <span style="vertical-align: middle;" *ngIf="!workOrderWellnessTask.wellnessTask.paymentStatus">&nbsp;|&nbsp;Not Paid</span>
        <span style="vertical-align: middle;" *ngIf="!workOrderWellnessTask.wellnessTask.paidDate">&nbsp;|&nbsp;No paid date</span>
      </div>
      <br/>
      <div class="ui-g-12 showLineBreaks" *ngIf="workOrderWellnessTask.wellnessTask.customerIssue && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed'">
        {{workOrderWellnessTask.wellnessTask.customerIssue}}
      </div>
      <div class="ui-g-4" *ngIf="workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment && workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment.length > 0 && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed'">
        Required Equipment Type(s):
      </div>
      <div class="ui-g-8" *ngIf="workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment && workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment.length > 0 && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed'">
        <span class="taskStyle" *ngFor="let wtEquipment of workOrderWellnessTask.wellnessTask.wellnessTaskGoBackEquipment">
        {{wtEquipment.equipmentType.type}}
        </span>
      </div>

      <div class="ui-g-4" *ngIf="isIncompleteGoBack">
        <label>Go Back Uploads:</label>
      </div>
      <div class="ui-g-8" *ngIf="isIncompleteGoBack">
        <app-blob-manager 
          [containerName]="'wellness-task-go-back'" 
          [parentRecordId]="uploadId" 
          [uploadLabel]="'Document/Photo'" 
          [viewOnly]="true"></app-blob-manager>
      </div>

      <div class="ui-g-12" style="text-align: left">
        <button 
          pButton 
          type="button" 
          label="Complete with Pesticide Record"
          (click)="showTaskHoursDialog(true, true)" 
          *ngIf="canComplete 
                && (workOrderWellnessTask.wellnessTask.goBackBucketId != undefined) 
                && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
                && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
          class="ui-button-success editButtonMargin" 
          style="font-size: 90%;"
        >
        </button>
        <button 
          pButton 
          type="button" 
          label="Complete without Pesticide Record" 
          (click)="showTaskHoursDialog(false, true)"
          *ngIf="canComplete 
                && (workOrderWellnessTask.wellnessTask.goBackBucketId != undefined) 
                && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
                && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
          class="ui-button-warning" 
          style="font-size: 90%;"
        >
        </button>
        <button pButton type="button" label="Undo Complete" icon="fa fa-undo" (click)="undoComplete()" *ngIf="canUndo" class="ui-button-primary"></button>
        <div>
          <button 
            pButton 
            type="button" 
            label="Multiday - Add Hours for Day"
            (click)="showTaskHoursDialogAddHours()" 
            *ngIf="canComplete 
                  && workOrderWellnessTask.wellnessTask.scheduleDateFrom
                  && workOrderWellnessTask.wellnessTask.currentBigDaySubrangeId
                  && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
                  && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
            class="ui-button editButtonMargin" 
            style="font-size: 90%; margin-top: 5px;"
          ></button>
          <button 
            pButton 
            type="button" 
            label="Unable to Complete"
            (click)="showTaskHoursDialogUnableToComplete(true)" 
            *ngIf="canComplete 
                  && (workOrderWellnessTask.wellnessTask.goBackBucketId != undefined) 
                  && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
                  && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
            class="ui-button-danger editButtonMargin" 
            style="font-size: 90%; margin-top: 5px;"
          ></button>
        </div>
      </div>
    </div>
    <div class="ui-g-9" style="text-align: left">
      <h6>{{workOrderWellnessTask.wellnessTask?.name}} - GDD {{workOrderWellnessTask.wellnessTask?.growingDegreeDay}}</h6>
      <h6>
        <span *ngIf="canSeeHiddenPrice || !hidePrice">{{workOrderWellnessTask.wellnessTask.price | currency}}</span>
        <span *ngIf="(canSeeHiddenPrice || !hidePrice) && !isCustomer"> - </span>
        <span *ngIf="!isCustomer">{{workOrderWellnessTask.wellnessTask.hours}} hr(s)</span>
      </h6>
    </div>
    <div class="ui-g-3" style="text-align: right" *ngIf="fromQuote && workOrderWellnessTask.id">
      <p-checkbox name="agree" [disabled]="true" [(ngModel)]="isSelected" (onChange)="workCheckChanged($event)" binary="true"></p-checkbox>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-9" style="text-align: left">
      <h6 class="showLineBreaks">
        {{workOrderWellnessTask.wellnessTask?.description}}
      </h6>
    </div>
    <div class="ui-g-3" style="text-align: right">
      <button 
        pButton
        type="button"
        icon="pi pi-copy"
        *ngIf="isEditable "
        (click)="copyTask()"
        class="ui-button-secondary editMargin">
      </button>
      <button pButton type="button" icon="pi pi-pencil" (click)="editWellnessTask()" *ngIf="isEditable || canSetGoBack || (canEditAssignedTasks && scheduledBucket)"
        class="ui-button-secondary editMargin">
      </button>
      <button pButton type="button" icon="pi pi-trash" (click)="deleteWellnessTask()"
        *ngIf="isDeletable()" class="ui-button-danger">
      </button>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12" style="text-align: left" *ngIf="!workOrderWellnessTask.wellnessTask.isGoBack">
      <button 
        pButton 
        type="button" 
        label="Complete with Pesticide Record"
        (click)="showTaskHoursDialog(true, false)" 
        *ngIf="canComplete 
              && (workOrderWellnessTask.wellnessTask.currentBucketId != undefined || workOrderWellnessTask.wellnessTask.scheduleDateFrom) 
              && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
              && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
        class="ui-button-success editButtonMargin" 
        style="font-size: 90%;"
      >
      </button>
      <button 
        pButton 
        type="button" 
        label="Complete without Pesticide Record" 
        (click)="showTaskHoursDialog(false, false)"
        *ngIf="canComplete 
              && (workOrderWellnessTask.wellnessTask.currentBucketId != undefined || workOrderWellnessTask.wellnessTask.scheduleDateFrom) 
              && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
              && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
        class="ui-button-warning" 
        style="font-size: 90%;"
      >
      </button>
      <button pButton type="button" label="Undo Complete" icon="fa fa-undo" (click)="undoComplete()" *ngIf="canUndo && !showStatusBar" class="ui-button-primary"></button>
      <div>
        <button 
          pButton 
          type="button" 
          label="Multiday - Add Hours for Day"
          (click)="showTaskHoursDialogAddHours()" 
          *ngIf="canComplete 
                && workOrderWellnessTask.wellnessTask.scheduleDateFrom
                && workOrderWellnessTask.wellnessTask.currentBigDaySubrangeId
                && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
                && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
          class="ui-button editButtonMargin" 
          style="font-size: 90%; margin-top: 5px;"
        ></button>
        <button 
          pButton 
          type="button" 
          label="Unable to Complete"
          (click)="showTaskHoursDialogUnableToComplete(false)" 
          *ngIf="canComplete 
                && (workOrderWellnessTask.wellnessTask.currentBucketId != undefined || workOrderWellnessTask.wellnessTask.scheduleDateFrom) 
                && workOrderWellnessTask.wellnessTask.completionStatus != 'Completed' 
                && workOrderWellnessTask.wellnessTask.completionStatus !='Unable to be Completed'"
          class="ui-button-danger editButtonMargin" 
          style="font-size: 90%; margin-top: 5px;"
        ></button>
      </div>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.wellnessTaskTreeTypes && workOrderWellnessTask.wellnessTask.wellnessTaskTreeTypes.length > 0" style="text-align: left">
    <div class="ui-g-4">
      Tree Type(s):
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let wtTreeType of workOrderWellnessTask.wellnessTask.wellnessTaskTreeTypes">
        {{wtTreeType.treeType.commonName}}
      </span>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.location" style="text-align: left">
    <div class="ui-g-4">
      Location of Tree(s):
    </div>
    <div class="ui-g-8">
      {{workOrderWellnessTask.wellnessTask.location}}
    </div>
  </div>
  <div class="ui-g" style="text-align: left" *ngIf="uploadId > 0 && !isCustomer">
    <div class="ui-g-4">
      Uploads:
    </div>
    <div class="ui-g-8">
      <app-blob-manager
        [containerName]="'wellness-task'" 
        [parentRecordId]="uploadId" 
        [uploadLabel]="'Document/Photo'" 
        [viewOnly]="true"></app-blob-manager>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.wellnessTaskSkills && workOrderWellnessTask.wellnessTask.wellnessTaskSkills.length > 0 && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Required Skill(s):
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let wtSkill of workOrderWellnessTask.wellnessTask.wellnessTaskSkills">
        {{wtSkill.skill.name}}
      </span>
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.wellnessTaskEquipment && workOrderWellnessTask.wellnessTask.wellnessTaskEquipment.length > 0 && !isCustomer" style="text-align: left">
    <div class="ui-g-4">
      Required Equipment Type(s):
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let wtEquipment of workOrderWellnessTask.wellnessTask.wellnessTaskEquipment">
        {{wtEquipment.equipmentType.type}}
      </span>
    </div>
  </div>

  <hr *ngIf="scheduledBucket && canSeePreviousCrew && !isCustomer"/>
  <div class="ui-g" *ngIf="scheduledBucket && canSeePreviousCrew && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Work Date(s):
    </div>
    <div class="ui-g-8">
      {{scheduledBucket.date | date: 'shortDate'}}<span *ngIf="goBackScheduledBucket">, {{goBackScheduledBucket.date | date: 'shortDate'}}</span> 
    </div>
    <div class="ui-g-4">
      Crew:
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let sbEmployee of scheduledBucket.scheduledBucketEmployees">
        {{sbEmployee.employee.fullName}}
      </span>
      <span class="taskStyle" *ngFor="let sbEmployee of goBackScheduledBucket?.scheduledBucketEmployees">
        {{sbEmployee.employee.fullName}}
      </span>
    </div>
    <div class="ui-g-4">
      Equipment:
    </div>
    <div class="ui-g-8">
      <span class="taskStyle" *ngFor="let sbEquipment of scheduledBucket.scheduledBucketEquipment">
        {{sbEquipment.equipment.number}}
      </span>
      <span class="taskStyle" *ngFor="let sbEquipment of goBackScheduledBucket?.scheduledBucketEquipment">
        {{sbEquipment.equipment.number}}
      </span>
    </div>
  </div>

  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.taskHourEntries && workOrderWellnessTask.wellnessTask.taskHourEntries.length > 0 && canSeePreviousCrew && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Task Hours Total:
    </div>
    <div class="ui-g-8">
      {{taskHourTotal | number: '1.0-2'}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.isGoBack && workOrderWellnessTask.wellnessTask.taskHourEntries && workOrderWellnessTask.wellnessTask.taskHourEntries.length > 0 && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Go Back Hours Total:
    </div>
    <div class="ui-g-8">
      {{taskHourGoBackTotal | number: '1.0-2'}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.jobFeedback && canSeePreviousCrew && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Crew Feedback:
    </div>
    <div class="ui-g-8">
      {{workOrderWellnessTask.wellnessTask.jobFeedback}}
    </div>
  </div>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.goBackFeedback && !isCustomer" style="text-align: left;">
    <div class="ui-g-4">
      Go Back Feedback:
    </div>
    <div class="ui-g-8">
      {{workOrderWellnessTask.wellnessTask.goBackFeedback}}
    </div>
  </div>

  <hr *ngIf="workOrderWellnessTask.wellnessTask.customerIssue && !isCustomer"/>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.customerIssue && !isCustomer" style="text-align:left">
    <div class="ui-g-4">
      Customer Issue:
    </div>
    <div class="ui-g-8 showLineBreaks">
      {{workOrderWellnessTask.wellnessTask.customerIssue}}
    </div>
    <div class="ui-g-4"></div>
    <div class="ui-g-8" *ngIf="workOrderWellnessTask.wellnessTask.goBackCompletionDate">
      Resolved {{workOrderWellnessTask.wellnessTask.goBackCompletionDate | date: 'shortDate'}}
    </div>
  </div>
  
  <hr *ngIf="workOrderWellnessTask.wellnessTask.renewable && !workOrderWellnessTask.wellnessTask.renewed"/>
  <div class="ui-g" *ngIf="workOrderWellnessTask.wellnessTask.renewable && !workOrderWellnessTask.wellnessTask.renewed" style="text-align:left">
    <div class="ui-g-4">
      Service Will Renew:
    </div>
    <div class="ui-g-8 showLineBreaks">
      {{workOrderWellnessTask.wellnessTask.renewalAccepted ? 'Yes' : 'No'}}
    </div>
  </div>
</p-card>

<p-dialog 
  [modal]="true" 
  [responsive]="true"
  [style]="{'width': '25em'}" 
  (onHide)="closedTaskHoursDialog()"
  [(visible)]="showTaskHour"  
  [draggable]="true"
  [blockScroll]="true"
>
  <app-task-hours-entry 
    *ngIf="showTaskHour"
    [asGoBack]="taskHoursForGoBack"
    [complete]="completeTask"
    [customerId]="customerId" 
    [quoteNumber]="quoteNumber" 
    [task]="workOrderWellnessTask.wellnessTask"
    [unableToComplete]="unableToComplete" 
    [withRecord]="withRecord"
    (addHours)="addedHours()"
    (commentId)="addCustomerCommentId($event)"
    (completed)="taskHoursCompleted()"
    (unableToCompleteSubmitted)="taskHoursSubmitted($event)"
  >
  </app-task-hours-entry>
</p-dialog>