
  <div class="p-grid">
    <div class="p-col-12 cancel-bottom-padding">
      <div class="rfq-header">Select Services</div>
      <div class="rfq-subheader">Please select the services for which you would like learn more!</div>
    </div>
    <div class="p-col-12 p-md-6 p-nogutter cancel-bottom-padding cancel-top-padding">
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="pruning" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Tree Pruning" [(ngModel)]="quoteRequest.needsPruning" inputId="pruning"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="removal" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Tree Removal" [(ngModel)]="quoteRequest.needsRemoval" inputId="removal"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="stumpRemoval" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Stump Removal" [(ngModel)]="quoteRequest.needsStumpRemoval" inputId="stumpRemoval"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="fertizialtion" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Fertilization" [(ngModel)]="quoteRequest.needsFertilization" inputId="fertilization"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="insectAndDisease" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Insect & Disease Management" [(ngModel)]="quoteRequest.needsInsectOrDiseaseControl" inputId="insectrOrDisease"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="cabling" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Cabling & Bracing" [(ngModel)]="quoteRequest.needsCablingAndBracing" inputId="cablingAndBracing"></p-checkbox>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-nogutter cancel-top-padding">
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="lightingProtection" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Lighting Protection" [(ngModel)]="quoteRequest.needsLightningProtection" inputId="lighting"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="clearing" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Clearing & Forestry Mowing" [(ngModel)]="quoteRequest.needsLotClearingAndForestryMower" inputId="clearingAndMowing"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="rootManagement" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Root Zone Management" [(ngModel)]="quoteRequest.needsRootZoneManagement" inputId="rootZone"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="treeRisk" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Tree Risk Assessment" [(ngModel)]="quoteRequest.needsTreeRiskAssessment" inputId="riskAssessment"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="treePreservation" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Tree Preservation" [(ngModel)]="quoteRequest.needsPreConstructionTreeConsulting" inputId="preservation"></p-checkbox>
      </div>
      <div class="p-col-12 less-top-no-bottom-padding">
        <p-checkbox name="treeMapping" group="servicesGroup" [binary]="true" [disabled]="isDisabled" label="Tree Mapping" [(ngModel)]="quoteRequest.needsTreeMapping" inputId="treeMapping"></p-checkbox>
      </div>
    </div>
</div>