<div>
  <div class="headerText">Survey Responses</div>
</div>

<div class="p-grid">
  <div class="p-col-12">
    <span class="ui-float-label">
      <input id="float-input" type="text" size="30" pInputText [(ngModel)]="searchTerm">
      <label for="float-input">
        <div class="search-icon-container">
          <div><i class="pi pi-search"></i></div>
          <div>Search</div>
        </div>
      </label>
    </span>
  </div>
</div>

<p-table #dt [columns]="cols" [value]="surveyHeaders | surveyHeaderFilter: searchTerm" [paginator]="true" [rows]="30"
  [responsive]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-surveyHeader let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{surveyHeader.tempCustomerName}}</td>
      <td><span class="ui-column-title">{{columns[1].header}}</span>{{surveyHeader.tempPhoneNumber}}</td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{surveyHeader.customer.address.street}}</td>
      <td><span class="ui-column-title">{{columns[3].header}}</span>{{surveyHeader.customer.address.zip}}</td>
      <td><span class="ui-column-title">{{columns[4].header}}</span>{{surveyHeader.dateSent | date}}</td>
      <td><span class="ui-column-title">{{columns[5].header}}</span>{{surveyHeader.dateCompleted | date}}</td>
      <td><span class="ui-column-title">{{columns[6].header}}</span>
        {{surveyHeader.surveyAnswers[0].yesNoResponse ? 'Yes' : (surveyHeader.surveyAnswers[0].yesNoResponse === false ? 'No' : null)}}
      </td>
      <td>
        <button pButton type="button" icon="pi pi-eye" (click)="viewSurvey(surveyHeader)"
          class="ui-button-secondary blackBorder">
        </button>
        <button pButton type="button" icon="pi pi-trash" (click)="deleteSurveyHeader(surveyHeader)"
          class="ui-button-danger tableButtonMarginLeft">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
